/* eslint-disable no-console */
import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
//import { datadogRum } from '@datadog/browser-rum';
//import { getEnvValue } from './utils/utils';

import { App } from './app/App';
import './index.css';

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}

export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetName: () => string;
}

/*
const envVar = getEnvValue();
if (envVar === 'dev' || envVar === 'perf') {
  datadogRum.init({
    applicationId: 'd2c342e2-07ef-4185-9446-bb3ade29c721',
    clientToken: 'pub31c376da00d50fcd551c38c2d2bded3a',
    site: 'datadoghq.com',
    service: 'racpad-agreement',
    env: envVar,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
  });
}
*/

window.renderAgreement = (
  containerId: string,
  history: History,
  customProps?: CustomPropInterface
) => {
  console.log('inside render agreement ', containerId);
  console.log('inside history ', history);

  const elem = document.getElementById(containerId);

  if (elem) {
    console.log('inside elem', elem);
    ReactDOM.render(<App history={history} customProps={customProps} />, elem);
  }
};

window.unmountAgreement = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderAgreement('root', defaultHistory);
  });
}
