/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
export const ProductType = 'RPDAGR';

export enum ProductPermissions {
  AGREEMENT = 'AGREEMENT',
}

export const AMOrigin = 'am-customer';
export const AccountManagementURLParam = `?origin=${AMOrigin}`;
export const SACrolesArray = [
  'AM',
  'CAR',
  'FSCCAR',
  'LAM',
  'FSCSM',
  'SM',
  'DMT',
  'FSCDMT',
  'DM',
  'FSCDM',
  'RD',
  'FSCRD',
  'FSCLAM',
  'FSCAM',
  'RDT',
];
export const getPrimaryPhoneNumber = (phoneDetails: any) => {
  console.log('initial execution', phoneDetails)
  const activePhoneNumber: any = []
  const primaryPhoneNumber: any = []
  for (let phoneNumber = 0; phoneNumber < phoneDetails.length; phoneNumber++) {
    if (phoneDetails[phoneNumber]?.primary == 'Y') {
      primaryPhoneNumber.push(phoneDetails[phoneNumber])
      break;
    }
    if (phoneDetails[phoneNumber]?.active == 'Y' && activePhoneNumber.length == 0) {
      activePhoneNumber.push(phoneDetails[phoneNumber])
    }
  }
  console.log('primaryPhoneNumber', primaryPhoneNumber, activePhoneNumber)
  return primaryPhoneNumber.length > 0 ? primaryPhoneNumber : activePhoneNumber
}