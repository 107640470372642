/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@rentacenter/racstrap';
export const addedItemStyles = () => {
  const useClasses = makeStyles((theme) => ({
    uploadBtnWrapper: {
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      cursor: 'pointer',
      float: 'right',
      '& input': {
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        height: '32px',
        width: '100%',
      },
    },
    buttonUploading: {
      border: '1px solid #2179fe',
      borderRadius: '10px',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      color: '#2179fe',
      fontFamily: 'OpenSans-semibold',
      cursor: 'pointer',
      textAlign: 'right',
      zIndex: 0,
      padding: '5px 15px',
    },
    agrAddedTotal: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      width: '100%',
    },
    inputGroupText: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#212529',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e9ecef',
      border: '1px solid #ced4da',
      borderTopLeftRadius: '0.25rem',
      borderBottomLeftRadius: '0.25rem',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    inputGroup: {
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
    },
    inputGroup1: {
      position: 'relative',
      alignItems: 'stretch',
    },
    currencyAlign: {
      textAlign: 'right',
    },
    chipBackground: {
      backgroundColor: '#2179FE',
    },
    disabledColour: {
      backgroundColor: '#e9ecef',
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
