/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { searchResultStyles } from '../../../JSstyles/searchResultStyles';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import ExceptionController from './exceptionController';
// import ContextSkeleton from './contextSkeleton';
import FooterContent from './footerInventory';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import AddedItem from './addedItem';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';
import { AgreementContext } from '../../../context/AgreementContext';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import {
  getInventorySearch,
  getPricingClientCall,
  getAgreementOptions,
  GetEmployeeID,
  GetRole,
} from '../../../api/user';

import {
  Grid,
  Card,
  RACButton,
  Typography,
  CardContent,
  RACModalCard,
  CircularProgress,
  Divider,
} from '@rentacenter/racstrap';
//PS - 14 Importing all required files from the corresponding folder
export const Usercontext = createContext({});

export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}

interface Params {
  customerId: string;
}

const SwitchOutSearchResult = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = agreementGlobalStyles(); //This will hold the global styles
  const agrComponent = searchResultStyles(); //This will hold the component specific styles
  const {
    addedItem,
    searchResult,
    setaddedItem,
    switchOutData,
    itemArrayData,
    copyArrayData,
    setsearchResult,
    setSwitchoutcopyArrayData,
    setitemArray,
    agreementCreateStatus,
    itemArrayDataSwitchOut,
    searchCriteria,
    numberOfInventory,
    oldInvRV,
  } = useContext(SwitchOutAgreementContext); //This is the destructured data of Agreement Context
  // const { itemArrayDataSwitchOut, searchCriteria, numberOfInventory } =
  //   useContext(SwitchOutAgreementContext);

  const { setagreementOptions, agreementOptions, isAllDone } =
    useContext(AgreementContext);
  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  }; //This will be handling the sorting order of search result

  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  }; //This object will hold all the state of the data of API response

  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  }; //This object will hold all the loader state
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const ModuleName = 'Choosing Price Tag';
  const [collectIndex, setcollectIndex] = useState<any>();
  const [storedItemId, setstoredItemId] = useState<any>();
  const deleteIdObj = { invDeleteId: '' };
  const { customerId } = useParams<Params>();
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [deleteId, setdeleteId] = React.useState(deleteIdObj);
  const [tagBlueLabel, settagBlueLabel]: any = useState({});
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [SLAController, setSLAController] = useState(SLAObj);
  const [resultController, setresultController] = useState(resultControllerObj);
  const [gridData, setGridData] = useState<any>();
  const [itemArray, setItemArray] = useState<any>([]);
  const [bucketCopy, setBucketCopy] = useState<any>([]);
  const [showAddedItem, setShowAddedItem] = useState(false);
  const [itemPricingFail, setitemPricingFail] = useState(false);
  const [controlViewMode, setControlViewMode] = useState(false);
  const [footerControlData, setfooterControlData] = useState(true);
  const [internalContextData, setInternalContextProvider] = useState<any>();
  //PS - 15 Declaration of all required state variable for performing appropriate action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  const [leftValue, setleftValue]: any = useState({});
  const [rightValue, setrightValue]: any = useState({});
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const [CurrentRole, setCurrentRole] = useState('');
  const [newSingleInvPricingInvArray, setnewSingleInvPricingInvArray]: any =
    useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const storeNumber = window.sessionStorage.getItem('storeNumber');

  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme'; //This will be controlling the view and edit mode of entire page

  const altersearch = showAddedItem
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid; //This will be making the added item pane, visible and invisible
  const showItemSearch = isAllDone
    ? agrComponent.showItemSearch
    : agrComponent.hideItemSearch;
  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setCurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // console.log("before getSearchCriteriaContext", getSearchCriteriaContext);
    console.log('before getsearchResultContext', searchResult);
    console.log('before getaddedItemContext', addedItem);
    console.log('before getItemArray', itemArrayData);
    console.log('before getCopyArray', copyArrayData);
    console.log('Status of the agreement is', agreementCreateStatus);

    if (
      addedItem !== undefined &&
      searchResult !== undefined &&
      itemArrayData !== undefined &&
      copyArrayData !== undefined &&
      agreementCreateStatus !== undefined
    ) {
      console.log('entered');
      if (agreementCreateStatus.agreementCreatedStatus === true) {
        console.log('entered view mode');
        console.log('entered view mode with getaddedItemContext[0]', addedItem);
        setShowAddedItem(true);
        setControlViewMode(true);
        setfooterControlData(false);
        setInternalContextProvider(addedItem);
      } else {
        console.log('entered edit mode');
        if (searchResult.length <= 0) {
          setresultController({
            ...resultControllerObj,
            controlEmptyRecord: true,
          });
        } else {
          setGridData(searchResult);
        }
        setShowAddedItem(true);
        setItemArray(itemArrayData);
        setBucketCopy(copyArrayData);
        setInternalContextProvider(addedItem);
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: false,
          loaderController: false,
        });
        setfooterControlData(false);
      }
    } else if (switchOutData !== undefined) {
      setInternalContextProvider(switchOutData);
      setItemArray(itemArrayDataSwitchOut);
      setitemArray(itemArrayDataSwitchOut);
      setShowAddedItem(true);
      setLoaderBundle({
        ...loaderBundle,
        loaderController: false,
        Gridloader: false,
      });
      setfooterControlData(true);
      const searchValue = {
        itemNo: '',
        serialNo: '',
        bracket: '',
        brand: '',
        model: '',
        statusId: 1,
        subStatusId: 2,
        conditionId: null,
        departments: searchCriteria.departments,
        subDepartments: '',
        packageTypeval: searchCriteria.packageTypeval,
        currentStoreNumber: '',
      };
      searchClick(searchValue);
    }

    async function GetDropdownApiCall() {
      const dropdownval: any = await getAgreementOptions(
        customerId,
        storeNumber
      );
      console.log('New res', dropdownval);
      if (dropdownval.status === 200) {
        setagreementOptions(dropdownval.data);
      }
    }
    if (agreementOptions == undefined) {
      GetDropdownApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //PS - 16 This method will be prepopulating all the values when previous scenario is handled

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Search Result Props', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    console.log('vaue of id in useeffect', deleteId.invDeleteId);
    if (deleteId.invDeleteId !== '') {
      deleteItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]); //PS - 17 This will be triggered when an item is deleted in blue card which will trigger the deleteitem() method.

  const pricingCall = async (
    inventoriesValue: any,
    validateInventoryIdValue: any,
    packageRequiredValue: any,
    agreementRateValue: any,
    agreementTermValue: any,
    isCompetitorMatchValue: any,
    packageNameValue: any,
    packagePriceType: any
  ) => {
    const req: any = {
      storeNumber: storeNumber,
      inventoryInfo: inventoriesValue,
      validateInventoryId: parseInt(validateInventoryIdValue),
      packageRequired: packageRequiredValue,
      packageName: packageNameValue,
      packagePriceType: packagePriceType,
      agreementRate: agreementRateValue,
      agreementTerm: agreementTermValue,
      isCompetitorMatch: isCompetitorMatchValue,
    };
    console.log('pricingCall request', req);

    return await getPricingClientCall(req);
  };
  const deleteItem = () => {
    let UpdatedArray: any = [];
    itemArray.map((deleteObject: any, index: any) => {
      if (
        parseInt(deleteObject.inventoryId) === parseInt(deleteId.invDeleteId)
      ) {
        UpdatedArray = itemArray.filter((item, filterIndex) => {
          console.log('filter copy item', item);
          return filterIndex != index;
        });
        console.log('Newly Updated Array', UpdatedArray);
        setItemArray(UpdatedArray);
        setitemArray(UpdatedArray);
      }
    }); //Here the existing itemArray is mapped and the deleted itemId is removed from the variable and it get reasign to the state variable
    setInternalContextProvider(switchOutData); //This will be providing the initial Switch Out Data to the Added item pane
    setLoaderBundle({
      ...loaderBundle,
      masterLoader: false,
      loaderController: false,
    }); //This will be making the loader invisible
    console.log('Came inside deep else');
    setShowAddedItem(true); //This will be making the Added Item Pane visible
    setfooterControlData(true); //This will be making the next button disable
    setBucketCopy([]); //This will be making the bucketCopy variable as empty array
    let alteredTableData: any = [];
    alteredTableData = [...gridData, bucketCopy[0]];
    setsearchResult(alteredTableData); //This will be adding the deleted item back to the Search result so that the user can add that again
    console.log('ItemMainAry', alteredTableData);
    setGridData(alteredTableData);
    props.setItemSearchCompleted(false);
  }; //PS - 19 This method is responsible for deleting an item in Added item by making an API call with new set of inventory array value.

  const searchClick = async (value: any) => {
    setLoaderBundle({
      ...loaderBundle,
      Gridloader: true,
      loaderController: true,
    });
    console.log('value in search click', value);

    const currentStore = sessionStorage.getItem('storeNumber');
    const req: any = {
      storeNumbers: [currentStore],
      itemNumber: value.itemNo == '' ? null : [value.itemNo],
      serialNumber: value.serialNo.trim(),
      bracket: value.bracket.trim(),
      brand: value.brand.trim(),
      modelNumber: value.model.trim(),
      statusId: value.statusId,
      subStatusId: [2],
      conditionId:
        value.conditionId == '0' || value.conditionId == null
          ? null
          : parseInt(value.conditionId),
      department: searchCriteria.departments,
      subDepartment: value.subDepartments,
      packageName: null,
      isActivePriceTag: true,
    }; //This will form the request object for the item search API
    console.log('structured payload', req);
    try {
      const response: any = await getInventorySearch(req);
      if (response.status == 400 || response.status == 500) {
        setresultController({
          ...resultControllerObj,
          internalServerController: true,
        }); //This will render Some thing went wrong message in search result
        setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
      } else if (response.status == 200 && response.data.response.length > 0) {
        let furtherFilteredData: any = [];
        if (itemArray.length > 0) {
          console.log('reached if of search');
          let pushMe = false;

          response.data.response.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < itemArray.length; i++) {
              console.log('itemArray log', itemArray);
              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );

              if (itemArray[i] !== parseInt(filteredDataObj.inventoryId)) {
                console.log('reached once true');
                pushMe = true;
              } else {
                console.log('reached break statement');
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        } else {
          console.log('reached else of sr');
          furtherFilteredData = response.data.response;
        }

        setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
        setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        console.log('Response from api', response);

        // const ApiResult: any = response.data.response;
        // console.log('response', ApiResult);
        // console.log('response-array', ApiResult[0]);
        setresultController(resultControllerObj);
        setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
      } else if (response.data.response.length <= 0) {
        setGridData([]);
        setresultController({ ...resultControllerObj, controlRecord: true });
        setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
      }
    } catch {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      }); //This will render Some thing went wrong message in search result
      setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
    }
  }; //PS - 20 This method is called every time when Search button is clicked. This method will be triggering the Search Inventory API call with the corresponding request parameter.

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');

    if (gridData !== undefined && gridData.length > 0) {
      console.log('Inside table bind', gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                orderBy="loanerAgreement"
                handleActionType={addItem}
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      ); //This is rendered when the API response has a definite data.
    } else if (resultController.internalServerController) {
      return (
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />
      ); //This is rendered when 500 occours
    } else if (resultController.controlRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Records"
          throwStyle={classes}
        />
      ); //This is rendered when no search result id found
    } else if (resultController.controlEmptyRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />
      ); //This is rendered when no item to add further
    }
  }; //PS - 21 This method is called when the API result arrives to a variable. Based on the response the data is displayed in the Search Result area.

  const addItem = async (itemid: any, index: any, inventoryPriceId: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true }); //This will enable the loader

    const pricingItemArray = itemArray;
    if (numberOfInventory == itemArray.length) {
      pricingItemArray.pop();
    } //This will be removing the last value in the itemArray every time when Add Item is clicked.
    console.log('pricingItemArray', pricingItemArray);
    setstoredItemId(itemid);
    setcollectIndex(index);
    const response: any = await pricingCall(
      pricingItemArray,
      itemid,
      false,
      null,
      null,
      false,
      '',
      null
    );

    setLoaderBundle({ ...loaderBundle, masterLoader: false }); //This will be making the loader disable
    console.log('Respoooooonse', response.data);

    if (response.status == 200 && response.data !== undefined) {
      const allowPricePopulate = await pricePopulator(response);
      if (allowPricePopulate) {
        const newItemArray = [
          ...itemArray,
          {
            inventoryId: Number(itemid),
            inventoryPriceId: Number(inventoryPriceId),
          },
        ];
        setItemArray(newItemArray); //This will be pushing the newly added item id into the variable
        setitemArray(newItemArray);
        console.log('need truncate response', response.data);

        const rawResponse = response.data;
        const alteringObject = rawResponse.itemPricing.filter((item) => {
          return parseInt(item.inventoryId) == itemid;
        });
        const calculateInvDepr =
          (Number(gridData[index].remainingValue) /
            Number(switchOutData.itemPricing[0].agreementTotal)) *
          100;
        const calculateAgrInvDepr =
          ((Number(oldInvRV) + Number(gridData[index].remainingValue)) /
            Number(switchOutData.agreementPricing.agreementTotal)) *
          100;
        console.log('calculateAgrInvDepr in add', calculateAgrInvDepr);
        console.log('calculateInvDepr in add', calculateInvDepr);
        console.log('Raw Response data', rawResponse);

        // const newItemPricingArray = rawResponse.itemPricing.slice(-1);
        rawResponse.itemPricing = alteringObject;
        rawResponse.itemPricing[0].agreementRate =
          switchOutData.itemPricing[0].agreementRate;
        rawResponse.itemPricing[0].agreementTotal =
          switchOutData.itemPricing[0].agreementTotal;
        rawResponse.agreementPricing.agreementTerm =
          switchOutData.agreementPricing.agreementTerm;
        rawResponse.agreementPricing.tagRate =
          switchOutData.agreementPricing.tagRate;
        rawResponse.agreementPricing.tagTerm =
          switchOutData.agreementPricing.tagTerm;
        rawResponse.agreementPricing.tagTotal =
          switchOutData.agreementPricing.tagTotal;
        rawResponse.agreementPricing.agreementRate =
          switchOutData.agreementPricing.agreementRate;
        rawResponse.agreementPricing.agreementTotal =
          switchOutData.agreementPricing.agreementTotal;
        rawResponse.agreementPricing.cashPrice =
          switchOutData.agreementPricing.cashPrice; //Here the Api response is being destructured and the existing value from the Switch Out item is added
        rawResponse.agreementPricing.agreementInventoryDepreciation =
          calculateAgrInvDepr.toFixed(2);
        rawResponse.itemPricing[0].inventoryDepreciation =
          calculateInvDepr.toFixed(2);
        console.log('switchOutData in add item', switchOutData);
        console.log('Manipulated Data', rawResponse);

        setInternalContextProvider(response.data); //Here the API response is assigned to the context to get access by the added item pane.
        setaddedItem(response.data);
        // ContextSkeleton(
        //   response.data,
        //   newItemArray,
        //   setTestInput,
        //   customerId,
        //   priceController
        // ); //This method will be providing the data to the context setter of agreement details page.

        console.log('newItemArray in add item', newItemArray);
        setfooterControlData(false); //This method will be enabling the Next button in the footer.
        if (bucketCopy.length == 0) {
          setBucketCopy([gridData[index]]); //Newly added item details is pushed to the bucketCopy
          setSwitchoutcopyArrayData([gridData[index]]);
          const newTableData = gridData.filter((item, filterIndex) => {
            return filterIndex != index;
          }); //The added item is removed from the Search result and new data is used to bind the result.

          if (newTableData.length <= 0) {
            setresultController({
              ...resultControllerObj,
              controlEmptyRecord: true,
            });
          } //If the search result becomes empty, then No item to add is displayed using this function.

          setsearchResult(newTableData);
          console.log('ItemMainAry', newTableData);
          setGridData(newTableData); //Here for the first time when an item is added, the added item is moved to the bucketCopy and it is removed from the Search Result grid
        } else if (bucketCopy.length == 1) {
          setBucketCopy([gridData[index]]); //Newly added item details is pushed to the bucketCopy

          const newTableData = gridData.filter((item, filterIndex) => {
            return filterIndex != index;
          }); //The added item is removed from the Search result and new data is used to bind the result.

          if (newTableData.length <= 0) {
            setresultController({
              ...resultControllerObj,
              controlEmptyRecord: true,
            });
          } //If the search result becomes empty, then No item to add is displayed using this function.
          let alteredTableData: any = [];
          alteredTableData = [...newTableData, bucketCopy[0]];
          setsearchResult(alteredTableData);
          console.log('ItemMainAry', newTableData);
          setGridData(alteredTableData);
          // let alteredTableData: any = [];
          // alteredTableData = [...newTableData, bucketCopy[0]];
          // setsearchResult(newTableData); //This will be adding the deleted item back to the Search result so that the user can add that again
          // console.log('ItemMainAry', newTableData);
          // setGridData(newTableData);
        }
        console.log('copy', bucketCopy);
        console.log('tabledata.filter', gridData);
        setShowAddedItem(true); //This will be making the Added Item Pane visible.
      }
    } else if (response.status == 500 || response.status == 400) {
      setitemPricingFail(true);
    }
  }; //PS - 22 This method is responsible for Adding an Item in added item pane while clicking + icon in Search Result

  const pricePopulator = (response: any) => {
    let allowPopulatePrice = false;
    for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
      if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
        allowPopulatePrice = false;
        break;
      } else {
        allowPopulatePrice = true;
      }
    }

    if (allowPopulatePrice) {
      return allowPopulatePrice;
    } else {
      //const arrayCollection: any = [];
      const itemArrayCollection: any = [];
      for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
        if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
          // arrayCollection.push(
          //   response.priceTag[i].inventoryPriceTags[0],
          //   response.priceTag[i].inventoryPriceTags[1]
          // );
          settagBlueLabel({
            inventoryNumber:
              response.data.inventoryPriceInfo[i].inventoryNumber,
            inventoryDesc: response.data.inventoryPriceInfo[i].inventoryDesc,
            inventoryId: response.data.inventoryPriceInfo[i].inventoryId,
          });
          if (
            response.data.inventoryPriceInfo[i].inventoryPrice[0]
              .inventoryPriceType == 'ACTIVE'
          ) {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[0]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[1]
            );
          } else {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[1]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
            );
          }
        } else {
          itemArrayCollection.push({
            inventoryId: Number(
              response.data.inventoryPriceInfo[i].inventoryId
            ),
            inventoryPriceId: Number(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
                .inventoryPriceId
            ),
          });
        }
      }
      setOpenPriceTag(true);
      setnewSingleInvPricingInvArray(itemArrayCollection); // this should be stored in a different array
      //setstoreArrayCollection(arrayCollection);
      //dynamicPopupHandler(arrayCollection);
      //console.log('vfdcdddc12d52c', arrayCollection);
    }
  };

  const deleteSingleItem = async (itemId: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log('Required Item Id', itemId);
    setdeleteId({ ...deleteId, invDeleteId: itemId });
  }; //PS - 23 This method is trigger when an item is deleted in the blue card. This will make the loader visible and store the delete id in a state variable.

  const openItemPricingFail = () => {
    return (
      <>
        <Grid item md={12} className={classes.textCenter}>
          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            <Erroricon />
          </Grid>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Something went wrong in getting Price
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setitemPricingFail(false)}
          >
            OK
          </RACButton>
        </Grid>
      </>
    );
  }; // This method will be opening the Exception message when item pricing get fail.

  const suppiler = () => {
    console.log('response in suppiler');
    console.log('fdgshjhsh');
    props.somedeeptrigger();
  };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const tagSingleInvCaller = async (selectedTag?: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    // let packageParameter = '';
    // if (itemArray.length > 1) {
    //   packageParameter = selectedPackage;
    // }
    console.log('vfdsdd cvfd', newSingleInvPricingInvArray);

    let arrayForm: any;
    if (selectedTag === undefined) {
      arrayForm = newSingleInvPricingInvArray;
    } else {
      arrayForm = [...itemArray, selectedTag];
    }
    console.log('fds wedfd 65454', arrayForm);
    console.log('vfdsx sdcvfd777', selectedTag);
    const response: any = await pricingCall(
      arrayForm,
      null,
      false,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status === 200) {
      const collectPriceArray: any = [];

      response.data.itemPricing.map((obj, index) => {
        collectPriceArray.push({
          inventoryId: Number(obj.inventoryId),
          inventoryPriceId: Number(obj.inventoryPriceId),
        });
      });
      setItemArray(collectPriceArray);
      setitemArray(collectPriceArray);
      console.log('need truncate response', response.data);

      const rawResponse = response.data;
      const alteringObject = rawResponse.itemPricing.filter((item) => {
        return parseInt(item.inventoryId) == storedItemId;
      });
      const calculateInvDepr =
        (Number(gridData[collectIndex].remainingValue) /
          Number(switchOutData.itemPricing[0].agreementTotal)) *
        100;
      const calculateAgrInvDepr =
        ((Number(oldInvRV) + Number(gridData[collectIndex].remainingValue)) /
          Number(switchOutData.agreementPricing.agreementTotal)) *
        100;
      console.log('calculateAgrInvDepr in add', calculateAgrInvDepr);
      console.log('calculateInvDepr in add', calculateInvDepr);
      console.log('Raw Response data', rawResponse);

      // const newItemPricingArray = rawResponse.itemPricing.slice(-1);
      rawResponse.itemPricing = alteringObject;
      rawResponse.itemPricing[0].agreementRate =
        switchOutData.itemPricing[0].agreementRate;
      rawResponse.itemPricing[0].agreementTotal =
        switchOutData.itemPricing[0].agreementTotal;
      rawResponse.agreementPricing.agreementTerm =
        switchOutData.agreementPricing.agreementTerm;
      rawResponse.agreementPricing.tagRate =
        switchOutData.agreementPricing.tagRate;
      rawResponse.agreementPricing.tagTerm =
        switchOutData.agreementPricing.tagTerm;
      rawResponse.agreementPricing.tagTotal =
        switchOutData.agreementPricing.tagTotal;
      rawResponse.agreementPricing.agreementRate =
        switchOutData.agreementPricing.agreementRate;
      rawResponse.agreementPricing.agreementTotal =
        switchOutData.agreementPricing.agreementTotal;
      rawResponse.agreementPricing.cashPrice =
        switchOutData.agreementPricing.cashPrice; //Here the Api response is being destructured and the existing value from the Switch Out item is added
      rawResponse.agreementPricing.agreementInventoryDepreciation =
        calculateAgrInvDepr.toFixed(2);
      rawResponse.itemPricing[0].inventoryDepreciation =
        calculateInvDepr.toFixed(2);
      console.log('switchOutData in add item', switchOutData);
      console.log('Manipulated Data', rawResponse);

      setInternalContextProvider(response.data); //Here the API response is assigned to the context to get access by the added item pane.
      setaddedItem(response.data);
      // ContextSkeleton(
      //   response.data,
      //   newItemArray,
      //   setTestInput,
      //   customerId,
      //   priceController
      // ); //This method will be providing the data to the context setter of agreement details page.

      console.log('newItemArray in add item', collectPriceArray);
      setfooterControlData(false); //This method will be enabling the Next button in the footer.
      if (bucketCopy.length == 0) {
        setBucketCopy([gridData[collectIndex]]); //Newly added item details is pushed to the bucketCopy
        setSwitchoutcopyArrayData([gridData[collectIndex]]);
        const newTableData = gridData.filter((item, filterIndex) => {
          return filterIndex != collectIndex;
        }); //The added item is removed from the Search result and new data is used to bind the result.

        if (newTableData.length <= 0) {
          setresultController({
            ...resultControllerObj,
            controlEmptyRecord: true,
          });
        } //If the search result becomes empty, then No item to add is displayed using this function.

        setsearchResult(newTableData);
        console.log('ItemMainAry', newTableData);
        setGridData(newTableData); //Here for the first time when an item is added, the added item is moved to the bucketCopy and it is removed from the Search Result grid
      } else if (bucketCopy.length == 1) {
        setBucketCopy([gridData[collectIndex]]); //Newly added item details is pushed to the bucketCopy

        const newTableData = gridData.filter((item, filterIndex) => {
          return filterIndex != collectIndex;
        }); //The added item is removed from the Search result and new data is used to bind the result.

        if (newTableData.length <= 0) {
          setresultController({
            ...resultControllerObj,
            controlEmptyRecord: true,
          });
        } //If the search result becomes empty, then No item to add is displayed using this function.
        let alteredTableData: any = [];
        alteredTableData = [...newTableData, bucketCopy[0]];
        setsearchResult(alteredTableData);
        console.log('ItemMainAry', newTableData);
        setGridData(alteredTableData);
        // let alteredTableData: any = [];
        // alteredTableData = [...newTableData, bucketCopy[0]];
        // setsearchResult(newTableData); //This will be adding the deleted item back to the Search result so that the user can add that again
        // console.log('ItemMainAry', newTableData);
        // setGridData(newTableData);
      }
      console.log('copy', bucketCopy);
      console.log('tabledata.filter', gridData);
      setShowAddedItem(true); //This will be making the Added Item Pane visible.
    }
  };

  const triggerNextModal = (inventoryId, inventoryPriceId, decider) => {
    console.log('sdfbvfdfv inventoryId sd', inventoryId);
    console.log('sdfdedcx inventoryPriceId bvc', inventoryPriceId);

    setnewSingleInvPricingInvArray([
      ...newSingleInvPricingInvArray,
      {
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      },
    ]);

    // setnewSingleInvPricingInvArray({
    //   inventoryId: Number(inventoryId),
    //   inventoryPriceId: Number(inventoryPriceId),
    // });
    setOpenPriceTag(false);
    if (decider === 'Printed') {
      setSecondFactorEnabled(true);
    } else if (decider === 'Active') {
      tagSingleInvCaller({
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      });
    }
  };

  const openPriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              marginRight: '48px',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
          >
            Inventory # : {tagBlueLabel.inventoryNumber}
          </Typography>
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              tagBlueLabel?.inventoryDesc?.length > 22
                ? tagBlueLabel?.inventoryDesc
                : ''
            }
          >
            Description : {truncString(tagBlueLabel.inventoryDesc, 22, '...')}
          </Typography>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${leftValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {leftValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${leftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${leftValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    leftValue.inventoryPriceId,
                    'Active'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '200px',
              right: '320px',
              top: '140px',
              marginTop: '15px',
            }}
          ></Divider>
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${rightValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {rightValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${rightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${rightValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    rightValue.inventoryPriceId,
                    'Printed'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
  };

  const closeSLA = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    // if (isPackageFlow) {
    //   setopenPackagePriceTag(true);
    // } else {
    setOpenPriceTag(true);
    // }
  };

  const SLACompleted = (value: any) => {
    setSecondFactorEnabled(false);
    // if (isPackageFlow) {
    //   tagPackageInvCaller('PRTD');
    // } else {
    tagSingleInvCaller();
    // }
  };

  return (
    <div className={`${classes.agreementContainerSpacing} ${showItemSearch}`}>
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={dynamicViewClass}>
          <Grid item className={altersearch}>
            <SearchFilter
              searchClick={searchClick}
              IsPackage={true}
              throwStyle={classes}
            />
            <div>
              {loaderBundle.loaderController === true &&
              loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />
              ) : (
                gridCallMethod()
              )}
            </div>
          </Grid>
          {showAddedItem ? (
            <Usercontext.Provider value={internalContextData}>
              <AddedItem
                triggeroneDelete={deleteSingleItem}
                module="Agreement"
                throwStyle={classes}
              />
            </Usercontext.Provider>
          ) : null}
        </Grid>

        <FooterContent
          setItemsearchCompleted={props.setItemSearchCompleted}
          controlData={footerControlData}
          throwStyle={classes}
          throwDepr={addedItem}
          func={pull_data}
          sometrigger={suppiler}
        />
      </div>
      {SecondFactorEnabled === true ? (
        <SecondFactor
          // setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCancelClick={SLACancel}
          // setTwoFactorCompleted={setTwoFactorCompleted}
          setTwoFactorCompleted={SLACompleted}
          closeSLAClicked={closeSLA}
          // setRoleFromSLAModule={provideSLAData}
          moduleName={ModuleName}
          currentRole={CurrentRole}
          // minRole={SLAMinRole}
          throwCurrentPage="ItemSearch"
        />
      ) : null}
      <RACModalCard
        isOpen={itemPricingFail}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={openItemPricingFail()}
      />
      <RACModalCard
        isOpen={openPriceTag}
        maxWidth={'sm'}
        borderRadius={'25px !important'}
        title={'Please choose the price'}
        closeIcon={true}
        onClose={() => setOpenPriceTag(false)}
        //eslint-disable-next-line react/no-children-prop
        children={openPriceModal()}
      ></RACModalCard>
    </div>
  );
};

export default SwitchOutSearchResult;
