/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@rentacenter/racstrap';
import { searchCriteriaStyles } from '../../../JSstyles/searchCriteriaStyles';
import { getSearchParams, getPackageDropdown } from '../../../api/user';
import { SearchInventoryObj } from './itemSearchProps';
import { AgreementContext } from '../../../context/AgreementContext';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-btn.svg';

export type Componentprops = {
  inventoryId?: number;
  searchClick: (
    txtValue: SearchInventoryObj['itemObject'],
    source: any
  ) => void;
  clearbtnClick: (event: any) => void;
  // PackagedropdownOnClick: (value: any) => void;
  IsPackage?: boolean;
  triggerItemPriceByData: (event: any) => Promise<any>;
  throwStyle: any;
  throwSalesLead: any;
  throwPackage: any;
  throwSelectedPackage: (event: any) => void;
  throwItemArray: any;
  throwpackageList: any;
};
/* eslint-disable sonarjs/cognitive-complexity */
export default function SearchFilter(props: Componentprops) {
  const classes = props.throwStyle;
  const searchCriteriaClassName = searchCriteriaStyles();
  const salesLeadProps = props.throwSalesLead;

  const {
    //packageDataContext,
    agreementCreateStatus,
    searchCriteria,
    setsearchCriteria,
    containDepartment,
    containSubDepartment,
    containCondition,
    containPackage,
    setcontainDepartment,
    setcontainSubDepartment,
    setcontainCondition,
    setcontainPackage,
    setcontainCompetitor,
  } = useContext(AgreementContext);

  //const [getpackageData, setpackageData] = packageDataContext;
  const [controlViewMode, setcontrolViewMode] = useState(false);
  const [PackageDropValuesToBind, setPackageDropValuesToBind] = useState<any>();
  const [conditionAry, setconditionAry] = useState<any>([]);
  const [packageTypeAry, setpackageTypeAry] = useState<any>([]);
  const [departmentAry, setdepartmentAry] = useState<any>([]);
  const [subdepartmentAry, setsubdepartmentAry] = useState<any>([]);
  const [conditionLoader, setconditionLoader] = useState<boolean>(true);
  const [packageconditionLoader, setPackageconditionLoader] =
    useState<boolean>(true);
  const [packageErrorMessage, setpackageErrorMessage] = useState('');
  const initialTxtValues: SearchInventoryObj['itemObject'] = {
    itemNo: '',
    serialNo: '',
    bracket: '',
    brand: '',
    model: '',
    poNo: 0,
    locationTypeVal: 'Store',
    locationval: '986',
    statusId: 1,
    subStatusId: 2,
    conditionId: null,
    departments: '',
    subDepartments: '',
    packageTypeval: '',
    locationNumber: '',
    currentStoreNumber: '',
  };

  const [TxtValues, setTxtValues] =
    useState<SearchInventoryObj['itemObject']>(initialTxtValues);

  const [packagedrpdwn, setpackagedrpdwn] = useState(false);
  const [searchbtndisable, setsearchbtndisable] = useState(true);
  const [clrbtndisable, setclrbtndisable] = useState(false);
  const [packPerDiscount, setpackPerDiscount] = useState<any>('');
  const [packDollarDiscount, setpackDollarDiscount] = useState<any>('');

  useEffect(() => {
    if (salesLeadProps !== undefined) {
      console.log('tiggered the salesLead', salesLeadProps);
      let leadPackageValue = '';
      if (packageTypeAry !== undefined) {
        packageTypeAry.map((value: any) => {
          if (
            salesLeadProps.packageTypeval?.toLowerCase() ==
            value.packageName?.toLowerCase()
          ) {
            leadPackageValue = value.packageName;
          }
        });
      }
      setTxtValues({
        ...TxtValues,
        packageTypeval:
          salesLeadProps.packageTypeval !== undefined ? leadPackageValue : '',
        itemNo:
          salesLeadProps.itemNo !== undefined ? salesLeadProps.itemNo : '',
        model: salesLeadProps.model !== undefined ? salesLeadProps.model : '',
      });
    }
  }, [salesLeadProps]);

  useEffect(() => {
    console.log('packageTypeAry hkdbvjhbsdhskbjds', packageTypeAry);

    if (packageTypeAry !== undefined) {
      // props.receivePackage(TxtValues.packageTypeval);
      packageTypeAry.map((value: any) => {
        if (TxtValues.packageTypeval == value.packageName) {
          // eslint-disable-next-line no-console
          console.log('Came inside package type if');

          setpackPerDiscount(value.pctDiscountOnRate);
          setpackDollarDiscount(value.dollarDiscountOnRate);
        }
        setpackagedrpdwn(true);
      });
      /* eslint-disable no-console */
      console.log('packPerDiscount' + packPerDiscount);
      /* eslint-disable no-console */
      console.log('packDollarDiscount' + packDollarDiscount);
    }
    if (TxtValues.packageTypeval !== '') {
      setpackagedrpdwn(true);
    } else {
      setpackagedrpdwn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TxtValues.packageTypeval]);

  useEffect(() => {
    console.log('reached package rebind useeffect', props.throwpackageList);
    if (
      props.throwpackageList !== undefined &&
      props.throwpackageList !== null
    ) {
      //getpackageData !== undefined && getpackageData !== null
      setPackageconditionLoader(true);

      console.log('reached package rebind');
      // const buildPackage = getpackageData;
      const buildPackage = props.throwpackageList;
      console.log('buildPackage data', buildPackage);
      const setterPackage = [
        { packagePriceId: '0', packageName: 'Select' },
        ...buildPackage,
      ];
      setpackageTypeAry(setterPackage);
      console.log('expexted TxtValues', TxtValues);

      if (props.throwPackage !== null && props.throwPackage !== undefined) {
        setTxtValues({
          ...TxtValues,
          packageTypeval: props.throwPackage,
        });
        setpackagedrpdwn(true);
      }
      setPackageconditionLoader(false);
    } else if (
      props.throwpackageList === null ||
      props.throwpackageList === undefined
    ) {
      //getpackageData === null || getpackageData === undefined
      console.log('reached package original bind');
      console.log('containPackage to be get check', containPackage);

      const getPackage_APIcall = async () => {
        const currentStore = sessionStorage.getItem('storeNumber');
        const packageReq: any = {
          storeNumber: currentStore,
        };
        let packageresponse: any = [];
        if (
          PackageDropValuesToBind == undefined &&
          agreementCreateStatus.agreementCreatedStatus === false
        ) {
          setPackageconditionLoader(true);
          if (containPackage == undefined) {
            packageresponse = await getPackageDropdown(packageReq);
            setPackageDropValuesToBind(packageresponse);
            setcontainPackage(packageresponse);
            // eslint-disable-next-line sonarjs/no-collapsible-if
            console.log('packageresponse', packageresponse?.data?.length);
            setPackageconditionLoader(false);
          } else {
            setPackageDropValuesToBind(containPackage);
            setPackageconditionLoader(false);
            packageresponse = containPackage;
            setcontainPackage(containPackage);
          }
        } else if (PackageDropValuesToBind !== undefined) {
          packageresponse = PackageDropValuesToBind;
        } else {
          setPackageconditionLoader(false);
        }
        //packageresponse = await getPackageDropdown(packageReq);
        // eslint-disable-next-line sonarjs/no-collapsible-if
        //setPackageconditionLoader(false);
        console.log('packageresponse final result', packageresponse);

        if (
          packageresponse !== undefined &&
          packageresponse?.data?.length !== undefined
        ) {
          // if (packageresponse.data.length > 1) {
          const buildPackage = packageresponse.data;
          const setterPackage = [
            { packagePriceId: '0', packageName: 'Select' },
            ...buildPackage,
          ];
          setpackageTypeAry(setterPackage);
          setPackageconditionLoader(false);
          if (setterPackage?.length === 1) {
            setpackageErrorMessage('No package type found');
          }
          console.log('expexted TxtValues', TxtValues);
          // if (TxtValues.packageTypeval !== '') {
          //   setTxtValues({
          //     ...TxtValues,
          //     packageTypeval: '',
          //   });
          // }
        } else {
          const setterPackage = [
            { packagePriceId: '0', packageName: 'Select' },
          ];
          setpackageTypeAry(setterPackage);
          if (packageresponse.status == 200) {
            setpackageErrorMessage('No package type found');
          } else if (
            packageresponse.status == 500 ||
            packageresponse.status == 400
          ) {
            setpackageErrorMessage('Unable to fetch data');
          }
          setPackageconditionLoader(false);
        }
      };
      getPackage_APIcall();
    }
  }, [props.throwpackageList, props.throwPackage]);
  //getpackageData

  useEffect(() => {
    /* eslint-disable no-console */
    console.log('session' + TxtValues);
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({ ...TxtValues, locationNumber: currentStore });
    console.log('containDepartment to be check', containDepartment);
    console.log('containSubDepartment to be check', containSubDepartment);
    console.log('containCondition to be check', containCondition);

    const getSearchParams_APIcall = async () => {
      setconditionLoader(true);
      const response: any = await getSearchParams();
      console.log('response on the all dd', response);

      setconditionLoader(false);
      if (response.status == 200) {
        const buildConditionOption = response.data.conditionList;
        const setterCondition = [
          { id: '0', referenceCode: 'ALL' },
          ...buildConditionOption,
        ];
        console.log('buildConditionOption test log', buildConditionOption);
        console.log('setterCondition test log', setterCondition);

        setconditionAry(setterCondition);
        setcontainCondition(setterCondition);
        const buildDepartOption = response.data.departmentList;
        const setterDepartment = [
          { id: '0', description: 'Select' },
          ...buildDepartOption,
        ];
        setdepartmentAry(setterDepartment);
        setcontainDepartment(setterDepartment);

        const buildSubDepartOption = response.data.subDepartmentList;
        const setterSubDepartment = [
          { id: '0', description: 'Select' },
          ...buildSubDepartOption,
        ];

        const buildCompetitor = response.data.competitorList;
        const setterCompetitor: any = [
          { description: 'Select', referenceCode: '0' },
          ...buildCompetitor,
        ];
        console.log('buildCompetitor in testing', buildCompetitor);
        console.log('setterCompetitor in test', setterCompetitor);

        setcontainCompetitor(setterCompetitor);
        setsubdepartmentAry(setterSubDepartment);
        setcontainSubDepartment(setterSubDepartment);
      } else if (response.status == 500 || response.status == 400) {
        const setterDepartment = [{ id: '0', description: 'Select' }];
        const setterSubDepartment = [{ id: '0', description: 'Select' }];
        const setterCondition = [{ id: '0', referenceCode: 'Select' }];
        setconditionAry(setterCondition);
        setdepartmentAry(setterDepartment);
        setsubdepartmentAry(setterSubDepartment);
      }
    };
    if (
      agreementCreateStatus.agreementCreatedStatus === false &&
      containDepartment == undefined &&
      containSubDepartment == undefined &&
      containCondition == undefined
    ) {
      getSearchParams_APIcall();
    } else if (
      agreementCreateStatus.agreementCreatedStatus === false &&
      containDepartment !== undefined &&
      containSubDepartment !== undefined &&
      containCondition !== undefined
    ) {
      setconditionAry(containCondition);
      setdepartmentAry(containDepartment);
      setsubdepartmentAry(containSubDepartment);
      setconditionLoader(false);
    } else {
      const setterCondition = [
        { id: '0', referenceCode: 'ALL' },
        { id: '1', referenceCode: 'None' },
      ];
      setconditionAry(setterCondition);
      const setterDepartment = [
        { id: '0', description: 'Select' },
        { id: '1', description: 'None' },
      ];
      setdepartmentAry(setterDepartment);
      const setterSubDepartment = [
        { id: '0', description: 'Select' },
        { id: '1', description: 'None' },
      ];
      setsubdepartmentAry(setterSubDepartment);
      const setterPackage = [
        { packagePriceId: '0', packageName: 'Select' },
        { packagePriceId: '1', packageName: 'None' },
      ];
      setpackageTypeAry(setterPackage);
      setconditionLoader(false);
    }
    //getSearchParams_APIcall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      TxtValues.bracket.length > 2 ||
      TxtValues.brand.length > 0 ||
      TxtValues.model.length > 2 ||
      TxtValues.itemNo.length > 2 ||
      TxtValues.serialNo.length > 2 ||
      TxtValues.conditionId !== null ||
      TxtValues.departments !== '' ||
      TxtValues.subDepartments !== '' ||
      TxtValues.packageTypeval !== ''
    ) {
      setsearchbtndisable(false);
      setclrbtndisable(true);
    } else {
      setsearchbtndisable(true);
      setclrbtndisable(false);
    }
    if (TxtValues.packageTypeval !== '') {
      setpackagedrpdwn(true);
    } else {
      setpackagedrpdwn(false);
    }
    console.log('TxtValues is updated as', TxtValues);
  }, [TxtValues]);

  useEffect(() => {
    if (searchCriteria !== undefined && agreementCreateStatus !== undefined) {
      if (agreementCreateStatus.agreementCreatedStatus === false) {
        setTxtValues({
          ...TxtValues,
          itemNo: searchCriteria.itemNo,
          bracket: searchCriteria.bracket,
          model: searchCriteria.bracket,
          brand: searchCriteria.brand,
          departments: searchCriteria.departments,
          subDepartments: searchCriteria.subDepartments,
          packageTypeval: searchCriteria.packageTypeval,
        });
      } else {
        setcontrolViewMode(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dynamicViewClass = controlViewMode ? classes.disableme : 'enableme';

  const EnterOnClick = (e: any) => {
    if (
      e.key == 'Enter' &&
      (TxtValues.bracket.length > 2 ||
        TxtValues.brand.length > 2 ||
        TxtValues.model.length > 2 ||
        TxtValues.itemNo.length > 2 ||
        TxtValues.serialNo.length > 2 ||
        TxtValues.conditionId !== null ||
        TxtValues.departments !== '' ||
        TxtValues.subDepartments !== '' ||
        TxtValues.packageTypeval !== '')
    ) {
      SearchOnclick();
    }
  };

  const clearbtn = () => {
    props.clearbtnClick(true);
    const currentStore = sessionStorage.getItem('storeNumber');
    setTxtValues({
      ...TxtValues,
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: 0,
      locationTypeVal: 'Store',
      locationval: '986',
      statusId: 1,
      subStatusId: 2,
      departments: '',
      subDepartments: '',
      packageTypeval: '',
      locationNumber: currentStore,
      currentStoreNumber: '',
      rateDiscount: 9.99,
      totalCashPriceDiscount: 0.0,
      conditionId: null,
    });
    setclrbtndisable(false);
    setsearchbtndisable(true);
    props.throwSelectedPackage('');
  };
  /*
   * RF No: IS_PC_32
   * This method is passing the values to the ApiLayer to get the filtered data from database.
   * */
  const SearchOnclick = () => {
    (document.getElementById('a11y_Item') as HTMLInputElement).blur();
    (document.getElementById('a11y_bracket') as HTMLInputElement).blur();
    (document.getElementById('a11y_Model') as HTMLInputElement).blur();
    (document.getElementById('a11y_Brand') as HTMLInputElement).blur();
    (document.getElementById('a11y_Serial') as HTMLInputElement).blur();
    props.searchClick(TxtValues, 'searchButtonClick');
    /* eslint-disable no-console */
    console.log(TxtValues);
    setsearchCriteria(TxtValues);
    props.throwSelectedPackage(TxtValues.packageTypeval);
  };

  const packageChange = async (e: any) => {
    //console.log('selected package',e.targe.value);
    if (e.target.value !== 'Select') {
      if (props.throwItemArray?.length > 1) {
        const apiResponse: any = await props.triggerItemPriceByData(
          e.target.value
        );
        // props.throwSelectedPackage(e.target.value);
        console.log('apiResponse checking log test log', apiResponse);

        if (apiResponse.status == 200) {
          console.log('successful item call');
          setTxtValues({
            ...TxtValues,
            packageTypeval: e.target.value,
          });
          props.throwSelectedPackage(e.target.value);
        } else {
          if (e.target.value == 'Select') {
            setTxtValues({ ...TxtValues, packageTypeval: '' });
          } else {
            setTxtValues({ ...TxtValues, packageTypeval: e.target.value });
          }
        }
      } else {
        setTxtValues({ ...TxtValues, packageTypeval: e.target.value });
      }
    } else {
      setTxtValues({ ...TxtValues, packageTypeval: '' });
      props.throwSelectedPackage('');
    }
  };

  const optionsArrayFunction = () => {
    console.log('checking call of vdjsvbhd', departmentAry);

    if (departmentAry !== undefined) {
      return departmentAry.map((entry) => {
        console.log('did i reach here optionsArrayFunction map');

        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const conditionArrayFunction = () => {
    console.log('nbhngfbfhngbfdgbf check', conditionAry);

    if (conditionAry !== undefined) {
      return conditionAry.map((value: any, index: any) => {
        console.log('did i reach here conditionArrayFunction map');

        console.log('Counting log', index);
        let displayvalue: any;
        if (index >= 0) {
          if (value.referenceCode == 'EXCP') {
            displayvalue = 'Exception';
          } else if (value.referenceCode == 'ATO') {
            displayvalue = 'Auto';
          } else if (value.referenceCode == 'NEW') {
            displayvalue = 'New';
          } else if (value.referenceCode == 'ALL') {
            displayvalue = 'All';
          } else if (value.referenceCode == 'Select') {
            displayvalue = 'Select';
          }
          return { label: displayvalue, value: value.id.toString() };
        }
      });
    } else {
      return [{ label: 'All', value: '0' }];
    }
  };

  const subDepartmentArrayFunction = () => {
    console.log('subdepartmentAry hdgvbhjbdsvjhcsd', subdepartmentAry);

    if (subdepartmentAry !== undefined) {
      console.log('reachefdehjvfbdhbv', subdepartmentAry);

      return subdepartmentAry.map((entry: any) => {
        console.log('did i reach here subdept map');

        return { label: entry.description, value: entry.description };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const packageArrayFunction = () => {
    console.log('packageTypeAry check jsdbvjhbds', packageTypeAry);

    if (packageTypeAry !== undefined && packageTypeAry !== null) {
      console.log('packageTypeAry check jsdbvjhbds deep', packageTypeAry);

      return packageTypeAry.map((obj: any) => {
        console.log('did i reach here package map');

        return { label: obj.packageName, value: obj.packageName };
      });
    } else {
      return [{ label: 'Select', value: 'Select' }];
    }
  };

  const handleOnChangeTxtbox = (e: any) => {
    const FieldName = e.target.name;
    const InputValue = e.target.value;

    setTxtValues({ ...TxtValues, [FieldName]: InputValue });
  };

  const handle_OnChangeTxtNumber = (e: any, number: any) => {
    if (e.target.value == '' || number.test(e.target.value)) {
      // handle_OnchangeTxt_errorval(e);
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  };
  const handleOnChangeItemTxtboxOnlyNumber = (e: any) => {
    if (e.target.id == 'a11y_Item') {
      const number = /^[0-9\b]+$/;
      handle_OnChangeTxtNumber(e, number);
    } else if (e.target.id == 'a11y_Serial') {
      setTxtValues({ ...TxtValues, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className={dynamicViewClass}>
      <Grid container className={classes.mt2}>
        <Grid item md={12}>
          {props.IsPackage !== false ? (
            <Typography className={classes.title} variant="h6">
              Search Criteria
            </Typography>
          ) : (
            <Typography className={classes.title} variant="h5">
              Package Item Search
            </Typography>
          )}
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={classes.formLabel}
                    id="a11y_Item"
                    type="number"
                    name="itemNo"
                    isCurrency={false}
                    digitFormat="normal"
                    maxlength={13}
                    value={TxtValues.itemNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={optionsArrayFunction()}
                    defaultValue={
                      TxtValues.departments !== ''
                        ? TxtValues.departments
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                            ...TxtValues,
                            departments: '',
                          })
                        : setTxtValues({
                            ...TxtValues,
                            departments: e.target.value,
                          });
                    }}
                    {...(departmentAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>
                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Sub Department"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={subDepartmentArrayFunction()}
                    defaultValue={
                      TxtValues.subDepartments !== ''
                        ? TxtValues.subDepartments
                        : 'Select'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      e.target.value == 'Select'
                        ? setTxtValues({
                            ...TxtValues,
                            subDepartments: '',
                          })
                        : setTxtValues({
                            ...TxtValues,
                            subDepartments: e.target.value,
                          });
                    }}
                    {...(subdepartmentAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Bracket"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_bracket"
                    name="bracket"
                    isCurrency={false}
                    value={TxtValues.bracket}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Model #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Model"
                    name="model"
                    isCurrency={false}
                    value={TxtValues.model}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Brand"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Brand"
                    name="brand"
                    isCurrency={false}
                    value={TxtValues.brand}
                    OnChange={(e) => handleOnChangeTxtbox(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes.formLabel}
                    type="text"
                    id="a11y_Serial"
                    name="serialNo"
                    maxlength={20}
                    isCurrency={false}
                    value={TxtValues.serialNo}
                    OnChange={(e) => handleOnChangeItemTxtboxOnlyNumber(e)}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Condition"
                    inputLabelClassName={
                      searchCriteriaClassName.dropDownLabelAlign
                    }
                    loading={conditionLoader}
                    options={conditionArrayFunction()}
                    defaultValue={
                      TxtValues.conditionId !== null
                        ? TxtValues.conditionId
                        : '0'
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setTxtValues({
                        ...TxtValues,
                        conditionId: e.target.value.toString(),
                      });
                    }}
                    {...(conditionAry.length == 1 &&
                      !conditionLoader && {
                        errorMessage: 'Unable to fetch Data',
                      })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.mt2}>
                {props.IsPackage === true ? (
                  <>
                    <Grid item md={3}>
                      <RACSelect
                        inputLabel="Package Type"
                        inputLabelClassName={
                          searchCriteriaClassName.dropDownLabelAlign
                        }
                        loading={packageconditionLoader}
                        options={packageArrayFunction()}
                        defaultValue={
                          TxtValues.packageTypeval !== ''
                            ? TxtValues.packageTypeval
                            : 'Select'
                        }
                        onChange={(e) => packageChange(e)}
                        {...(packageTypeAry.length == 1 &&
                          !packageconditionLoader && {
                            errorMessage: packageErrorMessage,
                          })}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      lg={5}
                      className={searchCriteriaClassName.responcol6}
                    >
                      {packagedrpdwn === true ? (
                        <Typography
                          variant="caption"
                          className={searchCriteriaClassName.packageStructure}
                        >
                          <Typography variant="caption">
                            <Typography
                              variant="caption"
                              className={classes.semiBold}
                            >
                              Rate Discount :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.bold}
                            >
                              {' '}
                              {packPerDiscount !== null
                                ? packPerDiscount + '%'
                                : '$' + packDollarDiscount}
                            </Typography>
                          </Typography>

                          <Typography variant="caption">
                            <Typography
                              variant="caption"
                              className={`${classes.ms4} ${classes.semiBold}`}
                            >
                              Total Cash Price Discount :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.bold}
                            >
                              {' '}
                              {/* {TxtValues
                                ? TxtValues.totalCashPriceDiscount
                                : 0.0} */}
                              0.0%
                            </Typography>
                          </Typography>
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid
                      item
                      md={12}
                      lg={4}
                      className={searchCriteriaClassName.responcol12}
                    >
                      <Grid className={`${classes.floatRight} ${classes.mt4}`}>
                        {clrbtndisable ? (
                          <a
                            className={`${classes.racpadLink} ${classes.mt2} ${classes.me4}`}
                            data-testid="ClearBtn"
                            onClick={clearbtn}
                          >
                            Clear
                          </a>
                        ) : null}{' '}
                        <RACButton
                          startIcon={<SearchIcon />}
                          variant="contained"
                          color="primary"
                          className={searchCriteriaClassName.searchBtn}
                          size="large"
                          onClick={() => SearchOnclick()}
                          disabled={searchbtndisable}
                        >
                          {/* <SearchIcon className={classes.me1} /> */}
                          Search
                        </RACButton>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
