/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createContext, useState, useEffect } from 'react';
export const FeatureFlagContext = createContext<any>('');
export function FeatureFlagContextProvider(props: any) {
  const [featureFlagDetails, setFeatureFlagDetails] = useState({});
  const [sacRolesLimitDetails, setSacRolesLimitDetails] = useState({});

  return (
    <div>
      <FeatureFlagContext.Provider
        value={{
          featureFlagDetails,
          setFeatureFlagDetails,
          sacRolesLimitDetails,
          setSacRolesLimitDetails,
        }}
      >
        {props.children}
      </FeatureFlagContext.Provider>
    </div>
  );
}
export function MakeFeatureFlagObjFn(storeValue: any) {
  const tempFeatureFlagObj = {};
  for (let i = 0; i < storeValue.length; i++) {
    tempFeatureFlagObj[storeValue[i].paramKeyName] = storeValue[i].paramValue;
  }
  console.log('tempFeatureFlagObj', tempFeatureFlagObj);
  return tempFeatureFlagObj;
}

export function makeSACrolesAllocationFn(req: any) {
  const paramGroupName = 'SACOverrideLimit';
  const paramCategoryName = 'PriceOverride';
  const sacRoleLimitObj = {};

  for (let i = 0; i < req.length; i++) {
    if (
      req[i].paramCategoryName == paramCategoryName &&
      req[i].paramGroupName == paramGroupName
    ) {
      sacRoleLimitObj[req[i].paramKeyName] = req[i].paramValue;
    }
  }
  return sacRoleLimitObj;
}
