/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  RACModalCard,
  RACButton,
  CircularProgress,
  RACSelect,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACBadge,
  RACTextbox,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import {
  GetCustomer,
  RunDE,
  updatecustomer,
  GetDeReason,
} from '../../api/user';
import { getPrimaryPhoneNumber } from '../../constants/constants';

interface DecisionEngineInterface {
  customerId: string[];
  decisionEngineCompletedFn?: any;
  decisionCanceledFn?: any;
}
export default function DecisionEngine(props: DecisionEngineInterface) {
  const { customerId, decisionEngineCompletedFn, decisionCanceledFn } = props;
  const currentStore = sessionStorage.getItem('storeNumber');
  const classes = agreementGlobalStyles();
  const history = useHistory();
  const popupRadious = '25px !important';
  const defaultDropdownValue = {
    value: '',
    label: 'Select',
  };
  const [getCustomerLoading, setGetCustomerLoading] = useState(false);
  const [deRunLoading, setDeRunLoading] = useState(false);
  const [deDropdownOptions, setDeDropdownOptions] = useState([]);
  const [deReasonValue, setDeReasonValue] = useState('');
  const [updateCusLoading, setUpdateCusLoading] = useState(false);
  const [noDEopen, setNoDEopen] = useState(false);
  const [noDECustomerNames, setNoDECustomerNames] = useState('');
  //const [customerDetailsArr,setcustomerDetailsArr] = useState([]);
  const [ssnCustomerDetails, setSsnCustomerDetails] = useState({
    customerid: '',
    customerName: '',
  });
  const [enterSsnOpen, setEnterSsnOpen] = useState(false);
  const [getSsnOpen, setGetSsnOpen] = useState(false);
  const [ssnTxtValue, setSsnTxtValue] = useState({
    threeDigitSSN: '',
    twoDigitSSN: '',
    fourDigitSSN: '',
  });
  const [deResultOpen, setDEresultopen] = useState(false);
  const [approvalFailOpen, setApprovalFailOpen] = useState(false);
  const [deResultArray, setDEResultArray] = useState([
    {
      customerName: '',
      approvalAmount: '',
      weeklyApprovalAmount: '',
      expiryDate: '',
      deResult: 'A',
    },
    {
      customerName: '',
      approvalAmount: '',
      weeklyApprovalAmount: '',
      expiryDate: '',
      deResult: 'D',
    },
  ]);
  const [noDEcustomerDetails, setNoDEcustomerDetails]: any = useState([]);
  const [customerDetailsArrData, setCustomerDetailsArrData] = useState([]);
  let customerDetailsArr: any = [];
  useEffect(() => {
    setGetCustomerLoading(true);
    const getCustomerResponse: any = getCustomerCall();
    getCustomerResponse.then(function (response: any) {
      console.log('response', response);
      const customerDetailsArray: any = [];
      for (let cusLength = 0; response?.length > cusLength; cusLength++) {
        const obj: any = response[cusLength]?.GetCustomer?.value;
        customerDetailsArray.push(obj);
      }
      customerDetailsArr = customerDetailsArray;
      setCustomerDetailsArrData(customerDetailsArray);
      console.log('customerDetailsArr', customerDetailsArr);
      taxIdCheckForCustomers(customerDetailsArray);
    });
    getDEReason();
  }, []);

  const getCustomerCall = async () => {
    const customerReponse: any = [];
    const getCustomerResponse: any = [];
    await Promise.all(
      customerId?.map(async (customerId: any) => {
        getCustomerResponse.push(await GetCustomer(customerId, currentStore));
      })
    );
    console.log('getCustomerResponse', getCustomerResponse);
    for (
      let cusLength = 0;
      getCustomerResponse?.length > cusLength;
      cusLength++
    ) {
      if (getCustomerResponse[cusLength]?.status == 200) {
        const obj = getCustomerResponse[cusLength].data;
        customerReponse.push(obj);
      }
    }
    setGetCustomerLoading(false);
    return customerReponse;
  };
  const getDEReason = async () => {
    const decisionengineresponse = await GetDeReason();
    if (decisionengineresponse?.status == 200) {
      let reasonValue = decisionengineresponse?.data?.referenceDetails;
      reasonValue = reasonValue?.map((value: any) => {
        return { label: value.description, value: value.referenceCode };
      });
      reasonValue?.splice(0, 0, defaultDropdownValue);
      setDeDropdownOptions(reasonValue);
    }
  };
  const taxIdCheckForCustomers = async (customerDetailsArray: any) => {
    const withOutTaxidArray = customerDetailsArray.filter((obj) => !obj.taxId);
    if (withOutTaxidArray.length > 0) {
      for (
        let customerLength = 0;
        customerLength < customerDetailsArray.length;
        customerLength++
      ) {
        if (!customerDetailsArray[customerLength].taxId) {
          setSsnCustomerDetails({
            ...ssnCustomerDetails,
            customerid: customerDetailsArray[customerLength].customerId,
            customerName: getCustomerNameById(
              customerDetailsArray[customerLength].customerId
            ),
          });
          setEnterSsnOpen(true);
          break;
        }
      }
    } else {
      const runDEresponse: any = [];
      setDeRunLoading(true);
      await Promise.all(
        customerDetailsArray?.map(async (obj: any) => {
          runDEresponse.push({
            response: await RunDE(makerunDEpayload(obj)),
            request: obj,
          });
        })
      );
      setDeRunLoading(false);
      const runDEresponseCheck = runDEresponse.every(
        (obj: any) => obj?.response?.status == 200
      );
      const tempDEresultArray: any = [];
      const tempNoDEreqArray: any = [];
      let noDEcustomers = '';
      for (let length = 0; length < runDEresponse?.length; length++) {
        if (runDEresponse[length]?.response?.status == 200) {
          const runDEData = runDEresponse[length]?.response?.data;
          tempDEresultArray.push({
            customerName: getCustomerNameById(runDEData?.customerId),
            approvalAmount: runDEData?.approvalAmount,
            weeklyApprovalAmount: runDEData?.weeklyApprovalAmount,
            expiryDate: runDEData?.approvalExpirationDate,
            deResult: runDEData?.approvalResponse,
          });
        } else {
          const failedCustomerReq = customerDetailsArray.filter(
            (obj: any) =>
              obj.customerId == runDEresponse[length]?.request?.customerId
          );
          const obj: any = {};
          obj.payload = {
            customerId: failedCustomerReq[0]?.customerId,
            deExceptionReason: '',
            globalCustomerId: failedCustomerReq[0]?.globalCustomerId,
          };
          obj.customerName = getCustomerNameById(
            failedCustomerReq[0]?.customerId
          );
          tempNoDEreqArray.push(obj);
          if (noDEcustomers.length == 0) {
            noDEcustomers = noDEcustomers.concat(
              getCustomerNameById(customerDetailsArray[length]?.customerId)
            );
          } else {
            noDEcustomers = noDEcustomers.concat(
              `, ${getCustomerNameById(
                customerDetailsArray[length]?.customerId
              )}`
            );
          }
        }
      }
      setNoDEcustomerDetails(tempNoDEreqArray);
      setDEResultArray(tempDEresultArray);
      setNoDECustomerNames(noDEcustomers);
      if (runDEresponseCheck) {
        setDEresultopen(true);
      } else {
        setApprovalFailOpen(true);
      }
    }
  };
  const getCustomerNameById = (cusId: any) => {
    if (cusId) {
      const tempArr: any = customerDetailsArr.filter(
        (obj: any) => obj.customerId == cusId
      );
      if (tempArr[0]?.firstName && tempArr[0]?.lastName) {
        return `${tempArr[0]?.firstName} ${tempArr[0]?.lastName}`;
      } else if (tempArr[0]?.firstName && !tempArr[0]?.lastName) {
        return `${tempArr[0]?.firstName}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  const makerunDEpayload = (req: any) => {
    const phoneDetails = getPrimaryPhoneNumber(req?.phones)
    console.log('phoneDetails', phoneDetails)
    const payload: any = {
      firstName: req?.firstName,
      lastName: req?.lastName,
      dateOfBirth: req?.dateOfBirthEnc,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      taxId: req?.taxIdEnc || req?.taxId,
      // languagePreference: req?.preferredLanguage,
      customerId: req?.customerId,
      globalCustomerId: req?.globalCustomerId,
      address: {
        addressType: req?.addresses[0]?.addressType,
        addressLine1: req?.addresses[0]?.addressLine1,
        addressLine2: req?.addresses[0]?.addressLine2,
        city: req?.addresses[0]?.city,
        state: req?.addresses[0]?.state,
        postalCode: req?.addresses[0]?.postalCode,
      },
    };
    if (req?.phones) {
      payload.phone = {
        phoneNumber: phoneDetails[0]?.phoneNumber,
        phoneType: phoneDetails[0]?.phoneType,
        primary: phoneDetails[0]?.primary,
        callTimeType: phoneDetails[0]?.callTimeType,
      };
    }
    return payload;
  };
  const saveandrunDEclick = async () => {
    setGetSsnOpen(false);
    setUpdateCusLoading(true);
    const completeSSNtxt =
      ssnTxtValue.threeDigitSSN +
      ssnTxtValue.twoDigitSSN +
      ssnTxtValue.fourDigitSSN;
    const postnoDE = {
      customerId: ssnCustomerDetails.customerid,
      taxId: completeSSNtxt,
    };
    const updateCustomerResponse = await updatecustomer(postnoDE);
    console.log('updateCustomerResponse', updateCustomerResponse);
    setUpdateCusLoading(false);
    if (updateCustomerResponse.status === 200) {
      const tempCustomerDetailsArr: any = customerDetailsArrData;
      const indexOfUpdate = tempCustomerDetailsArr.findIndex(
        (obj: any) => obj.customerId == ssnCustomerDetails.customerid
      );
      tempCustomerDetailsArr[indexOfUpdate].taxId = completeSSNtxt;
      customerDetailsArr = tempCustomerDetailsArr;
      taxIdCheckForCustomers(tempCustomerDetailsArr);
    }
  };
  const handlessn = (e: any) => {
    let regexItem;
    if (
      e.target.name == 'threeDigitSSN' ||
      e.target.name == 'twoDigitSSN' ||
      e.target.name == 'fourDigitSSN'
    ) {
      regexItem = new RegExp('^[0-9]*$');
    }
    if (e.target.value.match(regexItem)) {
      setSsnTxtValue({ ...ssnTxtValue, [e.target.name]: e.target.value });

      if (e.target.name === 'threeDigitSSN' && e.target.value.length === 3) {
        (document.getElementById('SSN2') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'twoDigitSSN' &&
        e.target.value.length === 2
      ) {
        (document.getElementById('SSN4') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'twoDigitSSN' &&
        e.target.value.length === 0 &&
        ssnTxtValue.threeDigitSSN.length > 0
      ) {
        (document.getElementById('SSN3') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'fourDigitSSN' &&
        e.target.value.length === 0 &&
        ssnTxtValue.threeDigitSSN.length > 0
      ) {
        (document.getElementById('SSN2') as HTMLInputElement).focus();
      }
    }
  };
  const noDEsubmitClick = async () => {
    setNoDEopen(false);
    const noDEpayload: any = noDEcustomerDetails[0].payload;
    noDEpayload.deExceptionReason = deReasonValue;
    setUpdateCusLoading(true);
    const updateCustomerResponse = await updatecustomer(noDEpayload);
    if (updateCustomerResponse.status == 200) {
      setUpdateCusLoading(false);
      const newNoDEcustomerDetails = noDEcustomerDetails.filter(
        (obj: any) => obj?.payload?.customerId != noDEpayload?.customerId
      );
      if (newNoDEcustomerDetails?.length > 0) {
        setNoDEcustomerDetails(newNoDEcustomerDetails);
        setDeReasonValue('');
        setNoDEopen(true);
      } else if (deResultArray?.length > 0) {
        setDEresultopen(true);
      } else {
        decisionEngineCompletedFn();
      }
    } else {
      setUpdateCusLoading(false);
    }
  };
  const enterSSNnoClick = () => {
    setEnterSsnOpen(false);
    setNoDEopen(true);
  };
  const approvalFailedPopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.textCenter} ${classes.pt2}`}>
            Error in approval process for below customers. Please proceed
            without approval.
          </Typography>
          <Typography className={`${classes.textCenter} ${classes.pt2}`}>
            {noDECustomerNames}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setApprovalFailOpen(false);
              setNoDEopen(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const fetchingCustomerInfoPopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Fetching Customers Information
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const deEngineRunningPopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Decision Engine is Running
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const noDEReasonPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.fontHeading}>
            Please specify the reason, why you are not running the decision
            engine for {noDEcustomerDetails[0]?.customerName}?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={deDropdownOptions}
            name="postNODEReason"
            defaultValue={deReasonValue}
            loading={deDropdownOptions?.length > 0 ? false : true}
            onChange={(e: any) => {
              setDeReasonValue(e.target.value);
            }}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              setNoDEopen(false);
              decisionCanceledFn();
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            disabled={deReasonValue !== '' ? false : true}
            onClick={() => {
              noDEsubmitClick();
            }}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const enterSSNPopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.subTitle}`}>
            If you want to run DE, Please enter the SSN for{' '}
            {ssnCustomerDetails.customerName}
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => {
              enterSSNnoClick();
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setEnterSsnOpen(false);
              setGetSsnOpen(true);
            }}
          >
            Next
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const Getssn = () => {
    return (
      <Grid>
        <Grid>
          <Typography variant="caption" className={classes.formLabel}>
            Social Security# / ITIN#
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <RACTextbox
                placeHolder={'xxx'}
                type="password"
                isCurrency={false}
                value={ssnTxtValue.threeDigitSSN}
                maxlength={3}
                name="threeDigitSSN"
                id="SSN3"
                OnChange={(e) => handlessn(e)}
              />
            </Grid>
            <Grid item md={2}>
              <RACTextbox
                type="password"
                placeHolder={'xx'}
                isCurrency={false}
                value={ssnTxtValue.twoDigitSSN}
                digitFormat="normal"
                maxlength={2}
                name="twoDigitSSN"
                id="SSN2"
                OnChange={(e) => handlessn(e)}
              />
            </Grid>
            <Grid item md={3}>
              <RACTextbox
                type="text"
                placeHolder={'xxxx'}
                isCurrency={false}
                value={ssnTxtValue.fourDigitSSN}
                maxlength={4}
                name="fourDigitSSN"
                id="SSN4"
                OnChange={(e) => handlessn(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.w100} ${classes.mt15} ${classes.mt3} ${classes.textRight}`}
        >
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push(
                `/customer/update/${ssnCustomerDetails.customerid}/customer`
              )
            }
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            disabled={
              !(
                ssnTxtValue.threeDigitSSN.length == 3 &&
                ssnTxtValue.twoDigitSSN.length == 2 &&
                ssnTxtValue.fourDigitSSN.length == 4
              )
            }
            onClick={() => saveandrunDEclick()}
          >
            Save & Run DE
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const updateCustomerLoadingPopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Updating customers information
          </Typography>
          <Typography
            className={`${classes.greyFont} ${classes.font12} ${classes.mt2}`}
          >
            Please wait
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const deBadgeBind = (deResult: any) => {
    switch (deResult) {
      case '1':
        return (
          <RACBadge
            backgroundColor="#E8F5E9"
            color="#43A047"
            OnclickNeed={false}
            TextValue="DE Approved"
            handleOnClick={() => {
              console.log('DE Approved');
            }}
            className={classes.deResultsBadge}
          />
        );
      case '2':
        return (
          <RACBadge
            backgroundColor="#FFEBEE"
            color="#FF0000"
            OnclickNeed={false}
            TextValue="DE Declined"
            handleOnClick={() => {
              console.log('DE Declined');
            }}
            className={classes.deResultsBadge}
          />
        );
      case '4':
        return (
          <RACBadge
            backgroundColor="#FFF3E0"
            color="#F57F17"
            OnclickNeed={false}
            TextValue="Conditionally Approved"
            handleOnClick={() => {
              console.log('Conditionally Approved');
            }}
            className={classes.deResultsBadge}
          />
        );
      case '5':
        return (
          <RACBadge
            backgroundColor="#FFA500"
            color="#fff"
            OnclickNeed={false}
            TextValue="Conditionally Approved"
            handleOnClick={() => {
              console.log('Conditionally');
            }}
            className={classes.deResultsBadge}
          />
        );
      case '':
        return (
          <RACBadge
            backgroundColor="#FF0000"
            color="#fff"
            OnclickNeed={false}
            TextValue="No DE"
            handleOnClick={() => {
              console.log('No DE');
            }}
            className={classes.deResultsBadge}
          />
        );
    }
  };
  function formatMoney(Money: any) {
    console.log('Money', Money);
    if (Money) {
      const numMoneny = Number(Money);
      const strMoney = String(numMoneny.toFixed(2));
      let MoneyFormat = strMoney.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (MoneyFormat === '0') {
        MoneyFormat = `${MoneyFormat}.00`;
      }
      return MoneyFormat;
    } else {
      return '';
    }
  }
  const dateConversion = (value: any) => {
    if (value) {
      const strArr = value.split('-');
      return `${strArr[1]}/${strArr[2]}/${strArr[0]}`;
    } else {
      return '-';
    }
  };
  const deResultHeadfn = () => (
    <>
      <RACTableCell>Customer Name </RACTableCell>
      <RACTableCell style={{ textAlign: 'right' }}>
        Approval Amount
      </RACTableCell>
      <RACTableCell style={{ textAlign: 'right' }}>
        Weekly Approval Amount{' '}
      </RACTableCell>
      <RACTableCell style={{ textAlign: 'right' }}>Expiry Date </RACTableCell>
      <RACTableCell style={{ textAlign: 'center' }}>Status </RACTableCell>
    </>
  );
  const deResultBodyfn = () => (
    <>
      {deResultArray?.map((obj: any, index: any) => {
        return (
          <RACTableRow key={index} hover backgroundColor="white">
            <RACTableCell key={index}>{obj.customerName}</RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'right' }}>
              {obj?.approvalAmount
                ? `$ ${formatMoney(obj.approvalAmount)}`
                : '-'}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'right' }}>
              {obj?.weeklyApprovalAmount
                ? `$ ${formatMoney(obj.weeklyApprovalAmount)}`
                : '-'}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'right' }}>
              {dateConversion(obj.expiryDate)}
            </RACTableCell>
            <RACTableCell key={index} style={{ textAlign: 'right' }}>
              {deBadgeBind(obj.deResult)}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );
  const deResultPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <RACTable
            className={`${classes.racGrid} ${classes.mb32}`}
            renderTableHead={deResultHeadfn}
            renderTableContent={deResultBodyfn}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setDEresultopen(false);
              decisionEngineCompletedFn();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <RACModalCard
        isOpen={getCustomerLoading}
        maxWidth="xs"
        borderRadius={popupRadious}
      >
        {fetchingCustomerInfoPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={deRunLoading}
        maxWidth="xs"
        borderRadius={popupRadious}
      >
        {deEngineRunningPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={enterSsnOpen}
        maxWidth="xs"
        borderRadius={popupRadious}
      >
        {enterSSNPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={getSsnOpen}
        maxWidth="xs"
        borderRadius={'25px !important'}
        title={'Please enter the SSN number'}
      >
        {Getssn()}
      </RACModalCard>
      <RACModalCard
        isOpen={updateCusLoading}
        maxWidth="xs"
        borderRadius={popupRadious}
      >
        {updateCustomerLoadingPopup()}
      </RACModalCard>
      <RACModalCard isOpen={noDEopen} maxWidth="xs" borderRadius={popupRadious}>
        {noDEReasonPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={deResultOpen}
        closeIcon={true}
        maxWidth="md"
        borderRadius={popupRadious}
        title={'Decision Engine Results'}
        onClose={() => {
          setDEresultopen(false);
          decisionEngineCompletedFn();
        }}
      >
        {deResultPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={approvalFailOpen}
        maxWidth="xs"
        borderRadius={popupRadious}
      >
        {approvalFailedPopup()}
      </RACModalCard>
    </>
  );
}
