/* eslint-disable*/
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-unused-collection */
import React, { useEffect, useState, useContext } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { pendingAgreementStyles } from '../../JSstyles/pendingAgreementStyles';
import { Card, CardContent, Grid, RACButton, RACCheckBox, RACModalCard, RACRadio, RACSelect, RACTable, RACTableCell, RACTableRow, RACTextbox, Typography, List, ListItem, CircularProgress } from "@rentacenter/racstrap";
import { agreementGlobalStyles } from "../../JSstyles/agreementGlobalStyles";
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { reversePaymentStyle } from "../../JSstyles/reversePaymentStyle";
// import { ReactComponnet as AlertIcon} from '../../assets/images/'
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import moment from 'moment';

import { getStoreActionsItem } from '../../../src/api/user';


export default function PendingAgreement() {

    const history = useHistory();
    const pendingAgreementStyle = pendingAgreementStyles();
    const classes = agreementGlobalStyles();
    const revereseclass = reversePaymentStyle();

    const [noRecords, setnoRecords] = useState(false);
    const [transferRequestTableLoader, settransferRequestTableLoader] = useState(false);
    const [pendingAgreemntValues, setpendingAgreemntValues] = useState<any>([]);
    const [errorpopup, seterrorpopup] = useState(false);
    const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
    const [dropdownvalue, setdropdownvalue] = useState('PEND,PRELIM,PRELIMINV,PRELIMPAID,PRELIMRFND');
    const [hasMore, sethasMore] = useState(true);
    const [offset, setoffset] = useState(0);
    const [fetchDataLoader, setfetchDataLoader] = useState(false);
    const [NomoreDataOtherReq, setNomoreDataOtherReq] = useState(false);
    //const [enableloader, setenableloader] = useState(false);

    useEffect(() => {
        console.log('dropdownvalues', { dropdownvalue })
        pendingAgreement();
    }, [dropdownvalue]);

    const [options, setOptions] = useState([
        {
            label: 'All',
            value: 'PEND,PRELIM,PRELIMINV,PRELIMPAID,PRELIMRFND'
        },
        {
            label: 'Pending',
            value: 'PEND'
        },
        {
            label: 'Preliminary',
            value: 'PRELIM'
        },
        {
            label: 'Preliminary Inventory',
            value: 'PRELIMINV'
        },
        {
            label: 'Preliminary Paid',
            value: 'PRELIMPAID'
        },
        {
            label: 'Preliminary Refunded',
            value: 'PRELIMRFND'
        },

    ])

    const optionsArrayFunction = (options: any) => {
        if (options !== undefined) {
            return options.map((opt: any) => {
                return { label: opt.label, value: opt.value };
            });
        } else {
            return [{ label: 'Select', value: 'Select' }];
        }
    };

    const fetchNextData = async () => {
        debugger
        sethasMore(true);
        setfetchDataLoader(true);
        setoffset((prev) => prev + 1);

        const payload = {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            limit: 25,
            offset: offset + 1,
            agreementStatus: dropdownvalue
        }
        console.log('payload for pending Agreement', { payload })
        const fetchNextDataRes = await getStoreActionsItem(payload);

        if (fetchNextDataRes?.status == 200) {
            setfetchDataLoader(false);
            const fetchNexyDataArray = fetchNextDataRes?.data?.agreementDetails;
            setpendingAgreemntValues([
                ...pendingAgreemntValues,
                ...fetchNexyDataArray,
            ]);

            if (fetchNextDataRes?.data?.agreementDetails?.length !== 25) {
                sethasMore(false);
                setNomoreDataOtherReq(true);
            }
        }
        else {
            seterrorpopup(true);
            setsomethingWentWrongOpen(true);
        }
        //else if (fetchNextDataRes?.status == 400) {
        //     seterrorMessagePopupOpen(true);
        //     setmanageAgrErrMessage(fetchNextDataRes?.data?.errors[0]?.error);
        // } else {
        //     setSomethingWentWrongOpen(true);
        // }
    }

    const pendingAgreement = async () => {
        settransferRequestTableLoader(true);
        setnoRecords(true)
        debugger
        const payload = {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            limit: 25,
            offset: offset,
            agreementStatus: dropdownvalue
        }
        console.log('payload for pending Agreement', { payload })
        const response = await getStoreActionsItem(payload)
        console.log('response', { response })
        debugger;

        if (response?.status === 200) {
            console.log('response data', response.data?.agreementDetails)

            setpendingAgreemntValues(response.data.agreementDetails);
            setnoRecords(false)
            seterrorpopup(false)
        }
        else {
            seterrorpopup(true);
            setnoRecords(false);
            setsomethingWentWrongOpen(true);

        }
        settransferRequestTableLoader(false)
    }

    const pendingAgreementHeader = [
        { heading: "Last Name" },
        { heading: "First Name" },
        { heading: "Agreement#" },
        { heading: "Description" },
        { heading: "Type" },
        { heading: "#Payments" },
        { heading: "Total Cost" },
        { heading: "Schedule" },
        { heading: "Next Due Date" },
        { heading: "Amount Due" },
        { heading: "Status" },
        { heading: "Sub Status" }
    ]

    const Handledropdown = (e: any) => {
        const value = e.target.value;
        setdropdownvalue(value);
    };

    const renderTableHeadFn = () => (
        <>
            {pendingAgreementHeader.map((obj: any) => {
                return <RACTableCell>{obj.heading}</RACTableCell>
            })}
        </>
    )

    const renderTableContentFn = () => (
        <>
            {
                noRecords ?
                    <>
                        <RACTableRow className={pendingAgreementStyle.tableRow} >
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell style={{ "float": "right" }}>
                                {transferRequestTableLoader ?
                                    <>
                                        <Grid style={{ "marginTop": "50px" }}>
                                            <CircularProgress />
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                                            <NoRecordsFound />
                                            <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                                                No records found
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                            </RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                            <RACTableCell></RACTableCell>
                        </RACTableRow>
                    </>
                    : <>
                        {pendingAgreemntValues?.map((obj: any, i: any) => {
                            return (
                                <React.Fragment>
                                    <RACTableRow className={pendingAgreementStyle.tableRow} >

                                        <RACTableCell style={{ textAlign: 'left' }}>
                                            <a
                                                className={classes.racpadLink}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/customer/update/${obj.customerId}/customer`,
                                                    })
                                                }
                                            >
                                                {obj.customerLastName}
                                            </a>
                                        </RACTableCell>

                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            <a
                                                className={classes.racpadLink}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/customer/update/${obj.customerId}/customer`,
                                                    })
                                                }
                                            >
                                                {obj.customerFirstName}
                                            </a>
                                        </RACTableCell>

                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            <a
                                                className={classes.racpadLink}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `info/details/${obj.customerId}/${obj.agreementId}`,
                                                    })
                                                }
                                            >
                                                {obj.agreementNumber}
                                            </a>

                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>

                                            <label
                                                title={obj.agreementDesc}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                data-original-title={obj.agreementDesc}
                                                id={'agreementDescription'}
                                            >
                                                {obj.agreementDesc.length > 16 ? obj.agreementDesc.substring(0, 16) + '...' : obj.agreementDesc}

                                            </label>

                                        </RACTableCell>

                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>

                                            {obj.agreementType == 'CHAR' ? 'Charity' :
                                                obj.agreementType == 'CORP' ? 'Corporate Leasing' :
                                                    obj.agreementType == 'EMP' ? 'Employee Purchase' :
                                                        obj.agreementType == 'GINI' ? 'GIN Installment' :
                                                            obj.agreementType == 'LEG' ? 'Legal' :
                                                                obj.agreementType == 'PRO' ? 'Promo' :
                                                                    obj.agreementType == 'RAC' ? 'RAC Acceptance' :
                                                                        obj.agreementType == 'RTO' ? 'Rental Agreement' :
                                                                            obj.agreementType == 'RTR' ? 'Rent to Rent' :
                                                                                obj.agreementType == 'SPEC' ? 'Customer Order' :
                                                                                    obj.agreementType == 'STE' ? 'Short Term Event' :
                                                                                        obj.agreementType == 'HTL' ? 'High Touch Loaner' :
                                                                                            obj.agreementType == 'LOAN' ? 'Rent to Rent - Loaner' : obj.agreementType}
                                        </RACTableCell>

                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {obj.payments}
                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {'$' + obj.totalCost}
                                        </RACTableCell>

                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {obj.paymentSchedule == 'WK' ? 'Weekly' :
                                                obj.paymentSchedule == 'MON' ? 'Monthly' :
                                                    obj.paymentSchedule == 'BIWK' ? 'Bi-Weekly' :
                                                        obj.paymentSchedule == 'SEMI' ? 'Semi-Monthly'
                                                            : obj.paymentSchedule}

                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {moment(obj.nextDueDate).format('MM/DD/YYYY')}
                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {'$' + obj.amountDue}
                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {obj.agreementStatus == 'PEND' ? 'Pending' :
                                                obj.agreementStatus == 'PRELIM' ? 'Preliminary' :
                                                    obj.agreementStatus == 'PRELIMNV' ? 'Preliminary Inventory' :
                                                        obj.agreementStatus == 'PRELIMPAID' ? 'Preliminary Paid' :
                                                            obj.agreementStatus == 'PRELIMRFND' ? 'Preliminary Refunded' : obj.agreementStatus}
                                        </RACTableCell>


                                        <RACTableCell style={{ textAlign: 'left', color: 'grey' }}>
                                            {obj.subStatus == 'NP' ? 'Not Printed' :
                                                obj.subStatus == 'DL' ? 'Delivered' :
                                                    obj.subStatus == 'D' ? 'Declined' :
                                                        obj.subStatus == 'VOID' ? 'Void Agreement or Bad/Unload record' :
                                                            obj.subStatus == 'APS' ? 'Paper Signed' :
                                                                obj.subStatus == 'P' ? 'Printed' :
                                                                    obj.subStatus == 'APR' ? 'Approved' :
                                                                        obj.subStatus == 'ADS' ? 'Digitally Signed' :
                                                                            obj.subStatus == 'A' ? 'Activated Agreement' : obj.subStatus}
                                        </RACTableCell>

                                    </RACTableRow>
                                    {/* {agreementTable(i)} */}
                                </React.Fragment>

                            );
                        })}
                    </>
            }
        </>
    )





    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={classes.formLabel}>
                        Something went Wrong
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setsomethingWentWrongOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };

    return (
        <div>
            <Grid>
                <Grid container>
                    <Grid item md={7}>
                        <div>
                            <Typography variant="h4" style={{ padding: "0px", margin: "18px" }}>
                                Pending Agreements
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item md={5}>
                        <div>
                            <List style={{ display: "inline-block", float: "right", padding: "0%", width: "45%" }}>

                                <ListItem style={{ display: "inline-block" }}>
                                    <Grid container>
                                        <Grid item style={{ float: 'right', marginTop: '6px' }}>
                                            <Typography
                                                //className={acimaDeliveryClasses.deliverytype}
                                                style={{ padding: "0px", marginTop: "6  px" }}
                                            >
                                                Filter by:
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ margin: "0px", padding: "5px 7px", width: "65%" }}>
                                            <RACSelect
                                                options={optionsArrayFunction(options)}
                                                defaultValue={dropdownvalue}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    Handledropdown(e);
                                                }}
                                            ></RACSelect>

                                        </Grid>
                                    </Grid>
                                </ListItem>

                            </List>
                        </div>
                    </Grid>
                </Grid>
                <Card style={{ margin: '10px', borderRadius: '13px' }}>
                    <CardContent>
                        <Grid className={pendingAgreementStyle.fixTableHeadAgrTrsRec} >
                            {pendingAgreemntValues.length > 9 ?
                                <InfiniteScroll
                                    next={fetchNextData}
                                    dataLength={pendingAgreemntValues?.length}
                                    hasMore={hasMore}
                                    height={900}
                                    loader={
                                        fetchDataLoader ? (
                                            <Grid className={classes.GridLoader}>
                                                <CircularProgress></CircularProgress>
                                            </Grid>
                                        ) : NomoreDataOtherReq ? (
                                            <Typography>No More Data</Typography>
                                        ) : null
                                    }
                                >
                                    <RACTable
                                        renderTableHead={renderTableHeadFn}
                                        renderTableContent={renderTableContentFn}
                                    />
                                </InfiniteScroll> : <RACTable
                                    renderTableHead={renderTableHeadFn}
                                    renderTableContent={renderTableContentFn}
                                />}

                        </Grid>
                    </CardContent>

                </Card>
                <RACModalCard
                    isOpen={somethingWentWrongOpen}
                    closeIcon={false}
                    maxWidth="xs"
                    borderRadius={"15px"}
                >
                    {somethingWentWrongPopup()}
                </RACModalCard>
            </Grid>

            <Grid className={classes.footerGrid}>
                <Grid className={classes.footerFixed}>

                    <RACButton
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: '15px', float: 'left' }}
                        onClick={() => {
                            history.push({
                                pathname: `/dashboard`,
                            });
                            const navEvent = new PopStateEvent('popstate');
                            window.dispatchEvent(navEvent);
                        }}
                    >
                        Cancel
                    </RACButton>
                </Grid>
            </Grid>

        </div>
    )

}