/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { searchResultStyles } from '../../../JSstyles/searchResultStyles';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';
// import { AgreementContext } from '../../../context/AgreementContext';
import { ReinstateAgreementContext } from '../../../context/ReinstatementAgreementContext';
import ExceptionController from './exceptionController';
// import ContextSkeleton from './contextSkeleton';
import FooterContent from './footerInventory';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import AddedItem from './addedItem';
import ReinstetementGrid from './reinstatementGrid';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';
import { getAgreementOptions, GetEmployeeID, GetRole } from '../../../api/user';
import { AgreementContext } from '../../../context/AgreementContext';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';
import { getInventorySearch, getPricingClientCall } from '../../../api/user';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import {
  Grid,
  Card,
  RACButton,
  Typography,
  CardContent,
  RACModalCard,
  CircularProgress,
  Divider,
} from '@rentacenter/racstrap';
//PS - 14 Importing all required files from the corresponding folder
export const Usercontext = createContext({});

export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}

interface Params {
  customerId: string;
  agreementId: string;
}

const ReinstateSearchResult = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = agreementGlobalStyles(); //This will hold the global styles
  const agrComponent = searchResultStyles(); //This will hold the component specific styles
  const agrInfoClassName = agreementInfoStyles();

  const { customerId, agreementId } = useParams<Params>();
  const { setagreementOptions, agreementOptions, isAllDone } =
    useContext(AgreementContext);
  const {
    addedItem,
    setItemSearchData,
    setaddedItem,
    reinstatementGridData,
    itemArrayData,
    setitemArrayData,
    setsearchCriteria,
    agreementCreateStatus,
    numberOfInventory,
    searchCriteria,
    copyOfReinstateGrid,
    deletedIndex,
    storeReinstateInvID,
    setcopyOfReinstateGrid,
    oldInvRV,
    setbluePackageName,
    setdeletedIndex,
    epoAmount,
    transferRights,
    collectedRVValue,
    setcollectedRVValue,
    setOffSetValue,
    offSetValue,
  } = useContext(ReinstateAgreementContext); //This is the destructured data of Agreement Context

  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  }; //This will be handling the sorting order of search result
  const childFunc: any = React.useRef(null);
  const [openPriceTag, setOpenPriceTag] = useState(false);

  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  }; //This object will hold all the state of the data of API response

  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  }; //This object will hold all the loader state

  const betterPriceObject = {
    currentRate: '',
    currentTRTO: '',
    reinstateRate: '',
    reinstateTRTO: '',
  };
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const deleteIdObj = { invDeleteId: '' };
  const [SLAController, setSLAController] = useState(SLAObj);
  const [leftValue, setleftValue]: any = useState({});
  const [rightValue, setrightValue]: any = useState({});
  const [tagBlueLabel, settagBlueLabel]: any = useState({});
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [deleteId, setdeleteId] = React.useState(deleteIdObj);
  const [resultController, setresultController] = useState(resultControllerObj);
  const [gridData, setGridData] = useState<any>();
  const [itemArray, setItemArray] = useState<any>([]);
  // const [bucketCopy, setBucketCopy] = useState<any>([]);
  const [showAddedItem, setShowAddedItem] = useState(false);
  const [betterPriceData, setbetterPriceData] = useState(betterPriceObject);
  const [controlViewMode, setControlViewMode] = useState(false);
  const [allowGridCallMethod, setallowGridCallMethod] = useState(true);
  const [isBetterRate, setisBetterRate] = useState(false);
  const [storeAddedInvID, setstoreAddedInvID] = useState('');
  const [storeAddedPriceId, setStoreAddedPriceId] = useState('');

  const [internalContextData, setInternalContextProvider] = useState<any>();
  const [pricingFailPopup, setpricingFailPopup] = React.useState(false);
  const [reinstateDepartment, setreinstateDepartment]: any = useState();
  //PS - 15 Declaration of all required state variable for performing appropriate action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const [CurrentRole, setCurrentRole] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const [hasMore, sethasMore] = useState(true);
  const [newSingleInvPricingInvArray, setnewSingleInvPricingInvArray]: any =
    useState([]);
  const [scrollLoader, setscrollLoader] = useState(false);
  const [payloadSetNumber, setpayloadSetNumber] = useState<any>(1);
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const sansBold = 'OpenSans-bold';
  const radiusClass = '25px !important';
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme'; //This will be controlling the view and edit mode of entire page

  const altersearch = showAddedItem
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid; //This will be making the added item pane, visible and invisible
  const ModuleName = 'Choosing Price Tag';
  const showItemSearch = isAllDone
    ? agrComponent.showItemSearch
    : agrComponent.hideItemSearch;
  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    if (containerData !== undefined) {
      console.log('containerData', containerData);
      console.log('containerDataRole', containerData.GetRole());
      setCurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("before getSearchCriteriaContext", getSearchCriteriaContext);
    //console.log('before getsearchResultContext', searchResult);
    console.log('before getaddedItemContext', addedItem);
    console.log('before getItemArray', itemArrayData);
    //console.log('before getCopyArray', copyArrayData);
    console.log('Status of the agreement is', agreementCreateStatus);

    if (
      // getSearchCriteriaContext[0] !== undefined &&
      addedItem !== undefined &&
      //searchResult !== undefined &&
      itemArrayData !== undefined &&
      //copyArrayData !== undefined &&
      agreementCreateStatus !== undefined
    ) {
      console.log('entered');
      if (agreementCreateStatus.agreementCreatedStatus === true) {
        console.log('entered view mode');
        console.log('entered view mode with getaddedItemContext[0]', addedItem);
        setShowAddedItem(true);
        setControlViewMode(true);
        // setfooterControlData(false);
        setInternalContextProvider(addedItem);
      } else {
        console.log('entered edit mode');
        // if (searchResult.length <= 0) {
        //   setresultController({
        //     ...resultController,
        //     controlEmptyRecord: true,
        //   });
        // } else {
        //   setGridData(searchResult);
        // }
        setpayloadSetNumber(offSetValue);
        setShowAddedItem(true);
        setItemArray(itemArrayData);
        //setBucketCopy(copyArrayData);
        setInternalContextProvider(addedItem);
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: false,
          loaderController: false,
        });
        // setfooterControlData(false);
        setallowGridCallMethod(false);
        setGridData([]);
      }
    } else if (reinstatementGridData !== undefined) {
      const searchValue = {
        itemNo: '',
        serialNo: '',
        bracket: '',
        brand: '',
        model: '',
        statusId: 1,
        subStatusId: 2,
        conditionId: null,
        departments: searchCriteria.departments,
        subDepartments: '',
        packageTypeval: searchCriteria.packageTypeval,
        currentStoreNumber: '',
      };
      searchClick(searchValue, 'searchButtonClick');
    }

    async function GetDropdownApiCall() {
      const dropdownval: any = await getAgreementOptions(
        customerId,
        storeNumber
      );
      console.log('New res', dropdownval);
      if (dropdownval.status === 200) {
        setagreementOptions(dropdownval.data);
      }
    }
    if (agreementOptions == undefined) {
      GetDropdownApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //PS - 16 This method will be prepopulating all the values when previous scenario is handled

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Search Result Props', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    console.log('vaue of id in useeffect', deleteId.invDeleteId);
    if (deleteId.invDeleteId !== '') {
      deleteItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]); //PS - 17 This will be triggered when an item is deleted in blue card which will trigger the deleteitem() method.

  const pricingCall = async (
    inventoriesValue: any,
    validateInventoryIdValue: any,
    packageRequiredValue: any,
    agreementRateValue: any,
    agreementTermValue: any,
    isCompetitorMatchValue: any,
    packageNameValue: any,
    packagePriceType: any
  ) => {
    const req: any = {
      storeNumber: storeNumber,
      inventoryInfo: inventoriesValue,
      validateInventoryId: parseInt(validateInventoryIdValue),
      packageRequired: packageRequiredValue,
      packageName: packageNameValue,
      packagePriceType: packagePriceType,
      agreementRate: agreementRateValue,
      agreementTerm: agreementTermValue,
      isCompetitorMatch: isCompetitorMatchValue,
    };
    console.log('pricingCall request', req);

    return await getPricingClientCall(req);
  }; //PS - 18 This method will be triggering the Item Pricing all with the defined request parameter.

  const deleteItem = () => {
    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('Data Inside invDeleteId use effect', deleteId.invDeleteId);
      let UpdatedArray: any = [];
      itemArray.map((deleteObject: any, index: any) => {
        if (
          parseInt(deleteObject.inventoryId) === parseInt(deleteId.invDeleteId)
        ) {
          UpdatedArray = itemArray.filter((item, filterIndex) => {
            // console.log('filter copy item', item);
            return filterIndex != index;
          });
          //UpdatedArray = itemArray.splice(index, 1);
          console.log('Newly Updated Array', UpdatedArray);
          setItemArray(UpdatedArray);
        }
      });
      // if (numberOfInventory > UpdatedArray.length) {
      //   setallowGridCallMethod(true);
      // }
      // if (UpdatedArray.length <= 1) {
      //   console.log('reached null received package');

      //   setreceivedpackage('');
      // }
      // setitemArrayContext(UpdatedArray);
      setitemArrayData(UpdatedArray);

      let response: any;
      if (UpdatedArray.length > 0) {
        console.log('Inside if');

        const triggerAPI = async () => {
          response = await pricingCall(
            UpdatedArray,
            null,
            false,
            null,
            null,
            false,
            '',
            null
          );
          // setmasterLoader(false);
          setLoaderBundle({ ...loaderBundle, masterLoader: false });

          console.log('Inside Use Effect of invDeleteId', response);
          console.log('Came inside result if condition');
          // setaddedItemContext(response.data);
          setaddedItem(response.data);

          if (response.status == 200) {
            const iterateArray: any = [];
            console.log('copyOfReinstateGrid values test', copyOfReinstateGrid);

            copyOfReinstateGrid.map((reinstObj) => {
              console.log('reinstObj.itemNumber check', reinstObj.itemNumber);
              console.log(
                'deletedIndex.replacedInvId',
                deletedIndex.replacedInvId
              );

              if (reinstObj.itemNumber != deletedIndex.replacedInvId) {
                iterateArray.push(reinstObj);
              }
            });
            const rawResponse = response.data;
            console.log('deleted iterateArray valueee', iterateArray);
            setcopyOfReinstateGrid(iterateArray);
            let entireRVValue = 0;
            response.data.itemPricing.map((obj: any) => {
              entireRVValue = entireRVValue + Number(obj.remainingValue);
            });
            setcollectedRVValue(entireRVValue);

            const calculateAgrInvDepr =
              (Number(entireRVValue) /
                Number(
                  reinstatementGridData.agreementPricing.remainingPaymentAmount
                )) *
              100;

            let calculatedAgreementTotal = 0;
            let calculateAgreementRate = 0;
            const inventoryArray = rawResponse.itemPricing;
            iterateArray.map((copyValue, CopyItemIndex) => {
              for (let i = 0; i < inventoryArray.length; i++) {
                if (
                  UpdatedArray[CopyItemIndex].inventoryId ==
                  inventoryArray[i].inventoryId
                ) {
                  inventoryArray[i].agreementRate = copyValue.agreementRate;
                  inventoryArray[i].agreementTotal =
                    copyValue.remainingAgreementTotal;
                  // inventoryArray[i].inventoryDepreciation = (
                  //   (Number(inventoryArray[i].remainingValue) /
                  //     Number(
                  //       iterateArray[CopyItemIndex].remainingAgreementTotal
                  //     )) *
                  //   100
                  // ).toFixed(2);
                  inventoryArray[i].inventoryDepreciation = (
                    (Number(inventoryArray[i].remainingValue) /
                      Number(
                        (
                          Number(iterateArray[CopyItemIndex].agreementRate) *
                          Number(iterateArray[CopyItemIndex].term)
                        ).toFixed(2)
                      )) *
                    100
                  ).toFixed(2);
                  inventoryArray[i].replacedInvId = copyValue.itemNumber;
                  calculatedAgreementTotal =
                    calculatedAgreementTotal +
                    Number(copyValue.remainingAgreementTotal);
                  calculateAgreementRate =
                    calculateAgreementRate + Number(copyValue.agreementRate);
                }
                //entireRVValue = entireRVValue + responseValue.remainingValue;
              }
            });
            rawResponse.agreementPricing.agreementTerm =
              reinstatementGridData.agreementPricing.agreementTerm;
            rawResponse.agreementPricing.agreementRate =
              calculateAgreementRate.toFixed(2);
            rawResponse.agreementPricing.agreementTotal =
              calculatedAgreementTotal.toFixed(2);
            rawResponse.agreementPricing.cashPrice =
              reinstatementGridData.agreementPricing.cashPrice;
            rawResponse.agreementPricing.agreementInventoryDepreciation = (
              (Number(entireRVValue) /
                Number(
                  (
                    Number(calculateAgreementRate) *
                    Number(reinstatementGridData.agreementPricing.agreementTerm)
                  ).toFixed(2)
                )) *
              100
            ).toFixed(2);

            setShowAddedItem(true);
            setInternalContextProvider(response.data);
            if (response.data.packageInformation !== null) {
              // console.log(
              //   'response.data.packageInformation[0].packageCriteriaa',
              //   response.data.packageInformation.packageTypeList[0].packageCriteria
              // );
              setbluePackageName(response.data.itemPricing[0].packageName);
              //setreceivedpackage(response.data.itemPricing[0].packageName);
            } else {
              setbluePackageName('');
            }
            let tireController: any = false;
            const responseHolder = response.data.itemPricing;
            for (let i = 0; i < responseHolder.length; i++) {
              if (
                responseHolder[i].rmsItemNumber == '200012120' ||
                responseHolder[i].rmsItemNumber == '200012121' ||
                responseHolder[i].rmsItemNumber == '200013177' ||
                responseHolder[i].rmsItemNumber == '200012118' ||
                responseHolder[i].rmsItemNumber == '200013350' ||
                responseHolder[i].rmsItemNumber == '200012119'
              ) {
                tireController = true;
                // settireController(true);
                break;
              }
            }
            const contextObject: any = {
              storeNumber: sessionStorage.getItem('storeNumber'),
              customerId: parseInt(customerId),
              agreementRate: parseFloat(
                response.data.agreementPricing.agreementRate
              ),
              agreementTerm: parseInt(
                response.data.agreementPricing.agreementTerm
              ),
              sacTotal: parseFloat(response.data.agreementPricing.cashPrice),
              inventoryIds: UpdatedArray,
              agreementType: 'RTO',
              actualAgreementRate: parseFloat(
                response.data.agreementPricing.agreementRate
              ),
              actualAgreementTerm: parseInt(
                response.data.agreementPricing.agreementTerm
              ),
              tagTotal: parseFloat(response.data.agreementPricing.tagTotal),
              sacPrice: parseFloat(response.data.agreementPricing.cashPrice),
              inventory: UpdatedArray,
              customer: [
                {
                  customerId: parseInt(customerId),
                  priority: 1,
                },
              ],
              AgreementTotal: response.data.agreementPricing.agreementTotal,
              TireAgreementFlow: tireController,
              itemDescription:
                response.data.itemPricing[0].packageName !== null
                  ? response.data.itemPricing[0].packageName
                  : response.data.itemPricing[0].inventoryName,
              reinstateType: true,
              parentAgreementId: parseInt(agreementId),
              istransferRightsApplied: transferRights,
            };
            setItemSearchData(contextObject);
            //ContextSkeleton(response.data, UpdatedArray, setItemSearchData);
          } else if (response.status == 400 || response.status == 500) {
            // setmodalController({
            //   ...modalController,
            //   itemPricingFail: true,
            // });
            setpricingFailPopup(true);
          }
        };
        triggerAPI();
      } else {
        // setmasterLoader(false);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });

        console.log('Came inside deep else');
        setInternalContextProvider(undefined);
        setShowAddedItem(false);
        setcopyOfReinstateGrid([]);
        // setreinstatementGridControl(true);
        // setfooterControlData(true);
      }
    } else {
      console.log('Came inside else');
      setInternalContextProvider(undefined);
      setShowAddedItem(false);
      // setfooterControlData(true);
      // setreinstatementGridControl(true);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
    // const copyNum = deleteId.invDeleteId;

    // const resultArr = bucketCopy.filter((data, index) => {
    //   return bucketCopy.indexOf(data) === index;
    // });

    // console.log('copy before map', bucketCopy);
    // console.log('copyNum', copyNum);

    // resultArr.map((obj: any, index: any) => {
    //   //   console.log('copy map obj', obj);
    //   //   if (obj.inventoryId == copyNum) {
    //   //     const copyTableData = gridData;
    //   //     copyTableData.push(obj);
    //   //     setGridData(copyTableData);

    //   const newCopy = bucketCopy.filter((item, filterIndex) => {
    //     console.log('filter copy item', item);
    //     return filterIndex != index;
    //   });

    //   //     console.log('copy after map', newCopy);
    //   //     setBucketCopy(newCopy);
    //   //     // setcopyArrayContext(newCopy);
    //   //     //setcopyArrayData(newCopy);
    //   //   }
    // });
  };
  const searchClick = async (value: any, source: any) => {
    // setLoaderBundle({
    //   ...loaderBundle,
    //   Gridloader: true,
    //   loaderController: true,
    // });
    if (source == 'searchButtonClick') {
      setpayloadSetNumber(1);
      setOffSetValue(1);
      setLoaderBundle({
        ...loaderBundle,
        Gridloader: true,
        loaderController: true,
      });
      sethasMore(true);
    } else if (source == 'searchButtonNotClick') {
      setscrollLoader(true);
    }
    console.log('value in search click', value);

    const currentStore = sessionStorage.getItem('storeNumber');
    const req: any = {
      storeNumbers: [currentStore],
      itemNumber: value.itemNo == '' ? null : [value.itemNo],
      serialNumber: value.serialNo.trim(),
      bracket: value.bracket.trim(),
      brand: value.brand.trim(),
      modelNumber: value.model.trim(),
      statusId: value.statusId,
      subStatusId: [2],
      conditionId:
        value.conditionId == '0' || value.conditionId == null
          ? null
          : parseInt(value.conditionId),
      department: value.departments,
      subDepartment: value.subDepartments,
      packageName: '',
      setNumber: source == 'searchButtonClick' ? 0 : payloadSetNumber,
      isActivePriceTag: true,
      //searchCriteria.packageTypeval
    }; //This will form the request object for the item search API
    //setsearchCriteria(req); //The search request is stored in the context for populating in Search Criteria when previous button is clicked
    console.log('structured payload', req);
    try {
      const response: any = await getInventorySearch(req);
      if (response.status == 400 || response.status == 500) {
        setresultController({
          ...resultControllerObj,
          internalServerController: true,
        }); //This will render Some thing went wrong message in search result
        setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
      } else if (response.status == 200 && response.data.response.length > 0) {
        if (source == 'searchButtonNotClick') {
          setpayloadSetNumber(payloadSetNumber + 1);
          setOffSetValue(payloadSetNumber + 1);
        }
        const filteredData = response.data.response.filter(function (element) {
          console.log('filter object', element);
          return element.conditionRefCode !== 'NEW';
        });
        let furtherFilteredData: any = [];
        if (itemArray.length > 0) {
          console.log('reached if of search');
          let pushMe = false;
          // itemArray.map((itemArrayData: any, itemIndex: any) => {
          //   filteredData.map((filteredDataObj: any, index: any) => {
          //     console.log('itemArrayData', itemArrayData);
          //     console.log(
          //       'filteredDataObj.inventoryId',
          //       filteredDataObj.inventoryId
          //     );

          //     if (itemArrayData !== parseInt(filteredDataObj.inventoryId)) {
          //       console.log('reached once');

          //       furtherFilteredData.push(filteredDataObj);
          //     }
          //   });
          // });

          filteredData.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < itemArray.length; i++) {
              console.log('itemArrayData', itemArrayData);
              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );

              if (itemArray[i] !== parseInt(filteredDataObj.inventoryId)) {
                console.log('reached once true');
                pushMe = true;
              } else {
                console.log('reached break statement');
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        } else {
          console.log('reached else of sr');
          furtherFilteredData = filteredData;
        }
        console.log('furtherFilteredData check in log', furtherFilteredData);

        const rangeBreake: any = [];
        if (furtherFilteredData.length <= 0) {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });

          // setloaderController(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        } else {
          //setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
          setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
          console.log('Response from api', response);

          //Sorting RV

          const reinstateDataFilter = reinstatementGridData.itemPricing.filter(
            function (obj) {
              return (
                parseInt(obj.inventoryNumber) == parseInt(storeReinstateInvID)
              );
            }
          );
          console.log(
            'dsjhvbshd ascbhhsd545sc',
            reinstateDataFilter[0].remainingValue
          );
          console.log('jcbhsdhysvjbsdvh 31scdsfcsd', reinstateDataFilter);
          console.log('xscdcdsvd dc3rrfec3', reinstateDataFilter[0]);

          const range = {
            min: Number(reinstateDataFilter[0].remainingValue) - 50,
            max: Number(reinstateDataFilter[0].remainingValue) + 50,
          };

          console.log('min check value', range.min);
          console.log('max check value', range.max);

          const res = furtherFilteredData.filter(function (o) {
            console.log('loggin the oth value', o);

            if (
              Number(o.remainingValue) <= range.max &&
              Number(o.remainingValue) >= range.min
            ) {
              return o;
            } else {
              rangeBreake.push(o);
            }
          });
          console.log('sorted filtered check ', res);

          console.log('rengebreaked sjdvbdshbv', rangeBreake);
          const finalData = [...res, ...rangeBreake];
          console.log('finalData check', finalData);
          if (source == 'searchButtonNotClick') {
            //setsearchResult([...gridData, ...furtherFilteredData]);
            setGridData([...gridData, ...finalData]);
          } else {
            //setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
            setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
            console.log('Response from api', response);

            setGridData(finalData); //This will assign the API result to get the Search Result grid get load.
          }
          //setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          setresultController(resultControllerObj);
          setallowGridCallMethod(true);
        }
      } else if (response.data.response.length <= 0) {
        // setGridData([]);
        // setresultController({ ...resultController, controlRecord: true });
        // setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        // if (gridData.length == 0) {
        //   setGridData([]);
        //   setresultController({ ...resultController, controlRecord: true });
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        // } else if (gridData.length > 0 && payloadSetNumber > 1) {
        //   sethasMore(false);
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        // } else {
        //   sethasMore(false);
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        //   setresultController({ ...resultController, controlRecord: true });
        // }

        if (source == 'searchButtonClick') {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        } else if (source == 'searchButtonNotClick') {
          sethasMore(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        }
      }
    } catch {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      }); //This will render Some thing went wrong message in search result
      setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
    }
    //setallowGridCallMethod(true);
  }; //PS - 20 This method is called every time when Search button is clicked. This method will be triggering the Search Inventory API call with the corresponding request parameter.

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');

    if (gridData !== undefined && gridData.length > 0 && allowGridCallMethod) {
      console.log('Inside table bind', gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                orderBy="loanerAgreement"
                handleActionType={addItem}
                order={sortDirection.ASC}
                throwStyle={classes}
                triggerSearch={searchClick}
                reveiveHasMore={hasMore}
                reveivescrollLoader={scrollLoader}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      ); //This is rendered when the API response has a definite data.
    } else if (resultController.internalServerController) {
      return (
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />
      ); //This is rendered when 500 occours
    } else if (resultController.controlRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Records"
          throwStyle={classes}
        />
      ); //This is rendered when no search result id found
    } else if (resultController.controlEmptyRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />
      ); //This is rendered when no item to add further
    }
  }; //PS - 21 This method is called when the API result arrives to a variable. Based on the response the data is displayed in the Search Result area.
  const reinstateAddItem = async (itemid: any, itemNumber: any) => {
    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    const response: any = await pricingCall(
      itemArray,
      itemid,
      false,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });

    console.log('raw response', response);

    console.log('Respoooooonse', response.data);
    //let newItemArray: any = [];
    if (response.status == 200) {
      const allowPricePopulate = await pricePopulator(response);
      if (allowPricePopulate) {
        const collectPriceArray: any = [];

        response.data.itemPricing.map((obj, index) => {
          collectPriceArray.push({
            inventoryId: Number(obj.inventoryId),
            inventoryPriceId: Number(obj.inventoryPriceId),
          });
        });
        setaddedItem(response.data);

        //newItemArray = [...itemArray, parseInt(itemid)];
        setallowGridCallMethod(false);
        setGridData([]);
        setresultController(resultControllerObj);
        if (numberOfInventory == collectPriceArray.length) {
          console.log('reached tshis point');
          setGridData([]);
          setallowGridCallMethod(false);
        }
        console.log('itemNumber in add item', itemNumber);

        console.log('copyOfReinstateGrid in add iyem', copyOfReinstateGrid);
        let iterateArray: any = [];
        if (
          copyOfReinstateGrid !== undefined &&
          copyOfReinstateGrid.length > 0
        ) {
          iterateArray = copyOfReinstateGrid;
        }
        reinstatementGridData.itemPricing.map((reinstObj) => {
          if (itemNumber == reinstObj.inventoryNumber) {
            iterateArray.push(reinstObj);
          }
        });
        setcopyOfReinstateGrid(iterateArray);
        console.log('iterateArray in the reinstate add function', iterateArray);

        if (response.data.packageInformation !== null) {
          // console.log(
          //   'response.data.packageInformatsion[0].packageCriteria',
          //   response.data.packageInformation.packageTypeList[0].packageCriteria
          // );
          setbluePackageName(response.data.itemPricing[0].packageName);
          //setreceivedpackage(response.data.itemPricing[0].packageName);
        } else {
          setbluePackageName('');
        }
        console.log('iterateArray after adding item', iterateArray);
        const rawResponse = response.data;

        console.log(
          'parseInt(reinstatementGridData.agreementPricing.agreementTotal) something',
          parseInt(reinstatementGridData.agreementPricing.agreementTotal)
        );
        console.log('collectedRVValue in initial', collectedRVValue);
        let entireRVValue = 0;
        response.data.itemPricing.map((obj: any) => {
          entireRVValue = entireRVValue + Number(obj.remainingValue);
        });
        setcollectedRVValue(entireRVValue);
        console.log('entireRVValue in the sr calculated', entireRVValue);

        console.log(
          'reinstatementGridData.agreementPricing.remainingPaymentAmount',
          reinstatementGridData.agreementPricing.remainingPaymentAmount
        );

        const calculateAgrInvDepr =
          (Number(entireRVValue) /
            Number(
              reinstatementGridData.agreementPricing.remainingPaymentAmount
            )) *
          100; // add all the rv value of new inv /remaining payment amount in agr information object in agr info response
        console.log(
          'reinstate calculateAgrInvDepr in search result',
          calculateAgrInvDepr
        );

        let calculatedAgreementTotal = 0;
        let calculateAgreementRate = 0;
        const inventoryArray = rawResponse.itemPricing;
        iterateArray.map((copyValue, CopyItemIndex) => {
          console.log('thrgdfs');

          for (let i = 0; i < inventoryArray.length; i++) {
            console.log('esrntd');

            if (
              collectPriceArray[CopyItemIndex].inventoryId ==
              inventoryArray[i].inventoryId
            ) {
              console.log(
                'iterateArray[CopyItemIndex].remainingAgreementTotal reinst grid',
                iterateArray[CopyItemIndex].remainingAgreementTotal
              );
              console.log(
                'copyValue.remainingAgreementTotal checking log',
                copyValue.remainingAgreementTotal
              );

              inventoryArray[i].agreementRate = copyValue.agreementRate;
              inventoryArray[i].agreementTotal =
                copyValue.remainingAgreementTotal;
              inventoryArray[i].inventoryDepreciation = (
                (Number(inventoryArray[i].remainingValue) /
                  Number(
                    (
                      Number(iterateArray[CopyItemIndex].agreementRate) *
                      Number(iterateArray[CopyItemIndex].term)
                    ).toFixed(2)
                  )) *
                100
              ).toFixed(2);
              inventoryArray[i].replacedInvId = copyValue.itemNumber;
              calculatedAgreementTotal =
                calculatedAgreementTotal +
                Number(copyValue.remainingAgreementTotal);
              calculateAgreementRate =
                calculateAgreementRate + Number(copyValue.agreementRate);
            }
          }
        });
        rawResponse.agreementPricing.agreementTerm =
          reinstatementGridData.agreementPricing.agreementTerm;
        rawResponse.agreementPricing.agreementRate =
          numberOfInventory == collectPriceArray.length
            ? reinstatementGridData.agreementPricing.agreementRate
            : calculateAgreementRate.toFixed(2);

        rawResponse.agreementPricing.agreementTotal =
          calculatedAgreementTotal.toFixed(2);

        rawResponse.agreementPricing.cashPrice =
          reinstatementGridData.agreementPricing.cashPrice;
        rawResponse.agreementPricing.agreementInventoryDepreciation = (
          (Number(entireRVValue) /
            Number(
              (
                Number(calculateAgreementRate) *
                Number(reinstatementGridData.agreementPricing.agreementTerm)
              ).toFixed(2)
            )) *
          100
        ).toFixed(2);

        console.log(
          'calculateAgrInvDepr in reinstate additem',
          calculateAgrInvDepr
        );
        let tireController: any = false;
        const responseHolder = response.data.itemPricing;
        for (let i = 0; i < responseHolder.length; i++) {
          if (
            responseHolder[i].rmsItemNumber == '200012120' ||
            responseHolder[i].rmsItemNumber == '200012121' ||
            responseHolder[i].rmsItemNumber == '200013177' ||
            responseHolder[i].rmsItemNumber == '200012118' ||
            responseHolder[i].rmsItemNumber == '200013350' ||
            responseHolder[i].rmsItemNumber == '200012119'
          ) {
            tireController = true;
            // settireController(true);
            break;
          }
        }
        const contextObject: any = {
          storeNumber: sessionStorage.getItem('storeNumber'),
          customerId: parseInt(customerId),
          agreementRate: parseFloat(
            response.data.agreementPricing.agreementRate
          ),
          agreementTerm: parseInt(response.data.agreementPricing.agreementTerm),
          sacTotal: parseFloat(response.data.agreementPricing.cashPrice),
          inventoryIds: collectPriceArray,
          agreementType: 'RTO',
          actualAgreementRate: parseFloat(
            response.data.agreementPricing.agreementRate
          ),
          actualAgreementTerm: parseInt(
            response.data.agreementPricing.agreementTerm
          ),
          tagTotal: parseFloat(response.data.agreementPricing.tagTotal),
          sacPrice: parseFloat(response.data.agreementPricing.cashPrice),
          inventory: collectPriceArray,
          customer: [
            {
              customerId: parseInt(customerId),
              priority: 1,
            },
          ],
          AgreementTotal: response.data.agreementPricing.agreementTotal,
          TireAgreementFlow: tireController,
          itemDescription:
            response.data.itemPricing[0].packageName !== null
              ? response.data.itemPricing[0].packageName
              : response.data.itemPricing[0].inventoryName,
          reinstateType: true,
          parentAgreementId: parseInt(agreementId),
          istransferRightsApplied: transferRights,
        };
        setItemSearchData(contextObject);
        console.log('newItemArray in add item', collectPriceArray);
        console.log('response.data in reinstate additem', response.data);

        setItemArray(collectPriceArray);
        childFunc.current.deleteFromGrid();
        setitemArrayData(collectPriceArray);
        // setfooterControlData(false);
        setInternalContextProvider(response.data);

        console.log('tabledata.filter', gridData);

        setShowAddedItem(true);
        setsearchCriteria({
          ...searchCriteria,
          itemNo: '',
          serialNo: '',
          bracket: '',
          brand: '',
          model: '',
          conditionId: null,
          departments: '',
          subDepartments: '',
        });
        setreinstateDepartment('');
      }
    } else if (response.status == 400 || response.status == 500) {
      // setpackageExceptionController(true);
      // setmodalController({
      //   ...modalController,
      //   itemPricingFail: true,
      // });
      setpricingFailPopup(true);
    }
  };

  const cartAdder = async (itemid: any, inventoryPriceId: any) => {
    setisBetterRate(false);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    const response: any = await pricingCall(
      itemArray,
      itemid,
      false,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });

    console.log('raw response', response);

    console.log('Respoooooonse', response.data);
    let newItemArray: any = [];
    if (response.status == 200) {
      const allowPricePopulate = await pricePopulator(response);
      if (allowPricePopulate) {
        setaddedItem(response.data);

        newItemArray = [
          ...itemArray,
          {
            inventoryId: Number(itemid),
            inventoryPriceId: Number(inventoryPriceId),
          },
        ];
        setallowGridCallMethod(false);
        setGridData([]);
        setresultController(resultControllerObj);
        if (numberOfInventory == newItemArray.length) {
          console.log('reached this point');
          setGridData([]);
          setallowGridCallMethod(false);
        }
        console.log(
          'storeReinstateInvID in add item additem',
          storeReinstateInvID
        );

        console.log(
          'copyOfReinstateGrid in add iyem iterateArray value in Search Result Add Item',
          copyOfReinstateGrid
        );
        let iterateArray: any = [];
        if (
          copyOfReinstateGrid !== undefined &&
          copyOfReinstateGrid.length > 0
        ) {
          iterateArray = copyOfReinstateGrid;
        }
        reinstatementGridData.itemPricing.map((reinstObj) => {
          if (storeReinstateInvID == reinstObj.inventoryNumber) {
            iterateArray.push(reinstObj);
          }
        });
        // const ResponseArrayForPackage: any[] | undefined = iterateArray.sort(
        //   (a, b) => b.inventory_total_cost - a.inventory_total_cost
        // );

        // console.log('After Sorting', ResponseArrayForPackage);
        setcopyOfReinstateGrid(iterateArray);
        console.log(
          'iterateArray value in Search Result Add Item',
          iterateArray
        );

        if (
          response.data.packageInformation.packageTypeList != null &&
          Object.keys(response.data.packageInformation.packageTypeList)
            .length !== 0 &&
          response.data.packageInformation.packageTypeList != ''
        ) {
          // console.log(
          //   'response.data.packageInformation[0].packageCriteria',
          //   response.data.packageInformation.packageTypeList[0].packageCriteria
          // );
          setbluePackageName(response.data.itemPricing[0].packageName);
          //setreceivedpackage(response.data.itemPricing[0].packageName);
        } else {
          setbluePackageName('');
        }
        // console.log('iterateArray after adding item', iterateArray);
        const rawResponse = response.data;

        let entireRVValue = 0;
        response.data.itemPricing.map((obj: any) => {
          entireRVValue = entireRVValue + Number(obj.remainingValue);
        });

        setcollectedRVValue(entireRVValue);

        const calculateAgrInvDepr =
          (Number(entireRVValue) /
            Number(
              reinstatementGridData.agreementPricing.remainingPaymentAmount
            )) *
          100; // add all the rv value of new inv /remaining payment amount in agr information object in agr info response

        let calculatedAgreementTotal = 0;
        let calculateAgreementRate = 0;
        console.log('initial newItemArray in add item', newItemArray);

        const inventoryArray = rawResponse.itemPricing;
        iterateArray.map((copyValue, CopyItemIndex) => {
          console.log('reached this line loop', inventoryArray.length);

          for (let i = 0; i < inventoryArray.length; i++) {
            console.log('reachedc gvhbjn');

            if (
              newItemArray[CopyItemIndex].inventoryId ==
              inventoryArray[i].inventoryId
            ) {
              console.log('nhgbfvdcced');
              console.log(
                'newItemArray[CopyItemIndex] in additem',
                newItemArray[CopyItemIndex]
              );
              console.log(
                'inventoryArray[i].inventoryId in additem',
                inventoryArray[i].inventoryId
              );
              console.log(
                'inventoryArray[i].agreementRate',
                inventoryArray[i].agreementRate
              );

              inventoryArray[i].agreementRate = copyValue.agreementRate;
              inventoryArray[i].agreementTotal =
                copyValue.remainingAgreementTotal;
              console.log(
                'Number(inventoryArray[i].remainingValue)',
                Number(inventoryArray[i].remainingValue)
              );
              console.log(
                'Number(iterateArray[CopyItemIndex].agreementRate)',
                Number(iterateArray[CopyItemIndex].agreementRate)
              );
              console.log(
                'Number(iterateArray[CopyItemIndex].term)',
                Number(iterateArray[CopyItemIndex].term)
              );
              console.log(
                'fcdf351',
                Number(inventoryArray[i].remainingValue) /
                  Number(
                    (
                      Number(iterateArray[CopyItemIndex].agreementRate) *
                      Number(iterateArray[CopyItemIndex].term)
                    ).toFixed(2)
                  )
              );

              inventoryArray[i].inventoryDepreciation = (
                (Number(inventoryArray[i].remainingValue) /
                  Number(
                    (
                      Number(iterateArray[CopyItemIndex].agreementRate) *
                      Number(iterateArray[CopyItemIndex].term)
                    ).toFixed(2)
                  )) *
                100
              ).toFixed(2);
              inventoryArray[i].replacedInvId = copyValue.itemNumber;

              calculatedAgreementTotal =
                calculatedAgreementTotal +
                Number(copyValue.remainingAgreementTotal);
              calculateAgreementRate =
                calculateAgreementRate + Number(copyValue.agreementRate);
            }
          }
        });

        rawResponse.agreementPricing.agreementTerm =
          reinstatementGridData.agreementPricing.agreementTerm;
        rawResponse.agreementPricing.agreementRate =
          numberOfInventory == newItemArray.length
            ? reinstatementGridData.agreementPricing.agreementRate
            : calculateAgreementRate.toFixed(2);

        rawResponse.agreementPricing.agreementTotal =
          calculatedAgreementTotal.toFixed(2);
        rawResponse.agreementPricing.cashPrice =
          reinstatementGridData.agreementPricing.cashPrice;
        rawResponse.agreementPricing.agreementInventoryDepreciation = (
          (Number(entireRVValue) /
            Number(
              (
                Number(calculateAgreementRate) *
                Number(reinstatementGridData.agreementPricing.agreementTerm)
              ).toFixed(2)
            )) *
          100
        ).toFixed(2);

        console.log(
          'calculateAgrInvDepr in reinstate additem',
          calculateAgrInvDepr
        );
        console.log('Altered Inv inventoryArray', inventoryArray);
        let tireController: any = false;
        const responseHolder = response.data.itemPricing;
        for (let i = 0; i < responseHolder.length; i++) {
          if (
            responseHolder[i].rmsItemNumber == '200012120' ||
            responseHolder[i].rmsItemNumber == '200012121' ||
            responseHolder[i].rmsItemNumber == '200013177' ||
            responseHolder[i].rmsItemNumber == '200012118' ||
            responseHolder[i].rmsItemNumber == '200013350' ||
            responseHolder[i].rmsItemNumber == '200012119'
          ) {
            tireController = true;
            // settireController(true);
            break;
          }
        }
        const contextObject: any = {
          storeNumber: sessionStorage.getItem('storeNumber'),
          customerId: parseInt(customerId),
          agreementRate: parseFloat(
            response.data.agreementPricing.agreementRate
          ),
          agreementTerm: parseInt(response.data.agreementPricing.agreementTerm),
          sacTotal: parseFloat(response.data.agreementPricing.cashPrice),
          inventoryIds: newItemArray,
          agreementType: 'RTO',
          actualAgreementRate: parseFloat(
            response.data.agreementPricing.agreementRate
          ),
          actualAgreementTerm: parseInt(
            response.data.agreementPricing.agreementTerm
          ),
          tagTotal: parseFloat(response.data.agreementPricing.tagTotal),
          sacPrice: parseFloat(response.data.agreementPricing.cashPrice),
          inventory: newItemArray,
          customer: [
            {
              customerId: parseInt(customerId),
              priority: 1,
            },
          ],
          AgreementTotal: response.data.agreementPricing.agreementTotal,
          TireAgreementFlow: tireController,
          itemDescription:
            response.data.itemPricing[0].packageName !== null
              ? response.data.itemPricing[0].packageName
              : response.data.itemPricing[0].inventoryName,
          reinstateType: true,
          parentAgreementId: parseInt(agreementId),
          istransferRightsApplied: transferRights,
        };
        setItemSearchData(contextObject);
        console.log('newItemArray in add item', newItemArray);
        console.log('response.data in reinstate delete', response.data);

        setItemArray(newItemArray);
        childFunc.current.deleteFromGrid();
        setitemArrayData(newItemArray);
        setInternalContextProvider(response.data);
        console.log('tabledata.filter', gridData);

        setShowAddedItem(true);

        setsearchCriteria({
          ...searchCriteria,
          itemNo: '',
          serialNo: '',
          bracket: '',
          brand: '',
          model: '',
          conditionId: null,
          departments: '',
          subDepartments: '',
        });
        setreinstateDepartment('');
      }
    } else if (response.status == 400 || response.status == 500) {
      // setpackageExceptionController(true);
      // setmodalController({
      //   ...modalController,
      //   itemPricingFail: true,
      // });
      setpricingFailPopup(true);
    }
  };

  const pricePopulator = (response: any) => {
    let allowPopulatePrice = false;
    for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
      if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
        allowPopulatePrice = false;
        break;
      } else {
        allowPopulatePrice = true;
      }
    }

    if (allowPopulatePrice) {
      return allowPopulatePrice;
    } else {
      //const arrayCollection: any = [];
      const itemArrayCollection: any = [];
      for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
        if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
          // arrayCollection.push(
          //   response.priceTag[i].inventoryPriceTags[0],
          //   response.priceTag[i].inventoryPriceTags[1]
          // );
          settagBlueLabel({
            inventoryNumber:
              response.data.inventoryPriceInfo[i].inventoryNumber,
            inventoryDesc: response.data.inventoryPriceInfo[i].inventoryDesc,
            inventoryId: response.data.inventoryPriceInfo[i].inventoryId,
          });
          if (
            response.data.inventoryPriceInfo[i].inventoryPrice[0]
              .inventoryPriceType == 'ACTIVE'
          ) {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[0]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[1]
            );
          } else {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[1]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
            );
          }
        } else {
          itemArrayCollection.push({
            inventoryId: Number(
              response.data.inventoryPriceInfo[i].inventoryId
            ),
            inventoryPriceId: Number(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
                .inventoryPriceId
            ),
          });
        }
      }
      setOpenPriceTag(true);
      setnewSingleInvPricingInvArray(itemArrayCollection); // this should be stored in a different array
      //setstoreArrayCollection(arrayCollection);
      //dynamicPopupHandler(arrayCollection);
      //console.log('vfdcdddc12d52c', arrayCollection);
    }
  };

  const addItem = async (itemid: any, inventoryPriceId: any) => {
    // setmasterLoader(true);
    console.log('check itemid value', itemid);

    const addedInvData = gridData.filter(function (obj) {
      return parseInt(obj.inventoryId) == parseInt(itemid);
    });
    console.log('check addedInvData in log', addedInvData);
    console.log('weeklyRate value in grid', addedInvData[0].weeklyRate);
    console.log('weeklyRate value in grid', addedInvData[0].weeklyTerm);
    console.log(
      'Multiplied value of rate and term',
      Number(addedInvData[0].weeklyTerm) * Number(addedInvData[0].weeklyRate)
    );

    console.log('reinstatementGridData check log', reinstatementGridData);

    const reinstateDataFilter = reinstatementGridData.itemPricing.filter(
      function (obj) {
        return parseInt(obj.inventoryNumber) == parseInt(storeReinstateInvID);
      }
    );

    console.log('reinstateDataFilter in log', reinstateDataFilter);
    console.log(
      'weeklyRate reinstateDataFilter in log',
      reinstateDataFilter[0].weeklyRate
    );
    console.log(
      'weeklyTerm reinstateDataFilter in log',
      reinstateDataFilter[0].weeklyTerm
    );

    console.log(
      'calculated reinstateDataFilter total value',
      Number(reinstateDataFilter[0].weeklyRate) *
        Number(reinstateDataFilter[0].weeklyTerm)
    );

    const newInvTagTotal =
      Number(addedInvData[0].weeklyTerm) * Number(addedInvData[0].weeklyRate);
    setbetterPriceData({
      ...betterPriceData,
      currentRate: addedInvData[0].weeklyRate,
      currentTRTO: (
        Number(addedInvData[0].weeklyTerm) * Number(addedInvData[0].weeklyRate)
      )
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      reinstateRate: reinstateDataFilter[0].agreementRate,
      reinstateTRTO: Number(reinstateDataFilter[0].remainingAgreementTotal)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    });
    console.log(
      'Number(reinstateDataFilter[0].tagTotal)',
      Number(reinstateDataFilter[0].tagTotal)
    );
    console.log('newInvTagTotal vakjebv5', newInvTagTotal);

    if (
      Number(reinstateDataFilter[0].remainingAgreementTotal) > newInvTagTotal
    ) {
      setisBetterRate(true);
      setstoreAddedInvID(itemid);
      setStoreAddedPriceId(inventoryPriceId);
    } else {
      cartAdder(itemid, inventoryPriceId);
    }
  };
  const deleteSingleItem = async (itemId: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log('Required Item Id', itemId);
    setdeleteId({ ...deleteId, invDeleteId: itemId });
  }; //PS - 23 This method is trigger when an item is deleted in the blue card. This will make the loader visible and store the delete id in a state variable.

  const openItemPricingFail = () => {
    return (
      <>
        <Grid item md={12} className={classes.textCenter}>
          <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
            <Erroricon />
          </Grid>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Something went wrong in getting Price
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setpricingFailPopup(false)}
          >
            OK
          </RACButton>
        </Grid>
      </>
    );
  }; // This method will be opening the Exception message when item pricing get fail.

  const performReinstateSearch = (reinstateDepartment: any) => {
    const searchValue = {
      itemNo: '',
      serialNo: '',
      bracket: '',
      brand: '',
      model: '',
      poNo: 0,
      statusId: 1,
      subStatusId: 2,
      conditionId: null,
      departments: reinstateDepartment,
      subDepartments: '',
      packageTypeval: searchCriteria.packageTypeval,
      currentStoreNumber: '',
    };
    console.log('performReinstateSearch params', searchValue);
    setreinstateDepartment(reinstateDepartment);
    setsearchCriteria({ ...searchCriteria, departments: reinstateDepartment });
    searchClick(searchValue, 'searchButtonClick');
  };

  const betterRateModal = () => {
    return (
      <div>
        <Grid item md={12}>
          <Typography
            variant="caption"
            className={`${classes.bold} ${classes.font15}`}
          >
            Current pricing is a better value than reinstating.
          </Typography>

          <Grid
            className={`${classes.floatLeft} ${classes.racLightBlue} ${agrInfoClassName.agrBorderRadius} ${classes.px2} ${classes.py2} ${classes.mt3}`}
          >
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Current Rate{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.currentRate}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Current TRTO{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.currentTRTO}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Reinstate Rate{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.reinstateRate}
              </Typography>
            </Grid>
            <Grid className={`${classes.floatLeft}`} md={3}>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrInfoGeneral} ${classes.width100} ${classes.floatLeft}`}
              >
                Reinstate TRTO{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
              >
                $ {betterPriceData.reinstateTRTO}
                {'  '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} style={{ paddingTop: '25%' }}>
          <RACButton
            variant="contained"
            color="primary"
            size="large"
            onClick={(e) => cartAdder(storeAddedInvID, storeAddedPriceId)}
            style={{ float: 'right' }}
          >
            Continue
          </RACButton>
          <RACButton
            variant="outlined"
            color="secondary"
            size="large"
            onClick={(e) => setisBetterRate(false)}
            style={{ float: 'right', marginRight: '0.5rem' }}
          >
            Cancel
          </RACButton>
        </Grid>
      </div>
    );
  };

  const suppiler = () => {
    console.log('response in suppiler');
    console.log('fdgshjhsh');
    props.somedeeptrigger();
  };

  const tagSingleInvCaller = async (selectedTag?: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log('vfdsdd cvfd', newSingleInvPricingInvArray);

    let arrayForm: any;
    if (selectedTag === undefined) {
      arrayForm = newSingleInvPricingInvArray;
    } else {
      arrayForm = [...itemArray, selectedTag];
    }
    const response: any = await pricingCall(
      arrayForm,
      null,
      false,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status == 200) {
      setaddedItem(response.data);
      const collectPriceArray: any = [];

      response.data.itemPricing.map((obj, index) => {
        collectPriceArray.push({
          inventoryId: Number(obj.inventoryId),
          inventoryPriceId: Number(obj.inventoryPriceId),
        });
      });
      setallowGridCallMethod(false);
      setGridData([]);
      setresultController(resultControllerObj);
      if (numberOfInventory == collectPriceArray.length) {
        console.log('reached this point');
        setGridData([]);
        setallowGridCallMethod(false);
      }
      let iterateArray: any = [];
      if (copyOfReinstateGrid !== undefined && copyOfReinstateGrid.length > 0) {
        iterateArray = copyOfReinstateGrid;
      }
      reinstatementGridData.itemPricing.map((reinstObj) => {
        if (storeReinstateInvID == reinstObj.inventoryNumber) {
          iterateArray.push(reinstObj);
        }
      });
      // const ResponseArrayForPackage: any[] | undefined = iterateArray.sort(
      //   (a, b) => b.inventory_total_cost - a.inventory_total_cost
      // );
      setcopyOfReinstateGrid(iterateArray);
      if (
        response.data.packageInformation.packageTypeList != null &&
        Object.keys(response.data.packageInformation.packageTypeList).length !==
          0 &&
        response.data.packageInformation.packageTypeList != ''
      ) {
        // console.log(
        //   'response.data.packageInformation[0].packageCriteria',
        //   response.data.packageInformation.packageTypeList[0].packageCriteria
        // );
        setbluePackageName(response.data.itemPricing[0].packageName);
        //setreceivedpackage(response.data.itemPricing[0].packageName);
      } else {
        setbluePackageName('');
      }
      const rawResponse = response.data;
      let entireRVValue = 0;
      response.data.itemPricing.map((obj: any) => {
        entireRVValue = entireRVValue + Number(obj.remainingValue);
      });

      setcollectedRVValue(entireRVValue);
      const calculateAgrInvDepr =
        (Number(entireRVValue) /
          Number(
            reinstatementGridData.agreementPricing.remainingPaymentAmount
          )) *
        100;
      let calculatedAgreementTotal = 0;
      let calculateAgreementRate = 0;
      const inventoryArray = rawResponse.itemPricing;
      iterateArray.map((copyValue, CopyItemIndex) => {
        console.log('reached this line loop', inventoryArray.length);

        for (let i = 0; i < inventoryArray.length; i++) {
          console.log('reachedc gvhbjn');

          if (
            collectPriceArray[CopyItemIndex].inventoryId ==
            inventoryArray[i].inventoryId
          ) {
            console.log('nhgbfvdcced');
            console.log(
              'newItemArray[CopyItemIndex] in additem',
              collectPriceArray[CopyItemIndex]
            );
            console.log(
              'inventoryArray[i].inventoryId in additem',
              inventoryArray[i].inventoryId
            );
            console.log(
              'inventoryArray[i].agreementRate',
              inventoryArray[i].agreementRate
            );

            inventoryArray[i].agreementRate = copyValue.agreementRate;
            inventoryArray[i].agreementTotal =
              copyValue.remainingAgreementTotal;
            console.log(
              'Number(inventoryArray[i].remainingValue)',
              Number(inventoryArray[i].remainingValue)
            );
            console.log(
              'Number(iterateArray[CopyItemIndex].agreementRate)',
              Number(iterateArray[CopyItemIndex].agreementRate)
            );
            console.log(
              'Number(iterateArray[CopyItemIndex].term)',
              Number(iterateArray[CopyItemIndex].term)
            );
            console.log(
              'fcdf351',
              Number(inventoryArray[i].remainingValue) /
                Number(
                  (
                    Number(iterateArray[CopyItemIndex].agreementRate) *
                    Number(iterateArray[CopyItemIndex].term)
                  ).toFixed(2)
                )
            );

            inventoryArray[i].inventoryDepreciation = (
              (Number(inventoryArray[i].remainingValue) /
                Number(
                  (
                    Number(iterateArray[CopyItemIndex].agreementRate) *
                    Number(iterateArray[CopyItemIndex].term)
                  ).toFixed(2)
                )) *
              100
            ).toFixed(2);
            inventoryArray[i].replacedInvId = copyValue.itemNumber;

            calculatedAgreementTotal =
              calculatedAgreementTotal +
              Number(copyValue.remainingAgreementTotal);
            calculateAgreementRate =
              calculateAgreementRate + Number(copyValue.agreementRate);
          }
        }
      });

      rawResponse.agreementPricing.agreementTerm =
        reinstatementGridData.agreementPricing.agreementTerm;
      rawResponse.agreementPricing.agreementRate =
        numberOfInventory == collectPriceArray.length
          ? reinstatementGridData.agreementPricing.agreementRate
          : calculateAgreementRate.toFixed(2);

      rawResponse.agreementPricing.agreementTotal =
        calculatedAgreementTotal.toFixed(2);
      rawResponse.agreementPricing.cashPrice =
        reinstatementGridData.agreementPricing.cashPrice;
      rawResponse.agreementPricing.agreementInventoryDepreciation = (
        (Number(entireRVValue) /
          Number(
            (
              Number(calculateAgreementRate) *
              Number(reinstatementGridData.agreementPricing.agreementTerm)
            ).toFixed(2)
          )) *
        100
      ).toFixed(2);

      let tireController: any = false;
      const responseHolder = response.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          // settireController(true);
          break;
        }
      }

      const contextObject: any = {
        storeNumber: sessionStorage.getItem('storeNumber'),
        customerId: parseInt(customerId),
        agreementRate: parseFloat(response.data.agreementPricing.agreementRate),
        agreementTerm: parseInt(response.data.agreementPricing.agreementTerm),
        sacTotal: parseFloat(response.data.agreementPricing.cashPrice),
        inventoryIds: collectPriceArray,
        agreementType: 'RTO',
        actualAgreementRate: parseFloat(
          response.data.agreementPricing.agreementRate
        ),
        actualAgreementTerm: parseInt(
          response.data.agreementPricing.agreementTerm
        ),
        tagTotal: parseFloat(response.data.agreementPricing.tagTotal),
        sacPrice: parseFloat(response.data.agreementPricing.cashPrice),
        inventory: collectPriceArray,
        customer: [
          {
            customerId: parseInt(customerId),
            priority: 1,
          },
        ],
        AgreementTotal: response.data.agreementPricing.agreementTotal,
        TireAgreementFlow: tireController,
        itemDescription:
          response.data.itemPricing[0].packageName !== null
            ? response.data.itemPricing[0].packageName
            : response.data.itemPricing[0].inventoryName,
        reinstateType: true,
        parentAgreementId: parseInt(agreementId),
        istransferRightsApplied: transferRights,
      };

      setItemSearchData(contextObject);
      setItemArray(collectPriceArray);
      childFunc.current.deleteFromGrid();
      setitemArrayData(collectPriceArray);
      setInternalContextProvider(response.data);
      setShowAddedItem(true);
      setsearchCriteria({
        ...searchCriteria,
        itemNo: '',
        serialNo: '',
        bracket: '',
        brand: '',
        model: '',
        conditionId: null,
        departments: '',
        subDepartments: '',
      });
      setreinstateDepartment('');
    } else if (response.status == 400 || response.status == 500) {
      // setpackageExceptionController(true);
      // setmodalController({
      //   ...modalController,
      //   itemPricingFail: true,
      // });
      setpricingFailPopup(true);
    }
  };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
  };

  const closeSLA = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    setOpenPriceTag(true);
  };

  const SLACompleted = (value: any) => {
    setSecondFactorEnabled(false);
    tagSingleInvCaller();
  };

  const triggerNextModal = (inventoryId, inventoryPriceId, decider) => {
    console.log('sdfbvfdfv inventoryId sd', inventoryId);
    console.log('sdfdedcx inventoryPriceId bvc', inventoryPriceId);

    setnewSingleInvPricingInvArray([
      ...newSingleInvPricingInvArray,
      {
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      },
    ]);

    // setnewSingleInvPricingInvArray({
    //   inventoryId: Number(inventoryId),
    //   inventoryPriceId: Number(inventoryPriceId),
    // });
    setOpenPriceTag(false);
    if (decider === 'Printed') {
      setSecondFactorEnabled(true);
    } else if (decider === 'Active') {
      tagSingleInvCaller({
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      });
    }
  };

  const openPriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              marginRight: '48px',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
          >
            Inventory # : {tagBlueLabel.inventoryNumber}
          </Typography>
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              tagBlueLabel?.inventoryDesc?.length > 22
                ? tagBlueLabel?.inventoryDesc
                : ''
            }
          >
            Description : {truncString(tagBlueLabel.inventoryDesc, 22, '...')}
          </Typography>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: sansBold, fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${leftValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {leftValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${leftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${leftValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    leftValue.inventoryPriceId,
                    'Active'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '200px',
              right: '320px',
              top: '140px',
              marginTop: '15px',
            }}
          ></Divider>
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: sansBold, fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${rightValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {rightValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${rightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography style={{ color: '#4A5174', fontFamily: sansBold }}>
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: sansBold,
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${rightValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    rightValue.inventoryPriceId,
                    'Printed'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    console.log('updated value of betterPriceData is', betterPriceData);
  }, [betterPriceData]);

  return (
    <div className={`${classes.agreementContainerSpacing} ${showItemSearch}`}>
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={dynamicViewClass}>
          <Grid item className={altersearch}>
            {agreementCreateStatus.agreementCreatedStatus === false ? (
              <div>
                <Typography className={`${classes.title} ${classes.mt3}`}>
                  Reinstatable Items
                </Typography>
                <Card
                  className={`${classes.card} ${classes.mb4} ${classes.p3}`}
                >
                  <CardContent className={classes.p0}>
                    <ReinstetementGrid
                      throwStyle={classes}
                      triggerremoveReinstateGrid={itemArray}
                      receiveReinstateSearch={performReinstateSearch}
                      childFunc={childFunc}
                      triggeraddItem={reinstateAddItem}
                    />
                  </CardContent>
                </Card>
              </div>
            ) : null}
            <SearchFilter
              searchClick={searchClick}
              IsPackage={true}
              throwStyle={classes}
              controlSearchButton={allowGridCallMethod}
              throwDeparment={reinstateDepartment}
            />
            <div>
              {loaderBundle.loaderController === true &&
              loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />
              ) : (
                gridCallMethod()
              )}
            </div>
          </Grid>
          {showAddedItem ? (
            <Usercontext.Provider value={internalContextData}>
              <AddedItem
                triggeroneDelete={deleteSingleItem}
                module="Agreement"
                throwStyle={classes}
              />
            </Usercontext.Provider>
          ) : null}
        </Grid>

        <FooterContent
          setItemsearchCompleted={props.setItemSearchCompleted}
          throwStyle={classes}
          throwDepr={addedItem}
          func={pull_data}
          throwItemArray={itemArray}
          sometrigger={suppiler}
        />
      </div>
      <RACModalCard
        isOpen={pricingFailPopup}
        maxWidth="xs"
        borderRadius={radiusClass}
        //eslint-disable-next-line react/no-children-prop
        children={openItemPricingFail()}
      />
      <RACModalCard
        isOpen={isBetterRate}
        maxWidth="xs"
        borderRadius={radiusClass}
        //eslint-disable-next-line react/no-children-prop
        children={betterRateModal()}
        closeIcon={true}
        onClose={() => setisBetterRate(false)}
      />
      <RACModalCard
        isOpen={openPriceTag}
        maxWidth={'sm'}
        borderRadius={radiusClass}
        title={'Please choose the price'}
        closeIcon={true}
        onClose={() => setOpenPriceTag(false)}
        //eslint-disable-next-line react/no-children-prop
        children={openPriceModal()}
      />
      {SecondFactorEnabled === true ? (
        <SecondFactor
          // setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCancelClick={SLACancel}
          // setTwoFactorCompleted={setTwoFactorCompleted}
          setTwoFactorCompleted={SLACompleted}
          closeSLAClicked={closeSLA}
          // setRoleFromSLAModule={provideSLAData}
          moduleName={ModuleName}
          currentRole={CurrentRole}
          // minRole={SLAMinRole}
          throwCurrentPage="ItemSearch"
        />
      ) : null}
    </div>
  );
};

export default ReinstateSearchResult;
