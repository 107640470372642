/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as Addimage } from '../../../assets/images/ImageAdd.svg';
import { ReactComponent as Successimage } from '../../../assets/images/success-icon.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import Eposchedule from '../../Rental/AddCoCustomer/Eposchedule';
import AddCoCustomer from '../../Rental/AddCoCustomer/AddCocustomer';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Alertimage } from '../../../assets/images/no-records-found.svg';
import {
  GetDeliveryAddress,
  GetTimeSlots,
  Getglobalcustomerid,
  updatecustomer,
  FinishdeliveryStatus,
  CoworkerRequired,
  GetstateDropdown,
  GetSuggestedAddress,
  getAgreementInfo,
  UpdateAgreement,
  GenerateReceipt,
  LoadDeliveryreceipt,
  getnextpaymentamount,
} from '../../../api/user';
import { addDays } from 'date-fns';
import {
  RACRadio,
  RACTextbox,
  RACDatePicker,
  RACButton,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACSelect,
  RACModalCard,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from '@rentacenter/racstrap';

import clsx from 'clsx';
import { agreementDeliveryStyles } from '../../../JSstyles/agreementDeliveryStyles';
import { SwitchOutAgreementContext } from '../../../context/SwitchOutAgreementContext';
import { AgreementContext } from '../../../context/AgreementContext';
let addRowArray: any;
// eslint-disable-next-line prefer-const
addRowArray = [];
// const BindtimeslotId = '';
let BindtimeslotDate = '';

interface ParamType {
  customerId: string;
  agreementId: string;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function SwitchOutDeliverymethod(props: any) {
  const classes = agreementDeliveryStyles();
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  // const { addedItem } = useContext(SwitchOutAgreementContext);

  const history = useHistory();
  const { viewAgrData, SetaddressId, addressId } = useContext(AgreementContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const {
    addedItem,
    setdeliveryMethodData,
    entireAgrInfoResponse,
    setaddedItem,
    setsearchResult,
    setitemArray,
    setSwitchoutcopyArrayData,
    setagreementCreateStatus,
    setcontainCondition,
    setcontainSubDepartment,
    setpendingSOAgr,
    setselectedInvNumber,
    isswitchoutCancelled,
    setisswitchoutCancelled,
  } = useContext(SwitchOutAgreementContext);
  const [AgrNumber, setAgrNumber] = useState<any>();
  const [MaxDate, setMaxDate] = useState('');
  const [InventoryNumber, setInventoryNumber] = useState<any>();
  const [AppoinmentSource, setAppoinmentSource]: any = React.useState('');
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Footer contex', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  // Setting up State Variables for the Component

  // State Variables for the  the ID's that we use in the component

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agreementid, Setagreementid] = useState('');
  const [IdAddress, SetIdAddress] = useState(''); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeslotreason, SetTimeslotReason]: any = useState();
  const [Coworkers, SetCoworkers]: any = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [EnableLoaderButton, SetEnableLoaderButton] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getaddress, SetGetAddress] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeslotid, Settimeslotid] = useState('');

  // State Variables for the radio buttons in Delivery Type heading

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [DeliveryRdo, SetDeliveryRdo] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CarryoutRdo, SetCarryouutRdo] = useState('');
  const [Deliverytype, SetDeliverytype] = useState('D');
  const [checkDeliverytype, SetcheckDeliverytype] = useState('DEL');

  // State Variables for the Delivery Address in Delivery Address Heading
  const [StateconditionLoader, SetStateconditionLoader] = useState(true);
  const [Addressline1, SetAddressline1] = useState('');
  const [Addressline2, SetAddressline2] = useState('');
  // const [Apt, SetApt] = useState('');
  // const [POBox, SetPOBox] = useState('');
  const [Zip, SetZip] = useState('');
  const [City, SetCity] = useState('');
  const [State, SetState] = useState('');

  // State Variables for the Pop-Up display on clicking Select Other Address

  const [Popup, SetPopup] = useState(false);

  // State Variables for the Delivery Address that is displayed in the input field in the Pop-up

  const [Line1Popup, SetLine1Popup] = useState('');
  const [Line2Popup, SetLine2Popup] = useState('');

  const [ZipPopup, SetZipPopup] = useState('');
  // const [Plus4Popup, SetPlus4Popup] = useState('');
  const [CityPopup, SetCityPopup] = useState('');
  const [StatePopup, SetStatePopup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [StatePopupId, SetStatePopupId] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Statedrop, SetStatedrop]: any = useState([]);

  // State Variables for adding rows in the Pop-Up below the input field for each value entered

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addRow, SetAddRow] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeSlotData, SettimeSlotData]: any = useState([]);
  const [coWorkerRequiredArray, setcoWorkerRequiredArray]: any = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pdfresponse, setpdfresponse] = useState([]);
  // State Variables for showing details in Delivery Details heading

  // eslint-disable-next-line sonarjs/no-duplicate-string
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));

  const [Time, SetTime] = useState('');

  const [Instructions, SetInstructions] = useState('');
  const [successpopup, Setsuccesspopup] = useState(false);
  // State Variables for displaying 5 days from the Selected Date

  const [TEMP, SetTEMP] = useState('0');
  const [inventoryid, Setinventoryid] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Errorpopup, SetErrorpopup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agreementNumber, SetagreementNumber] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Starttime, SetStarttime]: any = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Endtime, SetEndtime]: any = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [enableadd, Setenableadd] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableFinish, SetenableFinish] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [enableSwitchOut, SetenableSwitchOut] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editdisable, Seteditdisable] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [InputGrid, SetInputGrid] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [EpoProps, SetEpoProps] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Cocustomer, SetCocustomer] = useState<any>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [savedisable, Setsavedisable] = useState(false);

  const [conditionLoader, SetconditionLoader] = useState(true);

  const [ValidateIsOpen, setValidateIsOpen] = useState(false);

  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SuggestedAddress, setSuggestedAddress]: any = useState();

  const StoreId = window.sessionStorage.getItem('storeNumber') || '';

  const [RescheduleEventId, SetRescheduleEventId] = useState('');

  const [masterLoader, setmasterLoader]: any = React.useState(true);

  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState('SUG');

  const [Opendeliveryconfirmpopup, SetOpendeliveryconfirmpopup] =
    useState(false);

  // const [OpenTimeslotbookedPopup, SetOpenTimeslotbookedPopup] = useState(false);
  const [RescheduleProps, SetRescheduleProps] = useState('Schedule');

  const [SaveTrigger, SetSaveTrigger] = useState(false);
  const [dateError, setdateError] = useState('');
  const [CancelSwitchOut, SetCancelSwitchOut] = useState(false);
  const [DisplayErrorMessage, SetDisplayErrorMessage] = useState('');
  const [ContextStartDate, SetContextStartDate] = useState('');
  const [ContextEndDate, SetContextEndDate] = useState('');
  const nextpaymentobj = {
    nextamountdue: '',
    nextduedate: '',
  };
  const [nextpaymentinfo, setnextpaymentinfo] = useState(nextpaymentobj);
  const [BindtimeslotId, setBindtimeslotId] = useState('');
  const [hideswitchoutbutton, sethideswitchoutbutton] = useState(true);
  const [disablecancelswitchout, setdisablecancelswitchout] = useState(true);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    /* eslint-disable no-console */
    console.log('Props Log', props);

    if (props !== undefined && props.reScheduleData !== undefined) {
      SetRescheduleProps('Reschedule');
      getCoworkerRequiredData();
      getStatedata();
      GetAddressMethod();
      getInventoryId();
      setmasterLoader(false);
      GetTimeSlot(
        new Date(
          props.reScheduleData.appointmentDate !== undefined &&
          props.reScheduleData.appointmentDate !== ''
            ? props.reScheduleData.appointmentDate
            : ''
        )
      );
      // eslint-disable-next-line no-console
      console.log(coWorkerRequiredArray);
      setBindtimeslotId(
        props.reScheduleData.timeSlotId !== undefined &&
          props.reScheduleData.timeSlotId !== ''
          ? props.reScheduleData.timeSlotId
          : ''
      );
      // BindtimeslotId =
      //   props.reScheduleData.timeSlotId !== undefined &&
      //   props.reScheduleData.timeSlotId !== ''
      //     ? props.reScheduleData.timeSlotId
      //     : '';
      BindtimeslotDate =
        props.reScheduleData.appointmentDate !== undefined &&
        props.reScheduleData.appointmentDate !== ''
          ? props.reScheduleData.appointmentDate
          : '';

      const StartingTime =
        props.reScheduleData.appointmentStartTime !== undefined &&
        props.reScheduleData.appointmentStartTime !== ''
          ? props.reScheduleData.appointmentStartTime
          : '';
      const EndingTime =
        props.reScheduleData.appointmentEndTime !== undefined &&
        props.reScheduleData.appointmentEndTime !== ''
          ? props.reScheduleData.appointmentEndTime
          : '';
      const slicedStartdate =
        StartingTime.slice(0, 5) + StartingTime.slice(8, StartingTime.length);

      const Selectedslot = slicedStartdate + '-' + EndingTime;
      SetTime(Selectedslot);
      SetContextStartDate(
        props.reScheduleData.appointmentStartTime !== undefined &&
          props.reScheduleData.appointmentStartTime !== ''
          ? props.reScheduleData.appointmentStartTime
          : ''
      );

      SetContextEndDate(
        props.reScheduleData.appointmentEndTime !== undefined &&
          props.reScheduleData.appointmentEndTime !== ''
          ? props.reScheduleData.appointmentEndTime
          : ''
      );

      setStartDate(moment(BindtimeslotDate).format('YYYY-MM-DD'));

      SetCoworkers(
        props.reScheduleData.coWorkersRequired !== undefined &&
          props.reScheduleData.coWorkersRequired !== ''
          ? props.reScheduleData.coWorkersRequired
          : ''
      );
      SetAddressline1(
        props.reScheduleData.addressLine1 !== undefined &&
          props.reScheduleData.addressLine1 !== ''
          ? props.reScheduleData.addressLine1
          : ''
      );
      SetAddressline2(
        props.reScheduleData.addressLine2 !== undefined &&
          props.reScheduleData.addressLine2 !== ''
          ? props.reScheduleData.addressLine2
          : ''
      );
      SetZip(
        props.reScheduleData.zip !== undefined &&
          props.reScheduleData.zip !== ''
          ? props.reScheduleData.zip
          : ''
      );
      SetCity(
        props.reScheduleData.city !== undefined &&
          props.reScheduleData.city !== ''
          ? props.reScheduleData.city
          : ''
      );
      SetState(
        props.reScheduleData.state != undefined &&
          props.reScheduleData.state != ''
          ? props.reScheduleData.state
          : ''
      );
      SetInstructions(
        props.reScheduleData.instructions !== undefined &&
          props.reScheduleData.instructions !== ''
          ? props.reScheduleData.instructions
          : ''
      );
      SetRescheduleEventId(
        props.reScheduleData.eventId !== undefined &&
          props.reScheduleData.eventId !== ''
          ? props.reScheduleData.eventId
          : ''
      );
      Settimeslotid(
        props.reScheduleData.timeSlotId != undefined &&
          props.reScheduleData.timeSlotId !== ''
          ? props.reScheduleData.timeSlotId
          : ''
      );
      SetIdAddress(
        props.reScheduleData.addressId != undefined &&
          props.reScheduleData.addressId !== ''
          ? props.reScheduleData.addressId
          : ''
      );
      if (
        props.reScheduleData.deliveryType != undefined &&
        props.reScheduleData.deliveryType !== ''
      ) {
        if (props.reScheduleData.deliveryType == 'Delivery') {
          SetcheckDeliverytype('DEL');
          SetDeliverytype('D');
        } else {
          SetcheckDeliverytype('CAR');
          SetDeliverytype('CO');
        }
      }
    } else {
      SetRescheduleProps('Schedule');
      getCoworkerRequiredData();
      getStatedata();
      GetTimeSlot(new Date());
      GetAddressMethod();
      getInventoryId();
      setmasterLoader(false);
      // eslint-disable-next-line no-console
      console.log(coWorkerRequiredArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
useEffect(()=>{
  if (viewAgrData?.data?.agreementInformation) {
    setAgrNumber(viewAgrData?.data?.agreementInformation?.agreementNumber);
    setInventoryNumber(addedItem?.itemPricing[0]?.inventoryNumber)
  }
}, [viewAgrData, addedItem])
  // useEffect(() => {
  //   Setinventoryid(addedItem.itemPricing[0].inventoryNumber);
  //   // Agreement Number should be Added here after Context is set
  // }, [addedItem]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('view agreement Context loggerreeds');
    console.log('agrNo checker of value', entireAgrInfoResponse);

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (
      entireAgrInfoResponse !== undefined &&
      entireAgrInfoResponse.agreementWorkflow != undefined &&
      entireAgrInfoResponse.agreementWorkflow != null &&
      entireAgrInfoResponse.agreementWorkflow.isSwitchoutEnabled != undefined &&
      entireAgrInfoResponse.agreementWorkflow.isSwitchoutEnabled != null &&
      entireAgrInfoResponse.agreementInformation != undefined &&
      entireAgrInfoResponse.agreementInformation != null &&
      entireAgrInfoResponse.agreementInformation.storeNumber != undefined &&
      entireAgrInfoResponse.agreementInformation.storeNumber != null
    ) {
      if (
        entireAgrInfoResponse.agreementWorkflow.isSwitchoutEnabled == '1' &&
        entireAgrInfoResponse.agreementInformation.storeNumber ==
          window.sessionStorage.getItem('storeNumber')
      ) {
        console.log('agrementudhishidcdi---->', hideswitchoutbutton);
        sethideswitchoutbutton(false);
        setdisablecancelswitchout(false);
      } else if (
        entireAgrInfoResponse.agreementWorkflow.isSwitchoutEnabled == '1' &&
        entireAgrInfoResponse.agreementInformation.storeNumber !==
          window.sessionStorage.getItem('storeNumber')
      ) {
        sethideswitchoutbutton(false);
        setdisablecancelswitchout(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(StatePopup);
    if (
      Line1Popup != '' &&
      CityPopup != '' &&
      ZipPopup != '' &&
      StatePopup !== '' &&
      StatePopup !== '0'
    ) {
      Setenableadd(false);
    } else {
      Setenableadd(true);
    }
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (
      ZipPopup !== undefined &&
      ZipPopup !== null &&
      ZipPopup != '' &&
      ZipPopup.length > 0
    ) {
      if (
        ZipPopup.length == 5 &&
        Line1Popup != '' &&
        CityPopup != '' &&
        StatePopup !== '' &&
        StatePopup !== '0'
      ) {
        Setenableadd(false);
      } else if (
        Line1Popup != '' &&
        CityPopup != '' &&
        StatePopup !== '' &&
        StatePopup !== '0' &&
        ZipPopup.length == 10
      ) {
        Setenableadd(false);
      } else {
        Setenableadd(true);
      }
    } else {
      Setenableadd(true);
    }
    console.log('check timeslotid value', timeslotid);

    if (Deliverytype != '' && timeslotid != '') {
      console.log('bfd reachefwbdcb', timeslotid);
      console.log('edvbjdkvj reachefwbdcb', Deliverytype);
      SetenableFinish(false);
    } else {
      console.log('2654edcw48wd');
      SetenableFinish(true);
    }
  }, [Line1Popup, ZipPopup, CityPopup, timeslotid, StatePopup, Deliverytype]);

  useEffect(
    () => {
      if (
        props.setItemSearchCompleted &&
        props.setAgrDetailsCompleted &&
        props.setInitialPaymentCompleted
      ) {
        props.setItemSearchCompleted(true);
        props.setAgrDetailsCompleted(true);
        props.setInitialPaymentCompleted(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (
      props !== undefined &&
      props.isDeliveryReschedule !== undefined &&
      props.isDeliveryReschedule.deliveryrescheduling != undefined &&
      props.isDeliveryReschedule.deliveryrescheduling === true &&
      SaveTrigger === false
    ) {
      Complete_delivery_status();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // const PropsDetails = () => {
  //   SetEpoProps({ agreementNumber });
  //   SetCocustomer({ agreementNumber, agreementId });
  // };

  // const getStatedata = async () => {
  //   PropsDetails();
  //   const stateDropdown = await GetstateDropdown();
  //   // eslint-disable-next-line no-console
  //   console.log('STATESSSSS', stateDropdown);
  //   SetStatedrop(stateDropdown);
  // };
  const getStatedata = async () => {
    // PropsDetails();
    const stateDropdown = await GetstateDropdown();
    if (stateDropdown.status === 200) {
      // eslint-disable-next-line no-console
      const buildConditionOption = stateDropdown.data;

      const setterCondition = [
        { stateAbbreviation: 'Select' },

        ...buildConditionOption,
      ];
      console.log('STATESSSSS', setterCondition);
      SetStatedrop(setterCondition);
      SetStateconditionLoader(false);
    } else {
      const setterCondition = [{ stateAbbreviation: 'Select' }];
      SetStatedrop(setterCondition);
      SetStateconditionLoader(false);
    }
  };

  // const getCoworkerRequiredData = async () => {
  //   const bodyobject = {
  //     storeNumbers: [StoreId],

  //     paramKeyNames: ['CoworkerRequired'],
  //   };
  //   const CoworkerNum = await CoworkerRequired(bodyobject);
  //   const value =
  //     CoworkerNum !== null &&
  //     CoworkerNum.storeProfileResponse !== undefined &&
  //     CoworkerNum.storeProfileResponse &&
  //     CoworkerNum.storeProfileResponse.configDetails !== undefined &&
  //     CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
  //     CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
  //       undefined &&
  //     CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length > 0
  //       ? CoworkerNum.storeProfileResponse.configDetails[0].configDetails[0]
  //           .paramValue
  //       : 0;

  //   // eslint-disable-next-line no-console
  //   console.log(value);
  //   const integervalue = parseInt(value);
  //   const arrayNumber: any = [];

  //   for (let i = 1; i <= integervalue; i++) {
  //     arrayNumber.push(i);
  //   }
  //   // eslint-disable-next-line no-console
  //   console.log('ArrayNumber', arrayNumber);
  //   setcoWorkerRequiredArray(arrayNumber);
  //   SetconditionLoader(false);
  // };

  const getCoworkerRequiredData = async () => {
    const bodyobject = {
      storeNumbers: [StoreId],

      paramKeyNames: ['CoworkerRequired'],
    };
    const CoworkerNum = await CoworkerRequired(bodyobject);
    if (CoworkerNum.status === 200) {
      const value =
        CoworkerNum.data !== null &&
        CoworkerNum.data.storeProfileResponse !== undefined &&
        CoworkerNum.data.storeProfileResponse &&
        CoworkerNum.data.storeProfileResponse.configDetails !== undefined &&
        CoworkerNum.data.storeProfileResponse.configDetails.length > 0 &&
        CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
        CoworkerNum.data.storeProfileResponse.configDetails[0].configDetails
          .length > 0
          ? CoworkerNum.data.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue
          : 0;

      // eslint-disable-next-line no-console
      console.log(value);
      const integervalue = parseInt(value);
      const arrayNumber: any = [];

      for (let i = 1; i <= integervalue; i++) {
        arrayNumber.push(i);
      }
      // eslint-disable-next-line no-console
      console.log('ArrayNumber', arrayNumber);
      setcoWorkerRequiredArray(arrayNumber);
      SetconditionLoader(false);
    } else {
      const setterCondition = ['Select'];
      setcoWorkerRequiredArray(setterCondition);
      SetconditionLoader(false);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const GetAddressMethod = async () => {
    const getdeliveryaddress = await GetDeliveryAddress(customerId);

    // eslint-disable-next-line no-console
    console.log(getdeliveryaddress.data, 'address value');

    if (
      getdeliveryaddress.data !== undefined &&
      getdeliveryaddress.data.getAdd !== undefined &&
      getdeliveryaddress.data.getAdd !== null &&
      getdeliveryaddress.data.getAdd.length > 0
    ) {
      // eslint-disable-next-line no-console
      console.log(getdeliveryaddress.data, 'Inside Log');
      SetGetAddress(getdeliveryaddress.data.getAdd);
      addRowArray = getdeliveryaddress.data.getAdd;
    }
    // eslint-disable-next-line no-console
    console.log(getdeliveryaddress.data, 'testingresponsetimeslot');

    if (
      props == undefined ||
      props == null ||
      props == '' ||
      props.reScheduleData == undefined ||
      props.reScheduleData == null ||
      props.reScheduleData == ''
    ) {
      SetAddressline1(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressLine1
          : ''
      );
      SetAddressline2(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressLine2
          : ''
      );

      const Zipcode =
        getdeliveryaddress.data !== undefined &&
        getdeliveryaddress.data.getAdd !== undefined &&
        getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].zip
          : '';

      let plus4Code = '';

      if (getdeliveryaddress.data.getAdd[0].plus4 != null) {
        plus4Code =
          getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
            ? getdeliveryaddress.data.getAdd[0].plus4
            : '';

        // const tempik = temps '-'

        SetZip(Zipcode + '-' + plus4Code);
      } else {
        SetZip(Zipcode);
      }

      SetCity(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].city
          : ''
      );
      SetState(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].stateProvinceName
          : ''
      );
      SetStatePopupId(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].stateProvinceId
          : ''
      );
      SetIdAddress(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressId
          : ''
      );
      SetaddressId(
        getdeliveryaddress.data !== undefined &&
          getdeliveryaddress.data.getAdd !== undefined &&
          getdeliveryaddress.data.getAdd.length > 0
          ? getdeliveryaddress.data.getAdd[0].addressId
          : ''
      );
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const GetTimeSlot = async (StartingDate: any) => {
    //const startingdate = new Date(StartingDate);
    const startingdate = StartingDate;
    const maximumdate = moment(StartingDate).add(3, 'months').format('YYYY-MM-DD');
    setMaxDate(maximumdate);
    //const endingdate = addDays(startingdate, 9);
    const endingdate = moment(StartingDate).add(9, 'days').format('YYYY-MM-DD')


    console.log(endingdate);
    const formattedstartingdate = moment(startingdate).format('YYYY-MM-DD');
    const formattedenddate = moment(endingdate).format('YYYY-MM-DD');
    // const formattedstartingdate = changeDateFormat(startingdate);
    // const formattedenddate = changeDateFormat(endingdate);
    console.log('DAtsssssssrefd', formattedenddate);
    // const bodyobj = {
    //   customerId: customerId,
    //   startDate: formattedstartingdate,
    //   endDate: formattedenddate,
    //   storeId: StoreId,
    //   eventSource: 'Store',
    // };

    const payload = {
      isLoaner: 'Y',
      customerId: customerId,
      endDate: formattedenddate,
      eventSource: 'STO',
      startDate: formattedstartingdate,
      storeId: window.sessionStorage.getItem('storeNumber'),
    };

    const timeslotresponse = await GetTimeSlots(payload);

    if (timeslotresponse.data !== undefined) {
      // SetTimeslotReason(timeslotresponse);
      setAppoinmentSource(timeslotresponse.data.appointmentSource);
      const TimeSlotsDetailsArrayValue: any = [];
      interface TimeSlotDetails {
        timeSlot: string;
        startTime: string;
        endTime: string;
        status: string;
      }

      interface TimeSlotDetailsArray {
        Date: string;
        TimeSlots: TimeSlotDetails;
      }

      const TimeSlotVariable = timeslotresponse.data.timeSlots;
      Object.keys(TimeSlotVariable).forEach(function (key) {
        const value = TimeSlotVariable[key];
        //  console.log(key + ':' + value);
        const Timeslots: any = [];

        value.map((value: any) => {
          const timeslot_object = {} as TimeSlotDetails;
          timeslot_object.timeSlot = value.timeSlot;
          timeslot_object.startTime = value.startTime;
          timeslot_object.endTime = value.endTime;
          timeslot_object.status = value.status;
          Timeslots.push(timeslot_object);
        });

        const TotalTimeSlots = {} as TimeSlotDetailsArray;
        TotalTimeSlots.Date = key;
        TotalTimeSlots.TimeSlots = Timeslots;
        TimeSlotsDetailsArrayValue.push(TotalTimeSlots);
      });
      SettimeSlotData(TimeSlotsDetailsArrayValue);

      // eslint-disable-next-line no-console
      console.log(TimeSlotsDetailsArrayValue, 'timeslot converted array');
    }
    // eslint-disable-next-line no-console
    console.log(timeslotresponse.data, 'testingresponsetimeslot');
  };

  const getInventoryId = async () => {
    console.log('view agreementinforesponse', entireAgrInfoResponse);
    const agreementInfoResult = await getAgreementInfo(agreementId);
    if (
      agreementInfoResult != undefined &&
      agreementInfoResult != null &&
      agreementInfoResult.data != undefined &&
      agreementInfoResult.data != null &&
      agreementInfoResult.data.agreementInventories[0] != undefined &&
      agreementInfoResult.data.agreementInventories[0] != null &&
      agreementInfoResult.data.agreementInventories[0].inventoryId !=
        undefined &&
      agreementInfoResult.data.agreementInventories[0].inventoryId != null &&
      agreementInfoResult.data.agreementWorkflow != undefined &&
      agreementInfoResult.data.agreementWorkflow != null &&
      agreementInfoResult.data.agreementWorkflow.agreementNumber != undefined &&
      agreementInfoResult.data.agreementWorkflow.agreementNumber != null
    ) {
      const InventoryArray: any = [];
      InventoryArray.push(
        agreementInfoResult.data.agreementInventories[0].inventoryId
      );
      Setinventoryid(InventoryArray);
      SetagreementNumber(
        agreementInfoResult.data.agreementWorkflow.agreementNumber
      );
      SetEpoProps({
        agreementNumber:
          agreementInfoResult.data.agreementWorkflow.agreementNumber,
      });
    }
  };

  // Disabling the delivery address Pop up, by clicking on Select Delivery Address.

  const disPopup = async () => {
    SetPopup(true);
    // SetAddRow(getaddress);
    // SetenableRdo(true);
  };

  const hideDeliveryaddress = (event: any) => {
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (
      event?.target.value == 'CarryoutRdo' ||
      document.getElementById('carryout')
    ) {
      SetDeliverytype('CO');
    } else {
      SetDeliverytype('D');
    }
    SetcheckDeliverytype('CAR');
    SetSaveTrigger(false);
  };

  // Function for showing Delivery Address when Delivery is clicked again in Delivery Type heading

  const showDeliveryaddress = (event: any) => {
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (
      event?.target.value == 'DeliveryRdo' ||
      document.getElementById('delivery')
    ) {
      SetDeliverytype('D');
    } else {
      SetDeliverytype('CO');
    }
    SetcheckDeliverytype('DEL');
    SetSaveTrigger(false);
  };
  // console.log(Deliverytype);
  // Function for Cancel button and close image in Delivery Address Pop-Up

  const cancelbtn = () => {
    setmasterLoader(false);
    SetPopup(false);
  };

  const changeDateFormat = (date: Date) => {
    // eslint-disable-next-line prefer-const
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      // eslint-disable-next-line prefer-const
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  // Function for displaying selected and next five days

  const dates = async (selectedFormatdate: any) => {
    const validateYear = moment(selectedFormatdate).add(12, 'months').format('YYYY-MM-DD');
    const selectFormatdate = moment(selectedFormatdate).format('YYYY-MM-DD')
    if(selectFormatdate>=moment(new Date()).format('YYYY-MM-DD') && selectFormatdate < validateYear){      setdateError('')
      SettimeSlotData([]);
    setStartDate(moment(selectedFormatdate).format('YYYY-MM-DD'));

    const startDate = selectedFormatdate;

    GetTimeSlot(startDate);
    SetTime('');
    Settimeslotid('');
    console.log('beoefevcdvbh', BindtimeslotId);
    setBindtimeslotId('');
  }
  else{
    setStartDate(moment(new Date()).format('YYYY-MM-DD'));
    setdateError('Date must not be in the past')
  }
  };

  const getslotdetails = (
    avail: any,
    modifiedStarttime: any,
    modifiedEndtime: any
  ) => {
    console.log('ContextStart', modifiedStarttime);
    console.log('ContextEnd', modifiedEndtime);
    SetContextStartDate(modifiedStarttime);
    SetContextEndDate(modifiedEndtime);
    SetTime(avail.target.value);
    Settimeslotid(avail.target.id);
    setBindtimeslotId(avail.target.id);
    // BindtimeslotId = avail.target.id;
    BindtimeslotDate = avail.target.name;
    setStartDate(moment(BindtimeslotDate).format('YYYY-MM-DD'));
    buildTimeSlot();
    SetSaveTrigger(false);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const bindGrid = () => {
    if (
      getaddress != undefined &&
      getaddress != null &&
      getaddress.length > 0
    ) {
      // eslint-disable-next-line no-console
      console.log('addRow', getaddress);
      // SetEnableLoaderButton(false);
      return getaddress.map((val: any, index: any) => {
        const shtAddr1 =
          val.addressLine1 != null && val.addressLine1 != undefined
            ? val.addressLine1.length > 15
              ? val.addressLine1.substring(0, 25) + '...'
              : val.addressLine1
            : '';
        const shtAddr2 =
          val.addressLine2 != null && val.addressLine2 != undefined
            ? val.addressLine2.length > 15
              ? val.addressLine2.substring(0, 25) + '...'
              : val.addressLine2
            : '';

        let Zipandand4plus = '';
        // eslint-disable-next-line no-console
        console.log(val.zip);
        // eslint-disable-next-line no-console
        console.log(val.plus4);
        if (val.zip != undefined && val.zip != null) {
          if (val.plus4 != null && val.plus4 != undefined) {
            Zipandand4plus = val.zip + '-' + val.plus4;
          } else {
            Zipandand4plus = val.zip;
          }
        }

        const shtZip = Zipandand4plus;

        const shtCity =
          val.city != null && val.city != undefined
            ? val.city.length > 15
              ? val.city.substring(0, 25) + '...'
              : val.city
            : '';
        const shtState =
          val.stateProvinceName != null && val.city != undefined
            ? val.stateProvinceName.length > 15
              ? val.stateProvinceName.substring(0, 25) + '...'
              : val.stateProvinceName
            : '';

        console.log('addressId', val.addressId);

        return (
          // eslint-disable-next-line react/jsx-key
          <RACTableRow>
            <RACTableCell>
              <RACRadio
                value={val.addressId}
                id={index}
                name="flexRadioDefault"
                onClick={Selectaddress.bind(event)}
                checked={TEMP == index ? true : false}
                size={'small'}
              />
              <label
                title={val.addressLine1}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.addressLine1}
                id={'addLin1Lab' + index}
              >
                {shtAddr1}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.addressLine2}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.addressLine2}
                id={'addLin2Lab' + index}
              >
                {shtAddr2}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.zip_plus4}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.zip_plus4}
                id={'addZipLab' + index}
              >
                {shtZip}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.city}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.city}
                id={'addCityLab' + index}
              >
                {shtCity}
              </label>
            </RACTableCell>
            <RACTableCell>
              <label
                title={val.stateProvinceName}
                data-toggle="tooltip"
                data-placement="top"
                data-original-title={val.stateProvinceName}
                id={'addStateLab' + index}
              >
                {shtState}
              </label>
            </RACTableCell>
          </RACTableRow>
        );
      });
    } else {
      return (
        <div className={classes.Norecords}>
          <CircularProgress />
        </div>
      );
    }
  };

  // const addadress = async (postaddress: any) => {
  //   const updatecustomerresponse = await updatecustomer(postaddress);
  //   if (
  //     updatecustomerresponse !== undefined &&
  //     updatecustomerresponse.data !== undefined &&
  //     updatecustomerresponse !== null &&
  //     updatecustomerresponse.data.customerId !== undefined
  //   ) {
  //     SetLine1Popup('');
  //     SetLine2Popup('');
  //     SetZipPopup('');
  //     SetCityPopup('');
  //     SetStatePopup('');

  //     const getdeliveryaddress = await GetDeliveryAddress(customerId);

  //     // eslint-disable-next-line no-console
  //     console.log(getdeliveryaddress, 'address value');
  //     setmasterLoader(false);

  //     if (
  //       getdeliveryaddress !== undefined &&
  //       getdeliveryaddress.getAdd !== undefined &&
  //       getdeliveryaddress.getAdd !== null &&
  //       getdeliveryaddress.getAdd.length > 0
  //     ) {
  //       // eslint-disable-next-line no-console
  //       console.log(getdeliveryaddress, 'Inside Log');
  //       SetGetAddress(getdeliveryaddress.getAdd);
  //       addRowArray = getdeliveryaddress.getAdd;
  //       // SetAddRow(addRowArray);
  //     }
  //   }
  // };

  const addadress = async (postaddress: any) => {
    const updatecustomerresponse = await updatecustomer(postaddress);
    if (updatecustomerresponse.status === 400) {
      SetDisplayErrorMessage('No Records Found');
      setmasterLoader(false);
      SetErrorpopup(true);
    } else if (updatecustomerresponse.status === 500) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      SetDisplayErrorMessage('Something Went Wrong');
      setmasterLoader(false);
      SetErrorpopup(true);
    }
    if (
      updatecustomerresponse !== undefined &&
      updatecustomerresponse.data !== undefined &&
      updatecustomerresponse !== null &&
      updatecustomerresponse.data.customerId !== undefined
    ) {
      SetLine1Popup('');
      SetLine2Popup('');
      SetZipPopup('');
      SetCityPopup('');
      SetStatePopup('');

      const getdeliveryaddress = await GetDeliveryAddress(customerId);

      // eslint-disable-next-line no-console
      console.log(getdeliveryaddress.data, 'address value');
      setmasterLoader(false);

      if (
        getdeliveryaddress.data !== undefined &&
        getdeliveryaddress.data.getAdd !== undefined &&
        getdeliveryaddress.data.getAdd !== null &&
        getdeliveryaddress.data.getAdd.length > 0
      ) {
        // eslint-disable-next-line no-console
        console.log(getdeliveryaddress.data, 'Inside Log');
        SetGetAddress(getdeliveryaddress.data.getAdd);
        addRowArray = getdeliveryaddress.data.getAdd;
        SetAddRow(addRowArray);
      }
    }
  };
  const addGridRow = async () => {
    setmasterLoader(true);

    const ZipPlus4Split: any = ZipPopup.replace('-', '');
    console.log(ZipPlus4Split);

    const addressPayload = {
      MessageID: 'CMS006-93589345',
      MessageType: 'CMS006',
      MessageDTS: '2014-05-23-14:30:15:254633',
      MessageDTSTZOffset: '-06:00',
      RegionID: '1',
      ClientID: '8',
      ClientLocationNumber: '00006',
      ClientSource: '1',
      ClientOriginator: '1',
      LocationNumber: StoreId,
      EncryptionMethod: '0',
      Addresses: [
        {
          AddressID: '11114',
          StreetAddress: Line1Popup,
          City: CityPopup,
          StateProvince: StatePopup,
          PostalCode: ZipPlus4Split,
          Country: 'USA',
        },
      ],
      StoreConfig: {
        storeNumbers: [StoreId],
        paramKeyNames: ['AddressDoctorEnabled'],
      },
    };
    // try {
    //   const response: any = await GetSuggestedAddress(addressPayload);
    //   console.log('AddressDoctor', response);
    //   if (
    //     response !== undefined &&
    //     response !== null &&
    //     response.storeConfig !== undefined &&
    //     response.storeConfig.storeProfileResponse.configDetails[0]
    //       .configDetails[0].paramValue == 'Y'
    //   ) {
    //     setSuggestedAddress(response.validateAddress);
    //     setmasterLoader(false);
    //     setValidateIsOpen(true);
    //   }
    // } catch (e: any) {
    //   console.log('Error In Address Doctor', e);
    // }
    try {
      const response: any = await GetSuggestedAddress(addressPayload);
      console.log('AddressDoctor', response);
      if (response.status === 400) {
        SetDisplayErrorMessage('No Records Found');
        setmasterLoader(false);
        SetErrorpopup(true);
      } else if (response.status === 500) {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        SetDisplayErrorMessage('Something Went Wrong');
        setmasterLoader(false);
        SetErrorpopup(true);
      }
      if (
        response.data !== undefined &&
        response.data !== null &&
        response.data.storeConfig !== undefined &&
        (response.data.storeConfig.storeProfileResponse.configDetails[0]
          .configDetails[0].paramValue == 'Y' ||
          response.data.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == '1' ||
          response.data.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == 1)
      ) {
        setSuggestedAddress(response.data.validateAddress);
        // const ZipValue = response.validateAddress.Addresses.PostalCode;
        // if (ZipValue.length < 10) {
        //   const values = ZipValue.replace('-', '');
        //   SetTrimmedZip(values);
        // }

        setmasterLoader(false);
        setValidateIsOpen(true);
      }
    } catch (e: any) {
      console.log('Error In Address Doctor', e);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChange = (event: any) => {
    if (event.target.value !== undefined) {
      // eslint-disable-next-line no-console
      console.log('Inside', event.target.name);
      let regexItem;
      if (event.target.name !== 'state') {
        if (event.target.name == 'city') {
          regexItem = new RegExp('^[a-zA-Z]*$');
        } else if (event.target.name == 'zip') {
          regexItem = new RegExp('^[0-9 -]+$');
        }

        if (event.target.value.match(regexItem) || event.target.value == '') {
          console.log(event.target.value);
          // if (regexItem.test(event.target.value)) {
          if (event.target.name == 'addressLine1') {
            SetLine1Popup(event.target.value);
          } else if (event.target.name == 'addressLine2') {
            SetLine2Popup(event.target.value);
          } else if (event.target.name == 'city') {
            // eslint-disable-next-line no-console
            console.log('Inside cisty value', event.target.value);
            SetCityPopup(event.target.value);
          } else if (event.target.name == 'state') {
            SetStatePopup(event.target.value);
            SetStatePopupId(event.target.id);
          } else if (event.target.name == 'zip') {
            const value = event.target.value.replace('-', '');
            console.log(value);
            if (value.length > 5) {
              SetZipPopup(
                value.trim().substring(0, 5) +
                  '-' +
                  value.trim().substring(5, value.length)
              );
            } else {
              SetZipPopup(value);
            }
          }
        }
      }
    }
  };

  const Selectaddress = (event: any) => {
    /* eslint-disable no-console */
    console.log(event);
    /* eslint-disable no-console */
    console.log(event.target.id);
    console.log('TEMP 1', TEMP);
    SetTEMP(event.target.id);
    SetIdAddress(event.target.value);
    console.log('vhdjsjjdfjdfh', event.target.value);

    console.log('TEMP 2', TEMP);
    const temporary = TEMP;
    console.log('Var TEMP', temporary);
  };

  const Okpopup = () => {
    setmasterLoader(false);
    /* eslint-disable no-console */
    console.log('TEMP 3', TEMP);

    /* eslint-disable no-console */
    console.log('ArrayValue', addRowArray);
    /* eslint-disable no-console */
    console.log('array size', TEMP);
    SetAddressline1(addRowArray[TEMP].addressLine1);
    SetAddressline2(addRowArray[TEMP].addressLine2);

    if (addRowArray[TEMP].zip != null && addRowArray[TEMP].zip != undefined) {
      if (
        addRowArray[TEMP].plus4 != null &&
        addRowArray[TEMP].zip != undefined
      ) {
        SetZip(addRowArray[TEMP].zip + '-' + addRowArray[TEMP].plus4);
      } else {
        SetZip(addRowArray[TEMP].zip);
      }
    }
    SetCity(addRowArray[TEMP].city);
    SetState(addRowArray[TEMP].stateProvinceName);
    Setagreementid(IdAddress);
    SetPopup(false);
    SetSaveTrigger(false);
  };

  const Loadstate = () => {
    if (Statedrop !== undefined) {
      return Statedrop.map((entry: any, index: any) => {
        if (index === 0) {
          return { label: 'Select', value: '0' };
        } else {
          return {
            label: entry.stateAbbreviation,
            value: entry.stateAbbreviation,
          };
        }
      });
    } else {
      return [{ label: 'Select', value: '0' }];
    }
  };

  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);

      setBtnIsdisabled2(false);

      setdefaultSuggestedAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);

      setBtnIsdisabled2(true);

      setdefaultSuggestedAddress('ENT');
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const continueclick = async (type: any) => {
    setValidateIsOpen(false);
    setmasterLoader(true);

    const getGcidresponse = await Getglobalcustomerid(customerId);
    // SetEnableLoaderButton(true);
    // eslint-disable-next-line no-console
    console.log(
      'gcid response',
      getGcidresponse !== undefined &&
        getGcidresponse.data &&
        getGcidresponse.data.globalCustomerId !== undefined &&
        getGcidresponse.data.globalCustomerId !== null
        ? getGcidresponse.data.globalCustomerId
        : ''
    );

    if (type == 'SUG') {
      const ZipAddress = SuggestedAddress.Addresses.PostalCode;

      let postAddresszip = '';

      if (ZipAddress.length == 10) {
        postAddresszip = ZipPopup.slice(0, 5) + '-' + ZipPopup.slice(6, 10);
      } else if (ZipAddress.length >= 5) {
        postAddresszip = ZipAddress.slice(0, 5);
      }

      const postaddress = {
        customerId: customerId,
        globalCustomerId:
          getGcidresponse.data !== undefined &&
          getGcidresponse.data.globalCustomerId !== undefined &&
          getGcidresponse.data.globalCustomerId !== null
            ? getGcidresponse.data.globalCustomerId
            : '',

        addresses: [
          {
            addressType: 'DELIV',
            addressLine1: SuggestedAddress.Addresses.StreetAddress,
            addressLine2: Line2Popup,
            city: SuggestedAddress.Addresses.City,
            state: SuggestedAddress.Addresses.StateProvince,
            postalCode: postAddresszip,
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log('after api ', postaddress);
      if (postaddress != undefined) {
        addadress(postaddress);
        // SetenableRdo(false);
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    } else if (type == 'ENT') {
      let postAddresszip = '';
      if (ZipPopup.length == 10) {
        postAddresszip = ZipPopup.slice(0, 5) + '-' + ZipPopup.slice(6, 10);
      } else if (ZipPopup.length >= 5) {
        postAddresszip = ZipPopup.slice(0, 5);
      }

      const postaddress = {
        customerId: customerId,
        globalCustomerId:
          getGcidresponse.data !== undefined &&
          getGcidresponse.data.globalCustomerId !== undefined &&
          getGcidresponse.data.globalCustomerId !== null
            ? getGcidresponse.data.globalCustomerId
            : '',

        addresses: [
          {
            addressType: 'DELIV',
            addressLine1: Line1Popup,
            addressLine2: Line2Popup,
            city: CityPopup,
            state: StatePopup,
            postalCode: postAddresszip,
          },
        ],
      };
      // eslint-disable-next-line no-console
      console.log('after api ', postaddress);
      if (postaddress != undefined) {
        addadress(postaddress);
        // SetenableRdo(false);
      } else {
        return (
          <div className={classes.Norecords}>
            <CircularProgress />
          </div>
        );
      }
    }
  };
  const getnextamountdue = async () => {
    const paymentobject = {
      agreementIdCustomerId: [
        {
          agreementId: parseInt(agreementId),
        },
      ],
    };

    const getamountresponse = await getnextpaymentamount(paymentobject);

    if (getamountresponse.status === 504) {
      SetErrorpopup(true);
    } else {
      if (
        getamountresponse.data !== undefined &&
        getamountresponse.data !== null &&
        getamountresponse.data.amountDueResponse !== undefined &&
        getamountresponse.data.amountDueResponse !== null
      ) {
        const AmountResponse = getamountresponse.data.amountDueResponse.find(
          (x: { agreementId: string }) => x.agreementId == agreementId
        );
        /* eslint-disable no-console */
        console.log('agreementid related data', AmountResponse);

        if (
          AmountResponse !== undefined &&
          AmountResponse !== null &&
          AmountResponse !== '' &&
          AmountResponse.ldwAmount !== undefined &&
          AmountResponse.ldwAmount !== null
        ) {
          const Totalamountdue =
            parseFloat(AmountResponse.amountDue) +
            parseFloat(AmountResponse.ldwAmount);
          /* eslint-disable no-console */
          console.log('agreementid Total Amount', Totalamountdue);
          setnextpaymentinfo({
            ...nextpaymentinfo,
            nextamountdue: Totalamountdue.toFixed(2),
            nextduedate: AmountResponse.nextDueDate,
          });
          console.log('next amount due', nextpaymentinfo);
          return Totalamountdue.toFixed(2);
        } else {
          /* eslint-disable no-console */
          console.log('nextpaymentamount', getamountresponse.data);

          setnextpaymentinfo({
            ...nextpaymentinfo,
            nextamountdue:
              getamountresponse.data.amountDueResponse[0].amountDue,
            nextduedate:
              getamountresponse.data.amountDueResponse[0].nextDueDate,
          });
          console.log('next amount due', nextpaymentinfo);
          console.log(
            'getamountdue response',
            getamountresponse.data.amountDueResponse[0].amountDue
          );
          return getamountresponse.data.amountDueResponse[0].amountDue;
        }
      }
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const receiptstatus = async (nextamount: any) => {
    console.log('view params for nextamount', nextamount);
    const deliveryreceiptresponse = await LoadDeliveryreceipt(agreementId);
    if (
      deliveryreceiptresponse !== undefined &&
      deliveryreceiptresponse.responseData !== undefined
    ) {
      const TempArr: any = [];
      for (
        let i = 0;
        i <= deliveryreceiptresponse.responseData.length - 1;
        i++
      ) {
        const obj = {
          inventoryId: deliveryreceiptresponse.responseData[i].inventoryId,
          inventoryNumber:
            deliveryreceiptresponse.responseData[i].inventoryNumber,
          serialNumber: deliveryreceiptresponse.responseData[i].serialNumber,
          inventoryDesc: deliveryreceiptresponse.responseData[i].inventoryDesc,
        };
        TempArr.push(obj);
      }
      const bodyobj = {
        inventorydescription: TempArr,
        customerName:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerName
            : '',
        addressLine1:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].addressLine1
            : '',
        addressLine2:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0 &&
          deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
          deliveryreceiptresponse.responseData[0].addressLine2 !== ''
            ? deliveryreceiptresponse.responseData[0].addressLine2
            : '',
        customerCity:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerCity
            : '',
        customerZip:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0 &&
          deliveryreceiptresponse.responseData[0].customerZip !== null &&
          deliveryreceiptresponse.responseData[0].customerPlus4 !== null
            ? deliveryreceiptresponse.responseData[0].customerZip +
              '-' +
              deliveryreceiptresponse.responseData[0].customerPlus4
            : deliveryreceiptresponse.responseData[0].customerZip,
        customerState:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerState
            : '',
        customerPhoneNumber:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
            : '',
        storeName:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeName
            : '',
        storeNumber:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeNumber
            : '',
        storeAddressLine1:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeAddressLine1
            : '',
        storeAddressLine2:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0 &&
          deliveryreceiptresponse.responseData[0].storeAddressLine2 !== null &&
          deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ''
            ? deliveryreceiptresponse.responseData[0].storeAddressLine2
            : '',
        storeCity:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeCity
            : '',
        storeState:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeState
            : '',
        storeZip:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeZip
            : '',
        storePhoneNumber:
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined &&
          deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storePhoneNumber
            : '',
        agreementId: agreementId,
        customerId: customerId,
        deliveryDate: startDate,
        deliveryTime: Time,
        agreementNumber: agreementNumber,
        nexDueAmount:
          nextamount !== undefined && nextamount !== null
            ? nextamount.toString()
            : '',
      };

      console.log('view payload', bodyobj);

      const receiptresponse = await GenerateReceipt(bodyobj);

      if (receiptresponse.data !== undefined && receiptresponse.data !== null) {
        setpdfresponse(receiptresponse.data);
        console.log('delievry checklist', receiptresponse.data);
      }
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Complete_delivery_status = async () => {
    if (RescheduleEventId != null && RescheduleEventId != '') {
      setmasterLoader(true);
      let NumberofCoworkers = '';
      if (Coworkers == '') {
        NumberofCoworkers = '1';
      } else {
        NumberofCoworkers = Coworkers;
      }
      console.log('adressid----->', IdAddress);
      const complete = {
        storeNumber: StoreId,
        address_id: null,
        eventInformation: {
          partyId: customerId,
          agreementId: agreementId,
          inventoryIds: inventoryid,
          type: Deliverytype,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          eventDate: moment(startDate).format('MM/DD/YYYY'),
          eventSource: 'Store',
          eventId: RescheduleEventId,
          timeSlot: {
            timeSlotId: timeslotid,
          },
          eventInstruction: Instructions,
          requiredCoworkers: NumberofCoworkers,
          racdbSourced: true,
        },
      };

      console.log('Complete event reuest', complete);
      const Apiresult = await FinishdeliveryStatus(complete);
      /* eslint-disable no-console */
      try {
        console.log(Apiresult.data);
        const nextamount = await getnextamountdue();
        console.log('1731', nextamount);
        await receiptstatus(nextamount);
        setmasterLoader(false);
        SetSaveTrigger(true);
        if (Apiresult.data.PostAddress.value.statusMessage == 'Success') {
          SetOpendeliveryconfirmpopup(true);
          GetTimeSlot(new Date(startDate));
          let TrimmedZip = '';
          let TrimmedPlus4: any = '';
          if (Zip.length > 5) {
            TrimmedZip = Zip.slice(0, 5);
            TrimmedPlus4 = Zip.slice(6, Zip.length);
          } else {
            TrimmedZip = Zip;
          }
          const Value = {
            eventId: RescheduleEventId,
            deliveryType: Deliverytype,
            addressId: null,
            addressLine1: Addressline1,
            addressLine2: Addressline2,
            zip: TrimmedZip,
            zipPlus4: TrimmedPlus4 != '' ? TrimmedPlus4 : null,
            city: City,
            state: State,
            timeSlotId: timeslotid,
            appointmentDate: moment(startDate).format('MM/DD/YYYY'),
            appointmentStartTime: ContextStartDate,
            appointmentEndTime: ContextEndDate,
            coWorkersRequired: Coworkers,
            instructions: Instructions,
          };
          console.log('Context', Value);

          const some = {
            deliveryMethodStatus: '0',
            deliveryInformation: Value,
          };
          console.log('DeliveryValue', some);
          setdeliveryMethodData(some);
        } else {
          SetErrorpopup(true);
          // eslint-disable-next-line sonarjs/no-duplicate-string
          SetDisplayErrorMessage('Something Went Wrong');
        }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage('Something Went Wrong');
      }
    } else {
      try {
        setmasterLoader(true);
        let NumberofCoworkers = '';
        if (Coworkers == '') {
          NumberofCoworkers = '1';
        } else {
          NumberofCoworkers = Coworkers;
        }

        const complete = {
          storeNumber: StoreId,
          address_id: IdAddress,
          eventInformation: {
            partyId: customerId,
            agreementId: agreementId,
            inventoryIds: inventoryid,
            type: props.isReturn === true ? 'Return' : Deliverytype,
            eventDate: moment(startDate).format('MM/DD/YYYY'),
            eventSource: 'Store',
            timeSlot: {
              timeSlotId: timeslotid,
            },
            eventInstruction: Instructions,
            requiredCoworkers: NumberofCoworkers,
            racdbSourced: true,
            isLoaner: 'Y',
            loanerCreate: {
              storeNumber: window.sessionStorage.getItem('storeNumber'),
              timeSlot: timeslotid,
              customerId: String(customerId),
              agreementNumber: AgrNumber,
              inventories: [
                {
                  inventoryNumber: InventoryNumber,
                  //serviceTicketId: String(servicedetails?.serviceTicketId)
                },
              ],
              // coworkerId: "654644",
              appointmentInfo: {
                appointmentType: Deliverytype,
                appointmentSource: AppoinmentSource,
                appointmentDate: startDate,
                requiredCoworkers: NumberofCoworkers,
                instructions: Instructions,
              },
            },
          },
        };

        console.log('Complete event reuest', complete);
        const Apiresult = await FinishdeliveryStatus(complete);
        /* eslint-disable no-console */
        console.log(Apiresult.data);
        setmasterLoader(false);
        SetSaveTrigger(true);
        if (Apiresult.status == 200) {
          SetOpendeliveryconfirmpopup(true);
          const request = {
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            eventInformation: {
              partyId: '33925768',
              agreementId: agreementId,
              inventoryIds: ['9999207382277'],
              type: 'Delivery',
              eventDate: '11/23/2021',
              eventSource: 'Store',
              timeSlot: {
                timeSlotId: '9999100000392',
              },
              eventInstructions: 'created customer event',
              requiredCoworkers: '2',
              racdbSourced: true,
              isLoaner: 'N',
            },
            address_id: addressId,
          };
          const result = await FinishdeliveryStatus(request);
        } else {
          SetErrorpopup(true);
          SetDisplayErrorMessage('Something Went Wrong');
        }
      } catch (e) {
        setmasterLoader(false);
        SetErrorpopup(true);
        SetDisplayErrorMessage('Something Went Wrong');
      }
    }
  };

  // const formatDate = (dates: any) => {
  //   const dateFormat = new Date(dates);
  //   return (
  //     dateFormat.toString().slice(0, 3) +
  //     ', ' +
  //     dateFormat.toString().slice(4, 10)
  //   );
  // };

  const formatDate = (dates: any) => {
    const dateFormat = new Date(dates);
    console.log(dateFormat, '<--- Date Format');
    console.log(dateFormat.toUTCString(), 'UTC');
    return (
        dateFormat.toUTCString().slice(0, 4) +
        dateFormat.toUTCString().slice(7, 12) + dateFormat.toUTCString().slice(5, 7)
    );
};

  const buildTimeSlot = () => {
    if (timeSlotData != undefined && timeSlotData.length > 0) {
      let slotNumbers = 0;
      return timeSlotData.map((objdate: any) => {
        if (
          objdate.TimeSlots != null &&
          objdate.TimeSlots != '' &&
          slotNumbers < 5
        ) {
          slotNumbers++;
          const formattedDate = formatDate(objdate.Date);
          return (
            // eslint-disable-next-line react/jsx-key
            <Grid item className={classes.agrTimeSlot}>
              <Typography
                className={`${classes.semiBold} ${classes.font14} ${classes.MarginDate}`}
              >
                {formattedDate}
              </Typography>
              <Grid className={classes.agrListGroup}>
                {timeslotbind(objdate)}
              </Grid>
            </Grid>
            // </div>
            // </div>
            // </div>
          );
        }
      });
    } else {
      return (
        <div className={classes.Norecords}>
          <CircularProgress />
        </div>
      );
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const timeslotbind = (objdate: any) => {
    // var itr = 0;
    if (objdate.TimeSlots != undefined) {
      return objdate.TimeSlots.map((objtime: any) => {
        // itr += 1;
        const modifiedStarttime = moment(objtime.startTime, 'HH:mm:ss').format(
          'LT'
        );
        const modifiedEndtime = moment(objtime.endTime, 'HH:mm:ss').format(
          'LT'
        );
        const slicedTimeslot =
          modifiedStarttime.slice(0, 5) +
          modifiedStarttime.slice(8, modifiedStarttime.length);

        const Currently = new Date();
        const todayDate = String(Currently.getDate()).padStart(2, '0');
        const todayMonth = String(Currently.getMonth() + 1).padStart(2, '0'); //January is 0!
        const todayYear = Currently.getFullYear();

        const todaysDate = todayMonth + '/' + todayDate + '/' + todayYear;

        if (todaysDate == objdate.Date || todaysDate > objdate.Date) {
          const startTime: any = objtime.startTime;
          const hoursdisplayed = startTime.split(':')[0];
          const minutesdisplayed = startTime.split(':')[1];
          const displayedTime = hoursdisplayed + ':' + minutesdisplayed;
          console.log(displayedTime);

          const Currently = new Date();
          const TimeatPresent = new Date(Currently.getTime());
          const currentHours = TimeatPresent.getHours();
          console.log(currentHours);
          const currentMinutes = TimeatPresent.getMinutes();
          console.log(currentMinutes);
          const Currenttime = currentHours + ':' + currentMinutes;
          console.log(Currenttime);

          if (objtime.status !== 'Available') {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else if (
            todaysDate > objdate.Date ||
            (todaysDate == objdate.Date && displayedTime <= Currenttime)
            // eslint-disable-next-line sonarjs/no-duplicated-branches
          ) {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotBusy;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = '';

            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        } else {
          if (objtime.status !== 'Available') {
            return (
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={classes.slotBusy}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
                disabled={true}
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          } else {
            let slotColor = '';
            if (
              BindtimeslotId !== '' &&
              BindtimeslotId == objtime.timeSlot &&
              BindtimeslotDate !== '' &&
              BindtimeslotDate == objdate.Date
            ) {
              slotColor = classes.slotBooked;
            } else {
              slotColor = classes.slotAvailable;
            }
            return (
              //
              <button
                type="button"
                name={objdate.Date}
                value={slicedTimeslot + '-' + modifiedEndtime}
                id={objtime.timeSlot}
                className={slotColor}
                onClick={(e) =>
                  getslotdetails(e, modifiedStarttime, modifiedEndtime)
                }
              >
                {slicedTimeslot} - {modifiedEndtime}
              </button>
            );
          }
        }
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const Addressselect = () => {
    return (
      <div>
        {masterLoader ? (
          <Grid
            style={{
              position: 'fixed',
              background: '#f7f5f5',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: 'center',
              margin: '0px 0px',
            }}
          >
            <Grid
              style={{
                display: 'block',
                position: 'fixed',
                zIndex: 9999999,
                top: '40%',
                right: '50%',
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid className={classes.fixTableHead}>
          <RACTable
            // renderTableHead={RenderTable}
            renderTableContent={RenderTableBody}
          />
        </Grid>

        <Grid item md={12} className={classes.modalFooter}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerMR4}
            onClick={cancelbtn}
          >
            Cancel
          </RACButton>

          <RACButton
            variant="contained"
            color="primary"
            // disabled={enableOkpopup}
            onClick={Okpopup}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const CoworkerOptionsArray = () => {
    if (coWorkerRequiredArray !== undefined) {
      return coWorkerRequiredArray.map((entry: any, index: any) => {
        const some: any = entry.toString();
        if (index === 0) {
          return { label: '1', value: '1' };
        } else {
          return { label: entry, value: some };
        }
      });
    } else {
      return [{ label: '1', value: '1' }];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const Timebind = (event: any) => {
    SetTime(event.target.value);
    SetSaveTrigger(false);
  };
  const RenderTableBody = () => (
    <>
      <>
        <RACTableRow>
          <RACTableCell className={classes.AddressPopup}>
            Address Line 1{' '}
            <Box component="span" className={classes.Mandatory}>
              {' '}
              *{' '}
            </Box>
          </RACTableCell>

          <RACTableCell className={classes.AddressPopup}>
            Address Line 2
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            Zip{' '}
            <Box component="span" className={classes.Mandatory}>
              {' '}
              *{' '}
            </Box>
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            City{' '}
            <Box component="span" className={classes.Mandatory}>
              {' '}
              *{' '}
            </Box>
          </RACTableCell>
          <RACTableCell className={classes.AddressPopup}>
            State{' '}
            <Box component="span" className={classes.Mandatory}>
              {' '}
              *{' '}
            </Box>
          </RACTableCell>
        </RACTableRow>
      </>
      <RACTableRow>
        <RACTableCell>
          <RACTextbox
            // type="text"
            value={Line1Popup}
            maxlength={30}
            name="addressLine1"
            OnChange={(e) => handleChange(e)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>
        <RACTableCell>
          <RACTextbox
            type="text"
            value={Line2Popup}
            maxlength={30}
            name="addressLine2"
            OnChange={() => handleChange(event)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          <RACTextbox
            // type="phoneno"
            maxlength={10}
            value={ZipPopup}
            name="zip"
            OnChange={(e) => handleChange(e)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          <RACTextbox
            type="text"
            value={CityPopup}
            maxlength={30}
            name="city"
            OnChange={() => handleChange(event)}
            disabled={InputGrid}
            isCurrency={false}
          />
        </RACTableCell>

        <RACTableCell>
          {/* <RACSelect
            options={Loadstate()}
            loading={StateconditionLoader}
            // defaultValue={StatePopup}
            defaultValue={StatePopup !== '' ? StatePopup : 'Select'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              SetStatePopup(e.target.value);
            }}
            isDisabled={InputGrid}
          /> */}
          <RACSelect
            options={Loadstate()}
            loading={StateconditionLoader}
            // defaultValue={StatePopup}
            defaultValue={StatePopup !== '' ? StatePopup : 'Select'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (e.target.value == 'Select') {
                SetStatePopup('');
              } else {
                SetStatePopup(e.target.value);
              }
            }}
            isDisabled={InputGrid}
            {...(Statedrop.length == 1 &&
              !conditionLoader && {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                errorMessage: 'Unable to fetch Data',
              })}
          />
        </RACTableCell>
        <RACTableCell>
          <RACButton onClick={() => addGridRow()} disabled={enableadd}>
            <Addimage />
          </RACButton>
        </RACTableCell>
      </RACTableRow>
      {bindGrid()}
    </>
  );
  const addressDoctorPopup = () => {
    return (
      <Grid
        item
        md={12}
        id="confirmduedate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        {masterLoader ? (
          <Grid
            style={{
              position: 'fixed',
              background: '#f7f5f5',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: 'center',
              margin: '0px 0px',
            }}
          >
            <Grid
              style={{
                display: 'block',
                position: 'fixed',
                zIndex: 9999999,
                top: '40%',
                right: '50%',
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Typography className={classes.RACPopupMsg}>
          Entered address is not located
        </Typography>
        <Grid item md={12}>
          <Typography className={classes.RACPopupMsg}>
            Suggested Addresses
          </Typography>
          <div className={classes.validateCheck}>
            <RACRadio
              id="inlineRadio1"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == 'SUG' ? true : false}
              onClick={() => RadioBtnclick('SUG')}
              value={'SUG'}
            />
            <Typography variant="caption" className={classes.RACPopupMsg}>
              {SuggestedAddress !== undefined &&
              SuggestedAddress !== null &&
              SuggestedAddress.Addresses !== null &&
              // SuggestedAddress.value.Addresses !== null &&
              SuggestedAddress.Addresses.StreetAddress !== undefined
                ? `${SuggestedAddress.Addresses.StreetAddress},`
                : ''}
              {SuggestedAddress !== undefined &&
              SuggestedAddress !== null &&
              // SuggestedAddress.value !== null &&
              SuggestedAddress.Addresses !== null &&
              SuggestedAddress.Addresses.City !== undefined
                ? `${SuggestedAddress.Addresses.City},`
                : ''}
              {SuggestedAddress !== undefined &&
              SuggestedAddress !== null &&
              // SuggestedAddress.value !== null &&
              SuggestedAddress.Addresses !== null &&
              SuggestedAddress.Addresses.StateProvince !== undefined
                ? `${SuggestedAddress.Addresses.StateProvince},`
                : ''}
              {SuggestedAddress !== undefined &&
              SuggestedAddress !== null &&
              // SuggestedAddress.value !== null &&
              SuggestedAddress.Addresses !== null &&
              SuggestedAddress.Addresses.PostalCode !== undefined
                ? `${SuggestedAddress.Addresses.PostalCode}`
                : ''}
            </Typography>
          </div>
        </Grid>
        <Grid item md={12}>
          <Typography className={classes.RACPopupMsg}>
            Entered Address
          </Typography>
          <div className={classes.validateCheck}>
            <RACRadio
              id="inlineRadio2"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == 'ENT' ? true : false}
              onClick={() => RadioBtnclick('ENT')}
              value={'ENT'}
            />
            <Typography variant="caption" className={classes.RACPopupMsg}>
              {Line1Popup}, {Line2Popup ? Line2Popup : ''}, {CityPopup},{' '}
              {StatePopup} - {ZipPopup}
            </Typography>
          </div>
        </Grid>
        <Grid container className={classes.dInherit}>
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              onClick={() => setValidateIsOpen(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={classes.floatRight}>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.spacerMR4}
              onClick={() => continueclick('ENT')}
              disabled={BtnIsdisabled1}
            >
              Override
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              disabled={BtnIsdisabled2}
              onClick={() => continueclick('SUG')}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const errorpopup = () => {
    return (
      <div
        id="success"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textCenter}>
          <Alertimage></Alertimage>
          <Typography className={classes.my2}>{DisplayErrorMessage}</Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => Removevoid()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const DeliveryConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={classes.mt3}>
            Delivery Scheduled Successfully.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={DeliveryScheduled}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  // const BookedtimeSlotpopup = () => {
  //   return (
  //     <div
  //       id="success"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className={classes.textCenter}>
  //         <Alertimage></Alertimage>
  //         <Typography className={classes.my2}>
  //           Selected Slot is Already Booked. Please Choose a different Time
  //         </Typography>
  //       </Grid>

  //       <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           variant="contained"
  //           color="primary"
  //           isRounded={false}
  //           onClick={() => SetOpenTimeslotbookedPopup(false)}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </div>
  //   );
  // };
  const DeliveryScheduled = () => {
    SetOpendeliveryconfirmpopup(false);
    if (
      props !== undefined &&
      props.isDeliveryReschedule !== undefined &&
      props.isDeliveryReschedule.deliveryrescheduling != undefined &&
      props.isDeliveryReschedule.deliveryrescheduling === true
    ) {
      SetOpendeliveryconfirmpopup(false);
      RescheduledDelivery();
    } else {
      history.push({
        pathname: `/dashboard`,
      });
      // BindtimeslotId = '';
      setBindtimeslotId('');
      BindtimeslotDate = '';
    }
  };

  const RescheduledDelivery = () => {
    props.rescheduleDatas({ data: true });
  };

  const Removevoid = () => {
    Setsuccesspopup(false);
  };

  const PreviousbuttonClick = () => {
    history.push({
      pathname: `/agreement/switchout/details/${customerId}/${agreementId}`,
    });

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  // const deliveryCancel = () => {
  //   history.push({
  //     pathname: `/customer/searchcustomer`,
  //   });
  // };

  const CancelConfirmation = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Are you Sure, you want to cancel the Switchout?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => SetCancelSwitchOut(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={ConfirmSwitchoutCancel}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ConfirmSwitchoutCancel = async () => {
    console.log(
      'gott new inventoryid from context',
      addedItem.itemPricing[0].inventoryId
    );
    console.log('is switchout cancel ', isswitchoutCancelled);
    SetCancelSwitchOut(false);
    setmasterLoader(true);
    const SwitchoutCancel = {
      action: 'CSO',
      agreementId: Number(agreementId),
      switchOut: {
        newInventoryInfo: {
          inventoryId: Number(addedItem.itemPricing[0].inventoryId),
          inventoryPriceId: null,
        },
      },
    };

    console.log('Dummy', SwitchoutCancel);
    const Cancelresponse = await UpdateAgreement(SwitchoutCancel);
    console.log('Cancelled Response', Cancelresponse);
    setmasterLoader(false);
    if (Cancelresponse.status === 200) {
      // sethideswitchoutbutton(true);
      setaddedItem(undefined);
      setsearchResult(undefined);
      setitemArray(undefined);
      setSwitchoutcopyArrayData(undefined);
      setcontainSubDepartment(undefined);
      setcontainCondition(undefined);
      setselectedInvNumber(undefined);
      setagreementCreateStatus({ agreementCreatedStatus: false });
      setpendingSOAgr(false);
      setisswitchoutCancelled(true);
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      });
    } else if (Cancelresponse.status === 400 || Cancelresponse.status === 500) {
      SetErrorpopup(true);
      // eslint-disable-next-line sonarjs/no-duplicate-string
      SetDisplayErrorMessage('Something Went Wrong');
    }
  };

  // const SwitchOutCancel = () => {
  //   history.push({
  //     pathname: `/agreement/switchout/details/${customerId}/${agreementId}`,
  //   });
  // };

  return (
    <div>
      {masterLoader ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <div id="step-4" className={classes.tabPanel}>
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.title} variant="h6">
              Delivery Information
            </Typography>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={4} lg={3} className={classes.DetailsPadding}>
                    <Typography className={classes.subTitle} variant="h5">
                      Delivery Type
                    </Typography>

                    <Grid className={classes.formCheck}>
                      <RACRadio
                        name="inlineRadioOptions"
                        id="delivery"
                        checked={checkDeliverytype == 'DEL' ? true : false}
                        value={DeliveryRdo}
                        onClick={showDeliveryaddress}
                        size={'small'}
                        label="Delivery"
                      />
                    </Grid>
                    <Grid className={classes.formCheck}>
                      <RACRadio
                        name="inlineRadioOptions"
                        id="carryout"
                        checked={checkDeliverytype == 'CAR' ? true : false}
                        value={CarryoutRdo}
                        onClick={hideDeliveryaddress}
                        size={'small'}
                        label="Carryout"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={8} lg={9} className={classes.DetailsPadding}>
                    <Typography className={classes.subTitle} variant="h6">
                      Delivery Address
                    </Typography>

                    <Grid container>
                      <Grid className={classes.widthAdjust16}>
                        <Typography className={classes.formLabel}>
                          Address Line 1
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Addressline1}
                        </Typography>
                      </Grid>
                      <Grid className={classes.widthAdjust16}>
                        <Typography className={classes.formLabel}>
                          Address Line 2
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Addressline2}
                        </Typography>
                      </Grid>

                      <Grid className={classes.widthAdjust16}>
                        <Typography className={classes.formLabel}>
                          Zip
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {Zip}
                        </Typography>
                      </Grid>
                      <Grid className={classes.widthAdjust16}>
                        <Typography className={classes.formLabel}>
                          City
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {City}
                        </Typography>
                      </Grid>
                      <Grid className={classes.widthAdjust13}>
                        <Typography className={classes.formLabel}>
                          State
                        </Typography>
                        <Typography className={classes.formLabelValue}>
                          {State}
                        </Typography>
                      </Grid>
                      <Grid className={classes.widthAdjust16}>
                        <RACButton
                          className={classes.buttonAddRows}
                          onClick={() => disPopup()}
                          disabled
                        >
                          Select Other Address
                        </RACButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {props.reScheduleData != undefined &&
                props.reScheduleData.deliveryType != '' ? (
                  <Grid container className={classes.DetailsPadding}>
                    <Grid item md={12}>
                      {props.reScheduleData.deliveryType === 'Delivery' &&
                      Deliverytype === 'Delivery' ? (
                        <Typography className={classes.subTitle} variant="h5">
                          Delivery Details
                        </Typography>
                      ) : Deliverytype === 'Delivery' ? (
                        <Typography className={classes.subTitle} variant="h5">
                          Delivery Details
                        </Typography>
                      ) : (
                        <Typography className={classes.subTitle} variant="h5">
                          Carryout Details
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container className={classes.DetailsPadding}>
                    <Grid item md={12}>
                      {Deliverytype === 'Delivery' ? (
                        <Typography className={classes.subTitle} variant="h5">
                          Delivery Details
                        </Typography>
                      ) : (
                        <Typography className={classes.subTitle} variant="h5">
                          Carryout Details
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item md={2}>
                  <RACDatePicker
                      label="Date"
                      value={startDate}
                      classes={{input:dateError!==''?classes.bgdateError :''}}
                      errorMessage={dateError}
                      onChange={(e) => dates(e)}
                      inputProps={{
                        min: moment().format('YYYY-MM-DD'),
                        max: MaxDate
                      }}
                    />
                  </Grid>
                  {/* dates.bind(event) */}
                  <Grid item md={2}>
                    <RACTextbox
                      isCurrency={false}
                      id="a11y_time"
                      disabled
                      value={Time}
                      inputlabel="Time"
                      OnChange={Timebind}
                    />
                  </Grid>
                  {props.reScheduleData != undefined &&
                  props.reScheduleData.deliveryType != '' ? (
                    <>
                      {props.reScheduleData.deliveryType === 'Delivery' &&
                      Deliverytype === 'Delivery' ? (
                        <Grid item md={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== '' ? Coworkers : '1'}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              SetCoworkers(e.target.value);
                              SetSaveTrigger(false);
                            }}
                          />
                        </Grid>
                      ) : Deliverytype === 'Delivery' ? (
                        <Grid item md={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== '' ? Coworkers : '1'}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              SetCoworkers(e.target.value);
                              SetSaveTrigger(false);
                            }}
                          />
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {Deliverytype === 'Delivery' ? (
                        <Grid item md={2}>
                          <RACSelect
                            inputLabel="# Co-workers Required"
                            loading={conditionLoader}
                            options={CoworkerOptionsArray()}
                            defaultValue={Coworkers !== '' ? Coworkers : '1'}
                            // defaultValue={'1'}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              SetCoworkers(e.target.value);
                              SetSaveTrigger(false);
                            }}
                          />
                        </Grid>
                      ) : null}
                    </>
                  )}
                  <Grid item md={4}>
                    <RACTextbox
                      inputlabel="Instructions"
                      id="a11y_Instructions"
                      isCurrency={false}
                      value={Instructions}
                      OnChange={(e) => {
                        SetInstructions(e.target.value);
                        SetSaveTrigger(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={8} className={classes.spacerMT8}>
                    <Grid className={classes.floatLeft}>
                      <Typography variant="h5" className={classes.subTitle}>
                        Select Available Timeslot
                      </Typography>
                      <Typography className={classes.deliveryEventDesc}>
                        Only 4 events can be added in one time slot.
                      </Typography>
                    </Grid>
                    <Grid className={classes.floatRight}>
                      <Grid className={`${classes.floatLeft} ${classes.me48}`}>
                        <span className={classes.circleAvailable} />
                        <span>Available</span>
                      </Grid>
                      <Grid
                        classes={{
                          root: clsx(classes.floatLeft, classes.spacerMR4),
                        }}
                      >
                        <span className={classes.circleBusy} />
                        <span>Busy</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={12} className={classes.spacerMB5}>
                  <Grid className={`${classes.mt2} ${classes.me2}`}>
                    {buildTimeSlot()}
                  </Grid>
                </Grid>

                {RescheduleProps === 'Schedule' ? (
                  <Grid className={classes.fixedBottom}>
                    <Grid className={classes.floatLeft}>
                      <RACButton
                        variant="outlined"
                        color="primary"
                        className={classes.floatLeft}
                        onClick={() => PreviousbuttonClick()}
                      >
                        Previous
                      </RACButton>
                      {/* <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.floatLeft} ${classes.ms2}`}
                        onClick={() => deliveryCancel()}
                      >
                        Cancel
                      </RACButton> */}
                    </Grid>

                    <Grid className={`${classes.floatRight} ${classes.dFlex}`}>
                      {hideswitchoutbutton === false ? (
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={`${classes.floatLeft} ${classes.me2}`}
                          // disabled={enableSwitchOut}
                          onClick={() => SetCancelSwitchOut(true)}
                          disabled={disablecancelswitchout}
                        >
                          Cancel SwitchOut
                        </RACButton>
                      ) : null}
                      <AddCoCustomer
                        // coCustomerData={Cocustomer}
                        func={pull_data}
                      />
                      <Eposchedule epoData={EpoProps} />
                      <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.floatLeft}
                        disabled={enableFinish}
                        onClick={() => Complete_delivery_status()}
                      >
                        Finish
                      </RACButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid className={classes.fixedBottom}>
                    <Grid className={classes.floatLeft}>
                      <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.floatLeft}
                        disabled={enableFinish}
                        onClick={() => Complete_delivery_status()}
                      >
                        Save
                      </RACButton>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/* Delivery Method ends here  */}

      {/* Delivery Address Pop-Up */}

      <RACModalCard
        isOpen={Popup}
        borderRadius="25px"
        maxWidth="lg"
        title="Select Delivery Address"
        onClose={() => SetPopup(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={Addressselect()}
      />

      <RACModalCard
        isOpen={ValidateIsOpen}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setValidateIsOpen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />

      <RACModalCard
        isOpen={successpopup}
        maxWidth="xs"
        borderRadius="15px"
        // title="Modal title"
        closeIcon={true}
        onClose={() => Setsuccesspopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={errorpopup()}
      />
      <RACModalCard
        isOpen={Opendeliveryconfirmpopup}
        borderRadius="15px"
        onClose={() => SetOpendeliveryconfirmpopup(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryConfirmPopupOpen()}
      />
      {/* <RACModalCard
        isOpen={OpenTimeslotbookedPopup}
        onClose={() => SetOpenTimeslotbookedPopup(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={BookedtimeSlotpopup()}
      /> */}
      <RACModalCard
        isOpen={CancelSwitchOut}
        borderRadius="15px"
        onClose={() => SetCancelSwitchOut(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={CancelConfirmation()}
      />
      {/* <Userscontext.Provider value={obj}>	
        <Footer></Footer>	
      </Userscontext.Provider> */}
    </div>
  );
}
