/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-extra-arguments */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { fixedGridStyles } from '../../JSstyles/fixedGridStyles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as Successicon } from '../../assets/images/success-icon.svg';
import { ReactComponent as NoRecordsFound } from '../../assets/images/No-records.svg';
import { getAgreementTransfer, manageAgreementTransfer } from '../../api/user';
import ComingSoonPage from '../Shared/ComingSoonPage';
import { FeatureFlagContext } from '../../context/FeatureFlagContext';
import {
  Grid,
  List,
  ListItem,
  Typography,
  RACModalCard,
  RACSelect,
  RACButton,
  CircularProgress,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
} from '@rentacenter/racstrap';
import { title } from 'process';

export default function AgreementTransferReceive() {
  const gridClass = fixedGridStyles();
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const modalCardRadius = '25px !important';
  const [hiddenLoader, sethiddenLoader] = useState(false);
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [manageAgrErrMessage, setmanageAgrErrMessage] = useState('');
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const classes = agreementGlobalStyles();
  const history = useHistory();
  const [noRecords, setnoRecords] = useState(false);
  const [noRecordsRequest, setnoRecordsRequest] = useState(false);
  const [otherStoreRequest, setotherStoreRequest] = useState(true);
  const [rejectTransferRequestPopup, setrejectTransferRequestPopup] =
    useState(false);
  const [acceptRequestPopup, setacceptRequestPopup] = useState(false);
  const [acceptCompletePopup, setacceptCompletePopup] = useState(false);
  const [cancelRequestPopup, setcancelRequestPopup] = useState(false);
  const [cancelRequestValue, setcancelRequestValue] = useState('');
  // -----------------------------------------------------------
  // other store request
  const [customerIds, setcustomerIds]: any = useState([]);
  const [otherStoreRequestCheckbox, setotherStoreRequestCheckbox] =
    useState(false);
  const [otherStReqCusCheckBox, setotherStReqCusCheckBox]: any = useState([]);
  const [getAgreementTransferData, setgetAgreementTransferData]: any = useState(
    []
  );
  const [hasMore, sethasMore] = useState(true);
  const [fetchDataLoader, setfetchDataLoader] = useState(false);
  const [offset, setoffset] = useState(1);
  const [NomoreDataOtherReq, setNomoreDataOtherReq] = useState(false);
  // ---------------------------------------------------------------------
  ///transferRequest///
  const [transferCancelcustomerIds, settransferCancelcustomerIds]: any =
    useState([]);
  const [transferRequestCheckbox, settransferRequestCheckbox] = useState(false);
  const [transferReqCusCheckBox, settransferReqCusCheckBox]: any = useState([]);
  const [getAgreementTransferReqData, setgetAgreementTransferReqData]: any =
    useState([]);
  const [hasMoreReq, sethasMoreReq] = useState(true);
  const [fetchDataLoaderReq, setfetchDataLoaderReq] = useState(false);
  const [offsetOG, setoffsetOG] = useState(1);
  const [NomoreDataTransferReq, setNomoreDataTransferReq] = useState(false);
  const [trasnferReqCancelReason, settrasnferReqCancelReason] = useState('');
  // -------------------------------------------------------------------------------------//
  useEffect(() => {
    getAgreementTransferFn();
  }, []);

  // cancel Request Options
  const [cancelReason, setcancelReason] = useState([
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Wrong Customer',
      value: '0',
    },
    {
      label: 'Wrong Store',
      value: '1',
    },
    {
      label: 'Customer Changed Mind',
      value: '2',
    },
  ]);

  // agreement transfer/receive table heading values
  const HeadArr = [
    { displayName: 'Agreement#' },
    { displayName: 'Description' },
    { displayName: 'Type' },
    { displayName: '# Payments' },
    { displayName: 'Total cost' },
    { displayName: 'Schedule' },
    { displayName: 'Next Due Date' },
    { displayName: 'Amount Due' },
    { displayName: 'Status' },
    { displayName: 'Sub Status' },
  ];

  const RequestHeaderArr = [
    { displayName: 'Agreement#' },
    { displayName: 'Description' },
    { displayName: 'Type' },
    { displayName: 'To Store' },
    { displayName: '# Payments' },
    { displayName: 'Total cost' },
    { displayName: 'Schedule' },
    { displayName: 'Next Due Date' },
    { displayName: 'Amount Due' },
    { displayName: 'Status' },
    { displayName: 'Sub Status' },
  ];

  // getAgreement Other store Transfer function
  const getAgreementTransferFn = async () => {
    sethiddenLoader(true);
    const currentStoreNum = window.sessionStorage.getItem('storeNumber');
    console.log('currentStoreNumbergetAgreementTransferFn', currentStoreNum);
    let getAgreementTransferRes = await getAgreementTransfer(
      currentStoreNum,
      'INC',
      1
    );
    sethiddenLoader(false);
    if (getAgreementTransferRes.status == 200) {
      getAgreementTransferRes = getAgreementTransferRes?.data;
      getAgreementTransferRes?.transferDetails?.length == 0
        ? setnoRecords(true)
        : setgetAgreementTransferData([
          ...getAgreementTransferRes?.transferDetails,
        ]);
      console.log('getAgreementTransferRes', getAgreementTransferRes);
      const checkBoxArray: any = new Array(
        getAgreementTransferRes?.transferDetails?.length
      );
      checkBoxArray.fill(false);
      console.log('checkBoxArray', checkBoxArray);
      setotherStReqCusCheckBox(checkBoxArray);
    } else if (getAgreementTransferRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(getAgreementTransferRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };
  // fetchNextData data function

  const fetchNextData = async () => {
    debugger;
    sethasMore(true);
    setfetchDataLoader(true);
    setoffset((prev) => prev + 1);
    console.log(
      'currentStoreNumberfetchNextData',
      window.sessionStorage.getItem('storeNumber')
    );
    const fetchNextDataRes = await getAgreementTransfer(
      window.sessionStorage.getItem('storeNumber'),
      'INC',
      offset + 1
    );
    if (fetchNextDataRes?.status == 200) {
      setfetchDataLoader(false);
      const fetchNexyDataArray = fetchNextDataRes?.data?.transferDetails;
      setgetAgreementTransferData([
        ...getAgreementTransferData,
        ...fetchNexyDataArray,
      ]);
      const fetchNextDataCheckBox: any = new Array(
        fetchNextDataRes?.data?.transferDetails?.length
      );
      fetchNextDataCheckBox.fill(false);
      setotherStReqCusCheckBox([
        ...otherStReqCusCheckBox,
        ...fetchNextDataCheckBox,
      ]);
      if (fetchNextDataRes?.data?.transferDetails?.length !== 5) {
        sethasMore(false);
        setNomoreDataOtherReq(true);
      }
    } else if (fetchNextDataRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(fetchNextDataRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };
  console.log('getAgreementTransferData', getAgreementTransferData);

  // getAgreementTransferRequest function
  const getAgreementTransferReqFn = async () => {
    sethiddenLoader(true);
    const currentStoreNum = window.sessionStorage.getItem('storeNumber');
    console.log('currentStoreNumgetAgreementTransferReqFn', currentStoreNum);
    let getAgreementTransferRes = await getAgreementTransfer(
      currentStoreNum,
      'OG',
      1
    );
    sethiddenLoader(false);
    if (getAgreementTransferRes.status == 200) {
      getAgreementTransferRes = getAgreementTransferRes?.data;
      getAgreementTransferRes?.transferDetails?.length == 0
        ? setnoRecordsRequest(true)
        : setgetAgreementTransferReqData([
          ...getAgreementTransferRes?.transferDetails,
        ]);
      console.log(
        'getAgreementTransferRequestResponse',
        getAgreementTransferRes
      );
      const checkBoxArray: any = new Array(
        getAgreementTransferRes?.transferDetails?.length
      );
      checkBoxArray.fill(false);
      console.log('TransferReqcheckBoxArray', checkBoxArray);
      settransferReqCusCheckBox(checkBoxArray);
    } else if (getAgreementTransferRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(getAgreementTransferRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };

  // Transfer Request fetchNextData data function
  const fetchTransferReqNextData = async () => {
    debugger;
    sethasMoreReq(true);
    setfetchDataLoaderReq(true);
    setoffsetOG((prev) => prev + 1);
    console.log(
      "window.sessionStorage.getItem('storeNumber')fetchTransferReqNextData",
      window.sessionStorage.getItem('storeNumber')
    );
    const fetchNextDataRes = await getAgreementTransfer(
      window.sessionStorage.getItem('storeNumber'),
      'OG',
      offsetOG + 1
    );
    if (fetchNextDataRes?.status == 200) {
      setfetchDataLoaderReq(false);
      const fetchNexyDataArray = fetchNextDataRes?.data?.transferDetails;
      setgetAgreementTransferReqData([
        ...getAgreementTransferReqData,
        ...fetchNexyDataArray,
      ]);
      const fetchNextDataCheckBox: any = new Array(
        fetchNextDataRes?.data?.transferDetails?.length
      );
      fetchNextDataCheckBox.fill(false);
      settransferReqCusCheckBox([
        ...transferReqCusCheckBox,
        ...fetchNextDataCheckBox,
      ]);
      if (fetchNextDataRes?.data?.transferDetails?.length !== 5) {
        sethasMoreReq(false);
        setNomoreDataTransferReq(true);
      }
    } else if (fetchNextDataRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(fetchNextDataRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };

  console.log('getAgreementTransferReqData', getAgreementTransferReqData);

  // function for other rquest reject
  const transferRejectFn = async () => {
    sethiddenLoader(true);
    const updateTransferReq = {
      action: 'TRJ',
      customerIds: customerIds,
      currentStoreNumber: window.sessionStorage.getItem('storeNumber'),
    };
    const transferRejRes = await manageAgreementTransfer(updateTransferReq);
    sethiddenLoader(false);
    if (transferRejRes?.status == 200) {
      getAgreementTransferFn();
    } else if (transferRejRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(transferRejRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };

  // function for other store request accept
  const transferAcceptFn = async () => {
    sethiddenLoader(true);
    const updateTransferReq = {
      action: 'TRC',
      customerIds: customerIds,
      currentStoreNumber: window.sessionStorage.getItem('storeNumber'),
    };
    const transferAcceptRes = await manageAgreementTransfer(updateTransferReq);
    sethiddenLoader(false);
    if (transferAcceptRes?.status == 200) {
      setacceptCompletePopup(true);
    } else if (transferAcceptRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(transferAcceptRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };

  // function for transfer Request cancel
  const transferReqCancelFn = async () => {
    sethiddenLoader(true);

    const updateTransferReq = {
      action: 'TRL',
      customerIds: transferCancelcustomerIds,
      currentStoreNumber: window.sessionStorage.getItem('storeNumber'),
    };

    const transferReqCancelRes = await manageAgreementTransfer(
      updateTransferReq
    );
    sethiddenLoader(false);
    if (transferReqCancelRes?.status == 200) {
      setcancelRequestValue('');
      getAgreementTransferReqFn();
    } else if (transferReqCancelRes?.status == 400) {
      seterrorMessagePopupOpen(true);
      setmanageAgrErrMessage(transferReqCancelRes?.data?.errors[0]?.error);
    } else {
      setSomethingWentWrongOpen(true);
    }
  };

  const dateArray = ['PIF', 'SAC', 'EPO'];
  // next due date converting function
  function nextDueDateFn(date: any) {
    if (!date || dateArray.includes(date)) {
      return '-';
    } else {
      return `${new Date(date).toString().split(' ')[0]}, ${new Date(date).getMonth() + 1
        }/${new Date(date).getDate()}/${new Date(date).getFullYear()}`;
    }
  }
  // function for other store request table heading binding
  const renderTableHeadFn = () => (
    <>
      <RACTableCell>
        <RACCheckBox
          checked={otherStoreRequestCheckbox}
          onClick={() => {
            handleOtherStoreRequestChBoxFn(otherStoreRequestCheckbox);
          }}
        />
      </RACTableCell>
      {HeadArr.map((obj: any) => {
        return <RACTableCell>{obj.displayName}</RACTableCell>;
      })}
    </>
  );
  // handle function for other store request check box
  const handleOtherStoreRequestChBoxFn = (value: boolean) => {
    if (value == false) {
      setotherStoreRequestCheckbox(true);
      otherStReqCusCheckBox.fill(true);
      setotherStReqCusCheckBox(otherStReqCusCheckBox);
      customerIds.splice(0, otherStReqCusCheckBox.length);
      setcustomerIds(customerIds);
    } else if (value == true) {
      setotherStoreRequestCheckbox(false);
      otherStReqCusCheckBox.fill(false);
      setotherStReqCusCheckBox(otherStReqCusCheckBox);
      customerIds.splice(0, otherStReqCusCheckBox.length);
      setcustomerIds(customerIds);
    }
  };

  const otherStoreCusCheckboxClick = (index: any, data: any) => {
    const updateCheckbox = otherStReqCusCheckBox.map((item, position) => {
      return index == position ? !item : item;
    });
    setotherStReqCusCheckBox([...updateCheckbox]);
    if (updateCheckbox[index] == true) {
      setcustomerIds([...customerIds, data]);
    } else if (updateCheckbox[index] == false) {
      const result: any = customerIds.filter((obj) => obj != data);
      setcustomerIds(result);
    }
  };

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };
  // function for Other storerquest table body data binding

  const renderTableContentFn = () => (
    <>
      {getAgreementTransferData?.map((obj: any, index: any) => {
        return (
          <>
            <RACTableRow>
              <RACCheckBox
                style={{ marginTop: '8px' }}
                size="small"
                checked={otherStReqCusCheckBox[index] === false ? false : true}
                onClick={() => {
                  otherStoreCusCheckboxClick(index, obj.customerId);
                }}
              />
              <RACTableCell style={{ fontFamily: 'OpenSans-bold' }}>
                {obj.customerName +
                  ',' +
                  ' ' +
                  obj.agreements[0].storeNumber +
                  ' ' +
                  '-' +
                  ' ' +
                  obj.agreements[0].city +
                  ',' +
                  ' ' +
                  obj.agreements[0].state}
              </RACTableCell>
            </RACTableRow>

            {obj.agreements?.map((value: any) => {
              return (
                <RACTableRow>
                  <RACTableCell></RACTableCell>
                  <RACTableCell
                    className={`${classes.activeAgreementTransfer} ${classes.fontstyle}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push({
                        pathname: `/agreement/info/details/${obj.customerId}/${value.agreementId}`,
                      });
                      const navEvent = new PopStateEvent('popstate');
                      window.dispatchEvent(navEvent);
                    }}
                  >
                    {value.agreementNumber}
                  </RACTableCell>
                  <RACTableCell
                    style={{ textAlign: 'center', color: 'gray' }}
                    title={value.agreementDesc}
                  >
                    {!value.agreementDesc
                      ? '-'
                      : hideLengthyText(value.agreementDesc, 10, '...')}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {/* {value.agreementType}
                     */}
                    {value.agreementType}
                  </RACTableCell>
                  <RACTableCell style={{ paddingLeft: '55px', color: 'gray' }}>
                    {value.noOfPayments}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    ${value.totalCost}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {value.scheduleDesc}
                  </RACTableCell>
                  <RACTableCell style={{ textAlign: 'center', color: 'gray' }}>
                    {nextDueDateFn(value.nextDueDate)}
                  </RACTableCell>
                  <RACTableCell style={{ textAlign: 'center', color: 'gray' }}>
                    ${value.amountDue}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }} title={value.status}>
                    {hideLengthyText(value.status, 10, '...')}
                  </RACTableCell>
                  <RACTableCell
                    style={{ color: 'gray' }}
                    title={value.subStatus}
                  >
                    {hideLengthyText(value.subStatus, 10, '...')}
                  </RACTableCell>
                </RACTableRow>
              );
            })}
          </>
        );
      })}
    </>
  );

  // ----------------- Other store request End----------------------------//

  //-------------------Transfer Request Start-------------------------//

  // function for Transfer Request table heading binding

  const renderRequestTableHeadFn = () => (
    <>
      <RACTableCell>
        <RACCheckBox
          checked={transferRequestCheckbox}
          onClick={() => {
            handleTransferRequestChBoxFn(transferRequestCheckbox);
          }}
        />
      </RACTableCell>
      {RequestHeaderArr.map((obj: any) => {
        return <RACTableCell>{obj.displayName}</RACTableCell>;
      })}
    </>
  );
  // handle function for Transfer request check box
  const handleTransferRequestChBoxFn = (value: boolean) => {
    debugger;
    if (value == false) {
      settransferRequestCheckbox(true);
      transferReqCusCheckBox.fill(true);
      settransferReqCusCheckBox(transferReqCusCheckBox);
      transferCancelcustomerIds.splice(0, transferReqCusCheckBox.length);
      settransferCancelcustomerIds(transferCancelcustomerIds);
    } else if (value == true) {
      settransferRequestCheckbox(false);
      transferReqCusCheckBox.fill(false);
      settransferReqCusCheckBox(transferReqCusCheckBox);
      transferCancelcustomerIds.splice(0, transferReqCusCheckBox.length);
      settransferCancelcustomerIds(transferCancelcustomerIds);
    }
  };

  // Function for Transfer Request Customer check box click
  const transferReqCusCheckboxClick = (index: any, data: any) => {
    debugger;
    const updateCheckbox = transferReqCusCheckBox.map((item, position) => {
      return index == position ? !item : item;
    });
    settransferReqCusCheckBox([...updateCheckbox]);
    if (updateCheckbox[index] == true) {
      settransferCancelcustomerIds([...transferCancelcustomerIds, data]);
    } else if (updateCheckbox[index] == false) {
      const result: any = transferCancelcustomerIds.filter(
        (obj) => obj != data
      );
      settransferCancelcustomerIds(result);
    }
  };

  // function for transfer Request table body data binding

  const renderRequestTableContentFn = () => (
    <>
      {console.log('transferReqCusCheckBox', transferReqCusCheckBox)}
      {getAgreementTransferReqData?.map((obj: any, index: any) => {
        return (
          <>
            <RACTableRow>
              <RACCheckBox
                style={{ marginTop: '8px' }}
                size="small"
                checked={transferReqCusCheckBox[index] === false ? false : true}
                onClick={() => {
                  transferReqCusCheckboxClick(index, obj.customerId);
                }}
              />
              <RACTableCell style={{ fontFamily: 'OpenSans-bold' }}>
                {obj.customerName +
                  ',' +
                  ' ' +
                  obj.agreements[0].storeNumber +
                  ' ' +
                  '-' +
                  ' ' +
                  obj.agreements[0].city +
                  ',' +
                  ' ' +
                  obj.agreements[0].state}
              </RACTableCell>
            </RACTableRow>
            {obj.agreements?.map((value: any) => {
              return (
                <RACTableRow>
                  <RACTableCell></RACTableCell>
                  <RACTableCell
                    className={`${classes.activeAgreementTransfer} ${classes.fontstyle}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push({
                        pathname: `/agreement/info/details/${obj.customerId}/${value.agreementId}`,
                      });
                      const navEvent = new PopStateEvent('popstate');
                      window.dispatchEvent(navEvent);
                    }}
                  >
                    {value.agreementNumber}
                  </RACTableCell>
                  <RACTableCell
                    style={{ textAlign: 'center', color: 'gray' }}
                    title={value.agreementDesc}
                  >
                    {!value.agreementDesc
                      ? '-'
                      : hideLengthyText(value.agreementDesc, 10, '...')}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {value.agreementType}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {!value.toStoreNumber ? '-' : value.toStoreNumber}
                  </RACTableCell>
                  <RACTableCell style={{ paddingLeft: '55px', color: 'gray' }}>
                    {value.noOfPayments}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    ${value.totalCost}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }}>
                    {value.scheduleDesc}
                  </RACTableCell>
                  <RACTableCell style={{ textAlign: 'center', color: 'gray' }}>
                    {nextDueDateFn(value.nextDueDate)}
                  </RACTableCell>
                  <RACTableCell style={{ textAlign: 'center', color: 'gray' }}>
                    ${value.amountDue}
                  </RACTableCell>
                  <RACTableCell style={{ color: 'gray' }} title={value.status}>
                    {hideLengthyText(value.status, 10, '...')}
                  </RACTableCell>
                  <RACTableCell
                    style={{ color: 'gray' }}
                    title={value.subStatus}
                  >
                    {hideLengthyText(value.subStatus, 10, '...')}
                  </RACTableCell>
                </RACTableRow>
              );
            })}
          </>
        );
      })}
    </>
  );
  // -------------------------------------------------------------------------------------
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {manageAgrErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const transferRejectPopup = () => {
    return (
      <>
        <Typography
          style={{
            fontSize: 'larger',
            textAlign: 'center',
            marginTop: '-5px',
          }}
        >
          Are you sure want to reject this agreement?
        </Typography>
        <RACButton
          style={{
            marginTop: '35px',
            marginLeft: '100px',
            padding: '12px 30px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setrejectTransferRequestPopup(false);
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '35px',
            padding: '12px 33px',
            marginLeft: '20px',
          }}
          onClick={() => {
            setrejectTransferRequestPopup(false);
            transferRejectFn();
          }}
        >
          Yes
        </RACButton>
      </>
    );
  };
  const transferRequestAcceptPopup = () => {
    return (
      <>
        <Typography style={{ fontSize: 'larger', textAlign: 'center' }}>
          Agreements Selected! Are you sure want to proceed?
        </Typography>
        <RACButton
          style={{
            marginTop: '35px',
            marginLeft: '100px',
            padding: '12px 30px',
          }}
          variant="outlined"
          color="secondary"
          onClick={() => {
            setacceptRequestPopup(false);
          }}
        >
          No
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '35px',
            padding: '12px 33px',
            marginLeft: '20px',
          }}
          onClick={() => {
            setacceptRequestPopup(false);
            transferAcceptFn();
          }}
        >
          Yes
        </RACButton>
      </>
    );
  };
  const transferAcceptCompletePopup = () => {
    return (
      <>
        <div id="testimage" className={classes.agrTranferSuccessImg}>
          <Successicon></Successicon>
        </div>
        <Typography style={{ fontSize: '14px', textAlign: 'center' }}>
          Accept Complete! The agreement(s) are now at your store. Please to the
          Payment Screen and Activate/Verify Benefits Plus for the renter(s).
        </Typography>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginLeft: '10rem',
            marginTop: '25px',
            padding: '12px 30px',
          }}
          onClick={() => {
            setacceptCompletePopup(false);
            getAgreementTransferFn();
          }}
        >
          Ok
        </RACButton>
      </>
    );
  };
  const transferRequestCancelPopup = () => {
    return (
      <>
        <Grid style={{ width: '44%' }}>
          <RACSelect
            inputLabelClassName={classes.cancelTransferRequestDropDown}
            inputLabel="Cancel Reason"
            options={cancelReason}
            defaultValue={cancelRequestValue}
            onChange={(e: any) => {
              setcancelRequestValue(e.target.value);
            }}
          />
        </Grid>
        <RACButton
          variant="outlined"
          color="default"
          style={{ marginTop: '40px', marginLeft: '181px' }}
          onClick={() => {
            setcancelRequestPopup(false);
          }}
        >
          Cancel
        </RACButton>
        <RACButton
          variant="contained"
          color="primary"
          style={{
            marginTop: '40px',
            marginLeft: '17px',
            padding: '12px 29px',
          }}
          onClick={() => {
            transferReqCancelFn();
            setcancelRequestPopup(false);
          }}
          disabled={cancelRequestValue ? false : true}
        >
          Save
        </RACButton>
      </>
    );
  };
  return (
    <>
      {featureFlagDetails?.AgreementTransferReceive == 1 ? (
        <>
          <Grid style={{ width: '98%' }}>
            <Grid>
              {hiddenLoader === true ? (
                <Grid
                  style={{
                    position: 'fixed',
                    background: '#f7f5f5',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: 0.6,
                    zIndex: 2000,
                    textAlign: 'center',
                    margin: '0px 0px',
                  }}
                >
                  <Grid
                    style={{
                      display: 'block',
                      position: 'fixed',
                      zIndex: 9999999,
                      top: '40%',
                      right: '50%',
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : null}
              <RACModalCard
                isOpen={errorMessagePopupOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={modalCardRadius}
              >
                {errorMessagePopup()}
              </RACModalCard>
              <RACModalCard
                isOpen={somethingWentWrongOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={modalCardRadius}
              >
                {somethingWentWrongPopup()}
              </RACModalCard>
              <Grid>
                <List className={`${classes.breadcrumb} ${classes.py1}`}>
                  <ListItem className={`${classes.breadcrumbItemActive}`}>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push({
                          pathname: `/dashboard`,
                        });
                        const navEvent = new PopStateEvent('popstate');
                        window.dispatchEvent(navEvent);
                      }}
                    >
                      Dashboard
                    </a>
                  </ListItem>
                  <ListItem
                    className={`${classes.breadcrumbItemActive1}  ${classes.breadcrumbItem} ${classes.breadcrumbArrow}`}
                  >
                    <a>Accept Transfer Receive</a>
                  </ListItem>
                </List>
                <Grid
                  style={{ backgroundColor: 'white', marginRight: '-29px' }}
                >
                  <List style={{ padding: '21px 0px' }}>
                    <ListItem
                      style={{ display: 'inline', cursor: 'pointer' }}
                      className={
                        otherStoreRequest ? classes.lIstItem1 : classes.lIstItem
                      }
                    >
                      <a
                        onClick={() => {
                          setotherStoreRequest(true);
                        }}
                      >
                        Other Store Request
                      </a>
                    </ListItem>
                    <ListItem
                      style={{ display: 'inline', cursor: 'pointer' }}
                      className={
                        !otherStoreRequest
                          ? classes.lIstItem1
                          : classes.lIstItem
                      }
                    >
                      <a
                        onClick={() => {
                          setotherStoreRequest(false);
                          !getAgreementTransferReqData.length &&
                            getAgreementTransferReqFn();
                        }}
                      >
                        Transfer Request
                      </a>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>

              {otherStoreRequest ? (
                <>
                  <Card
                    style={{
                      borderRadius: '15px',
                      marginLeft: '15px',
                      height: '470px',
                      width: '100%',
                      marginTop: '25px',
                    }}
                  >
                    <CardContent>
                      {noRecords ? (
                        <>
                          <Grid
                            className={`${classes.textCenter} ${classes.mt5}`}
                            style={{ marginTop: '150px' }}
                          >
                            <NoRecordsFound />
                            <Typography
                              className={`${classes.subTitle} ${classes.mb3}`}
                            >
                              No records found
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid className={gridClass.fixTableHeadAgrTrsRec}>
                            {getAgreementTransferData?.length > 4 ? (
                              <InfiniteScroll
                                next={fetchNextData}
                                dataLength={getAgreementTransferData?.length}
                                hasMore={hasMore}
                                height={390}
                                loader={
                                  fetchDataLoader ? (
                                    <Grid className={classes.GridLoader}>
                                      <CircularProgress></CircularProgress>
                                    </Grid>
                                  ) : NomoreDataOtherReq ? (
                                    <Typography>No More Data</Typography>
                                  ) : null
                                }
                              >
                                <RACTable
                                  renderTableHead={renderTableHeadFn}
                                  renderTableContent={renderTableContentFn}
                                />
                              </InfiniteScroll>
                            ) : (
                              <RACTable
                                renderTableHead={renderTableHeadFn}
                                renderTableContent={renderTableContentFn}
                              />
                            )}
                          </Grid>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <Card
                    style={{
                      borderRadius: '15px',
                      marginLeft: '15px',
                      height: '470px',
                      width: '100%',
                      marginTop: '25px',
                    }}
                  >
                    <CardContent>
                      {noRecordsRequest ? (
                        <>
                          <Grid
                            className={`${classes.textCenter} ${classes.mt5}`}
                            style={{ marginTop: '150px' }}
                          >
                            <NoRecordsFound />
                            <Typography
                              className={`${classes.subTitle} ${classes.mb3}`}
                            >
                              No records found
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid className={gridClass.fixTableHeadAgrTrsRec}>
                            {getAgreementTransferReqData?.length > 4 ? (
                              <InfiniteScroll
                                next={fetchTransferReqNextData}
                                dataLength={getAgreementTransferReqData?.length}
                                hasMore={hasMoreReq}
                                height={390}
                                loader={
                                  fetchDataLoaderReq ? (
                                    <Grid className={classes.GridLoader}>
                                      <CircularProgress></CircularProgress>
                                    </Grid>
                                  ) : NomoreDataTransferReq ? (
                                    <Typography>No More Data </Typography>
                                  ) : null
                                }
                              >
                                <RACTable
                                  renderTableHead={renderRequestTableHeadFn}
                                  renderTableContent={
                                    renderRequestTableContentFn
                                  }
                                />
                              </InfiniteScroll>
                            ) : (
                              <RACTable
                                renderTableHead={renderRequestTableHeadFn}
                                renderTableContent={renderRequestTableContentFn}
                              />
                            )}
                          </Grid>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </>
              )}

              <RACModalCard
                isOpen={rejectTransferRequestPopup}
                maxWidth="xs"
                borderRadius={modalCardRadius}
                closeIcon={true}
                onClose={() => {
                  setrejectTransferRequestPopup(false);
                }}
              >
                {transferRejectPopup()}
              </RACModalCard>
              <RACModalCard
                isOpen={acceptRequestPopup}
                maxWidth="xs"
                borderRadius={modalCardRadius}
                closeIcon={true}
                onClose={() => {
                  setacceptRequestPopup(false);
                }}
              >
                {transferRequestAcceptPopup()}
              </RACModalCard>
              <RACModalCard
                isOpen={acceptCompletePopup}
                maxWidth="xs"
                borderRadius={modalCardRadius}
                closeIcon={true}
                onClose={() => {
                  setacceptCompletePopup(false);
                  getAgreementTransferFn();
                }}
              >
                {transferAcceptCompletePopup()}
              </RACModalCard>
              <RACModalCard
                title="Cancel Request"
                isOpen={cancelRequestPopup}
                borderRadius={modalCardRadius}
                closeIcon={true}
                maxWidth="xs"
                onClose={() => {
                  setcancelRequestPopup(false);
                }}
              >
                {transferRequestCancelPopup()}
              </RACModalCard>
            </Grid>
            <Grid className={classes.fixedBottom}>
              <Grid className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push({
                      pathname: `/dashboard`,
                    });
                    const navEvent = new PopStateEvent('popstate');
                    window.dispatchEvent(navEvent);
                  }}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid className={classes.floatRight} style={{ display: 'flex' }}>
                {otherStoreRequest ? (
                  <>
                    <RACButton
                      style={{
                        backgroundColor: 'indianred',
                        color: 'white',
                        padding: '11px 43px',
                        marginRight: '10px',
                      }}
                      variant="contained"
                      disabled={
                        otherStReqCusCheckBox.includes(true) ? false : true
                      }
                      onClick={() => {
                        setrejectTransferRequestPopup(true);
                      }}
                    >
                      Reject
                    </RACButton>
                    <RACButton
                      style={{
                        padding: '11px 43px',
                      }}
                      variant="contained"
                      color="primary"
                      disabled={
                        otherStReqCusCheckBox.includes(true) ? false : true
                      }
                      onClick={() => {
                        setacceptRequestPopup(true);
                      }}
                    >
                      Accept
                    </RACButton>
                  </>
                ) : (
                  <>
                    <RACButton
                      variant="contained"
                      color="primary"
                      style={{
                        padding: '11px 25px',
                      }}
                      onClick={() => {
                        setcancelRequestPopup(true);
                      }}
                      disabled={
                        transferReqCusCheckBox.includes(true) ? false : true
                      }
                    >
                      Cancel Request
                    </RACButton>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <ComingSoonPage />
        </>
      )}
    </>
  );
}
