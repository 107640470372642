/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as SortdownIcon } from '../../../assets/images/sort-down-icon.svg';
import { ReactComponent as SortupIcon } from '../../../assets/images/sort-up-icon.svg';
import { ReactComponent as ItemAddition } from '../../../assets/images/ItemAddition.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AgreementContext } from '../../../context/AgreementContext';
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
  CircularProgress,
} from '@rentacenter/racstrap';
import { fixedGridStyles } from '../../../JSstyles/fixedGridStyles';
import { useHistory } from 'react-router-dom';

export interface dynamicProps {
  items: any;
  orderBy: string;
  order: string;

  handleActionType: (
    value: any,
    index: any,
    status: any,
    invNumber: any,
    inventoryPriceId: any
  ) => void;
  throwStyle: any;
}
export interface columns {
  columnId: string;
  sortOrder: string;
  columnTitle: string;
  IsSorted: boolean;
  IsSortable: boolean;
  columnType?: 'link';
  handleColumnClick?: () => void;
  subColumns?: columns[];
}
const sortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface IDynamicPageState {
  gridValues: [];
  gridColumn: columns[] | [];
}

const packagePriceColumns: columns[] = [
  {
    columnId: 'inventoryNumber',
    sortOrder: sortDirection.ASC,
    columnTitle: 'Item #',
    IsSorted: true,
    columnType: 'link',
    IsSortable: true,
  },
  {
    columnId: 'departmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'subDepartmentsEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Sub Department',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'brandName',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Brand',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'modelNumber',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Model #',
    IsSorted: false,
    IsSortable: false,
  },
  {
    columnId: 'weeklyRate',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Rate',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'weeklyTerm',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Term',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'statusEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Status',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'conditionEn',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Condition',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'remainingValue',
    sortOrder: sortDirection.DESC,
    columnTitle: 'RV',
    IsSorted: false,
    IsSortable: true,
  },
  {
    columnId: 'daysIdle',
    sortOrder: sortDirection.DESC,
    columnTitle: 'Days Idle',
    IsSorted: false,
    IsSortable: true,
  },
];

const DynamicGridComponent = (props: any) => {
  const classes = props.throwStyle;
  const fixedGridClassName = fixedGridStyles();
  const history = useHistory();

  const [gridValues, setGridValues] = useState<any[]>([]);
  const [gridColumn, setGridColumn] = useState<columns[]>([]);
  const { searchCriteria } = useContext(AgreementContext);
  useEffect(() => {
    const { order, orderBy, items } = props;
    /* eslint-disable no-console */
    console.log('In Grid Component', props.items);

    const sortedData =
      order == sortDirection.DESC
        ? items.sort((a: any, b: any) => (a[orderBy] < b[orderBy] ? -1 : 1))
        : items.sort((a: any, b: any) => (b[orderBy] < a[orderBy] ? -1 : 1));

    setGridValues(sortedData);
    setGridColumn(packagePriceColumns);
  }, [props]);

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) =>
            b[columnId] < a[columnId] ? -1 : 1
          )
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };
  const sortArrayForDollar = (
    columnId: string,
    index: number,
    datacolumn: any,
    dataValues: any
  ) => {
    const sortedData =
      datacolumn[index].sortOrder == sortDirection.DESC
        ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
        : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
    const reorderColumns: columns[] = datacolumn;
    reorderColumns[index].sortOrder =
      reorderColumns[index].sortOrder == sortDirection.ASC
        ? sortDirection.DESC
        : sortDirection.ASC;
    reorderColumns.map((value: any, index1: number) => {
      reorderColumns[index1].IsSorted = index == index1 ? true : false;
    });
    setGridValues([...sortedData]);
    setGridColumn([...reorderColumns]);
    // setState({ gridValues: sortedData, gridColumn: reorderColumns });
  };

  const renderTableHead = (dataColumns: any, dataValues: any) => {
    return (
      <>
        {gridColumn.map((value, index) => {
          return (
            <RACTableCell key={index} className={classes.bold}>
              {value.columnTitle !== 'Model #' &&
              value.columnTitle !== 'Status' ? (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  id={value.columnId}
                  onClick={() =>
                    value.columnTitle !== 'Rate' && value.columnTitle !== 'RV'
                      ? sortArray(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                      : sortArrayForDollar(
                          value.columnId,
                          index,
                          dataColumns,
                          dataValues
                        )
                  }
                >
                  {value.columnTitle + '  '}
                  {value.IsSorted === true ? (
                    value.sortOrder == sortDirection.DESC ? (
                      <SortupIcon className={classes.ms0} />
                    ) : (
                      <SortdownIcon className={classes.ms0} />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'none',
                  }}
                  id={value.columnId}
                >
                  {value.columnTitle + '  '}
                </a>
              )}
            </RACTableCell>
          );
        })}
        <RACTableCell></RACTableCell>
      </>
    );
  };

  const renderTableContent = (gridBodyData: any, gridColumns: any) => {
    if (!!gridBodyData && gridBodyData.length > 0) {
      return gridBodyData.map((value: any, index1: any) => {
        const tagRate: any = value.weeklyRate.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );
        const rv: any = value.remainingValue.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        );

        let condition: any = '';
        if (value.conditionRefCode == 'ATO') {
          condition = 'Auto';
        } else if (value.conditionRefCode == 'EXCP') {
          condition = 'Exception';
        } else if (value.conditionRefCode == 'NEW') {
          condition = 'New';
        } else {
          console.log('condition mistake', value.conditionRefCode);
          condition = value.conditionRefCode;
        }

        return (
          <React.Fragment key={index1}>
            <RACTableRow>
              <RACTableCell>
                <a
                  className={classes.racpadLink}
                  onClick={() =>
                    history.push({
                      pathname: `/inventory/${value.inventoryNumber}/inventoryinformation`,
                    })
                  }
                  //href={`/inventory/${value.inventoryNumber}/inventoryinformation`}
                >
                  {value.inventoryNumber}
                </a>
              </RACTableCell>
              <RACTableCell
                title={
                  value.departmentsEn.length > 10 ? value.departmentsEn : null
                }
              >
                {truncString(value.departmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={
                  value.subDepartmentsEn.length > 10
                    ? value.subDepartmentsEn
                    : null
                }
              >
                {truncString(value.subDepartmentsEn, 20, '...')}
              </RACTableCell>
              <RACTableCell
                title={value.brandName.length > 7 ? value.brandName : null}
              >
                {truncString(value.brandName, 7, '...')}
              </RACTableCell>
              {value.modelNumber ? (
                <RACTableCell
                  title={
                    value.modelNumber.length > 7 ? value.modelNumber : null
                  }
                >
                  {truncString(value.modelNumber, 7, '...')}
                </RACTableCell>
              ) : (
                <RACTableCell className={classes.textCenter}> - </RACTableCell>
              )}
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {tagRate}
              </RACTableCell>
              <RACTableCell>{value.weeklyTerm}</RACTableCell>
              <RACTableCell
                style={{ whiteSpace: 'nowrap' }}
                title={value.statusEn.length > 15 ? value.statusEn : null}
              >
                {truncString(value.statusEn, 15, '...')}
              </RACTableCell>
              <RACTableCell>{truncString(condition, 15, '...')}</RACTableCell>
              <RACTableCell style={{ whiteSpace: 'nowrap' }}>
                $ {rv}
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                {value.daysIdle}
              </RACTableCell>
              <RACTableCell>
                <ItemAddition
                  className={classes.cursorPointer}
                  id={value.inventoryId}
                  onClick={() =>
                    props.handleActionType(
                      value.inventoryId,
                      index1,
                      value.statusEn,
                      value.inventoryNumber,
                      value.inventoryPriceId
                    )
                  }
                  data-testid={index1 + 'plusId'}
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    }
  };
  const fetchNextData = () => {
    props.triggerSearch(searchCriteria, 'searchButtonNotClick');
  };

  return (
    <Grid className={fixedGridClassName.fixTableHead}>
      {gridValues?.length > 7 ? (
        <InfiniteScroll
          next={fetchNextData}
          dataLength={gridValues?.length}
          hasMore={props.reveiveHasMore}
          height={500}
          loader={
            props.reveivescrollLoader ? (
              <Grid className={classes.GridLoader}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : null
          }
        >
          <RACTable
            renderTableHead={() => renderTableHead(gridColumn, gridValues)}
            renderTableContent={() =>
              renderTableContent(gridValues, gridColumn)
            }
          />
        </InfiniteScroll>
      ) : (
        <RACTable
          renderTableHead={() => renderTableHead(gridColumn, gridValues)}
          renderTableContent={() => renderTableContent(gridValues, gridColumn)}
        />
      )}
    </Grid>
  );
};

export default DynamicGridComponent;
