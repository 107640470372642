/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const agreementInfoStyles = () => {
  const useClasses = makeStyles((theme) => ({
    racGrid: {
      '& th': {
        fontFamily: 'OpenSans-semibold',
      },
      '& td': {
        fontFamily: 'OpenSans-semibold',
        color: '#4A5174',
      },
    },
    racAgrViewGrid: {
      '& th': {
        fontFamily: 'OpenSans-semibold',
      },
      '& td': {
        fontFamily: 'OpenSans-regular',
        color: '#4A5174',
      },
    },
    racAgrTotalGrid: {
      '& th': {
        fontFamily: 'OpenSans-semibold',
        '&:first-child': {
          width: '100px',
        },
      },
      '& td': {
        fontFamily: 'OpenSans-regular',
        color: '#4A5174',
      },
    },
    agrInfoGeneral: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
      fontFamily: 'OpenSans-semibold',
      letterSpacing: 'unset',
    },
    agrGenaralInfoColourLabel: {
      fontFamily: 'OpenSans-bold',
      color: `${RACCOLOR.NORMAL_BLUE}`,
    },
    agrBorderRadius: {
      borderRadius: theme.typography.pxToRem(8),
    },
    agrInfoLink: {
      fontFamily: 'OpenSans-regular !important',
    },
    paymentViewWidget: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '830px',
    },
    disableme: {
      pointerEvents: 'none',
      opacity: 0.9,
    },
    cool: {
      '& span': {
        '& span': {
          border: '1px solid blue !important',
        },
      },
    },
    priceTagLabelColour: {
      fontFamily: 'OpenSans-bold',
      color: `${RACCOLOR.GRAY}`,
      fontSize: '14px',
    },
  }));
  return useClasses();
};
