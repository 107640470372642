/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable prefer-const */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import QRCode from 'react-qr-code';
import {
  Grid,
  Typography,
  RACButton,
  RACRadio,
  RACModalCard,
  RACTextbox,
  RACCheckBox,
  CircularProgress,
  Card,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as PDFImage } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as Racfont } from "../../assets/images/RAC-Banner.svg";
import Raclogo from '../../assets/images/Rac-logo.png';
import {
  LoadDeliveryreceipt,
  getnextpaymentamount,
  GetCustomerId,
  GenerateReceipt,
  getPrintDocument,
  getAgreementInfo,
  getAgreementDelivery,
  GenerateSwitchoutReceipt,
  LoadswitchoutDeliveryreceipt,
  FinishdeliveryStatus
} from '../../api/user';
import SignatureCanvas from 'react-signature-canvas';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
interface DeliveryDigitalSignInferface {
  agreementId: string;
  sundaySky: any;
  deliveryDigitalSignCompletedFn: any;
  deliveryDigitalSignCanceledFn: any;
}
export default function DeliveryDigitalSign(
  props: DeliveryDigitalSignInferface
) {
  const signatureRef: any = React.useRef();
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodeStoreDetails = Buffer.from(storeDetails, 'base64');
  const decodestoreDetails: any = JSON.parse(
    encodeStoreDetails.toString('ascii')
  );
  const {
    agreementId,
    deliveryDigitalSignCompletedFn,
    deliveryDigitalSignCanceledFn,
    sundaySky
  } = props;
  const classes = agreementGlobalStyles();
  const modalCardRadius = '20px !important';
  const PaddingConst = "0px 8px 0px 8px";
  const Constinter = "1px solid  #545454";
  const borderRadiuss = "3px";
  const borderbotttom = "1px solid #545454";
  const borderrr = "1px solid #DFDFDF";
  const borderrbottommm = "1px dashed #545454";
  const borderalone = "1px solid   #545454";
  const [hiddenLoader, sethiddenLoader] = useState(false);
  const [uploadeDocLoader, setUploadeDocLoader] = useState(false);
  const [sendDocLoader, setSendDocLoader] = useState(false);
  const [isDeliveryReceiptOpen, setIsDeliveryReceiptOpen] = useState(false);
  const [isDeliveryPickupReceipt, setisDeliveryPickupReceipt] = useState(false)
  const [isPickupEnabled, setisPickupEnabled] = useState(false);
  const [isSwitchoutEnabled, setisSwitchoutEnabled] = useState(false);
  const [isSignaturePanelOpen, setIsSignaturePanelOpen] = useState(false);
  const [isCowokerSignPanelOpen, setIsCowokerSignPanelOpen] = useState(false);
  const [damagedescription, setDamagedescription] = useState("");
  const [isSendDeliveryReceiptOpen, setIsSendDeliveryReceiptOpen] =
    useState(false);
  const [getAgreementDeliveryRes, setgetAgreementDeliveryRes] = useState<any>()
  const [getAgreementinfoRes, setgetAgreementinfoRes] = useState<any>()
  const [deliveryChecklistEnabled, setdeliveryChecklistEnabled] = useState(false)
  const [isDeliveryDocOpen, setIsDeliveryDocOpen] = useState(false);
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [serviceErrorMsg, setServiceErrorMsg] = useState('');
  const [customerSignContent, setCustomerSignContent]: any = useState('');
  const [coworkerSignContent, setCoworkerSignContent]: any = useState('');
  const [currentSign, setCurrentSign] = useState('');
  const [deliveryAddendumB64, setDeliveryAddendumB64] = useState('')
  const [isCOAgr, setisCOAgr] = useState(false);
  const [satisfactionCheckBox, setsatisfactionCheckBox] = useState(false)
  const [videoWatched, setvideoWatched] = useState(false)
  const [inventoryCheckBox, setinventorycheckbox]: any = useState([])
  const [damageCheckBox, setdamageCheckbox] = useState({
    Walls: false,
    Floors: false,
    Doorway: false,
    Statirs: false,
    Other: false
  })
  const [acknowledgeInputs, setAcknowledgeInputs] = useState({
    acceptChecked: false,
    declineChecked: false,
  });
  const deliverReceiptObj = {
    storeInfo: {
      address: decodestoreDetails.addressLine1,
      city: decodestoreDetails.city,
      state: decodestoreDetails.state,
      zip: decodestoreDetails.zip,
      phoneNumber: decodestoreDetails.workPhoneNumber,
    },
    customerInfo: {
      customerId: '',
      customerName: '',
      address: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
    },
    agreementNumber: '',
    customerNumber: '',
    nextPaymentDue: '',
    inventoryInfo: [
      {
        itemDescription: '',
        serialNumber: '',
        itemNumber: '',
        modelNumber: '',
      },
    ],
    customerSigned: false,
    nameOftheCustomerSigned: '',
    coWorkerSigned: false,
  };

  const signaturePanelInputsObj = {
    customerOptionChecked: true,
    othersOptionChecked: false,
    nameOfThePerson: '',
    isNextButtonDisabled: true,
  };
  const sendDeliveryReceiptInputsObj = {
    emailChecked: true,
    textChecked: false,
    emailValue: '',
    invalidEmail: false,
    invalidPhone: false,
    phoneNumberValue: '',
  };
  const [deliverReceiptInfo, setDeliverReceiptInfo] =
    useState(deliverReceiptObj);
  const [signaturePanelInputs, setSignaturePanelInputs] = useState(
    signaturePanelInputsObj
  );
  const [sendDeliveryReceiptInputs, setSendDeliveryReceiptInputs] = useState(
    sendDeliveryReceiptInputsObj
  );
  const [coWorkerSignSaveDisable, setCoWorkerSignSaveDisable] = useState(true);
  const [deliveryReceiptB64, setDeliveryReceiptB64] = useState('');
  const [deliveryCheckListB64, setDeliveryCheckListB64] = useState('');
  const [pickupReceiptB64, setpickupReceiptB64] = useState('');
  const [pickupCheckListB64, setpickupCheckListB64] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState<any>('')

  useEffect(() => {
    // getFieldValues();
    getAgreementInfoFn()
  }, []);


  const getAgreementInfoFn = async () => {
    try {
      sethiddenLoader(true);
      let getAgreementInfoRes = await getAgreementInfo(agreementId);
      setgetAgreementinfoRes(getAgreementInfoRes?.data)
      if (getAgreementInfoRes.status == 200 && getAgreementInfoRes.data) {
        let getAgreementDeliveryRes = await getAgreementDelivery(agreementId, getAgreementInfoRes?.data?.agreementInformation?.appointmentId)
        setgetAgreementDeliveryRes(getAgreementDeliveryRes?.data)
        if (getAgreementDeliveryRes.status == 200 && getAgreementDeliveryRes.data) {
          if ((getAgreementInfoRes?.data?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
            getFieldValues(getAgreementInfoRes?.data, getAgreementDeliveryRes.data)
          }
          else if ((getAgreementInfoRes?.data?.agreementWorkflow?.isSwitchoutEnabled == '1')) {
            getFieldValues(getAgreementInfoRes?.data, getAgreementDeliveryRes.data)
          }
        }
      }
    }
    catch {
      setsomethingWentWrongOpen(true);

    }
  }

  const getFieldValues = async (getAgrInfoRes: any, getAgreementDeliveryRes: any) => {
    debugger;
    try {
      const getnextpaymentPayload = {
        agreementIdCustomerId: [{ agreementId: Number(agreementId),
        customerId: Number(getAgrInfoRes?.agreementCustomer[0]?.customerId) }],
      };
      let deliveryReceiptResponse: any = {};
      let getnextpaymentResponse: any = {};
      let getPrintDocumentRes: any = {}
      sethiddenLoader(true);
      const payLoad: any = {
        identifier: getAgrInfoRes?.agreementInformation?.agreementId,
        documentStatus: 'signed',
        actionType: 'qrcode',
        identifierType: 'AGR',
      };
      if (getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {

        await Promise.all([
          LoadDeliveryreceipt(agreementId),
          getnextpaymentamount(getnextpaymentPayload),
          getPrintDocument(payLoad)
        ]).then((resp) => {
          [deliveryReceiptResponse, getnextpaymentResponse, getPrintDocumentRes] = resp;
        });
      }
      else {
        setisSwitchoutEnabled(true)
        await Promise.all([
          LoadswitchoutDeliveryreceipt(agreementId),
          getnextpaymentamount(getnextpaymentPayload),
          getPrintDocument(payLoad)
        ]).then((resp) => {
          [deliveryReceiptResponse, getnextpaymentResponse, getPrintDocumentRes] = resp;
        });
      }

      sethiddenLoader(false);
      if (
        deliveryReceiptResponse.responseData &&
        getnextpaymentResponse.status == 200
      ) {
        debugger;
        setQrCodeUrl(getPrintDocumentRes?.data[0].documentUrl)
        const response = deliveryReceiptResponse.responseData;
        const amountDueResponse =
          getnextpaymentResponse.data?.amountDueResponse[0]?.currentDueDate;
        const customerInfoObj = {
          customerName: response[0].customerName,
          address: response[0].addressLine1,
          addressLine2: response[0]?.addressLine2,
          city: response[0].storeCity,
          state: response[0].storeState,
          zip: response[0].customerZip,
          phoneNumber: response[0].customerPhoneNumber,
          customerId: response[0].customerId,
        };

        let inventoryDetailsArray: any = ''
        if (getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {
          inventoryDetailsArray = response?.map((obj: any) => ({
            itemDescription: obj.inventoryDesc,
            serialNumber: obj.serialNumber,
            itemNumber: obj.inventoryNumber,
            modelNumber: obj.modelNumber,
          }));
        }
        else {
          inventoryDetailsArray = response?.map((obj: any) => ({
            itemDescription: obj.switchInItem.inventoryDesc,
            serialNumber: obj.switchInItem.serialNumber,
            itemNumber: obj.switchInItem.inventoryNumber,
            modelNumber: obj.switchInItem.modelNumber,
          }));
        }

        let inventoryArrayLength: any = new Array(
          inventoryDetailsArray?.length + 1
        );
        inventoryArrayLength.fill(false)
        setinventorycheckbox(inventoryArrayLength)
        setDeliverReceiptInfo({
          ...deliverReceiptInfo,
          customerInfo: customerInfoObj,
          inventoryInfo: inventoryDetailsArray,
          agreementNumber: response[0].agreementNumber,
          customerNumber: response[0].customerId,
          nextPaymentDue: amountDueResponse,
        });

        // setSendDeliveryReceiptInputs({...sendDeliveryReceiptInputs,phoneNumberValue:response[0].customerPhoneNumber})
        setSignaturePanelInputs({
          ...signaturePanelInputs,
          nameOfThePerson: customerInfoObj.customerName,
        });
        debugger;
        console.log("checkResponse", getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled)
        console.log("appointasmentTypeRes", getAgreementDeliveryRes?.appointmentInfo?.appointmentType)
        if ((getAgrInfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SP' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCI')) {
          setisDeliveryPickupReceipt(true);
          setisPickupEnabled(true)
        } else {
          setIsDeliveryReceiptOpen(true);
        }

        const getCustomerResponse = await GetCustomerId(response[0].customerId);
        if (getCustomerResponse?.status == 200) {
          const getCustomerData = getCustomerResponse?.data;
          console.log("phnNum", getCustomerData)
          setSendDeliveryReceiptInputs({
            ...sendDeliveryReceiptInputs,
            emailValue: getCustomerData?.emailAddress,
            phoneNumberValue: response[0].customerPhoneNumber
          });
          console.log("phncEmailCheck", getCustomerData?.emailAddress, response[0].customerPhoneNumber)

        }
      } else if (deliveryReceiptResponse.status == 400) {
        setServiceErrorMsg(deliveryReceiptResponse?.data?.errors[0]?.error);
        seterrorMessagePopupOpen(true);
      } else if (getnextpaymentResponse.status == 400) {
        setServiceErrorMsg(getnextpaymentResponse?.data?.errors[0]?.error);
        seterrorMessagePopupOpen(true);
      } else {
        setsomethingWentWrongOpen(true);
      }
    } catch {
      setsomethingWentWrongOpen(true);
    }
  };
  const getCurrentDateWithFormat = (segregationKey: string) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${month}${segregationKey}${day}${segregationKey}${year}`;
  };
  const getCurrentTimeWithMeridian = () => {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getCurrentDateFn = (segregationKey?: any) => {
    const currentDate = new Date(segregationKey);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = (currentDate.getDate() + 1).toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  };
  const getTimeFormatFn = (time?: any) => {
    return moment(time, 'hh:mm:a').format('hh:mm a')
  };

  const getDateFormatFn = (date?: any) => {
    return moment(date).format('MM/DD/YYYY')
  }

  const somethingWentWrongOkClick = () => {
    setsomethingWentWrongOpen(false);
    deliveryDigitalSignCanceledFn();
  };
  const addSignatureClick = (value: any) => {
    setCurrentSign(value);
    if (value == 'Customer') {
      setIsSignaturePanelOpen(true);
    } else {
      setIsCowokerSignPanelOpen(true);
    }
    {
      isPickupEnabled ? setisDeliveryPickupReceipt(false) : setIsDeliveryReceiptOpen(false);
    }
  };
  const presentToSignOnChange = (value: any) => {
    if (value == 'Customer') {
      setSignaturePanelInputs({
        ...signaturePanelInputs,
        customerOptionChecked: true,
        othersOptionChecked: false,
        nameOfThePerson: deliverReceiptInfo.customerInfo.customerName,
        isNextButtonDisabled: true,
      });
      signatureRef.current.clear()
    } else {
      setSignaturePanelInputs({
        ...signaturePanelInputs,
        customerOptionChecked: false,
        othersOptionChecked: true,
        nameOfThePerson: '',
        isNextButtonDisabled: true,
      });
      signatureRef.current.clear()

    }
  };
  const nameOfThePersonOnChange = (value: any) => {
    const alphabetRegex = /^[A-Za-z]+$/;
    if (alphabetRegex.test(value)) {
      setSignaturePanelInputs({
        ...signaturePanelInputs,
        nameOfThePerson: value,
      });
    }
  };
  const resetSignaturePanelInput = () => {
    setSignaturePanelInputs({
      customerOptionChecked: true,
      othersOptionChecked: false,
      nameOfThePerson: deliverReceiptInfo.customerInfo.customerName,
      isNextButtonDisabled: true,
    });
  };
  const signaturePanelCancelClick = () => {
    resetSignaturePanelInput();
    setIsSignaturePanelOpen(false);
    {
      isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
    }
  };
  const signaturePanelSaveClick = () => {
    const signatureData = signatureRef.current.toDataURL();
    if (currentSign == 'Customer') {
      setCustomerSignContent(signatureData);
      setDeliverReceiptInfo({
        ...deliverReceiptInfo,
        customerSigned: true,
        nameOftheCustomerSigned: signaturePanelInputs.nameOfThePerson,
      });
      setIsSignaturePanelOpen(false);
    } else {
      setCoworkerSignContent(signatureData);
      setDeliverReceiptInfo({ ...deliverReceiptInfo, coWorkerSigned: true });
      setIsCowokerSignPanelOpen(false);
    }
    resetSignaturePanelInput();
    {
      isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
    }
  };
  const deliveryReceiptCancelClick = () => {
    deliveryDigitalSignCanceledFn();
  };
  const deliveryDocumentContinueClick = () => {
    deliveryDigitalSignCompletedFn();
  };
  const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const viewPDFclick = (b64File: any) => {
    console.log('viewPDFclick triggered');
    const blobResult = b64toBlob(b64File, `application/pdf`);
    const fileURL = URL.createObjectURL(blobResult);
    window.open(fileURL);
  };
  const deliveryDocumentPopup = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              {
                isPickupEnabled ? "Pickup Receipt" : "Delivery Receipt"
              }
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() => {
                viewPDFclick(deliveryReceiptB64);
              }}
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              {
                isPickupEnabled ? "Pickup Checklist" : "Delivery Checklist"
              }
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() => {
                viewPDFclick(deliveryCheckListB64);
              }}
            >
              View PDF
            </RACButton>
          </Grid>
          {isCOAgr ? <>
            <Grid item md={12}>
              <PDFImage className={classes.me2}></PDFImage>
              <Typography variant="caption" className={classes.formLabel}>
                Delivery Addendum

              </Typography>
              <RACButton
                className={classes.agrPrintDoc}
                onClick={() => {
                  viewPDFclick(deliveryAddendumB64);
                }}
              >
                View PDF
              </RACButton>
            </Grid>
          </> : null}
          {isSwitchoutEnabled ? <>
            <Grid item md={12}>
              <PDFImage className={classes.me2}></PDFImage>
              <Typography variant="caption" className={classes.formLabel}>
                Pickup Receipt

              </Typography>
              <RACButton
                className={classes.agrPrintDoc}
                onClick={() => {
                  viewPDFclick(pickupReceiptB64);
                }}
              >
                View PDF
              </RACButton>
            </Grid>
            <Grid item md={12}>
              <PDFImage className={classes.me2}></PDFImage>
              <Typography variant="caption" className={classes.formLabel}>
                Pickup Checklist
              </Typography>
              <RACButton
                className={classes.agrPrintDoc}
                onClick={() => {
                  viewPDFclick(pickupCheckListB64);
                }}
              >
                View PDF
              </RACButton>
            </Grid>
          </> : null}
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
          style={{ textAlign: 'right' }}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => deliveryDocumentContinueClick()}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const makeUploadDocument = async (
    deliveryReceiptFile?: any,
    deliveryCheckListFile?: any,
    deliveryAddendumFile?: any
  ) => {
    debugger;
    // Delivery or carry out
    let uploadDeliveryReceiptPayload: any
    if (((getAgreementinfoRes?.agreementInformation?.agreementType == 'Rental Agreement (RTO)' && getAgreementinfoRes.agreementWorkflow.isCOAgreement == '0') || getAgreementinfoRes?.agreementInformation?.agreementType == 'Promo' || getAgreementinfoRes?.agreementInformation?.agreementType == 'Rent to Rent - Loaner') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'D' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CO' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SD' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCO') && (getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
      uploadDeliveryReceiptPayload = {
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }

    // Return
    else if ((getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SP' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCI') && (getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
      uploadDeliveryReceiptPayload = {
        action: 3,
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'PKRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'PCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }

    // CUSTOMER Order
    else {
      uploadDeliveryReceiptPayload = {
        action: 2,
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
          {
            documentType: 'DELAD',
            file: deliveryAddendumFile,
          },
        ],
      };
    }
    const uploadDeliveryReceiptResponse = await GenerateReceipt(
      uploadDeliveryReceiptPayload
    )
    setUploadeDocLoader(false);
    if (uploadDeliveryReceiptResponse.status == 200) {
      setIsSendDeliveryReceiptOpen(true);
    } else if (uploadDeliveryReceiptResponse.status == 400) {
      setServiceErrorMsg(uploadDeliveryReceiptResponse?.data?.errors[0]?.error);
      seterrorMessagePopupOpen(true);
    } else {
      setsomethingWentWrongOpen(true);
    }
  };
  const makeUploadDocumentSwitchout = async (
    deliveryReceiptFile?: any,
    deliveryCheckListFile?: any,
    pickupReceiptFile?: any,
    pickupChecklistFile?: any
  ) => {
    debugger;
    const uploadDeliveryReceiptPayload = {
      action: 1,
      agreementId: Number(agreementId),
      documents: [
        {
          documentType: 'DLRCT',
          file: deliveryReceiptFile,
        },
        {
          documentType: 'DCL',
          file: deliveryCheckListFile,
        },
        {
          documentType: 'PKRCT',
          file: pickupReceiptFile,
        },
        {
          documentType: 'PCL',
          file: pickupChecklistFile,
        },
      ],
    };
    const uploadDeliveryReceiptRes = await GenerateSwitchoutReceipt(
      uploadDeliveryReceiptPayload
    )
    setUploadeDocLoader(false);
    if (uploadDeliveryReceiptRes.status == 200) {
      setIsSendDeliveryReceiptOpen(true);
    } else if (uploadDeliveryReceiptRes.status == 400) {
      setServiceErrorMsg(uploadDeliveryReceiptRes?.data?.errors[0]?.error);
      seterrorMessagePopupOpen(true);
    } else {
      setsomethingWentWrongOpen(true);
    }
  };
  const UploadeDocLoaderFn = async () => {
    setUploadeDocLoader(true);
  }
  const deliveryReceiptContinueClick = async () => {
    setUploadeDocLoader(true);
    const complete = {
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      address_id: getAgreementDeliveryRes?.customerInfo?.address?.addressId,
      eventInformation: {
        partyId: "12344",
        agreementId: getAgreementinfoRes?.agreementInformation?.agreementId,
        inventoryIds: ['1', '2'],
        type: "string",
        eventDate: "12331",
        eventSource: 'Store',
        eventId: "RescheduleEventId",
        timeSlot: {
          timeSlotId: "timeslotid",
        },
        eventInstruction: "Instructions",
        requiredCoworkers: "NumberofCoworkers",
        racdbSourced: true,
        isMAMVideoWatched: videoWatched ? '1' : '0',
        isCustomerSatisfied: satisfactionCheckBox ? true : false
      },
    };
    const welcomevideoWatchRes = await FinishdeliveryStatus(complete);

    const UploadeDocLoaderRes = await UploadeDocLoaderFn();
    // if (isSwitchoutEnabled) {
    //   setisDeliveryPickupReceipt(true)
    // }
    // else {
    setisDeliveryPickupReceipt(false);
    setIsDeliveryReceiptOpen(false)
    // }
    debugger;
    let deliveryaddendumElement: any
    let deliveryReceiptElement: any
    let deliveryChecklistElement: any
    let pickupReceiptElement: any
    let pickChecklistElement: any
    if (((getAgreementinfoRes?.agreementInformation?.agreementType == 'Rental Agreement (RTO)' && getAgreementinfoRes.agreementWorkflow.isCOAgreement == '0') || getAgreementinfoRes?.agreementInformation?.agreementType == 'Rent to Rent - Loaner' || getAgreementinfoRes?.agreementInformation?.agreementType == 'Promo') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'D' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CO' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SD' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCO') && (getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
      deliveryReceiptElement = document.getElementById(
        'deliveryReceiptContent'
      );
      deliveryChecklistElement = document.getElementById(
        'deliveryCheckListContent'
      );
    }
    // Return Documnet Pick receipt and Pick check list
    else if ((getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SP' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCI') && (getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
      deliveryReceiptElement = document.getElementById(
        'pickupreciptPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'pickupchecklistprintHTML'
      );
    }
    // customer order agreement 
    else if (getAgreementinfoRes.agreementWorkflow.isCOAgreement == '1' && getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {
      setisCOAgr(true);
      deliveryReceiptElement = document.getElementById(
        'deliveryReceiptContent'
      );
      deliveryChecklistElement = document.getElementById(
        'deliveryCheckListContent'
      );
      deliveryaddendumElement = document.getElementById(
        'deliveryaddendum'
      );

    }
    else {
      deliveryReceiptElement = document.getElementById(
        'deliveryReceiptContent'
      );
      deliveryChecklistElement = document.getElementById(
        'deliveryCheckListContent'
      );
      pickupReceiptElement = document.getElementById(
        'pickupreciptPrintHTML'
      );
      pickChecklistElement = document.getElementById(
        'pickupchecklistprintHTML'
      );
    }
    debugger;
    // retrun or delivery
    if (((((getAgreementinfoRes?.agreementInformation?.agreementType == 'Rental Agreement (RTO)' && getAgreementinfoRes.agreementWorkflow.isCOAgreement == '0') || getAgreementinfoRes?.agreementInformation?.agreementType == 'Rent to Rent - Loaner' || getAgreementinfoRes?.agreementInformation?.agreementType == 'Promo') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'D' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CO' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SD' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCO')) || (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SP' ||
      getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'S' ||
      getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'SCI')) && (getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0')) {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader ddelivveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            setDeliveryReceiptB64(b64DeliveryReceipt);
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  setDeliveryCheckListB64(b64DeliveryCheckList);
                  makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList,);
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
    // Customer order
    else if (getAgreementinfoRes?.agreementWorkflow?.isCOAgreement == '1' && getAgreementinfoRes?.agreementWorkflow?.isSwitchoutEnabled == '0') {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            setDeliveryReceiptB64(b64DeliveryReceipt);
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  setDeliveryCheckListB64(b64DeliveryCheckList);
                  html2pdf()
                    .from(deliveryaddendumElement)
                    .outputPdf('blob')
                    .then((pdfBlob) => {
                      const DAreader: any = new FileReader();
                      console.log('reader deliveryReceipt', DAreader);
                      DAreader.onloadend = async () => {
                        console.log('after Reader deliveryReceipt', DAreader);
                        const b64DeliveryAddendumReceipt = DAreader?.result.split(',')[1];
                        setDeliveryAddendumB64(b64DeliveryAddendumReceipt)
                        makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, b64DeliveryAddendumReceipt);
                      };
                      DAreader.readAsDataURL(pdfBlob);
                      // 
                    });
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
    // Switch out
    else {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            setDeliveryReceiptB64(b64DeliveryReceipt);
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  setDeliveryCheckListB64(b64DeliveryCheckList);
                  html2pdf()
                    .from(pickupReceiptElement)
                    .outputPdf('blob')
                    .then((pdfBlob) => {
                      const PRreader: any = new FileReader();
                      console.log('reader deliveryReceipt', PRreader);
                      PRreader.onloadend = async () => {
                        console.log('after Reader deliveryReceipt', PRreader);
                        const b64PickUpReceipt = PRreader?.result.split(',')[1];
                        setpickupReceiptB64(b64PickUpReceipt)
                        html2pdf()
                          .from(pickChecklistElement)
                          .outputPdf('blob')
                          .then((pdfBlob) => {
                            const PCreader: any = new FileReader();
                            console.log('reader deliveryReceipt', PCreader);
                            PCreader.onloadend = async () => {
                              console.log('after Reader deliveryReceipt', PCreader);
                              const b64PickupCheckList = PCreader?.result.split(',')[1];
                              setpickupCheckListB64(b64PickupCheckList)
                              makeUploadDocumentSwitchout(b64DeliveryReceipt, b64DeliveryCheckList, b64PickUpReceipt, b64PickupCheckList);
                            };
                            PCreader.readAsDataURL(pdfBlob);
                            // 
                          });

                      };
                      PRreader.readAsDataURL(pdfBlob);
                      // 
                    });
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
  };
  // const deliveryChecklistEnabledFn = () => {
  //   setdeliveryChecklistEnabled(true);
  // }
  const sendDeliveryReceiptRadioOnchange = (value: any) => {
    if (value == 'Email') {
      setSendDeliveryReceiptInputs({
        ...sendDeliveryReceiptInputs,
        emailChecked: true,
        textChecked: false,
      });
    } else {
      setSendDeliveryReceiptInputs({
        ...sendDeliveryReceiptInputs,
        emailChecked: false,
        textChecked: true,
      });
    }
  };
  const sendDeliveryReceiptEmailOnchange = (value: any) => {
    setSendDeliveryReceiptInputs({
      ...sendDeliveryReceiptInputs,
      emailValue: value,
      invalidEmail: false,
    });
  };
  const sendDeliveryReceiptPhoneNumberOnchange = (value: any) => {
    setSendDeliveryReceiptInputs({
      ...sendDeliveryReceiptInputs,
      phoneNumberValue: value,
      invalidPhone: false,
    });
  };
  const resetSendDeliveryReceiptInputs = () => {
    setSendDeliveryReceiptInputs({
      emailChecked: true,
      textChecked: false,
      emailValue: '',
      invalidEmail: false,
      invalidPhone: false,
      phoneNumberValue: '',
    });
  };
  const sendDeliveryReceiptCall = () => {
    const sendDeliveryReceiptPayload = {
      identifier: String(agreementId),
      identifierType: 'AGR',
      customerName: deliverReceiptInfo.customerInfo.customerName,
      customerFirstName: getAgreementinfoRes.agreementCustomer[0].firstName || '',
      customerLastName: getAgreementinfoRes.agreementCustomer[0].lastName || '',
      customerId: deliverReceiptInfo.customerInfo.customerId,
      documentStatus: 'signed',
      emailId: sendDeliveryReceiptInputs.emailValue,
      actionType: sendDeliveryReceiptInputs.emailChecked ? 'email' : 'text',
      digitalDeliveryType: 'RTOdigiSign',
      customerPhoneNumber: !sendDeliveryReceiptInputs.emailChecked ? sendDeliveryReceiptInputs.phoneNumberValue.replace(/\D/g, '') : null
    };
    setSendDocLoader(true);
    getPrintDocument(sendDeliveryReceiptPayload);
    setSendDocLoader(false);
    setIsDeliveryDocOpen(true);
  }
  const formatePhoneNumber = (value: any) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  }
  const sendDeliveryEmailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(sendDeliveryReceiptInputs.emailValue) ? true : false;
  }
  const sendDeliveryPhoneNumberValidation = () => {
    return sendDeliveryReceiptInputs.phoneNumberValue?.length < 10 ? false : true;
  }
  const sendDeliveryReceiptSendClick = async () => {
    if (sendDeliveryReceiptInputs.emailChecked) {
      const isEmailCheckPassed = sendDeliveryEmailValidation();
      if (isEmailCheckPassed) {
        setIsSendDeliveryReceiptOpen(false);
        sendDeliveryReceiptCall();
      } else {
        setSendDeliveryReceiptInputs({ ...sendDeliveryReceiptInputs, invalidEmail: true })
      }
    }
    else {
      const isPhoneNumberCheckPassed = sendDeliveryPhoneNumberValidation();
      if (isPhoneNumberCheckPassed) {
        setIsSendDeliveryReceiptOpen(false);
        sendDeliveryReceiptCall();
      }
      else {
        setSendDeliveryReceiptInputs({ ...sendDeliveryReceiptInputs, invalidPhone: true })
      }
    }

  };

  const inventoryCheckBoxClick = (index?: any, videoclick?: any) => {
    debugger;
    const updateCheckbox = inventoryCheckBox.map((item, position) => {
      return index == position ? !item : item;
    });
    setinventorycheckbox([...updateCheckbox]);
    if (updateCheckbox[index] == true && videoclick) {
      setvideoWatched(true)
    }
    else {
      setvideoWatched(false)
    }
  };
  function deliveryInfoInventoriesInfoBind(source?: any) {
    let data: any = deliverReceiptInfo?.inventoryInfo;
    if (
      source == "ResendVideo" &&
      sundaySky == '1'
    ) {
      const lineItem = {
        inventoryId: "",
        inventoryNumber: "",
        itemDescription: "",
        serialNumber: "",
        modelNumber: "",
      };
      // if (getAgreementinfoRes?.agreementWorkflow?.isWelcomeVideoSent == "1") {
      //   const activityDesc =
      //     getAgreementinfoRes?.welcomeVideoInfo?.activityDesc?.split(" ");
      //   if (
      //     getAgreementinfoRes?.welcomeVideoInfo?.activityRefCode == "SSKYES"
      //   ) {
      //     lineItem.itemDescription = `Introduction video sent ${getAgreementinfoRes?.welcomeVideoInfo?.activityDate} to email ${activityDesc[4]}.`;
      //   } else {
      //     lineItem.itemDescription = `Introduction video texted ${getAgreementinfoRes?.welcomeVideoInfo?.activityDate
      //       } to phone number ${getAgreementinfoRes?.welcomeVideoInfo?.activityDesc?.replace(
      //         /\D/g,
      //         ""
      //       )}.`;
      //   }
      // } else {
      //   lineItem.itemDescription = "The Introduction video has not been sent.";
      //   //setrepeatContent(true);
      // }

      lineItem.itemDescription = ""
      data.push(lineItem);
      console.log("agreementInfoData if data", data);
    }
    else {
      data = deliverReceiptInfo?.inventoryInfo;
      console.log("agreementInfoData else data", data);
    }
    const uniquedesc = [
      ...new Map(
        data.map((item: any) => [item.itemNumber, item])
      ).values(),
    ];
    return uniquedesc.map((value: any, index: any) => {
      return (
        <tr key={index} style={{ width: "100%" }}>

          <td
            style={{
              width: "15px",
              paddingLeft: "12px",
              paddingRight: "12px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            {value.itemDescription ? <input
              style={{ height: 16, width: 16 }}
              type="checkbox"
              defaultValue=""
              id="Walls"
              checked={inventoryCheckBox[index] === false ? false : true}
              onClick={() => {
                inventoryCheckBoxClick(index);
              }}
            /> : qrCodeUrl && source ? <input
              style={{ height: 16, width: 16 }}
              type="checkbox"
              defaultValue=""
              id="Walls"
              checked={inventoryCheckBox[index] === false ? false : true}
              onClick={() => {
                inventoryCheckBoxClick(index, "videoclick");
              }}
            /> : ''}


          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 6px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '50%',
            }}
          >
            {value.itemDescription
              ? value.itemDescription
              : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 6px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '50%',
            }}
          >
            {value !== undefined &&
              value.serialNumber !== undefined &&
              value.serialNumber !== null &&
              value.serialNumber != ""
              ? value.serialNumber
              : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 6px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '50%',
            }}
          >
            {value !== undefined && value.itemNumber !== undefined
              ? value.itemNumber
              : qrCodeUrl && source ? <QRCode
                style={{ width: '70px', height: '70px' }}
                value={qrCodeUrl}
              /> : ''}
          </td>
        </tr >
      );
    });
  }
  function deliveryAddendum(source?: any) {
    let data: any = deliverReceiptInfo?.inventoryInfo;
    if (
      source == "ResendVideo" &&
      sundaySky == '1'
    ) {
      const lineItem = {
        inventoryId: "",
        inventoryNumber: "",
        itemDescription: "",
        serialNumber: "",
        modelNumber: "",
      };
      lineItem.itemDescription = ""
      data.push(lineItem);
      console.log("agreementInfoData if data", data);
    }
    else {
      data = deliverReceiptInfo?.inventoryInfo;
      console.log("agreementInfoData else data", data);
    }
    const uniquedesc = [
      ...new Map(
        data.map((item: any) => [item.itemNumber, item])
      ).values(),
    ];
    return uniquedesc.map((value: any, index: any) => {
      return (
        <tr key={index} style={{ width: "100%" }}>

          <td
            style={{
              textAlign: 'start',
              padding: '10px 0px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '25%',
            }}
          >
            {value.itemNumber ? value.itemNumber : '-'}
          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 0px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '25%',
            }}
          >
            {value.itemDescription
              ? value.itemDescription : "-"}
          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 0px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '25%',
            }}
          >
            {value.serialNumber
              ? value.serialNumber
              : ''}
          </td>
          <td
            style={{
              textAlign: 'start',
              padding: '10px 0px',
              fontFamily: 'arial',
              fontSize: 12,
              color: '#4A5174',
              width: '25%',
            }}
          >
            {value.modelNumber
              ? value.modelNumber
              : '-'}
          </td>
        </tr >
      );
    });
  }
  const deliveryReceiptPopup = () => {
    return (
      <div>
        <div
          id="deliveryReceiptContent"
          style={{
            border: '3.5px solid #6c757d',
            borderRadius: '10px',
            fontFamily: 'Arial',
          }}
        >
          <div
            style={{
              width: '100%',
              borderTop: '20px solid #2179FE',
              marginBottom: 20,
            }}
          ></div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '95%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <img
                    src={Raclogo}
                    style={{ width: '70px', height: '70px' }}
                  /> */}
                  <Logo />
                  <h2
                    style={{
                      display: 'inline',
                      margin: '0px 20px',
                      fontFamily: 'arial',
                      fontWeight: 'bolder',
                      fontSize: 'x-large',
                    }}
                  >
                    DELIVERY RECEIPT
                  </h2>
                </div>
                <p
                  style={{
                    display: 'inline',
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 11,
                  }}
                >
                  Delivery Date &amp; Time :
                  <span
                    style={{ marginLeft: 6, fontWeight: 500, color: '#4A5174' }}
                  >
                    {`${getCurrentDateFn(getAgreementDeliveryRes?.timeSlotInfo?.startTimeStamp?.split(' ')[0])}  ${getTimeFormatFn(getAgreementDeliveryRes?.timeSlotInfo?.startTimeStamp.split(' ')[1])}`}</span>
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #DFDFDF',
                }}
              >
                <div
                  style={{
                    width: '70%',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <div style={{ width: '45%' }}>
                    <p
                      style={{
                        fontFamily: 'arial',
                        marginBottom: 8,
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      RAC Store
                    </p>
                    <p
                      style={{
                        display: 'inline',
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 10,
                      }}
                    >
                      {`${deliverReceiptInfo.storeInfo.address},${deliverReceiptInfo.storeInfo.city},${deliverReceiptInfo.storeInfo.zip},${deliverReceiptInfo.storeInfo.phoneNumber}`}
                    </p>
                  </div>
                  <div style={{ width: '45%' }}>
                    <p
                      style={{
                        fontFamily: 'arial',
                        marginBottom: 10,
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Customer
                    </p>
                    <p
                      style={{
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 11,
                        margin: 0,
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.customerName}
                    </p>
                    <p
                      style={{
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 11,
                        margin: 0,
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.address}
                    </p>

                    {deliverReceiptInfo?.customerInfo?.addressLine2 ?
                      <p
                        style={{
                          fontFamily: 'arial',
                          fontWeight: 500,
                          lineHeight: '1.8',
                          color: '#4A5174',
                          fontSize: 11,
                          marginTop: 0,
                        }}
                      >
                        {deliverReceiptInfo?.customerInfo?.addressLine2}
                      </p> : null

                    }
                    <p
                      style={{
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 10,
                        marginTop: '-12PX',
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.city +
                        ',' +
                        deliverReceiptInfo.customerInfo.zip + ' ' + phoneNumberFormat(deliverReceiptInfo.customerInfo.phoneNumber)}
                    </p>
                  </div>
                </div>
                <div style={{ width: '30%' }}>
                  <div>
                    <label
                      style={{
                        fontFamily: 'arial',
                        marginBottom: 10,
                        fontSize: 10,
                        fontWeight: 600,
                      }}
                    >
                      Agreement # :
                    </label>
                    <label
                      style={{
                        display: 'inline',
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 11,
                      }}
                    >
                      {deliverReceiptInfo.agreementNumber}
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        fontFamily: 'arial',
                        marginBottom: 10,
                        fontSize: 10,
                        fontWeight: 600,
                      }}
                    >
                      Customer # :
                    </label>
                    <label
                      style={{
                        display: 'inline',
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 11,
                      }}
                    >
                      {deliverReceiptInfo.customerNumber}
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        fontFamily: 'arial',
                        marginBottom: 10,
                        fontSize: 10,
                        fontWeight: 600,
                      }}
                    >
                      Next Payment Due :
                    </label>
                    <label
                      style={{
                        display: 'inline',
                        fontFamily: 'arial',
                        fontWeight: 500,
                        lineHeight: '1.8',
                        color: '#4A5174',
                        fontSize: 9,
                      }}
                    >
                      {getDateFormatFn(deliverReceiptInfo.nextPaymentDue)}
                    </label>
                  </div>
                </div>
              </div>
              <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'Arial',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                ITEMS
              </p>
              <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: 10,
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        background: '#EFF4FF',
                        WebkitPrintColorAdjust: 'exact',
                      }}
                    ><th
                      style={{
                        width: "15px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    ></th>
                      <th
                        style={{
                          textAlign: 'start',
                          padding: '10px 6px',
                          fontFamily: 'arial',
                          fontSize: 14,
                        }}
                      >
                        Description of property
                      </th>
                      <th
                        style={{
                          textAlign: 'start',
                          padding: '10px 6px',
                          fontFamily: 'arial',
                          fontSize: 14,
                        }}
                      >
                        Serial #
                      </th>
                      <th
                        style={{
                          textAlign: 'start',
                          padding: '10px 6px',
                          fontFamily: 'arial',
                          fontSize: 14,
                        }}
                      >
                        Item #
                      </th>
                    </tr>
                  </thead>
                  <tbody>{deliveryInfoInventoriesInfoBind('ResendVideo')}</tbody>
                </table>
              </div>
              <p
                style={{
                  fontFamily: 'arial',
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: '1.8',
                }}
              >
                Before merchandise is taken from the customer's home, please
                check the condition of the following areas and note any damage :
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="Walls"
                    checked={damageCheckBox.Walls}
                    onClick={() => { { damageCheckBox.Walls ? setdamageCheckbox({ ...damageCheckBox, Walls: false }) : setdamageCheckbox({ ...damageCheckBox, Walls: true }) } }}
                  />
                  <label
                    htmlFor="flexCheckDefault"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    Wall(s)
                  </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="Floors-2"
                    checked={damageCheckBox.Floors}
                    onClick={() => { { damageCheckBox.Floors ? setdamageCheckbox({ ...damageCheckBox, Floors: false }) : setdamageCheckbox({ ...damageCheckBox, Floors: true }) } }}

                  />
                  <label
                    htmlFor="flexCheckDefault-2"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    Floors
                  </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="Doorway-3"
                    checked={damageCheckBox.Doorway}
                    onClick={() => { { damageCheckBox.Doorway ? setdamageCheckbox({ ...damageCheckBox, Doorway: false }) : setdamageCheckbox({ ...damageCheckBox, Doorway: true }) } }}



                  />
                  <label
                    htmlFor="flexCheckDefault-3"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    Door(s)/Doorway(s)
                  </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="Stairs-4"
                    checked={damageCheckBox.Statirs}

                    onClick={() => { { damageCheckBox.Statirs ? setdamageCheckbox({ ...damageCheckBox, Statirs: false }) : setdamageCheckbox({ ...damageCheckBox, Statirs: true }) } }}

                  />
                  <label
                    htmlFor="flexCheckDefault-4"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    Stairs
                  </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="Other-5"
                    checked={damageCheckBox.Other}
                    onClick={() => { { damageCheckBox.Other ? setdamageCheckbox({ ...damageCheckBox, Other: false }) : setdamageCheckbox({ ...damageCheckBox, Other: true }) } }}
                  />
                  <label
                    htmlFor="flexCheckDefault-5"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    Other
                  </label>
                </div>
              </div>
              <label
                style={{
                  display: 'block',
                  fontFamily: 'arial',
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: '1.8',
                }}
                htmlFor="text-area"
              >
                If any Boxes were checked, explain damage:
              </label>
              <textarea
                rows={3}
                style={{ width: '100%', resize: 'none', borderColor: "#cecece" }}
                id="text-area"
                defaultValue={''}
                maxLength={240}
                value={damagedescription}
                onChange={(e) => {
                  setDamagedescription(e.target.value)
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  marginTop: 10,
                  marginBottom: 10,
                  backgroundColor: "#E0EAFF"
                }}
              >
                <input
                  style={{ height: 16, width: 16 }}
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault-7"
                  checked={satisfactionCheckBox}
                  onClick={() => {
                    satisfactionCheckBox ? setsatisfactionCheckBox(false) : setsatisfactionCheckBox(true)
                  }}
                />
                <label
                  htmlFor="flexCheckDefault-6"
                  style={{
                    marginLeft: 10,
                    fontFamily: 'arial',
                    fontSize: 13,
                    color: '#4A5174',
                  }}
                >
                  Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                </label>
              </div>

              <p
                style={{
                  fontFamily: 'arial',
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: '1.8',
                }}
              >
                Accept / Decline (Check one)
              </p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <input
                  style={{ height: 16, width: 16, accentColor: '#198754' }}
                  type="checkbox"
                  defaultValue=""
                  id="flexCheckDefault-7"
                  checked={acknowledgeInputs.acceptChecked}
                  // disabled={}
                  onClick={() => {
                    if (!acknowledgeInputs.acceptChecked) {
                      setAcknowledgeInputs({
                        ...acknowledgeInputs,
                        acceptChecked: true,
                        declineChecked: false,
                      });
                    } else {
                      setAcknowledgeInputs({
                        ...acknowledgeInputs,
                        acceptChecked: false,
                      });
                    }
                  }}
                />
                <label
                  htmlFor="flexCheckDefault-6"
                  style={{
                    marginLeft: 10,
                    fontFamily: 'arial',
                    fontSize: 12,
                    color: '#4A5174',
                  }}
                >
                  I acknowledge that Rent-A-Center has delivered, installed, and
                  demonstrated, to my satisfaction, the rental property listed
                  above.
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <input
                  style={{ height: 16, width: 16, accentColor: '#E85C5C' }}
                  type="checkbox"
                  id="flexCheckDefault-7"
                  checked={acknowledgeInputs.declineChecked}
                  onClick={() => {
                    if (!acknowledgeInputs.declineChecked) {
                      setAcknowledgeInputs({
                        ...acknowledgeInputs,
                        declineChecked: true,
                        acceptChecked: false
                      });
                    } else {
                      setAcknowledgeInputs({
                        ...acknowledgeInputs,
                        declineChecked: false,
                      });
                    }
                  }}
                />
                <label
                  htmlFor="flexCheckDefault-7"
                  style={{
                    marginLeft: 10,
                    fontFamily: 'arial',
                    fontSize: 12,
                    color: '#4A5174',
                  }}
                >
                  I decline delivery by Rent-A-Center of the rental property
                  listed above.
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                }}
              >
                <div
                  style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                >
                  <label
                    style={{
                      fontFamily: 'arial',
                      display: 'block',
                      marginBottom: 20,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    Customer Signature
                  </label>
                  <div style={{ marginBottom: 10, textAlign: 'center' }}>
                    {!deliverReceiptInfo.customerSigned ? (
                      <div>
                        <button
                          style={{
                            WebkitPrintColorAdjust: 'exact',
                            backgroundColor:
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? '#2468FF'
                                : '#62A1FE',
                            color: '#fff',
                            fontFamily: 'arial',
                            border: 0,
                            padding: '10px 20px',
                            borderRadius: 5,
                          }}
                          onClick={() => {
                            addSignatureClick('Customer');
                          }}
                          disabled={
                            acknowledgeInputs.acceptChecked ||
                              acknowledgeInputs.declineChecked
                              ? false
                              : true
                          }
                        >
                          Click here to add Signature
                        </button>
                      </div>
                    ) : (
                      <div>
                        <img className={classes.digitalSigantureImg} src={customerSignContent} />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                >
                  <label
                    style={{
                      fontFamily: 'arial',
                      display: 'block',
                      marginBottom: 20,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    Co-Worker Signature
                  </label>
                  <div style={{ marginBottom: 10, textAlign: 'center' }}>
                    {!deliverReceiptInfo.coWorkerSigned ? (
                      <div>
                        <button
                          style={{
                            WebkitPrintColorAdjust: 'exact',
                            backgroundColor:
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? '#2468FF'
                                : '#62A1FE',
                            color: '#fff',
                            fontFamily: 'arial',
                            border: 0,
                            padding: '10px 20px',
                            borderRadius: 5,
                          }}
                          onClick={() => {
                            addSignatureClick('CoWorker');
                          }}
                          disabled={
                            acknowledgeInputs.acceptChecked ||
                              acknowledgeInputs.declineChecked
                              ? false
                              : true
                          }
                        >
                          Click here to add Signature
                        </button>
                      </div>
                    ) : (
                      <div>
                        <img className={classes.digitalSigantureImg} src={coworkerSignContent} />
                      </div>)}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '60%' }}>
                  <div style={{ marginBottom: 10 }}>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Name of the person signed :
                    </label>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#4A5174',
                      }}
                    >
                      {deliverReceiptInfo.nameOftheCustomerSigned}
                    </label>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Date :
                    </label>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#4A5174',
                      }}
                    >
                      {getCurrentDateWithFormat('/')}
                    </label>
                  </div>
                </div>
                <div style={{ width: '40%', marginTop: '25px' }}>
                  <label
                    style={{
                      fontFamily: 'arial',
                      fontSize: 14,
                      fontWeight: 600,

                    }}
                  >
                    Date :
                  </label>
                  <label
                    style={{
                      fontFamily: 'arial',
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#4A5174',
                    }}
                  >
                    {getCurrentDateWithFormat('/')}
                  </label>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
          style={{ marginTop: '100px' }}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={classes.mx1}
            onClick={() => deliveryReceiptCancelClick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            disabled={customerSignContent && coworkerSignContent ? false : true}
            onClick={() => deliveryReceiptContinueClick()}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const pickupReceiptPopup = () => {
    return (
      <div>
        < div
          id="pickupreciptPrintHTML"
          style={{
            border: '3.5px solid #6c757d',
            borderRadius: '10px',
            fontFamily: 'Arial',
          }}
        >

          <div
            style={{
              width: '100%',
              borderTop: '20px solid #2179FE',
              marginBottom: 20,
            }}
          ></div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {/*first part starts here*/}
            <div style={{ width: "95%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {/* {DeliveryDate} */}
                          {getCurrentDateWithFormat('/')}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {/* {DeliveryTime} */}
                          10:00AM
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storename} */}
                      Store Name
                    </p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {StoreAddressline1} */}
                      {decodestoreDetails?.addressLine1}
                    </p>

                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storecity + "," + storezip} */}
                      {decodestoreDetails?.city + "," + decodestoreDetails?.zip}

                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {normalizeinput(Storephonenum)}{" "} */}
                      {decodestoreDetails?.workPhoneNumber}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.customerName}
                    </p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.address}
                    </p>
                    {deliverReceiptInfo?.customerInfo?.addressLine2 ?
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {deliverReceiptInfo?.customerInfo?.addressLine2}
                      </p> : null

                    }
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.city + "," + deliverReceiptInfo.customerInfo.zip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.storeInfo.phoneNumber}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {getCurrentDateWithFormat('/')}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {deliverReceiptInfo.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {deliverReceiptInfo.customerNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "100px",
                  }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {bindChecklist()} */}
                      {deliveryInfoInventoriesInfoBind()}
                      {/* <tr style={{ width: '100%' }}>
                     <td
                       style={{
                         width: '15px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       <span
                         style={{
                           padding: PaddingConst,
                           borderRadius: borderRadiuss,
                           border: Constinter,
                           marginRight: '10px',
                         }}
                       />
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventorydesc}
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventoryseialnum}
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventorynumber}
                     </td>
                   </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              {/* <div
              style={{
                width: "100%",
                float: "left",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <table style={{ width: "100%", float: "left" }}>
                <thead />
                <tbody style={{ width: "100%", float: "left" }}>
                  <tr
                    style={{
                      width: "18%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Employee Signature:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "28%",
                      float: "left",
                      paddingRight: "25px",
                      whiteSpace: "nowrap",
                      marginLeft: "8px",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "18px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                  <tr
                    style={{
                      width: "8%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Date:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "32%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "15px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            </div> */}
              {/* <div
              style={{
                width: "13.33%",
                marginLeft: "2%",
                marginRight: "2%",
                float: "left",
                height: "100px",
                marginBottom: "10px",
              }}
            >
              {/* /> */}
              {/* <Racfont style={{ width: "700%", height: "75%" }}></Racfont> */}
              {/* </div> */}
              {/* fourth Parts End */}

              {/* Fourth New part start here */}
              <div style={{ width: '100%', float: 'left' }}>
                <p
                  style={{
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                >
                  Before merchandise is taken from the customer's home, please
                  check the condition of the following areas and note any damage :
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Walls"
                      checked={damageCheckBox.Walls}
                      onClick={() => { { damageCheckBox.Walls ? setdamageCheckbox({ ...damageCheckBox, Walls: false }) : setdamageCheckbox({ ...damageCheckBox, Walls: true }) } }}
                    />
                    <label
                      htmlFor="flexCheckDefault"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Wall(s)
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Floors-2"
                      checked={damageCheckBox.Floors}
                      onClick={() => { { damageCheckBox.Floors ? setdamageCheckbox({ ...damageCheckBox, Floors: false }) : setdamageCheckbox({ ...damageCheckBox, Floors: true }) } }}

                    />
                    <label
                      htmlFor="flexCheckDefault-2"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Floors
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Doorway-3"
                      checked={damageCheckBox.Doorway}
                      onClick={() => { { damageCheckBox.Doorway ? setdamageCheckbox({ ...damageCheckBox, Doorway: false }) : setdamageCheckbox({ ...damageCheckBox, Doorway: true }) } }}



                    />
                    <label
                      htmlFor="flexCheckDefault-3"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Door(s)/Doorway(s)
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Stairs-4"
                      checked={damageCheckBox.Statirs}

                      onClick={() => { { damageCheckBox.Statirs ? setdamageCheckbox({ ...damageCheckBox, Statirs: false }) : setdamageCheckbox({ ...damageCheckBox, Statirs: true }) } }}

                    />
                    <label
                      htmlFor="flexCheckDefault-4"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Stairs
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Other-5"
                      checked={damageCheckBox.Other}
                      onClick={() => { { damageCheckBox.Other ? setdamageCheckbox({ ...damageCheckBox, Other: false }) : setdamageCheckbox({ ...damageCheckBox, Other: true }) } }}
                    />
                    <label
                      htmlFor="flexCheckDefault-5"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Other
                    </label>
                  </div>
                </div>
                <label
                  style={{
                    display: 'block',
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                  htmlFor="text-area"
                >
                  If any Boxes were checked, explain damage:
                </label>
                <textarea
                  rows={3}
                  style={{ width: '100%', resize: 'none' }}
                  id="text-area"
                  defaultValue={''}
                  maxLength={240}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "#E0EAFF"
                  }}
                >
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault-7"
                    checked={satisfactionCheckBox}
                    onClick={() => {
                      satisfactionCheckBox ? setsatisfactionCheckBox(false) : setsatisfactionCheckBox(true)
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-6"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 13,
                      color: '#4A5174',
                    }}
                  >
                    Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                  </label>
                </div>
                <p
                  style={{
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                >
                  Accept / Decline (Check one)
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <input
                    style={{ height: 16, width: 16, accentColor: '#198754' }}
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault-7"
                    checked={acknowledgeInputs.acceptChecked}
                    onClick={() => {
                      if (!acknowledgeInputs.acceptChecked) {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          acceptChecked: true,
                          declineChecked: false,
                        });
                      } else {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          acceptChecked: false,
                        });
                      }
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-6"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    I acknowledge that Rent-A-Center has delivered, installed, and
                    demonstrated, to my satisfaction, the rental property listed
                    above.
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <input
                    style={{ height: 16, width: 16, accentColor: '#E85C5C' }}
                    type="checkbox"
                    id="flexCheckDefault-7"
                    checked={acknowledgeInputs.declineChecked}
                    onClick={() => {
                      if (!acknowledgeInputs.declineChecked) {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          declineChecked: true,
                          acceptChecked: false
                        });
                      } else {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          declineChecked: false,
                        });
                      }
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-7"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    I decline delivery by Rent-A-Center of the rental property
                    listed above.
                  </label>
                </div>
                <div
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                >
                  <div
                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                  >
                    <label
                      style={{
                        fontFamily: 'arial',
                        display: 'block',
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Customer Signature
                    </label>
                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                      {!deliverReceiptInfo.customerSigned ? (
                        <div>
                          <button
                            style={{
                              WebkitPrintColorAdjust: 'exact',
                              backgroundColor:
                                acknowledgeInputs.acceptChecked ||
                                  acknowledgeInputs.declineChecked
                                  ? '#2468FF'
                                  : '#62A1FE',
                              color: '#fff',
                              fontFamily: 'arial',
                              border: 0,
                              padding: '10px 20px',
                              borderRadius: 5,
                            }}
                            onClick={() => {
                              addSignatureClick('Customer');
                            }}
                            disabled={
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? false
                                : true
                            }
                          >
                            Click here to add Signature
                          </button>
                        </div>
                      ) : (
                        <div>
                          <img className={classes.digitalSigantureImg} src={customerSignContent} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                  >
                    <label
                      style={{
                        fontFamily: 'arial',
                        display: 'block',
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Co-Worker Signature
                    </label>
                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                      {!deliverReceiptInfo.coWorkerSigned ? (
                        <div>
                          <button
                            style={{
                              WebkitPrintColorAdjust: 'exact',
                              backgroundColor:
                                acknowledgeInputs.acceptChecked ||
                                  acknowledgeInputs.declineChecked
                                  ? '#2468FF'
                                  : '#62A1FE',
                              color: '#fff',
                              fontFamily: 'arial',
                              border: 0,
                              padding: '10px 20px',
                              borderRadius: 5,
                            }}
                            onClick={() => {
                              addSignatureClick('CoWorker');
                            }}
                            disabled={
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? false
                                : true
                            }
                          >
                            Click here to add Signature
                          </button>
                        </div>
                      ) : (
                        <div>
                          <img className={classes.digitalSigantureImg} src={coworkerSignContent} />
                        </div>)}
                    </div>
                  </div>
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ marginBottom: 10 }}>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Name of the person signed :
                      </label>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#4A5174',
                        }}
                      >
                        {deliverReceiptInfo.nameOftheCustomerSigned}
                      </label>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Date :
                      </label>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#4A5174',
                        }}
                      >
                        {getCurrentDateWithFormat('/')}
                      </label>
                    </div>
                  </div>
                  <div style={{ width: '40%', marginTop: '25px' }}>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Date :
                    </label>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#4A5174',
                      }}
                    >
                      {getCurrentDateWithFormat('/')}
                    </label>
                  </div>
                  <div></div>
                </div>
              </div>
              {/* Fourth New part end here */}

            </div>
          </div>

        </div >
        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
          style={{ marginTop: '100px' }}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={classes.mx1}
            onClick={() => deliveryReceiptCancelClick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            disabled={customerSignContent && coworkerSignContent ? false : true}
            onClick={() => deliveryReceiptContinueClick()}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    )
  }
  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${decodestoreDetails.workPhoneNumber}`;
  }
  const phoneNumberFormat = (phonenum: any) => {
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const phoneNumberFormat9prefix = (phonenum: any) => {
    if (!phonenum) return '-';
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `9 (${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const bindChecklist = () => {
    return deliverReceiptInfo.inventoryInfo.map((value: any, index: any) => {
      return (
        <tr key={index} style={{ width: '100%' }}>
          <td
            style={{
              width: '15px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            <span
              style={{
                padding: '0px 8px 0px 8px',
                borderRadius: '3px',
                border: '1px solid  #545454',
                marginRight: '10px',
              }}
            />
          </td>
          <td
            style={{
              width: '300px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            {value?.inventoryDesc}
          </td>
          <td
            style={{
              width: '300px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            {value?.serialNumber}
          </td>
          <td
            style={{
              width: '300px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            {value?.inventoryNumber}
          </td>
        </tr>
      );
    });
  };

  // const deliveryChecklist = () => {
  //   return (
  //   );
  // };
  // Pickup Receipt Html End
  const signaturePanelPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid item sm={12} md={12} className={classes.mb3}>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              Who is present to sign?
            </Typography>
          </Grid>
          <Grid item sm={12} md={12} className={classes.mb3}>
            <RACRadio
              value={signaturePanelInputs.customerOptionChecked}
              checked={signaturePanelInputs.customerOptionChecked}
              onChange={() => {
                presentToSignOnChange('Customer');
              }}
              label="Customer"
            />
            <RACRadio
              value={signaturePanelInputs.othersOptionChecked}
              checked={signaturePanelInputs.othersOptionChecked}
              onChange={() => {
                presentToSignOnChange('Others');
              }}
              label="Others"
            />
          </Grid>
          <Grid>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              Name of the person
            </Typography>
            <RACTextbox
              maxlength={40}
              disabled={
                !signaturePanelInputs.othersOptionChecked ? true : false
              }
              value={signaturePanelInputs.nameOfThePerson}
              OnChange={(e) => {
                nameOfThePersonOnChange(e.target.value);
              }}
            />
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
              style={{ marginTop: '30px' }}
            >
              Add the signature here
            </Typography>
            <div id="SignatureContent">
              <div
                style={{
                  border: '1px solid rgb(223, 223, 223)',
                  borderRadius: '5px',
                }}
              >
                <SignatureCanvas
                  penColor="black"
                  ref={signatureRef}
                  canvasProps={{
                    width: 350,
                    height: 200,
                    className: 'sigCanvas',
                  }}
                  onEnd={() => {
                    setSignaturePanelInputs({
                      ...signaturePanelInputs,
                      isNextButtonDisabled: false,
                    });
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.pb4} ${classes.px3}`}
            style={{ marginTop: '30px', marginLeft: '8rem' }}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={() => signaturePanelCancelClick()}
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mx1}
              onClick={() => signaturePanelSaveClick()}
              disabled={signaturePanelInputs.isNextButtonDisabled}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const coWorkerSignPanelPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid>
            <div id="SignatureContent">
              <div
                style={{
                  border: '1px solid rgb(223, 223, 223)',
                  borderRadius: '5px',
                }}
              >
                <SignatureCanvas
                  penColor="black"
                  ref={signatureRef}
                  canvasProps={{
                    width: 350,
                    height: 200,
                    className: 'sigCanvas',
                  }}
                  onEnd={() => {
                    setCoWorkerSignSaveDisable(false);
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.pb4} ${classes.px3}`}
            style={{ marginTop: '30px', marginLeft: '8rem' }}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={() => {
                setIsCowokerSignPanelOpen(false);
                {
                  isPickupEnabled ? setisDeliveryPickupReceipt(true) : setIsDeliveryReceiptOpen(true);
                };
                setCoWorkerSignSaveDisable(true);
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.mx1}
              onClick={() => signaturePanelSaveClick()}
              disabled={coWorkerSignSaveDisable}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  function decideSendDeliverySend() {
    if (sendDeliveryReceiptInputs.textChecked) {
      return false;
    } else if (sendDeliveryReceiptInputs.phoneNumberValue.length > 0) {
      return false;
    } else {
      true;
    }
  }
  const sendDeliveryReceiptPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid item sm={12} md={12} className={classes.mb3}>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              How do you want the delivery receipt to be shared?
            </Typography>
          </Grid>
          <Grid item sm={12} md={12} className={classes.mb3}>
            <RACRadio
              value={sendDeliveryReceiptInputs.emailChecked}
              checked={sendDeliveryReceiptInputs.emailChecked}
              onChange={() => sendDeliveryReceiptRadioOnchange('Email')}
              label="Email"
            />
            <RACRadio
              value={sendDeliveryReceiptInputs.textChecked}
              checked={sendDeliveryReceiptInputs.textChecked}
              onChange={() => sendDeliveryReceiptRadioOnchange('Text')}
              label="Text"
            />
          </Grid>
          <Grid item md={6}>
            {sendDeliveryReceiptInputs.emailChecked ? (
              <div>
                <RACTextbox
                  inputlabel="Email"
                  inputLabelClassname="form-label"
                  name="Email"
                  value={sendDeliveryReceiptInputs.emailValue}
                  OnChange={(e) =>
                    sendDeliveryReceiptEmailOnchange(e.target.value)
                  }
                  isCurrency={false}
                  type="email"
                />
                <label
                  style={{
                    color: 'red',
                    fontSize: 'small',
                    marginLeft: '3%',
                    visibility: sendDeliveryReceiptInputs.invalidEmail ? 'visible' : 'hidden'
                  }}
                >
                  Please enter valid email
                </label>

              </div>
            ) : <div>
              <RACTextbox
                inputlabel="Phone Number"
                inputLabelClassname="form-label"
                name="Phone Number"
                value={formatePhoneNumber(sendDeliveryReceiptInputs.phoneNumberValue)}
                OnChange={(e) =>
                  sendDeliveryReceiptPhoneNumberOnchange(e.target.value)
                }
                isCurrency={false}
                type="text"
                maxlength={10}
              />
              <label
                style={{
                  color: 'red',
                  fontSize: 'small',
                  marginLeft: '3%',
                  visibility: sendDeliveryReceiptInputs.invalidPhone ? 'visible' : 'hidden'
                }}
              >
                Please enter valid phone number
              </label>
            </div>}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            onClick={() => {
              setIsSendDeliveryReceiptOpen(false);
              setIsDeliveryDocOpen(true);
              resetSendDeliveryReceiptInputs();
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.mx1}
            onClick={() => sendDeliveryReceiptSendClick()}
            disabled={decideSendDeliverySend()}
          >
            Send
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const loadingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const uploadingDocPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            Uploading documents
          </Typography>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const sendingDocPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            Sending documents
          </Typography>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => somethingWentWrongOkClick()}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {serviceErrorMsg}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <>
      {/* {deliveryChecklist()} */}
      {/* {deliveryChecklistEnabled ? deliveryChecklist() : null} */}
      <RACModalCard
        isOpen={hiddenLoader}
        closeIcon={false}
        maxWidth="xs"
        children={loadingPopup()}
        borderRadius={modalCardRadius}
      />
      <RACModalCard
        isOpen={uploadeDocLoader}
        closeIcon={false}
        maxWidth="xs"
        children={uploadingDocPopup()}
        borderRadius={modalCardRadius}
      />
      <RACModalCard
        isOpen={sendDocLoader}
        closeIcon={false}
        maxWidth="xs"
        children={sendingDocPopup()}
        borderRadius={modalCardRadius}
      />
      <RACModalCard
        isOpen={isDeliveryReceiptOpen}
        closeIcon={false}
        maxWidth="md"
        borderRadius={modalCardRadius}
      >
        {deliveryReceiptPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={isDeliveryPickupReceipt}
        closeIcon={false}
        maxWidth="md"
        borderRadius={modalCardRadius}
      >
        {pickupReceiptPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={isSignaturePanelOpen}
        closeIcon={false}
        maxWidth="xs"
        children={signaturePanelPopup()}
        borderRadius={modalCardRadius}
        title="Signature Panel"
      />
      <RACModalCard
        isOpen={isCowokerSignPanelOpen}
        closeIcon={false}
        maxWidth="xs"
        children={coWorkerSignPanelPopup()}
        borderRadius={modalCardRadius}
        title="Add the Co-worker signature here"
      />
      <RACModalCard
        isOpen={isSendDeliveryReceiptOpen}
        closeIcon={true}
        maxWidth="sm"
        children={sendDeliveryReceiptPopup()}
        borderRadius={modalCardRadius}
        title="Send Delivery Receipt"
        onClose={() => {
          setIsSendDeliveryReceiptOpen(false);
          setIsDeliveryDocOpen(true);
        }}
      />
      <RACModalCard
        isOpen={isDeliveryDocOpen}
        closeIcon={true}
        maxWidth="xs"
        children={deliveryDocumentPopup()}
        borderRadius={modalCardRadius}
        title="Delivery Document"
        onClose={() => {
          deliveryDigitalSignCompletedFn();
        }}
      />
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        children={somethingWentWrongPopup()}
        borderRadius={modalCardRadius}
      />
      <RACModalCard
        isOpen={errorMessagePopupOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={modalCardRadius}
        children={errorMessagePopup()}
      />

      <Grid style={{ display: 'none' }}>



        {/* Pickup receipt start*/}
        < div
          id="pickupreciptPrintHTML"
          style={{
            border: '3.5px solid #6c757d',
            borderRadius: '10px',
            fontFamily: 'Arial',
          }}
        >

          <div
            style={{
              width: '100%',
              borderTop: '20px solid #2179FE',
              marginBottom: 20,
            }}
          ></div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {/*first part starts here*/}
            <div style={{ width: "95%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {/* {DeliveryDate} */}
                          {getCurrentDateWithFormat('/')}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {/* {DeliveryTime} */}
                          10:00AM
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storename} */}
                      Store Name
                    </p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {StoreAddressline1} */}
                      {decodestoreDetails?.addressLine1}
                    </p>

                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storecity + "," + storezip} */}
                      {decodestoreDetails?.city + "," + decodestoreDetails?.zip}

                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {normalizeinput(Storephonenum)}{" "} */}
                      {decodestoreDetails?.workPhoneNumber}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.customerName}
                    </p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.address}
                    </p>
                    {deliverReceiptInfo?.customerInfo?.addressLine2 ?
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {deliverReceiptInfo?.customerInfo?.addressLine2}
                      </p> : null

                    }
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.customerInfo.city + "," + deliverReceiptInfo.customerInfo.zip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo.storeInfo.phoneNumber}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {getCurrentDateWithFormat('/')}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {deliverReceiptInfo.agreementNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {deliverReceiptInfo.customerNumber}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "100px",
                  }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {bindChecklist()} */}
                      {deliveryInfoInventoriesInfoBind()}
                      {/* <tr style={{ width: '100%' }}>
                     <td
                       style={{
                         width: '15px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       <span
                         style={{
                           padding: PaddingConst,
                           borderRadius: borderRadiuss,
                           border: Constinter,
                           marginRight: '10px',
                         }}
                       />
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventorydesc}
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventoryseialnum}
                     </td>
                     <td
                       style={{
                         width: '300px',
                         paddingLeft: '12px',
                         paddingRight: '12px',
                         paddingTop: '5px',
                         paddingBottom: '5px',
                       }}
                     >
                       {inventorynumber}
                     </td>
                   </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              {/* <div
              style={{
                width: "100%",
                float: "left",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <table style={{ width: "100%", float: "left" }}>
                <thead />
                <tbody style={{ width: "100%", float: "left" }}>
                  <tr
                    style={{
                      width: "18%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Employee Signature:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "28%",
                      float: "left",
                      paddingRight: "25px",
                      whiteSpace: "nowrap",
                      marginLeft: "8px",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "18px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                  <tr
                    style={{
                      width: "8%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Date:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "32%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "15px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
            </div> */}
              {/* <div
              style={{
                width: "13.33%",
                marginLeft: "2%",
                marginRight: "2%",
                float: "left",
                height: "100px",
                marginBottom: "10px",
              }}
            >
              {/* /> */}
              {/* <Racfont style={{ width: "700%", height: "75%" }}></Racfont> */}
              {/* </div> */}
              {/* fourth Parts End */}

              {/* Fourth New part start here */}
              <div style={{ width: '100%', float: 'left' }}>
                <p
                  style={{
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                >
                  Before merchandise is taken from the customer's home, please
                  check the condition of the following areas and note any damage :
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Walls"
                      checked={damageCheckBox.Walls}
                      onClick={() => { { damageCheckBox.Walls ? setdamageCheckbox({ ...damageCheckBox, Walls: false }) : setdamageCheckbox({ ...damageCheckBox, Walls: true }) } }}
                    />
                    <label
                      htmlFor="flexCheckDefault"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Wall(s)
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Floors-2"
                      checked={damageCheckBox.Floors}
                      onClick={() => { { damageCheckBox.Floors ? setdamageCheckbox({ ...damageCheckBox, Floors: false }) : setdamageCheckbox({ ...damageCheckBox, Floors: true }) } }}

                    />
                    <label
                      htmlFor="flexCheckDefault-2"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Floors
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Doorway-3"
                      checked={damageCheckBox.Doorway}
                      onClick={() => { { damageCheckBox.Doorway ? setdamageCheckbox({ ...damageCheckBox, Doorway: false }) : setdamageCheckbox({ ...damageCheckBox, Doorway: true }) } }}



                    />
                    <label
                      htmlFor="flexCheckDefault-3"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Door(s)/Doorway(s)
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Stairs-4"
                      checked={damageCheckBox.Statirs}

                      onClick={() => { { damageCheckBox.Statirs ? setdamageCheckbox({ ...damageCheckBox, Statirs: false }) : setdamageCheckbox({ ...damageCheckBox, Statirs: true }) } }}

                    />
                    <label
                      htmlFor="flexCheckDefault-4"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Stairs
                    </label>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="Other-5"
                      checked={damageCheckBox.Other}
                      onClick={() => { { damageCheckBox.Other ? setdamageCheckbox({ ...damageCheckBox, Other: false }) : setdamageCheckbox({ ...damageCheckBox, Other: true }) } }}
                    />
                    <label
                      htmlFor="flexCheckDefault-5"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 12,
                        color: '#4A5174',
                      }}
                    >
                      Other
                    </label>
                  </div>
                </div>
                <label
                  style={{
                    display: 'block',
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                  htmlFor="text-area"
                >
                  If any Boxes were checked, explain damage:
                </label>
                <textarea
                  rows={3}
                  style={{ width: '100%', resize: 'none' }}
                  id="text-area"
                  defaultValue={''}
                  maxLength={240}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "#E0EAFF"
                  }}
                >
                  <input
                    style={{ height: 16, width: 16 }}
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault-7"
                    checked={satisfactionCheckBox}
                    onClick={() => {
                      satisfactionCheckBox ? setsatisfactionCheckBox(false) : setsatisfactionCheckBox(true)
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-6"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 13,
                      color: '#4A5174',
                    }}
                  >
                    Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                  </label>
                </div>
                <p
                  style={{
                    fontFamily: 'arial',
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '1.8',
                  }}
                >
                  Accept / Decline (Check one)
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <input
                    style={{ height: 16, width: 16, accentColor: '#198754' }}
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault-7"
                    checked={acknowledgeInputs.acceptChecked}
                    onClick={() => {
                      if (!acknowledgeInputs.acceptChecked) {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          acceptChecked: true,
                          declineChecked: false
                        });
                      } else {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          acceptChecked: false,
                        });
                      }
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-6"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    I acknowledge that Rent-A-Center has delivered, installed, and
                    demonstrated, to my satisfaction, the rental property listed
                    above.
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <input
                    style={{ height: 16, width: 16, accentColor: '#E85C5C' }}
                    type="checkbox"
                    id="flexCheckDefault-7"
                    checked={acknowledgeInputs.declineChecked}
                    onClick={() => {
                      if (!acknowledgeInputs.declineChecked) {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          declineChecked: true,
                          acceptChecked: false
                        });
                      } else {
                        setAcknowledgeInputs({
                          ...acknowledgeInputs,
                          declineChecked: false,
                        });
                      }
                    }}
                  />
                  <label
                    htmlFor="flexCheckDefault-7"
                    style={{
                      marginLeft: 10,
                      fontFamily: 'arial',
                      fontSize: 12,
                      color: '#4A5174',
                    }}
                  >
                    I decline delivery by Rent-A-Center of the rental property
                    listed above.
                  </label>
                </div>
                <div
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    display: 'flex'
                  }}
                >
                  <div
                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                  >
                    <label
                      style={{
                        fontFamily: 'arial',
                        display: 'block',
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Customer Signature
                    </label>
                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                      {!deliverReceiptInfo.customerSigned ? (
                        <div>
                          <button
                            style={{
                              WebkitPrintColorAdjust: 'exact',
                              backgroundColor:
                                acknowledgeInputs.acceptChecked ||
                                  acknowledgeInputs.declineChecked
                                  ? '#2468FF'
                                  : '#62A1FE',
                              color: '#fff',
                              fontFamily: 'arial',
                              border: 0,
                              padding: '10px 20px',
                              borderRadius: 5,
                            }}
                            onClick={() => {
                              addSignatureClick('Customer');
                            }}
                            disabled={
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? false
                                : true
                            }
                          >
                            Click here to add Signature
                          </button>
                        </div>
                      ) : (
                        <div>
                          <img className={classes.digitalSigantureImg} src={customerSignContent} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ width: '40%', borderBottom: '1px solid #DFDFDF' }}
                  >
                    <label
                      style={{
                        fontFamily: 'arial',
                        display: 'block',
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Co-Worker Signature
                    </label>
                    <div style={{ marginBottom: 10, textAlign: 'center' }}>
                      {!deliverReceiptInfo.coWorkerSigned ? (
                        <div>
                          <button
                            style={{
                              WebkitPrintColorAdjust: 'exact',
                              backgroundColor:
                                acknowledgeInputs.acceptChecked ||
                                  acknowledgeInputs.declineChecked
                                  ? '#2468FF'
                                  : '#62A1FE',
                              color: '#fff',
                              fontFamily: 'arial',
                              border: 0,
                              padding: '10px 20px',
                              borderRadius: 5,
                            }}
                            onClick={() => {
                              addSignatureClick('CoWorker');
                            }}
                            disabled={
                              acknowledgeInputs.acceptChecked ||
                                acknowledgeInputs.declineChecked
                                ? false
                                : true
                            }
                          >
                            Click here to add Signature
                          </button>
                        </div>
                      ) : (
                        <div>
                          <img className={classes.digitalSigantureImg} src={coworkerSignContent} />
                        </div>)}
                    </div>
                  </div>
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <div style={{ width: '60%' }}>
                    <div style={{ marginBottom: 10 }}>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Name of the person signed :
                      </label>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#4A5174',
                        }}
                      >
                        {deliverReceiptInfo.nameOftheCustomerSigned}
                      </label>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Date :
                      </label>
                      <label
                        style={{
                          fontFamily: 'arial',
                          fontSize: 14,
                          fontWeight: 500,
                          color: '#4A5174',
                        }}
                      >
                        {getCurrentDateWithFormat('/')}
                      </label>
                    </div>
                  </div>
                  <div style={{ width: '40%', marginTop: '25px' }}>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Date :
                    </label>
                    <label
                      style={{
                        fontFamily: 'arial',
                        fontSize: 14,
                        fontWeight: 500,
                        color: '#4A5174',
                      }}
                    >
                      {getCurrentDateWithFormat('/')}
                    </label>
                  </div>
                  <div></div>
                </div>
              </div>
              {/* Fourth New part end here */}

            </div>
          </div>

        </div >
        {/* Pickup receipt end */}
        {/* Delivery Checklist Html Start Here */}
        <div
          style={{
            width: '90%',
            fontFamily: 'Arial',
            margin: '10px auto 0px auto',
          }}
          id="deliveryCheckListContent"

        >
          {/*first part starts here*/}
          <div
            style={{ width: '100%', float: 'left' }}
          >
            <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
              <div style={{ width: '30%', float: 'left' }}>
                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                  Delivery Checklist
                </h5>
              </div>
              <div
                style={{
                  width: '31%',
                  marginLeft: '2%',
                  marginRight: '2%',
                  float: 'left',
                  height: '100px',
                  marginBottom: '10px',
                }}
              >
                <Logo style={{ width: '100%', height: '100%' }}></Logo>
              </div>
              <div></div>
              <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                <thead />
                <tbody style={{ width: '100%', float: 'right' }}>
                  {/*child-parent of first widget*/}
                  <tr style={{ width: '42%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Date:
                    </td>
                  </tr>
                  <tr style={{ width: '58%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: '1px solid #545454',
                          fontSize: '13px',
                        }}
                      >
                        {/* {getCurrentDateWithFormat('/')} */}
                        {getCurrentDateFn(getAgreementDeliveryRes?.timeSlotInfo?.startTimeStamp?.split(' ')[0])}
                      </span>
                    </td>
                  </tr>
                  <tr style={{ width: '43%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Time:
                    </td>
                  </tr>
                  <tr style={{ width: '57%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: '1px solid #545454',
                          fontSize: '13px',
                        }}
                      >
                        {/* {'10:00AM'} */}
                        {getTimeFormatFn(getAgreementDeliveryRes?.timeSlotInfo?.startTimeStamp.split(' ')[1])}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*first part ends here*/}
          {/*second part starts here */}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: '1px solid #DFDFDF',
            }}
          >
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <div style={{ width: '33.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                  RAC Store
                </h5>
                <>
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.address}
                  </p>
                </>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {deliverReceiptInfo.storeInfo.city +
                    ',' +
                    deliverReceiptInfo.storeInfo.zip}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {phoneNumberFormat(deliverReceiptInfo.storeInfo.phoneNumber)}
                </p>
              </div>
              <div style={{ width: '30.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                  Customer
                </h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {deliverReceiptInfo.customerInfo.customerName}
                </p>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {deliverReceiptInfo.customerInfo.address}
                </p>
                {deliverReceiptInfo?.customerInfo?.addressLine2 ? <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {`${deliverReceiptInfo?.customerInfo?.addressLine2}`}
                </p> : null}
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {deliverReceiptInfo.customerInfo.city +
                    ',' +
                    deliverReceiptInfo.customerInfo.zip}
                </p>

                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {phoneNumberFormat(deliverReceiptInfo.customerInfo.phoneNumber)}
                </p>
              </div>
              <div style={{ width: '36.33%', float: 'left', marginTop: '12px' }}>
                <table>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr
                      style={{ width: '50%', textAlign: 'left', float: 'left' }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: '40%', textAlign: 'left', float: 'left' }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {getCurrentDateWithFormat('/')}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Agreement # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {deliverReceiptInfo.agreementNumber}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {deliverReceiptInfo.customerInfo.customerId}{' '}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Next Payment Due:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {getDateFormatFn(deliverReceiptInfo.nextPaymentDue)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/*second part ends here */}
          {/*3rd part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: '1px solid #DFDFDF',
            }}
          >
            <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
              <h5
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontSize: '14px',
                  margin: '0px',
                  marginBottom: '10px',
                }}
              >
                Delivery Checklist
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th
                      style={{
                        width: "15px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    ></th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Description of Property
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Serial #
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Item #
                    </th>
                  </tr>
                </thead>
                <tbody>{deliveryInfoInventoriesInfoBind('ResendVideo')}</tbody>
              </table>
            </div>
          </div>
          {/*3rd part ends*/}
          {/*fourth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              marginBottom: '400px',
              marginTop: '10px',
            }}
          >
            <h5
              style={{
                fontSize: '14px',
                textDecoration: 'underline',
                float: 'left',
              }}
            >
              Delivery Information
            </h5>
            <table style={{ width: '100%', float: 'left' }}>
              <thead />
              <tbody style={{ width: '100%', marginTop: '20px', float: 'left' }}>
                <tr style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    CoWorker Signature:
                  </td>
                </tr>
                <tr
                  style={{
                    width: '28%',
                    float: 'left',
                    paddingRight: '25px',
                    whiteSpace: 'nowrap',
                    marginLeft: '8px',
                  }}
                >
                  <td
                    style={{
                      marginTop: '18px',
                      color: '#656565',
                      borderBottom: '1px solid #545454',
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
                <tr style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date:
                  </td>
                </tr>
                <tr style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginTop: '15px',
                      color: '#656565',
                      borderBottom: '1px solid #545454',
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {/*fourth part ends*/}
          {/*fifth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: '1px solid #DFDFDF',
              marginTop: '18rem'
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <h5
                style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  marginTop: '0px',
                  marginBottom: '10px',
                }}
              >
                For Office Use Only
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <span
                style={{
                  fontSize: '14px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                }}
              >
                I acknowledge that Rent-A-Center has delivered, installed and
                demonstrated, to my satisfaction, the rental property listed
                above.
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%', float: 'left', marginTop: '20px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  Customer Call back survey
                </span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '20px',
                marginBottom: '30px',
              }}
            >
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '35%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date of Call Back
                  </span>
                </div>
                <div style={{ width: '65%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: '1px solid #545454',
                      width: '80%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '30%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mgr/Asst Initials
                  </span>
                </div>
                <div style={{ width: '70%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: '1px dashed #545454',
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*fifth part ends*/}
          {/*letter format starts*/}
          <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
            <p
              style={{
                fontSize: '14px',
                marginBottom: '5px',
                lineHeight: '26px',
              }}
            >
              Hello Mr./Mrs./Ms., this is{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: '1px dashed #545454',
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              , the manager of the Rent-A-Center in{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: '1px dashed #545454',
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              I want to welcome you as a customer and ask you a few questions
              about the delivery we made to you the other day. Is this a
              convenient time for you to answer a few questions?
            </p>
            <p style={{ fontStyle: 'italic', marginTop: '5px' }}>
              {' '}
              (If yes, continue with survey. If no, ask for a convenient time to
              call back)
            </p>
          </div>
          {/*letter format ends*/}
          {/*grid starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <table style={{ marginBottom: '10px', fontSize: '14px' }}>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    1. Was the delivery on time ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    2. Was the unit&apos; operation explained to your satisfaction
                    ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    4. Can you tell me when your next renewal is due and the
                    amount you will pay?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    5. Did we explain our customer referral program?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    6. Did you take advantage of our Benefits Plus program when
                    you rented your item?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: '30px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                      marginRight: '20px',
                    }}
                  >
                    If No explain to them the benefits &amp; close the deal.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    7. Do you have any other questions or is there anything I can
                    do for you?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                    }}
                  >
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: '100%', float: 'left' }}>
            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Notes :</p>
            <span
              style={{
                marginTop: '20px',
                color: '#656565',
                borderBottom: '1px solid #545454',
                width: '100%',
                float: 'left',
              }}
            />
            <span
              style={{
                marginTop: '30px',
                color: '#656565',
                borderBottom: '1px solid #545454',
                width: '100%',
                float: 'left',
              }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                Thank you for your time and please do not hesitate to call us at
                Rent-A-Center with any questions.
              </p>
            </div>
          </div>
          {/*grid ends here*/}
        </div>
        {/* Delivery Checklist Html End Here */}

        {/* Pickup Checklist Html Start Here */}
        <div
          style={{
            width: '90%',
            fontFamily: 'Arial',
            margin: '10px auto 0px auto',
          }}
          id="pickupchecklistprintHTML"
        >
          {/*first part starts here*/}
          <div style={{ width: "100%", float: "left" }}>
            <div
              style={{ width: "100%", fontFamily: "Arial", float: "left" }}
            >
              <div style={{ width: "20.33%", float: "left" }}>
                <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                  Pickup Checklist
                </h5>
              </div>
              <div
                style={{
                  width: "13.33%",
                  marginLeft: "2%",
                  marginRight: "2%",
                  float: "left",
                  height: "100px",
                  marginBottom: "10px",
                }}
              >
                <Logo style={{ width: "100%", height: "100%" }}></Logo>
              </div>
              <div></div>
              <table
                style={{ width: "62.33%", float: "left", marginTop: "30px" }}
              >
                <thead />
                <tbody style={{ width: "100%", float: "left" }}>
                  {/*child-parent of first widget*/}
                  <tr
                    style={{ width: "24%", float: "left", textAlign: "left" }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Pickup Date:
                    </td>
                  </tr>
                  <tr
                    style={{ width: "24%", float: "left", textAlign: "left" }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          fontSize: "13px",
                        }}
                      >
                        {getCurrentDateWithFormat('/')}
                      </span>
                    </td>
                  </tr>
                  <tr
                    style={{ width: "22%", float: "left", textAlign: "left" }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Pickup Time:
                    </td>
                  </tr>
                  <tr
                    style={{ width: "30%", float: "left", textAlign: "left" }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          fontSize: "13px",
                        }}
                      >
                        10:00AM
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/*first part ends here*/}
            {/*second part starts here */}
            <div
              style={{
                width: "100%",
                float: "left",
                borderBottom: borderrr,
              }}
            >
              <div
                style={{ width: "100%", float: "left", marginBottom: "10px" }}
              >
                <div style={{ width: "33.33%", float: "left" }}>
                  <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                    RAC Store
                  </h5>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {/* {storename} */}
                    Store Name
                  </p>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.address}
                  </p>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.city + "," + deliverReceiptInfo.storeInfo.zip}
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.phoneNumber}{" "}
                  </p>
                </div>
                <div style={{ width: "30.33%", float: "left" }}>
                  <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                    Customer
                  </h5>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.customerName}{" "}
                  </p>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.address}
                  </p>
                  {deliverReceiptInfo?.customerInfo?.addressLine2 ?
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {deliverReceiptInfo?.customerInfo?.addressLine2}
                    </p> : null

                  }
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.city + "," + deliverReceiptInfo.customerInfo.zip}
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.phoneNumber}
                  </p>
                </div>
                <div
                  style={{
                    width: "36.33%",
                    float: "left",
                    marginTop: "12px",
                  }}
                >
                  <table>
                    <thead />
                    <tbody style={{ width: "100%", float: "left" }}>
                      <tr
                        style={{
                          width: "50%",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginBottom: "0.2rem",
                            color: "#111111",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Date:
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "40%",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            fontSize: "13px",
                          }}
                        >
                          {getCurrentDateWithFormat('/')}
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "50%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginBottom: "0.2rem",
                            color: "#111111",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Agreement # :
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "50%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            fontSize: "13px",
                          }}
                        >
                          {deliverReceiptInfo.agreementNumber}
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "50%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginBottom: "0.2rem",
                            color: "#111111",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Customer # :
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "40%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            fontSize: "13px",
                          }}
                        >
                          {deliverReceiptInfo.customerNumber}
                          {" "}
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "50%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginBottom: "0.2rem",
                            color: "#111111",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Due Date:
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "40%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            fontSize: "13px",
                          }}
                        >
                          {deliverReceiptInfo.nextPaymentDue}

                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "50%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginBottom: "0.2rem",
                            color: "#111111",
                            fontSize: "13px",
                            fontWeight: "bold",
                          }}
                        >
                          Back Rent:
                        </td>
                      </tr>
                      <tr
                        style={{
                          width: "40%",
                          marginTop: "10px",
                          textAlign: "left",
                          float: "left",
                        }}
                      >
                        <td
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            fontSize: "13px",
                          }}
                        >
                          $0.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*second part ends here */}
            {/*3rd part starts*/}
            <div
              style={{
                width: "100%",
                float: "left",
                borderBottom: borderrr,
              }}
            >
              <div
                style={{ width: "100%", float: "left", marginTop: "15px" }}
              >
                <h5
                  style={{
                    textTransform: "uppercase",
                    textAlign: "center",
                    fontSize: "13px",
                    margin: "0px",
                    marginBottom: "10px",
                  }}
                >
                  PICKUP CHECKLIST
                </h5>
              </div>
              <div
                style={{ width: "100%", float: "left", marginBottom: "10px" }}
              >
                <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                  <thead>
                    <tr style={{ width: "100%" }}>
                      <th
                        style={{
                          width: "15px",
                          textAlign: "left",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      ></th>
                      <th
                        style={{
                          width: "300px",
                          textAlign: "left",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Description of Property
                      </th>
                      <th
                        style={{
                          width: "300px",
                          textAlign: "left",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Serial #
                      </th>
                      <th
                        style={{
                          width: "300px",
                          textAlign: "left",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        Item #
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: '0px 8px 0px 8px',
                              borderRadius: '3px',
                              border: '1px solid  #545454',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                    {/* {bindChecklist()} */}
                    {deliveryInfoInventoriesInfoBind()}
                  </tbody>
                </table>
              </div>
            </div>
            {/*3rd part ends*/}
            {/* fourth part starts here */}
            <table>
              <thead />
              <tbody>
                <tr>
                  <td>
                    <div
                      style={{
                        width: "100%",
                        float: "left",
                        marginBottom: "250px",
                        marginTop: "10px",
                      }}
                    >
                      <h5
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                          float: "left",
                        }}
                      >
                        Pickup Information
                      </h5>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* fourth part ends here */}

            {/*fifth part starts*/}
            <div
              style={{
                width: "100%",
                float: "left",
                borderBottom: borderrr,
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "100%", float: "left" }}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    textAlign: "center",
                    textDecorationLine: "underline",
                  }}
                >
                  Before merchandise is taken from the customer&apos; home,
                  please check the condition of the following areas and note
                  any damage:
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "10px",
                  marginTop: "30px",
                }}
              >
                <div style={{ width: "15%", float: "left" }}>
                  <span
                    style={{
                      padding: PaddingConst,
                      borderRadius: borderRadiuss,
                      border: Constinter,
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Wall(s)
                  </span>
                </div>
                <div style={{ width: "15%", float: "left" }}>
                  <span
                    style={{
                      padding: PaddingConst,
                      borderRadius: borderRadiuss,
                      border: borderalone,
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {" "}
                    Floors
                  </span>
                </div>
                <div style={{ width: "22%", float: "left" }}>
                  <span
                    style={{
                      padding: PaddingConst,
                      borderRadius: borderRadiuss,
                      border: borderalone,
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Door(s)/Doorway(s)
                  </span>
                </div>
                <div style={{ width: "17%", float: "left" }}>
                  <span
                    style={{
                      padding: PaddingConst,
                      borderRadius: borderRadiuss,
                      border: borderalone,
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Stairs
                  </span>
                </div>
                <div style={{ width: "8%", float: "left" }}>
                  <span
                    style={{
                      padding: PaddingConst,
                      borderRadius: borderRadiuss,
                      border: borderalone,
                      marginRight: "10px",
                    }}
                  />
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Other
                  </span>
                </div>
                <div
                  style={{
                    width: "15%",
                    float: "left",
                    marginTop: "12px",
                    marginLeft: "5px",
                  }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid#545454",
                      width: "100%",
                      float: "left",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "40%", float: "left" }}>
                  <p
                    style={{
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    If any boxes were checked, explain damage:
                  </p>
                </div>
                <div
                  style={{ width: "60%", float: "right", marginTop: "20px" }}
                >
                  <span
                    style={{
                      borderBottom: "1px solid#545454",
                      width: "100%",
                      float: "left",
                    }}
                  />
                </div>
              </div>
            </div>
            {/*-fifth part ends*/}
            {/*sixth part starts*/}
            <div
              style={{
                width: "100%",
                float: "left",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <table style={{ width: "100%", float: "left" }}>
                <thead />
                <tbody style={{ width: "100%", float: "left" }}>
                  <tr
                    style={{
                      width: "18%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Customer Signature:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "28%",
                      float: "left",
                      paddingRight: "25px",
                      whiteSpace: "nowrap",
                      marginLeft: "8px",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "18px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                  <tr
                    style={{
                      width: "8%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Date:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "32%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "15px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
              <table
                style={{ width: "100%", float: "left", marginTop: "25px" }}
              >
                <thead />
                <tbody style={{ width: "100%", float: "left" }}>
                  <tr
                    style={{
                      width: "18%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Co-Worker Signature:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "28%",
                      float: "left",
                      paddingRight: "25px",
                      whiteSpace: "nowrap",
                      marginLeft: "8px",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "18px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                  <tr
                    style={{
                      width: "8%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Date:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: "32%",
                      float: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <td
                      style={{
                        marginTop: "15px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
              {/*sixth part ends*/}
            </div>
          </div>

        </div>
        {/* Pickup Checklist Html End Here */}

        {/* Delivery Addendum Html Start Here */}
        <div id="deliveryaddendum">
          <title>Delivery Addendum And Confirmation</title>
          <div style={{
            borderTop: "10px solid #2179FE",
            width: "90%",
            float: "left",
            margin: '10px 0px 0px 15px',
          }}>
            {/*Heading section starts here*/}
            <div
              style={{
                width: "100%",
                float: "left",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Delivery Addendum and Confirmation
              </h3>
            </div>
            {/*Heading section ends here*/}
            {/*detail section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <label
                  style={{
                    float: "left",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Date:
                </label>
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    paddingBottom: "10px",
                    width: "30%",
                    float: "left",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {getCurrentDateWithFormat('/')}
                </span>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <label
                  style={{
                    float: "left",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Agreement Number:
                </label>
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    paddingBottom: "10px",
                    width: "42%",
                    float: "left",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {deliverReceiptInfo.agreementNumber}
                </span>
              </div>
            </div>
            <div style={{ width: "100%", float: "left", marginBottom: "30px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "20%", float: "left" }}>
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Lessor:
                  </label>
                </div>
                <div style={{ width: "80%", float: "left" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "80%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {/* {storename} */}
                    Store Number
                  </span>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "80%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.address}
                  </span>

                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "80%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {deliverReceiptInfo.storeInfo.city + "," + deliverReceiptInfo.storeInfo.zip}
                  </span>
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "25%", float: "left" }}>
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Consumer:
                  </label>
                </div>
                <div style={{ width: "65%", float: "left" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.customerName}
                  </span>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.address}
                  </span>
                  {deliverReceiptInfo?.customerInfo?.addressLine2 ?
                    <p
                      style={{
                        borderBottom: "1px solid #000",
                        paddingBottom: "10px",
                        width: "91%",
                        float: "left",
                        marginTop: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {deliverReceiptInfo?.customerInfo?.addressLine2}
                    </p> : null

                  }
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {deliverReceiptInfo.customerInfo.city + "," + deliverReceiptInfo.customerInfo.zip}
                  </span>
                </div>
              </div>
            </div>
            {/*detail section ends here*/}
            {/*Agreement parah section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <p
                style={{
                  paddingRight: "70px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Upon execution by you, this document will be incorporated into
                and become part of the Rental-Purchase / Lease Purchase
                Agreement referenced above ( the "Agreement"). By accepting
                delivery and signing below, you are confirming that the
                merchandise described below has been delivered and that the
                serial number(s) listed correctly identifies the items listed on
                your Agreement.
              </p>
            </div>
            {/*Agreement parah section ends here*/}
            {/*DESCRIPTION table section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "60px" }}>
              <h4
                style={{
                  padding: "5px",
                  fontSize: "16px",
                  marginBottom: "0px",
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                  borderRight: "1px solid #000",
                }}
              >
                {" "}
                DESCRIPTION OF DELIVERED MERCHANDISE
              </h4>
              <table
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                  borderRight: "1px solid #000",
                }}
              >
                <thead>
                  <tr>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Item #
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Item Description
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Serial #
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Model #
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        9999207978448
                      </span>{" "}
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        MAYTAG 7.0 CF. ELECTRIC DRYER 15
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        999922029478746059
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        MEDC465HW
                      </span>
                    </td>
                  </tr> */}

                </thead>
                <tbody>
                  {deliveryAddendum()}
                </tbody>
              </table>
            </div>
            {/*DESCRIPTION table section ends here*/}
            {/*PLEASE INITIAL section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <label
                style={{
                  marginBottom: "25px",
                  width: "100%",
                  float: "left",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                PLEASE INITIAL ONE OF THE FOLLOWING:
              </label>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "15px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    width: "45px",
                    float: "left",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  I acknowledge that Rent-A-Center has delivered the rental
                  property listed above.
                </label>
              </div>
              <div
                style={{ width: "100%", float: "left", marginBottom: "15px" }}
              >
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    width: "45px",
                    float: "left",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  I decline delivery by Rent-A-Center of the rental property
                  listed above.
                </label>
              </div>
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "40px",
                  fontSize: "16px",
                }}
              >
                YOU AGREE BY SIGNING THIS DELIVERY CONFIRMATION SUPPLEMENT THAT
                (1) YOU HAVE READ IT, (2) THE INFORMATION ABOVE IS CORRECTLY
                STATED, AND (3) YOU RECEIVED A COPY OF IT.
              </p>
            </div>
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "35%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    DATE
                  </label>
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
              </div>
            </div>
            {/*PLEASE INITIAL section ends here*/}
          </div>
        </div>
        {/* Delivery Addendum Html End Hear */}
      </Grid>
    </>
  );
}
