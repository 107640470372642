/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
// eslint-disable-next-line prettier/prettier
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  RACModalCard,
  RACDatePicker,
  Grid,
  RACButton,
  RACTextbox,
  RACRadio,
  RACSelect,
  Typography,
  makeStyles,
  CircularProgress,
  RACCOLOR,
  Card,
  CardContent,
  RACCheckBox,
} from "@rentacenter/racstrap";
import { ReactComponent as Racfont } from "../../../assets/images/RAC-Banner.svg";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "react-number-format";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { addedItemStyles } from "../../../JSstyles/addedItemStyles";
import { agreementGlobalStyles } from "../../../JSstyles/agreementGlobalStyles";
import {
  LoadInventoryInformation,
  getAgreementReturnOptions,
  FinishdeliveryStatus,
  getVoidOptions,
  LoadswitchoutDeliveryreceipt,
  getnextpaymentamount,
  GenerateSwitchoutReceipt,
  getAgreementDelivery,
  CancelPickupStatus,
  LoadDeliveryreceipt,
  getRetentionOffers,
  insertAgreementLog,
  CoworkerRequired,
} from "../../../api/user";
import { printHTML } from "./print";
import SecondFactor from "../TwoFactorPopup/TwoFactorPopup";
import { AgreementContext } from "../../../context/AgreementContext";
import { ReactComponent as Images } from "../../../assets/images/pdf-icon.svg";
import { ContainerContext } from "../../../app/App";
import { ReactComponent as PDFImage } from "../../../assets/images/pdf-icon.svg";

const useClasses = makeStyles((theme: any) => ({
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  me3: {
    marginRight: theme.typography.pxToRem(16),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  pT: {
    paddingTop: theme.typography.pxToRem(24),
  },
  font16: {
    fontSize: theme.typography.pxToRem(16),
  },

  semiBold: {
    fontFamily: "OpenSans-semibold",
  },

  bold: {
    fontFamily: "OpenSans-Bold",
  },
  mt2: {
    marginTop: "8px",
  },
  textCenter: {
    textAlign: "center",
  },
  mb: {
    marginBottom: "2px",
  },
  returnFollowWidth: {
    "& div": {
      "& div": {
        maxWidth: "341px !important",
        // maxHeight: "500px !important",
      },
    },
  },
  returnItemWidth: {
    "& div": {
      "& div": {
        maxWidth: "420px !important",
        // maxHeight: "500px !important",
      },
    },
  },
  hide: {
    display: "none",
  },
  formLabelValue: {
    fontFamily: "OpenSans-semibold",
    fontSize: "14px",
    color: "#4A5174",
  },
  Pl13: {
    paddingLeft: "13Px",
  },
  mt15: {
    marginTop: "15Px",
  },
  hyperLink: {
    cursor: "pointer",
    color: RACCOLOR.BLUE_CRAYOLA,
    textDecoration: "none",
    textDecorationColor: RACCOLOR.BLUE_CRAYOLA,
  },

  mt2_1: {
    marginTop: theme.typography.pxToRem(8),
  },
  mb1: {
    marginBottom: theme.typography.pxToRem(4),
  },
  title: {
    color: "#000",
    fontFamily: "OpenSans-bold",
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(10),
  },
  cardTile: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
    width: "100%",
  },
  payScheduleHighlight: {
    backgroundColor: `#2179FE`,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cardTileStyle: {
    borderStyle: "solid",
    borderWidth: "thin",
    borderColor: "#22a3ff",
  },
  justifyLabel: {
    justifyContent: "center",
  },
  paySchedulePeriodHighlight: {
    color: `${RACCOLOR.WHITE}`,
    fontSize: "15px",
    textAlign: "center",
  },
  paySchedulePeriodNotHighlight: {
    textAlign: "center",
    fontSize: "15px",
    color: "#22a3ff",
  },
  alertwidget: {
    borderRadius: "15px !important",
    background: "#eff4ff",
  },
  mb1p: {
    marginBottom: "1%",
  },

  px0: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  h65: {
    height: "65px",
  },
  paymentAlignCenter: {
    display: "flex",
    alignItems: "center",
  },

  raccollg6: {
    flex: "0 0 auto",
    width: "15%",
  },
  titletxtstyle: {
    fontSize: theme.typography.pxToRem(18),
  },
  pe4: {
    paddingRight: theme.typography.pxToRem(24),
  },
  px20: {
    padding: 10,
  },
  payScheduleNotHighlight: {
    backgroundColor: `${RACCOLOR.WHITE}`,
  },
  gridLabel: {
    color: "#6C86A5",
    fontFamily: "OpenSans-bold",
    fontSize: theme.typography.pxToRem(14),
  },
  Pl15: {
    paddingLeft: "15Px",
  },
  Pl25: {
    paddingLeft: "25Px",
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  card: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
    width: "100%",
  },
  w100: {
    width: "100%",
  },
  floatLeft: {
    float: "left",
  },
  floatRight: {
    float: "right",
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));
export default function AgreementReturn({
  confirmFlow,
  InvisibleFlow,
  ReturnStable,
  paymentredirectFlow,
  AgrInfo,
  printRestrict,
  scheduleReturn,
}: any) {
  interface ParamType {
    customerId: string;
    agreementId: string;
  }
  interface CustomPropInterface {
    GetRole: () => string;
    GetEmployeeId: () => string;
    GetName: () => string;
  }
  const history = useHistory();
  const search = useLocation().search;
  const apid = new URLSearchParams(search).get('apid');
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const pastDueRef = useRef(null);
  const storeNo = window.sessionStorage.getItem("storeNumber");
  const [itemdata, setitemdata] = useState([]);
  const {
    setIsReturn,
    viewAgrDataContext,
    deliveryMethodData,
    amountDueResponse,
    ReturnReasons,
    addressId,
  } = useContext(AgreementContext);
  const [getViewAgrData, setgetViewAgrData] = useState(viewAgrDataContext);
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const [DeliveryDate, setDeliveryDate] = useState("");
  const [DeliveryTime, setDeliveryTime] = useState("");
  const [inventoryInfoList, setInventoryInfoList] = React.useState<any>([]);
  const [returnConfirm, setReturnConfirm] = React.useState<boolean>(false);
  const [returnItem, setReturnItem] = React.useState(false);
  const [AgrSheetHide, setAgrSheetHide] = useState(true);
  const [masterLoader, setmasterLoader]: any = React.useState(false);
  const [customerRetention, setCustomerRetention] = React.useState(false);
  const [transferSuspense, setTransferSuspense] = React.useState(false);
  const [signatureType, setSignatureType] = useState("");
  const [printReceipt, setPrintReceipt] = React.useState(false);
  const [signatureOption, setSignatureOption] = React.useState(false);
  const [newLeadCreate, setnewLeadCreate] = React.useState(false);
  const [returnFollow, setreturnFollow] = React.useState(false);
  const [agrReturned, setAgrReturned] = React.useState(false);
  const [pastDuemaxValidPopup, setpastDuemaxValidPopup] = React.useState(false);
  const [saleLeadNotCreate, setsaleLeadNotCreate] = useState(false);
  const [suspense, setSuspense] = React.useState<number | string>("");
  const [inventoryInfo, setInventoryInfo] = React.useState<any>();
  const [CurrentDate, setCurrentDate] = React.useState("");
  const [MaxDate, setMaxDate] = React.useState("");
  const [AgreementNum, setAgreementNum] = useState("");
  const [returnReasonDisabled, setReturnReasonDisabled] = useState(false);
  const [followupDate, setfollowupDate] = React.useState("");
  const [amountDue, setAmountDue] = React.useState<any>();
  const [signatureCheck, setSignatureCheck] = React.useState("");
  const [pastDueValue, setPastdueValue] = React.useState("0.00");
  const [storename, Setstorename] = useState(""); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storenumber, SetstoreNumber] = useState(""); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storezip, Setstorezip] = useState("");
  const [storestate, Setstorestate] = useState("");
  const [storecity, Setstorecity] = useState("");
  const [StoreAddressline1, SetStoreAddressline1] = useState("");
  const [StoreAddressline2, SetStoreAdressline2] = useState("");
  const [Storephonenum, SetStorephonenum] = useState("");
  const [CustomerName, SetCustomerName] = useState("");
  const [CustomerAddressline1, SetCustomerAddressline1] = useState("");
  const [CustomerAddressline2, SetCustomerAddressline2] = useState("");
  const [CustomerPhoneNumber, SetCustomerPhoneNumber] = useState("");
  const [customercity, Setcustomercity] = useState("");
  const [customerstate, SetCustomerstate] = useState("");
  const [customerzip, Setcustomerzip] = useState("");
  const [customerplus4, Setcustomerplus4] = useState("");
  const [inventorynumber, setinventorynumber] = useState("");
  const [inventorydesc, setinventorydesc] = useState("");
  const [inventoryseialnum, setinventoryserialnum] = useState("");
  const [AgreementId, SetAgreementId] = useState("");
  const [CustomerId, SetCustomerId] = useState("");
  const [ReturnDisableButton, setReturnDisableButton] = useState(true);
  const [print, setprint] = useState(false);
  const [printRestriction, setprintRestriction] = React.useState(false);
  const [currentRole, setCurrentRole] = useState("");
  const nextpaymentobj = {
    nextamountdue: "",
    nextduedate: "",
  };
  const [nextpaymentinfo, setnextpaymentinfo] = useState(nextpaymentobj);
  const [SLAPastDue, setSLAPastDue] = useState("");
  const [CustomerPresent, setCustomerPresent] = useState(false);
  const [SOTwoFactoreEnabled, setSOTwoFactoreEnabled] = useState(false);
  const [SOTwoFactoreEnabledPay, setSOTwoFactoreEnabledPay] = useState(false);
  const [signatureoptionstate, setsignatureoptionstate] = useState<any>([]);
  const [returnReasonValue, setreturnReasonValue] = React.useState<any>([]);
  const [returnItemValues, setReturnitemValues] = React.useState({
    returnReason: "",
  });
  const PaddingConst = "0px 8px 0px 8px";
  const Constinter = "1px solid  #545454";
  const borderRadiuss = "3px";
  const borderbotttom = "1px solid #545454";
  const borderrr = "1px solid #DFDFDF";
  const borderalone = "1px solid   #545454";
  const classes = useClasses();
  const agrClasses = agreementGlobalStyles();
  const addedItemClassName = addedItemStyles();

  // retention changes start
  const [isRetentionEnabled, setIsRetentionEnabled] = useState<boolean>(false);
  const [openSLA, setOpenSLA] = useState<boolean>(false);
  const [openOfferLoading, setOpenOfferLoading] = useState<boolean>(false);
  const [choosenAgreementNumber, setChoosenAgreementNumber] = useState<any>();
  const [choosenAgreementID, setChoosenAgreementID] = useState<any>();
  const [availableRetentionOffers, setAvailableOffers] = useState<any>();
  const [offerResponse, setOfferResponse] = useState<any>();
  const [openRetention, setOpenRetention] = useState<boolean>(false);
  const [choosenOptionIndex, setChoosenIndex] = useState<any>();
  const [choosenRetentionOptions, setChoosenRetentionOptions] = useState<any>();
  const [choosenOfferReason, setChoosenOfferReason] = useState<string>();
  const [retentionOptions, setRetentionOptions] = useState<any>();
  const [openReinstateAlert, setOpenReinstateAlert] = useState<boolean>(false);
  const [openActivityLoading, setOpenActivityLoading] =
    useState<boolean>(false);
  const [agreementType, setAgreementType] = useState<any>();
  const [storeNumber, SetStoreNumber] = useState<any>();
  const availableOffers = [
    "Product",
    "No Longer Needs",
    "EPO too high",
    "Renewal rate too high",
    "Cannot make current payment",
    "Service",
    "Moving",
    "Total RTO/Term too high",
    "SAC too high",
  ];
  // retention changes end

  React.useEffect(() => {
    const currentdate = moment().add(1, "days").format("YYYY-MM-DD");
    const maximumdate = moment().add(3, "months").format("YYYY-MM-DD");
    setCurrentDate(currentdate);
    setfollowupDate(currentdate);
    setMaxDate(maximumdate);
    getReturnOptions();
    inventoryInfoAPIcall();
    PickupPrintApiCall();
    setCurrentRole(containerData?.GetRole());
    configPaymentFunctions();
    if (!confirmFlow && paymentredirectFlow === true) {
      setAmountDue(amountDueResponse?.data?.amountDueResponse[0]);
      setPastdueValue(
        amountDueResponse?.data?.amountDueResponse[0].pastDueRent
      );
      setSuspense(
        amountDueResponse.data?.amountDueResponse[0].agreementRateDetails
          .suspenseAmount
      );
      const reasonDropdown = ReturnReasons?.data.returnReasons;
      const signatureOptionValues = ReturnReasons?.data.signatureType;
      const filterReturnReasons = signatureOptionValues?.sort(function (
        a: any,
        b: any
      ) {
        return b.id < a.id ? -1 : 1;
      });
      const setterReason = [
        { description: "Select Reason", referenceCode: "Select" },
        ...reasonDropdown,
      ];
      setreturnReasonValue(setterReason);
      setsignatureoptionstate(filterReturnReasons);
      setSignatureType(filterReturnReasons[0].referenceCode);
      setReturnItem(true);
    } else if (!confirmFlow) {
      if (
        (deliveryMethodData?.deliveryInformation.deliveryType == "Return" &&
          deliveryMethodData?.deliveryInformation.deliveryStatus ==
          "Completed") ||
        scheduleReturn
      ) {
        returnDeliverySchedule();
      } else {
        setReturnConfirm(true);
      }
    } else {
      returnDeliverySchedule();
    }
  }, [confirmFlow]);

  // useEffect(() => {
  //     inventoryInfoAPIcall()
  //     PickupPrintApiCall();

  // }, [])

  useEffect(() => {
    const InventoryList: any = [];
    const bodyobj = getViewAgrData[0]?.data?.agreementInventories;
    console.log("getViewAfdgrData[0]", getViewAgrData[0]);
    setAgreementType(
      getViewAgrData[0]?.data?.agreementInformation?.agreementType
    );
    SetStoreNumber(getViewAgrData[0]?.data?.agreementInformation?.storeNumber);
    setChoosenAgreementNumber(
      getViewAgrData[0]?.data?.agreementInformation?.agreementNumber
    );

    Promise.all(
      bodyobj.map(async (value: any) => {
        const inventoryInfoResponse = await LoadInventoryInformation(
          value.inventoryNumber
        );
        console.log("inventoryInfokhbkbkbkbkb", inventoryInfoResponse);
        if (inventoryInfoResponse.status == 200) {
          InventoryList.push(inventoryInfoResponse.data);
          console.log("InventoryList", InventoryList);
          setInventoryInfoList(InventoryList);
          //setAgrReturned(true)
        }
      })
    );
  }, []);

  useEffect(() => {
    if (printRestrict) {
      setprintRestriction(true);
    }
  }, [printRestrict]);

  useEffect(() => {
    const collectedRetentionOptions: any = [];
    if (offerResponse) {
      if (choosenOfferReason == "Product" || choosenOfferReason == "Service") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Repair or switch out product",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "SW",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "No Longer Needs") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Explain lifetime reinstatement to the customer",
            offerValue: {
              redirectionPage: "Agreement",
              functionalityInPage: "R",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else if (choosenOfferReason == "Moving") {
        collectedRetentionOptions.push(
          {
            displayLabel: "Give customers their local RAC store info",
            offerValue: {
              redirectionPage: "StoreManagement",
              functionalityInPage: "MOVE",
            },
          },
          {
            displayLabel: "Customer does not accept any offer",
          }
        );
      } else {
        // let alteredReasonDescription: any = '';
        // if (choosenOfferReason == 'Early Purchase Option (EPO) too high') {
        //   alteredReasonDescription = 'EPO too High';
        // } else if (choosenOfferReason == 'Same as Cash (SAC) too high') {
        //   alteredReasonDescription = 'SAC too High';
        // } else if (choosenOfferReason == 'Term Rent to own (TRTO) too high') {
        //   alteredReasonDescription = 'Total RTO/Term too high';
        // } else {
        //   alteredReasonDescription = choosenOfferReason;
        // }
        const filteredReason: any = offerResponse?.returnReasons.filter(
          (el: any) => el.reason == choosenOfferReason
        );
        offerResponse?.agreementOffers.forEach((offer: any) => {
          offer.updateDateTime = new Date(offer.updateDateTime);
        });
        const agreementData: any = offerResponse?.agreementOffers.sort(
          (a: any, b: any) => b.updateDateTime - a.updateDateTime
        );

        filteredReason.map((el: any) => {
          if (el.epoEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].epoOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "EPO",
                currentEpo: agreementData[0].currentEpo,
                epoDiscount: agreementData[0].epoDiscount,
                newEpo: agreementData[0].newEpo,
              },
            });
          }
          if (el.rateReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].rateOfferDescription,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "RR",
                currentRate: agreementData[0].currentRate,
                rateReduction: agreementData[0].rateReduction,
                newRate: agreementData[0].newRate,
              },
            });
          }
          if (el.termReductionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].termOfferReduction,
              offerValue: {
                redirectionPage: "Agreement",
                functionalityInPage: "TR",
                currentRemainingTerm: agreementData[0].currentRemainingTerm,
                termReduction: agreementData[0].termReduction,
                newRemainingTerm: agreementData[0].newRemainingTerm,
              },
            });
          }
          if (el.extensionEligible == "Yes") {
            collectedRetentionOptions.push({
              displayLabel: agreementData[0].extensionOfferDescription,
              offerValue: {
                redirectionPage: "Payment",
                functionalityInPage: "FREETIME",
                extension: agreementData[0].extension,
              },
            });
          }
        });
        if (collectedRetentionOptions.length) {
          collectedRetentionOptions.push({
            displayLabel: "Customer does not accept any offer",
          });
        } else {
          collectedRetentionOptions.push({
            displayLabel: "No retention offer option available",
          });
        }
      }
    } else {
      collectedRetentionOptions.push({
        displayLabel: "No retention offer option available",
      });
    }
    setRetentionOptions(collectedRetentionOptions);
  }, [choosenOfferReason]);

  const getReturnOptions = async () => {
    setReturnReasonDisabled(true);
    const returnResponse: any = await getAgreementReturnOptions(
      customerId,
      storeNo
    );
    console.log("returnResponseeee", returnResponse);
    setReturnReasonDisabled(false);
    if (returnResponse.status == 200) {
      const reasonDropdown = returnResponse.data.returnReasons;
      const signatureOptionValues = returnResponse.data.signatureType;
      const filterReturnReasons = signatureOptionValues.sort(function (
        a: any,
        b: any
      ) {
        return b.id < a.id ? -1 : 1;
      });
      const setterReason = [
        { description: "Select Reason", referenceCode: "Select" },
        ...reasonDropdown,
      ];
      setreturnReasonValue(setterReason);
      setsignatureoptionstate(filterReturnReasons);
      setSignatureType(filterReturnReasons[0].referenceCode);
    }
  };
  //inventoryInformationPrintAPiCall
  const inventoryInfoAPIcall = async () => {
    const payload =
      getViewAgrData[0]?.data?.agreementInventories[0].inventoryNumber;
    const inventoryInfoResponse: any = await LoadInventoryInformation(payload);
    console.log("inventoryInfo", inventoryInfoResponse);
    if (inventoryInfoResponse.status == 200) {
      setInventoryInfo(inventoryInfoResponse.data);
    }
  };
  //AmountDueApiCall
  const getnextpaymentamountCall = async () => {
    const paymentobject = {
      agreementIdCustomerId: [
        {
          agreementId: parseInt(agreementId),
        },
      ],
    };
    setmasterLoader(true);
    const getnextpaymentamountResponse: any = await getnextpaymentamount(
      paymentobject
    );
    getViewAgrData[0]?.data?.agreementWorkflow?.isDeliveryScheduled == 1
      ? setmasterLoader(false)
      : null;
    console.log("nextinventoryInfo", getnextpaymentamountResponse);
    if (getnextpaymentamountResponse.status == 200) {
      setAmountDue(getnextpaymentamountResponse.data?.amountDueResponse[0]);
      setPastdueValue(
        getnextpaymentamountResponse.data?.amountDueResponse[0].pastDueRent
      );
      setSuspense(
        getnextpaymentamountResponse.data?.amountDueResponse[0]
          .agreementRateDetails.suspenseAmount
      );
      if (
        getViewAgrData[0]?.data?.agreementWorkflow?.isDeliveryScheduled == 0
      ) {
        const request = {
          storeNumber: window.sessionStorage.getItem("storeNumber"),
          eventInformation: {
            partyId: "33925768",
            agreementId: agreementId,
            inventoryIds: ["9999207382277"],
            type: "Delivery",
            eventDate: "11/23/2021",
            eventSource: "Store",
            timeSlot: {
              timeSlotId: "9999100000392",
            },
            eventInstructions: "created customer event",
            requiredCoworkers: "2",
            racdbSourced: true,
            isLoaner: "N",
          },
          address_id: addressId,
        };
        const result = await FinishdeliveryStatus(request);
      }
      setmasterLoader(false);
      setReturnItem(true);
    } else {
      setAmountDue("0.00");
      setPastdueValue("0.00");
      setSuspense("0.00");
      setmasterLoader(false);
      setReturnItem(true);
    }
  };
  //PickupChecklist,PickupReceipt API call
  const PickupPrintApiCall = async () => {
    const appointmentId = getViewAgrData[0]?.data?.agreementInformation?.appointmentId ? getViewAgrData[0]?.data?.agreementInformation?.appointmentId : apid
    if (appointmentId) {
      const response = await getAgreementDelivery(agreementId, appointmentId);
      if (response.status == 200) {
        const resData = response.data;
        setDeliveryDate(resData.appointmentInfo.appointmentDate);
        const modifiedStarttime = resData.timeSlotInfo.startTime
          .replace(" PM", "")
          .replace(" AM", "");

        setAgreementType(resData?.agreement?.agreementType);
        SetStoreNumber(resData?.storeNumber);
        const modifiedEndtime = resData.timeSlotInfo.endTime;

        const slicedStartTimeslot =
          modifiedStarttime.slice(0, 5) +
          modifiedStarttime.slice(8, modifiedStarttime.length);
        setDeliveryTime(slicedStartTimeslot + "-" + modifiedEndtime);
      }
    }
  };
  //Dyanamic Data Binding
  const bindChecklist = () => {
    if (itemdata !== undefined && itemdata !== null && itemdata.length > 0) {
      return itemdata.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "15px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <span
                style={{
                  padding: PaddingConst,
                  borderRadius: borderRadiuss,
                  border: Constinter,
                  marginRight: "10px",
                }}
              />
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderBottom: borderbotttom,
              }}
            >
              {value !== undefined && value.inventoryDesc !== undefined
                ? value.inventoryDesc
                : ""}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderBottom: borderbotttom,
              }}
            >
              {value !== undefined &&
                value.serialNumber !== undefined &&
                value.serialNumber !== null &&
                value.serialNumber != ""
                ? value.serialNumber
                : "-"}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderBottom: borderbotttom,
              }}
            >
              {value !== undefined && value.inventoryNumber !== undefined
                ? value.inventoryNumber
                : ""}
            </td>
          </tr>
        );
      });
    }
  };
  //PickupChecklist,PickupReceipt API call
  const agreementsignedmethod = async () => {
    setmasterLoader(true);
    const bodyobj = {
      agreementIdCustomerId: [
        {
          agreementId: parseInt(agreementId),
        },
      ],
    };
    let getamountresponse: any = {
      getamountdata: {},
    };

    let deliveryreceiptresponse: any = {
      deliverydata: {},
    };

    await Promise.all([
      getnextpaymentamount(bodyobj),
      LoadDeliveryreceipt(agreementId),
    ]).then((resp) => {
      [getamountresponse, deliveryreceiptresponse] = resp;

      console.log("asynchronous api calls", getamountresponse);
      console.log(
        "asynchronous api calls for reprint",
        deliveryreceiptresponse
      );
      console.log("responseData", deliveryreceiptresponse.responseData);
      console.log(
        "amountdueresponse",
        getamountresponse.data.amountDueResponse
      );

      if (
        deliveryreceiptresponse !== undefined &&
        deliveryreceiptresponse.responseData !== undefined
      ) {
        setmasterLoader(false);
        setPrintReceipt(true);
        const resUniqueValue: any = deliveryreceiptresponse.responseData.filter(
          (obj, index) => {
            return (
              index ===
              deliveryreceiptresponse.responseData.findIndex(
                (o) => obj.inventoryId === o.inventoryId
              )
            );
          }
        );
        setitemdata(resUniqueValue);

        /* eslint-disable no-console */

        console.log("view response", deliveryreceiptresponse);

        setinventorynumber(
          deliveryreceiptresponse.responseData[0].inventoryNumber
        );
        setinventorydesc(deliveryreceiptresponse.responseData[0].inventoryDesc);
        setinventoryserialnum(
          deliveryreceiptresponse.responseData[0].serialNumber
        );

        Setstorename(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeName
            : ""
        );

        SetstoreNumber(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeNumber
            : ""
        );
        Setstorestate(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeState
            : ""
        );
        Setstorezip(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeZip
            : ""
        );

        Setstorecity(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeCity
            : ""
        );
        SetStorephonenum(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storePhoneNumber
            : ""
        );
        SetStoreAddressline1(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeAddressLine1
            : ""
        );
        SetStoreAdressline2(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeAddressLine2
            : ""
        );
        SetCustomerName(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerName
            : ""
        );
        SetCustomerAddressline1(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].addressLine1
            : ""
        );
        SetCustomerAddressline2(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].addressLine2
            : ""
        );
        Setcustomercity(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerCity
            : ""
        );
        SetCustomerstate(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerState
            : ""
        );

        Setcustomerzip(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerZip
            : ""
        );

        Setcustomerplus4(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerPlus4
            : ""
        );
        SetCustomerPhoneNumber(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
            : ""
        );
        SetAgreementId(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].agreementNumber
            : ""
        );
        SetCustomerId(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerId
            : ""
        );
      }

      if (getamountresponse.status === 504) {
        setmasterLoader(false);
      } else {
        if (
          getamountresponse.data !== undefined &&
          getamountresponse.data !== null &&
          getamountresponse.data.amountDueResponse !== undefined &&
          getamountresponse.data.amountDueResponse !== null
        ) {
          const AmountResponse = getamountresponse.data.amountDueResponse.find(
            (x: { agreementId: string }) => x.agreementId == agreementId
          );
          /* eslint-disable no-console */
          console.log("agreementid related data", AmountResponse);

          if (
            AmountResponse !== undefined &&
            AmountResponse !== null &&
            AmountResponse !== "" &&
            AmountResponse.ldwAmount !== undefined &&
            AmountResponse.ldwAmount !== null
          ) {
            const Totalamountdue =
              parseFloat(AmountResponse.amountDue) +
              parseFloat(AmountResponse.ldwAmount);
            /* eslint-disable no-console */
            console.log("agreementid Total Amount", Totalamountdue);
            setnextpaymentinfo({
              ...nextpaymentinfo,
              nextamountdue: Totalamountdue.toFixed(2),
              nextduedate: AmountResponse.nextDueDate,
            });
            console.log("next amount due", nextpaymentinfo);

            // return Totalamountdue.toFixed(2);
          } else {
            /* eslint-disable no-console */
            console.log("nextpaymentamount", getamountresponse.data);

            setnextpaymentinfo({
              ...nextpaymentinfo,
              nextamountdue:
                getamountresponse.data.amountDueResponse[0].amountDue,
              nextduedate:
                getamountresponse.data.amountDueResponse[0].nextDueDate,
            });
            console.log("next amount due", nextpaymentinfo);
            console.log(
              "getamountdue response",
              getamountresponse.data.amountDueResponse[0].amountDue
            );
            // return getamountresponse.data.amountDueResponse[0].amountDue;
          }

          //   const TempArr: any = [];
          //   if (
          //     deliveryreceiptresponse !== undefined &&
          //     deliveryreceiptresponse.responseData !== undefined
          //   ) {
          //     for (
          //       let i = 0;
          //       i <= deliveryreceiptresponse.responseData.length - 1;
          //       i++
          //     ) {
          //       let obj: any;
          //       if (isCustomerorderenabled === true) {
          //         obj = {
          //           inventoryId:
          //             deliveryreceiptresponse.responseData[i].inventoryId,
          //           inventoryNumber:
          //             deliveryreceiptresponse.responseData[i].inventoryNumber,
          //           serialNumber:
          //             deliveryreceiptresponse.responseData[i].serialNumber,
          //           inventoryDesc:
          //             deliveryreceiptresponse.responseData[i].inventoryDesc,
          //           modelNumber:
          //             deliveryreceiptresponse.responseData[i].modelNumber,
          //         };
          //       } else {
          //         obj = {
          //           inventoryId:
          //             deliveryreceiptresponse.responseData[i].inventoryId,
          //           inventoryNumber:
          //             deliveryreceiptresponse.responseData[i].inventoryNumber,
          //           serialNumber:
          //             deliveryreceiptresponse.responseData[i].serialNumber,
          //           inventoryDesc:
          //             deliveryreceiptresponse.responseData[i].inventoryDesc,
          //         };
          //       }
          //       TempArr.push(obj);
          //     }
          //   }
          //   let generatereceiptpayload: any;
          //   if (isCustomerorderenabled === true) {
          //     generatereceiptpayload = {
          //       action: 2,
          //       inventorydescription: TempArr,
          //       customerName:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerName
          //           : '',
          //       addressLine1:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].addressLine1
          //           : '',
          //       addressLine2:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
          //         deliveryreceiptresponse.responseData[0].addressLine2 !== ''
          //           ? deliveryreceiptresponse.responseData[0].addressLine2
          //           : '',
          //       customerCity:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerCity
          //           : '',
          //       customerZip:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].customerZip !== null &&
          //         deliveryreceiptresponse.responseData[0].customerPlus4 !== null
          //           ? deliveryreceiptresponse.responseData[0].customerZip +
          //             '-' +
          //             deliveryreceiptresponse.responseData[0].customerPlus4
          //           : deliveryreceiptresponse.responseData[0].customerZip,
          //       customerState:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerState
          //           : '',

          //       storeName:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeName
          //           : '',
          //       storeNumber:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeNumber
          //           : '',
          //       storeAddressLine1:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeAddressLine1
          //           : '',
          //       storeAddressLine2:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
          //           null &&
          //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ''
          //           ? deliveryreceiptresponse.responseData[0].storeAddressLine2
          //           : '',
          //       storeCity:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeCity
          //           : '',
          //       storeState:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeState
          //           : '',
          //       storeZip:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeZip
          //           : '',
          //       deliveryDate: DeliveryDate,
          //       agreementNumber: agreementNum,
          //     };
          //   } else {
          //     generatereceiptpayload = {
          //       action: 0,
          //       inventorydescription: TempArr,
          //       customerName:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerName
          //           : '',
          //       addressLine1:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].addressLine1
          //           : '',
          //       addressLine2:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
          //         deliveryreceiptresponse.responseData[0].addressLine2 !== ''
          //           ? deliveryreceiptresponse.responseData[0].addressLine2
          //           : '',
          //       customerCity:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerCity
          //           : '',
          //       customerZip:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].customerZip !== null &&
          //         deliveryreceiptresponse.responseData[0].customerPlus4 !== null
          //           ? deliveryreceiptresponse.responseData[0].customerZip +
          //             '-' +
          //             deliveryreceiptresponse.responseData[0].customerPlus4
          //           : deliveryreceiptresponse.responseData[0].customerZip,
          //       customerState:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerState
          //           : '',
          //       customerPhoneNumber:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
          //           : '',

          //       storeName:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeName
          //           : '',
          //       storeNumber:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeNumber
          //           : '',
          //       storeAddressLine1:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeAddressLine1
          //           : '',
          //       storeAddressLine2:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0 &&
          //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
          //           null &&
          //         deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ''
          //           ? deliveryreceiptresponse.responseData[0].storeAddressLine2
          //           : '',
          //       storePhoneNumber:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storePhoneNumber
          //           : '',
          //       storeCity:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeCity
          //           : '',
          //       storeState:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeState
          //           : '',
          //       storeZip:
          //         deliveryreceiptresponse !== undefined &&
          //         deliveryreceiptresponse.responseData !== undefined &&
          //         deliveryreceiptresponse.responseData.length > 0
          //           ? deliveryreceiptresponse.responseData[0].storeZip
          //           : '',
          //       nexDueAmount:
          //         AmountResponse.amountDue !== undefined &&
          //         AmountResponse.amountDue !== null &&
          //         AmountResponse.ldwAmount !== undefined &&
          //         AmountResponse.ldwAmount !== null
          //           ? (
          //               parseFloat(AmountResponse.amountDue) +
          //               parseFloat(AmountResponse.ldwAmount)
          //             )

          //               .toFixed(2)
          //               .toString()
          //           : getamountresponse.data.amountDueResponse[0].amountDue.toString(),

          //       deliveryDate: DeliveryDate,
          //       deliveryTime: DeliveryTime,
          //       agreementNumber: agreementNum,
          //       agreementId: agreementId,
          //       customerId: customerId,
          //     };
          //   }

          //   generatereceippdf(generatereceiptpayload);
        }
      }
    });
  };
  const DeliveryRedirect = (event: any) => {
    setReturnConfirm(false);
    setIsReturn(true);
    event.preventDefault();
    history.push(`/agreement/info/delivery/${customerId}/${agreementId}`);
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  const returnDeliverySchedule = () => {
    setReturnConfirm(false);
    getnextpaymentamountCall();
  };
  const ReturnConfirmContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Is the customer in-store and want to return the item now?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={DeliveryRedirect}
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={returnDeliverySchedule}
          >
            Yes
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const blurFunction = () => {
    if (
      Number(amountDue.pastDueRent) !== Number(pastDueValue) &&
      pastDueValue != undefined
    ) {
      if (
        Number(pastDueValue) < 0 ||
        Number(amountDue.pastDueRent) < Number(pastDueValue)
      ) {
        setReturnItem(false);
        setpastDuemaxValidPopup(true);
      }
    } else {
      setPastdueValue(amountDue.pastDueRent);
    }
  };
  const normalizeinput = (phonenum: any) => {
    if (phonenum == "") return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, "");

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const returnReasonTypeFunction = () => {
    if (returnReasonValue !== undefined) {
      return returnReasonValue.map((entry: any) => {
        return { label: entry.description, value: entry.referenceCode };
      });
    } else {
      return [{ label: "Select", value: "Select" }];
    }
  };
  const handleOnChange = (e: any) => {
    setfollowupDate(e);
  };
  const PastDueKeyDownChange = (e: any) => {
    console.log(e);
  };
  const PastDueOnchange = (e: any) => {
    setPastdueValue(e);
  };
  //ReturnItem PopupClick
  // const ReturnOnClick = () => {
  //     console.log('deliveryMethodDataReturn',deliveryMethodData);
  //     setReturnItem(false);
  //     if (Number(suspense) > 0) {
  //         setTransferSuspense(true)
  //     }
  //     else if (deliveryMethodData?.deliveryInformation.deliveryType == "Return" && deliveryMethodData?.deliveryInformation.deliveryStatus == "Completed") {
  //         setSignatureOption(true)
  //     }
  //     else {
  //         agreementsignedmethod()
  //     }

  // }
  //ReturnItem PopupClick
  const ReturnOnClick = () => {
    setReturnItem(false);
    if (Number(amountDue?.pastDueRent) !== Number(pastDueValue)) {
      if (
        currentRole == "CAR" ||
        currentRole == "AM" ||
        currentRole == "LAM" ||
        currentRole == "SM"
      ) {
        setSOTwoFactoreEnabled(true);
        setSLAPastDue("pastDueSLA");
      } else if (
        currentRole == "RD" ||
        currentRole == "DM" ||
        currentRole == "DMT"
      ) {
        setSOTwoFactoreEnabled(true);
        setSLAPastDue("pastDuePin");
      }
    } else if (Number(suspense) > 0) {
      setTransferSuspense(true);
    } else if (
      (deliveryMethodData?.deliveryInformation.deliveryType == "Return" &&
        deliveryMethodData?.deliveryInformation.deliveryStatus ==
        "Completed") ||
      printRestriction
    ) {
      setSignatureOption(true);
    } else {
      agreementsignedmethod();
    }
  };
  //Roundoff PopupClick
  const roundOff = (num: any, places: number) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
  };

  const validateObject = (validateObj: any) => {
    if (validateObj != undefined && validateObj !== null) {
      return true;
    } else {
      return false;
    }
  };

  const signCheckFunction = (e) => {
    console.log("signCheckFunction", e.target.value);

    setSignatureCheck(e.target.id);
    setSignatureType(e.target.value);
    if (e.target.value == "SREQ") {
      setAgrSheetHide(true);
    } else {
      setAgrSheetHide(false);
    }
  };

  const signatureSelectOnChange = () => {
    /* eslint-disable no-debugger */
    debugger;
    let currentDate = new Date();
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const openDate = getViewAgrData[0]?.data.agreementInformation.openDate;
    let openDateConv = new Date(openDate);
    openDateConv = new Date(
      openDateConv.getFullYear(),
      openDateConv.getMonth(),
      openDateConv.getDate()
    );
    console.log("currentDate", currentDate);
    console.log("openDateConv", openDateConv);

    if (currentDate.getTime() > openDateConv.getTime()) {
      console.log("00000000");

      setnewLeadCreate(true);
    } else if (currentDate.getTime() == openDateConv.getTime()) {
      console.log("11111111");
      setnewLeadCreate(false);
      AgreementReturnMainAPI();
    }
    setSignatureOption(false);
  };
  const AgreementReturnMainAPI = async () => {
    console.log("signatureType", signatureType);
    console.log("signatureoptionstate", signatureoptionstate);

    setmasterLoader(true);
    const ReturnPayload = {
      storeNumber: storeNo,
      agreementId: agreementId,
      statusReason: returnItemValues.returnReason,
      signatureType:
        signatureType == ""
          ? signatureoptionstate[0].referenceCode
          : signatureType,
      type: "return",
      backRent: pastDueValue,
    };
    console.log("ReturnMainPayload", ReturnPayload);

    const response = await CancelPickupStatus(ReturnPayload);
    console.log("ReturnMainResponse", response);

    setmasterLoader(false);
    if (response.status == 200) {
      setAgreementNum(response?.data.agreementNumber);
      setAgrReturned(true);
      InvisibleFlow();
    }
  };
  //SignatureRadioButton Dynamic Content
  const SignatureOptionContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12}>
          {signatureoptionstate?.map((value: any, index: any) => {
            console.log("signValue", value);
            return (
              <React.Fragment key={index}>
                <RACRadio
                  id={index}
                  value={value.referenceCode}
                  label={value.description}
                  onClick={signCheckFunction.bind(event)}
                  checked={signatureCheck == index ? true : false}
                ></RACRadio>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              setSignatureOption(false);
              ReturnStable();
            }}
          >
            Cancel
          </RACButton>

          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={signatureSelectOnChange}
          >
            Select
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };

  const truncString = (str: any, max: number, add: any) => {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const ReturnInventories = () => {
    const Inventories = Array.prototype.map
      .call(getViewAgrData[0]?.data?.agreementInventories, function (item) {
        return item.inventoryNumber;
      })
      .join(",");

    return truncString(Inventories, 11, "...");
  };
  const titleReturnInventories = () => {
    return Array.prototype.map
      .call(getViewAgrData[0]?.data?.agreementInventories, function (item) {
        return item.inventoryNumber;
      })
      .join(",");
  };

  const ReturnReasonOnchange = (e) => {
    if (e.target.value == "Select") {
      setReturnitemValues({
        ...returnItemValues,
        returnReason: "",
      });
      setReturnDisableButton(true);
    } else {
      setReturnitemValues({
        ...returnItemValues,
        returnReason: e.target.value,
      });
      setReturnDisableButton(false);
    }
  };
  const SOTwoFactorCompleted = (e: any) => {
    console.log("SOTwoFactorCompletedkmkm", e);
    if (e === true) {
      if (Number(suspense) > 0) {
        setTransferSuspense(true);
      } else if (
        deliveryMethodData?.deliveryInformation.deliveryType == "Return" &&
        deliveryMethodData?.deliveryInformation.deliveryStatus == "Completed"
      ) {
        setSignatureOption(true);
      } else {
        agreementsignedmethod();
      }
    }
  };
  const SOTwoFactorCompletedPay = (e: any) => {
    if (e) {
      setCustomerPresent(true);
    }
  };
  const saveSaleLeadFlow = async () => {
    setmasterLoader(true);
    const saleLeadpayload = {
      agreementId: getViewAgrData[0]?.data?.agreementInformation?.agreementId,
      storeNumber: getViewAgrData[0]?.data?.agreementInformation?.storeNumber,
      type: "lead",
      salesLead: {
        entPartyId: "",
        customerId: getViewAgrData[0]?.data?.agreementCustomer[0]?.customerId,
        gcid: getViewAgrData[0]?.data?.agreementCustomer[0]?.globalCustomerId,
        description:
          "Returned" +
          "" +
          getViewAgrData[0]?.data?.agreementInventories?.map((v) => v.inventoryNumber).join(","),
        followUpDate: followupDate,
        userId: containerData?.GetEmployeeId(),
        firstName: getViewAgrData[0]?.data?.agreementCustomer[0]?.firstName,
        lastName: getViewAgrData[0]?.data?.agreementCustomer[0]?.lastName,
        phoneNumber: getViewAgrData[0]?.data?.agreementCustomer[0]?.phoneNumber,
      },
    };
    const response = await CancelPickupStatus(saleLeadpayload);
    setmasterLoader(false);
    if (response.status == 200) {
      AgreementReturnMainAPI();
      //setAgrReturned(true);
      setreturnFollow(false);
    } else {
      setsaleLeadNotCreate(true);
    }
    //setreturnFollow(false)
  };
  const suspensePaymentRedirection = () => {
    setTransferSuspense(false);
    setSOTwoFactoreEnabledPay(true);
    // history.push(`/payment1/paymentinformation/${customerId}/0?returnAGR`)
  };
  const PaymentRedirection = (value: any) => {
    if (value == "Y") {
      history.push(
        `/payment1/paymentinformation/${customerId}/0?agreementId=${agreementId}&type=RETURN&cps=1`
      );
    } else {
      history.push(
        `/payment1/paymentinformation/${customerId}/0?agreementId=${agreementId}&type=RETURN&cps=0`
      );
    }
  };

  //Return Item Contents
  const ReturnItemContent = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingLeft: "20px", paddingBottom: "26px" }}>
            <Typography>Normal Rent</Typography>
            <Typography>
              ${" "}
              {amountDue?.agreementRateDetails?.weeklyRent
                ? amountDue?.agreementRateDetails.weeklyRent
                : "0.00"}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography>Days Late</Typography>
            <Typography>
              {amountDue?.agreementRateDetails?.daysLate
                ? amountDue?.agreementRateDetails.daysLate
                : "0.00"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingLeft: "20px", paddingBottom: "26px" }}>
            <Typography>Carried Rent</Typography>
            <Typography>
              ${" "}
              {amountDue?.agreementRateDetails?.carryRent
                ? amountDue?.agreementRateDetails?.carryRent
                : "0.00"}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography>Carried Late Fee</Typography>
            <Typography>
              ${" "}
              {amountDue?.agreementRateDetails?.carriedLate
                ? amountDue?.agreementRateDetails?.carriedLate
                : "0.00"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingLeft: "20px", paddingBottom: "26px" }}>
            <Typography>Past Due Total</Typography>
            <Grid
              className={`${addedItemClassName.inputGroup} ${agrClasses.wd70}`}
              style={{ height: "33px" }}
            >
              <Typography className={addedItemClassName.inputGroupText}>
                $
              </Typography>

              <CurrencyInput
                id="AgrRate"
                name="AgrRate"
                ref={pastDueRef}
                value={pastDueValue}
                className={`${agrClasses.formControl} ${addedItemClassName.currencyAlign} ${classes.semiBold}`}
                decimalScale={2}
                decimalsLimit={2}
                onValueChange={(e) => PastDueOnchange(e)}
                onBlur={() => blurFunction()}
                onKeyDown={(e) => PastDueKeyDownChange(e)}
              // disabled={controlCurrencyInput}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography
          style={{ margin: "0px 12px 12px 12px", fontFamily: "OpenSans-bold" }}
        >
          Action
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={5} style={{ paddingLeft: "20px", paddingRight: "22px" }}>
            <RACSelect
              inputLabel="Return Reason"
              required={true}
              inputLabelClassName={classes.mb}
              loading={returnReasonDisabled}
              isDisabled={returnReasonDisabled}
              options={returnReasonTypeFunction()}
              defaultValue={
                returnItemValues.returnReason !== ""
                  ? returnItemValues.returnReason
                  : "Select"
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                ReturnReasonOnchange(e)
              }
            // {...(departmentAry.length == 1 &&
            //   !conditionLoader && {
            //     errorMessage: 'Unable to fetch Data',
            //   })}
            />
          </Grid>
        </Grid>

        {isRetentionEnabled &&
          agreementType !== "club" &&
          agreementType !== "Club" &&
          storeNumber == String(window.sessionStorage.getItem("storeNumber")) ? (
          <Typography
            className={`${classes.hyperLink} ${classes.Pl13}  ${classes.mt15}`}
            onClick={() => {
              console.log("currentRole", currentRole);
              setOpenSLA(true);
              setReturnItem(false);
              setChoosenAgreementID(agreementId);
            }}
          >
            View Retention Option
          </Typography>
        ) : null}

        <Grid
          item
          md={12}
          className={`${classes.pT} ${classes.textCenter}`}
          style={{ float: "right" }}
        >
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setReturnItem(false);
              ReturnStable();
            }}
          >
            Cancel
          </RACButton>

          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            disabled={ReturnDisableButton}
            onClick={ReturnOnClick}
          >
            Return
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };

  const PrintReceiptContent = () => {
    return (
      <div>
        <Grid container spacing={2} className={agrClasses.p3}>
          <Grid item md={12}>
            <Images className={agrClasses.me2}></Images>
            <Typography variant="caption" className={agrClasses.formLabel}>
              Pickup Receipt
            </Typography>
            <RACButton
              className={agrClasses.agrPrintDoc}
              onClick={() => {
                printHTML(document.getElementById("pickupreciptPrintHTML"));
              }}
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={agrClasses.me2}></PDFImage>
            <Typography variant="caption" className={agrClasses.formLabel}>
              Pickup Checklist
            </Typography>
            <RACButton
              className={agrClasses.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("pickupchecklistprintHTML"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${agrClasses.pb4} ${agrClasses.px3} ${agrClasses.textCenter}`}
        >
          <RACButton
            className={agrClasses.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSignatureOption(true);
              setPrintReceipt(false);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const TransferSuspenseContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.semiBold} ${classes.font16} ${classes.mt2}`}
          >
            {/* Agreement with suspense is not eligible for a return.Please transfer suspense (${suspense}) to another agreements or to Credit-On-Account and try again. */}
            Agreement with suspense is not eligible for a return.Do you want to
            return the suspense amount (${suspense}) ?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              setTransferSuspense(false);
              ReturnStable();
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={suspensePaymentRedirection}
          >
            Yes
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const CustomerPresentContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.semiBold} ${classes.font16} ${classes.mt2}`}
          >
            Is the customer present?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => PaymentRedirection("N")}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => PaymentRedirection("Y")}
          >
            Yes
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const CreateNewLeadContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Do you want to create a new lead in sales tool?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.pT} ${classes.textCenter}`}>
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setnewLeadCreate(false);
              AgreementReturnMainAPI();
            }}
          >
            No
          </RACButton>

          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              setreturnFollow(true);
              setnewLeadCreate(false);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const pastDueValidContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            Coworker can update pastdue upto 0-{amountDue?.pastDueRent}
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.pT} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              setpastDuemaxValidPopup(false);
              setPastdueValue(amountDue.pastDueRent);
              setReturnItem(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const saleLeadNotCreateContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.semiBold} ${classes.font16}`}>
            The Sales Lead(SLT) is currently not avaliable.Lead was not created.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.pT} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              setsaleLeadNotCreate(false);
              setnewLeadCreate(false);
              AgreementReturnMainAPI();
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const agrReturnedContent = () => {
    return (
      <React.Fragment>
        <Grid item md={12} className={classes.textCenter}>
          <Typography style={{ fontSize: "1rem", fontFamily: "OpenSans-bold" }}>
            Agreement Number {AgreementNum} has been returned
          </Typography>
          <Typography
            className={`${classes.semiBold}  ${classes.mt2}`}
            style={{ marginBottom: "8px" }}
          >
            The following documents are being printed:
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={8} style={{ paddingLeft: "16px", paddingBottom: "26px" }}>
              {AgrSheetHide ? (
                <Typography
                  style={{
                    padding: "2px 3px",
                    color: "#4A5174",
                    textAlign: "left",
                  }}
                >
                  Agreement Return Receipt
                </Typography>
              ) : null}
              {/* <Typography style={{ padding: '2px 3px', color: '#4A5174', textAlign: 'left' }}>Rent Ready Sheet</Typography> */}
              {inventoryInfoList?.map((value, index: any) => {
                return (
                  <Typography
                    key={index}
                    style={{
                      padding: "2px 3px",
                      color: "#4A5174",
                      textAlign: "left",
                    }}
                  >
                    Rent Ready Sheet
                  </Typography>
                );
              })}
            </Grid>
            <Grid xs={4} style={{ paddingLeft: "16px", paddingBottom: "26px" }}>
              {AgrSheetHide ? (
                <Typography
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#0d6efd",
                    padding: "2px 0px",
                  }}
                  onClick={() => {
                    printHTML(
                      document.getElementById("agreementReturnPrintHTML")
                    );
                  }}
                >
                  View PDF
                </Typography>
              ) : null}
              {/* <Typography style={{ cursor: 'pointer', textDecoration: 'underline', color: '#0d6efd', padding: '2px 0px' }} onClick={() => printHTML(document.getElementById('printRentReadyInlineHtml'))}>View PDF</Typography> */}
              {inventoryInfoList?.map((value: any, index: any) => {
                return (
                  <Typography
                    key={index}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#0d6efd",
                      padding: "2px 0px",
                    }}
                    onClick={() =>
                      printHTML(
                        document.getElementById(
                          `printRentReadyInlineHtml-${value.inventoryHeader.itemNumber}`
                        )
                      )
                    }
                  >
                    View PDF
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className={`${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setAgrReturned(false);
              history.push(
                `/agreement/info/details/${customerId}/${agreementId}`
              );
              const navEvent = new PopStateEvent("popstate");
              window.dispatchEvent(navEvent);
              window.location.reload();
            }}
          >
            OK
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };
  const CustomerRetentionContent = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <RACSelect
            inputLabel="Please select why the customer wishes to return to see Customer Retension Offer"
            inputLabelClassName={classes.mb}
            loading={false}
            options={returnReasonTypeFunction()}
            defaultValue={
              returnItemValues.returnReason !== ""
                ? returnItemValues.returnReason
                : "Select"
            }
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              e.target.value == "Select"
                ? setReturnitemValues({
                  ...returnItemValues,
                  returnReason: "",
                })
                : setReturnitemValues({
                  ...returnItemValues,
                  returnReason: e.target.value,
                });
            }}
          // {...(departmentAry.length == 1 &&
          //   !conditionLoader && {
          //     errorMessage: 'Unable to fetch Data',
          //   })}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography style={{ margin: "10px", fontFamily: "OpenSans-bold" }}>
            Retention Options
          </Typography>
        </Grid>
        <Typography style={{ margin: "10px", fontFamily: "OpenSans-bold" }}>
          Co-worker assitance is needed, please select the checkbox of the
          listed items that the customer wants to retain this agreement. These
          details will be added to the agreement and work histories
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.pT} ${classes.textCenter}`}
          style={{ float: "right" }}
        >
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
          // onClick={}
          >
            Cancel
          </RACButton>

          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
          // onClick={props.triggeraddAllItem}
          >
            Continue
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  const ReturnFollowUpContent = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingLeft: "16px", paddingBottom: "26px" }}>
            <Typography>Customer Name</Typography>
            <Typography style={{ color: "#4A5174", fontSize: "13px" }}>
              {getViewAgrData[0]?.data?.agreementCustomer[0].firstName +
                getViewAgrData[0]?.data.agreementCustomer[0].lastName}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography>Phone Number</Typography>
            <Typography style={{ color: "#4A5174", fontSize: "13px" }}>
              {formatPhoneNumber(
                getViewAgrData[0]?.data?.agreementCustomer[0].phoneNumber
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingLeft: "16px", paddingBottom: "26px" }}>
            <Typography>Source</Typography>
            <Typography style={{ color: "#4A5174", fontSize: "13px" }}>
              Return
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography>Description</Typography>
            <Typography
              title={titleReturnInventories()}
              style={{ color: "#4A5174", fontSize: "13px" }}
            >
              Returned {ReturnInventories()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ paddingRight: "36px", marginLeft: "16px" }}>
            <RACDatePicker
              value={followupDate}
              label="Follow Up"
              name="followup"
              // disabled={DropDownDisable}
              inputProps={{
                min: CurrentDate,
                max: MaxDate,
              }}
              // classes={classes.datePickerMargin}
              onChange={(value) => handleOnChange(value)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.pT} ${classes.textCenter}`}
          style={{ float: "right" }}
        >
          <RACButton
            className={classes.me3}
            size="large"
            variant="outlined"
            color="secondary"
          >
            Cancel
          </RACButton>

          <RACButton
            className={classes.mx1}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              setreturnFollow(false);
              saveSaleLeadFlow();
            }}
          >
            Save
          </RACButton>
        </Grid>
      </React.Fragment>
    );
  };

  //Retention Changes

  const configPaymentFunctions = async () => {
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: ["AgreementReturnCustomerRetention"],
    };
    const CoworkerNumResp = await CoworkerRequired(bodyobject);
    const CoworkerNum = CoworkerNumResp?.data;
    debugger;
    console.log("configPaymentFunctions", CoworkerNum);
    if (
      CoworkerNum !== undefined &&
      CoworkerNum !== null &&
      CoworkerNum.storeProfileResponse !== undefined &&
      CoworkerNum.storeProfileResponse &&
      CoworkerNum.storeProfileResponse.configDetails !== undefined &&
      CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
      undefined &&
      CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length > 0
    ) {
      console.log(
        "CoworkerNum.storeProfileResponse.configDetails[0].configDetails",
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails[0]
      );
      for (
        let i = 0;
        i <
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length;
        i++
      ) {
        const configArray =
          CoworkerNum.storeProfileResponse.configDetails[0].configDetails;

        if (configArray[i].paramKeyName == "AgreementReturnCustomerRetention") {
          debugger;
          setIsRetentionEnabled(
            Number(configArray[i].paramValue) > 0 ? true : false
          );
        }
      }
    }
  };

  const SLACanceled = (status) => {
    setOpenSLA(false);
    if (status) {
      setReturnItem(true);
    }
  };

  const SLACompleted = async (status) => {
    setOpenSLA(false);

    if (status) {
      setOpenOfferLoading(true);
      try {
        const retentionOffersResult = await getRetentionOffers({
          agreementNumbers: [String(choosenAgreementNumber)],
        });
        const collectedLabels: any = [];
        const constantLabels: any = [
          "Product",
          "No Longer Needs",
          "Service",
          "Moving",
        ];
        retentionOffersResult.data?.returnReasons.map((el: any) => {
          collectedLabels.push(el.reason);
        });
        setAvailableOffers([...constantLabels, ...collectedLabels]);
        if (retentionOffersResult.data) {
          setOfferResponse(retentionOffersResult.data);
        }
        setOpenRetention(true);
      } catch {
        setOfferResponse(undefined);
        setAvailableOffers(availableOffers);
        setOpenRetention(true);
      }
      setOpenOfferLoading(false);
    }
  };

  const closeRetentionPopup = async () => {
    setOpenRetention(false);
    setChoosenIndex(undefined);
    setChoosenRetentionOptions(undefined);
    setChoosenOfferReason(undefined);
    setReturnItem(true);

    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const payloadAgreementActivities: any = [];
    if (choosenOfferReason == "Total RTO/Term too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `TRTO high Canceled: sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRTRT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRTRT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `TRTO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRTRTO",
        });
      }
    } else if (choosenOfferReason == "SAC too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `SAC high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSACT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSACT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `SAC high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSACTH",
        });
      }
    } else if (choosenOfferReason == "EPO too High") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `EPO high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCREPOT",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCREPOT",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `EPO high Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCREPOTH",
        });
      }
    } else if (choosenOfferReason == "Renewal rate too high") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Rate high Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRRTH",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRRTH",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Rate high Canceled: Not Sel Customer does not accept any offer`,
          agreementActivityType: "CANCRRTH",
        });
      }
    } else if (choosenOfferReason == "Cannot make current payment") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `make paymnt Canceled: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRCMCP",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRCMCP",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `make paymnt Canceled: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCCRCMCP",
        });
      }
    } else if (choosenOfferReason == "Service") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Service: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRSERV",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRSERV",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Service: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRSERV",
        });
      }
    } else if (choosenOfferReason == "Product") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Product: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRPROD",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRPROD",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Product: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRPROD",
        });
      }
    } else if (choosenOfferReason == "Moving") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `Moving: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRMOVI",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRMOVI",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `Moving: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRMOVI",
        });
      }
    } else if (choosenOfferReason == "No Longer Needs") {
      retentionOptions.map((el: any) => {
        if (
          el.displayLabel !== "Customer does not accept any offer" &&
          el.displayLabel !== "No retention offer option available"
        ) {
          payloadAgreementActivities.push({
            activityDate: date,
            agreementId: choosenAgreementID,
            activityDescription: `No Longer Needs: Sel ${el.displayLabel}`,
            agreementActivityType: "CANSCRNLN",
          });
        }
      });
      if (
        choosenRetentionOptions?.displayLabel ==
        "Customer does not accept any offer"
      ) {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Sel Customer does not accept any offer`,
          agreementActivityType: "CANSCRNLN",
        });
      } else {
        payloadAgreementActivities.push({
          activityDate: date,
          agreementId: choosenAgreementID,
          activityDescription: `No Longer Needs: Not sel Customer does not accept any offer`,
          agreementActivityType: "CANCRNLN",
        });
      }
    }
    if (payloadAgreementActivities.length) {
      try {
        const agreementActivityPayloadObj = {
          agreementActivities: payloadAgreementActivities,
        };
        await insertAgreementLog(agreementActivityPayloadObj);
      } catch {
        // No Catch
      }
    }
  };

  const acceptReinstate = async () => {

    setOpenActivityLoading(true);

    const today = new Date();

    const date = today.toISOString().split('T')[0];

    const agreementActivityPayloadObj = {

      agreementActivities: [

        {

          activityDate: date,

          agreementId: choosenAgreementID,

          activityDescription: 'No Longer Needs Selected',

          agreementActivityType: 'NLNS',

        },

      ],

    };

    try {

      await insertAgreementLog(agreementActivityPayloadObj);

    } catch {

      // Exception Handle

    }

    history.push({

      pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,

      search: `?source=AMRETENTION&type=R`,

      state: {

        retentionData: offerResponse,

      },

    });

    setOpenActivityLoading(false);

  };

  const buildReinstateAlert = () => {

    return (

      <Grid>

        <Grid className={`${classes.textCenter}`}>

          <Typography className={`${classes.bold}`}>

            At any time you can contact your store to schedule a return of your

            product or return merchandise in person and pause your payments.

            When you are ready, you can come back, reinstate your agreement to

            get the same or comparable item, and pick up your payments right

            where you left off.

          </Typography>

        </Grid>

        <Grid className={`${classes.py4} ${classes.textCenter}`}>

          <RACButton

            className={classes.mx1}

            variant="contained"

            color="primary"

            onClick={() => {

              setOpenReinstateAlert(false);

              acceptReinstate();

            }}

          >

            Return

          </RACButton>

          <RACButton

            className={classes.mx1}

            variant="outlined"

            color="primary"

            onClick={() => {

              setOpenReinstateAlert(false);

              setOpenRetention(true);

            }}

          >

            Cancel

          </RACButton>

        </Grid>

      </Grid>

    );

  };


  const recordRedirection = async () => {
    setOpenActivityLoading(true);
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const agreementActivityPayload: any = [];
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement EPO Selected",
        agreementActivityType: "AEPOS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Extension Selected",
        agreementActivityType: "AEXTNS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Rate Adjustment Selected",
        agreementActivityType: "ARAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agreement Term Adjustment Selected",
        agreementActivityType: "ATAS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Switchout Selected",
        agreementActivityType: "AGMRETSWS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention Service Selected",
        agreementActivityType: "AGMRETSS",
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Moving Selected",
        agreementActivityType: "MS",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "Customer does not accept any offer"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Selected",
        agreementActivityType: "AGMRETNONE",
      });
    } else if (
      choosenRetentionOptions.displayLabel ==
      "No retention offer option available"
    ) {
      agreementActivityPayload.push({
        activityDate: date,
        agreementId: choosenAgreementID,
        activityDescription: "Agr Retention No Offer Available",
        agreementActivityType: "RETNOAVAIL",
      });
    }
    const agreementActivityPayloadObj = {
      agreementActivities: agreementActivityPayload,
    };
    try {
      setmasterLoader(true);
      await insertAgreementLog(agreementActivityPayloadObj);
      setmasterLoader(false);
    } catch {
      // Exception Handle
    }
    if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "EPO"
    ) {
      const epoDiscountAmount = choosenRetentionOptions?.offerValue?.epoDiscount
        .trim()
        .replace(/\$/g, "");
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=EPO&SRC=PAYMENT`,
        state: {
          retentionData: {
            newEpo: choosenRetentionOptions?.offerValue?.newEpo,
            epoDiscount: epoDiscountAmount,
            currentEpo: choosenRetentionOptions?.offerValue?.currentEpo,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
            retentionEPOMessage: choosenRetentionOptions.displayLabel,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Payment" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "FREETIME"
    ) {
      history.push({
        pathname: `/payment1/paymentinformation/${customerId}/0`,
        search: `?CRTYPE=FREETIME&SRC=PAYMENT`,
        state: {
          retentionData: {
            extentionDays: choosenRetentionOptions?.offerValue?.extension,
            agreementNumber: choosenAgreementNumber,
            agreementId: choosenAgreementID,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "RR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=RR`,
        state: {
          retentionData: {
            retentionData: offerResponse,
          },
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "TR"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=TR`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "SW"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=SW`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "R"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=R`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage == "Agreement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "S"
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${choosenAgreementID}`,
        search: `?source=AMRETENTION&type=S`,
        state: {
          retentionData: offerResponse,
        },
      });
    } else if (
      choosenRetentionOptions?.offerValue?.redirectionPage ==
      "StoreManagement" &&
      choosenRetentionOptions?.offerValue?.functionalityInPage == "MOVE"
    ) {
      history.push({
        pathname: `/storemgmt/lookupstore`,
      });
    }
    setOpenActivityLoading(false);
    // setReturnItem(true);
  };

  const renderRetentionModal = () => {
    return (
      <>
        <Grid container className={classes.mt2_1}>
          <Typography
            variant="h6"
            className={`${classes.title} ${classes.mb1}`}
          >
            Please select why the customer wishes to return to see Customer
            Retention Offer
          </Typography>
          <Grid container spacing={2}>
            {availableRetentionOffers?.map((el: any, index: any) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={3}
                  className={`${classes.mb2}`}
                  key={index}
                >
                  <Card
                    className={
                      el == choosenOfferReason
                        ? `${classes.cardTile} ${classes.payScheduleHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                        : `${classes.cardTile} ${classes.payScheduleNotHighlight} ${classes.cursorPointer} ${classes.cardTileStyle}`
                    }
                    onClick={() => {
                      setChoosenOfferReason(el);
                      setChoosenRetentionOptions(undefined);
                      setChoosenIndex(undefined);
                    }}
                  >
                    <CardContent>
                      <Grid container className={classes.justifyLabel}>
                        <Typography
                          className={
                            el == choosenOfferReason
                              ? classes.paySchedulePeriodHighlight
                              : classes.paySchedulePeriodNotHighlight
                          }
                          variant="body1"
                        >
                          {el}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {choosenOfferReason ? (
            <Grid container className={`${classes.mb2}`}>
              <Card
                className={`${classes.card} ${classes.w100} ${classes.alertwidget} ${classes.mb1p}`}
              >
                <Grid
                  item
                  className={`${classes.w100} ${classes.px0} ${classes.floatLeft}`}
                >
                  <Grid container className={`${classes.px2} ${classes.h65}`}>
                    <Grid
                      className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                    >
                      <Grid
                        item
                        className={`${classes.raccollg6}  ${classes.floatLeft}`}
                      >
                        <Typography
                          component="span"
                          className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                        >
                          Retention Option
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className={`${classes.px2}`}>
                    {retentionOptions?.map((el: any, index: any) => {
                      return (
                        <Grid
                          className={`${classes.w100} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                          key={index}
                        >
                          <RACCheckBox
                            size="medium"
                            key={index}
                            onChange={() => {
                              if (choosenOptionIndex != index) {
                                setChoosenIndex(index);
                                setChoosenRetentionOptions(el);
                              } else {
                                setChoosenIndex(undefined);
                                setChoosenRetentionOptions(undefined);
                              }
                              //onCheckBoxClicked(index, el);
                            }}
                            checked={choosenOptionIndex == index ? true : false}
                            label={el.displayLabel}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={`${classes.w100} ${classes.px20} ${classes.floatRight} `}
                >
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="contained"
                    disabled={choosenRetentionOptions ? false : true}
                    onClick={() => {
                      if (
                        choosenRetentionOptions?.displayLabel ==
                        "Explain lifetime reinstatement to the customer"
                      ) {
                        setOpenRetention(false);
                        setOpenReinstateAlert(true);
                      } else {
                        recordRedirection();
                        setOpenRetention(false);
                      }
                    }}
                  >
                    Record
                  </RACButton>
                  <RACButton
                    className={`${classes.floatRight} ${classes.ms2}`}
                    color="primary"
                    variant="outlined"
                    onClick={() => closeRetentionPopup()}
                  >
                    Cancel
                  </RACButton>
                </Grid>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };
  const activityLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />

          <Typography

            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}

          >

            Saving Information

          </Typography>

          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>

            Please wait...

          </Typography>

        </Grid>

      </>

    );

  };
  const offerLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2_1} `}
          >
            Fetching Retention Offers
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2_1}`}>
            Please wait...
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <>
      {/* {masterLoader ? (
                <Grid className={agrClasses.masterLoader}>
                    <Grid className={agrClasses.Loader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : null} */}
      {masterLoader ? (
        <Grid
          style={{
            position: "fixed",
            background: "#f7f5f5",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: "center",
            margin: "0px 0px",
          }}
        >
          <Grid
            style={{
              display: "block",
              position: "fixed",
              zIndex: 9999999,
              top: "40%",
              right: "50%",
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={returnConfirm}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setReturnConfirm(false);
          ReturnStable();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={ReturnConfirmContent()}
      />
      <RACModalCard
        isOpen={openReinstateAlert}
        maxWidth="sm"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={buildReinstateAlert()}

      />
      <RACModalCard
        isOpen={openActivityLoading}
        maxWidth="xs"
        borderRadius={'25px !important'}
        //eslint-disable-next-line react/no-children-prop
        children={activityLoadingRenderer()}
      />
      <RACModalCard
        isOpen={pastDuemaxValidPopup}
        // isOpen={false}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setpastDuemaxValidPopup(false);
          setPastdueValue(amountDue.pastDueRent);
          setReturnItem(true);
        }}
        // eslint-disable-next-line react/no-children-prop
        children={pastDueValidContent()}
      />
      {SOTwoFactoreEnabled ? (
        <SecondFactor
          setTwoFactorCompleted={SOTwoFactorCompleted}
          setTwoFactorCancelClick={(e) => {
            if (e) {
              setSOTwoFactoreEnabled(false);
              setReturnItem(true);
            }
          }}
          closeSLAClicked={() => {
            setSOTwoFactoreEnabled(false);
          }}
          currentRole={currentRole}
          //   moduleName={SLAPastDue}
          moduleName={"rate reduction"}
        //   throwCurrentPage="SwitchoutScenario"
        />
      ) : null}
      {SOTwoFactoreEnabledPay ? (
        <SecondFactor
          setTwoFactorCompleted={SOTwoFactorCompletedPay}
          setTwoFactorCancelClick={(e) => {
            if (e) {
              setSOTwoFactoreEnabledPay(false);
            }
          }}
          closeSLAClicked={() => {
            setSOTwoFactoreEnabledPay(false);
          }}
          currentRole={currentRole}
          //   moduleName={SLAPastDue}
          moduleName={"Manual Sign In RentalAgmt"}
        //   throwCurrentPage="SwitchoutScenario"
        />
      ) : null}

      <RACModalCard
        isOpen={returnItem}
        borderRadius="15px"
        title="Return Item"
        maxWidth="sm"
        closeIcon={true}
        className={classes.returnItemWidth}
        onClose={() => {
          setReturnItem(false);
          ReturnStable();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={ReturnItemContent()}
      />
      <RACModalCard
        isOpen={customerRetention}
        borderRadius="15px"
        title="Customer Retention"
        maxWidth="sm"
        closeIcon={true}
        onClose={() => setCustomerRetention(false)}
        // eslint-disable-next-line react/no-children-prop
        children={CustomerRetentionContent()}
      />
      <RACModalCard
        isOpen={saleLeadNotCreate}
        borderRadius="15px"
        maxWidth="xs"
        // closeIcon={true}
        onClose={() => setsaleLeadNotCreate(false)}
        // eslint-disable-next-line react/no-children-prop
        children={saleLeadNotCreateContent()}
      />
      <RACModalCard
        isOpen={transferSuspense}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setTransferSuspense(false);
          ReturnStable();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={TransferSuspenseContent()}
      />
      <RACModalCard
        isOpen={CustomerPresent}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setCustomerPresent(false);
          ReturnStable();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={CustomerPresentContent()}
      />
      <RACModalCard
        isOpen={printReceipt}
        borderRadius="15px"
        className={classes.returnFollowWidth}
        title="Print Pickup Receipt"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setPrintReceipt(false);
          setSignatureOption(true);
        }}
        // eslint-disable-next-line react/no-children-prop
        children={PrintReceiptContent()}
      />
      <RACModalCard
        isOpen={signatureOption}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setSignatureOption(false);
          ReturnStable();
        }}
        title="Select Signature Option"
        // eslint-disable-next-line react/no-children-prop
        children={SignatureOptionContent()}
      />
      <RACModalCard
        isOpen={newLeadCreate}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setnewLeadCreate(false);
          ReturnStable();
        }}
        // eslint-disable-next-line react/no-children-prop
        children={CreateNewLeadContent()}
      />
      <RACModalCard
        isOpen={returnFollow}
        borderRadius="15px"
        className={classes.returnFollowWidth}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setreturnFollow(false);
          ReturnStable();
        }}
        title="Return Follow Up"
        // eslint-disable-next-line react/no-children-prop
        children={ReturnFollowUpContent()}
      />
      <RACModalCard
        isOpen={agrReturned}
        borderRadius="15px"
        maxWidth="xs"
        className={classes.returnFollowWidth}
        // closeIcon={true}
        onClose={() => setAgrReturned(false)}
        // eslint-disable-next-line react/no-children-prop
        children={agrReturnedContent()}
      />

      <RACModalCard
        isOpen={openOfferLoading}
        maxWidth="xs"
        borderRadius={"25px !important"}
        //eslint-disable-next-line react/no-children-prop
        children={offerLoadingRenderer()}
      />

      <RACModalCard
        isOpen={openRetention}
        maxWidth="xl"
        title="Customer Retention"
        borderRadius={"25px !important"}
        closeIcon={true}
        onClose={() => closeRetentionPopup()}
        /* eslint-disable react/no-children-prop */
        children={renderRetentionModal()}
      />

      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          currentRole={currentRole}
          moduleName="CustomerRetention"
        />
      ) : null}

      <div className={classes.hide}>
        {inventoryInfoList.map((value: any, index: any) => {
          return (
            <div
              key={index}
              id={`printRentReadyInlineHtml-${value.inventoryHeader.itemNumber}`}
            >
              <div style={{ float: "left", width: "96%", padding: "2%" }}>
                <div style={{ float: "left", width: "100%" }}>
                  <h5
                    style={{
                      float: "left",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }}
                  >
                    Ready Rent Form
                  </h5>
                </div>
                <div style={{ float: "left", width: "100%" }}>
                  <h1 style={{ float: "left", fontSize: "50px" }}>
                    {validateObject(value?.inventoryInformation) &&
                      validateObject(value?.inventoryInformation.currentRate) ? (
                      <NumberFormat
                        thousandsGroupStyle="thousand"
                        value={roundOff(
                          value?.inventoryInformation.currentRate,
                          3
                        ).toFixed(2)}
                        prefix="$ "
                        className={classes.formLabelValue}
                        displayType="text"
                        type="text"
                        thousandSeparator={true}
                      />
                    ) : (
                      ""
                    )}
                  </h1>
                  <label
                    style={{
                      float: "left",
                      marginTop: "65px",
                      marginLeft: "20px",
                    }}
                  >
                    a Week
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "20px" }}
                >
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "#fff",
                            textAlign: "left",
                            backgroundColor: "#000",
                            padding: "7px",
                            fontSize: "14px",
                          }}
                        >
                          Single Item Rental includes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: "20px", fontSize: "14px" }}>
                          {validateObject(value?.inventoryHeader.description)
                            ? value?.inventoryHeader.description
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "20px" }}
                >
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #000",
                            textAlign: "left",
                            backgroundColor: "#fff",
                            width: "60%",
                            padding: "5px",
                            fontSize: "14px",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            border: "1px solid #000",
                            textAlign: "left",
                            backgroundColor: "#fff",
                            width: "40%",
                            padding: "5px",
                            fontSize: "14px",
                          }}
                        >
                          Condition
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "14px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Serial :{" "}
                          {validateObject(
                            value?.inventoryInformation.serialNumber
                          )
                            ? value?.inventoryInformation.serialNumber
                            : ""}
                        </td>
                        <td style={{ padding: "5px", fontSize: "14px" }}>
                          Based on DOR-system push
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "14px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Item :{" "}
                          {validateObject(value?.inventoryHeader.itemNumber)
                            ? value?.inventoryHeader.itemNumber
                            : ""}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "14px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Model :{" "}
                          {validateObject(
                            value?.inventoryInformation.modelNumber
                          )
                            ? value?.inventoryInformation.modelNumber
                            : ""}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "14px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Barcode
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    borderBottom: "2px solid #000",
                    paddingBottom: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "20px",
                    }}
                  >
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                        border: "1px solid #000",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              fontSize: "14px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Weekly Rental Rate
                          </td>
                          <td style={{ padding: "5px", fontSize: "14px" }}>
                            {validateObject(
                              value?.inventoryInformation.currentRate
                            ) ? (
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={roundOff(
                                  value?.inventoryInformation.currentRate,
                                  3
                                ).toFixed(2)}
                                prefix="$ "
                                className={classes.formLabelValue}
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              fontSize: "14px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            Monthly Rental Rate
                          </td>
                          <td style={{ padding: "5px", fontSize: "14px" }}>
                            {validateObject(
                              value?.inventoryInformation.currentRate
                            ) ? (
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={roundOff(
                                  value?.inventoryInformation.currentRate *
                                  4.3333,
                                  3
                                ).toFixed(2)}
                                prefix="$ "
                                className={classes.formLabelValue}
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "20px" }}
                >
                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}>
                      {" "}
                      Repair nicks or scratches
                    </label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Refurbish</label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}>
                      {" "}
                      Inspect for missing parts (e.g. remotes, cables, hoses,
                      etc.){" "}
                    </label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}>
                      {" "}
                      Clean and inspect
                    </label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}>
                      {" "}
                      System restore (e.g. laptops, tablets, Xbox, etc.)
                    </label>
                  </div>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "30px" }}
                >
                  <label
                    style={{
                      width: "100%",
                      float: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Select a proposed condition:
                  </label>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Like New</label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Good</label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Fair </label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Poor</label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}>
                      {" "}
                      Requires service
                    </label>
                  </div>

                  <div style={{ width: "100%", float: "left" }}>
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        width: "45px",
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    ></span>
                    <label style={{ marginBottom: "10px" }}> Cash sale</label>
                  </div>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "20px" }}
                >
                  <div style={{ width: "30%", float: "left" }}>
                    <label style={{ float: "left" }}>Signature :</label>
                    <span
                      style={{
                        borderBottom: "2px solid #000",
                        width: "50%",
                        float: "left",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    ></span>
                  </div>

                  <div style={{ width: "30%", float: "left" }}>
                    <label style={{ float: "left" }}>Date :</label>
                    <span
                      style={{
                        borderBottom: "2px solid #000",
                        width: "50%",
                        float: "left",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div id="printRentReadyInlineHtml">
                    <div style={{ float: 'left', width: '96%', padding: '2%' }}>
                        <div style={{ float: 'left', width: '100%' }}>
                            <h5 style={{ float: 'left', marginBottom: '0px', marginTop: '0px' }}>Ready Rent Form</h5>
                        </div>
                        <div style={{ float: 'left', width: '100%' }}>
                            <h1 style={{ float: 'left', fontSize: '50px' }}>
                                {validateObject(inventoryInfo?.inventoryInformation) && validateObject(inventoryInfo?.inventoryInformation.currentRate) ?
                                    <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={roundOff(inventoryInfo?.inventoryInformation.currentRate, 3).toFixed(2)}
                                        prefix="$ "
                                        className={classes.formLabelValue}
                                        displayType="text"
                                        type="text"
                                        thousandSeparator={true}
                                    />
                                    : ''}
                            </h1>
                            <label style={{ float: 'left', marginTop: '65px', marginLeft: '20px' }}>a Week</label>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                                <thead>
                                    <tr>
                                        <th style={{ color: '#fff', textAlign: 'left', backgroundColor: '#000', padding: '7px', fontSize: '14px' }}>Single Item Rental includes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '20px', fontSize: '14px' }}>{validateObject(inventoryInfo?.inventoryHeader.description) ? inventoryInfo?.inventoryHeader.description : '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                                <thead >
                                    <tr>
                                        <th style={{ border: '1px solid #000', textAlign: 'left', backgroundColor: '#fff', width: '60%', padding: '5px', fontSize: '14px' }}>Description</th>
                                        <th style={{ border: '1px solid #000', textAlign: 'left', backgroundColor: '#fff', width: '40%', padding: '5px', fontSize: '14px' }}>Condition</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Serial : {validateObject(inventoryInfo?.inventoryInformation.serialNumber) ? inventoryInfo?.inventoryInformation.serialNumber : ''}</td>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>Based on DOR-system push</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Item : {validateObject(inventoryInfo?.inventoryHeader.itemNumber) ? inventoryInfo?.inventoryHeader.itemNumber : ''}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Model : {validateObject(inventoryInfo?.inventoryInformation.modelNumber) ? inventoryInfo?.inventoryInformation.modelNumber : ''}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Barcode</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '100%', float: 'left', borderBottom: '2px solid #000', paddingBottom: '10px', marginBottom: '20px' }}>
                            <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }}>
                                <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #000' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Weekly Rental Rate</td>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>
                                                {validateObject(inventoryInfo?.inventoryInformation.currentRate) ?
                                                    <NumberFormat
                                                        thousandsGroupStyle="thousand"
                                                        value={roundOff(inventoryInfo?.inventoryInformation.currentRate, 3).toFixed(2)}
                                                        prefix="$ "
                                                        className={classes.formLabelValue}
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                    />
                                                    : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '5px', fontSize: '14px', borderRight: '1px solid #000' }}>Monthly Rental Rate</td>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>{validateObject(inventoryInfo?.inventoryInformation.currentRate) ?
                                                    <NumberFormat
                                                        thousandsGroupStyle="thousand"
                                                        value={roundOff((inventoryInfo?.inventoryInformation.currentRate * 4.3333), 3).toFixed(2)}
                                                        prefix="$ "
                                                        className={classes.formLabelValue}
                                                        displayType="text"
                                                        type="text"
                                                        thousandSeparator={true}
                                                    />
                                                    : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Repair nicks or scratches</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Refurbish</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Inspect for missing parts (e.g. remotes, cables, hoses, etc.) </label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Clean and inspect</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> System restore (e.g. laptops, tablets, Xbox, etc.)</label>
                            </div>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '30px' }}>
                            <label style={{ width: '100%', float: 'left', marginBottom: '10px' }}>Select a proposed condition:</label>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Like New</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Good</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Fair </label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Poor</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Requires service</label>
                            </div>

                            <div style={{ width: '100%', float: 'left' }}>
                                <span style={{ borderBottom: '1px solid #000', width: '45px', float: 'left', marginTop: '20px', marginBottom: '20px' }}></span>
                                <label style={{ marginBottom: '10px' }}> Cash sale</label>
                            </div>
                        </div>
                        <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                            <div style={{ width: '30%', float: 'left' }}>
                                <label style={{ float: 'left' }}>Signature :</label>
                                <span style={{ borderBottom: '2px solid #000', width: '50%', float: 'left', marginTop: '20px', marginLeft: '10px' }}></span>
                            </div>

                            <div style={{ width: '30%', float: 'left' }}>
                                <label style={{ float: 'left' }}>Date :</label>
                                <span style={{ borderBottom: '2px solid #000', width: '50%', float: 'left', marginTop: '20px', marginLeft: '10px' }}></span>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
      <div className={classes.hide}>
        {/* pickup receipt innerhtml starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
          }}
          id="pickupreciptPrintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  {/* /> */}
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>

                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  // borderBottom: borderrr,
                  borderBottom: "2px solid blue",
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "100px",
                  }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindChecklist()}
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: PaddingConst,
                              borderRadius: borderRadiuss,
                              border: Constinter,
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}

                      {/* {bindChecklist()} */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Employee Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  width: "13.33%",
                  marginLeft: "2%",
                  marginRight: "2%",
                  float: "left",
                  height: "100px",
                  marginBottom: "10px",
                }}
              >
                {/* <img
                    style={{ width: '100%', height: '100%' }}
                    src={logo}
                    alt="logo" */}
                {/* /> */}
                <Racfont style={{ width: "700%", height: "75%" }}></Racfont>
              </div>
              <div>{/* 5th part ends */}</div>
            </div>
          </div>
          {/* pickup receipt Inner html ends here */}
        </div>
        {/* pickup receipt inner htnl ends here */}
        {/* pickup checklist inner html starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
          }}
          id="pickupchecklistprintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Checklist
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storename + '/' + storenumber} */}
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* {storecity + ',' + storestate + ',' + storezip} */}
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Due Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {nextpaymentinfo.nextduedate !== undefined &&
                              nextpaymentinfo.nextduedate !== null
                              ? nextpaymentinfo.nextduedate
                              : ""}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    PICKUP CHECKLIST
                  </h5>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindChecklist()}

                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: '0px 8px 0px 8px',
                              borderRadius: '3px',
                              border: '1px solid  #545454',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/* fourth part starts here */}
              <table>
                <thead />
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          marginBottom: "250px",
                          marginTop: "10px",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "14px",
                            textDecoration: "underline",
                            float: "left",
                          }}
                        >
                          Pickup Information
                        </h5>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* fourth part ends here */}

              {/*fifth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "100%", float: "left" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textAlign: "center",
                      textDecorationLine: "underline",
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "10px",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {" "}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: "22%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: "17%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: "8%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      float: "left",
                      marginTop: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ width: "40%", float: "left" }}>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: "60%", float: "right", marginTop: "20px" }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*-fifth part ends*/}
              {/*sixth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: "100%", float: "left", marginTop: "25px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*sixth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* pickup checklist inner html ends here */}
      </div>
      {/* <div style={{ display: print ? "block" : 'none' }}> */}
      <div style={{ display: "none" }}>
        {/* pickup receipt innerhtml starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
          }}
          id="agreementReturnPrintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div
              style={{
                width: "100%",
                float: "left",
                border: "1px solid black",
              }}
            >
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "33.33%", float: "left" }}>
                  <h5
                    style={{
                      textTransform: "uppercase",
                      fontSize: "21px",
                      color: "blue",
                      textAlign: "center",
                    }}
                  >
                    Return Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "33.33%",
                    // marginLeft: '2%',
                    // marginRight: '2%',
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                    marginTop: "6px",
                  }}
                >
                  {/* /> */}
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <div
                  style={{ width: "33.33%", float: "left", marginTop: "30px" }}
                >
                  <div
                    style={{
                      marginBottom: "0.2rem",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    <>
                      <span style={{ color: "blue" }}>Return Date:</span>
                      <span
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          fontSize: "13px",
                        }}
                      >
                        {DeliveryDate}
                      </span>
                    </>
                  </div>
                  <div
                    style={{
                      marginBottom: "0.2rem",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ color: "blue" }}>Return Time:</span>
                    <span
                      style={{
                        marginTop: "5px",
                        color: "#656565",
                        borderBottom: borderbotttom,
                        fontSize: "13px",
                      }}
                    >
                      {DeliveryTime}
                    </span>
                  </div>
                </div>
                {/* <table
                                    style={{ width: '33.33%', float: 'left', marginTop: '30px' }}
                                >
                                    <thead />
                                    <tbody style={{ width: '100%', float: 'left' }}>
                                      
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: 'blue',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Return Date:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {DeliveryDate}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td
                                                style={{
                                                    marginBottom: '0.2rem',
                                                    color: 'blue',
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Return Time:
                                            </td>
                                        </tr>
                                        <tr
                                            style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                        >
                                            <td>
                                                <span
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        borderBottom: borderbotttom,
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {DeliveryTime}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
              </div>

              {/*first part ends here*/}

              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  // borderBottom: borderrr,
                  borderBottom: "2px solid blue",
                  borderTop: "2px solid blue",
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div
                    style={{
                      width: "30%",
                      float: "left",
                      margin: "10px",
                      paddingLeft: "8px",
                    }}
                  >
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <div
                      style={{
                        border: "1px solid black",
                        backgroundColor: "lightgrey",
                        paddingLeft: "8px",
                      }}
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {storename}
                      </p>
                      <>
                        {StoreAddressline2 != undefined &&
                          StoreAddressline2 != null &&
                          StoreAddressline2 != "" ? (
                          <p
                            style={{
                              textTransform: "uppercase",
                              fontSize: "13px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {StoreAddressline1 + " , " + StoreAddressline2}
                          </p>
                        ) : (
                          <p
                            style={{
                              textTransform: "uppercase",
                              fontSize: "13px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {StoreAddressline1}
                          </p>
                        )}
                      </>

                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {storecity + "," + storezip}
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {normalizeinput(Storephonenum)}{" "}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "30%",
                      float: "left",
                      margin: "10px",
                      paddingLeft: "8px",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "13px",
                        marginBottom: "10px",
                        paddingLeft: "8px",
                      }}
                    >
                      Customer
                    </h5>
                    <div
                      style={{
                        border: "1px solid black",
                        backgroundColor: "lightgrey",
                        paddingLeft: "8px",
                      }}
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerName}{" "}
                      </p>
                      {CustomerAddressline2 !== undefined &&
                        CustomerAddressline2 !== null &&
                        CustomerAddressline2 !== "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {CustomerAddressline1 + "," + CustomerAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {CustomerAddressline1}
                        </p>
                      )}
                      {customerplus4 != undefined &&
                        customerplus4 != null &&
                        customerplus4 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {customercity +
                            "," +
                            customerzip +
                            "-" +
                            customerplus4}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {customercity + "," + customerzip}
                        </p>
                      )}
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {normalizeinput(CustomerPhoneNumber)}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      float: "left",
                      marginTop: "52px",
                      // paddingLeft:'8px'
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}

              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  // borderBottom: borderrr,
                  borderBottom: "2px solid blue",
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "100px",
                  }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <u>Description of Property</u>
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <u>Serial #</u>
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          <u>Item #</u>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindChecklist()}
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: PaddingConst,
                              borderRadius: borderRadiuss,
                              border: Constinter,
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}

                      {/* {bindChecklist()} */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Employee Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  width: "13.33%",
                  marginLeft: "2%",
                  marginRight: "2%",
                  float: "left",
                  height: "100px",
                  marginBottom: "10px",
                }}
              >
                {/* <img
                    style={{ width: '100%', height: '100%' }}
                    src={logo}
                    alt="logo" */}
                {/* /> */}
                <Racfont style={{ width: "700%", height: "75%" }}></Racfont>
              </div>
              <div>{/* 5th part ends */}</div>
            </div>
          </div>
          {/* pickup receipt Inner html ends here */}
        </div>
        {/* pickup receipt inner htnl ends here */}
      </div>
    </>
  );
}
