/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect, useContext } from "react";
import {
  RACTable,
  RACTableCell,
  RACTableRow,
  RadioColor,
} from "@rentacenter/racstrap";
import { fieldDeviceStyles } from "../../../JSstyles/fieldDeviceStyles";
import { useHistory, useLocation } from "react-router-dom";
import { agreementInfoStyles } from "../../../JSstyles/agreementInfoStyles";
import { SwitchOutAgreementContext } from "../../../context/SwitchOutAgreementContext";
import { AgreementContext } from "../../../context/AgreementContext";
import { FeatureFlagContext } from "../../../context/FeatureFlagContext";
// import { AgreementContext } from '../../../context/AgreementContext';
const GridPage = (props: any) => {
  const fieldDeviceName = fieldDeviceStyles();
  const columnsWOColfieldDevice: any = [
    "agreementRate",
    "agreementTerm",
    "agreementTotal",
    "agreementInventoryDepreciation",
    "currentTotalRv",
    "optServices",
    "policy",
    "salesPerson",
  ];
  const columnsWOheadfieldDevice: any = ['Agr Rate','Agr Term','Agr Total','RV','Inv Depr %','Opt Services','Policy','Sales Person'];
  const [firstGrid, setfirstGrid] = useState(true);
  const [thirdGrid, setthirdGrid] = useState(true);
  const [secondGrid, setsecondGrid] = useState(true);
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const source = new URLSearchParams(search).get("source");
  const history = useHistory();
  const agrGridClassName = agreementInfoStyles();
  const classes = props.throwStyles;
  const { setselectedInvNumber, entireAgrInfoResponse } = useContext(
    SwitchOutAgreementContext
  );
  const { viewAgreementData, isSOEnabledbyconfig } =
    useContext(AgreementContext);
  // const { viewAgreementData } = useContext(AgreementContext);
  const [switchInvNumber, setInventoryNumber]: any = useState();
  const [controlRadioState, setcontrolRadioState]: any = useState(false);
  const [tablevalue, settablevalue]: any = useState([]);
  const [customeStyle, setcustomeStyle]: any = useState("");
  const [dynamicDisableClass, setdynamicDisableClass]: any = useState("");
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  // let dynamicDisableClass = '';
  function apiItemCall() {
    settablevalue(props.GridValues);
    console.log("props.GridValues in grid method", props.GridValues);
    // console.log(
    //   'props.GridValues in grid method deep drill',
    //   props.GridValues[0].inventoryNumber
    // );
    console.log("viewAgreementData in grid file", viewAgreementData);

    // props.GridValues.map((obj: any, index: any) => {
    //   if (
    //     obj.isItemSwitchedIn == '1' &&
    //     viewAgreementData.agreementWorkflow.isSwitchoutEnabled == '1'
    //   ) {
    //     setInventoryNumber(props.GridValues[index].inventoryNumber);
    //     setselectedInvNumber(props.GridValues[index].inventoryNumber);
    //     props.receiveSelectedInv(props.GridValues[index].inventoryNumber);
    //     setdynamicDisableClass(agrGridClassName.disableme);
    //   } else {
    //     setInventoryNumber(props.GridValues[0].inventoryNumber);
    //     setselectedInvNumber(props.GridValues[0].inventoryNumber);
    //     props.receiveSelectedInv(props.GridValues[0].inventoryNumber);
    //     setdynamicDisableClass('');
    //   }
    // });
    // setInventoryNumber(props.GridValues[0].inventoryNumber);
    // setselectedInvNumber(props.GridValues[0].inventoryNumber);
    // props.receiveSelectedInv(props.GridValues[0].inventoryNumber);
  }
  function apiAgrTotalCall() {
    settablevalue(props.SecondGridValues);
  }
  function apiAgrInfoCall() {
    settablevalue(props.ThirdGridValues);
  }
  const truncString = (str: any, max: number, add: any) => {
    add = add || "...";

    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  useEffect(() => {
    console.log("viewAgreementData in grid file", viewAgreementData);
    viewAgreementData.agreementInventories.map((obj: any, index: any) => {
      if (
        viewAgreementData.agreementWorkflow.isSwitchoutEnabled == "1" &&
        obj.isItemSwitchedIn == "1"
      ) {
        setInventoryNumber(
          viewAgreementData.agreementInventories[index].inventoryNumber
        );
        setselectedInvNumber(
          viewAgreementData.agreementInventories[index].inventoryNumber
        );
        props.receiveSelectedInv(
          viewAgreementData.agreementInventories[index].inventoryNumber
        );
        //setdynamicDisableClass(agrGridClassName.disableme);
        setcontrolRadioState(true);
        console.log("agrGridClassName.disableme", agrGridClassName.disableme);
        console.log("reached switch in log");
      } else if (
        viewAgreementData.agreementWorkflow.isSwitchoutEnabled == "0"
      ) {
        setInventoryNumber(
          viewAgreementData.agreementInventories[0].inventoryNumber
        );
        setselectedInvNumber(
          viewAgreementData.agreementInventories[0].inventoryNumber
        );
        props.receiveSelectedInv(
          viewAgreementData.agreementInventories[0].inventoryNumber
        );
        //setdynamicDisableClass('');
        setcontrolRadioState(false);
        console.log("reached switch in log else");
      }
    });
  }, [viewAgreementData]);

  useEffect(() => {
    settablevalue([]);

    // eslint-disable-next-line no-console
    console.log("In grid inventory values", props.GridValues);
    if (props.mapper[props.mapper.length - 1].gridInfo === "ItemInfo") {
      apiItemCall();
    } else if (props.mapper[props.mapper.length - 1].gridInfo === "AgrTotal") {
      apiAgrTotalCall();
    } else if (props.mapper[props.mapper.length - 1].gridInfo === "AgrInfo") {
      apiAgrInfoCall();
    }
    if (props.mapper[props.mapper.length - 1].gridInfo === "AgrTotal") {
      //console.log('Custome Class Receive',props.mapper[7].customiseTable);
      setcustomeStyle(props.mapper[7].customiseTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.mapper,
    props.GridValues,
    props.SecondGridValues,
    props.ThirdGridValues,
  ]);

  useEffect(() => {
    console.log(
      "throwentireAgrInfoResponseToGrid in grid component",
      props.throwentireAgrInfoResponseToGrid
    );
  }, [props.throwentireAgrInfoResponseToGrid]);

  // useEffect(() => {
  //   console.log('viewAgreementData in grid component', viewAgreementData);
  // }, [viewAgreementData]);

  // ${classes1.itemInfoTbl}
  const fieldDeviceStyleDecider=(type:any)=>{
    switch (type) {
      case 'InvInfo':
        return fieldDeviceName.itemInfoTbl
      case 'AgrTotal':
        return fieldDeviceName.agrTotalTbl
      case 'AgrInfo':
        return fieldDeviceName.agrInfoTbl
    }
  }

  return (
    <div >
      <RACTable
        className={
          customeStyle == ""
            ? `${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid} ${fieldDeviceStyleDecider(props?.UsingField)}`
            : `${agrGridClassName.racAgrTotalGrid} ${agrGridClassName.racGrid} ${agrGridClassName.racAgrViewGrid} ${fieldDeviceStyleDecider(props?.UsingField)}`
        }
        renderTableHead={headerBuild}
        renderTableContent={bodyBuild}
      />
    </div>
  );

  function headerBuild() {
    return props.mapper.map((obj: any) => {
      if (obj.displayname !== undefined) {
        if (
          obj.displayname === "Tag Rate" ||
          // obj.displayname === 'Tag Term' ||
          obj.displayname === "Tag Total" ||
          obj.displayname === "Agr Rate" ||
          // obj.displayname === 'Agr Term' ||
          obj.displayname === "Agr Total" ||
          obj.displayname === "Inv Depr %" ||
          obj.displayname === "Delivery Charge"
        ) {
          return (
            <RACTableCell className={classes.textRight}>
              {obj.displayname}
            </RACTableCell>
          );
        } else if (
          obj.displayname === "Tag Term" ||
          obj.displayname === "Agr Term" ||
          obj.displayname === "RV"
        ) {
          return (
            <RACTableCell className={classes.textCenter}>
              {obj.displayname}
            </RACTableCell>
          );
        } else if (
          obj.displayname === "RadioButton" &&
          featureFlagDetails?.Switchout !== "0"
        ) {
          return <RACTableCell></RACTableCell>;
        } else if (
          obj.displayname === "RadioButton" &&
          featureFlagDetails?.Switchout == "0"
        ) {
          return null;
        } else {
          return <RACTableCell className={''}>{obj.displayname}</RACTableCell>;
        }
      }
    });
  }
  function bodyBuild() {
    if (tablevalue !== undefined) {
      return tablevalue.map((tabval: any, index: any) => {
        return (
          <RACTableRow key={index} backgroundColor="white">
            {subBuild(tabval)}
          </RACTableRow>
        );
      });
    }
    function invNumberClick(invNumber: any) {
      let inventoryUrl = `/inventory/${invNumber}/`;
      if (source == "AMRETENTION" && (type == "S" || type == "SW")) {
        inventoryUrl = inventoryUrl + "service";
        history.push({
          pathname: inventoryUrl,
          search: "?retention=1",
        });
      } else {
        inventoryUrl = inventoryUrl + "inventoryinformation";
        history.push({
          pathname: inventoryUrl,
        });
      }
    }
    function subBuild(tabval: any) {
      return props.mapper.map((obj: any, index: any) => {
        for (const key in tabval) {
          if (
            obj.key == "radioRequire" &&
            featureFlagDetails?.Switchout !== "0"
          ) {
            console.log("tabval[obj.key]", tabval["inventoryNumber"]);

            return (
              <RACTableCell key={index} className={dynamicDisableClass}>
                <RadioColor
                  size="small"
                  disabled={controlRadioState}
                  value={tabval["inventoryNumber"]}
                  onClick={() => {
                    setInventoryNumber(tabval["inventoryNumber"]);
                    setselectedInvNumber(tabval["inventoryNumber"]);
                    props.receiveSelectedInv(tabval["inventoryNumber"]);
                    props.reveiveDepartment(tabval["department"]);
                  }}
                  checked={
                    switchInvNumber == tabval["inventoryNumber"] ? true : false
                  }
                  IscolorVariant={true}
                  variantColor={"blue"}
                  backgroundColor={"white"}
                  name="invNumber"
                  className={agrGridClassName.cool}
                  // backgroundColor={text("backgroundColor", "green")}
                />
              </RACTableCell>
            );
          }
          if (key === obj.key) {
            if (
              key === "tagRate" ||
              key === "tagTotal" ||
              key === "agreementRate" ||
              key === "agreementTotal" ||
              key === "deliveryCharge" ||
              key === "currentTotalRv"
            ) {
              //const x = Number(tabval[obj.key]);
              const currencyValue = tabval[obj.key].replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              //const x = parseInt(tabval[obj.key]);
              return (
                <RACTableCell key={index} className={classes.textRight}>
                  $ {currencyValue}
                </RACTableCell>
              );
            } else if (key === "tagTerm" || key === "agreementTerm") {
              return (
                <RACTableCell
                  key={index}
                  className={
                     classes.textCenter
                  }
                >
                  {tabval[obj.key]}
                </RACTableCell>
              );
            } else if (
              key === "agreementInventoryDepreciation" ||
              key === "inventoryDepreciation"
            ) {
              return (
                <RACTableCell
                  key={index}
                  className={
                     classes.textRight
                  }
                >
                  {tabval[obj.key]} %
                </RACTableCell>
              );
            } else if (key === "tagTerm" || key == "agreementTerm") {
              return (
                <RACTableCell
                  key={index}
                  className={
                     ""
                  }
                >
                  {tabval[obj.key]}
                </RACTableCell>
              );
            } else if (key === "dueDate") {
              return <RACTableCell key={index}>{tabval[obj.key]}</RACTableCell>;
            } else if (key === "promoName") {
              return (
                <RACTableCell
                  key={index}
                  className={
                     ""
                  }
                >
                  {tabval[obj.key] !== null ? tabval[obj.key] : "N/A"}
                </RACTableCell>
              );
            } else if (key === "inventoryNumber") {
              return (
                <RACTableCell
                  key={index}
                  className={
                     ""
                  }
                >
                  {viewAgreementData?.agreementWorkflow?.isCOAgreement == 1 && viewAgreementData?.agreementWorkflow?.isPurchaseOrderReceived == 0  ?
                  <a
                    className={classes.racpadLink}
                    //onClick={() => invNumberClick(tabval[obj.key])}
                  >
                    {tabval[obj.key]}
                  </a> : 

                  <a
                    className={classes.racpadLink}
                    onClick={() => invNumberClick(tabval[obj.key])}
                  >
                    {tabval[obj.key]}
                  </a>}
                </RACTableCell>
              );
            } else if (key === "policy") {
              return (
                <RACTableCell
                  key={index}
                  
                >
                  {tabval[obj.key]}
                </RACTableCell>
              );
            } else {
              if (tabval[obj.key] === null) {
                return (
                  <RACTableCell
                    key={index}
                    title={tabval[obj.key]}               
                  >
                    -
                  </RACTableCell>
                );
              } else {
                return (
                  <RACTableCell
                    key={index}
                    title={tabval[obj.key]}                    
                  >
                    {truncString(tabval[obj.key], 30, "...")}
                  </RACTableCell>
                );
              }
            }
          }
        }
      });
    }
  }
};
export default GridPage;