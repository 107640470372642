/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    Card,
    Typography,
    CardContent,
    RACTable,
    CircularProgress,
    RACTableCell,
    RACTableRow,
    RACModalCard,
    RACTextbox,
    RACButton

} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Plus } from '../../../../assets/images/plus.svg';
import { ReactComponent as Minus } from '../../../../assets/images/minus.svg';
import { ReactComponent as NoRecordsFound } from '../../../../assets/images/No-records.svg';

import { fixedGridStyles } from '../../../../JSstyles/fixedGridStyles';
// import attachment from '../../../assets/images/attachment.png';
import Attachments from '../../../../assets/images/attachment.png'
import { ReactComponent as ItemAddition } from '../../../../assets/images/ItemAddition.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomerOrderAgreementContext } from '../../../../context/CustomerOrderAgreementContext';
export default function SearchResultGrid() {
    const fixedGridClassName = fixedGridStyles();
    const { toggleTB, modelNumberArray, setModelNumberArray, addedItems, setAddedItems, itemSearchTableContent, setItemSearchTableContent, yearsArray, setYearsArray,
        searchResultLoder, setsearchResultLoder,
        makeArray, setMakeArray,
        styleArray, setStyleArray,
        modelArray, setModelArray,
        sizeArray, setSizeArray,
        year, setYear,
        make, setMake,
        model, setModel,
        style, setStyle,
        searchResultNoRecords,
        setsearchResult,
        size, setSize } = useContext(CustomerOrderAgreementContext);
    const [scrollLoader, setScrollLoader] = useState(false);
    const [isNoRec, setisNoRec] = useState(false);
    const classes = agreementGlobalStyles();
    const [gridRecordsArray, setGridRecordsArray] = useState([]);
    const [isImagePopupOpened, setIsImagePopupOpened] = useState(false);
    const [isQuantityPopupOpened, setIsQuantityPopupOpened] = useState(false);
    const [itemSearchTableHeader, setItemSearchTableHeader] = useState([
        { heading: 'Vendor' },
        { heading: 'Brand' },
        { heading: 'Department' },
        { heading: 'Sub-Department' },
        { heading: 'Model#' },
        { heading: 'Monthly Rate' },
        { heading: 'Monthly Term' },
        { heading: 'Total Cost' },
        { heading: 'Cash Price' },
        { heading: 'Images' },
        { heading: '' }
    ]);
    const [imageSource, setImageSource] = useState('');
    const [quantityValue, setQuantityValue] = useState('0');

    useEffect(() => {
        console.log("modelNumberArray", modelNumberArray);

    }, [modelNumberArray])

    let TotalRec;
    function renderGridHeader() {
        return <>
            {
                itemSearchTableHeader.map((obj, index) => {
                    return <RACTableCell key={index}>{obj.heading}</RACTableCell>
                })
            }
        </>
    }
    function closeImage() {
        try {
            setIsImagePopupOpened(false)
        } catch (error: any) {
            console.log(error.message);
        }
    }
    function showImage(modelNumber: any) {
        try {
            setIsImagePopupOpened(true)
            const filteredImageSource = modelNumberArray.filter((value: any) => {
                return value.modelNumber == modelNumber
            })
            console.log(filteredImageSource, "this is the filtered image source")
            setImageSource(filteredImageSource[0].imageSource[0]);
        } catch (error: any) {
            console.log(error.message)
        }
    }
    function availableQunity(modelNumber?: any) {
        const filteredImageSource = modelNumberArray.filter((value: any) => {
            return value.modelNumber == modelNumber
        })
        return filteredImageSource[0]?.minimumQuantity ? filteredImageSource[0]?.minimumQuantity : '-'
    }
    function checkIsModelAlreadyAdded(obj) {
        debugger;
        try {
            const addedItemSpread = [...addedItems];

            // console.log(obj, "this is the particular column object");
            // console.log(addedItemSpread)
            // console.log(addedItemSpread.some((value: any) => {
            //     return value.modelNumber == obj.modelNumber
            // }), "This is the some of the addedItemSpread");
            // if (addedItemSpread.some((value: any) => {
            //     return value.modelNumber == obj.modelNumber
            // })) {
            //     let filteredModelNumberArray = addedItems.filter((value1: any) => {
            //         return value1.modelNumber == obj.modelNumber
            //     })
            //     setQuantityValue(filteredModelNumberArray[0].quantity)
            //     setIsQuantityPopupOpened(true);
            // } else {
            // }

            const gridItemSpread = [...itemSearchTableContent];
            let newAddedRemoved = itemSearchTableContent.filter((value: any) => {
                return value.modelNumber !== obj.modelNumber
            });
            console.log(newAddedRemoved, "this is the removed item");
            setItemSearchTableContent(newAddedRemoved)

            let objectNew: any = obj;
            objectNew.quantity = 1;
            objectNew.viewMore = false;
            addedItemSpread.push(obj);
            setAddedItems(addedItemSpread);
            setsearchResult(addedItemSpread)


        } catch (error: any) {
            console.log(error.message);
            return;
        }
    }

    {
        console.log(itemSearchTableContent, "itemSearchTableContent");
    }
    const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
        return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
    };

    function renderGridContent() {
        return <>
            {itemSearchTableContent?.length ?
                itemSearchTableContent.map((obj, index) => {
                    return <RACTableRow key={index} style={{ background: 'white' }}>
                        <RACTableCell key={index}>
                            {"Tire Buyer"}
                        </RACTableCell>
                        <RACTableCell key={index}>
                            {obj.brand ? hideLengthyText(obj.brand, 10, '...') : '-'}
                        </RACTableCell>
                        <RACTableCell key={index}>
                            {obj.department ? hideLengthyText(obj.department, 10, '...') : '-'}
                        </RACTableCell>
                        <RACTableCell key={index}>
                            {obj.subdepartment ? hideLengthyText(obj.subdepartment, 10, '...') : '-'}
                        </RACTableCell>
                        <RACTableCell key={index}>
                            {obj.modelNumber ? hideLengthyText(obj.modelNumber, 15, '...') : '-'}
                        </RACTableCell>
                        <RACTableCell key={index} style={{ textAlign: 'center' }}>
                            {obj.monthlyRate ? `$${obj.monthlyRate}` : '-'}
                        </RACTableCell>
                        <RACTableCell key={index} style={{ textAlign: 'center' }}>
                            {obj.monthlyTerm ? obj.monthlyTerm : '-'}
                        </RACTableCell>
                        <RACTableCell key={index} style={{ textAlign: 'center' }}>
                            {obj.totalCost ? `$${obj.totalCost}` : '-'}

                        </RACTableCell>
                        <RACTableCell key={index} style={{ textAlign: 'center' }}>
                            {obj.cashPrice ? `$${obj.cashPrice}` : '-'}
                        </RACTableCell>
                        {/* <RACTableCell key={index} style={{ textAlign: 'center' }}>  
                            {availableQunity(obj.modelNumber)}
                        </RACTableCell> */}
                        <RACTableCell key={index} onClick={() => {
                            showImage(obj.modelNumber);
                        }} style={{ cursor: 'pointer' }}>
                            <img src={Attachments} alt={"attachment image"} />
                        </RACTableCell>
                        <RACTableCell key={index}>
                            <ItemAddition
                                className={classes.cursorPointer}
                                id={obj.modelNumber}
                                onClick={(e) => {
                                    checkIsModelAlreadyAdded(obj)
                                }}
                                data-testid={index + 'plusId'}
                            />
                        </RACTableCell>
                    </RACTableRow>
                })
                : <RACTableRow style={{ background: 'white' }}>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>

                    <RACTableCell>
                        {searchResultLoder ?
                            <>
                                <Grid style={{ "marginTop": "50px" }}>
                                    <CircularProgress />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid className={`${classes.textCenter} ${classes.mt5}`}>
                                    <NoRecordsFound />
                                    <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                                        No records found
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>
                    <RACTableCell></RACTableCell>


                </RACTableRow>}
        </>
    }
    function fetchNextSetData() {
    }
    function imageModel() {
        return (
            <Grid item>
                <img src={imageSource} style={{
                    "width": "100%",
                    "height": "250px"
                }} />
                <Grid container >
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Make :</b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{make ? make : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span> <b>Year : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{year ? year : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Style : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{style ? style : '-'}</span>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Model :</b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{model ? model : '-'}</span>
                    </Grid>
                    <Grid item md={4} style={{ padding: '10px' }}>
                        <span><b>Size : </b></span>
                        <span style={{ fontFamily: 'Opensans-semibold' }}>{size ? size : '-'}</span>
                    </Grid>
                    <Grid item md={4}>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    function quantityModel() {
        return (
            <Grid container>
                <Grid item md={12}>
                    <Typography>Please select the number of tires</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={3}><Plus /></Grid>
                        <Grid item md={6}><RACTextbox value={
                            quantityValue
                        } /></Grid>
                        <Grid item md={3}><Minus /></Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <RACButton type="button" variant="outlined" color="secondary">Cancel</RACButton>
                        <RACButton type="button" variant="contained" color="primary">Save</RACButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return <>
        {/*image popup starts here*/}
        <RACModalCard
            isOpen={isImagePopupOpened}
            closeIcon={true}
            maxWidth="sm"
            title='Product Image'
            borderRadius={"15px"}
            onClose={() => {
                closeImage();
            }}
        >
            {imageModel()}
        </RACModalCard>
        {/**image popup ends here */}
        {/**Quantity popup starts here */}
        <RACModalCard
            isOpen={isQuantityPopupOpened}
            closeIcon={true}
            maxWidth="xs"
            borderRadius={"15px"}
            title='Select Quantity'
            onClose={() => {
                setIsQuantityPopupOpened(false)
            }}
        >
            {quantityModel()}
        </RACModalCard>
        {/**Quantity model ends here */}
        {/*Something wenterong pop  */}
        <Card className={classes.card}>
            <CardContent>
                {gridRecordsArray.length > 10 ?
                    <InfiniteScroll
                        hasMore={isNoRec}
                        dataLength={gridRecordsArray.length}
                        loader={scrollLoader ? (
                            <Grid className={classes.GridLoader}>
                                <CircularProgress />
                            </Grid>
                        ) : null}
                        next={fetchNextSetData}
                    ><Grid className={fixedGridClassName.fixTableHead}>
                            <RACTable
                                renderTableHead={renderGridHeader}
                                renderTableContent={renderGridContent}
                            /></Grid></InfiniteScroll>
                    : <>
                        <Grid className={fixedGridClassName.fixTableHead}>
                            <RACTable
                                renderTableHead={renderGridHeader}
                                renderTableContent={renderGridContent}
                            />
                        </Grid>
                    </>
                }
            </CardContent>
        </Card>
    </>
}