/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { searchResultStyles } from '../../../JSstyles/searchResultStyles';
import { AgreementContext } from '../../../context/AgreementContext';
import ExceptionController from './exceptionController';
import ContextSkeleton from './contextSkeleton';
import { useLocation } from 'react-router-dom';
import ModalContainer from './modalContainer';
import FooterContent from './footerInventory';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import AddedItem from './addedItem';
import { agreementInfoStyles } from '../../../JSstyles/agreementInfoStyles';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { getAgreementOptions } from '../../../api/user';
import SecondFactor from '../../Rental/TwoFactorPopup/TwoFactorPopup';

import {
  getSalesInfo,
  getInventorySearch,
  getPricingClientCall,
  GetEmployeeID,
  GetRole,
  // getCompetitors,
} from '../../../api/user';
// import ModalBuilder from "./modalBuilder";

import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  RACModalCard,
  RACButton,
  Divider,
} from '@rentacenter/racstrap';
// import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
export const Usercontext = createContext({});

export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}

interface Params {
  customerId: string;
}

const SearchResult = (props: any) => {
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const search = useLocation().search;
  const classes = agreementGlobalStyles();
  const agrInfoClassName = agreementInfoStyles();
  const agrComponent = searchResultStyles();
  const { customerId } = useParams<Params>();
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');

  const {
    TestInput,
    addedItem,
    setTestInput,
    searchResult,
    setaddedItem,
    itemArrayData,
    copyArrayData,
    setsearchResult,
    setcopyArrayData,
    setitemArrayData,
    setinitialAgrData,
    setsearchCriteria,
    // setpackageListData,
    agreementCreateStatus,
    searchCriteria,
    setisCompCheck,
    containCompetitor,
    setselectedCompetitor,
    setagreementOptions,
    agreementOptions,
    setproofFileName,
    setisSalesLeadFailed,
    offSetValue,
    setOffSetValue,
    setstoreSelectedPackage,
    storeSelectedPackageValue,
    isAllDone,
    storeSelectedPackagePriceTag,
    setStoreSelectedPackagePriceTag,
    setbrokenPackageData,
    coworkerCheck,
    customerIdCheck,
    isChoosePriceConfig,
    setPromoAgrItemPrice,
  } = useContext(AgreementContext);
  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  };
  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  };

  const modalStates = {
    breakPopup: false,
    addAllPopUp: false,
    itemPricingFail: false,
    breakPackageCtrl: false,
    RateTermController: false,
    PackageExceptionPopup: false,
    packageExceptionController: false,
    reservedExceptionController: false,
  };
  const ModuleName = 'Choosing Price Tag'; //Need to update this
  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  };
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };
  const [selectedPackagePriceTag, setselectedPackagePriceTag] = useState(null);
  // const [SLAMinRole, setSLAMinRole] = useState('');
  const [packageInvPopulator, setpackageInvPopulator] = useState([]);
  const [PackageleftValue, setPackageleftValue]: any = useState({});
  const [PackagerightValue, setPackagerightValue]: any = useState({});
  const [packageBlueLabel, setpackageBlueLabel] = useState('');
  const [SLAController, setSLAController] = useState(SLAObj);
  const [CurrentRole, setCurrentRole] = useState('');
  const deleteIdObj = { invDeleteId: '' };
  const [SecondFactorEnabled, setSecondFactorEnabled] = useState(false);
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [deleteId, setdeleteId] = React.useState(deleteIdObj);
  const [modalController, setmodalController] = useState(modalStates);
  const [priceController, setpriceController] = useState<any>();
  const [resultController, setresultController] = useState(resultControllerObj);
  const [tagBlueLabel, settagBlueLabel]: any = useState({});
  const [leftValue, setleftValue]: any = useState({});
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [openPackagePriceTag, setopenPackagePriceTag] = useState(false);
  const [newSingleInvPricingInvArray, setnewSingleInvPricingInvArray]: any =
    useState([]);
  const [rightValue, setrightValue]: any = useState({});
  const [gridData, setGridData] = useState<any>([]);
  const [isPackage, setIsPackage] = useState(false);
  const [itemArray, setItemArray] = useState<any>([]);
  const [salesLeadData, setSalesLeadData]: any = useState();
  const [bucketCopy, setBucketCopy] = useState<any>([]);
  const [collectIndex, setcollectIndex] = useState<any>();
  const [formException, setformException] = useState<any>();
  const [PackageItemId, setPackageItemId] = useState<any>();
  const [packageDeptid, setpackageDeptid] = useState<any>();
  const [showAddedItem, setShowAddedItem] = useState(false);
  const [storeSalesData, setstoreSalesData] = useState<any>();
  const [packageSubdepid, setpackageSubdepid] = useState<any>();
  const [receivedpackage, setreceivedpackage] = useState<any>();
  const [packageCriteria, setpackageCriteria] = useState<any>();
  const [controlViewMode, setControlViewMode] = useState(false);
  const [packageBracketid, setpackageBracketid] = useState<any>();
  const [pricingException, setpricingException] = useState<any>();
  const [footerControlData, setfooterControlData] = useState(true);
  const [PackagePricingResponse, setPackagePricingResponse] = useState<any>();
  const [internalContextData, setInternalContextProvider] = useState<any>();
  const [getSalesInfoFail, setgetSalesInfoFail] = useState(false);
  const [tireController, settireController] = useState<any>(false);
  const [selectedPackage, setselectedPackage] = useState('');
  const [competitorDDValue, setcompetitorDDValue]: any = useState();
  const [packageList, setpackageListData] = useState();
  const [isPackageFlow, setisPackageFlow] = useState(false);
  const [PricexeptionMessage, setPricexeptionMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const [hasMore, sethasMore] = useState(true);
  const [scrollLoader, setscrollLoader] = useState(false);
  const [payloadSetNumber, setpayloadSetNumber] = useState<any>(1);
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme';
  const altersearch = showAddedItem
    ? agrComponent.collapsedWigid
    : agrComponent.expandWigid;

  const showItemSearch = isAllDone
    ? agrComponent.showItemSearch
    : agrComponent.hideItemSearch;
  useEffect(() => {
    console.log(
      'vcxbvbjnbvb 545451 newSingleInvPricingInvArray',
      newSingleInvPricingInvArray
    );
    console.log(
      'sdvfddcvd 541495151 loaderBundle.masterLoader',
      loaderBundle.masterLoader
    );
  }, [newSingleInvPricingInvArray, itemArray, loaderBundle.masterLoader]);

  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log('GetcurrentUser', GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log('GetMenuDetails', GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log('currentRole', currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    if (containerData == undefined) {
      console.log('containerData', containerData);
      //console.log('containerDataRole', containerData?.GetRole());
      //console.log('containerDataRole', containerData?.GetRole());
      setCurrentRole('RD');
    } else {
      GettingRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("before getSearchCriteriaContext", getSearchCriteriaContext);
    console.log('before getsearchResultContext', searchResult);
    console.log('before getaddedItemContext', addedItem);
    console.log('before getItemArray', itemArrayData);
    console.log('before getCopyArray', copyArrayData);
    console.log('Status of the agreement is', agreementCreateStatus);

    if (
      // getSearchCriteriaContext[0] !== undefined &&
      addedItem !== undefined &&
      searchResult !== undefined &&
      itemArrayData !== undefined &&
      copyArrayData !== undefined &&
      agreementCreateStatus !== undefined
    ) {
      console.log('entered');
      if (agreementCreateStatus.agreementCreatedStatus === true) {
        console.log('entered view mode');
        console.log('entered view mode with getaddedItemContext[0]', addedItem);
        setShowAddedItem(true);
        setControlViewMode(true);
        setfooterControlData(false);
        setInternalContextProvider(addedItem);
        setPromoAgrItemPrice(addedItem);
      } else {
        console.log('entered edit mode');
        setselectedPackagePriceTag(storeSelectedPackagePriceTag);
        setpayloadSetNumber(offSetValue);
        setShowAddedItem(true);
        setGridData(searchResult);
        setItemArray(itemArrayData);
        setBucketCopy(copyArrayData);
        setInternalContextProvider(addedItem);
        setPromoAgrItemPrice(addedItem);
        // setloaderController(false);
        // setGridloader(false);
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: false,
          loaderController: false,
        });
        setselectedPackage(storeSelectedPackageValue);
        setfooterControlData(false);
      }
    }

    if (
      searchCriteria !== undefined &&
      searchCriteria.packageTypeval !== undefined &&
      searchCriteria.packageTypeval !== ''
    ) {
      setreceivedpackage(searchCriteria.packageTypeval);
    }
    console.log('containCompetitor to get check', containCompetitor);

    async function GetDropdownApiCall() {
      const dropdownval: any = await getAgreementOptions(
        customerId,
        storeNumber
      );
      console.log('New res', dropdownval);
      if (dropdownval.status === 200) {
        setagreementOptions(dropdownval.data);
      }
    }
    if (agreementOptions == undefined) {
      GetDropdownApiCall();
    }
    // End of Competitor DD Call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Search Result Props', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };

  const suppiler = () => {
    console.log('response in suppiler');
    console.log('fdgshjhsh');
    props.somedeeptrigger();
  };
  useEffect(() => {
    if (salesLeadId !== null) {
      const salesValue = async () => {
        const salesData = await getSalesInfo(salesLeadId);
        if (salesData.status == 200) {
          setstoreSalesData(salesData.data);
          if (salesData.data.inventories[0].reservedStoreNumber === null) {
            if (salesData.data.inventories.length > 1) {
              const req: any = {
                itemNo: '',
                serialNo: '',
                bracket: '',
                brand: '',
                model: '',
                statusId: 1,
                conditionId: null,
                departments: '',
                subDepartments: '',
                packageTypeval: salesData.data.bundleId,
                locationNumber: '',
              };
              setselectedPackage(salesData.data.bundleId);
              setstoreSelectedPackage(salesData.data.bundleId);
              searchClick(req, 'searchButtonClick');
            } else {
              const req: any = {
                itemNo: '',
                serialNo: '',
                bracket: '',
                brand: '',
                model: salesData.data.inventories[0].modelNumber,
                statusId: 1,
                conditionId: null,
                departments: '',
                subDepartments: '',
                packageTypeval: '',
                locationNumber: '',
              };
              searchClick(req, 'searchButtonClick');
            }
          } else {
            if (salesData.data.inventories.length > 1) {
              let isAllReserved = true;
              const arrayStorage: any = [];
              salesData.data.inventories.map((obj: any) => {
                if (obj.reserved === true) {
                  arrayStorage.push(obj.inventoryNumber);
                }
              });

              for (let i = 0; i < salesData.data.inventories.length; i++) {
                if (salesData.data.inventories[i].reserved === false) {
                  isAllReserved = false;
                  break;
                }
              }
              console.log(
                'final data of isAllReserved statuscb',
                isAllReserved
              );
              console.log('gbdv6584dfa65b45fd', arrayStorage);

              if (isAllReserved) {
                const currentStore = sessionStorage.getItem('storeNumber');
                const req: any = {
                  storeNumbers: [currentStore],
                  itemNumber: arrayStorage,
                  serialNumber: '',
                  bracket: '',
                  brand: '',
                  modelNumber: '',
                  statusId: 1,
                  subStatusId: [2, 6],
                  conditionId: null,
                  department: '',
                  subDepartment: '',
                  packageName: '',
                  isActivePriceTag: true,
                };
                try {
                  const response: any = await getInventorySearch(req);
                  const arrayCollecter: any = [];
                  if (
                    response.status == 200 &&
                    response.data.response.length === 1
                  ) {
                    salesLeadSingleAdder(
                      {
                        inventoryId: Number(
                          response.data.response[0].inventoryId
                        ),
                        inventoryPriceId: Number(
                          response.data.response[0].inventoryPriceId
                        ),
                      },
                      response.data.response[0]
                    );
                  } else if (
                    response.status == 200 &&
                    response.data.response.length > 1
                  ) {
                    response.data.response.map((filteredDataObj: any) => {
                      arrayCollecter.push({
                        inventoryId: Number(filteredDataObj.inventoryId),
                        inventoryPriceId: Number(
                          filteredDataObj.inventoryPriceId
                        ),
                      });
                    });
                    //setBucketCopy([...bucketCopy, response.data.response]); //need to add this
                    //setcopyArrayData([...bucketCopy, response.data.response]); //need to add this
                    salesLeadPackageAdder(
                      arrayCollecter,
                      salesData.data.bundleId,
                      response.data.response
                    ); // Need to change here
                  } else if (
                    response.status == 200 &&
                    response.data.response.length == 0
                  ) {
                    const req: any = {
                      itemNo: '',
                      serialNo: '',
                      bracket: '',
                      brand: '',
                      model: '',
                      statusId: 1,
                      conditionId: null,
                      departments: '',
                      subDepartments: '',
                      packageTypeval: salesData.data.bundleId,
                      locationNumber: '',
                    };
                    searchClick(req, 'searchButtonClick');
                  }
                  console.log('bgdvdsv654f1vdf', arrayCollecter);
                } catch {
                  setresultController({
                    ...resultControllerObj,
                    internalServerController: true,
                  });
                  setLoaderBundle({ ...loaderBundle, loaderController: false });
                }
              } else if (!isAllReserved) {
                console.log('package observdvs564', salesData.data.bundleId);

                const req: any = {
                  itemNo: '',
                  serialNo: '',
                  bracket: '',
                  brand: '',
                  model: '',
                  statusId: 1,
                  conditionId: null,
                  departments: '',
                  subDepartments: '',
                  packageTypeval: salesData.data.bundleId,
                  locationNumber: '',
                };
                setselectedPackage(salesData.data.bundleId);
                setstoreSelectedPackage(salesData.data.bundleId);
                searchClick(req, 'searchButtonClick');
              }
            } else {
              const currentStore = sessionStorage.getItem('storeNumber');
              const req: any = {
                storeNumbers: [currentStore],
                itemNumber: [salesData.data.inventories[0].inventoryNumber],
                serialNumber: '',
                bracket: '',
                brand: '',
                modelNumber: '',
                statusId: 1,
                subStatusId: [2, 6],
                conditionId: null,
                department: '',
                subDepartment: '',
                packageName: '',
                isActivePriceTag: true,
              };
              try {
                const response: any = await getInventorySearch(req);
                console.log('fgvbdsv5fd4', response);
                if (
                  response.status == 200 &&
                  response.data.response.length > 0
                ) {
                  //setBucketCopy([...bucketCopy, response.data.response[0]]); //need to add this
                  //setcopyArrayData([...bucketCopy, response.data.response[0]]); //need to add this
                  console.log(
                    'gbdfvsd vds',
                    response.data.response[0].inventoryId
                  );
                  salesLeadSingleAdder(
                    {
                      inventoryId: Number(
                        response.data.response[0].inventoryId
                      ),
                      inventoryPriceId: Number(
                        response.data.response[0].inventoryPriceId
                      ),
                    },
                    response.data.response[0]
                  );
                } else {
                  setresultController({
                    ...resultControllerObj,
                    controlRecord: true,
                  });
                  setLoaderBundle({ ...loaderBundle, loaderController: false });
                  setSalesLeadData({
                    itemNo: salesData.data?.inventories[0].inventoryNumber
                      ? salesData.data?.inventories[0].inventoryNumber
                      : '',
                    packageTypeval: '',
                  });
                }
              } catch {
                setresultController({
                  ...resultControllerObj,
                  internalServerController: true,
                });
                setLoaderBundle({ ...loaderBundle, loaderController: false });
              }
            }
          }
        } else {
          setgetSalesInfoFail(true);
          setisSalesLeadFailed(true);
        }
      };
      salesValue();
    }
    console.log('Received Query Param Data', salesLeadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesLeadId]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    console.log('vaue of id in useeffect', deleteId.invDeleteId);
    if (deleteId.invDeleteId !== '') {
      if (isPackage) {
        // setbreakPackageCtrl(true);
        setmodalController({ ...modalController, breakPackageCtrl: true });

        console.log('Came Inside Is Package');
      } else if (!isPackage) {
        console.log('Came Inside Not Package');
        deleteItem();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]); // this method is used to manipulate the data item based in the delete in the addedadd item menu

  const salesLeadSingleAdder = async (itemid: any, itemObject: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    // const constructedItemArray = [];
    console.log('6r8sgd1v5f1', itemid);

    const response: any = await pricingCall(
      [itemid],
      null,
      false,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    setaddedItem(response.data);
    let newItemArray: any = [];
    if (response.status == 200) {
      const constructedItemArray = [];

      let tireController: any = false;
      response.data.itemPricing.map((obj: any) => {
        if (
          obj.rmsItemNumber == '200012120' ||
          obj.rmsItemNumber == '200012121' ||
          obj.rmsItemNumber == '200013177' ||
          obj.rmsItemNumber == '200012118' ||
          obj.rmsItemNumber == '200013350' ||
          obj.rmsItemNumber == '200012119'
        ) {
          tireController = true;
          settireController(true);
        }
      });
      newItemArray = [itemid]; // need to store inv id now its number
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      ContextSkeleton(
        response.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );
      setIsPackage(false);
      console.log('newItemArray in add item', newItemArray);

      setItemArray(newItemArray);
      console.log('reached setitemArrayData');

      setitemArrayData(newItemArray);

      setfooterControlData(false);
      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      setBucketCopy([itemObject]);
      setcopyArrayData([itemObject]);
      setShowAddedItem(true);
    } else if (response.status == 400) {
      setpricingException(response.data.errors[0].error);
      setmodalController({ ...modalController, RateTermController: true });
    }
  };

  const salesLeadPackageAdder = async (
    itemid: any,
    packageValue: any,
    itemObj: any
  ) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    console.log('bd65v4fd65vfb6fd', itemid);

    const response: any = await pricingCall(
      itemid,
      null,
      false,
      null,
      null,
      false,
      packageValue,
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    setaddedItem(response.data);
    // let newItemArray: any = [];
    if (response.status == 200) {
      // const constructedItemArray = [];
      setselectedPackage(packageValue);
      let tireController: any = false;
      response.data.itemPricing.map((obj: any) => {
        if (
          obj.rmsItemNumber == '200012120' ||
          obj.rmsItemNumber == '200012121' ||
          obj.rmsItemNumber == '200013177' ||
          obj.rmsItemNumber == '200012118' ||
          obj.rmsItemNumber == '200013350' ||
          obj.rmsItemNumber == '200012119'
        ) {
          // settireController(true);
          tireController = true;
          settireController(true);
        }
      });
      const newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log('individual inv id of package', value.inventoryId);
          newItemArray.push({
            inventoryId: Number(value.inventoryId),
            inventoryPriceId: Number(value.inventoryId),
          });
          //newItemArray = [...newItemArray, parseInt(value.inventoryId)];
        });

        if (
          response.data.packageInformation.packageTypeList !== null &&
          Object.keys(response.data.packageInformation.packageTypeList)
            .length !== 0 &&
          response.data.packageInformation.packageTypeList !== ''
        ) {
          if (packageValue == '') {
            setpackageCriteria(
              response.data.packageInformation.packageTypeList[0]
                .packageCriteria
            );
          } else {
            const filteredCriteria =
              response.data.packageInformation.packageTypeList.filter(
                (item, index) => {
                  if (packageValue == item.packageName) {
                    return item;
                  }
                }
              );
            setpackageCriteria(filteredCriteria[0].packageCriteria);
          }
          setreceivedpackage(response.data.itemPricing[0].packageName);
          setsearchCriteria({
            ...searchCriteria,
            packageTypeval: response.data.itemPricing[0].packageName,
          });
        } else {
          setpackageCriteria(null);
        }
      }
      // newItemArray = [...constructedItemArray, parseInt(itemid)];
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      ContextSkeleton(
        response.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );
      setfooterControlData(false);
      setIsPackage(true);
      setItemArray(newItemArray);
      setitemArrayData(newItemArray);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      setBucketCopy(itemObj);
      setcopyArrayData(itemObj);
      setShowAddedItem(true);
      setaddedItem(response.data);
    } else if (response.status == 400) {
      setpricingException(response.data.errors[0].error);
      setmodalController({ ...modalController, RateTermController: true });
    }
  };

  const minMaxException = (index: any) => {
    console.log('index log of fault package', index);

    if (packageCriteria !== undefined) {
      const wordingArray: any = [
        'Items in the Agreement do not meet the Package Criteria. Please check the Item in the Agreement to Continue.',
      ];
      index.forEach((obj: any) => {
        const wordFormation = `${obj.rmsDepartment} Minimum: ${obj.minQuantity} Maximum: ${obj.maxQuantity}`;
        wordingArray.push(wordFormation);
      });
      console.log('wordingArray finally', wordingArray);

      if (wordingArray !== undefined && wordingArray.length > 0) {
        let str: any = '';
        wordingArray.map((el: any) => {
          str = str + el;
        });
        console.log('str formed value', str);

        setformException(str);
      }
      setmodalController({ ...modalController, PackageExceptionPopup: true });
    }
  };

  const pricingCall = async (
    inventoriesValue: any,
    validateInventoryIdValue: any,
    packageRequiredValue: any,
    agreementRateValue: any,
    agreementTermValue: any,
    isCompetitorMatchValue: any,
    packageNameValue: any,
    packagePriceType: any
  ) => {
    const req: any = {
      storeNumber: storeNumber,
      inventoryInfo: inventoriesValue,
      validateInventoryId: parseInt(validateInventoryIdValue),
      packageRequired: packageRequiredValue,
      packageName: packageNameValue,
      customerId: coworkerCheck == 'Y' ? customerIdCheck : null,
      packagePriceType: packagePriceType,
      agreementRate: agreementRateValue,
      agreementTerm: agreementTermValue,
      isCompetitorMatch: isCompetitorMatchValue,
    };
    console.log('pricingCall request', req);

    return await getPricingClientCall(req);
  };

  const closeModal = (controller: any): any => {
    console.log('received controller', controller);
    if (controller == 'addAllPopUp') {
      setmodalController({ ...modalController, addAllPopUp: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'breakPackageCtrl') {
      setmodalController({ ...modalController, breakPackageCtrl: false });
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    } else if (controller == 'RateTermController') {
      setmodalController({ ...modalController, RateTermController: false });
    } else if (controller == 'breakPopup') {
      setmodalController({ ...modalController, breakPopup: false });
    }
  };

  const deleteItem = () => {
    console.log('dsdcd sdcvfdf itemArray fcv', itemArray);

    if (itemArray !== undefined && itemArray.length > 0) {
      console.log('Data Inside invDeleteId use effect', deleteId.invDeleteId);
      let UpdatedArray: any = [];
      itemArray.map((deleteObject: any, index: any) => {
        if (
          parseInt(deleteObject.inventoryId) === parseInt(deleteId.invDeleteId)
        ) {
          UpdatedArray = itemArray.filter((item, filterIndex) => {
            console.log('filter copy item', item);
            return filterIndex != index;
          });
          //UpdatedArray = itemArray.splice(index, 1);
          console.log('Newly Updated Array', UpdatedArray);
          setItemArray(UpdatedArray);
        }
      });
      if (UpdatedArray.length <= 1) {
        console.log('reached null received package');
        setselectedPackage('');
        setreceivedpackage('');
        setsearchCriteria({ ...searchCriteria, packageTypeval: '' });
      }
      // setitemArrayContext(UpdatedArray);
      setitemArrayData(UpdatedArray);

      let response: any;
      if (UpdatedArray.length > 0) {
        console.log('Inside if');
        const selectedPackageProvider =
          UpdatedArray.length > 1 ? selectedPackage : '';
        console.log('selectedPackageProvider logger', selectedPackageProvider);

        const triggerAPI = async () => {
          response = await pricingCall(
            UpdatedArray,
            null,
            false,
            null,
            null,
            false,
            selectedPackageProvider,
            null
          );
          // setmasterLoader(false);
          setLoaderBundle({ ...loaderBundle, masterLoader: false });

          console.log('Inside Use Effect of invDeleteId', response);
          console.log('Came inside result if condition');
          // setaddedItemContext(response.data);
          setaddedItem(response.data);

          if (response.data !== undefined && response.status == 200) {
            setinitialAgrData(response.data.agreementPricing.agreementRate);
            setShowAddedItem(true);
            setIsPackage(false);
            setfooterControlData(false);
            setInternalContextProvider(response.data);
            setPromoAgrItemPrice(response.data);
            if (
              response.data.packageInformation.packageTypeList !== null &&
              Object.keys(response.data.packageInformation.packageTypeList)
                .length !== 0 &&
              response.data.packageInformation.packageTypeList !== ''
            ) {
              if (selectedPackageProvider == '') {
                setpackageCriteria(
                  response.data.packageInformation.packageTypeList[0]
                    .packageCriteria
                );
              } else {
                const filteredCriteria =
                  response.data.packageInformation.packageTypeList.filter(
                    (item, index) => {
                      if (selectedPackageProvider == item.packageName) {
                        return item;
                      }
                    }
                  );
                setpackageCriteria(filteredCriteria[0].packageCriteria);
              }
              setreceivedpackage(response.data.itemPricing[0].packageName);
              setsearchCriteria({
                ...searchCriteria,
                packageTypeval: response.data.itemPricing[0].packageName,
              });
            } else {
              setpackageCriteria(null);
            }
            let tireController = false;
            const responseHolder = response.data.itemPricing;
            for (let i = 0; i < responseHolder.length; i++) {
              if (
                responseHolder[i].rmsItemNumber == '200012120' ||
                responseHolder[i].rmsItemNumber == '200012121' ||
                responseHolder[i].rmsItemNumber == '200013177' ||
                responseHolder[i].rmsItemNumber == '200012118' ||
                responseHolder[i].rmsItemNumber == '200013350' ||
                responseHolder[i].rmsItemNumber == '200012119'
              ) {
                tireController = true;
                // settireController(true);
                break;
              }
            }
            const getBreakPkgData: any = [];
            response.data.itemPricing.map((obj: any) => {
              if (
                obj.packagePriceId !== null &&
                obj.packagePriceId !== 'null'
              ) {
                getBreakPkgData.push(Number(obj.packagePriceId));
              }
            });
            const uniqueBreakData = [...new Set(getBreakPkgData)];
            setbrokenPackageData(uniqueBreakData);
            ContextSkeleton(
              response.data,
              UpdatedArray,
              setTestInput,
              customerId,
              priceController,
              competitorDDValue,
              tireController
            );
            setpackageListData(
              response.data.packageInformation.packageTypeList
            );
          } else if (response.status == 400) {
            setmodalController({
              ...modalController,
              itemPricingFail: true,
            });
            setPricexeptionMessage(response.data.errors[0].error);
          }
        };
        triggerAPI();
        setcompetitorDDValue(null);
        setisCompCheck(false);
        setselectedCompetitor(undefined);
        //childFunc.current.resetCompetitorData();
      } else {
        // setmasterLoader(false);
        setIsPackage(false);
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        setisCompCheck(false);
        console.log('Came inside deep else');
        setInternalContextProvider(undefined);
        setShowAddedItem(false);
        setfooterControlData(true);
        setselectedCompetitor(undefined);
        setproofFileName(undefined);
        setpackageCriteria(null);
        setselectedPackage('');
        setstoreSelectedPackage('');
        props.setItemSearchCompleted(false);
        setbrokenPackageData([]);
      }
    } else {
      console.log('Came inside else');
      setInternalContextProvider(undefined);
      setShowAddedItem(false);
      setfooterControlData(true);
      setbrokenPackageData([]);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
    const copyNum = deleteId.invDeleteId;

    const resultArr = bucketCopy.filter((data, index) => {
      return bucketCopy.indexOf(data) === index;
    });

    console.log('copy before map', bucketCopy);
    console.log('copyNum', copyNum);

    const refinedTableData: any = [];
    gridData.map((obj: any, index: any) => {
      if (obj.inventoryId !== copyNum) {
        refinedTableData.push(obj);
      }
    });

    resultArr.map((obj: any, index: any) => {
      console.log('copy map obj', obj);
      if (obj.inventoryId == copyNum) {
        const copyTableData = refinedTableData;
        copyTableData.push(obj);
        setGridData(copyTableData);

        const newCopy = bucketCopy.filter((item, filterIndex) => {
          console.log('filter copy item', item);
          return filterIndex != index;
        });

        console.log('copy after map', newCopy);
        setBucketCopy(newCopy);
        setcopyArrayData(newCopy);
      }
    });
  };

  const acceptBreakPackage = () => {
    deleteItem();
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const declineBreakPackage = () => {
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    setmodalController({ ...modalController, breakPackageCtrl: false });
  };

  const searchClick = async (value: any, source: any) => {
    if (source == 'searchButtonClick') {
      setpayloadSetNumber(1);
      setOffSetValue(1);
      setLoaderBundle({
        ...loaderBundle,
        Gridloader: true,
        loaderController: true,
      });
      sethasMore(true);
    } else if (source == 'searchButtonNotClick') {
      setscrollLoader(true);
    }
    console.log('thsg6rd8z4vsc5', value);

    const currentStore = sessionStorage.getItem('storeNumber');
    const req: any = {
      storeNumbers: [currentStore],
      itemNumber: value.itemNo == '' ? null : [value.itemNo],
      serialNumber: value.serialNo.trim(),
      bracket: value.bracket.trim(),
      brand: value.brand.trim(),
      modelNumber: value.model.trim(),
      statusId: value.statusId,
      subStatusId: salesLeadId == null ? [2] : [2, 6],
      conditionId:
        value.conditionId == '0' || value.conditionId == null
          ? null
          : parseInt(value.conditionId),
      department: value.departments,
      subDepartment: value.subDepartments,
      packageName: value.packageTypeval,
      setNumber: source == 'searchButtonClick' ? 0 : payloadSetNumber,
      isActivePriceTag: true,
    };
    console.log('defined payload', req);

    // setSearchCriteriaContext(req);
    //setsearchCriteria(req);
    // if(salesLeadId !== null){
    //   setSalesLeadData({...req,itemNo:value.itemNo});
    // }// removed this
    try {
      const response: any = await getInventorySearch(req);
      if (salesLeadId !== null) {
        setSalesLeadData({
          packageTypeval: value.packageTypeval,
          itemNo: value.itemNo,
          model: value.model,
        });
        setsearchCriteria({
          ...searchCriteria,
          packageTypeval: value.packageTypeval,
          itemNo: value.itemNo,
          model: value.model,
        });
        setselectedPackage(value.packageTypeval);
        setstoreSelectedPackage(value.packageTypeval);
      }
      if (response.status == 400 || response.status == 500) {
        // setinternalServerController(true);
        setresultController({
          ...resultControllerObj,
          internalServerController: true,
        });
        // setloaderController(false);
        setLoaderBundle({ ...loaderBundle, loaderController: false });
      } else if (response.status == 200 && response.data.response.length > 0) {
        // setsearchResultContext(response.data.response);
        if (source == 'searchButtonNotClick') {
          setpayloadSetNumber(payloadSetNumber + 1);
          setOffSetValue(payloadSetNumber + 1);
        }
        let furtherFilteredData: any = [];
        if (itemArray.length > 0) {
          console.log('reached if of search');
          let pushMe = false;
          response.data.response.map((filteredDataObj: any, index: any) => {
            console.log('times of trigger', index);

            for (let i = 0; i < itemArray.length; i++) {
              console.log('itemArrayData', itemArrayData);
              console.log(
                'filteredDataObj.inventoryId',
                filteredDataObj.inventoryId
              );

              if (
                itemArray[i].inventoryId !==
                parseInt(filteredDataObj.inventoryId)
              ) {
                console.log('reached once true');
                pushMe = true;
              } else {
                console.log('reached break statement');
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              console.log('times reached fbdfbdfzBf');

              furtherFilteredData.push(filteredDataObj);
            }
          });
        } else {
          console.log('reached else of sr');
          furtherFilteredData = response.data.response;
        }
        if (furtherFilteredData.length <= 0) {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });

          // setloaderController(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
        } else {
          // setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
          // setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
          // console.log('Response from api', response);

          // setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          if (source == 'searchButtonNotClick') {
            setsearchResult([...gridData, ...furtherFilteredData]);
            setGridData([...gridData, ...furtherFilteredData]);
          } else {
            setsearchResult(furtherFilteredData); //This will be storing the response of the inventory search API
            setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
            console.log('Response from api', response);

            setGridData(furtherFilteredData); //This will assign the API result to get the Search Result grid get load.
          }
          setresultController(resultControllerObj);
        }
        //setsearchResult(response.data.response);

        // setloaderController(false);
        //setLoaderBundle({ ...loaderBundle, loaderController: false });

        console.log('Response from api', response);

        const ApiResult: any = response.data.response;
        console.log('response', ApiResult);
        console.log('response-array', ApiResult[0]);

        // if (salesLeadId !== null && storeSalesData.modelNumber == null && storeSalesData.inventories[0].inventoryNumber !== null && getSalesInfoFail == false) {
        //   console.log("passed the condition");
        //   console.log("response-array", ApiResult[0]);
        //   const apiInventoryid: any = ApiResult[0];
        //   console.log("apiInventoryid", apiInventoryid);
        //   const setInventoryid: any = apiInventoryid.inventoryId;
        //   console.log("setInventoryid", setInventoryid);
        //   let SalesInventoryId: any = [];
        //   SalesInventoryId = [...itemArray, parseInt(setInventoryid)];
        //   setBucketCopy(ApiResult);
        //   addSalesLeadItem(setInventoryid);
        //   setItemArray(SalesInventoryId);
        // } // Commented as QA Requested
        // else {
        //   setGridData(ApiResult);
        // }
      } else if (response.data.response.length <= 0) {
        // setGridData([]);
        // // setcontrolRecord(true);
        // setresultController({ ...resultController, controlRecord: true });

        // // setloaderController(false);
        // setLoaderBundle({ ...loaderBundle, loaderController: false });
        // if (gridData.length == 0) {
        //   setGridData([]);
        //   setresultController({ ...resultController, controlRecord: true });
        //   setLoaderBundle({ ...loaderBundle, loaderController: false });
        // } else {
        //   sethasMore(false);
        // }

        if (source == 'searchButtonClick') {
          setGridData([]);
          setresultController({ ...resultControllerObj, controlRecord: true });
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        } else if (source == 'searchButtonNotClick') {
          sethasMore(false);
          setLoaderBundle({ ...loaderBundle, loaderController: false });
        }
      }
    } catch {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      });
      setLoaderBundle({ ...loaderBundle, loaderController: false });
    }
  };

  const gridCallMethod = () => {
    console.log('Came inside Grid Method');

    if (gridData !== undefined && gridData.length > 0) {
      console.log('Inside table bind', gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                orderBy="loanerAgreement"
                handleActionType={addItem}
                order={sortDirection.ASC}
                throwStyle={classes}
                triggerSearch={searchClick}
                reveiveHasMore={hasMore}
                reveivescrollLoader={scrollLoader}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      );
    } else if (resultController.internalServerController) {
      return (
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />
      );
    } else if (resultController.controlRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Records"
          throwStyle={classes}
        />
      );
    } else if (resultController.controlEmptyRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />
      );
    }
  };

  const addAllItem = async () => {
    // eslint-disable-next-line no-debugger
    debugger;
    // if(itemArray.length >= 1){

    //   childFunc.current.resetCompetitorData();
    // }
    // setaddAllPopUp(false);
    setmodalController({ ...modalController, addAllPopUp: false });

    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    //setItemArray([]);
    const response = await pricingCall(
      [],
      PackageItemId,
      true,
      null,
      null,
      false,
      '',
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    // eslint-disable-next-line no-debugger
    debugger;
    console.log('add all item popup', response);
    if (
      response.status == 200 &&
      response.data.packageInformation.packagePriceInfo.packagePrice.length == 1
    ) {
      // setpackageDataContext(response.data.packageInformation);
      setisCompCheck(false);
      setbrokenPackageData([]);
      setselectedCompetitor(undefined);
      setIsPackage(true);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      // setmasterLoader(false);
      //setLoaderBundle({ ...loaderBundle, masterLoader: false });

      setreceivedpackage(response.data.itemPricing[0].packageName);
      console.log(
        'response.data.itemPricing[0].packageName',
        response.data.itemPricing[0].packageName
      );
      setsearchCriteria({
        ...searchCriteria,
        packageTypeval: response.data.itemPricing[0].packageName,
      });

      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log('individual inv id of package', value.inventoryId);

          newItemArray = [
            ...newItemArray,
            {
              inventoryId: Number(value.inventoryId),
              inventoryPriceId: Number(value.inventoryPriceId),
            },
          ];
        });

        setfooterControlData(false);

        if (
          response.data.packageInformation.packageTypeList !== null &&
          Object.keys(response.data.packageInformation.packageTypeList)
            .length !== 0 &&
          response.data.packageInformation.packageTypeList !== ''
        ) {
          setpackageCriteria(
            response.data.packageInformation.packageTypeList[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);
          setsearchCriteria({
            ...searchCriteria,
            packageTypeval: response.data.itemPricing[0].packageName,
          });
        } else {
          setpackageCriteria(null);
        }
      }
      setItemArray(newItemArray);
      // setitemArrayContext(newItemArray);
      setitemArrayData(newItemArray);

      console.log('Item Array in package', newItemArray);
      let tireController = false;
      // response.data.itemPricing.map((obj: any) => {
      //   if (
      //     obj.rmsItemNumber == '200012120' ||
      //     obj.rmsItemNumber == '200012121' ||
      //     obj.rmsItemNumber == '200013177' ||
      //     obj.rmsItemNumber == '200012118' ||
      //     obj.rmsItemNumber == '200013350' ||
      //     obj.rmsItemNumber == '200012119'
      //   ) {
      //     tireController = true;
      //     settireController(true);
      //   }
      // });
      const responseHolder = response.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          settireController(true);
          break;
        }
      }
      // provideContext(response.data, newItemArray, tireController);
      ContextSkeleton(
        response.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );

      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      // setGridData([...tabledata, copy]);
      const copyTableData = gridData;

      // setGridData([...tabledata, obj]);
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      // setcopy([]);

      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);
        // setcopyArrayContext(emptyCopy);
        setcopyArrayData(emptyCopy);
      }

      console.log('the empty copy is', emptyCopy);

      console.log('before tab data values', gridData);

      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object) => !emptyCopy.includes(object));
      console.log('exptyTableData', emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {
        // setaddedItemContext(response.data);
        setaddedItem(response.data);

        setShowAddedItem(true);
        // setaddAllPopUp(false);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (
      response.status === 200 &&
      response.data.packageInformation.packagePriceInfo.packagePrice.length >
        1 &&
      isChoosePriceConfig == '1'
    ) {
      const modalInvPopupator: any = [];
      response.data.itemPricing.map((obj) => {
        modalInvPopupator.push({
          inventoryNumber: obj.inventoryNumber,
          inventoryName: obj.inventoryName,
        });
      });
      console.log('dfdvfd dcfv542 modalInvPopupator', modalInvPopupator);
      setisPackageFlow(true);
      setpackageInvPopulator(modalInvPopupator);
      setopenPackagePriceTag(true);
      setpackageBlueLabel(
        response.data.packageInformation.packagePriceInfo.packageType
      );
      if (
        response.data.packageInformation.packagePriceInfo.packagePrice[0] ===
        'ACTIVE'
      ) {
        setPackageleftValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[1]
        );
        setPackagerightValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[0]
        );
      } else {
        setPackageleftValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[0]
        );
        setPackagerightValue(
          response.data.packageInformation.packagePriceInfo.packagePrice[1]
        );
      }
    } else if (
      response.status === 200 &&
      response.data.packageInformation.packagePriceInfo.packagePrice.length >
        1 &&
      isChoosePriceConfig == '0'
    ) {
      setisCompCheck(false);
      setselectedCompetitor(undefined);
      setIsPackage(true);
      setbrokenPackageData([]);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      // setmasterLoader(false);
      //setLoaderBundle({ ...loaderBundle, masterLoader: false });

      setreceivedpackage(response.data.itemPricing[0].packageName);
      console.log(
        'response.data.itemPricing[0].packageName',
        response.data.itemPricing[0].packageName
      );
      setsearchCriteria({
        ...searchCriteria,
        packageTypeval: response.data.itemPricing[0].packageName,
      });

      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log('individual inv id of package', value.inventoryId);

          newItemArray = [
            ...newItemArray,
            {
              inventoryId: Number(value.inventoryId),
              inventoryPriceId: Number(value.inventoryPriceId),
            },
          ];
        });

        setfooterControlData(false);

        if (
          response.data.packageInformation !== null &&
          Object.keys(response.data.packageInformation).length !== 0 &&
          response.data.packageInformation !== ''
        ) {
          setpackageCriteria(
            response.data.packageInformation[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);
          setsearchCriteria({
            ...searchCriteria,
            packageTypeval: response.data.itemPricing[0].packageName,
          });
        } else {
          setpackageCriteria(null);
        }
      }
      setItemArray(newItemArray);
      // setitemArrayContext(newItemArray);
      setitemArrayData(newItemArray);

      console.log('Item Array in package', newItemArray);
      let tireController = false;
      // response.data.itemPricing.map((obj: any) => {
      //   if (
      //     obj.rmsItemNumber == '200012120' ||
      //     obj.rmsItemNumber == '200012121' ||
      //     obj.rmsItemNumber == '200013177' ||
      //     obj.rmsItemNumber == '200012118' ||
      //     obj.rmsItemNumber == '200013350' ||
      //     obj.rmsItemNumber == '200012119'
      //   ) {
      //     tireController = true;
      //     settireController(true);
      //   }
      // });
      const responseHolder = response.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          settireController(true);
          break;
        }
      }
      // provideContext(response.data, newItemArray, tireController);
      ContextSkeleton(
        response.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );

      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      // setGridData([...tabledata, copy]);
      const copyTableData = gridData;

      // setGridData([...tabledata, obj]);
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      // setcopy([]);

      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);
        // setcopyArrayContext(emptyCopy);
        setcopyArrayData(emptyCopy);
      }

      console.log('before tab data values', gridData);

      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object) => !emptyCopy.includes(object));
      console.log('exptyTableData', emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {
        // setaddedItemContext(response.data);
        setaddedItem(response.data);

        setShowAddedItem(true);
        // setaddAllPopUp(false);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      setPricexeptionMessage(response.data.errors[0].error);
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
  };
  const declineAdding = () => {
    // setChange('1');
    // setaddAllPopUp(false);
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    // setbreakPopup(false);
    if (itemArray.length > 0) {
      setShowAddedItem(true);
    } else {
      setShowAddedItem(false);
      setInternalContextProvider({});
    }
  }; //PS-12

  const addItem = async (
    itemid: any,
    index: any,
    status: any,
    invNumber: any,
    inventoryPriceId: any
  ) => {
    // setmasterLoader(true);
    const pricingCallController = await addController(invNumber, status);
    if (pricingCallController) {
      setLoaderBundle({ ...loaderBundle, masterLoader: true });

      setcollectIndex(index);
      let constructedItemArray = [];
      if (isPackage) {
        constructedItemArray = [];
      } else {
        constructedItemArray = itemArray;
      }
      let packageParameter = '';
      if (constructedItemArray.length > 0) {
        packageParameter = selectedPackage;
      }
      const response: any = await pricingCall(
        constructedItemArray,
        itemid,
        false,
        null,
        null,
        false,
        packageParameter,
        null
      );
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });

      console.log('raw response', response);
      // setaddedItemContext(response.data);
      setaddedItem(response.data);

      console.log('Respoooooonse', response.data);
      let newItemArray: any = [];
      if (
        response.data !== undefined &&
        response.status == 200 &&
        response.data.isPackage === true
      ) {
        // setShowAddedItem(false);
        // setaddAllPopUp(true);
        setmodalController({ ...modalController, addAllPopUp: true });

        setPackageItemId(parseInt(itemid));
        setPackagePricingResponse(response);
      } else if (response.status == 200 && response.data.isPackage === false) {
        // if(itemArray.length >= 1){
        //   childFunc.current.resetCompetitorData();
        // }
        const allowPricePopulate =
          isChoosePriceConfig == '1' ? await pricePopulator(response) : true;
        if (allowPricePopulate) {
          setisCompCheck(false);
          setselectedCompetitor(undefined);
          let constructedItemArray = [];
          if (isPackage) {
            constructedItemArray = [];
          } else {
            constructedItemArray = itemArray;
          }
          let tireController: any = false;
          const responseHolder = response.data.itemPricing;
          for (let i = 0; i < responseHolder.length; i++) {
            if (
              responseHolder[i].rmsItemNumber == '200012120' ||
              responseHolder[i].rmsItemNumber == '200012121' ||
              responseHolder[i].rmsItemNumber == '200013177' ||
              responseHolder[i].rmsItemNumber == '200012118' ||
              responseHolder[i].rmsItemNumber == '200013350' ||
              responseHolder[i].rmsItemNumber == '200012119'
            ) {
              tireController = true;
              settireController(true);
              break;
            }
          }
          newItemArray = [
            ...constructedItemArray,
            {
              inventoryId: Number(itemid),
              inventoryPriceId: Number(inventoryPriceId),
            },
          ];
          setinitialAgrData(response.data.agreementPricing.agreementRate);
          ContextSkeleton(
            response.data,
            newItemArray,
            setTestInput,
            customerId,
            priceController,
            competitorDDValue,
            tireController
          );
          setIsPackage(false);
          console.log('newItemArray in add item', newItemArray);

          setItemArray(newItemArray);
          console.log('reached setitemArrayData');
          setpackageListData(response.data.packageInformation.packageTypeList);
          console.log('reached setpackageData');

          console.log(
            'response.data.packageInformation',
            response.data.packageInformation
          );
          setitemArrayData(newItemArray);
          setfooterControlData(false);
          if (isPackage) {
            console.log('reached expected');
            console.log('reached expected copy', bucketCopy);
            const copyTableData = gridData;
            bucketCopy.map((obj: any) => {
              copyTableData.push(obj);
            });
            setGridData(copyTableData);
          }
          if (
            response.data.packageInformation.packageTypeList != null &&
            Object.keys(response.data.packageInformation.packageTypeList)
              .length !== 0 &&
            response.data.packageInformation.packageTypeList != ''
          ) {
            if (packageParameter == '') {
              setpackageCriteria(
                response.data.packageInformation.packageTypeList[0]
                  .packageCriteria
              );
            } else {
              const filteredCriteria =
                response.data.packageInformation.packageTypeList.filter(
                  (item, index) => {
                    if (packageParameter == item.packageName) {
                      return item;
                    }
                  }
                );
              setpackageCriteria(filteredCriteria[0].packageCriteria);
            }
            setreceivedpackage(response.data.itemPricing[0].packageName);
            setsearchCriteria({
              ...searchCriteria,
              packageTypeval: response.data.itemPricing[0].packageName,
            });
            setselectedPackage(response.data.itemPricing[0].packageName);
            setstoreSelectedPackage(response.data.itemPricing[0].packageName);
          } else if (selectedPackage !== '') {
            setselectedPackage(selectedPackage);
            setstoreSelectedPackage(selectedPackage);
          } else {
            setpackageCriteria(null);
            setselectedPackage('');
            setstoreSelectedPackage('');
          }
          setInternalContextProvider(response.data);
          setPromoAgrItemPrice(response.data);
          setBucketCopy([...bucketCopy, gridData[index]]);
          setcopyArrayData([...bucketCopy, gridData[index]]);
          console.log('copy', bucketCopy);
          console.log('tabledata.filter', gridData);
          const newTableData = gridData.filter((item, filterIndex) => {
            return filterIndex != index;
          });
          if (newTableData.length <= 0) {
            // setcontrolEmptyRecord(true);
            setresultController({
              ...resultControllerObj,
              controlEmptyRecord: true,
            });
          }
          // setsearchResultContext(newTableData);
          setsearchResult(newTableData);

          console.log('ItemMainAry', newTableData);
          setGridData(newTableData);
          setShowAddedItem(true);
        }
      } else if (response.status == 400) {
        // setpackageExceptionController(true);
        setmodalController({
          ...modalController,
          itemPricingFail: true,
        });
        if (response.data.errors[0].error == 'Invalid inventoryIds') {
          setPricexeptionMessage(
            'No active price tags found for selected inventory'
          );
        } else {
          setPricexeptionMessage(response.data.errors[0].error);
        }
      }
    } else {
      setmodalController({
        ...modalController,
        reservedExceptionController: true,
      });
    }
  };

  const addController = (invNumber: any, status: any) => {
    if (
      salesLeadId !== null &&
      status === 'Reserved' &&
      storeSalesData !== undefined
    ) {
      let canCallPricing = false;
      for (let i = 0; i < storeSalesData.inventories.length; i++) {
        console.log('bvfdsd544', storeSalesData.inventories[i]);
        console.log('bgfdfd5651521541', invNumber);

        if (storeSalesData.inventories[i].inventoryNumber === invNumber) {
          canCallPricing = true;
          break;
        }
      }
      console.log('dsvcswcsd vc54257', canCallPricing);

      return canCallPricing;
    } else if (
      salesLeadId !== null &&
      status === 'Reserved' &&
      storeSalesData === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  const pricePopulator = (response: any) => {
    let allowPopulatePrice = false;
    for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
      if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
        allowPopulatePrice = false;
        break;
      } else {
        allowPopulatePrice = true;
      }
    }

    if (allowPopulatePrice) {
      return allowPopulatePrice;
    } else {
      //const arrayCollection: any = [];
      const itemArrayCollection: any = [];
      for (let i = 0; i < response.data.inventoryPriceInfo.length; i++) {
        if (response.data.inventoryPriceInfo[i].inventoryPrice.length > 1) {
          // arrayCollection.push(
          //   response.priceTag[i].inventoryPriceTags[0],
          //   response.priceTag[i].inventoryPriceTags[1]
          // );
          settagBlueLabel({
            inventoryNumber:
              response.data.inventoryPriceInfo[i].inventoryNumber,
            inventoryDesc: response.data.inventoryPriceInfo[i].inventoryDesc,
            inventoryId: response.data.inventoryPriceInfo[i].inventoryId,
          });
          if (
            response.data.inventoryPriceInfo[i].inventoryPrice[0]
              .inventoryPriceType == 'ACTIVE'
          ) {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[0]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[1]
            );
          } else {
            setleftValue(response.data.inventoryPriceInfo[i].inventoryPrice[1]);
            setrightValue(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
            );
          }
        } else {
          itemArrayCollection.push({
            inventoryId: Number(
              response.data.inventoryPriceInfo[i].inventoryId
            ),
            inventoryPriceId: Number(
              response.data.inventoryPriceInfo[i].inventoryPrice[0]
                .inventoryPriceId
            ),
          });
        }
      }
      setOpenPriceTag(true);
      setnewSingleInvPricingInvArray(itemArrayCollection); // this should be stored in a different array
      //setstoreArrayCollection(arrayCollection);
      //dynamicPopupHandler(arrayCollection);
      //console.log('vfdcdddc12d52c', arrayCollection);
    }
  };

  const pricingCallTrigger = async (packData: any) => {
    if (itemArray.length > 1) {
      // setmasterLoader(true);
      setLoaderBundle({ ...loaderBundle, masterLoader: true });

      const getResult = await pricingCall(
        itemArray,
        null,
        false,
        internalContextData.agreementPricing.agreementRate
          ? parseFloat(internalContextData.agreementPricing.agreementRate)
          : null,
        internalContextData.agreementPricing.agreementTerm
          ? parseInt(internalContextData.agreementPricing.agreementTerm)
          : null,
        false,
        packData,
        null
      );

      setLoaderBundle({ ...loaderBundle, masterLoader: false });
      if (getResult.data !== undefined && getResult.status == 200) {
        setaddedItem(getResult.data);

        setInternalContextProvider(getResult.data);
        setPromoAgrItemPrice(getResult.data);
        setmodalController({ ...modalController, addAllPopUp: false });
        if (
          getResult.data.packageInformation.packageTypeList !== null &&
          Object.keys(getResult.data.packageInformation.packageTypeList)
            .length !== 0 &&
          getResult.data.packageInformation.packageTypeList !== ''
        ) {
          if (packData == '') {
            setpackageCriteria(
              getResult.data.packageInformation.packageTypeList[0]
                .packageCriteria
            );
          } else {
            const filteredCriteria =
              getResult.data.packageInformation.packageTypeList.filter(
                (item, index) => {
                  if (packData == item.packageName) {
                    return item;
                  }
                }
              );
            setpackageCriteria(filteredCriteria[0].packageCriteria);
          }
          setreceivedpackage(getResult.data.itemPricing[0].packageName);
          setsearchCriteria({
            ...searchCriteria,
            packageTypeval: getResult.data.itemPricing[0].packageName,
          });
        }
        setShowAddedItem(true);
        ContextSkeleton(
          getResult.data,
          itemArray,
          setTestInput,
          customerId,
          priceController,
          competitorDDValue,
          tireController
        );
      } else if (getResult.status == 400) {
        setLoaderBundle({ ...loaderBundle, masterLoader: false });
        setmodalController({
          ...modalController,
          itemPricingFail: true,
        });
        setPricexeptionMessage(getResult.data.errors[0].error);
      }

      return getResult;
    }
  };

  const closepackageExceptionController = () => {
    // setpackageExceptionController(false);
    setmodalController({
      ...modalController,
      packageExceptionController: false,
    });
  };

  const acceptPackageCriteria = () => {
    // setPackageExceptionPopup(false);
    setmodalController({ ...modalController, PackageExceptionPopup: false });
  };

  const closeAcceptPricingFail = () => {
    setmodalController({ ...modalController, itemPricingFail: false });
  };

  const clearbtnClick = (value: any) => {
    if (value === true) {
      setpackageDeptid([]);
      setpackageSubdepid([]);
      setpackageBracketid([]);
      // setpackageItemMasterid([]);
    }
  };

  const closeGroupPop = async () => {
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: false,
    });
    const allowPricePopulate =
      isChoosePriceConfig == '1'
        ? await pricePopulator(PackagePricingResponse)
        : true;
    if (allowPricePopulate) {
      const getBreakPkgData: any = [];
      PackagePricingResponse.data.itemPricing.map((obj: any) => {
        if (obj.packagePriceId !== null && obj.packagePriceId !== 'null') {
          getBreakPkgData.push(Number(obj.packagePriceId));
        }
      });
      const uniqueBreakData = [...new Set(getBreakPkgData)];
      setbrokenPackageData(uniqueBreakData);
      setreceivedpackage('');
      // if(itemArray.length >= 1){
      //   childFunc.current.resetCompetitorData();
      // }
      setisCompCheck(false);
      setselectedCompetitor(undefined);
      setsearchCriteria({ ...searchCriteria, packageTypeval: '' });
      const newItemArray: any = [];
      console.log('Package Pricing Response', PackagePricingResponse);
      // setpackageDataContext(PackagePricingResponse.packageInformation);
      //let pushingItemArrayId;
      console.log('initial bfvds itemArray', itemArray);
      console.log('ngbfdv PackagePricingResponse', PackagePricingResponse);
      if (itemArray.length > 0) {
        console.log('reached muli map');

        PackagePricingResponse.data.itemPricing.map(
          (packageMap: any, indexPak: any) => {
            console.log(
              'mapper of parseInt(packageMap.inventoryId)',
              parseInt(packageMap.inventoryId)
            );

            console.log('times reached here jhbvd');
            newItemArray.push({
              inventoryId: Number(packageMap.inventoryId),
              inventoryPriceId: Number(packageMap.inventoryPriceId),
            });
          }
        );
      }
      if (itemArray.length == 0) {
        console.log('reached singlr map');
        newItemArray.push({
          inventoryId: Number(
            PackagePricingResponse.data.itemPricing[
              PackagePricingResponse.data.itemPricing.length - 1
            ].inventoryId
          ),
          inventoryPriceId: Number(
            PackagePricingResponse.data.itemPricing[
              PackagePricingResponse.data.itemPricing.length - 1
            ].inventoryPriceId
          ),
        });
      }
      if (newItemArray.length > 1) {
        setpackageListData(
          PackagePricingResponse.data.packageInformation.packageTypeList
        );
        setreceivedpackage(
          PackagePricingResponse.data.itemPricing[0].packageName
        );
        if (selectedPackage == '') {
          setpackageCriteria(
            PackagePricingResponse.data.packageInformation.packageTypeList[0]
              .packageCriteria
          );
        } else {
          const filteredCriteria =
            PackagePricingResponse.data.packageInformation.packageTypeList.filter(
              (item, index) => {
                if (selectedPackage == item.packageName) {
                  return item;
                }
              }
            );
          setpackageCriteria(filteredCriteria[0].packageCriteria);
        }
        setsearchCriteria({
          ...searchCriteria,
          packageTypeval:
            PackagePricingResponse.data.itemPricing[0].packageName,
        });
      }

      setItemArray(newItemArray);
      setitemArrayData(newItemArray);
      setIsPackage(false);
      console.log('Break Item Array', newItemArray);
      console.log('itemArray in closeGroupPop', itemArray);

      console.log(
        'PackagePricingResponse.itemPricing.inventoryId',
        PackagePricingResponse.data.itemPricing[0].inventoryId
      );

      setfooterControlData(false);

      let tireController = false;
      const responseHolder = PackagePricingResponse.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          // settireController(true);
          break;
        }
      }
      ContextSkeleton(
        PackagePricingResponse.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );

      setInternalContextProvider(PackagePricingResponse.data);
      setPromoAgrItemPrice(PackagePricingResponse.data);
      setaddedItem(PackagePricingResponse.data);
      setBucketCopy([...bucketCopy, gridData[collectIndex]]);
      setcopyArrayData([...bucketCopy, gridData[collectIndex]]);
      console.log('copy', bucketCopy);
      console.log('received PackageItemId', PackageItemId);
      const newTableData = gridData.filter((item) => {
        if (parseInt(item.inventoryId) == PackageItemId) {
          console.log('PackageItemId in logic added', PackageItemId);
          console.log('item.inventoryid in logic added', item.inventoryid);
          console.log('Logic Worked');
        }
        console.log('filter item', item);
        return parseInt(item.inventoryId) != PackageItemId;
      });
      if (newTableData.length <= 0) {
        setresultController({
          ...resultControllerObj,
          controlEmptyRecord: true,
        });
      }
      console.log('Add Package newTableData', newTableData);
      setGridData(newTableData);
      // if (PackagePricingResponse.data !== undefined) {
      //   setShowAddedItem(true);
      //   setmodalController({ ...modalController, addAllPopUp: false });
      // }
      // console.log('ItemMainAry', newTableData);
      // setmodalController({
      //   ...modalController,
      //   addAllPopUp: false,
      //   breakPopup: false,
      // });
      setShowAddedItem(true);
    }
  };
  // this method is used to close the group popup //PS-12
  const declineAddOther = () => {
    setmodalController({
      ...modalController,
      addAllPopUp: false,
      breakPopup: true,
    });
  };

  const deleteSingleItem = async (itemId: any) => {
    // setmasterLoader(true);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    console.log('Required Item Id', itemId);
    setdeleteId({ ...deleteId, invDeleteId: itemId });
  };

  const storeSelectedPackage = (value) => {
    console.log('value of the selected packjage', value);

    setselectedPackage(value);
    setstoreSelectedPackage(value);
  };

  const closeReserved = () => {
    setmodalController({
      ...modalController,
      reservedExceptionController: false,
    });
  };

  useEffect(() => {
    console.log('change of all selectedPackage', selectedPackage);
  }, [selectedPackage]);

  const methodAssigner: any = {
    closeGroupPopCaller: closeGroupPop,
    acceptBreakPackageCaller: acceptBreakPackage,
    declineBreakPackageCaller: declineBreakPackage,
    closepackageExceptionControllerCaller: closepackageExceptionController,
    addAllItemCaller: addAllItem,
    declineAddOtherCaller: declineAddOther,
    declineAddingCaller: declineAdding,
    acceptPackageCriteriaCaller: acceptPackageCriteria,
    closeModalCaller: closeModal,
    acceptPricingFailCaller: closeAcceptPricingFail,
    closeReservedPopup: closeReserved,
  };

  const tagSingleInvCaller = async (selectedTag?: any) => {
    setLoaderBundle({ ...loaderBundle, masterLoader: true });

    console.log('vfdsdd cvfd', newSingleInvPricingInvArray);

    let arrayForm: any;
    if (selectedTag === undefined) {
      arrayForm = newSingleInvPricingInvArray;
    } else {
      arrayForm = [...itemArray, selectedTag];
    }
    let packageParameter = '';
    if (arrayForm.length > 0) {
      packageParameter = selectedPackage;
    }
    console.log('fds wedfd 65454', arrayForm);
    console.log('vfdsx sdcvfd777', selectedTag);
    const response: any = await pricingCall(
      arrayForm,
      null,
      false,
      null,
      null,
      false,
      packageParameter,
      null
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status === 200) {
      setisCompCheck(false);
      setselectedCompetitor(undefined);
      const getBreakPkgData: any = [];
      response.data.itemPricing.map((obj: any) => {
        if (obj.packagePriceId !== null && obj.packagePriceId !== 'null') {
          getBreakPkgData.push(Number(obj.packagePriceId));
        }
      });
      const uniqueBreakData = [...new Set(getBreakPkgData)];
      setbrokenPackageData(uniqueBreakData);
      const collectPriceArray: any = [];

      response.data.itemPricing.map((obj, index) => {
        collectPriceArray.push({
          inventoryId: Number(obj.inventoryId),
          inventoryPriceId: Number(obj.inventoryPriceId),
        });
      });
      setItemArray(collectPriceArray);
      setitemArrayData(collectPriceArray);
      let tireController: any = false;
      const responseHolder = response.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          settireController(true);
          break;
        }
      }
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      ContextSkeleton(
        response.data,
        arrayForm,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );
      setIsPackage(false);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setfooterControlData(false);
      if (isPackage) {
        console.log('reached expected');
        console.log('reached expected copy', bucketCopy);
        const copyTableData = gridData;
        bucketCopy.map((obj: any) => {
          copyTableData.push(obj);
        });
        setGridData(copyTableData);
      }

      if (
        response.data.packageInformation.packageTypeList !== null &&
        Object.keys(response.data.packageInformation.packageTypeList).length !==
          0 &&
        response.data.packageInformation.packageTypeList !== ''
      ) {
        if (packageParameter == '') {
          setpackageCriteria(
            response.data.packageInformation.packageTypeList[0].packageCriteria
          );
        } else {
          const filteredCriteria =
            response.data.packageInformation.packageTypeList.filter(
              (item, index) => {
                if (packageParameter == item.packageName) {
                  return item;
                }
              }
            );
          setpackageCriteria(filteredCriteria[0].packageCriteria);
        }
        setreceivedpackage(response.data.itemPricing[0].packageName);
        setsearchCriteria({
          ...searchCriteria,
          packageTypeval: response.data.itemPricing[0].packageName,
        });
        setselectedPackage(response.data.itemPricing[0].packageName);
        setstoreSelectedPackage(response.data.itemPricing[0].packageName);
      } else if (selectedPackage !== '') {
        setselectedPackage(selectedPackage);
        setstoreSelectedPackage(selectedPackage);
      } else {
        setpackageCriteria(null);
        setselectedPackage('');
        setstoreSelectedPackage('');
      }
      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      setBucketCopy([...bucketCopy, gridData[collectIndex]]);
      setcopyArrayData([...bucketCopy, gridData[collectIndex]]);
      const newTableData = gridData.filter((item, filterIndex) => {
        return filterIndex != collectIndex;
      });
      if (newTableData.length <= 0) {
        // setcontrolEmptyRecord(true);
        setresultController({
          ...resultControllerObj,
          controlEmptyRecord: true,
        });
      }

      setsearchResult(newTableData);

      console.log('ItemMainAry', newTableData);
      setGridData(newTableData);
      // setaddAllPopUp(false);
      //setmodalController({ ...modalController, addAllPopUp: false });

      setShowAddedItem(true);
    }
  };

  const tagPackageInvCaller = async (decider) => {
    setopenPackagePriceTag(false);
    setLoaderBundle({ ...loaderBundle, masterLoader: true });
    const response = await pricingCall(
      [],
      PackageItemId,
      true,
      null,
      null,
      false,
      '',
      decider
    );
    setLoaderBundle({ ...loaderBundle, masterLoader: false });
    if (response.status == 200) {
      setisPackageFlow(false);
      setbrokenPackageData([]);
      setisCompCheck(false);
      setselectedPackagePriceTag(decider);
      setStoreSelectedPackagePriceTag(decider);
      setselectedCompetitor(undefined);
      setIsPackage(true);
      setpackageListData(response.data.packageInformation.packageTypeList);
      setinitialAgrData(response.data.agreementPricing.agreementRate);
      setreceivedpackage(response.data.itemPricing[0].packageName);
      setsearchCriteria({
        ...searchCriteria,
        packageTypeval: response.data.itemPricing[0].packageName,
      });
      let newItemArray: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((value: any) => {
          console.log('individual inv id of package', value.inventoryId);

          newItemArray = [
            ...newItemArray,
            {
              inventoryId: Number(value.inventoryId),
              inventoryPriceId: Number(value.inventoryPriceId),
            },
          ];
        });

        setfooterControlData(false);

        if (
          response.data.packageInformation.packageTypeList !== null &&
          Object.keys(response.data.packageInformation.packageTypeList)
            .length !== 0 &&
          response.data.packageInformation.packageTypeList !== ''
        ) {
          setpackageCriteria(
            response.data.packageInformation.packageTypeList[0].packageCriteria
          );
          setreceivedpackage(response.data.itemPricing[0].packageName);
          setsearchCriteria({
            ...searchCriteria,
            packageTypeval: response.data.itemPricing[0].packageName,
          });
        } else {
          setpackageCriteria(null);
        }
      }
      setItemArray(newItemArray);
      // setitemArrayContext(newItemArray);
      setitemArrayData(newItemArray);
      let tireController = false;
      const responseHolder = response.data.itemPricing;
      for (let i = 0; i < responseHolder.length; i++) {
        if (
          responseHolder[i].rmsItemNumber == '200012120' ||
          responseHolder[i].rmsItemNumber == '200012121' ||
          responseHolder[i].rmsItemNumber == '200013177' ||
          responseHolder[i].rmsItemNumber == '200012118' ||
          responseHolder[i].rmsItemNumber == '200013350' ||
          responseHolder[i].rmsItemNumber == '200012119'
        ) {
          tireController = true;
          settireController(true);
          break;
        }
      }
      ContextSkeleton(
        response.data,
        newItemArray,
        setTestInput,
        customerId,
        priceController,
        competitorDDValue,
        tireController
      );

      setInternalContextProvider(response.data);
      setPromoAgrItemPrice(response.data);
      // setGridData([...tabledata, copy]);
      const copyTableData = gridData;

      // setGridData([...tabledata, obj]);
      bucketCopy.map((obj: any) => {
        copyTableData.push(obj);
      });
      setGridData(copyTableData);
      // setcopy([]);

      let emptyCopy: any = [];
      if (response.data !== undefined) {
        response.data.itemPricing.map((obj: any) => {
          gridData.map((value: any) => {
            if (value.inventoryId === obj.inventoryId) {
              emptyCopy = [...emptyCopy, value];
            }
          });
        });
        setBucketCopy(emptyCopy);
        // setcopyArrayContext(emptyCopy);
        setcopyArrayData(emptyCopy);
      }
      console.log('the empty copy is', emptyCopy);
      console.log('before tab data values', gridData);
      let emptyTableData: any = [];
      emptyTableData = gridData.filter((object) => !emptyCopy.includes(object));
      console.log('exptyTableData', emptyTableData);
      setGridData(emptyTableData);
      if (response.data !== undefined) {
        // setaddedItemContext(response.data);
        setaddedItem(response.data);

        setShowAddedItem(true);
        // setaddAllPopUp(false);
        setmodalController({ ...modalController, addAllPopUp: false });
      }
    } else if (response.status == 400) {
      // setpackageExceptionController(true);
      setmodalController({
        ...modalController,
        itemPricingFail: true,
      });
      setPricexeptionMessage(response.data.errors[0].error);
      // setmasterLoader(false);
      setLoaderBundle({ ...loaderBundle, masterLoader: false });
    }
  };

  const SLACancel = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
  };

  const closeSLA = () => {
    setSLAController({ ...SLAController, TwoFactorCancelClick: false });
    setSecondFactorEnabled(false);
    if (isPackageFlow) {
      setopenPackagePriceTag(true);
    } else {
      setOpenPriceTag(true);
    }
  };

  const SLACompleted = (value: any) => {
    setSecondFactorEnabled(false);
    if (isPackageFlow) {
      tagPackageInvCaller('PRTD');
    } else {
      tagSingleInvCaller();
    }
  };

  const triggerNextModal = (inventoryId, inventoryPriceId, decider) => {
    console.log('sdfbvfdfv inventoryId sd', inventoryId);
    console.log('sdfdedcx inventoryPriceId bvc', inventoryPriceId);

    setnewSingleInvPricingInvArray([
      ...newSingleInvPricingInvArray,
      {
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      },
    ]);

    // setnewSingleInvPricingInvArray({
    //   inventoryId: Number(inventoryId),
    //   inventoryPriceId: Number(inventoryPriceId),
    // });
    setOpenPriceTag(false);
    if (decider === 'Printed') {
      setSecondFactorEnabled(true);
    } else if (decider === 'Active') {
      tagSingleInvCaller({
        inventoryId: Number(inventoryId),
        inventoryPriceId: Number(inventoryPriceId),
      });
    }
  };

  const triggerPackageNextModal = (decider: any) => {
    console.log('ASDCVD decider sd', decider);

    setopenPackagePriceTag(false);
    if (decider === 'PRTD') {
      setSecondFactorEnabled(true);
    }
  };

  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return typeof str === 'string' && str.length > max
      ? str.substring(0, max) + add
      : str;
  };

  const openPriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              marginRight: '48px',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
          >
            Inventory # : {tagBlueLabel.inventoryNumber}
          </Typography>
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              tagBlueLabel?.inventoryDesc?.length > 22
                ? tagBlueLabel?.inventoryDesc
                : ''
            }
          >
            Description : {truncString(tagBlueLabel.inventoryDesc, 22, '...')}
          </Typography>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${leftValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {leftValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${leftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${leftValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    leftValue.inventoryPriceId,
                    'Active'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '200px',
              right: '320px',
              top: '140px',
              marginTop: '15px',
            }}
          ></Divider>
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '13px',
                }}
              >
                ${rightValue.tagRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Weekly Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '10px',
                }}
              >
                {rightValue.tagTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${rightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '30px',
                }}
              >
                ${rightValue.tagTotal}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() =>
                  triggerNextModal(
                    tagBlueLabel.inventoryId,
                    rightValue.inventoryPriceId,
                    'Printed'
                  )
                }
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const dynamicInvNumberBinding = () => {
    console.log('dfdc 8555 packageInvPopulator', packageInvPopulator);

    return packageInvPopulator.map((obj: any, index: any) => {
      return (
        <Typography
          key={index}
          variant="caption"
          className={`${agrInfoClassName.priceTagLabelColour} ${classes.width100} ${classes.floatLeft} ${classes.margincardsize}`}
        >
          {obj.inventoryNumber}
        </Typography>
      );
    });
  };

  const dynamicInvDescriptionBinding = () => {
    return packageInvPopulator.map((obj: any, index: any) => {
      return (
        <Typography
          key={index}
          variant="caption"
          className={`${agrInfoClassName.priceTagLabelColour} ${classes.width100} ${classes.floatLeft} ${classes.margincardsize}`}
        >
          {obj.inventoryName}
        </Typography>
      );
    });
  };

  const openPackagePriceModal = () => {
    return (
      <Grid className="">
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} `}
          style={{ padding: '10px 10px', backgroundColor: '#EFF4FF' }}
        >
          <Typography
            style={{
              float: 'left',
              color: '#2279fd',
              fontSize: '14px',
              fontFamily: 'opensans-bold',
            }}
            title={
              leftValue?.inventoryName?.length > 22
                ? leftValue?.inventoryName
                : ''
            }
          >
            Package Type : {packageBlueLabel}
          </Typography>
        </Card>
        <Card
          className={`${classes.racSkyBlue} ${classes.bluecards} ${classes.mt3}`}
          style={{
            padding: '10px 10px',
            backgroundColor: '#EFF4FF',
            maxHeight: '130px',
            overflow: 'auto',
          }}
        >
          <Grid className={`${classes.floatLeft}`} md={4}>
            <Typography
              variant="caption"
              style={{
                float: 'left',
                color: '#2279fd',
                fontSize: '15px',
                fontFamily: 'opensans-bold',
                width: '100%',
                backgroundColor: '#EFF4FF',
                zIndex: '1',
                top: '-10px',
                position: 'sticky',
              }}
            >
              Inventory # :{' '}
            </Typography>
            {dynamicInvNumberBinding()}
          </Grid>

          <Grid className={`${classes.floatLeft}`} md={8}>
            <Typography
              variant="caption"
              style={{
                float: 'left',
                color: '#2279fd',
                fontSize: '15px',
                fontFamily: 'opensans-bold',
                width: '100%',
                backgroundColor: '#EFF4FF',
                zIndex: '1',
                top: '-10px',
                position: 'sticky',
              }}
              //className={`${agrInfoClassName.agrGenaralInfoColourLabel} ${classes.width100} ${classes.floatLeft}`}
            >
              Description :{' '}
            </Typography>
            {dynamicInvDescriptionBinding()}
          </Grid>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              New Price Tag (Active Pricing)
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${PackageleftValue.agreementRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                {PackageleftValue.agreementTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackageleftValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackageleftValue.totalCost}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() => tagPackageInvCaller('ACTIVE')}
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              height: '200px',
              right: '320px',
              top: '140px',
              marginTop: '15px',
            }}
          ></Divider>
          {/* <hr style={{backgroundColor:"red",transform: 'rotate(90deg)',margin:'inherit'}}></hr> */}
          <Grid item xs={6}>
            <Typography
              className={classes.margincard}
              style={{ fontFamily: 'OpenSans-bold', fontSize: '15px' }}
            >
              Printed Price Tag
            </Typography>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Rate:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                ${PackagerightValue.agreementRate}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Agreement Term:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '27px',
                }}
              >
                {PackagerightValue.agreementTerm}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Cash Price:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackagerightValue.cashPrice}
              </Typography>
            </Grid>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <Typography
                style={{ color: '#4A5174', fontFamily: 'OpenSans-bold' }}
              >
                Total Cost:
              </Typography>
              <Typography
                style={{
                  fontFamily: 'OpenSans-bold',
                  fontSize: '14px',
                  float: 'right',
                  paddingLeft: '70px',
                }}
              >
                ${PackagerightValue.totalCost}
              </Typography>
            </Grid>
            <Grid className={`${classes.py4} ${classes.textCenter}`}>
              <RACButton
                className={classes.mxe}
                variant="contained"
                color="primary"
                onClick={() => triggerPackageNextModal('PRTD')}
              >
                ADD
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={`${classes.agreementContainerSpacing} ${showItemSearch}`}>
      {/* </div>/<div className={`${classes.agreementContainerSpacing}`}> */}
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={dynamicViewClass}>
          {/* <Grid container spacing={2} className={'enableme'}> */}
          <Grid item className={altersearch}>
            <SearchFilter
              searchClick={searchClick}
              // PackagedropdownOnClick={PackagedropdownOnClick}
              IsPackage={true}
              clearbtnClick={clearbtnClick}
              triggerItemPriceByData={pricingCallTrigger}
              throwStyle={classes}
              throwSalesLead={salesLeadData}
              throwPackage={receivedpackage}
              throwSelectedPackage={storeSelectedPackage}
              throwItemArray={itemArray}
              throwpackageList={packageList}
            />
            <div>
              {loaderBundle.loaderController === true &&
              loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />
              ) : (
                gridCallMethod()
              )}
            </div>

            <ModalContainer
              throwModalController={modalController}
              throwMethodAssigner={methodAssigner}
              throwPricingException={pricingException}
              throwFormException={formException}
              throwPriceExceptionMessage={PricexeptionMessage}
            />
          </Grid>
          {showAddedItem ? (
            <Usercontext.Provider value={internalContextData}>
              <AddedItem
                triggeroneDelete={deleteSingleItem}
                module="Agreement"
                throwPackage={receivedpackage}
                priceexceptionMessage={pricingException}
                throwStyle={classes}
                // childFunc={childFunc}
              />
            </Usercontext.Provider>
          ) : null}
        </Grid>

        <FooterContent
          setItemsearchCompleted={props.setItemSearchCompleted}
          controlData={footerControlData}
          throwStyle={classes}
          throwCopy={bucketCopy}
          throwpackageCriteria={packageCriteria}
          triggerMinMaxPopup={minMaxException}
          func={pull_data}
          throwPackageState={isPackage}
          throwItemArray={itemArray}
          givereceivedpackage={receivedpackage}
          sometrigger={suppiler}
        />

        {SecondFactorEnabled === true ? (
          <SecondFactor
            // setTwoFactorCancelClick={setTwoFactorCancelClick}
            setTwoFactorCancelClick={SLACancel}
            // setTwoFactorCompleted={setTwoFactorCompleted}
            setTwoFactorCompleted={SLACompleted}
            closeSLAClicked={closeSLA}
            // setRoleFromSLAModule={provideSLAData}
            moduleName={ModuleName}
            currentRole={CurrentRole}
            // minRole={SLAMinRole}
            throwCurrentPage="ItemSearch"
          />
        ) : null}

        <RACModalCard
          isOpen={openPriceTag}
          maxWidth={'sm'}
          borderRadius={'25px !important'}
          title={'Please choose the price'}
          closeIcon={true}
          onClose={() => setOpenPriceTag(false)}
          //eslint-disable-next-line react/no-children-prop
          children={openPriceModal()}
        ></RACModalCard>

        <RACModalCard
          isOpen={openPackagePriceTag}
          maxWidth={'sm'}
          borderRadius={'25px !important'}
          title={'Please choose the price'}
          closeIcon={true}
          onClose={() => setopenPackagePriceTag(false)}
          //eslint-disable-next-line react/no-children-prop
          children={openPackagePriceModal()}
        ></RACModalCard>
      </div>
    </div>
  );
};

export default SearchResult;
