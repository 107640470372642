/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-constant-condition */
import { client, client1, clientCall } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
//
const QAToken =
  'eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjM1NTQ1NTY3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2MzU1NDkxNjcsImlhdCI6MTYzNTU0NTU2NywidmVyc2lvbiI6MiwianRpIjoiMWI4NjJmZDctZGE0Zi00ZTMwLWJmM2QtMGUwOGQwNDRmMmYwIiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.qOF9aMMN_8nCia6JbsZmUy6wD96wsWPZmZVULqu3bsa7RDctmVH3gbTANOToA-U3xqNGSFL9rMrHU8YmAuEgI9NXtMxcZzOXJrVvV_Zlpq6Kb6FgIRtDOFzOGvuW41aOAzOxL4H7BjLOLzbbIkfWINBUME68NhXyP8ZMDrjP0pw75sqw2peIaMPILQqEJUARNiFaphrMRz267SUo2LIkLFxsq20rE_tgCTkFZ8n_zgUZ5ejJnM92VQkd-2hmp8SCdqalEI4r1Q1vva3mkR8kaLqN2Ef0b_XMj4bMXnbl1DNKPdyi48GknOFD81AonGXqW5hJFxe1LfTOB4H0kcryeA';
//const BaseUrl = 'https://dev-agreement-racpad.rentacenter.com/api';

const TwoFactorBaseURL = appConfig.apiUrls.storemgmt;
const getCurrentUserBaseURL = appConfig.apiUrls.menu;
const InventoryBaseUrl = appConfig.apiUrls.inventory;
const customerBaseURL = appConfig.apiUrls.customer;
// const salesLeadUATURL = 'https://uat-customer-racpad.rentacenter.com/api';

// const PaymentBaseUrl = 'https://dev-payment1-racpad.rentacenter.com/api';

// eslint-disable-next-line no-console
console.log('app config in user', appConfig);

export const tireBuyerEmailNotification = async (voidobj) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/void/update`,
    { method: 'PUT', body: voidobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
}
export const amActivityLogInsertion = async (payload: any) => {
  return await clientCall(
    `customer/logactivity`,
    { method: 'POST', body: payload },
    appConfig.apiUrls.am,
    QAToken,
    true
  );
}

export const insertAgreementLog = async (Payload: any) => {
  return await clientCall(
    `agreement/activity`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer,
    QAToken,
    true
  );
};
export const tireCOCreateAgreement = async (payload: any) => {
  return await clientCall(
    `agreement/customerorder/create`,
    { method: 'POST', body: payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const tireCreateCart = async (payload: any) => {
  return await clientCall(
    `agreement/create/cart`,
    { method: 'POST', body: payload },
    // appConfig.apiUrls.micro,
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getRetentionOffers = async (Payload: any) => {
  return await clientCall(
    `retention/offers`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer,
    QAToken,
    true
  );
};

export const getAgreementsByCustomerId = async (customerId: any) => {
  return await clientCall(
    `customer/${customerId}/agreements?inActive=N`,
    { method: 'GET' },
    customerBaseURL,
    QAToken,
    true
  );
};
// store serach service function
export const getStore = async (Payload: any) => {
  return await clientCall(
    `customer/store-search`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer,
    QAToken,
    true
  );
};
export const updateEventStatus = async (payload: any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `appointment/action`,
      { method: "POST", body: payload },
      appConfig.apiUrls.storemgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
    //throw err;
  }
};
// Manage Agreement Transfer
export const manageAgreementTransfer = async (Payload: any) => {
  return await clientCall(
    `manage/transfer`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
// getAgreementTransferReceive
export const getAgreementTransfer = async (
  storeNum: any,
  transferType: any,
  offset: any
) => {
  return await clientCall(
    `agreement/transfers/${storeNum}?transferType=${transferType}&offset=${offset}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const splitAgreement = async (Payload: any) => {
  return await clientCall(
    `agreement/update/split`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const breakPackage = async (Payload: any) => {
  return await clientCall(
    `/inventory/package/break`,
    { method: 'POST', body: Payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};
export const getCOpriceQuote = async (Payload: any) => {
  return await clientCall(
    `/agreement/customerorder/quote`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const coCreateAgreement = async (Payload: any) => {
  return await clientCall(
    `agreement/customerorder/create`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const getnextpaymentamount = async (Payload: any) => {
  return await clientCall(
    `agreement/getnextpaymentamount`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getAgreementInfo = async (AgreementId: any) => {
  return await clientCall(
    `agreement/${AgreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const UpdateAddressInfo = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `customer/address/update`,
      { method: 'POST', body: payload },

      customerBaseURL,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getPrintDocument = async (payload: any) => {
  return await clientCall(
    `agreement/document/print`,
    { method: 'POST', body: payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const customerOrderSearch = async (payload: any) => {
  return await clientCall(
    `agreement/customerorder/search`,
    { method: 'POST', body: payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getCustomerPolicy = async (
  agreementId: any,
  customerId: any,
  storeNumber: any
) => {
  return await clientCall(
    `customer/${customerId}/${storeNumber}?agreementId=${agreementId}`,
    { method: 'PUT' },
    customerBaseURL,
    QAToken,
    true
  );
};

export const getPolicyInfo = async (paylaod: any) => {
  /* eslint-disable no-console */
  console.log('getPolicy URL Check', appConfig.apiUrls.micro);

  return await clientCall(
    `agreement/policy`,
    { method: 'POST', body: paylaod },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getprofileaccess = async (Payload: any) => {
  return await clientCall(
    `store-access`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getPinDetails = async (Payload: any) => {
  return await clientCall(
    `coworker`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getsecondfactor = async (Payload: any) => {
  return await clientCall(
    `coworker-authentication`,
    { method: 'POST', body: Payload },
    TwoFactorBaseURL,
    QAToken,
    true
  );
};

export const getCurrentInfo = async () => {
  return await clientCall(
    `user/current`,
    { method: 'GET' },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};

export const getSalesInfo = async (salesLeadId: any) => {
  return await clientCall(
    `customer/saleslead/details/${salesLeadId}`,
    { method: 'GET' },
    customerBaseURL,
    QAToken,
    true
  );
};

export const GetCustomer = async (PathParams: any, Payload: any) => {
  return await clientCall(
    `customer/${PathParams}`,
    { method: 'PUT', body: Payload },
    customerBaseURL,
    QAToken,
    true
  );
};
export const getAgreementOptions = async (
  CustomerID: any,
  StoreNumber: any
) => {
  return await clientCall(
    `agreement/options/${CustomerID}?storeNumber=${StoreNumber}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const getSwitchOutAgreementOptions = async (
  CustomerID: any,
  StoreNumber: any
) => {
  return await clientCall(
    `agreement/options/${CustomerID}?storeNumber=${StoreNumber}&isSwitchOut=1`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const getCOAgreementOptions = async (
  CustomerID: any,
  StoreNumber: any
) => {
  return await clientCall(
    `agreement/options/${CustomerID}?storeNumber=${StoreNumber}&isChargeOff=1`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const GetCustomerCoverage = async (
  customerId: any,
  storeNumber: any
) => {
  return await clientCall(
    `customer/${customerId}/${storeNumber}`,
    { method: 'PUT' },
    customerBaseURL,
    QAToken,
    true
  );
};
export const getPriceQuote = async (Payload: any) => {
  return await clientCall(
    `agreement/price/quote`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const GenerateAgreementDoc = async (Payload: any) => {
  return await clientCall(
    `agreement/document/generate`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const CreateAgreement = async (Payload: any) => {
  const customHeaders = {
    headers: { storeNumber: window.sessionStorage.getItem('storeNumber') },
  };
  return await clientCall(
    `agreement/create`,
    { method: 'POST', body: Payload, customHeaders },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const UpdateAgreement = async (Payload: any) => {
  return await clientCall(
    `agreement/switchout/manage`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getInventorySearch = async (payload: any) => {
  return await clientCall(
    'inventory/search',
    { method: 'POST', body: payload },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const SendAutopayText = async (Payload: any) => {
  return await clientCall(
    `agreement/autopay`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};
export const GetAgreementCustomer = async (Pathparams: any) => {
  return await clientCall(
    `agreement/customers/${Pathparams}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const UpdateVehicleInfo = async (CustomerId, Payload: any) => {
  return await clientCall(
    `customer/${CustomerId}/information`,
    { method: 'PUT', body: Payload },
    customerBaseURL,
    QAToken,
    true
  );
};
export const GetEmployeeID = async () => {
  return await clientCall(
    `user/current`,
    { method: 'GET' },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};
export const GetRole = async (Payload: any) => {
  return await clientCall(
    `menu-and-stores`,
    { method: 'POST', body: Payload },
    getCurrentUserBaseURL,
    QAToken,
    true
  );
};

export const getAgreementCustomerInfo = async (AgreementId: any) => {
  // eslint-disable-next-line no-console
  console.log('AgreementId', AgreementId);

  return await clientCall(
    `agreement/customers/${AgreementId}`,

    { method: 'GET' },

    appConfig.apiUrls.micro,

    QAToken,

    true
  );
};

export const getVoidOptions = async (CustomerID: any, StoreNumber: any) => {
  return await clientCall(
    `agreement/options/${CustomerID}?storeNumber=${StoreNumber}&isReturn=${0}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getReorderReasonOption = async () => {
  return await clientCall(
    `/agreement/void/reasons `,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getAgreementReturnOptions = async (
  CustomerID: any,
  StoreNumber: any
) => {
  return await clientCall(
    `agreement/options/${CustomerID}?storeNumber=${StoreNumber}&isReturn=${1}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const LoadInventoryInformation = async (inventoryNumber: any) => {
  return await clientCall(
    `inventory/${inventoryNumber}`,
    { method: 'GET' },
    InventoryBaseUrl,
    QAToken,
    true
  );
};

export const CancelPickupStatus = async (voidobj: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/void/update`,
    { method: 'PUT', body: voidobj },
    appConfig.apiUrls.micro,
    // 'https://qa-agreement-racpad.rentacenter.com/api',
    TempAccessToken
  );
};
export const getDeliveryMethod = async () => {
  return await client(
    'agreement/delivery/156',
    { method: 'GET' },
    appConfig.apiUrls.micro,
    true
  );
};
export const GetstateDropdown = async () => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `customer/state`,
    { method: 'GET' },
    customerBaseURL,
    TempAccessToken
  );
};
export const Getreorderreasons = async () => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/reorderreasons`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const itemReorder = async (reorderpayload: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/customerorder/reorder`,
    { method: 'POST', body: reorderpayload },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const GetSuggestedAddress = async (addressPayload: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `customer/address/validate`,
    { method: 'POST', body: addressPayload },
    customerBaseURL,
    TempAccessToken
  );
};

export const GetDeReason = async () => {
  return await clientCall(
    `customer/no-decision-reason`,
    { method: 'GET' },
    customerBaseURL,
    QAToken,
    true
  );
};
export const CoworkerRequired = async (bodyobject: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/address/eventId`,
    { method: 'POST', body: bodyobject },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const GetEpodetails = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/epo`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const GenerateReceipt = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/generatereceipt`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const GenerateSwitchoutReceipt = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/generatereceipt`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const sendEPO = async (mail: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/epo/mail`,
    { method: 'POST', body: mail },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const GetAgreementactivity = async (agreementId: string) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('agrid', agreementId);
  /* eslint-enable no-console */
  const TempAccessToken = QAToken;
  try {
    return await client1(
      `agreement/activity/${agreementId}`,
      { method: 'GET' },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};

export const GetLegalHold = async (customerId: string) => {
  /* eslint-disable no-console */
  console.log('Inside Getlegal Hold', customerId);
  const TempAccessToken = QAToken;
  try {
    return await client1(
      `customer/${customerId}/legal-hold`,
      { method: 'GET' },
      customerBaseURL,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log('Inside legal hold', err);
    /* eslint-enable no-console */
    return 'Error';
  }
};

export const GetCustomerId = async (GCID: string) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `customer/${GCID}/detail`,
      { method: 'GET' },
      customerBaseURL,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};
export const Getglobalcustomerid = async (customerId: string) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `customer/${customerId}/detail`,
      { method: 'GET' },
      customerBaseURL,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};
export const updatecustomer = async (addaddress: any) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `customer/post-no-decision`,
      { method: 'POST', body: addaddress },
      customerBaseURL,
      TempAccessToken
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};

export const FindCoCustomer = async (searchdata: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/co-customer/search`,
    { method: 'POST', body: searchdata },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const InsertCocustomer = async (insertdata: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/co-customer/add`,
    { method: 'POST', body: insertdata },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const UpdateVoidStatus = async (voidobj: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/void/update`,
    { method: 'PUT', body: voidobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
//VA_14
export const GetVoidReasons = async (voidreasons: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/void/update`,
    { method: 'PUT', body: voidreasons },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
//get delivery address component
export const GetDeliveryAddress = async (customerId: string) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/delivery/${customerId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
//GetTimeSlots component
export const GetTimeSlots = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/timeslots`,
    { method: 'POST', body: bodyobj },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const GetEnvelopeID = async (parameters: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    '/ess-rest-test.cloudhub.io/esignature/envelopes',
    { method: 'POST', body: parameters },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const LoadDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await client1(
    `agreement/delivery/receipt/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const LoadswitchoutDeliveryreceipt = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await client1(
    `agreement/delivery/receipt/${agreementId}?isSwitchOut=1`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
export const Confirmdelivery = async (updatedeliverystatus: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/status/update`,
    { method: 'PUT', body: updatedeliverystatus },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};
//  function added to confirm delivery for switchout
export const Confirmswitchout = async (updatedeliverystatus: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/status/update`,
    { method: 'PUT', body: updatedeliverystatus },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const Recieptsign = async (updatesign: any) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/delivery/receipt/sign`,
    { method: 'PUT', body: updatesign },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const CheckSign = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await client1(
    `agreement/delivery-receipt/sign/check/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const FinishdeliveryStatus = async (complete: any) => {
  const TempAccessToken = QAToken;

  return await clientCall(
    `agreement/delivery`,

    { method: 'POST', body: complete },

    appConfig.apiUrls.micro,

    TempAccessToken
  );
};
// export const GetTimeSlots = async (bodyobj: any) => {
//   const TempAccessToken = QAToken;

//   return await client1(
//     `agreement/timeslots`,

//     { method: 'POST', body: bodyobj },

//     BaseUrl,

//     TempAccessToken
//   );
// };
export const GetCustomerinfo = async (agreementId: string) => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `agreement/customers/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.micro,
    TempAccessToken
  );
};

export const getCurrentUser = (): Promise<any> =>
  client('users/current', { method: 'GET' }, appConfig.apiUrls.container, true);

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,

    { method: 'GET' },

    appConfig.apiUrls.container
  );

// export const getUserPermissions = (productType: string): Promise<any> =>
//   client(
//     `users/current/permissions/${productType}`,

//     { method: 'GET' },

//     appConfig.apiUrls.container
//   );

export const setUserPreferences = (
  productType: string,

  payload: Record<string, any>,

  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,

    {
      method: 'PATCH',

      body: [payload],

      cancelToken,
    },

    appConfig.apiUrls.container
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',

    { method: 'GET' },

    appConfig.apiUrls.container
  );

// export const getSearchParams = () =>
// client(
//   'users/current/stores?q=permissions[:includes]=RACPAD_DAP',

//   { method: 'GET' },

//   appConfig.apiUrls.container
// );

// export const getInventorySearch = async (payload: any) => {
//   // eslint-disable-next-line no-console
//   console.log('payload', payload);

//   return await client1(
//     'inventory/search',

//     { method: 'POST', body: payload },
//     InventoryBaseUrl,
//     QAToken,
//     true
//   );
// };
export const getSearchParams = async () => {
  return await clientCall(
    `inventory/search-params/""`,

    { method: 'GET' },

    InventoryBaseUrl,

    QAToken,

    true
  );
};

export const getCompetitors = async () => {
  return await clientCall(
    '/agreement/competitors',
    { method: 'GET' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getPackageDropdown = async (payload: any) => {
  try {
    return await clientCall(
      'inventory/package/list',

      { method: 'POST', body: payload },
      InventoryBaseUrl,
      QAToken,
      true
    );
  } catch (err: any) {
    /* eslint-disable no-console */
    console.log(err);
    /* eslint-enable no-console */
    return 'Error';
  }
};
export const getItemPricing = (payload: any) =>
  client1(
    'agreement/package-item/price',

    { method: 'POST', body: payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );

export const getAgreementDelivery = async (agreementId: any,appointmentId?:any) => {
  return await clientCall(
    `agreement/delivery/info/${agreementId}?appointmentId=${appointmentId}`,
    { method: 'Get' },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const getPricingClientCall = async (Payload: any) => {
  return await clientCall(
    'agreement/package-item/price',
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    QAToken,
    true
  );
};

export const RunDE = async (payload: any) => {
  return await clientCall(
    `customer/decision-engine`,
    { method: 'POST', body: payload },
    customerBaseURL,
    QAToken,
    true
  );
};

export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};
export const getFitmentApi = async(payload : any) => {
  try {

    return await clientCall(
      `agreement/customerorder/search`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      QAToken,
      true
    );
  } catch (error:any) {
    
    /* eslint-disable no-console */
    console.log(error);
    /* eslint-enable no-console */
  }
}
export const getProductAPI = async (payload: any) => {
  try {
    return await clientCall(
      `agreement/customerorder/search`,
      { method: "POST", body: payload },
      appConfig.apiUrls.micro,
      QAToken,
      true
    );
  } catch (error:any) {
    /* eslint-disable no-console */
    console.log(error);
    /* eslint-enable no-console */
  }
};
export const getStoreActionsItem = async (payload :any) => {
  /* eslint-disable no-console */
  try {
    return await clientCall(
      `cashmgmt/drawer/audit/info`,
      { method: "POST", body: payload }, 
      appConfig.apiUrls.cashmgmt,
      QAToken,
      true
    );
  } catch (err: any) {
    return err;
  }
};
