/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable prefer-const */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable sonarjs/no-extra-arguments */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState, useContext } from "react";
import { fieldDeviceStyles } from "../../../JSstyles/fieldDeviceStyles";
import PhoneInput from "react-phone-number-input/input";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Images } from "../../../assets/images/pdf-icon.svg";
import { ReactComponent as Successimage } from "../../../assets/images/success-icon.svg";
import { ReactComponent as RacLogo } from "../../../assets/images/RAC-Logo.svg";
import { ReactComponent as PDFImage } from "../../../assets/images/pdf-icon.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as Alert } from "../../../assets/images/no-records-found.svg";
import { ReactComponent as Polygon } from "../../../assets/images/actionarrow.svg";
import { ReactComponent as Racfont } from "../../../assets/images/RAC-Banner.svg";
import Erroricon from "../../../assets/images/erroricon.png";
import infoicon from "../../../assets/images/info.png";
import { AgreementContext } from "../../../context/AgreementContext";
import AgreementReturn from "./AgreementReturn";
import VoidAgreement from "../AddCoCustomer/voidagreement";
import { SwitchOutAgreementContext } from "../../../context/SwitchOutAgreementContext";
import DeliveryDigitalSign from "../../Shared/DeliveryDigitalSign";
import html2pdf from 'html2pdf.js';
import {
  Confirmdelivery,
  Recieptsign,
  CheckSign,
  GetCustomerinfo,
  GenerateAgreementDoc,
  getCustomerPolicy,
  getnextpaymentamount,
  getPrintDocument,
  GenerateReceipt,
  getAgreementInfo,
  LoadDeliveryreceipt,
  getCurrentInfo,
  GetRole,
  CancelPickupStatus,
  getAgreementDelivery,
  updateEventStatus
} from "../../../api/user";
import AgreementDetailsProps from "./Agreementdetailsprops";
import AddCoCustomer from "../AddCoCustomer/AddCocustomer";
import CustomerSignature from "../../Shared/CustomerSignature";
import { printHTML } from "./print";
import {
  List,
  ListItem,
  RACButton,
  RACRadio,
  RACModalCard,
  RACTextbox,
  RACSelect,
  Typography,
  Grid,
  CircularProgress,
  RACInfoIcon,
  Card,
  CardContent,
} from "@rentacenter/racstrap";
import {
  LoadswitchoutDeliveryreceipt,
  Confirmswitchout,
  GenerateSwitchoutReceipt,
  UpdateAgreement,
  sendEPO,
} from "../../../api/user";

import { agreementGlobalStyles } from "../../../JSstyles/agreementGlobalStyles";
import { twoFactorStyles } from "../../../JSstyles/twoFactorStyles";
import { addCoCustomerStyles } from "../../../JSstyles/addCoCustomerStyles";
import { autoPayStyles } from "../../../JSstyles/autoPayStyles";
import SecondFactor from "../TwoFactorPopup/TwoFactorPopup";
import { ContainerContext } from "../../../app/App";
import ChargeOff from "../View Agreement/chargeOff";
import { CustomPropInterface } from "../../../index";
import { add } from "date-fns/esm";
import { AppRoute } from "../../../config/route-config";
import { CustomerLocationState } from "../../../types/types";

import {
  AccountManagementURLParam,
  AMOrigin,
} from "../../../constants/constants";
import RateReduction from "./RateReduction";
import InstallmentNote from "./InstallmentNote";
import RetentionRTreduction from "./RetentionRTreduction";
import { OriginContext } from "../../../context/OriginContext";
import { FeatureFlagContext } from "../../../context/FeatureFlagContext";
import moment from "moment";
import SendDeliveryReceipt from "../../Shared/SendDeliveryReceipt";
export type PropsInterface = {
  setTwoFactorCancelClick?: any; //
  setTwoFactorCompleted?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
};

interface ParamType {
  agreementId: string;
  customerId: string;
}
let errorFlag: number;

// eslint-disable-next-line sonarjs/cognitive-complexity
const Footer = (props: any) => {
  const storeDetails = sessionStorage.getItem('storeDetails') || '';
  const encodeStoreDetails = Buffer.from(storeDetails, 'base64');
  const decodestoreDetails: any = JSON.parse(
    encodeStoreDetails.toString('ascii')
  );
  const classes1 = fieldDeviceStyles();
  const [returnAppiontmentStatus, setreturnAppiontmenStatus] = useState('')
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const history = useHistory();
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const source = new URLSearchParams(search).get("source");
  const isTireBuyerFlow = new URLSearchParams(search).get('tb');
  const isTireBuyerAgrSigned = new URLSearchParams(search).get('tbsigned');
  const apid = new URLSearchParams(search).get('apid');

  const location = useLocation<CustomerLocationState>();
  const classes = agreementGlobalStyles();
  const twoFactorClassName = twoFactorStyles();
  const addCOCustomerClassName = addCoCustomerStyles();
  const [actionBtnView, setactionBtnView] = useState(false);
  const autoPayClassName = autoPayStyles();
  const [digitalSignEnable, setdigitalSignEnable] = useState(false);
  const [deliveryReceiptB64, setDeliveryReceiptB64] = useState('');
  const [deliveryCheckListB64, setDeliveryCheckListB64] = useState('');
  const [deliveryAddendumB64, setdeliveryAddendumB64] = useState('')
  const [cancelEventPopup, setcancelEventPopup] = useState(false);
  const currentURL = window.location.href;
  const [cancelEventReason, setcancelEventReason] = useState('')
  const {
    viewAgreementContext,
    setdeliveryMethodData,
    deliveryMethodData,
    viewAgreementData,
    viewAgrDataContext,
    setviewAgrData,
    setviewAgreementData,
    isSOEnabledbyconfig,
    viewAgrData,
    CustomerInfoContext,
    SetReturnReschedule,
    isReturnEnabledbyconfig,
    isDeliveryComplete,
    returnReschedule,
    resumebtnFeatureFlag,
    isReturn,
    setIsReturn,
    agreementInfoData,
    amountDueResponse,
    ReturnReasons,
    setAgreementInfoData,
    newAppointmentId,
    manualSignatureEnabled,
    setManualSignatureEnabled,
    rescheduleEnable,
    setRescheduleEnable,
    setdatePickerEnable,
    createAAgreementTiemslot,
    setcreateAAgreementTiemslot
  } = useContext(AgreementContext);
  const { redirectOrigin } = useContext(OriginContext);
  const {
    setaddedItem,
    setsearchResult,
    setitemArray,
    setSwitchoutcopyArrayData,
    setagreementCreateStatus,
    setcontainCondition,
    setcontainSubDepartment,
    setpendingSOAgr,
    setisswitchoutCancelled,
    setretentionActivityCall,
  } = useContext(SwitchOutAgreementContext);

  let { agreementId } = useParams<ParamType>();
  const { customerId } = useParams<ParamType>();

  if (redirectOrigin === AMOrigin) {
    agreementId = agreementId.replace(AccountManagementURLParam, "");
  }
  const PaddingConst = "0px 8px 0px 8px";
  const Constinter = "1px solid  #545454";
  const borderRadiuss = "3px";
  const borderbotttom = "1px solid #545454";
  const borderrr = "1px solid #DFDFDF";
  const borderrbottommm = "1px dashed #545454";
  const borderalone = "1px solid   #545454";
  const [getViewAgrData, setgetViewAgrData] = viewAgrDataContext;
  const [ReturnComplete, setReturnComplete] = useState(false);
  const [ReturnButtonDisable, setReturnButtonDisable] = useState(true);
  const [ReturnDeliveryCompleted, SetReturnDeliveryCompleted] = useState(false);
  const [deliveryDigitalCustomerSignatureEnable, setdeliveryDigitalCustomerSignatureEnable] = useState(false)
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [controlFooter, setcontrolFooter] = React.useState("");
  const [CustomerValue, setCustomerValue]: any = CustomerInfoContext;
  const [coCustomercheck, setcoCustomercheck] = React.useState(false);
  const [printRestrict, setprintRestrict] = useState(false);
  const { setEmployeeDetails } = props;
  const [disableconfirmdelivery, Setdisableconfirmdelivery] = useState(true);
  const [deliveryconfirmerror, setdeliveryconfirmerror] = useState(false);
  const [Openprintpopup, SetOpenprintpopup] = useState(false);
  const [CancelSwitchOutInViewDelivery, setCancelSwitchOutInViewDelivery] =
    useState(false);
  const [CheckforSign, SetCheckforSign] = useState(false);
  const [cancelReturnPickup, setcancelReturnPickup] = useState(false);
  const [viewdocusign, setviewdocusign] = useState(false);
  const [Opendeliverypopup, SetOpendeliverypopup] = useState(false);
  const [Opendeliveryconfirmpopup, SetOpendeliveryconfirmpopup] =
    useState(false);
  const [appointmentId, setappointmentId] = useState("");
  const [storename, Setstorename] = useState(""); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storenumber, SetstoreNumber] = useState(""); // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storezip, Setstorezip] = useState("");
  const [storestate, Setstorestate] = useState("");
  const [ReturnSORPopup, setReturnSORPopup] = useState(false);
  const [SwitchOutSORPopup, setSwitchOutSORPopup] = useState(false);
  const [storecity, Setstorecity] = useState("");
  const [StoreAddressline1, SetStoreAddressline1] = useState("");
  const [StoreAddressline2, SetStoreAdressline2] = useState("");
  const [Storephonenum, SetStorephonenum] = useState("");
  const [CustomerName, SetCustomerName] = useState("");
  const [CustomerAddressline1, SetCustomerAddressline1] = useState("");
  const [CustomerAddressline2, SetCustomerAddressline2] = useState("");
  const [CustomerPhoneNumber, SetCustomerPhoneNumber] = useState("");
  const [customercity, Setcustomercity] = useState("");
  const [disableSubmitButton, setdisableSubmitButton] = useState(false);
  const [customerstate, SetCustomerstate] = useState("");
  const [customerzip, Setcustomerzip] = useState("");
  const [customerplus4, Setcustomerplus4] = useState("");
  const [inventorynumber, setinventorynumber] = useState("");
  const [inventorydesc, setinventorydesc] = useState("");
  const [inventoryseialnum, setinventoryserialnum] = useState("");
  const [inventorynumberswitchinitem, setinventorynumberswitchinitem] =
    useState("");
  const [inventorydescswitchin, setinventorydescswitchin] = useState("");
  const [inventoryserialnumswitchin, setinventoryserialnumswithchin] =
    useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState<any>('')


  const [deliveryerrorpopup, setdeliveryerrorpopup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [AgreementId, SetAgreementId] = useState("");
  const [CustomerId, SetCustomerId] = useState("");
  const [pdfresponse, setpdfresponse] = useState([]);
  const [itemdata, setitemdata]: any = useState<any>([]);
  const [pdfdata, setpdfdata] = useState([]);
  //MAM state
  const [ResendVopen, setResendVopen] = useState(false);
  const [textMsg, settextMsg] = useState(false);
  const [sendEmail, setsendEmail] = useState(true);
  const [emailText, setemailText] = useState("");
  const [resendsuccess, setResendsuccess] = useState(false);
  const [phoneNo, setphoneNo] = useState("");
  const [welcomeEnable, setwelcomeEnable] = useState(false);
  const [phoneErrMsg, setphoneErrMsg] = useState("");
  const [emailErrMsg, setemailErrMsg] = useState("");
  const [repeatContent, setrepeatContent] = useState(false);

  const [PolicyButtonEnable, setPolicyButtonEnable] = useState<any>();
  const [PolicyVisible, setPolicyVisible] = useState(false);
  const [isPolicyDisabled, setisPolicyDisabled] = useState(false);
  const [policyLoader, setpolicyLoader] = useState(false);
  const [disableSaveButton, setdisableSaveButton] = useState(false);
  // eslint-disable-next-line prefer-const

  const [masterLoader, setmasterLoader]: any = React.useState(false);
  const [somethingone, setsomethingone] = React.useState(
    "modal fade show expandPopup"
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState("modal fade");

  const [Continue, setContinue] = React.useState(true);
  const [currentUserRole, setCurrentUserRole] = React.useState("");
  const storeNo = window.sessionStorage.getItem("storeNumber");
  const [agrNo, setagrNo] = viewAgreementContext;
  const [agreementNum, setagreementNum] = useState("");

  const [DocumentsToPrintArr, setDocumentsToPrintArr]: any = React.useState([]);

  const [IsDeliveryConfirmed, setIsDeliveryConfirmed] = useState(false);

  const [CustomerID, setCustomerID] = React.useState("");
  const [coCustomerId, setcocustomerId] = useState("");
  const [coCustomerName, setcoCustomerName] = useState("");
  const [Cocustomer, SetCocustomer] = useState<any>();
  const [deliveryMethodFooter, SetdeliveryMethodFooter] = useState<any>();
  const [showDeliveryMethod, SetshowDeliveryMethod] = useState(true);
  const [IsAgreementVoided, setIsAgreementVoided] = useState(false);
  const [DeliveryDate, setDeliveryDate] = useState("");
  const [DeliveryTime, setDeliveryTime] = useState("");
  const [twofactorpopup, settwofactorpopup] = useState(true);
  const [voidedagreementfooter, Setvoidedagreementfooter] = useState(false);
  const [checksignresponse, setchecksignresponse]: any = useState([]);
  const [disablereprintbutton, setdisablereprintbutton] = useState(true);
  const [receiptsignresponse, setreceiptsignresponse] = useState("");
  const [cancelSODisable, setcancelSODisable] = useState(false);
  const [SOTwoFactoreEnabled, setSOTwoFactoreEnabled] = useState(false);
  const [SOTwoFactorReturnEnabled, setSOTwoFactorReturnEnabled] =
    useState(false);
  const nextpaymentobj = {
    nextamountdue: "",
    nextduedate: "",
  };
  const [nextpaymentinfo, setnextpaymentinfo] = useState(nextpaymentobj);
  const [Footer, SetFooter] = useState("");
  const [paymentredirectFlow, setpaymentredirectFlow] = useState(false);
  const [paymentredirectChargeOff, setpaymentredirectChargeOff] =
    useState(false);
  const [SwitchOutOpen, SetSwitchOutOpen] = useState("0");
  const [OpenPickupconfirmpopup, SetOpenPickupconfirmpopup] = useState(false);
  const [SwitchOutDisable, SetSwitchOutDisable] = useState(true);
  const [SwitchOutMessage, SetSwitchOutMessage] = useState("");
  const [printswitchoutpopup, setprintswitchoutpopup] = useState(false);
  const [disableconfirmswitchout, setdisableconfirmswitchout] = useState(true);
  const [confirmswitchoutpopup, setconfirmswitchoupopup] = useState(false);
  const [switchoutdelievry, setswitchoutdelievry] = useState(false);
  const [switchouterror, setswitchouterror] = useState(false);
  //state variable declared to hide the print switchout button
  const [hideswitchoutbutton, sethideswitchoutbutton] = useState(true);
  const [newinventoryId, setnewinventoryId] = useState("");
  const [maualSignReasonDrpdwnVal, setmaualSignReasonDrpdwnVal] = useState([
    {
      value: "",
      label: "Select",
    },
    {
      value: "3",
      label: "Technology issue - Digital Signing",
    },
    {
      value: "2",
      label: "Technology issue - Customer",
    },
    {
      value: "1",
      label: "Customer requires Paper Signature",
    },
  ]);
  const [customerSignEnabled, setcustomerSignEnabled] = useState(false);
  const [customersArray, setcustomersArray]: any = useState([]);
  const [CancelSwitchOut, SetCancelSwitchOut] = useState(false);
  const [twoFactorEnabled, settwoFactorEnabled] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState();
  const [openCOprintpopup, setopenCOprintpopup] = useState(false);
  const [isCustomerorderenabled, setisCustomerorderenabled] = useState(false);

  const [returnbtnRdn, setreturnbtnRdn] = useState(false);
  const [installNoteEnable, setinstallNoteEnable] = useState(false);
  const [installNoteComponent, setinstallNoteComponent] = useState(false);
  const [confirmFlow, setconfirmFlow] = useState(false);
  const [isReturnStart, setisReturnStart] = useState(false);
  const [ReverseConfirmation, setReverseConfirmation] = useState(false);
  const [employeeId, setemployeeId] = useState("");
  const [getAgreementDeliveryRes, setgetAgreementDeliveryRes] = useState<any>('')
  const [getAgreementInfoRes, setgetAgreementInfoRes] = useState<any>('');
  const [isSendDeliveryreceiptEnabled, setIsSendDeliveryreceiptEnabled] = useState(false);
  const [tbCustomerSignatureEnable, setTbCustomerSignatureEnable] = useState(false);
  const [RefundSDPopup, setRefundSDPopup] = useState(false)
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    console.log("Footer contex", data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  useEffect(() => {
    setcreateAAgreementTiemslot(false)
    getAgreementDeliveryFn();
    if (isTireBuyerFlow && isTireBuyerAgrSigned == '1') {
      agreementsignedmethod();
    }
    else if (isTireBuyerFlow && isTireBuyerAgrSigned == '0') {
      fetchTBCustomerInfo()
    }
  }, [])
  useEffect(() => {

    getQrcodeFn()
  }, [])

  useEffect(() => {
    if (apid) {
      Pickup_Confirmed_Ok()
    }
  }, [])

  const fetchTBCustomerInfo = async () => {
    setmasterLoader(true);
    const result = await GetCustomerinfo(agreementId);
    console.log("GetCustomerinfo response : ", result);
    setmasterLoader(false);
    if (result.status == 200) {
      if (result?.data?.coCustomer?.length == 0) {
        const CustomerObj = [
          {
            customerId: result.data.customer[0].customerId,
            customerName: result.data.customer[0].customerName,
            phoneNumber: result.data.customer[0].phoneNumber,
            emailId: result.data.customer[0].emailAddress,
          },
        ];
        setcustomersArray(CustomerObj);
      } else {
        if (result.data.coCustomer[0].isDocumentGenerated === "1") {
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
            {
              emailId: result.data.coCustomer[0].emailAddress,
              customerName: result.data.coCustomer[0].customerName,
              phoneNumber: result.data.coCustomer[0].phoneNumber,
              customerId: result.data.coCustomer[0].customerId,
            },
          ];
          setcustomersArray(CustomerObj);
        } else {
          console.log("No Co Customer : ", result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
          ];
          setcustomersArray(CustomerObj);
        }
      }
    }
    setTbCustomerSignatureEnable(true);
  }

  const getQrcodeFn = async () => {
    const payLoad: any = {
      identifier: agreementId,
      documentStatus: 'signed',
      actionType: 'qrcode',
      identifierType: 'AGR',
    };
    const getPrintDocumentRes = await getPrintDocument(payLoad)
    setQrCodeUrl(getPrintDocumentRes?.data[0]?.documentUrl)
  }
  const getAgreementDeliveryFn = async () => {
    setmasterLoader(true);
    let getAgreementInfoRes = await getAgreementInfo(agreementId);
    setgetAgreementInfoRes(getAgreementInfoRes?.data)
    const appointmentId = getAgreementInfoRes?.data?.agreementInformation?.appointmentId
    if (appointmentId) {
      const getAgreementDeliveryRes = await getAgreementDelivery(agreementId, appointmentId)
      setmasterLoader(false);
      if (getAgreementDeliveryRes?.status == 200 && getAgreementDeliveryRes?.data) {
        setgetAgreementDeliveryRes(getAgreementDeliveryRes?.data)
      }
    }
    else {
      setmasterLoader(false);
    }
  }
  useEffect(() => {
    console.log("cdjvhbdsh342873rbfejhbf");
    setdisableSaveButton(props.throwButtonController);
    setdisableSubmitButton(props.throwReturnController);
  }, [props.throwButtonController, props.throwReturnController]);

  useEffect(() => {
    if (amountDueResponse && type?.toUpperCase() == "RETURN") {
      setreturnbtnRdn(true);
      setpaymentredirectFlow(true);
    } else if (amountDueResponse && type == "CHRGOFF") {
      setpaymentredirectChargeOff(true);
    }
  }, [amountDueResponse, ReturnReasons]);
  useEffect(() => {
    console.log("getViewAgrData111111", getViewAgrData);
  }, [getViewAgrData]);
  useEffect(() => {
    console.log("hideswitchoutbutton value change check", hideswitchoutbutton);
    console.log("props.currentPage value change check", props.currentPage);
  }, [hideswitchoutbutton, props.currentPage]);
  const customerSignUsingField = "deliveryMethod";
  const GettingRole = async () => {
    const userResponse = await getCurrentInfo();
    console.log("getCurrentUser Response", userResponse);
    const empId = userResponse.data.employeeId;
    const mailId = userResponse.data.email.toLowerCase();
    const id = userResponse.data.employeeId ? empId : mailId;
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    let storesPayload: any;
    // eslint-disable-next-line prefer-const
    storesPayload = { coworkerId: id };
    // eslint-disable-next-line no-console
    console.log("coworkerId", storesPayload);
    const userRole = await GetRole(storesPayload);
    const coworkerRole = userRole.data.coworkerProfile.role;
    // eslint-disable-next-line no-console
    console.log("Logged In user Role : ", coworkerRole);

    setCurrentUserRole(coworkerRole);
  };
  useEffect(() => {
    if (containerData !== undefined) {
      console.log("containerData", containerData);

      console.log("containerDataRole", containerData.GetRole());
      setemployeeId(containerData.GetEmployeeId());
      setCurrentUserRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, [TwoFactorCompleted, TwoFactorCancelClick]);

  useEffect(() => {
    console.log(
      "times of data with props.throwcoverageData",
      props.throwcoverageData
    );

    if (props.throwcoverageData == undefined) {
      setisPolicyDisabled(true);
      setpolicyLoader(true);
    } else {
      setisPolicyDisabled(false);
      setpolicyLoader(false);
    }
  }, [props.throwcoverageData]); //Updated
  useEffect(() => {
    setsomethingone("modal fade show expandPopup");
    setsomethingtwo("modal fade");
    const querystring = window.location.href;
    const finalize = querystring.charAt(querystring.length - 1);
    setcontrolFooter(finalize);
    console.log("props.agreementvoided", props.agreementvoided);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openPopup = () => {
    props.triggeropenPopup();
  };

  useEffect(() => {
    if (props.throwgetCustomerPolicyResponse !== undefined) {
      setPolicyButtonEnable(
        props.throwgetCustomerPolicyResponse.data.policyInfo
      );
      setPolicyVisible(true);
    }
  }, [props.throwgetCustomerPolicyResponse]); //agreementId, customerId, storeNo
  useEffect(() => {
    const checksignstatus = async () => {
      const apiResponse: any = await CheckSign(agreementId);
      console.log("response", apiResponse);
      setdisablereprintbutton(false);
      setchecksignresponse(apiResponse);
    };
    checksignstatus();
  }, []);
  useEffect(() => {
    console.log("view agreement Context ", viewAgreementContext, agrNo);
    if (agreementInfoData?.installmentNoteInfo) {
      setinstallNoteEnable(true);
    }
    if (agreementInfoData) {
      setappointmentId(agreementInfoData?.agreementInformation?.appointmentId);
    }
    if (agreementInfoData?.agreementCustomer) {
      const customerInfodata = agreementInfoData?.agreementCustomer.filter(
        (el) => el.priority == "1"
      );
      if (customerInfodata.length > 0) {
        customerInfodata.map((value: any) => {
          setemailText(value.emailId);
          setphoneNo(value.phoneNumber);
        });
      }
    }
    if (
      agrNo !== undefined &&
      agrNo.agreementInformation.agreementNumber !== undefined &&
      agrNo.agreementInformation.agreementNumber !== null
    ) {
      setagreementNum(agrNo.agreementInformation.agreementNumber);
      console.log(
        "context is here----->",
        agrNo.agreementInformation.agreementNumber
      );
      console.log("props  is here", agreementNum);
      agrNo.agreementInventories.map((obj: any, i: any) => {
        if (agrNo.agreementInventories[i].isItemSwitchedIn === "1") {
          setnewinventoryId(agrNo.agreementInventories[i].inventoryId);
          console.log(
            "view the agreement inventory isitemswitchedin 1",
            agrNo.agreementInventories[i].inventoryId
          );
        }
        console.log("itemswitched 1 inventoryid", newinventoryId);
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAgreementContext, AgreementContext]);
  useEffect(() => {
    console.log("setReturnButtonDisable", getViewAgrData);

    if (getViewAgrData != undefined) {
      console.log("heheheheh11");
      if (
        getViewAgrData?.data.agreementInformation.agreementStatus == "Active" &&
        getViewAgrData?.data.agreementInformation.storeNumber ==
        window.sessionStorage.getItem("storeNumber") &&
        getViewAgrData?.data.agreementWorkflow.isSwitchoutEnabled == "0" &&
        featureFlagDetails?.AgreementReturn == "1" &&
        ReturnComplete === false
      ) {
        console.log("heheheheh");

        setReturnButtonDisable(false);
      } else {
        setReturnButtonDisable(true);
      }
      if (
        getViewAgrData?.data.agreementInformation.agreementStatus !== "Active"
      ) {
        SetSwitchOutDisable(true);
      }
      if (getViewAgrData?.data.agreementWorkflow?.isCOAgreement == "1") {
        setisCustomerorderenabled(true);
      }
    }
  }, [viewAgreementContext, AgreementContext, props.ReturnDisable]);
  useEffect(() => {
    console.log(
      "view agreement Context loggerreeds",
      viewAgreementContext,
      agrNo
    );
    console.log("agrNo checker of value", agrNo);
    let isTire = false;
    if (
      props.throwreceivedDepartment !== undefined &&
      props.throwreceivedDepartment == "TIRES"
    ) {
      isTire = true;
    }
    if (
      agrNo !== undefined &&
      agrNo.agreementInventories[0] != undefined &&
      agrNo.agreementInventories[0] != null &&
      agrNo.agreementInventories[0].department != undefined &&
      agrNo.agreementInventories[0].department != null &&
      agrNo.agreementWorkflow != undefined &&
      agrNo.agreementWorkflow != null &&
      agrNo.agreementWorkflow.isChargeOffRequested != undefined &&
      agrNo.agreementWorkflow.isChargeOffRequested != null &&
      agrNo.agreementWorkflow.isSwitchoutEnabled != undefined &&
      agrNo.agreementWorkflow.isSwitchoutEnabled != null &&
      agrNo.agreementWorkflow.isDeliveryCompleted !== undefined &&
      agrNo.agreementWorkflow.isDeliveryCompleted !== null &&
      agrNo.agreementInformation != undefined &&
      agrNo.agreementInformation != null &&
      agrNo.agreementInformation.storeNumber != undefined &&
      agrNo.agreementInformation.storeNumber != null &&
      agrNo.agreementInformation.agreementStatusRefCode !== undefined &&
      agrNo.agreementInformation.agreementStatusRefCode !== null
    ) {
      console.log("5scad54cds");
      console.log("jdnfjdndudiid", featureFlagDetails?.Switchout);
      if (
        agrNo.agreementWorkflow.isSwitchoutEnabled == "1" &&
        agrNo.agreementInformation.storeNumber ==
        window.sessionStorage.getItem("storeNumber")
      ) {
        console.log("view context ---->", agrNo);

        console.log("hide print switchout", hideswitchoutbutton);
        sethideswitchoutbutton(false);
        setcancelSODisable(false);
        console.log("show print switchout", hideswitchoutbutton);
      } else if (
        agrNo.agreementWorkflow.isSwitchoutEnabled == "1" &&
        agrNo.agreementInformation.storeNumber !==
        window.sessionStorage.getItem("storeNumber")
      ) {
        sethideswitchoutbutton(false);
        setcancelSODisable(true);
      }
      if (
        isTire === false &&
        agrNo.agreementWorkflow.isChargeOffRequested == "0" &&
        agrNo.agreementWorkflow.isSwitchoutEnabled == "0" &&
        agrNo.agreementWorkflow.isDeliveryCompleted == "1" &&
        agrNo.agreementInformation.storeNumber ==
        window.sessionStorage.getItem("storeNumber") &&
        agrNo.agreementInformation.agreementStatusRefCode == "ACTIVE" &&
        featureFlagDetails?.Switchout == "1"
      ) {
        console.log("agrementudhishidcdi---->", agrNo);
        SetSwitchOutDisable(false);
      } else {
        SetSwitchOutDisable(true);
      }
      if (
        agrNo.agreementInformation.storeNumber !==
        window.sessionStorage.getItem("storeNumber")
      ) {
        console.log("ghjehdkjdojdoejdeidjwuh", agrNo);
        SetSwitchOutMessage("This Agreement is Created in a different Store");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.throwreceivedDepartment]); //viewAgreementContext, AgreementContext

  useEffect(() => {
    console.log("Props", props);
    if (
      props != undefined &&
      props.displayFooter != undefined &&
      props.displayFooter != null
    ) {
      if (props.displayFooter == "0") {
        SetdeliveryMethodFooter("deliveryReschedule");
      } else {
        SetshowDeliveryMethod(false);
        SetdeliveryMethodFooter("deliveryScheduled");
      }
    }
    if (props !== undefined && props.agreementvoided !== undefined) {
      if (props.agreementvoided === false) {
        setIsAgreementVoided(false);
        props.receiveIsVoid(false);
      } else {
        setIsAgreementVoided(true);
        props.receiveIsVoid(true);
      }
    }
    if (
      props.deliveryDetails !== undefined &&
      props.deliveryDetails.DeliveryDate !== undefined &&
      props.deliveryDetails.DeliveryTime !== undefined
    ) {
      console.log("Delivery date and time", props);
      console.log("delievry date", props.deliveryDetails.DeliveryDate);
      console.log("delivery time", props.deliveryDetails.DeliveryTime);
      setDeliveryDate(props?.deliveryDetails?.DeliveryDate ? moment(props?.deliveryDetails?.DeliveryDate).format("MM/DD/YYYY") : '');
      setDeliveryTime(props.deliveryDetails.DeliveryTime);
    }
    if (
      props.showDeliveryfooter != undefined ||
      props.showInfofooter != undefined
    ) {
      console.log("Displaydelivery Footer", props.showDeliveryfooter);
      console.log("Displayinfo Footer", props.showInfofooter);
      if (props.showDeliveryfooter != null) {
        SetFooter(props.showDeliveryfooter);
      } else if (props.showInfofooter != null) {
        SetFooter(props.showInfofooter);
      }
    }
    if (
      props.voidedAgreementfooter != undefined &&
      props.voidedAgreementfooter != null
    ) {
      if (props.voidedAgreementfooter === true) {
        Setvoidedagreementfooter(true);
      } else {
        Setvoidedagreementfooter(false);
      }
    }
  }, [props]);

  // // eslint-disable-next-line sonarjs/cognitive-complexity

  // const bindChecklist = () => {
  //   if (itemdata !== undefined && itemdata !== null && itemdata.length > 0) {
  //     return itemdata.map((value: any, index: any) => {
  //       return (
  //         <tr key={index} style={{ width: '100%' }}>
  //           <td
  //             style={{
  //               width: '15px',
  //               paddingLeft: '12px',
  //               paddingRight: '12px',
  //               paddingTop: '5px',
  //               paddingBottom: '5px',
  //             }}
  //           >
  //             <span
  //               style={{
  //                 padding: PaddingConst,
  //                 borderRadius: borderRadiuss,
  //                 border: Constinter,
  //                 marginRight: '10px',
  //               }}
  //             />
  //           </td>
  //           <td
  //             style={{
  //               width: '300px',
  //               paddingLeft: '12px',
  //               paddingRight: '12px',
  //               paddingTop: '5px',
  //               paddingBottom: '5px',
  //             }}
  //           >
  //             {value !== undefined && value.inventoryDesc !== undefined
  //               ? value.inventoryDesc
  //               : ''}
  //           </td>
  //           <td
  //             style={{
  //               width: '300px',
  //               paddingLeft: '12px',
  //               paddingRight: '12px',
  //               paddingTop: '5px',
  //               paddingBottom: '5px',
  //             }}
  //           >
  //             {value !== undefined &&
  //             value.serialNumber !== undefined &&
  //             value.serialNumber !== null &&
  //             value.serialNumber != ''
  //               ? value.serialNumber
  //               : '-'}
  //           </td>
  //           <td
  //             style={{
  //               width: '300px',
  //               paddingLeft: '12px',
  //               paddingRight: '12px',
  //               paddingTop: '5px',
  //               paddingBottom: '5px',
  //             }}
  //           >
  //             {value !== undefined && value.inventoryNumber !== undefined
  //               ? value.inventoryNumber
  //               : ''}
  //           </td>
  //         </tr>
  //       );
  //     });
  //   }
  // };

  const TakePaymentClick = () => {
    history.push({
      pathname: `/payment1/paymentinformation/${customerId}/0`,
    });
  };

  const SOTwoFactorCompleted = () => {
    setSOTwoFactoreEnabled(false);
    setisswitchoutCancelled(false);
    setCustomerValue(undefined);
    console.log("viewAgrData", viewAgrData);
    let switchOutUrl = "/agreement/switchout/itemsearch/";

    if (viewAgrData && viewAgrData.data.agreementCustomer.length == 1) {
      switchOutUrl = switchOutUrl + `${customerId}/${agreementId}`;
    } else if (viewAgrData && viewAgrData.data.agreementCustomer.length > 1) {
      switchOutUrl =
        switchOutUrl +
        `${viewAgrData.data.agreementCustomer[0].customerId}/${agreementId}`;
    }
    if (source == "AMRETENTION" && type == "SW") {
      setretentionActivityCall("1");
    }
    history.push({
      pathname: switchOutUrl,
    });
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };
  const customerSignatureCompletedFn = async () => {
    console.log("Customer sign completed");
    setcustomerSignEnabled(false);
    setmasterLoader(true);
    const apiResponse: any = await CheckSign(agreementId);
    console.log("view issigned 0 or 1", apiResponse);
    setchecksignresponse(apiResponse);
    if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned == "0"
    ) {
      setmasterLoader(false);
      SetCheckforSign(true);
    } else if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned === "1"
    ) {
      agreementsignedmethod();
    }
  };
  const customerSignatureCompltedFnForDigital = async () => {
    debugger;
    if (deliveryDigitalCustomerSignatureEnable) {
      setdeliveryDigitalCustomerSignatureEnable(false);
      setdigitalSignEnable(true);
    }
  }
  const customersignswichoutfuntion = async () => {
    console.log("Customer sign completed");
    {
      manualSignatureEnabled ? setcustomerSignEnabled(false)
        : setdeliveryDigitalCustomerSignatureEnable(false)
    }
    setmasterLoader(true);
    const apiResponse: any = await CheckSign(agreementId);
    console.log("view issigned 0 or 1", apiResponse);
    if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned == "0"
    ) {
      setmasterLoader(false);
      SetCheckforSign(true);
    } else if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned === "1"
    ) {
      switchoutbuttonclick();
    }
  };

  const emailcompletedFn = () => {
    {
      manualSignatureEnabled ?
        setcustomerSignEnabled(false) : setdeliveryDigitalCustomerSignatureEnable(false)
    } console.log("email completed");
  };
  const customerSignatureCanceledFn = () => {
    console.log("Customer sign canceled");
    {
      manualSignatureEnabled ?
        setcustomerSignEnabled(false) : setdeliveryDigitalCustomerSignatureEnable(false)
    }
  };

  const ReprintPopupOpen = async (agreementId: string) => {
    /* eslint-disable-next-line sonarjs/cognitive-complexity */
    SetCheckforSign(false);
    setmasterLoader(true);
    const result = await GetCustomerinfo(agreementId);
    console.log("Lambda response : ", result.data);
    setmasterLoader(false);
    const resultcheck = result.data ? result.data : undefined;

    if (result.status == 200 && resultcheck != undefined) {
      if (result.data.coCustomer.length == 0) {
        console.log("No Co Customer : ", result.data);

        console.log("customerdatalog", result.data.customer);
        const CustomerObj = [
          {
            customerId: result.data.customer[0].customerId,
            customerName: result.data.customer[0].customerName,
            phoneNumber: result.data.customer[0].phoneNumber,
            emailId: result.data.customer[0].emailAddress,
          },
        ];
        setcustomersArray(CustomerObj);
      } else {
        if (result.data.coCustomer[0].isDocumentGenerated === "1") {
          /* eslint-disable no-console */
          console.log("Co Customer : ", result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
            {
              emailId: result.data.coCustomer[0].emailAddress,
              customerName: result.data.coCustomer[0].customerName,
              phoneNumber: result.data.coCustomer[0].phoneNumber,
              customerId: result.data.coCustomer[0].customerId,
            },
          ];
          setcustomersArray(CustomerObj);
        } else {
          console.log("No Co Customer : ", result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
          ];
          setcustomersArray(CustomerObj);
        }
      }
      {
        !manualSignatureEnabled ? setdeliveryDigitalCustomerSignatureEnable(true) :
          setcustomerSignEnabled(true)
      }
    }
  };

  const generatereceippdf = async (generatereceiptpayload: any) => {
    console.log("view payload", generatereceiptpayload);
    let deliveryReceiptElement: any
    let deliveryChecklistElement: any
    let deliveryaddendumElement: any
    // Rental document Delivery Receipt and delivery checklist
    if (!generatereceiptpayload?.action) {
      deliveryReceiptElement = document.getElementById(
        'DeliveryReceiptrentalPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'DeliveryChecklistrentalPdf'
      );
    }
    // Return Documnet Pick receipt and Pick check list
    else if (generatereceiptpayload?.action == 3) {
      deliveryReceiptElement = document.getElementById(
        'pickupreciptPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'pickupchecklistprintHTML'
      );
    }
    // customer order agreement 
    else if (generatereceiptpayload?.action == 2) {
      deliveryReceiptElement = document.getElementById(
        'DeliveryReceiptrentalPrintHTML'
      );
      deliveryChecklistElement = document.getElementById(
        'DeliveryChecklistrentalPdf'
      );
      deliveryaddendumElement = document.getElementById(
        'deliveryaddendum'
      );

    }

    if (!generatereceiptpayload?.action || generatereceiptpayload?.action == 3) {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            setDeliveryReceiptB64(b64DeliveryReceipt);
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  setDeliveryCheckListB64(b64DeliveryCheckList);
                  makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, generatereceiptpayload?.action);
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }
    else {
      html2pdf()
        .from(deliveryReceiptElement)
        .outputPdf('blob')
        .then((pdfBlob) => {
          const DRreader: any = new FileReader();
          console.log('reader deliveryReceipt', DRreader);
          DRreader.onloadend = async () => {
            console.log('after Reader deliveryReceipt', DRreader);
            const b64DeliveryReceipt = DRreader?.result.split(',')[1];
            setDeliveryReceiptB64(b64DeliveryReceipt);
            //delivery check list flow
            html2pdf()
              .from(deliveryChecklistElement)
              .outputPdf('blob')
              .then((pdfBlob) => {
                const CLreader: any = new FileReader();
                console.log('reader deliveryChecklist', CLreader);
                CLreader.onloadend = async () => {
                  console.log('after Reader deliveryReceipt', CLreader);
                  const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                  setDeliveryCheckListB64(b64DeliveryCheckList);
                  html2pdf()
                    .from(deliveryaddendumElement)
                    .outputPdf('blob')
                    .then((pdfBlob) => {
                      const DAreader: any = new FileReader();
                      console.log('reader deliveryReceipt', DAreader);
                      DAreader.onloadend = async () => {
                        console.log('after Reader deliveryReceipt', DAreader);
                        const b64DeliveryAddendumReceipt = DAreader?.result.split(',')[1];
                        setdeliveryAddendumB64(b64DeliveryAddendumReceipt);
                        makeUploadDocument(b64DeliveryReceipt, b64DeliveryCheckList, generatereceiptpayload?.action, b64DeliveryAddendumReceipt);
                      };
                      DAreader.readAsDataURL(pdfBlob);
                      // 
                    });
                }
                CLreader.readAsDataURL(pdfBlob);
              });
            //end of check list flow
          };
          DRreader.readAsDataURL(pdfBlob);
        });
    }



  };
  const makeUploadDocument = async (
    deliveryReceiptFile?: any,
    deliveryCheckListFile?: any,
    type?: any,
    deliveryAddendumFile?: any
  ) => {
    debugger;
    let uploadDeliveryReceiptPayload: any
    if (!type) {
      uploadDeliveryReceiptPayload = {
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }
    else if (type == 3) {
      uploadDeliveryReceiptPayload = {
        action: 3,
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'PKRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'PCL',
            file: deliveryCheckListFile,
          },
        ],
      };
    }
    else if (type == 2) {
      uploadDeliveryReceiptPayload = {
        action: 2,
        agreementId: Number(agreementId),
        documents: [
          {
            documentType: 'DLRCT',
            file: deliveryReceiptFile,
          },
          {
            documentType: 'DCL',
            file: deliveryCheckListFile,
          },
          {
            documentType: 'DELAD',
            file: deliveryAddendumFile,
          },
        ],
      };
    }
    const uploadDeliveryReceiptResponse = await GenerateReceipt(
      uploadDeliveryReceiptPayload
    )
  };

  const agreementsignedmethod = async (actionUpdate?: string) => {
    const bodyobj = {
      agreementIdCustomerId: [
        {
          agreementId: parseInt(agreementId),
        },
      ],
    };
    let getamountresponse: any = {
      getamountdata: {},
    };

    let deliveryreceiptresponse: any = {
      deliverydata: {},
    };

    await Promise.all([
      getnextpaymentamount(bodyobj),
      LoadDeliveryreceipt(agreementId),
    ]).then((resp) => {
      [getamountresponse, deliveryreceiptresponse] = resp;

      console.log("asynchronous api calls", getamountresponse);
      console.log(
        "asynchronous api calls for reprint",
        deliveryreceiptresponse
      );
      console.log("responseData", deliveryreceiptresponse.responseData);
      console.log(
        "amountdueresponse",
        getamountresponse.data.amountDueResponse
      );

      if (
        deliveryreceiptresponse !== undefined &&
        deliveryreceiptresponse.responseData !== undefined
      ) {
        setmasterLoader(false);
        if (isCustomerorderenabled === true) {
          setopenCOprintpopup(true);
        } else {
          SetOpenprintpopup(true);
        }
        const resUniqueValue: any = deliveryreceiptresponse.responseData.filter(
          (obj, index) => {
            return (
              index ===
              deliveryreceiptresponse.responseData.findIndex(
                (o) => obj.inventoryId === o.inventoryId
              )
            );
          }
        );
        setitemdata(resUniqueValue);

        console.log("view response", deliveryreceiptresponse);
        if (isReturnStart || returnReschedule) {
          setinventorynumber(
            deliveryreceiptresponse.responseData[0].inventoryNumber
          );
          setinventorydesc(
            deliveryreceiptresponse.responseData[0].inventoryDesc
          );
          setinventoryserialnum(
            deliveryreceiptresponse.responseData[0].serialNumber
          );
        }
        Setstorename(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeName
            : ""
        );

        SetstoreNumber(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeNumber
            : ""
        );
        Setstorestate(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeState
            : ""
        );
        Setstorezip(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeZip
            : ""
        );

        Setstorecity(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeCity
            : ""
        );
        SetStorephonenum(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storePhoneNumber
            : ""
        );
        SetStoreAddressline1(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeAddressLine1
            : ""
        );
        SetStoreAdressline2(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].storeAddressLine2
            : ""
        );
        SetCustomerName(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerName
            : ""
        );
        SetCustomerAddressline1(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].addressLine1
            : ""
        );
        SetCustomerAddressline2(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].addressLine2
            : ""
        );
        Setcustomercity(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerCity
            : ""
        );
        SetCustomerstate(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerState
            : ""
        );

        Setcustomerzip(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerZip
            : ""
        );

        Setcustomerplus4(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerPlus4
            : ""
        );
        SetCustomerPhoneNumber(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
            : ""
        );
        SetAgreementId(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].agreementNumber
            : ""
        );
        SetCustomerId(
          deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined &&
            deliveryreceiptresponse.responseData.length > 0
            ? deliveryreceiptresponse.responseData[0].customerId
            : ""
        );
      }

      if (getamountresponse.status === 504) {
        setmasterLoader(false);
      } else {
        if (
          getamountresponse.data !== undefined &&
          getamountresponse.data !== null &&
          getamountresponse.data.amountDueResponse !== undefined &&
          getamountresponse.data.amountDueResponse !== null
        ) {
          const AmountResponse = getamountresponse.data.amountDueResponse.find(
            (x: { agreementId: string }) => x.agreementId == agreementId
          );
          console.log("agreementid related data", AmountResponse);

          if (
            AmountResponse !== undefined &&
            AmountResponse !== null &&
            AmountResponse !== "" &&
            AmountResponse.ldwAmount !== undefined &&
            AmountResponse.ldwAmount !== null
          ) {
            const Totalamountdue =
              parseFloat(AmountResponse.amountDue) +
              parseFloat(AmountResponse.ldwAmount);
            console.log("agreementid Total Amount", Totalamountdue);
            setnextpaymentinfo({
              ...nextpaymentinfo,
              nextamountdue: Totalamountdue.toFixed(2),
              nextduedate: AmountResponse.nextDueDate,
            });
            console.log("next amount due", nextpaymentinfo);
          } else {
            console.log("nextpaymentamount", getamountresponse.data);

            setnextpaymentinfo({
              ...nextpaymentinfo,
              nextamountdue:
                getamountresponse.data.amountDueResponse[0].amountDue,
              nextduedate:
                getamountresponse.data.amountDueResponse[0].nextDueDate,
            });
            console.log("next amount due", nextpaymentinfo);
            console.log(
              "getamountdue response",
              getamountresponse.data.amountDueResponse[0].amountDue
            );
          }

          const TempArr: any = [];
          if (
            deliveryreceiptresponse !== undefined &&
            deliveryreceiptresponse.responseData !== undefined
          ) {
            for (
              let i = 0;
              i <= deliveryreceiptresponse.responseData.length - 1;
              i++
            ) {
              let obj: any;
              if (isCustomerorderenabled === true) {
                obj = {
                  inventoryId:
                    deliveryreceiptresponse.responseData[i].inventoryId,
                  inventoryNumber:
                    deliveryreceiptresponse.responseData[i].inventoryNumber,
                  serialNumber:
                    deliveryreceiptresponse.responseData[i].serialNumber,
                  inventoryDesc:
                    deliveryreceiptresponse.responseData[i].inventoryDesc,
                  modelNumber:
                    deliveryreceiptresponse.responseData[i].modelNumber,
                };
              } else {
                obj = {
                  inventoryId:
                    deliveryreceiptresponse.responseData[i].inventoryId,
                  inventoryNumber:
                    deliveryreceiptresponse.responseData[i].inventoryNumber,
                  serialNumber:
                    deliveryreceiptresponse.responseData[i].serialNumber,
                  inventoryDesc:
                    deliveryreceiptresponse.responseData[i].inventoryDesc,
                };
              }
              TempArr.push(obj);
            }
          }
          let generatereceiptpayload: any;
          if (isCustomerorderenabled === true) {
            generatereceiptpayload = {
              action: 2,
              inventorydescription: TempArr,
              customerName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerName
                  : "",
              addressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].addressLine1
                  : "",
              addressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].addressLine2
                  : "",
              customerCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerCity
                  : "",
              customerZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].customerZip !== null &&
                  deliveryreceiptresponse.responseData[0].customerPlus4 !== null
                  ? deliveryreceiptresponse.responseData[0].customerZip +
                  "-" +
                  deliveryreceiptresponse.responseData[0].customerPlus4
                  : deliveryreceiptresponse.responseData[0].customerZip,
              customerState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerState
                  : "",

              storeName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeName
                  : "",
              storeNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeNumber
                  : "",
              storeAddressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine1
                  : "",
              storeAddressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
                  null &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine2
                  : "",
              storeCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeCity
                  : "",
              storeState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeState
                  : "",
              storeZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeZip
                  : "",
              deliveryDate: DeliveryDate,
              agreementNumber: agreementNum,
            };
          } else {
            generatereceiptpayload = {
              ...(actionUpdate == "3" && {
                action: 3,
              }),
              inventorydescription: TempArr,
              customerName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerName
                  : "",
              addressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].addressLine1
                  : "",
              addressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].addressLine2
                  : "",
              customerCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerCity
                  : "",
              customerZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].customerZip !== null &&
                  deliveryreceiptresponse.responseData[0].customerPlus4 !== null
                  ? deliveryreceiptresponse.responseData[0].customerZip +
                  "-" +
                  deliveryreceiptresponse.responseData[0].customerPlus4
                  : deliveryreceiptresponse.responseData[0].customerZip,
              customerState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerState
                  : "",
              customerPhoneNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
                  : "",

              storeName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeName
                  : "",
              storeNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeNumber
                  : "",
              storeAddressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine1
                  : "",
              storeAddressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
                  null &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine2
                  : "",
              storePhoneNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storePhoneNumber
                  : "",
              storeCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeCity
                  : "",
              storeState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeState
                  : "",
              storeZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeZip
                  : "",
              nexDueAmount:
                AmountResponse.amountDue !== undefined &&
                  AmountResponse.amountDue !== null &&
                  AmountResponse.ldwAmount !== undefined &&
                  AmountResponse.ldwAmount !== null
                  ? (
                    parseFloat(AmountResponse.amountDue) +
                    parseFloat(AmountResponse.ldwAmount)
                  )

                    .toFixed(2)
                    .toString()
                  : getamountresponse.data.amountDueResponse[0].amountDue.toString(),

              deliveryDate: DeliveryDate,
              deliveryTime: DeliveryTime,
              agreementNumber: agreementNum,
              agreementId: Number(agreementId),
              customerId: customerId,
            };
          }

          generatereceippdf(generatereceiptpayload);
        }
      }
    });
  };
  const phoneNumberFormat = (phonenum: any) => {
    if (!phonenum) return '-';
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const phoneNumberFormat9prefix = (phonenum: any) => {
    if (!phonenum) return '-';
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `9 (${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const Check_Sign_Reciept = async (value: string) => {
    setmasterLoader(true);
    console.log("fixed receipt isssues", checksignresponse);

    if (
      checksignresponse !== undefined &&
      checksignresponse !== null &&
      checksignresponse.isSigned == "0" &&
      value == "Delivery"
    ) {
      setmasterLoader(false);
      SetCheckforSign(true);
    } else if (
      checksignresponse !== undefined &&
      checksignresponse !== null &&
      checksignresponse.isSigned === "1" &&
      value == "Delivery"
    ) {
      agreementsignedmethod();
    } else if (checksignresponse && value == "Pickup") {
      agreementsignedmethod("3");
    }
  };

  const Ok_Popup = () => {
    Setdisableconfirmdelivery(false);
    SetOpenprintpopup(false);
    if (isTireBuyerFlow) {
      setIsSendDeliveryreceiptEnabled(true);
    }
  };
  const Okpopup = () => {
    Setdisableconfirmdelivery(false);
    setopenCOprintpopup(false);
  };

  const Confirm_delivery_Popup = () => {
    SetOpendeliverypopup(true);
  };

  const Yes_sign_reciept = async () => {
    SetOpendeliverypopup(false);
    setmasterLoader(true);
    if (
      deliveryMethodData?.deliveryInformation?.deliveryStatus == "Cancelled"
    ) {
      const value = {
        agreementId: agreementId,
        isLoaner: "Y",
        loanerAction: {
          appointmentId: String(appointmentId),
          storeNumber: window.sessionStorage.getItem("storeNumber"),
          actionName: "Reopen",
          coWorkerId: employeeId,
        },
      };
      const result = await Confirmdelivery(value);
    }
    const updatedeliverystatus = {
      agreementId: agreementId,
    };
    const updatesign = {
      agreementId: agreementId,
      value: 1,
    };
    let updatestatusresponse: any = {
      updatedata: {},
    };

    let updatesignresponse: any = {
      signdata: {},
    };

    await Promise.all([
      Confirmdelivery(updatedeliverystatus),
      Recieptsign(updatesign),
    ]).then((resp) => {
      [updatestatusresponse, updatesignresponse] = resp;
    });
    console.log("delivery confirmed", updatestatusresponse);
    console.log("sign updated", updatesignresponse);
    if (
      updatestatusresponse.data !== undefined &&
      updatestatusresponse.data !== null &&
      updatestatusresponse.data.statusMessage !== undefined &&
      updatestatusresponse.data.statusMessage !== null
    ) {
      setmasterLoader(false);
      SetOpendeliveryconfirmpopup(true);
      setmasterLoader(true);
      const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
      setviewAgrData(agreementInfoUpdatedResult);
      setmasterLoader(false);
      setviewAgreementData(agreementInfoUpdatedResult.data);
      setreceiptsignresponse(updatesignresponse.data.statusMessage);
    } else {
      setdeliveryerrorpopup(true);
    }
  };
  const Close_sign_reciept = () => {
    SetOpendeliverypopup(false);
  };
  const opendeliveryreceipt = () => {
    SetCheckforSign(false);
  };

  const Delivery_confirmed_ok = async () => {
    debugger
    if (['CI', 'P'].includes(returnAppiontmentStatus)) {
      Pickup_Confirmed_Ok()
    }
    setIsDeliveryConfirmed(true);
    SetOpendeliveryconfirmpopup(false);
    props.triggerpageViewController();
    SetdeliveryMethodFooter("deliveryScheduled");
    setdeliveryMethodData({
      ...deliveryMethodData,
      deliveryMethodStatus: "1",
    });
    window.location.reload();
  };
  const Delivery_confirmed_false = () => {
    setdeliveryerrorpopup(false);
  };

  const normalizeinput = (phonenum: any) => {
    if (phonenum == "") return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, "");

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };

  const SignCheckPopup = () => {
    return (
      <Grid className={classes.p2}>
        {masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Agreement# {agreementNum} has been unsigned. Do you like to sign the
            agreement to continue?{" "}
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            onClick={() => opendeliveryreceipt()}
            variant="outlined"
            color="primary"
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => ReprintPopupOpen(agreementId)}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const PrintPopupOpen = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              {isReturnStart || returnReschedule
                ? "Pickup Receipt"
                : "Delivery Receipt"}
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() => {
                isReturnStart || returnReschedule
                  ? printHTML(document.getElementById("pickupreciptPrintHTML"))
                  : printHTML(
                    document.getElementById("DeliveryReceiptrentalPrintHTML")
                  );
              }}
            >
              View PDF
            </RACButton>
          </Grid>
          {!isTireBuyerFlow ?
            <Grid item md={12}>
              <PDFImage className={classes.me2}></PDFImage>
              <Typography variant="caption" className={classes.formLabel}>
                {isReturnStart || returnReschedule
                  ? "Pickup Checklist"
                  : "Delivery Checklist"}
              </Typography>
              <RACButton
                className={classes.agrPrintDoc}
                onClick={() =>
                  isReturnStart || returnReschedule
                    ? printHTML(
                      document.getElementById("pickupchecklistprintHTML")
                    )
                    : printHTML(
                      document.getElementById("DeliveryChecklistrentalPdf")
                    )
                }
              >
                View PDF
              </RACButton>
            </Grid> :
            null}

        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => Ok_Popup()}
          >
            {!isTireBuyerFlow ? 'OK' : 'Continue'}
          </RACButton>
        </Grid>
      </div>
    );
  };

  const DeliveryPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Did the customer sign the delivery receipt?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={Close_sign_reciept}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={Yes_sign_reciept}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const DeliveryConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Delivery has been confirmed.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={Delivery_confirmed_ok}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPolicyPop = () => {
    props.triggeropenPolicyPop();
  };

  const deliveryerrorconfirm = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Delivery is not confirmed
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={Delivery_confirmed_false}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const deliveryerror = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Something went wrong
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setdeliveryconfirmerror(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const deliveryCancel = () => {
    if (redirectOrigin === AMOrigin) {
      history.push({
        pathname: `${AppRoute.AMCustomer}/${customerId}`,
        state: location?.state,
      });
      return;
    }
    history.push({
      pathname: `/customer/searchcustomer`,
    });
  };

  const saveReschedule = (value: any) => {
    console.log("saveeeeee", value);
    props.reschedulefunc({ data: true });
  };

  const submitReschedule = (value: any) => {
    console.log("saveeeeee", value);
    setisReturnStart(true);

    props.reschedulefunc({ data: true });
  };
  const switchoutconfirmedok = () => {
    setswitchoutdelievry(false);
    setIsDeliveryConfirmed(true);
    props.triggerpageViewController();
    SetdeliveryMethodFooter("deliveryScheduled");
    setdeliveryMethodData({
      ...deliveryMethodData,
      deliveryMethodStatus: "1",
    });
    sethideswitchoutbutton(true);
  };

  const switchoutokclick = () => {
    setprintswitchoutpopup(false);
    setdisableconfirmswitchout(false);
  };
  const generatereceiptpdf = async (generatereceiptpayload: any) => {
    // console.log("view payload", generatereceiptpayload);

    // const receiptresponse = await GenerateSwitchoutReceipt(
    //   generatereceiptpayload
    // );

    // if (receiptresponse.data !== undefined && receiptresponse.data !== null) {
    //   setpdfresponse(receiptresponse.data);
    //   console.log("delievry checklist,pickup receipt", receiptresponse.data);
    // }
    let deliveryReceiptElement: any
    let deliveryChecklistElement: any
    let pickupReceiptElement: any
    let pickChecklistElement: any
    deliveryReceiptElement = document.getElementById(
      'DeliveryReceiptPrintHTML'
    );
    deliveryChecklistElement = document.getElementById(
      'DeliveryChecklistPdf'
    );
    pickupReceiptElement = document.getElementById(
      'pickupreciptPrintHTML'
    );
    pickChecklistElement = document.getElementById(
      'pickupchecklistprintHTML'
    );

    html2pdf()
      .from(deliveryReceiptElement)
      .outputPdf('blob')
      .then((pdfBlob) => {
        const DRreader: any = new FileReader();
        console.log('reader deliveryReceipt', DRreader);
        DRreader.onloadend = async () => {
          console.log('after Reader deliveryReceipt', DRreader);
          const b64DeliveryReceipt = DRreader?.result.split(',')[1];
          setDeliveryReceiptB64(b64DeliveryReceipt);
          //delivery check list flow
          html2pdf()
            .from(deliveryChecklistElement)
            .outputPdf('blob')
            .then((pdfBlob) => {
              const CLreader: any = new FileReader();
              console.log('reader deliveryChecklist', CLreader);
              CLreader.onloadend = async () => {
                console.log('after Reader deliveryReceipt', CLreader);
                const b64DeliveryCheckList = CLreader?.result.split(',')[1];
                setDeliveryCheckListB64(b64DeliveryCheckList);
                html2pdf()
                  .from(pickupReceiptElement)
                  .outputPdf('blob')
                  .then((pdfBlob) => {
                    const PRreader: any = new FileReader();
                    console.log('reader deliveryReceipt', PRreader);
                    PRreader.onloadend = async () => {
                      console.log('after Reader deliveryReceipt', PRreader);
                      const b64PickUpReceipt = PRreader?.result.split(',')[1];
                      html2pdf()
                        .from(pickChecklistElement)
                        .outputPdf('blob')
                        .then((pdfBlob) => {
                          const PCreader: any = new FileReader();
                          console.log('reader deliveryReceipt', PCreader);
                          PCreader.onloadend = async () => {
                            console.log('after Reader deliveryReceipt', PCreader);
                            const b64PickupCheckList = PCreader?.result.split(',')[1];
                            makeUploadDocumentSwitchout(b64DeliveryReceipt, b64DeliveryCheckList, b64PickUpReceipt, b64PickupCheckList);
                          };
                          PCreader.readAsDataURL(pdfBlob);
                          // 
                        });

                    };
                    PRreader.readAsDataURL(pdfBlob);
                    // 
                  });
              }
              CLreader.readAsDataURL(pdfBlob);
            });
          //end of check list flow
        };
        DRreader.readAsDataURL(pdfBlob);
      });
  };
  const makeUploadDocumentSwitchout = async (
    deliveryReceiptFile?: any,
    deliveryCheckListFile?: any,
    pickupReceiptFile?: any,
    pickupChecklistFile?: any
  ) => {
    debugger;
    const uploadDeliveryReceiptPayload = {
      action: 1,
      agreementId: Number(agreementId),
      documents: [
        {
          documentType: 'DLRCT',
          file: deliveryReceiptFile,
        },
        {
          documentType: 'DCL',
          file: deliveryCheckListFile,
        },
        {
          documentType: 'PKRCT',
          file: pickupReceiptFile,
        },
        {
          documentType: 'PCL',
          file: pickupChecklistFile,
        },
      ],
    };
    const uploadDeliveryReceiptResponse = await GenerateSwitchoutReceipt(
      uploadDeliveryReceiptPayload
    )
  };


  const Coprintpopupopen = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Receipt
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(
                  document.getElementById("DeliveryReceiptrentalPrintHTML")
                )
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Checklist
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("DeliveryChecklistrentalPdf"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Addendum
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("deliveryaddendum"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => Okpopup()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const switchoutbuttonclick = async () => {
    setmasterLoader(true);
    const apiResponse: any = await CheckSign(agreementId);
    console.log("response", apiResponse);
    if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned == "0"
    ) {
      setmasterLoader(false);
      SetCheckforSign(true);
    } else if (
      apiResponse !== undefined &&
      apiResponse !== null &&
      apiResponse.isSigned === "1"
    ) {
      const bodyobj = {
        agreementIdCustomerId: [
          {
            agreementId: parseInt(agreementId),
          },
        ],
      };
      let getamountresponse: any = {
        getamountdata: {},
      };

      let deliveryreceiptresponse: any = {
        deliverydata: {},
      };

      await Promise.all([
        getnextpaymentamount(bodyobj),
        LoadswitchoutDeliveryreceipt(agreementId),
      ]).then((resp) => {
        [getamountresponse, deliveryreceiptresponse] = resp;

        console.log("asynchronous api calls", getamountresponse);
        console.log(
          "asynchronous api calls for reprint",
          deliveryreceiptresponse
        );
        console.log("responseData", deliveryreceiptresponse.responseData);
        console.log(
          "amountdueresponse",
          getamountresponse.data.amountDueResponse
        );

        if (
          deliveryreceiptresponse !== undefined &&
          deliveryreceiptresponse.responseData !== undefined
        ) {
          setmasterLoader(false);

          setprintswitchoutpopup(true);

          const resUniqueValue: any =
            deliveryreceiptresponse.responseData.filter((obj, index) => {
              return (
                index ===
                deliveryreceiptresponse.responseData.findIndex(
                  (o) => obj.inventoryId === o.inventoryId
                )
              );
            });
          const swithOutAry: any[] = []
          swithOutAry.push(deliveryreceiptresponse?.responseData[0]?.switchOutItem)
          console.log("swithOutAry", swithOutAry);
          setitemdata(swithOutAry);

          console.log("view response", deliveryreceiptresponse);

          Setstorename(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeName
              : ""
          );

          SetstoreNumber(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeNumber
              : ""
          );
          Setstorestate(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeState
              : ""
          );
          Setstorezip(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeZip
              : ""
          );

          Setstorecity(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeCity
              : ""
          );
          SetStorephonenum(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storePhoneNumber
              : ""
          );
          SetStoreAddressline1(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeAddressLine1
              : ""
          );
          SetStoreAdressline2(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].storeAddressLine2
              : ""
          );
          SetCustomerName(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerName
              : ""
          );
          SetCustomerAddressline1(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].addressLine1
              : ""
          );
          SetCustomerAddressline2(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].addressLine2
              : ""
          );
          Setcustomercity(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerCity
              : ""
          );
          SetCustomerstate(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerState
              : ""
          );

          Setcustomerzip(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerZip
              : ""
          );

          Setcustomerplus4(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerPlus4
              : ""
          );
          SetCustomerPhoneNumber(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
              : ""
          );
          SetAgreementId(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].agreementNumber
              : ""
          );
          SetCustomerId(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].customerId
              : ""
          );
          setinventorynumber(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchOutItem
                .inventoryNumber
              : ""
          );
          setinventorydesc(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchOutItem
                .inventoryDesc
              : ""
          );
          setinventoryserialnum(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchOutItem
                .serialNumber
              : ""
          );
          setinventoryserialnumswithchin(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchInItem
                .serialNumber
              : ""
          );
          setinventorydescswitchin(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchInItem
                .inventoryDesc
              : ""
          );
          setinventorynumberswitchinitem(
            deliveryreceiptresponse !== undefined &&
              deliveryreceiptresponse.responseData !== undefined &&
              deliveryreceiptresponse.responseData.length > 0
              ? deliveryreceiptresponse.responseData[0].switchInItem
                .inventoryNumber
              : ""
          );
        }

        if (getamountresponse.status === 504) {
          setmasterLoader(false);
        } else {
          if (
            getamountresponse.data !== undefined &&
            getamountresponse.data !== null &&
            getamountresponse.data.amountDueResponse !== undefined &&
            getamountresponse.data.amountDueResponse !== null
          ) {
            const AmountResponse =
              getamountresponse.data.amountDueResponse.find(
                (x: { agreementId: string }) => x.agreementId == agreementId
              );
            console.log("agreementid related data", AmountResponse);

            if (
              AmountResponse !== undefined &&
              AmountResponse !== null &&
              AmountResponse !== "" &&
              AmountResponse.ldwAmount !== undefined &&
              AmountResponse.ldwAmount !== null
            ) {
              const Totalamountdue =
                parseFloat(AmountResponse.amountDue) +
                parseFloat(AmountResponse.ldwAmount);
              /* eslint-disable no-console */
              console.log("agreementid Total Amount", Totalamountdue);
              setnextpaymentinfo({
                ...nextpaymentinfo,
                nextamountdue: Totalamountdue.toFixed(2),
                nextduedate: AmountResponse.nextDueDate,
              });
              console.log("next amount due", nextpaymentinfo);
            } else {
              console.log("nextpaymentamount", getamountresponse.data);

              setnextpaymentinfo({
                ...nextpaymentinfo,
                nextamountdue:
                  getamountresponse.data.amountDueResponse[0].amountDue,
                nextduedate:
                  getamountresponse.data.amountDueResponse[0].nextDueDate,
              });
              console.log("next amount due", nextpaymentinfo);
              console.log(
                "getamountdue response",
                getamountresponse.data.amountDueResponse[0].amountDue
              );
            }

            const generatereceiptpayload = {
              action: 1,
              switchInItem: {
                inventoryNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchInItem
                      .inventoryNumber
                    : "",
                inventoryDesc:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchInItem
                      .inventoryDesc
                    : "",
                serialNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchInItem
                      .serialNumber
                    : "",
                inventoryId:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchInItem.inventoryId.toString()
                    : "",
              },
              switchOutItem: {
                inventoryNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchOutItem
                      .inventoryNumber
                    : "",
                inventoryDesc:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchOutItem
                      .inventoryDesc
                    : "",
                serialNumber:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchOutItem
                      .serialNumber
                    : "",
                inventoryId:
                  deliveryreceiptresponse !== undefined &&
                    deliveryreceiptresponse.responseData !== undefined &&
                    deliveryreceiptresponse.responseData.length > 0
                    ? deliveryreceiptresponse.responseData[0].switchOutItem.inventoryId.toString()
                    : "",
              },

              customerName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerName
                  : "",
              addressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].addressLine1
                  : "",
              addressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== null &&
                  deliveryreceiptresponse.responseData[0].addressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].addressLine2
                  : "",
              customerCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerCity
                  : "",
              customerZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].customerZip !== null &&
                  deliveryreceiptresponse.responseData[0].customerPlus4 !== null
                  ? deliveryreceiptresponse.responseData[0].customerZip +
                  "-" +
                  deliveryreceiptresponse.responseData[0].customerPlus4
                  : deliveryreceiptresponse.responseData[0].customerZip,
              customerState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerState
                  : "",
              customerPhoneNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].customerPhoneNumber
                  : "",
              storeName:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeName
                  : "",
              storeNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeNumber
                  : "",
              storeAddressLine1:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine1
                  : "",
              storeAddressLine2:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0 &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !==
                  null &&
                  deliveryreceiptresponse.responseData[0].storeAddressLine2 !== ""
                  ? deliveryreceiptresponse.responseData[0].storeAddressLine2
                  : "",
              storeCity:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeCity
                  : "",
              storeState:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeState
                  : "",
              storeZip:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storeZip
                  : "",
              storePhoneNumber:
                deliveryreceiptresponse !== undefined &&
                  deliveryreceiptresponse.responseData !== undefined &&
                  deliveryreceiptresponse.responseData.length > 0
                  ? deliveryreceiptresponse.responseData[0].storePhoneNumber
                  : "",
              agreementId: parseInt(agreementId),
              customerId: customerId,
              deliveryDate: DeliveryDate,
              deliveryTime: DeliveryTime,
              agreementNumber: agreementNum,
              nexDueAmount:
                AmountResponse.amountDue !== undefined &&
                  AmountResponse.amountDue !== null &&
                  AmountResponse.ldwAmount !== undefined &&
                  AmountResponse.ldwAmount !== null
                  ? (
                    parseFloat(AmountResponse.amountDue) +
                    parseFloat(AmountResponse.ldwAmount)
                  )

                    .toFixed(2)
                    .toString()
                  : getamountresponse.data.amountDueResponse[0].amountDue.toString(),
              nextDueDate:
                getamountresponse.data.amountDueResponse[0].nextDueDate,
              backRent: " $ 0.00",
            };
            generatereceiptpdf(generatereceiptpayload);
          }
        }
      });
    }
  };

  const switchoutconfirm = () => {
    setconfirmswitchoupopup(true);
  };

  const Yesconfirmswitchout = async () => {
    setconfirmswitchoupopup(false);
    setmasterLoader(true);
    const updatestatus = {
      agreementId: agreementId,
      isSwitchOut: 1,
      switchInItem: Number(newinventoryId),
    };
    const updatestatusresponse = await Confirmswitchout(updatestatus);

    console.log("view updatestatusresponse", updatestatusresponse);
    setmasterLoader(false);
    if (
      updatestatusresponse.data !== undefined &&
      updatestatusresponse.data !== null &&
      updatestatusresponse.data.statusMessage !== undefined
    ) {
      setmasterLoader(true);
      const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
      setviewAgrData(agreementInfoUpdatedResult);
      setviewAgreementData(agreementInfoUpdatedResult.data);
      setmasterLoader(false);
      setswitchoutdelievry(true); //Logger
    } else {
      setswitchouterror(true);
    }
  };

  const CancelConfirmation = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Are you sure, you want to cancel the Switchout?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => SetCancelSwitchOut(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={ConfirmSwitchoutCancel}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const CancelConfirmationInDelivery = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Are you Sure, you want to cancel the Switchout?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setCancelSwitchOutInViewDelivery(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={ConfirmSwitchoutCancelInDelivery}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ConfirmSwitchoutCancelInDelivery = async () => {
    setCancelSwitchOutInViewDelivery(false);
    setmasterLoader(true);
    const SwitchoutCancel = {
      action: "CSO",
      agreementId: Number(agreementId),
      switchOut: {
        newInventoryInfo: {
          inventoryId: Number(newinventoryId),
          inventoryPriceId: null,
        },
      },
    };
    console.log("Dummy", SwitchoutCancel);
    const Cancelresponse = await UpdateAgreement(SwitchoutCancel);
    console.log("Cancelled Response", Cancelresponse);
    setmasterLoader(false);
    if (Cancelresponse.status === 200) {
      console.log("cancel the switchout button", hideswitchoutbutton);
      console.log("disable shduehie", SwitchOutDisable);
      sethideswitchoutbutton(true);
      setdeliveryMethodData(undefined);
      SetSwitchOutDisable(false);
      setmasterLoader(true);
      const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
      setmasterLoader(false);
      setviewAgrData(agreementInfoUpdatedResult); ///Reset Context
      setviewAgreementData(agreementInfoUpdatedResult.data);
      setaddedItem(undefined);
      setsearchResult(undefined);
      setitemArray(undefined);
      setSwitchoutcopyArrayData(undefined);
      setcontainSubDepartment(undefined);
      setcontainCondition(undefined);
      setagreementCreateStatus({ agreementCreatedStatus: false });
      setpendingSOAgr(false);
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agreementId}`,
      });
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (Cancelresponse.status === 400 || Cancelresponse.status === 500) {
      setswitchouterror(true);
    }
  };

  const ConfirmSwitchoutCancel = async () => {
    SetCancelSwitchOut(false);
    setmasterLoader(true);
    const SwitchoutCancel = {
      action: "CSO",
      agreementId: Number(agreementId),
      switchOut: {
        newInventoryInfo: {
          inventoryId: Number(newinventoryId),
          inventoryPriceId: null,
        },
      },
    };

    console.log("Dummy", SwitchoutCancel);
    const Cancelresponse = await UpdateAgreement(SwitchoutCancel);
    console.log("Cancelled Response", Cancelresponse);
    setmasterLoader(false);
    if (Cancelresponse.status === 200) {
      console.log("cancel the switchout button", hideswitchoutbutton);
      console.log("disable shduehie", SwitchOutDisable);
      sethideswitchoutbutton(true);
      setdeliveryMethodData(undefined);
      SetSwitchOutDisable(false);
      props.triggerconfirmedCancelSwitchout();
      ///Reset Context
      setaddedItem(undefined);
      setsearchResult(undefined);
      setitemArray(undefined);
      setSwitchoutcopyArrayData(undefined);
      setcontainSubDepartment(undefined);
      setcontainCondition(undefined);
      setagreementCreateStatus({ agreementCreatedStatus: false });
      setpendingSOAgr(false);
    } else if (Cancelresponse.status === 400 || Cancelresponse.status === 500) {
      setswitchouterror(true);
    }
  };

  const SOTwoFactorReturnCompleted = (e: any) => {
    if (e) {
      setSOTwoFactorReturnEnabled(false);
      setreturnbtnRdn(true);
      setconfirmFlow(false);
    }
  };
  const switchoutpopup = () => {
    return (
      <div>
        <Grid container spacing={2} className={classes.p3}>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Receipt
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("DeliveryReceiptPrintHTML"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <PDFImage className={classes.me2}></PDFImage>
            <Typography variant="caption" className={classes.formLabel}>
              Delivery Checklist
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("DeliveryChecklistPdf"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              Pickup Receipt
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("pickupreciptPrintHTML"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
          <Grid item md={12}>
            <Images className={classes.me2}></Images>
            <Typography variant="caption" className={classes.formLabel}>
              Pickup Checklist
            </Typography>
            <RACButton
              className={classes.agrPrintDoc}
              onClick={() =>
                printHTML(document.getElementById("pickupchecklistprintHTML"))
              }
            >
              View PDF
            </RACButton>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => switchoutokclick()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const confirmswitchoutdelivery = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Are you sure,you want to confirm Switchout delivery?
          </Typography>
        </Grid>

        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setconfirmswitchoupopup(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
            onClick={() => Yesconfirmswitchout()}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const deliveryswitchout = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Switchout has been confirmed.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => switchoutconfirmedok()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const deliveryswitchouterror = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Alert></Alert>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Something Went Wrong
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setswitchouterror(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const Pickup_Confirmed_Ok = () => {
    setreturnbtnRdn(true);
    setconfirmFlow(true);
    setprintRestrict(true);
    SetOpenPickupconfirmpopup(false);
    props.triggerpageViewController();
  };

  const ConfirmReturnPickup = async () => {
    setmasterLoader(true);

    const confirmPickPayload = {
      storeNumber: storeNo,
      agreementId: agreementId,
      type: "event",
      isCompleteEvent: true,
      eventId: newAppointmentId
        ? String(newAppointmentId)
        : String(deliveryMethodData?.deliveryInformation.eventId),
    };
    const updateReturnresponse = await CancelPickupStatus(confirmPickPayload);
    console.log("Return confirmed", updateReturnresponse);

    if (
      updateReturnresponse.status == 200 &&
      updateReturnresponse.data != undefined
    ) {
      setmasterLoader(false);
      SetOpenPickupconfirmpopup(true);
      SetReturnReschedule(false);
      setdeliveryMethodData(undefined);
      setIsReturn(false);
      setisReturnStart(false);
      props.returnpageViewController();
      setmasterLoader(true);
      const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
      setviewAgrData(agreementInfoUpdatedResult);
      setmasterLoader(false);
      setviewAgreementData(agreementInfoUpdatedResult.data);
    } else {
      setdeliveryerrorpopup(true);
    }
  };
  const CancelConfirmationInPickup = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Are you sure, you want to cancel the Pickup?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => setcancelReturnPickup(false)}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={ConfirmReturnCancelPickup}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };
  const AgrInfoAPI = () => {
    props.AgrInfo();
  };
  const ConfirmReturnCancelPickup = async () => {
    setcancelReturnPickup(false);
    setmasterLoader(true);
    const cancelPayload = {
      storeNumber: storeNo,
      agreementId: agreementId,
      type: "event",
      isCompleteEvent: false,
      eventId: String(deliveryMethodData?.deliveryInformation.eventId),
    };
    console.log("Dummy", cancelPayload);
    const Cancelresponse = await CancelPickupStatus(cancelPayload);
    console.log("Cancelled Response", Cancelresponse);
    setmasterLoader(false);
    window.location.reload()
    // if (Cancelresponse.status === 200) {
    //   console.log("cancel the switchout button", hideswitchoutbutton);
    //   console.log("disable shduehie", SwitchOutDisable);
    //   setdeliveryMethodData(undefined);
    //   SetSwitchOutDisable(false);
    //   setmasterLoader(true);
    //   const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
    //   setmasterLoader(false);
    //   setviewAgrData(agreementInfoUpdatedResult);
    //   setviewAgreementData(agreementInfoUpdatedResult.data);
    //   setaddedItem(undefined);

    //   SetReturnReschedule(false);
    //   setIsReturn(false);
    //   setisReturnStart(false);
    //   props.returnpageViewController();
    //   setsearchResult(undefined);
    //   setitemArray(undefined);
    //   setSwitchoutcopyArrayData(undefined);
    //   setcontainSubDepartment(undefined);
    //   setcontainCondition(undefined);
    //   setagreementCreateStatus({ agreementCreatedStatus: false });
    //   setpendingSOAgr(false);
    // } else if (Cancelresponse.status === 400 || Cancelresponse.status === 500) {
    //   setswitchouterror(true);
    // }
  };
  const InvisbleFunc = () => {
    setReturnComplete(true);
  };
  const ReturnStableFunc = () => {
    setreturnbtnRdn(false);
  };

  const InstallmentNoteFunc = () => {
    setinstallNoteComponent(false);
  };

  const PickupConfirmPopupOpen = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography className={`${classes.popupText} ${classes.mt3}`}>
            Pickup has been confirmed.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={Pickup_Confirmed_Ok}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const DeliveryCompletedPopContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Please reverse the payment to return the agreement?
          </Typography>
        </Grid>
        {/* <Typography>
          <RACInfoIcon iconClassname={classes.ReturnInfoIcon} />
          Please reverse the payment in SIMS to proceed the return
        </Typography> */}
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            onClick={() => {
              SetReturnDeliveryCompleted(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              SetReturnDeliveryCompleted(false);
              setReverseConfirmation(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const ReversePaymentConfirmationContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Do you want to reverse the payment?
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setReverseConfirmation(false);
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setReverseConfirmation(false);
              history.push({
                pathname: `/payment1/reversepayment/${customerId}`,
                search: `?agreementId=${agreementId}`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </div>
    );
  };

  const ReturnSORPopContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Atleast one of agreement items are in service. Agreement cannot be
            returned at this time.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setReturnSORPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const RefundSecurityDeposit = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            This agreement has a security deposit that not been returned to the customer. Please return the security before returning the agreement.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setRefundSDPopup(false);
              history.push({
                pathname: `/payment1/paymentinformation/${customerId}/0`,
              });
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SwitchoutSORPopContent = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Selected Inventory Item is in service. Please complete the service
            before switchout.
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSwitchOutSORPopup(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const formatPhone = (input: any) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    //This is raw data to pass on props
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };
  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return mailformat.test(inputText);
  }
  const welcomeValidateOnChange = (e: any) => {
    if (e.target.name == "phoneno") {
      const cleaned = ("" + e.target.value).replace(/\D/g, "");
      console.log("cleaned", cleaned.length);
      if (cleaned.length <= 10) {
        console.log("njnjnjn");

        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const phoneFormat = "($1) $2-$3";
        const temp = cleaned.replace(PhoneRegex, phoneFormat);
        setphoneNo(temp);
        setphoneErrMsg("");
        temp == "" || temp == undefined ? setphoneErrMsg("") : null;
      }
    } else if (e.target.name == "Email") {
      if (e.target.value == "") {
        //setemailErrMsg(true)
        setemailText(e.target.value);
        // setwelcomeEnable(true);
      } else {
        if (!ValidateEmail(e.target.value)) {
          setemailErrMsg("Please enter valid Email");
          setwelcomeEnable(true);
          setemailText(e.target.value);
        } else {
          setwelcomeEnable(false);
          setemailErrMsg("");
          setemailText(e.target.value);
        }

        // setwelcomeEnable(false)
      }
    }
  };

  const ResendVideoFinishClick = async () => {
    if (phoneNo.length <= 9 && !sendEmail) {
      if (phoneNo == "") {
        setphoneErrMsg("Please enter phone number");
      } else {
        setphoneErrMsg("Please enter valid phone number");
      }
    } else if (emailText == "" && sendEmail) {
      setemailErrMsg("Please enter email");
    } else {
      setphoneErrMsg("");
      setemailErrMsg("");
      setResendVopen(false);
      console.log("emailTextojjkj", emailText);
      console.log("phoneNojkjkj", phoneNo);
      const refCode = sendEmail ? "SSKYES" : "SSKYTS";
      const payload = {
        customerId: customerId,
        agreementId: agreementId,
        emailId: emailText,
        phoneNumber: phoneNo?.replace(/\D/g, ""),
        isSendMail: true,
        sendEmailType: refCode,
      };
      setmasterLoader(true);
      const response = await sendEPO(payload);
      console.log("responsesendEPO", response);

      if (response.statusCode == 200) {
        setmasterLoader(false);
        setResendsuccess(true);
        const agrInfoData = agreementInfoData;
        agrInfoData.agreementWorkflow.isWelcomeVideoSent = "1";
        agrInfoData.welcomeVideoInfo.activityDate = moment(new Date()).format(
          "DD/MM/YYYY"
        );
        agrInfoData.welcomeVideoInfo.activityDesc = `Text was sent to ${sendEmail ? emailText : phoneNo?.replace(/\D/g, "")
          }`;
        agrInfoData.welcomeVideoInfo.activityRefCode = refCode;

        console.log("agrInfoData destructure", agreementInfoData);

        setAgreementInfoData(agrInfoData);
      }
    }
  };
  const sendAgreementPopup = () => {
    return (
      <Grid className={classes.p3}>
        <Grid container>
          <Grid item md={12} className={classes.mb3}>
            <Typography
              component="label"
              className={`${classes.formLabel} ${classes.w100} ${classes.fontBold}`}
            >
              How do you want the welcome video to be shared?
            </Typography>
          </Grid>
          <Grid item md={12} className={classes.mb3}>
            <RACRadio
              value={sendEmail}
              checked={sendEmail}
              onChange={() => {
                setsendEmail(true);
                settextMsg(false);
              }}
              label="Email"
            />
            <RACRadio
              value={textMsg}
              checked={textMsg}
              onChange={() => {
                setsendEmail(false);
                settextMsg(true);
                setwelcomeEnable(false);
              }}
              label="Text Message"
            />
          </Grid>
          <Grid item md={6}>
            {sendEmail ? (
              <div>
                <RACTextbox
                  inputlabel="Email"
                  inputLabelClassname="form-label"
                  name="Email"
                  value={emailText != undefined ? emailText : ""}
                  OnChange={(e) => welcomeValidateOnChange(e)}
                  isCurrency={false}
                  type="email"
                  errorMessage={emailErrMsg}
                />
              </div>
            ) : (
              <div>
                <RACTextbox
                  inputlabel="Phone Number"
                  inputLabelClassname="form-label"
                  name="phoneno"
                  value={phoneNo != undefined ? formatPhone(phoneNo) : ""}
                  OnChange={(e) => welcomeValidateOnChange(e)}
                  isCurrency={false}
                  type="text"
                  errorMessage={phoneErrMsg}
                />
              </div>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.pb4} ${classes.px3} ${classes.textRight}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.mx1}
            name="SendAgreementCancel"
            onClick={() => {
              setResendVopen(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={welcomeEnable}
            className={classes.mx1}
            name=""
            onClick={() => ResendVideoFinishClick()}
          >
            Finish
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const resumeSuccessPopup = () => {
    return (
      <div>
        <Grid item md={12} className={classes.textCenter}>
          <Successimage></Successimage>
          <Typography
            className={`${classes.agrDeliveryReceiptText} ${classes.semiBold}`}
          >
            Welcome video sent
          </Typography>
        </Grid>
        <Grid item md={12} className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setResendsuccess(false);
              setsendEmail(true);
              settextMsg(false);
              //setrepeatContent(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const bindChecklist = (source?: any) => {
    if (itemdata !== undefined && itemdata !== null && itemdata.length > 0) {
      let data: any = itemdata;
      console.log("repeatContent", repeatContent);
      console.log("source", source);
      console.log("resumebtnFeatureFlag", resumebtnFeatureFlag);
      if (
        source == "ResendVideo" && featureFlagDetails?.SundaySky == '1'
      ) {
        console.log("agreddfementInsgfoDatahbhbhb", agreementInfoData);

        const lineItem = {
          inventoryId: "",
          inventoryNumber: "",
          inventoryDesc: "",
          serialNumber: "",
          modelNumber: "",
        };
        // if (agreementInfoData?.agreementWorkflow?.isWelcomeVideoSent == "1") {
        //   const activityDesc =
        //     agreementInfoData?.welcomeVideoInfo?.activityDesc?.split(" ");
        //   if (
        //     agreementInfoData?.welcomeVideoInfo?.activityRefCode == "SSKYES"
        //   ) {
        //     lineItem.inventoryDesc = `Introduction video sent ${agreementInfoData.welcomeVideoInfo.activityDate} to email ${activityDesc[4]}.`;
        //     //setrepeatContent(true);
        //   } else {
        //     lineItem.inventoryDesc = `Introduction video texted ${agreementInfoData.welcomeVideoInfo.activityDate
        //       } to phone number ${agreementInfoData?.welcomeVideoInfo?.activityDesc?.replace(
        //         /\D/g,
        //         ""
        //       )}.`;
        //     //setrepeatContent(true);
        //   }
        // } else {
        //   lineItem.inventoryDesc = "The Introduction video has not been sent.";
        //   //setrepeatContent(true);
        // }

        lineItem.inventoryDesc = ""
        data.push(lineItem);
        console.log("agreementInfoData if data", data);
      } else {
        data = itemdata;
        console.log("agreementInfoData else data", data);
      }
      const uniquedesc = [
        ...new Map(
          data.map((item: any) => [item.inventoryNumber, item])
        ).values(),
      ];
      return uniquedesc.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "15px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                />
                : qrCodeUrl && source ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                /> : ''}

            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? value.inventoryDesc
                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value !== undefined &&
                value.serialNumber !== undefined &&
                value.serialNumber !== null &&
                value.serialNumber != ""
                ? value.serialNumber
                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryNumber ?
                value.inventoryNumber
                :
                qrCodeUrl !== undefined && source ?
                  <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                  : ''
              }

            </td>
          </tr>
        );
      });
    }
  };
  const bindChecklistAddendum = (source?: any) => {
    if (itemdata !== undefined && itemdata !== null && itemdata.length > 0) {
      let data: any = itemdata;
      console.log("repeatContent", repeatContent);
      console.log("source", source);
      console.log("resumebtnFeatureFlag", resumebtnFeatureFlag);
      if (
        source == "ResendVideo" && featureFlagDetails?.SundaySky == '1'
      ) {
        console.log("agreddfementInsgfoDatahbhbhb", agreementInfoData);

        const lineItem = {
          inventoryId: "",
          inventoryNumber: "",
          inventoryDesc: "",
          serialNumber: "",
          modelNumber: "",
        };
        // if (agreementInfoData?.agreementWorkflow?.isWelcomeVideoSent == "1") {
        //   const activityDesc =
        //     agreementInfoData?.welcomeVideoInfo?.activityDesc?.split(" ");
        //   if (
        //     agreementInfoData?.welcomeVideoInfo?.activityRefCode == "SSKYES"
        //   ) {
        //     lineItem.inventoryDesc = `Introduction video sent ${agreementInfoData.welcomeVideoInfo.activityDate} to email ${activityDesc[4]}.`;
        //     //setrepeatContent(true);
        //   } else {
        //     lineItem.inventoryDesc = `Introduction video texted ${agreementInfoData.welcomeVideoInfo.activityDate
        //       } to phone number ${agreementInfoData?.welcomeVideoInfo?.activityDesc?.replace(
        //         /\D/g,
        //         ""
        //       )}.`;
        //     //setrepeatContent(true);
        //   }
        // } else {
        //   lineItem.inventoryDesc = "The Introduction video has not been sent.";
        //   //setrepeatContent(true);
        // }

        lineItem.inventoryDesc = ""
        data.push(lineItem);
        console.log("agreementInfoData if data", data);
      } else {
        data = itemdata;
        console.log("agreementInfoData else data", data);
      }
      const uniquedesc = [
        ...new Map(
          data.map((item: any) => [item.inventoryNumber, item])
        ).values(),
      ];
      return uniquedesc.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "25%",
                textAlign: 'start',
                padding: '10px 0px',
                // paddingLeft: "12px",
                // paddingRight: "12px",
                // paddingTop: "5px",
                // paddingBottom: "5px",
              }}
            >
              {value.inventoryNumber
                ? value.inventoryNumber : ''}
            </td>
            <td
              style={{
                width: "25%",
                textAlign: 'start',
                // paddingLeft: "12px",
                padding: '10px 0px',
                // paddingRight: "12px",
                // paddingTop: "5px",
                // paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? value.inventoryDesc
                : ''}
            </td>
            <td
              style={{
                width: "25%",
                textAlign: 'start',
                padding: '10px 0px',
                // paddingLeft: "12px",
                // paddingRight: "12px",
                // paddingTop: "5px",
                // paddingBottom: "5px",
              }}
            >
              {value.serialNumber
                ? value.serialNumber : ''}
            </td>
            <td
              style={{
                width: "25%",
                padding: '10px 0px',
                textAlign: 'start',
                // paddingLeft: "12px",
                // paddingRight: "12px",
                // paddingTop: "5px",
                // paddingBottom: "5px",
              }}
            >
              {value.modelNumber ?
                value.modelNumber :
                '-'
              }

            </td>
          </tr>
        );
      });
    }
  };
  const bindSwitchOutdata = (source?: any) => {
    let data: any = [];
    let itemdata1: any = {};
    if (inventorydescswitchin) {
      itemdata1.inventoryDesc = inventorydescswitchin;
      itemdata1.serialNumber = inventoryserialnumswitchin;
      itemdata1.inventoryNumber = inventorynumberswitchinitem;
      data.push(itemdata1)
      const lineItem = {
        inventoryNumber: "",
        inventoryDesc: "",
        serialNumber: "",
      };
      if (featureFlagDetails?.SundaySky == '1') {
        lineItem.inventoryDesc = ""
        data.push(lineItem);
      }
      const uniquedesc = [
        ...new Map(
          data.map((item: any) => [item.inventoryNumber, item])
        ).values(),
      ];
      return uniquedesc.map((value: any, index: any) => {
        return (
          <tr key={index} style={{ width: "100%" }}>
            <td
              style={{
                width: "15px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                />
                : qrCodeUrl && source ? <span
                  style={{
                    padding: PaddingConst,
                    borderRadius: borderRadiuss,
                    border: Constinter,
                    marginRight: "10px",
                  }}
                /> : ''}

            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryDesc
                ? value.inventoryDesc
                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value !== undefined &&
                value.serialNumber !== undefined &&
                value.serialNumber !== null &&
                value.serialNumber != ""
                ? value.serialNumber
                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
            </td>
            <td
              style={{
                width: "300px",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {value.inventoryNumber ?
                value.inventoryNumber
                :
                qrCodeUrl !== undefined && source ?
                  <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                  : ''
              }

            </td>
          </tr>
        );
      });
    }
  }
  const AGRReturn = () => {
    const data = getViewAgrData?.data?.agreementInventories;
    const AgrData = getViewAgrData?.data;
    const value = data?.some((v) => v.inventoryStatusRefcode == "SOR");
    if (AgrData.agreementInformation.agreementType == 'Promo' && AgrData.initialPayment.securityDeposit > 0) {
      setRefundSDPopup(true);
    }
    else if (value) {
      setReturnSORPopup(true);
    } else if (AgrData.agreementWorkflow.isDeliveryCompleted == "0") {
      if (
        (AgrData.agreementWorkflow.isInitialPaymentPaid == "1" &&
          AgrData.agreementInformation?.isLastReceiptReversed == "1") || (AgrData?.agreementInformation?.agreementType == 'Rent to Rent - Loaner')
      ) {
        setSOTwoFactorReturnEnabled(true);
        SetReturnDeliveryCompleted(false);
      } else {
        SetReturnDeliveryCompleted(true);
      }
    } else if (
      deliveryMethodData?.deliveryInformation.deliveryType == "Return" &&
      deliveryMethodData?.deliveryInformation.deliveryStatus == "Completed"
    ) {
      setreturnbtnRdn(true);
    } else {
      setSOTwoFactorReturnEnabled(true);
      SetReturnDeliveryCompleted(false);
    }
  };

  const SwitchOutFunction = () => {
    const data = getViewAgrData?.data?.agreementInventories;
    const value = data?.some((v) => v.inventoryStatusRefcode == "SOR");
    if (value) {
      setReturnSORPopup(true);
    } else {
      setSOTwoFactoreEnabled(true);
    }
  };
  const customerSignUsignModuleFn = (hideswitchoutbutton: any) => {
    if (hideswitchoutbutton == true) {
      if (
        viewAgrData?.data?.agreementInformation?.agreementType ==
        "Rent to Rent - Loaner"
      ) {
        return "loaner";
      } else {
        return "rental";
      }
    } else {
      return "switchOut";
    }
  };
  const documentTypeArrFn = (hideswitchoutbutton: any) => {
    if (hideswitchoutbutton == true) {
      if (
        viewAgrData?.data?.agreementInformation?.agreementType ==
        "Rent to Rent - Loaner"
      ) {
        return ["LOAN"];
      } else {
        return [];
      }
    } else {
      return ["SWA"];
    }
  };
  const deliveryDigitaSignFn = async () => {
    setmasterLoader(true);
    if (
      checksignresponse !== undefined &&
      checksignresponse !== null &&
      checksignresponse.isSigned == "0"
    ) {
      setmasterLoader(false);
      SetCheckforSign(true);
    }
    else {
      setmasterLoader(false);
      setdigitalSignEnable(true)
    }
  }
  const deliveryDigitalSignCompletedFn = async () => {
    setdigitalSignEnable(false);
    setmasterLoader(true);
    debugger
    if (
      deliveryMethodData?.deliveryInformation?.deliveryStatus == "Cancelled"
    ) {
      const value = {
        agreementId: agreementId,
        isLoaner: "Y",
        loanerAction: {
          appointmentId: String(appointmentId),
          storeNumber: window.sessionStorage.getItem("storeNumber"),
          actionName: "Reopen",
          coWorkerId: employeeId,
        },
      };
      const result = await Confirmdelivery(value)
    }
    debugger;

    let getAgreementInfoRes = await getAgreementInfo(agreementId);
    const appointmentid = getAgreementInfoRes?.data?.agreementInformation?.appointmentId

    const getAgreementDeliveryResponse = await getAgreementDelivery(agreementId, appointmentid)
    setreturnAppiontmenStatus(getAgreementDeliveryResponse?.data?.appointmentInfo?.appointmentType)

    let updatedeliverystatus: any = '';
    if (getAgreementInfoRes?.data?.agreementWorkflow?.isSwitchoutEnabled == '1') {
      updatedeliverystatus = {
        agreementId: agreementId,
        isSwitchOut: 1,
        switchInItem: Number(newinventoryId)
      }
    }
    else {
      updatedeliverystatus = {
        agreementId: agreementId,
      };
    }


    const updatesign = {
      agreementId: agreementId,
      value: 2,
    };
    let updatestatusresponse: any = {
      updatedata: {},
    };

    let updatesignresponse: any = {
      signdata: {},
    };
    const payload: any = {
      storeNumber: getAgreementDeliveryRes.storeNumber,
      appointmentId: String(appointmentid),
      actionName: 'Complete'
    }
    debugger
    if (['CI', 'P', 'S', 'SCI', 'SCO', 'SD', 'SP'].includes(getAgreementDeliveryResponse?.data?.appointmentInfo?.appointmentType)) {
      updatestatusresponse = await updateEventStatus(payload)
    }
    else {
      await Promise.all([
        Confirmdelivery(updatedeliverystatus),
        Recieptsign(updatesign),
      ]).then((resp) => {
        [updatestatusresponse, updatesignresponse] = resp;
      });
    }
    console.log("delivery confirmed", updatestatusresponse);
    console.log("sign updated", updatesignresponse);
    if (
      updatestatusresponse?.status == 200
    ) {
      setmasterLoader(false);
      SetOpendeliveryconfirmpopup(true);
      setmasterLoader(true);
      const agreementInfoUpdatedResult = await getAgreementInfo(agreementId);
      setviewAgrData(agreementInfoUpdatedResult);
      setmasterLoader(false);
      setviewAgreementData(agreementInfoUpdatedResult?.data);
    } else {
      setdeliveryerrorpopup(true);
    }
  }
  const deliveryDigitalSignCanceledFn = () => {
    setdigitalSignEnable(false);
    setManualSignatureEnabled(false)
  }
  const CancelEvent = () => {
    return (
      <div>
        <Typography>Reason for cancelling the event <span style={{ color: 'red' }}>*</span></Typography>
        <textarea style={{ resize: 'none' }} rows={7} cols={60} onChange={(e) => { setcancelEventReason(e.target.value.trimStart()) }}></textarea>
        <RACButton
          className={classes.mx1}
          style={{ marginLeft: '20rem', marginTop: '5px' }}
          onClick={() => setcancelEventPopup(false)}
          variant='outlined'
          color='secondary'
        >
          Cancel
        </RACButton>
        <RACButton
          disabled={cancelEventReason ? false : true
          }
          className={classes.mx1}
          style={{ marginTop: '5px' }}
          onClick={() => handleEventFn('Cancel')}
          variant="contained"
          color="primary"
        >
          Save
        </RACButton>
      </div>

    )
  }
  const handleEventFn = async (eventType: any) => {
    debugger;
    try {
      setcancelEventPopup(false)
      setactionBtnView(false)
      const payloadForAppointmentAction: any = {
        storeNumber: getAgreementDeliveryRes?.storeNumber,
        appointmentId: String(getAgreementInfoRes?.agreementInformation?.appointmentId),
        actionName: eventType
      }
      setmasterLoader(true)
      await updateEventStatus(payloadForAppointmentAction)
      setmasterLoader(false)
      // if (eventType == 'Cancel') {
      //   window.location.reload()
      // }
      // else {
      //   setRescheduleEnable(true)
      //   setdatePickerEnable(true)
      // }
      window.location.reload()

    } catch (e: any) {
      setmasterLoader(false)
    }
  }

  const PIFAgrCheckFn = (data: any) => {
    let isNCAgrWithActiveOrPend = false;
    if (data && (data?.noteStatus == "ACTIVE" || data?.noteStatus == "PEND")) {
      isNCAgrWithActiveOrPend = true;
    }
    return isNCAgrWithActiveOrPend;
  }
  const decidePrintPopupTittle = () => {
    if (isTireBuyerFlow) {
      return "Delivery Document";
    }
    else if (isReturnStart || returnReschedule) {
      return "Re-Print Pickup Receipt";
    }
    else {
      return "Re-Print Delivery Receipt"
    }
  }
  const tbCustomerSignCompletedFn = async () => {
    setTbCustomerSignatureEnable(false);
    window.location.reload();
  }
  const tbCustomerSignCanceledFn = () => {
    setTbCustomerSignatureEnable(false);
    history.push({
      pathname: `/dashboard/homepage`,
    });
  }
  const tbCustomerSignEmailCompletedFn = () => {
    setTbCustomerSignatureEnable(false);
  }
  return (
    <div>
      {digitalSignEnable ? (
        <DeliveryDigitalSign
          agreementId={String(agreementId)}
          sundaySky={featureFlagDetails?.SundaySky}
          deliveryDigitalSignCompletedFn={() => deliveryDigitalSignCompletedFn()}
          deliveryDigitalSignCanceledFn={() => deliveryDigitalSignCanceledFn()}
        />
      ) : null}

      {masterLoader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {returnbtnRdn ? (
        <AgreementReturn
          confirmFlow={confirmFlow}
          paymentredirectFlow={paymentredirectFlow}
          InvisibleFlow={InvisbleFunc}
          ReturnStable={ReturnStableFunc}
          AgrInfo={AgrInfoAPI}
          printRestrict={printRestrict}
          scheduleReturn={
            viewAgrData?.data?.agreementWorkflow?.isDeliveryCompleted == 1
              ? false
              : true
          }
        />
      ) : null}
      {installNoteComponent ? (
        <InstallmentNote InstallmentNoteStable={InstallmentNoteFunc} />
      ) : null}

      <div className={classes.fixedBottom}>
        <div className={classes.floatLeft}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => deliveryCancel()}
          >
            Cancel
          </RACButton>
        </div>
        {/* Desktop Footer Start */}
        <div
          className={`${classes.floatRight} ${classes.dFlex} ${classes1.dMdNone}`}
        >
          {isTireBuyerFlow ?
            <RACButton
              className={`${classes.floatLeft} ${classes.me2}`}
              variant="contained"
              color="primary"
              onClick={() => setIsSendDeliveryreceiptEnabled(true)}
            >
              Resend
            </RACButton>
            : null}
          {featureFlagDetails?.RateReduction == "1" ? (

            <>
              <RateReduction />
            </>
          ) : null}
          {installNoteEnable &&
            currentURL.includes("agreement/info/details/") &&
            featureFlagDetails?.InstallmentNotes == "1" ? (
            <RACButton
              className={`${classes.floatLeft} ${classes.me2}`}
              variant="contained"
              color="primary"
              onClick={() => setinstallNoteComponent(true)}
              disabled={
                viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                  ? true
                  : false
              }
            >
              Installment Note
            </RACButton>
          ) : null}
          {currentURL.includes("agreement/info/details") &&
            agreementInfoData?.agreementWorkflow?.isDeliveryCompleted == 1 &&
            (agreementInfoData?.agreementInformation?.agreementStatusRefCode ==
              "ACTIVE" || (agreementInfoData?.agreementInformation?.agreementStatusRefCode == "PIF" && PIFAgrCheckFn(agreementInfoData?.installmentNoteInfo))) &&
            agreementInfoData?.agreementInformation?.storeNumber ==
            window.sessionStorage.getItem("storeNumber") &&
            agreementInfoData?.agreementWorkflow?.isSwitchoutEnabled == 0 &&
            featureFlagDetails?.AgreementChargeOff == 1 ? (
            <>
              <ChargeOff paymentredirectChargeOff={paymentredirectChargeOff} />
            </>
          ) : null}
          {currentURL.includes("agreement/info/details") &&
            source == "AMRETENTION" &&
            (type == "RR" || type == "TR") ? (
            <RetentionRTreduction
              agreementId={String(agreementId)}
              agreementNumber={String(agreementNum)}
            />
          ) : null}
          {IsAgreementVoided === true ||
            voidedagreementfooter === true ? null : IsAgreementVoided === false ||
              voidedagreementfooter === false ? (
            <AddCoCustomer
              coCustomerData={twofactorpopup}
              func={pull_data}
              agreementvoided={IsAgreementVoided}
            />
          ) : null}
          {IsAgreementVoided === true ||
            voidedagreementfooter === true ? null : IsAgreementVoided === false ||
              voidedagreementfooter === false ? (
            <>
              {IsDeliveryConfirmed === false ||
                deliveryMethodFooter == "deliveryScheduled" ? (
                <>
                  {console.log("Vantuchaaaa")}
                  {showDeliveryMethod === true ? (
                    <>
                      {Footer == "DeliveryMethod" ? (
                        <>
                          {hideswitchoutbutton === false ? (
                            <>
                              {featureFlagDetails?.Switchout !== "0" ? (
                                <>
                                  <RACButton
                                    className={classes.mx1}
                                    variant="contained"
                                    color="primary"
                                    // onClick={() => switchoutbuttonclick()}
                                    onClick={() => { manualSignatureEnabled ? switchoutbuttonclick() : deliveryDigitaSignFn() }}
                                    disabled={
                                      viewAgrData?.data?.agreementInformation
                                        .storeNumber == storeNo
                                        ? false
                                        : true
                                    }
                                  >
                                    {manualSignatureEnabled ? 'Print Switchout Receipt' : 'Sign and Confirm Delivery'}
                                  </RACButton>
                                  {manualSignatureEnabled ? (
                                    <RACButton
                                      className={classes.mx1}
                                      variant="contained"
                                      color="primary"
                                      disabled={disableconfirmswitchout}
                                      onClick={() => switchoutconfirm()}
                                    >
                                      Confirm Switchout
                                    </RACButton>
                                  ) : null
                                  }
                                </>
                              ) : null}
                            </>
                          ) : returnReschedule === false ? (
                            <>
                              {resumebtnFeatureFlag == "1" &&
                                featureFlagDetails?.SundaySky == "1" ? (
                                <RACButton
                                  className={classes.mx1}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setResendVopen(true)}
                                >
                                  Resend Welcome Video
                                </RACButton>
                              ) : null}
                              {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' ? <RACButton
                                className={classes.mx1}
                                variant="contained"
                                color="primary"
                                // onClick={() => Check_Sign_Reciept("Delivery")}
                                onClick={() => { manualSignatureEnabled ? Check_Sign_Reciept("Delivery") : deliveryDigitaSignFn() }}
                                disabled={
                                  viewAgrData?.data?.agreementInformation
                                    .storeNumber == storeNo
                                    ? false
                                    : true
                                }
                              >
                                {manualSignatureEnabled ? 'Reprint Delivery Receipt' : 'Sign and Confirm Delivery'}

                              </RACButton> : null}

                              {(manualSignatureEnabled && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN') ? (
                                <RACButton
                                  variant="contained"
                                  color="primary"
                                  className={classes.mx1}
                                  disabled={
                                    viewAgrData?.data?.agreementInformation
                                      .storeNumber == storeNo
                                      ? disableconfirmdelivery
                                      : true
                                  }
                                  onClick={Confirm_delivery_Popup}
                                >
                                  Confirm Delivery
                                </RACButton>
                              ) : null
                              }
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                  <RACButton
                    disabled={
                      viewAgrData?.data?.agreementWorkflow
                        ?.isTransferInitiated == 1
                        ? true
                        : false
                    }
                    className={classes.mx1}
                    onClick={openPopup}
                    variant="contained"
                    color="primary"
                  >
                    Reprint
                  </RACButton>
                </>
              ) : null}
              {props.currentPage == "AgrInfo" ? (
                <RACButton
                  //disabled={ReturnButtonDisable}
                  disabled={
                    viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                      1
                      ? true
                      : ReturnButtonDisable
                  }
                  className={classes.mx1}
                  onClick={AGRReturn}
                  variant="contained"
                  color="primary"
                >
                  Return
                </RACButton>

              ) : null}
              {getViewAgrData?.data.agreementInformation
                .agreementStatusRefCode !== "ARTS" &&
                props.currentPage == "AgrInfo" ? (
                <RACButton
                  className={classes.mx1}
                  onClick={TakePaymentClick}
                  variant="contained"
                  color="primary"
                  // disabled={
                  //   agreementInfoData?.agreementInformation
                  //     ?.agreementStatusGroupCode == 'CLOSED'
                  //     ? true
                  //     : false
                  // }
                  disabled={
                    viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                      1
                      ? true
                      : agreementInfoData?.agreementInformation
                        ?.agreementStatusGroupCode == "CLOSED"
                        ? true
                        : false
                  }
                >
                  Take Payment
                </RACButton>
              ) : null}
              {props.currentPage == "AgrInfo" ? (
                <>
                  {hideswitchoutbutton === true ? (
                    <>
                      {featureFlagDetails?.Switchout !== "0" ? (
                        <Grid
                          title={
                            featureFlagDetails?.Switchout == "0"
                              ? "This feature is not available in your store"
                              : SwitchOutMessage
                          }
                          style={{ float: "right" }}
                        >
                          <RACButton
                            disabled={
                              viewAgrData?.data?.agreementWorkflow
                                ?.isTransferInitiated == 1
                                ? true
                                : SwitchOutDisable
                            }
                            className={classes.mx1}
                            onClick={() => SwitchOutFunction()}
                            variant="contained"
                            color="primary"
                          >
                            SwitchOut
                          </RACButton>
                        </Grid>
                      ) : null}
                    </>
                  ) : hideswitchoutbutton === false ? (
                    <>
                      {featureFlagDetails?.Switchout !== "0" ? (
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={classes.mx1}
                          onClick={() => SetCancelSwitchOut(true)}
                          disabled={
                            viewAgrData?.data?.agreementInformation
                              .storeNumber == storeNo
                              ? cancelSODisable
                              : true
                          }
                        >
                          Cancel SwitchOut
                        </RACButton>
                      ) : null}
                      {/* <RACButton
                        className={classes.mx1}
                        onClick={TakePaymentClick}
                        variant="contained"
                        color="primary"
                        // disabled={
                        //   agreementInfoData?.agreementInformation
                        //     ?.agreementStatusGroupCode == 'CLOSED'
                        //     ? true
                        //     : false
                        // }
                        disabled={
                          viewAgrData?.data?.agreementWorkflow
                            ?.isTransferInitiated == 1
                            ? true
                            : agreementInfoData?.agreementInformation
                              ?.agreementStatusGroupCode == "CLOSED"
                              ? true
                              : false
                        }
                      >
                        Take Payment
                      </RACButton> */}
                    </>
                  ) : null}
                </>
              ) : null}

              {props.currentPage == "DeliveryMethod" &&
                featureFlagDetails?.Switchout !== "0" ? (
                hideswitchoutbutton === false ? (
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.mx1}
                    onClick={() => setCancelSwitchOutInViewDelivery(true)}
                    disabled={
                      viewAgrData?.data?.agreementInformation?.storeNumber ==
                        storeNo
                        ? cancelSODisable
                        : true
                    }
                  >
                    Cancel SwitchOut
                  </RACButton>
                ) : null
              ) : null}

              {/* ReturnChanges cancel pickup/carry In */}
              {props.currentPage == "DeliveryMethod" &&
                featureFlagDetails?.AgreementReturn == "1" ? (
                isReturnStart || returnReschedule ? (
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.mx1}
                    onClick={() => setcancelReturnPickup(true)}
                    disabled={
                      viewAgrData?.data?.agreementInformation?.storeNumber ==
                        storeNo
                        ? false
                        : true
                    }
                  >
                    Cancel Pickup/Carry In
                  </RACButton>
                ) : null
              ) : null}
              {props.currentPage == "DeliveryMethod" &&
                (isReturnStart || returnReschedule) ? (
                <>
                  <RACButton
                    className={classes.mx1}
                    variant="contained"
                    color="primary"
                    // onClick={() => Check_Sign_Reciept("Pickup")}
                    onClick={() => { manualSignatureEnabled ? Check_Sign_Reciept("Pickup") : deliveryDigitaSignFn() }}
                    disabled={
                      viewAgrData?.data?.agreementInformation?.storeNumber ==
                        storeNo
                        ? false
                        : true
                    }
                  >

                    {manualSignatureEnabled ? 'Reprint Pickup Receipt' : 'Sign and Confirm Pickup/Carry In'}
                  </RACButton>
                  {manualSignatureEnabled ? (
                    <RACButton
                      variant="contained"
                      color="primary"
                      className={classes.mx1}
                      disabled={disableconfirmdelivery}
                      onClick={ConfirmReturnPickup}
                    >
                      Confirm Pickup
                    </RACButton>)
                    : null}
                </>
              ) : null}
              {PolicyButtonEnable !== null &&
                PolicyButtonEnable !== undefined &&
                props.currentPage == "AgrInfo" ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mx1}
                  data-bs-toggle="modal"
                  data-bs-target="#payment"
                  onClick={openPolicyPop}
                  disabled={
                    viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                      1
                      ? true
                      : isPolicyDisabled
                  }
                  //disabled={isPolicyDisabled}
                  loading={policyLoader}
                >
                  Policy
                </RACButton>
              ) : null}
            </>
          ) : null}

          {deliveryMethodFooter == "deliveryReschedule" &&
            isReturnStart === false &&
            returnReschedule === false && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' ? (
            <RACButton
              className={classes.mx1}
              variant="contained"
              id="save"
              color="primary"
              onClick={(e) => saveReschedule(e)}
              disabled={
                viewAgrData?.data?.agreementInformation.storeNumber == storeNo
                  ? disableSaveButton
                  : true
              }
            >
              Save
            </RACButton>
          ) : null}

          {(props.currentPage == "DeliveryMethod" && isReturn) ||
            (returnReschedule && props.currentPage == "DeliveryMethod") ? (
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              id="submit"
              disabled={
                viewAgrData?.data?.agreementInformation.storeNumber == storeNo
                  ? disableSubmitButton
                  : true
              }
              onClick={(e: any) => submitReschedule(e)}
            >
              Submit
            </RACButton>
          ) : null}

          {IsAgreementVoided === true || voidedagreementfooter === true ? (
            <RACButton
              //disabled={false}
              disabled={
                viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                  ? true
                  : false
              }
              className={classes.mx1}
              onClick={openPopup}
              variant="contained"
              color="primary"
            >
              Reprint
            </RACButton>
          ) : null}

          {(props.currentPage == "DeliveryMethod") && (getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' || getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN') && (getAgreementInfoRes?.agreementWorkflow
            ?.isSwitchoutEnabled == '0') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'D' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CO' || ((getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI') && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus !== 'TD'))
            && (getAgreementInfoRes?.agreementInformation?.storeNumber == storeNo) ? (
            <RACButton
              className={classes.mx1}
              onClick={() => {
                {
                  getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' ? setcancelEventPopup(true)
                    : handleEventFn('Reopen')
                }
              }}
              disabled={(getAgreementInfoRes?.agreementInformation?.agreementStatusGroupCode == 'VOID' || getAgreementInfoRes?.agreementInformation?.agreementStatusGroupCode == 'CLOSED') ? true : false} variant="contained"
              color="primary"
            >
              {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' ? 'Cancel Event' : getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN' ? 'Reopen Event' : null}

            </RACButton>
          ) : null}
        </div>
        {/* Desktop Footer end */}
        {/* Filed Devices Footer Start */}
        <div
          className={`${classes.floatRight} ${classes.hide} ${classes1.dMdBlock} ${classes1.positionRelative}`}
        >
          {actionBtnView ? (
            <List
              className={`${classes1.positionAbsolute} ${classes1.actionsList}`}
            >

              {featureFlagDetails?.RateReduction == "1" ? (
                <ListItem>
                  <RateReduction />
                </ListItem>
              ) : null}
              {installNoteEnable &&
                currentURL.includes("agreement/info/details/") &&
                featureFlagDetails?.InstallmentNotes == "1" ? (
                <ListItem
                  onClick={() => setinstallNoteComponent(true)}
                  disabled={
                    viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                      ? true
                      : false
                  }
                >
                  Installment Note
                </ListItem>
              ) : null}
              {currentURL.includes("agreement/info/details") &&
                agreementInfoData?.agreementWorkflow?.isDeliveryCompleted == 1 &&
                agreementInfoData?.agreementInformation?.agreementStatus ==
                "Active" &&
                agreementInfoData?.agreementInformation?.storeNumber ==
                window.sessionStorage.getItem("storeNumber") &&
                agreementInfoData?.agreementWorkflow?.isSwitchoutEnabled == 0 &&
                featureFlagDetails?.AgreementChargeOff == 1 ? (
                <ListItem
                  className={classes1.btnList}
                >
                  <ChargeOff paymentredirectChargeOff={paymentredirectChargeOff} />
                </ListItem>
              ) : null}
              {currentURL.includes("agreement/info/details") &&
                source == "AMRETENTION" &&
                (type == "RR" || type == "TR") ? (
                <ListItem
                  className={classes1.btnList}
                >
                  <RetentionRTreduction
                    agreementId={String(agreementId)}
                    agreementNumber={String(agreementNum)}
                  />
                </ListItem>
              ) : null}
              {IsAgreementVoided === true ||
                voidedagreementfooter === true ? null : IsAgreementVoided === false ||
                  voidedagreementfooter === false ? (
                <ListItem className={classes1.btnList}>
                  <AddCoCustomer
                    coCustomerData={twofactorpopup}
                    func={pull_data}
                    agreementvoided={IsAgreementVoided}
                  />
                </ListItem>
              ) : null}
              {/* ----------------------start buttons-------------------------------- */}
              {IsAgreementVoided === true ||
                voidedagreementfooter === true ? null : IsAgreementVoided === false ||
                  voidedagreementfooter === false ? (
                <>
                  {IsDeliveryConfirmed === false ||
                    deliveryMethodFooter == "deliveryScheduled" ? (
                    <>
                      {console.log("Vantuchaaaa")}
                      {showDeliveryMethod === true ? (
                        <>
                          {Footer == "DeliveryMethod" ? (
                            <>
                              {hideswitchoutbutton === false ? (
                                <>
                                  {featureFlagDetails?.Switchout !== "0" ? (

                                    <>
                                      <ListItem className={classes1.btnList}>
                                        <RACButton

                                          // onClick={() => switchoutbuttonclick()}
                                          onClick={() => { manualSignatureEnabled ? switchoutbuttonclick() : deliveryDigitaSignFn() }}
                                          disabled={
                                            viewAgrData?.data?.agreementInformation
                                              .storeNumber == storeNo
                                              ? false
                                              : true
                                          }
                                        >
                                          {manualSignatureEnabled ? 'Print Switchout Receipt' : 'Sign and Confirm Delivery'}
                                        </RACButton>
                                      </ListItem>
                                      {manualSignatureEnabled ? (
                                        <ListItem className={classes1.btnList}>
                                          <RACButton
                                            disabled={disableconfirmswitchout}
                                            onClick={() => switchoutconfirm()}
                                          >
                                            Confirm Switchout
                                          </RACButton>
                                        </ListItem>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              ) : returnReschedule === false ? (
                                <>
                                  {resumebtnFeatureFlag == "1" &&
                                    featureFlagDetails?.SundaySky == "1" ? (
                                    <ListItem className={classes1.btnList}>                                <RACButton

                                      onClick={() => setResendVopen(true)}
                                    >
                                      Resend Welcome Video
                                    </RACButton>
                                    </ListItem>
                                  ) : null}

                                  {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' ? <ListItem className={classes1.btnList}>
                                    <RACButton
                                      // onClick={() => Check_Sign_Reciept("Delivery")}
                                      onClick={() => { setRescheduleEnable(true), setactionBtnView(false), setdatePickerEnable(true) }}
                                      disabled={
                                        viewAgrData?.data?.agreementInformation
                                          .storeNumber == storeNo
                                          ? false
                                          : true
                                      }
                                    >
                                      Reschedule Delivery
                                    </RACButton>
                                  </ListItem> : null}

                                  {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' ? <ListItem className={classes1.btnList}>
                                    <RACButton
                                      // onClick={() => Check_Sign_Reciept("Delivery")}
                                      onClick={() => { manualSignatureEnabled ? Check_Sign_Reciept("Delivery") : deliveryDigitaSignFn() }}
                                      disabled={
                                        viewAgrData?.data?.agreementInformation
                                          .storeNumber == storeNo
                                          ? false
                                          : true
                                      }
                                    >
                                      {manualSignatureEnabled ? 'Reprint Delivery Receipt' : 'Sign and Confirm Delivery'}
                                    </RACButton>
                                  </ListItem> : null}

                                  {(manualSignatureEnabled && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN') ? (
                                    <ListItem className={classes1.btnList} >
                                      <RACButton
                                        disabled={
                                          viewAgrData?.data?.agreementInformation
                                            .storeNumber == storeNo
                                            ? disableconfirmdelivery
                                            : true
                                        }
                                        onClick={Confirm_delivery_Popup}
                                      >
                                        Confirm Delivery
                                      </RACButton>
                                    </ListItem>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}
                      <ListItem className={classes1.btnList}>
                        <RACButton
                          disabled={
                            viewAgrData?.data?.agreementWorkflow
                              ?.isTransferInitiated == 1
                              ? true
                              : false
                          }
                          onClick={openPopup}
                        >
                          Reprint
                        </RACButton>
                      </ListItem>
                    </>
                  ) : null}
                  {props.currentPage == "AgrInfo" ? (
                    <ListItem className={classes1.btnList}>
                      <RACButton
                        disabled={
                          viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                            1
                            ? true
                            : ReturnButtonDisable
                        }
                        onClick={AGRReturn}

                      >
                        Return
                      </RACButton>
                    </ListItem>
                  ) : null}
                  {getViewAgrData?.data.agreementInformation
                    .agreementStatusRefCode !== "ARTS" &&
                    props.currentPage == "AgrInfo" ? (
                    <ListItem className={classes1.btnList}>
                      <RACButton
                        onClick={TakePaymentClick}
                        disabled={
                          viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                            1
                            ? true
                            : agreementInfoData?.agreementInformation
                              ?.agreementStatusGroupCode == "CLOSED"
                              ? true
                              : false
                        }
                      >
                        Take Payment
                      </RACButton>
                    </ListItem>
                  ) : null}
                  {props.currentPage == "AgrInfo" ? (
                    <>
                      {hideswitchoutbutton === true ? (
                        <>
                          {featureFlagDetails?.Switchout !== "0" ? (
                            <Grid
                              title={
                                featureFlagDetails?.Switchout == "0"
                                  ? "This feature is not available in your store"
                                  : SwitchOutMessage
                              }
                            // style={{ float: "right" }}
                            >
                              <ListItem className={classes1.btnList}>
                                <RACButton
                                  disabled={
                                    viewAgrData?.data?.agreementWorkflow
                                      ?.isTransferInitiated == 1
                                      ? true
                                      : SwitchOutDisable
                                  }
                                  onClick={() => SwitchOutFunction()}

                                >
                                  SwitchOut
                                </RACButton>
                              </ListItem>
                            </Grid>
                          ) : null}
                        </>
                      ) : hideswitchoutbutton === false ? (
                        <>
                          {featureFlagDetails?.Switchout !== "0" ? (
                            <ListItem className={classes1.btnList}>
                              <RACButton
                                onClick={() => SetCancelSwitchOut(true)}
                                disabled={
                                  viewAgrData?.data?.agreementInformation
                                    .storeNumber == storeNo
                                    ? cancelSODisable
                                    : true
                                }
                              >
                                Cancel SwitchOut
                              </RACButton>
                            </ListItem>
                          ) : null}
                          {/* <ListItem className={classes1.btnList}>
                            <RACButton
                              onClick={TakePaymentClick}
                              disabled={
                                viewAgrData?.data?.agreementWorkflow
                                  ?.isTransferInitiated == 1
                                  ? true
                                  : agreementInfoData?.agreementInformation
                                    ?.agreementStatusGroupCode == "CLOSED"
                                    ? true
                                    : false
                              }
                            >
                              Take Payment
                            </RACButton>
                          </ListItem> */}
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {props.currentPage == "DeliveryMethod" &&
                    featureFlagDetails?.Switchout !== "0" ? (
                    hideswitchoutbutton === false ? (
                      <ListItem className={classes1.btnList}>
                        <RACButton
                          onClick={() => setCancelSwitchOutInViewDelivery(true)}
                          disabled={
                            viewAgrData?.data?.agreementInformation?.storeNumber ==
                              storeNo
                              ? cancelSODisable
                              : true
                          }
                        >
                          Cancel SwitchOut
                        </RACButton>
                      </ListItem>
                    ) : null
                  ) : null}

                  {/* ReturnChanges cancel pickup/carry In */}
                  {props.currentPage == "DeliveryMethod" &&
                    featureFlagDetails?.AgreementReturn == "1" ? (
                    isReturnStart || returnReschedule ? (
                      <ListItem className={classes1.btnList}>
                        <RACButton
                          onClick={() => setcancelReturnPickup(true)}
                          disabled={
                            viewAgrData?.data?.agreementInformation?.storeNumber ==
                              storeNo
                              ? false
                              : true
                          }
                        >
                          Cancel Pickup/Carry In
                        </RACButton>
                      </ListItem>
                    ) : null
                  ) : null}
                  {props.currentPage == "DeliveryMethod" &&
                    (isReturnStart || returnReschedule) ? (
                    <>
                      <ListItem className={classes1.btnList}>
                        <RACButton
                          // onClick={() => Check_Sign_Reciept("Pickup")}
                          onClick={() => { manualSignatureEnabled ? Check_Sign_Reciept("Pickup") : deliveryDigitaSignFn() }}
                          disabled={
                            viewAgrData?.data?.agreementInformation?.storeNumber ==
                              storeNo
                              ? false
                              : true
                          }
                        >
                          {manualSignatureEnabled ? 'Reprint Pickup Receipt' : 'Sign and Confirm Pickup/Carry In'}
                        </RACButton>
                      </ListItem>
                      {manualSignatureEnabled ? (
                        <ListItem className={classes1.btnList}>
                          <RACButton
                            disabled={disableconfirmdelivery}
                            onClick={ConfirmReturnPickup}
                          >
                            Confirm Pickup
                          </RACButton>
                        </ListItem>
                      ) : null}
                    </>
                  ) : null}
                  {PolicyButtonEnable !== null &&
                    PolicyButtonEnable !== undefined &&
                    props.currentPage == "AgrInfo" ? (
                    <ListItem className={classes1.btnList}>
                      <RACButton
                        data-bs-toggle="modal"
                        data-bs-target="#payment"
                        onClick={openPolicyPop}
                        disabled={
                          viewAgrData?.data?.agreementWorkflow?.isTransferInitiated ==
                            1
                            ? true
                            : isPolicyDisabled
                        }
                        //disabled={isPolicyDisabled}
                        loading={policyLoader}
                      >
                        Policy
                      </RACButton>
                    </ListItem>
                  ) : null}
                </>
              ) : null}
              {/* ------------------end buttons------------------------ */}
              {deliveryMethodFooter == "deliveryReschedule" &&
                isReturnStart === false &&
                returnReschedule === false && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus != 'CAN' ? (
                <ListItem className={classes1.btnList}
                >
                  <RACButton
                    id="save"
                    onClick={(e: any) => saveReschedule(e)}
                    disabled={
                      viewAgrData?.data?.agreementInformation.storeNumber == storeNo
                        ? disableSaveButton
                        : true
                    }
                  >
                    Save
                  </RACButton>
                </ListItem>
              ) : null}
              {(props.currentPage == "DeliveryMethod" && isReturn) ||
                (returnReschedule && props.currentPage == "DeliveryMethod") ? (
                <ListItem className={classes1.btnList}
                >
                  <RACButton
                    id="submit"
                    disabled={
                      viewAgrData?.data?.agreementInformation.storeNumber == storeNo
                        ? disableSubmitButton
                        : true
                    }
                    onClick={(e) => submitReschedule(e)}
                  >
                    Submit
                  </RACButton>
                </ListItem>
              ) : null}
              {IsAgreementVoided === true || voidedagreementfooter === true ? (
                <ListItem className={classes1.btnList}
                >
                  <RACButton
                    disabled={
                      viewAgrData?.data?.agreementWorkflow?.isTransferInitiated == 1
                        ? true
                        : false
                    }
                    onClick={openPopup}
                  >
                    Reprint
                  </RACButton>
                </ListItem>
              ) : null}
              {(props.currentPage == "DeliveryMethod") && (getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' || getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN') && (getAgreementInfoRes?.agreementWorkflow
                ?.isSwitchoutEnabled == '0') && (getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'D' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CO' || ((getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'P' || getAgreementDeliveryRes?.appointmentInfo?.appointmentType == 'CI') && getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus !== 'TD'))
                && (getAgreementInfoRes?.agreementInformation?.storeNumber == storeNo) ? (
                <ListItem className={classes1.btnList}>
                  <RACButton
                    className={classes.mx1}
                    onClick={() => {
                      {
                        getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' ? (setcancelEventPopup(true), setactionBtnView(false))
                          : handleEventFn('Reopen')
                      }
                    }}
                    disabled={(getAgreementInfoRes?.agreementInformation?.agreementStatusGroupCode == 'VOID' || getAgreementInfoRes?.agreementInformation?.agreementStatusGroupCode == 'CLOSED') ? true : false} variant="contained"
                    color="primary"
                  >
                    {getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'TD' ? 'Cancel Event' : getAgreementDeliveryRes?.appointmentInfo?.appointmentStatus == 'CAN' ? 'Reopen Event' : null}

                  </RACButton></ListItem>

              ) : null}
            </List>
          ) : null}

          <RACButton
            className={`${classes.floatLeft} ${classes.me2}`}
            variant="contained"
            color="primary"
            endIcon={<Polygon />}
            onClick={() => {
              actionBtnView ? setactionBtnView(false) : setactionBtnView(true);
            }}
          >
            Actions
          </RACButton>
        </div>
        {/* Filed Devices Footer end */}

      </div>

      {isSendDeliveryreceiptEnabled ?
        <SendDeliveryReceipt
          customerId={CustomerId}
          customerName={getAgreementInfoRes.agreementCustomer[0].firstName}
          customerFirstName={getAgreementInfoRes.agreementCustomer[0].firstName}
          customerLastName={getAgreementInfoRes.agreementCustomer[0].lastName}
          email={getAgreementInfoRes.agreementCustomer[0].emailId}
          phoneNumber={getAgreementInfoRes.agreementCustomer[0].phoneNumber}
          agreementId={agreementId}
          sendDeliveryReceiptCompletedFn={() => { setIsSendDeliveryreceiptEnabled(false) }}
          sendDeliveryReceiptCancelledFn={() => { setIsSendDeliveryreceiptEnabled(false) }}
        /> : null}
      {/* EVENT CANCEL POPUP */}
      <RACModalCard
        isOpen={cancelEventPopup}
        borderRadius="25px"
        maxWidth='sm'
        title="Reason for Cancelling Event"
        onClose={() => setcancelEventPopup(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={CancelEvent()}
      />
      {/* check sign popup */}

      <RACModalCard
        isOpen={CheckforSign}
        borderRadius="15px"
        maxWidth="xs"
        closeIcon={true}
        onClose={() => SetCheckforSign(false)}
        // eslint-disable-next-line react/no-children-prop
        children={SignCheckPopup()}
      />

      <RACModalCard
        isOpen={ResendVopen}
        closeIcon={true}
        maxWidth="sm"
        title="Resend Welcome Video"
        TitleVariantClassName={classes.popupTitle}
        // eslint-disable-next-line react/no-children-prop
        children={sendAgreementPopup()}
        onClose={() => setResendVopen(false)}
        borderRadius={"20px !important"}
      />
      <RACModalCard
        isOpen={resendsuccess}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={resumeSuccessPopup()}
        borderRadius={"20px !important"}
      />
      {/* please reverse payment */}
      <RACModalCard
        isOpen={ReturnDeliveryCompleted}
        onClose={() => SetReturnDeliveryCompleted(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryCompletedPopContent()}
      />
      {/* please Service On rent there is no permission  */}
      <RACModalCard
        isOpen={ReturnSORPopup}
        onClose={() => setReturnSORPopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={ReturnSORPopContent()}
      />
      {/* please Service On rent there is no permission  */}
      <RACModalCard
        isOpen={RefundSDPopup}
        onClose={() => setRefundSDPopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={RefundSecurityDeposit()}
      />
      {/* please Service On rent there is no permission  */}
      <RACModalCard
        isOpen={SwitchOutSORPopup}
        onClose={() => setSwitchOutSORPopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={SwitchoutSORPopContent()}
      />
      <RACModalCard
        isOpen={OpenPickupconfirmpopup}
        onClose={() => SetOpenPickupconfirmpopup(false)}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={PickupConfirmPopupOpen()}
      />

      {/* Re-Print Delivery Reciept */}
      <RACModalCard
        isOpen={Openprintpopup}
        onClose={() => SetOpenprintpopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        title={decidePrintPopupTittle()}
        // eslint-disable-next-line react/no-children-prop
        children={PrintPopupOpen()}
      />

      {/* update receipt sign popup  */}
      <RACModalCard
        isOpen={Opendeliverypopup}
        onClose={() => SetOpendeliverypopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryPopupOpen()}
      />
      {/*Re-print Delivery receipt for customer order */}
      <RACModalCard
        isOpen={openCOprintpopup}
        onClose={() => setopenCOprintpopup(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        title="Re-Print Delivery Receipt"
        // eslint-disable-next-line react/no-children-prop
        children={Coprintpopupopen()}
      />
      {/* Confirm Delivery Popup */}

      <RACModalCard
        isOpen={Opendeliveryconfirmpopup}
        onClose={() => SetOpendeliveryconfirmpopup(false)}
        maxWidth="xs"
        borderRadius="20px"
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={DeliveryConfirmPopupOpen()}
      />

      {/*Manual Sign Authetication Popup starts here*/}

      {/* iframe load popup  */}

      {/*  */}

      <RACModalCard
        isOpen={deliveryerrorpopup}
        closeIcon={true}
        maxWidth="sm"
        borderRadius="20px"
        onClose={() => setdeliveryerrorpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deliveryerrorconfirm()}
      />
      <RACModalCard
        isOpen={deliveryconfirmerror}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="sm"
        onClose={() => setdeliveryconfirmerror(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deliveryerror()}
      />
      {
        customerSignEnabled ? (
          <CustomerSignature
            customerSignUsingField={customerSignUsingField}
            // customerSignUsingModule={hideswitchoutbutton ? 'rental' : 'switchOut'}
            customerSignUsingModule={customerSignUsignModuleFn(
              hideswitchoutbutton
            )}
            identifier={parseInt(agreementId)}
            customersArr={customersArray}
            manualSignOptions={maualSignReasonDrpdwnVal}
            // documentTypeArr={hideswitchoutbutton ? [] : ['SWA']}
            documentTypeArr={documentTypeArrFn(hideswitchoutbutton)}
            customerSignatureCompletedFn={() => {
              hideswitchoutbutton
                ? customerSignatureCompletedFn()
                : customersignswichoutfuntion();
            }}
            customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
            emailcompletedFn={() => emailcompletedFn()}
            documentGenerateCompleted={true}
          />
        ) : null
      }

      {
        deliveryDigitalCustomerSignatureEnable ? (
          <CustomerSignature
            customerSignUsingField={customerSignUsingField}
            // customerSignUsingModule={hideswitchoutbutton ? 'rental' : 'switchOut'}
            customerSignUsingModule={customerSignUsignModuleFn(
              hideswitchoutbutton
            )}
            identifier={parseInt(agreementId)}
            customersArr={customersArray}
            manualSignOptions={maualSignReasonDrpdwnVal}
            // documentTypeArr={hideswitchoutbutton ? [] : ['SWA']}
            documentTypeArr={documentTypeArrFn(hideswitchoutbutton)}
            customerSignatureCompletedFn={() => {
              hideswitchoutbutton
                ? customerSignatureCompltedFnForDigital()
                : customerSignatureCompltedFnForDigital();
            }}
            customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
            emailcompletedFn={() => emailcompletedFn()}
            documentGenerateCompleted={true}
          />
        ) : null
      }
      {
        tbCustomerSignatureEnable ? (
          <CustomerSignature
            customerSignUsingField={customerSignUsingField}
            customerSignUsingModule={'customerOrder'}
            identifier={parseInt(agreementId)}
            customersArr={customersArray}
            manualSignOptions={maualSignReasonDrpdwnVal}
            documentTypeArr={[]}
            customerSignatureCompletedFn={() => tbCustomerSignCompletedFn()}
            customerSignatureCanceledFn={() => tbCustomerSignCanceledFn()}
            emailcompletedFn={() => tbCustomerSignEmailCompletedFn()}
            documentGenerateCompleted={true}
          />
        ) : null
      }

      {
        twoFactorEnabled ? (
          <SecondFactor
            setTwoFactorCompleted={setTwoFactorCompleted}
            setTwoFactorCancelClick={setTwoFactorCancelClick}
            currentRole={currentUserRole}
            moduleName={"Return Void Agreement"}
          />
        ) : null
      }
      {
        SOTwoFactoreEnabled ? (
          <SecondFactor
            setTwoFactorCompleted={SOTwoFactorCompleted}
            setTwoFactorCancelClick={() => setSOTwoFactoreEnabled(false)}
            closeSLAClicked={() => setSOTwoFactoreEnabled(false)}
            currentRole={currentUserRole}
            moduleName="Switchout"
            throwCurrentPage="SwitchoutScenario"
          />
        ) : null
      }
      {
        SOTwoFactorReturnEnabled ? (
          <SecondFactor
            setTwoFactorCompleted={SOTwoFactorReturnCompleted}
            setTwoFactorCancelClick={() => setSOTwoFactorReturnEnabled(false)}
            closeSLAClicked={() => setSOTwoFactorReturnEnabled(false)}
            currentRole={currentUserRole}
            moduleName="Return"
          //throwCurrentPage="SwitchoutScenario"
          />
        ) : null
      }
      {/*Print switchout receipt popup starts here*/}
      <RACModalCard
        isOpen={printswitchoutpopup}
        closeIcon={true}
        maxWidth="xs"
        borderRadius="20px"
        onClose={() => setprintswitchoutpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={switchoutpopup()}
      />
      {/* print switchout popup receipts ends here */}

      {/* switchout confirm popup starts here*/}
      <RACModalCard
        isOpen={confirmswitchoutpopup}
        closeIcon={true}
        maxWidth="xs"
        borderRadius="20px"
        onClose={() => setconfirmswitchoupopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={confirmswitchoutdelivery()}
      />
      {/* switchout confirm popup ends here */}

      {/* switchout delivery confirm popup starts here */}
      <RACModalCard
        isOpen={switchoutdelievry}
        closeIcon={true}
        maxWidth="xs"
        borderRadius="20px"
        onClose={() => setswitchoutdelievry(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deliveryswitchout()}
      />
      {/* switchout delivery confirm popup ends here */}

      {/* switchout error popup starts here */}
      <RACModalCard
        isOpen={switchouterror}
        closeIcon={true}
        maxWidth="xs"
        borderRadius="20px"
        onClose={() => setswitchouterror(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deliveryswitchouterror()}
      />

      <RACModalCard
        isOpen={CancelSwitchOut}
        borderRadius="15px"
        onClose={() => SetCancelSwitchOut(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={CancelConfirmation()}
      />

      <RACModalCard
        isOpen={cancelReturnPickup}
        borderRadius="15px"
        onClose={() => setcancelReturnPickup(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={CancelConfirmationInPickup()}
      />
      <RACModalCard
        isOpen={CancelSwitchOutInViewDelivery}
        borderRadius="15px"
        onClose={() => setCancelSwitchOutInViewDelivery(false)}
        maxWidth="xs"
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={CancelConfirmationInDelivery()}
      />
      <RACModalCard
        isOpen={ReverseConfirmation}
        onClose={() => setReverseConfirmation(false)}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        // eslint-disable-next-line react/no-children-prop
        children={ReversePaymentConfirmationContent()}
      />

      <Grid className={classes.hide}>
        {/* delivery receipt inner html starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
            paddingLeft: "12px"
          }}
          id="DeliveryReceiptPrintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Delivery Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}

                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Delivery Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Next Payment Due:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {nextpaymentinfo.nextamountdue !== undefined &&
                              nextpaymentinfo.nextamountdue !== null
                              ? "$" + " " + nextpaymentinfo.nextamountdue
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    Items
                  </h5>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindSwitchOutdata('ResendVideo')}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "100%", float: "left" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textAlign: "center",
                      textDecorationLine: "underline",
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "10px",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {" "}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: "22%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: "17%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: "8%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      float: "left",
                      marginTop: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <div style={{ width: "40%", float: "left" }}>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: "60%", float: "right", marginTop: "20px" }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "20px"
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      marginTop: 10,
                      marginBottom: 10,
                      backgroundColor: "#E0EAFF"
                    }}
                  >
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault-7"
                      checked={false}

                    />
                    <label
                      htmlFor="flexCheckDefault-6"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 13,
                        color: '#4A5174',
                      }}
                    >
                      Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                    </label>
                  </div>
                  <div style={{ width: "100%", float: "left" }}>
                    <h5
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "0px",
                        textDecoration: "underline",
                      }}
                    >
                      Accept / Decline (Check one)
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      I acknowledge that Rent-A-Center has delivered, installed
                      and demonstrated, to my satisfaction, the rental property
                      listed above.
                    </span>
                  </div>
                  <div
                    style={{ width: "100%", float: "left", marginTop: "10px" }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", marginBottom: "10px" }}>
                      I decline delivery by Rent-A-Center of the rental property
                      listed above.
                    </span>
                  </div>
                </div>
              </div>
              {/*-fourth part ends*/}
              {/*fifth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: "100%", float: "left", marginTop: "25px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*fifth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* delivery receipt inner html ends here */}

        {/* delivery checklist inner html starts here */}
        <div
          style={{
            width: "90%",
            fontFamily: "Arial",
            margin: "10px auto 0px auto",
            paddingLeft: "12px"
          }}
          id="DeliveryChecklistPdf"
        >
          {/*first part starts here*/}
          <div style={{ width: "100%", float: "left" }}>
            <div style={{ width: "100%", fontFamily: "Arial", float: "left" }}>
              <div style={{ width: "30%", float: "left" }}>
                <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                  Delivery Checklist
                </h5>
              </div>
              <div
                style={{
                  width: "31%",
                  marginLeft: "2%",
                  marginRight: "2%",
                  float: "left",
                  height: "100px",
                  marginBottom: "10px",
                }}
              >
                <Logo style={{ width: "100%", height: "100%" }}></Logo>
              </div>
              <div></div>
              <table style={{ width: "35%", float: "left", marginTop: "30px" }}>
                <thead />
                <tbody style={{ width: "100%", float: "right" }}>
                  {/*child-parent of first widget*/}
                  <tr
                    style={{ width: "42%", float: "left", textAlign: "left" }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Delivery Date:
                    </td>
                  </tr>
                  <tr
                    style={{ width: "58%", float: "left", textAlign: "left" }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          fontSize: "13px",
                        }}
                      >
                        {DeliveryDate}
                      </span>
                    </td>
                  </tr>
                  <tr
                    style={{ width: "43%", float: "left", textAlign: "left" }}
                  >
                    <td
                      style={{
                        marginBottom: "0.2rem",
                        color: "#111111",
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Delivery Time:
                    </td>
                  </tr>
                  <tr
                    style={{ width: "57%", float: "left", textAlign: "left" }}
                  >
                    <td>
                      <span
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          fontSize: "13px",
                        }}
                      >
                        {DeliveryTime}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*first part ends here*/}
          {/*second part starts here */}
          <div
            style={{
              width: "100%",
              float: "left",
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: "100%", float: "left", marginBottom: "10px" }}>
              <div style={{ width: "33.33%", float: "left" }}>
                <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                  RAC Store
                </h5>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: "13px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {storename}
                </p>
                <>
                  {StoreAddressline2 != undefined &&
                    StoreAddressline2 != null &&
                    StoreAddressline2 != "" ? (
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {StoreAddressline1 + " , " + StoreAddressline2}
                    </p>
                  ) : (
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {StoreAddressline1}
                    </p>
                  )}
                </>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: "13px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  {storecity + "," + storezip}{" "}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {normalizeinput(Storephonenum)}
                </p>
              </div>
              <div style={{ width: "30.33%", float: "left" }}>
                <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                  Customer
                </h5>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: "13px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {CustomerName}
                </p>
                {CustomerAddressline2 !== undefined &&
                  CustomerAddressline2 !== null &&
                  CustomerAddressline2 !== "" ? (
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {CustomerAddressline1 + "," + CustomerAddressline2}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {CustomerAddressline1}
                  </p>
                )}
                {customerplus4 != undefined &&
                  customerplus4 != null &&
                  customerplus4 != "" ? (
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {customercity + "," + customerzip + "-" + customerplus4}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontSize: "13px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {customercity + "," + customerzip}
                  </p>
                )}
                <p
                  style={{
                    fontSize: "13px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {normalizeinput(CustomerPhoneNumber)}
                </p>
              </div>
              <div
                style={{ width: "36.33%", float: "left", marginTop: "12px" }}
              >
                <table>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{ width: "50%", textAlign: "left", float: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "40%", textAlign: "left", float: "left" }}
                    >
                      <td
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          fontSize: "13px",
                        }}
                      >
                        {DeliveryDate}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "50%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Agreement # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "50%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          fontSize: "13px",
                        }}
                      >
                        {AgreementId}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "50%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Customer # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "40%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          fontSize: "13px",
                        }}
                      >
                        {CustomerId}{" "}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "50%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Next Payment Due:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "40%",
                        marginTop: "10px",
                        textAlign: "left",
                        float: "left",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "5px",
                          color: "#656565",
                          fontSize: "13px",
                        }}
                      >
                        {nextpaymentinfo.nextamountdue !== undefined &&
                          nextpaymentinfo.nextamountdue !== null
                          ? "$" + " " + nextpaymentinfo.nextamountdue
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/*second part ends here */}
          {/*3rd part starts*/}
          <div
            style={{
              width: "100%",
              float: "left",
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: "100%", float: "left", marginTop: "15px" }}>
              <h5
                style={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  fontSize: "14px",
                  margin: "0px",
                  marginBottom: "10px",
                }}
              >
                Delivery Checklist
              </h5>
            </div>
            <div style={{ width: "100%", float: "left", marginBottom: "10px" }}>
              <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                <thead>
                  <tr style={{ width: "100%" }}>
                    <th
                      style={{
                        width: "15px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    ></th>
                    <th
                      style={{
                        width: "300px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Description of Property
                    </th>
                    <th
                      style={{
                        width: "300px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Serial #
                    </th>
                    <th
                      style={{
                        width: "300px",
                        textAlign: "left",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Item #
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bindSwitchOutdata('ResendVideo')}
                </tbody>
              </table>
            </div>
          </div>
          {/*3rd part ends*/}
          {/*fourth part starts*/}
          <div
            style={{
              width: "100%",
              float: "left",
              marginBottom: "400px",
              marginTop: "10px",
            }}
          >
            <h5
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                float: "left",
              }}
            >
              Delivery Information
            </h5>
            <table style={{ width: "100%", float: "left" }}>
              <thead />
              <tbody
                style={{ width: "100%", marginTop: "20px", float: "left" }}
              >
                <tr
                  style={{ width: "18%", float: "left", whiteSpace: "nowrap" }}
                >
                  <td
                    style={{
                      marginBottom: "0.2rem",
                      color: "#111111",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    CoWorker Signature:
                  </td>
                </tr>
                <tr
                  style={{
                    width: "28%",
                    float: "left",
                    paddingRight: "25px",
                    whiteSpace: "nowrap",
                    marginLeft: "8px",
                  }}
                >
                  <td
                    style={{
                      marginTop: "18px",
                      color: "#656565",
                      borderBottom: borderbotttom,
                      width: "100%",
                      float: "left",
                    }}
                  />
                </tr>
                <tr
                  style={{ width: "8%", float: "left", whiteSpace: "nowrap" }}
                >
                  <td
                    style={{
                      marginBottom: "0.2rem",
                      color: "#111111",
                      fontSize: "13px",
                      fontWeight: "bold",
                    }}
                  >
                    Date:
                  </td>
                </tr>
                <tr
                  style={{ width: "32%", float: "left", whiteSpace: "nowrap" }}
                >
                  <td
                    style={{
                      marginTop: "15px",
                      color: "#656565",
                      borderBottom: borderbotttom,
                      width: "100%",
                      float: "left",
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {/*fourth part ends*/}
          {/*fifth part starts*/}
          <div
            style={{
              width: "100%",
              float: "left",
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: "100%", float: "left" }}>
              <h5
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "0px",
                  marginBottom: "10px",
                }}
              >
                For Office Use Only
              </h5>
            </div>
            <div style={{ width: "100%", float: "left" }}>
              <span
                style={{
                  fontSize: "14px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontStyle: "italic",
                }}
              >
                I acknowledge that Rent-A-Center has delivered, installed and
                demonstrated, to my satisfaction, the rental property listed
                above.
              </span>
            </div>
            <div style={{ textAlign: "center" }}>
              <div style={{ width: "100%", float: "left", marginTop: "20px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    marginBottom: "10px",
                    textDecoration: "underline",
                  }}
                >
                  Customer Call back survey
                </span>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                float: "left",
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "35%", float: "left" }}>
                  <span
                    style={{
                      marginBottom: "0.2rem",
                      color: "#111111",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Date of Call Back
                  </span>
                </div>
                <div style={{ width: "65%", float: "right" }}>
                  <span
                    style={{
                      marginTop: "12px",
                      color: "#656565",
                      borderBottom: borderbotttom,
                      width: "80%",
                      float: "left",
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "30%", float: "left" }}>
                  <span
                    style={{
                      marginBottom: "0.2rem",
                      color: "#111111",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Mgr/Asst Initials
                  </span>
                </div>
                <div style={{ width: "70%", float: "right" }}>
                  <span
                    style={{
                      marginTop: "12px",
                      color: "#656565",
                      borderBottom: borderrbottommm,
                      width: "100%",
                      float: "left",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*fifth part ends*/}
          {/*letter format starts*/}
          <div style={{ width: "100%", fontSize: "14px", float: "left" }}>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                lineHeight: "26px",
              }}
            >
              Hello Mr./Mrs./Ms., this is{" "}
              <span
                style={{
                  marginTop: "12px",
                  color: "#656565",
                  borderBottom: borderrbottommm,
                  paddingRight: "135px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              , the manager of the Rent-A-Center in{" "}
              <span
                style={{
                  marginTop: "12px",
                  color: "#656565",
                  borderBottom: borderrbottommm,
                  paddingRight: "135px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              I want to welcome you as a customer and ask you a few questions
              about the delivery we made to you the other day. Is this a
              convenient time for you to answer a few questions?
            </p>
            <p style={{ fontStyle: "italic", marginTop: "5px" }}>
              {" "}
              (If yes, continue with survey. If no, ask for a convenient time to
              call back)
            </p>
          </div>
          {/*letter format ends*/}
          {/*grid starts here*/}
          <div style={{ width: "100%", float: "left" }}>
            <table style={{ marginBottom: "10px", fontSize: "14px" }}>
              <tbody>
                <tr style={{ width: "100%" }}>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    1. Was the delivery on time ?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    2. Was the unit&apos; operation explained to your
                    satisfaction ?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    3. Have we answered all of your questions regarding the
                    rental agreement?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    3. Have we answered all of your questions regarding the
                    rental agreement?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    4. Can you tell me when your next renewal is due and the
                    amount you will pay?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    5. Did we explain our customer referral program?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    6. Did you take advantage of our Benefits Plus program when
                    you rented your item?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                      marginRight: "20px",
                    }}
                  >
                    If No explain to them the benefits &amp; close the deal.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    7. Do you have any other questions or is there anything I
                    can do for you?
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: "14%",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: "100%", float: "left" }}>
            <p style={{ fontSize: "14px", marginBottom: "5px" }}>Notes :</p>
            <span
              style={{
                marginTop: "20px",
                color: "#656565",
                borderBottom: borderbotttom,
                width: "100%",
                float: "left",
              }}
            />
            <span
              style={{
                marginTop: "30px",
                color: "#656565",
                borderBottom: borderbotttom,
                width: "100%",
                float: "left",
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                width: "100%",
                float: "left",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <p style={{ fontSize: "14px", marginBottom: "5px" }}>
                Thank you for your time and please do not hesitate to call us at
                Rent-A-Center with any questions.
              </p>
            </div>
          </div>
          {/*grid ends here*/}
        </div>
        {/* delivery checklist inner html ends here */}
        {/* pickup receipt innerhtml starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
            paddingLeft: "12px"
          }}
          id="pickupreciptPrintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>

                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    ITEMS
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "100px",
                  }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bindChecklist()}
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: PaddingConst,
                              borderRadius: borderRadiuss,
                              border: Constinter,
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}

              {/*fourth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Employee Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  width: "13.33%",
                  marginLeft: "2%",
                  marginRight: "2%",
                  float: "left",
                  height: "100px",
                  marginBottom: "10px",
                }}
              >
                {/* /> */}
                <Racfont style={{ width: "700%", height: "75%" }}></Racfont>
              </div>
              <div>{/* 5th part ends */}</div>
            </div>
          </div>
          {/* pickup receipt Inner html ends here */}
        </div>
        {/* pickup receipt inner htnl ends here */}
        {/* pickup checklist inner html starts here */}
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
            paddingLeft: "12px"
          }}
          id="pickupchecklistprintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Pickup Checklist
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Pickup Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}
                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Due Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {nextpaymentinfo.nextduedate !== undefined &&
                              nextpaymentinfo.nextduedate !== null
                              ? nextpaymentinfo.nextduedate
                              : ""}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Back Rent:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            $0.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    PICKUP CHECKLIST
                  </h5>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: '0px 8px 0px 8px',
                              borderRadius: '3px',
                              border: '1px solid  #545454',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                      {bindChecklist()}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/* fourth part starts here */}
              <table>
                <thead />
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          width: "100%",
                          float: "left",
                          marginBottom: "250px",
                          marginTop: "10px",
                        }}
                      >
                        <h5
                          style={{
                            fontSize: "14px",
                            textDecoration: "underline",
                            float: "left",
                          }}
                        >
                          Pickup Information
                        </h5>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* fourth part ends here */}

              {/*fifth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "100%", float: "left" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textAlign: "center",
                      textDecorationLine: "underline",
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "10px",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {" "}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: "22%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: "17%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: "8%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      float: "left",
                      marginTop: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ width: "40%", float: "left" }}>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: "60%", float: "right", marginTop: "20px" }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/*-fifth part ends*/}
              {/*sixth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: "100%", float: "left", marginTop: "25px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*sixth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* pickup checklist inner html ends here */}
        <div
          style={{
            width: '90%',
            fontFamily: 'Arial',
            margin: '10px auto 0px auto',
          }}
          id="DeliveryChecklistrentalPdf"
        >
          {/*first part starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
              <div style={{ width: '30%', float: 'left' }}>
                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                  Delivery Checklist
                </h5>
              </div>
              <div
                style={{
                  width: '31%',
                  marginLeft: '2%',
                  marginRight: '2%',
                  float: 'left',
                  height: '100px',
                }}
              >
                <Logo style={{ width: '100%', height: '100%' }}></Logo>
              </div>
              <div></div>
              <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                <thead />
                <tbody style={{ width: '100%', float: 'right' }}>
                  {/*child-parent of first widget*/}
                  <tr style={{ width: '42%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Date:
                    </td>
                  </tr>
                  <tr style={{ width: '58%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryDate}
                      </span>
                    </td>
                  </tr>
                  <tr style={{ width: '43%', float: 'left', textAlign: 'left' }}>
                    <td
                      style={{
                        marginBottom: '0.2rem',
                        color: '#111111',
                        fontSize: '13px',
                        fontWeight: 'bold',
                      }}
                    >
                      Delivery Time:
                    </td>
                  </tr>
                  <tr style={{ width: '57%', float: 'left', textAlign: 'left' }}>
                    <td>
                      <span
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          borderBottom: borderbotttom,
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryTime}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*first part ends here*/}
          {/*second part starts here */}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <div style={{ width: '33.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px' }}>RAC Store</h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '8px',
                    marginBottom: '10px',
                  }}
                >
                  {storename}
                </p>
                <>
                  {StoreAddressline2 != undefined &&
                    StoreAddressline2 != null &&
                    StoreAddressline2 != '' ? (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '8px',
                        marginBottom: 'px',
                      }}
                    >
                      {StoreAddressline1 + ' , ' + StoreAddressline2}
                    </p>
                  ) : (
                    <p
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        marginTop: '8px',
                        marginBottom: '8px',
                      }}
                    >
                      {StoreAddressline1}
                    </p>
                  )}
                </>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {' '}
                  {storecity + ',' + storezip}{' '}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {normalizeinput(Storephonenum)}
                </p>
              </div>
              <div style={{ width: '30.33%', float: 'left' }}>
                <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>Customer</h5>
                <p
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '13px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {CustomerName}
                </p>
                {CustomerAddressline2 !== undefined &&
                  CustomerAddressline2 !== null &&
                  CustomerAddressline2 !== '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {CustomerAddressline1 + ',' + CustomerAddressline2}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {CustomerAddressline1}
                  </p>
                )}
                {customerplus4 != undefined &&
                  customerplus4 != null &&
                  customerplus4 != '' ? (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {customercity + ',' + customerzip + '-' + customerplus4}
                  </p>
                ) : (
                  <p
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '13px',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    {customercity + ',' + customerzip}
                  </p>
                )}
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {normalizeinput(CustomerPhoneNumber)}
                </p>
              </div>
              <div style={{ width: '36.33%', float: 'left', marginTop: '12px' }}>
                <table>
                  <thead />
                  <tbody style={{ width: '100%', float: 'left' }}>
                    <tr style={{ width: '50%', textAlign: 'left', float: 'left' }}>
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr style={{ width: '40%', textAlign: 'left', float: 'left' }}>
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {DeliveryDate}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Agreement # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {AgreementId}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Customer # :
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {CustomerId}{' '}
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '50%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        Next Payment Due:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: '40%',
                        marginTop: '10px',
                        textAlign: 'left',
                        float: 'left',
                      }}
                    >
                      <td
                        style={{
                          marginTop: '5px',
                          color: '#656565',
                          fontSize: '13px',
                        }}
                      >
                        {nextpaymentinfo.nextamountdue !== undefined &&
                          nextpaymentinfo.nextamountdue !== null
                          ? '$' + ' ' + nextpaymentinfo.nextamountdue
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/*second part ends here */}
          {/*3rd part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
              <h5
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontSize: '14px',
                  margin: '0px',
                  marginBottom: '10px',
                }}
              >
                Delivery Checklist
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
              <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th
                      style={{
                        width: '15px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    ></th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Description of Property
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Serial #
                    </th>
                    <th
                      style={{
                        width: '300px',
                        textAlign: 'left',
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                      }}
                    >
                      Item #
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bindChecklist('ResendVideo')}
                </tbody>
              </table>
            </div>
          </div>
          {/*3rd part ends*/}
          {/*fourth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              marginBottom: '4px',
              marginTop: '4px',
            }}
          >
            <div
              style={{
                fontSize: '14px',
                textDecoration: 'underline',
                float: 'left',
              }}
            >
              Delivery Information
            </div>
            <table style={{ width: '100%', float: 'left' }}>
              <thead />
              <tbody style={{ width: '100%', marginTop: '4px', float: 'left' }}>
                <tr style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    CoWorker Signature:
                  </td>
                </tr>
                <tr
                  style={{
                    width: '28%',
                    float: 'left',
                    paddingRight: '25px',
                    whiteSpace: 'nowrap',
                    marginLeft: '8px',
                  }}
                >
                  <td
                    style={{
                      marginTop: '18px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
                <tr style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date:
                  </td>
                </tr>
                <tr style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}>
                  <td
                    style={{
                      marginTop: '15px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </div>
          {/*fourth part ends*/}
          {/*fifth part starts*/}
          <div
            style={{
              width: '100%',
              float: 'left',
              borderBottom: borderrr,
            }}
          >
            <div style={{ width: '100%', float: 'left' }}>
              <h5
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  marginTop: '0px',
                  marginBottom: '5px',
                }}
              >
                For Office Use Only
              </h5>
            </div>
            <div style={{ width: '100%', float: 'left' }}>
              <span
                style={{
                  fontSize: '14px',
                  marginTop: '5px',
                  marginBottom: '5px',
                  fontStyle: 'italic',
                }}
              >
                I acknowledge that Rent-A-Center has delivered, installed and
                demonstrated, to my satisfaction, the rental property listed above.
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    marginBottom: '10px',
                    textDecoration: 'underline',
                  }}
                >
                  Customer Call back survey
                </span>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginTop: '15px',
                marginBottom: '18px',
              }}
            >
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '35%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Date of Call Back
                  </span>
                </div>
                <div style={{ width: '65%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderbotttom,
                      width: '80%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <div style={{ width: '30%', float: 'left' }}>
                  <span
                    style={{
                      marginBottom: '0.2rem',
                      color: '#111111',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    Mgr/Asst Initials
                  </span>
                </div>
                <div style={{ width: '70%', float: 'right' }}>
                  <span
                    style={{
                      marginTop: '12px',
                      color: '#656565',
                      borderBottom: borderrbottommm,
                      width: '100%',
                      float: 'left',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*fifth part ends*/}
          {/*letter format starts*/}
          <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
            <p
              style={{
                fontSize: '14px',
                marginBottom: '4px',
                lineHeight: '26px',
              }}
            >
              Hello Mr./Mrs./Ms., this is{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              , the manager of the Rent-A-Center in{' '}
              <span
                style={{
                  marginTop: '12px',
                  color: '#656565',
                  borderBottom: borderrbottommm,
                  paddingRight: '135px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              />
              I want to welcome you as a customer and ask you a few questions about
              the delivery we made to you the other day. Is this a convenient time
              for you to answer a few questions?
            </p>
            <p style={{ fontStyle: 'italic', marginTop: '4px' }}>
              {' '}
              (If yes, continue with survey. If no, ask for a convenient time to
              call back)
            </p>
          </div>
          {/*letter format ends*/}
          {/*grid starts here*/}
          <div style={{ width: '100%', float: 'left' }}>
            <table style={{ marginBottom: '10px', fontSize: '14px' }}>
              <tbody>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    1. Was the delivery on time ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    2. Was the unit&apos; operation explained to your satisfaction ?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    3. Have we answered all of your questions regarding the rental
                    agreement?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    4. Can you tell me when your next renewal is due and the amount
                    you will pay?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    5. Did we explain our customer referral program?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    6. Did you take advantage of our Benefits Plus program when you
                    rented your item?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingLeft: '30px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                      marginRight: '20px',
                    }}
                  >
                    If No explain to them the benefits &amp; close the deal.
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    7. Do you have any other questions or is there anything I can do
                    for you?
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    Yes
                  </td>
                  <td
                    style={{
                      width: '14%',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    No
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ width: '100%', float: 'left' }}>
            <p style={{ fontSize: '14px', marginBottom: '3px' }}>Notes :</p>
            <span
              style={{
                marginTop: '12px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
            <span
              style={{
                marginTop: '18px',
                color: '#656565',
                borderBottom: borderbotttom,
                width: '100%',
                float: 'left',
              }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                // marginTop: '20px',
                // marginBottom: '20px',
              }}
            >
              <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                Thank you for your time and please do not hesitate to call us at
                Rent-A-Center with any questions.
              </p>
            </div>
          </div>
          {/*grid ends here*/}
        </div>
        <div
          style={{
            borderTop: "10px solid #2179FE",
            margin: "0px",
            width: "100%",
            float: "left",
            paddingLeft: "12px"
          }}
          id="DeliveryReceiptrentalPrintHTML"
        >
          <div
            style={{
              width: "100%",
              fontFamily: "Arial",
              margin: "10px auto 0px auto",
            }}
          >
            {/*first part starts here*/}
            <div style={{ width: "100%", float: "left" }}>
              <div
                style={{ width: "100%", fontFamily: "Arial", float: "left" }}
              >
                <div style={{ width: "20.33%", float: "left" }}>
                  <h5 style={{ textTransform: "uppercase", fontSize: "15px" }}>
                    Delivery Receipt
                  </h5>
                </div>
                <div
                  style={{
                    width: "13.33%",
                    marginLeft: "2%",
                    marginRight: "2%",
                    float: "left",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                >
                  <Logo style={{ width: "100%", height: "100%" }}></Logo>
                </div>
                <div></div>
                <table
                  style={{ width: "62.33%", float: "left", marginTop: "30px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    {/*child-parent of first widget*/}
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Date:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "24%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryDate}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{ width: "22%", float: "left", textAlign: "left" }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Time:
                      </td>
                    </tr>
                    <tr
                      style={{ width: "30%", float: "left", textAlign: "left" }}
                    >
                      <td>
                        <span
                          style={{
                            marginTop: "5px",
                            color: "#656565",
                            borderBottom: borderbotttom,
                            fontSize: "13px",
                          }}
                        >
                          {DeliveryTime}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*first part ends here*/}
              {/*second part starts here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <div style={{ width: "33.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      RAC Store
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storename}
                    </p>
                    <>
                      {StoreAddressline2 != undefined &&
                        StoreAddressline2 != null &&
                        StoreAddressline2 != "" ? (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1 + " , " + StoreAddressline2}
                        </p>
                      ) : (
                        <p
                          style={{
                            textTransform: "uppercase",
                            fontSize: "13px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {StoreAddressline1}
                        </p>
                      )}
                    </>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {storecity + "," + storezip}
                    </p>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(Storephonenum)}{" "}
                    </p>
                  </div>
                  <div style={{ width: "30.33%", float: "left" }}>
                    <h5 style={{ fontSize: "13px", marginBottom: "10px" }}>
                      Customer
                    </h5>
                    <p
                      style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {CustomerName}{" "}
                    </p>
                    {CustomerAddressline2 !== undefined &&
                      CustomerAddressline2 !== null &&
                      CustomerAddressline2 !== "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1 + "," + CustomerAddressline2}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {CustomerAddressline1}
                      </p>
                    )}

                    {customerplus4 != undefined &&
                      customerplus4 != null &&
                      customerplus4 != "" ? (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip + "-" + customerplus4}
                      </p>
                    ) : (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "13px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {customercity + "," + customerzip}
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {normalizeinput(CustomerPhoneNumber)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "36.33%",
                      float: "left",
                      marginTop: "12px",
                    }}
                  >
                    <table>
                      <thead />
                      <tbody style={{ width: "100%", float: "left" }}>
                        <tr
                          style={{
                            width: "50%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Delivery Date:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {DeliveryDate}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Agreement # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {AgreementId}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Customer # :
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {CustomerId}{" "}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginBottom: "0.2rem",
                              color: "#111111",
                              fontSize: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Next Payment Due:
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "40%",
                            marginTop: "10px",
                            textAlign: "left",
                            float: "left",
                          }}
                        >
                          <td
                            style={{
                              marginTop: "5px",
                              color: "#656565",
                              fontSize: "13px",
                            }}
                          >
                            {nextpaymentinfo.nextamountdue !== undefined &&
                              nextpaymentinfo.nextamountdue !== null
                              ? "$" + " " + nextpaymentinfo.nextamountdue
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*second part ends here */}
              {/*3rd part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                }}
              >
                <div
                  style={{ width: "100%", float: "left", marginTop: "15px" }}
                >
                  <h5
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                      fontSize: "13px",
                      margin: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    Items
                  </h5>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "10px" }}
                >
                  <table style={{ marginBottom: "20px", fontSize: "12px" }}>
                    <thead>
                      <tr style={{ width: "100%" }}>
                        <th
                          style={{
                            width: "15px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Description of Property
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "300px",
                            textAlign: "left",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          Item #
                        </th>
                      </tr>
                    </thead>
                    <tbody>{bindChecklist("ResendVideo")}</tbody>
                  </table>
                </div>
              </div>
              {/*3rd part ends*/}
              {/*fourth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: borderrr,
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "100%", float: "left" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      textAlign: "center",
                      textDecorationLine: "underline",
                    }}
                  >
                    Before merchandise is taken from the customer&apos; home,
                    please check the condition of the following areas and note
                    any damage:
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginBottom: "10px",
                    marginTop: "30px",
                  }}
                >
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Wall(s)
                    </span>
                  </div>
                  <div style={{ width: "15%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      {" "}
                      Floors
                    </span>
                  </div>
                  <div style={{ width: "22%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Door(s)/Doorway(s)
                    </span>
                  </div>
                  <div style={{ width: "17%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Stairs
                    </span>
                  </div>
                  <div style={{ width: "8%", float: "left" }}>
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: borderalone,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Other
                    </span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      float: "left",
                      marginTop: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ width: "40%", float: "left" }}>
                    <p
                      style={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      If any boxes were checked, explain damage:
                    </p>
                  </div>
                  <div
                    style={{ width: "60%", float: "right", marginTop: "20px" }}
                  >
                    <span
                      style={{
                        borderBottom: "1px solid#545454",
                        width: "100%",
                        float: "left",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                ><div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "#E0EAFF"
                  }}
                >
                    <input
                      style={{ height: 16, width: 16 }}
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault-7"
                      checked={false}

                    />
                    <label
                      htmlFor="flexCheckDefault-6"
                      style={{
                        marginLeft: 10,
                        fontFamily: 'arial',
                        fontSize: 13,
                        color: '#4A5174',
                      }}
                    >
                      Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                    </label>
                  </div>
                  <div style={{ width: "100%", float: "left" }}>
                    <h5
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        marginTop: "0px",
                        textDecoration: "underline",
                      }}
                    >
                      Accept / Decline (Check one)
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      I acknowledge that Rent-A-Center has delivered, installed
                      and demonstrated, to my satisfaction, the rental property
                      listed above.
                    </span>
                  </div>
                  <div
                    style={{ width: "100%", float: "left", marginTop: "10px" }}
                  >
                    <span
                      style={{
                        padding: PaddingConst,
                        borderRadius: borderRadiuss,
                        border: Constinter,
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontSize: "12px", marginBottom: "10px" }}>
                      I decline delivery by Rent-A-Center of the rental property
                      listed above.
                    </span>
                  </div>
                </div>
              </div>
              {/*-fourth part ends*/}
              {/*fifth part starts*/}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                <table style={{ width: "100%", float: "left" }}>
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Customer Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ width: "100%", float: "left", marginTop: "25px" }}
                >
                  <thead />
                  <tbody style={{ width: "100%", float: "left" }}>
                    <tr
                      style={{
                        width: "18%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Co-Worker Signature:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "28%",
                        float: "left",
                        paddingRight: "25px",
                        whiteSpace: "nowrap",
                        marginLeft: "8px",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "18px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                    <tr
                      style={{
                        width: "8%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginBottom: "0.2rem",
                          color: "#111111",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Date:
                      </td>
                    </tr>
                    <tr
                      style={{
                        width: "32%",
                        float: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <td
                        style={{
                          marginTop: "15px",
                          color: "#656565",
                          borderBottom: borderbotttom,
                          width: "100%",
                          float: "left",
                        }}
                      />
                    </tr>
                  </tbody>
                </table>
                {/*fifth part ends*/}
              </div>
            </div>
          </div>
        </div>
        {/* delivery addendum inner html starts here */}
        <div id="deliveryaddendum">
          <meta charSet="UTF-8" />
          <title>Delivery Addendum And Confirmation</title>
          <div style={{ width: "100%", float: "left", margin: '10px 0px 0px 15px' }}>
            {/*Heading section starts here*/}
            <div
              style={{
                width: "100%",
                float: "left",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Delivery Addendum and Confirmation
              </h3>
            </div>
            {/*Heading section ends here*/}
            {/*detail section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <label
                  style={{
                    float: "left",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Date:
                </label>
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    paddingBottom: "10px",
                    width: "30%",
                    float: "left",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {DeliveryDate}
                </span>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <label
                  style={{
                    float: "left",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Agreement Number:
                </label>
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    paddingBottom: "10px",
                    width: "42%",
                    float: "left",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {AgreementId}
                </span>
              </div>
            </div>
            <div style={{ width: "100%", float: "left", marginBottom: "30px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "20%", float: "left" }}>
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Lessor:
                  </label>
                </div>
                <div style={{ width: "80%", float: "left" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "80%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {storename}
                  </span>
                  {StoreAddressline2 !== undefined &&
                    StoreAddressline2 !== null &&
                    StoreAddressline2 !== "" ? (
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingBottom: "10px",
                        width: "80%",
                        float: "left",
                        marginTop: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {StoreAddressline1 + " , " + StoreAddressline2}
                    </span>
                  ) : (
                    <span
                      style={{
                        borderBottom: "1px solid #000",
                        paddingBottom: "10px",
                        width: "80%",
                        float: "left",
                        marginTop: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {StoreAddressline1 + " , " + StoreAddressline2}
                    </span>
                  )}

                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "80%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {storecity + "," + storezip}
                  </span>
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div style={{ width: "25%", float: "left" }}>
                  <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                    Consumer:
                  </label>
                </div>
                <div style={{ width: "65%", float: "left" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {CustomerName}
                  </span>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {CustomerAddressline1 + "," + CustomerAddressline2}
                  </span>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "10px",
                      width: "91%",
                      float: "left",
                      marginTop: "5px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {customercity + "," + customerzip + "-" + customerplus4}
                  </span>
                </div>
              </div>
            </div>
            {/*detail section ends here*/}
            {/*Agreement parah section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <p
                style={{
                  paddingRight: "70px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Upon execution by you, this document will be incorporated into
                and become part of the Rental-Purchase / Lease Purchase
                Agreement referenced above ( the "Agreement"). By accepting
                delivery and signing below, you are confirming that the
                merchandise described below has been delivered and that the
                serial number(s) listed correctly identifies the items listed on
                your Agreement.
              </p>
            </div>
            {/*Agreement parah section ends here*/}
            {/*DESCRIPTION table section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "60px" }}>
              <h4
                style={{
                  padding: "5px",
                  fontSize: "16px",
                  marginBottom: "0px",
                  borderTop: "1px solid #000",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                  borderRight: "1px solid #000",
                }}
              >
                {" "}
                DESCRIPTION OF DELIVERED MERCHANDISE
              </h4>
              <table
                style={{
                  width: "100%",
                  float: "left",
                  borderBottom: "1px solid #000",
                  borderLeft: "1px solid #000",
                  borderRight: "1px solid #000",
                }}
              >
                <thead>
                  <tr>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Item #
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Item Description
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Serial #
                    </td>
                    <td
                      style={{
                        paddingTop: "12px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Model #
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        9999207978448
                      </span>{" "}
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        MAYTAG 7.0 CF. ELECTRIC DRYER 15
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        999922029478746059
                      </span>
                    </td>
                    <td
                      style={{
                        paddingTop: "10px",
                        fontWeight: 600,
                        fontSize: "14px",
                        paddingBottom: "25px",
                      }}
                    >
                      <span
                        style={{
                          borderBottom: "1px solid #000",
                          paddingBottom: "10px",
                        }}
                      >
                        MEDC465HW
                      </span>
                    </td>
                  </tr> */}
                </thead>
                <tbody>
                  {bindChecklistAddendum()}
                </tbody>
              </table>
            </div>
            {/*DESCRIPTION table section ends here*/}
            {/*PLEASE INITIAL section starts here*/}
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <label
                style={{
                  marginBottom: "25px",
                  width: "100%",
                  float: "left",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                PLEASE INITIAL ONE OF THE FOLLOWING:
              </label>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginBottom: "15px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    width: "45px",
                    float: "left",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  I acknowledge that Rent-A-Center has delivered the rental
                  property listed above.
                </label>
              </div>
              <div
                style={{ width: "100%", float: "left", marginBottom: "15px" }}
              >
                <span
                  style={{
                    borderBottom: "1px solid #000",
                    width: "45px",
                    float: "left",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <label
                  style={{
                    marginBottom: "10px",
                    marginLeft: "15px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  I decline delivery by Rent-A-Center of the rental property
                  listed above.
                </label>
              </div>
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "40px",
                  fontSize: "16px",
                }}
              >
                YOU AGREE BY SIGNING THIS DELIVERY CONFIRMATION SUPPLEMENT THAT
                (1) YOU HAVE READ IT, (2) THE INFORMATION ABOVE IS CORRECTLY
                STATED, AND (3) YOU RECEIVED A COPY OF IT.
              </p>
            </div>
            <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
              <div style={{ width: "50%", float: "left" }}>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "35%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    DATE
                  </label>
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER NAME (PRINT)
                  </label>
                </div>
                <div
                  style={{ width: "100%", float: "left", marginBottom: "40px" }}
                >
                  <label
                    style={{
                      borderTop: "1px solid #000",
                      paddingTop: "10px",
                      width: "75%",
                      float: "left",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    CUSTOMER SIGNATURE
                  </label>
                </div>
              </div>
            </div>
            {/*PLEASE INITIAL section ends here*/}
          </div>
        </div>
        {/* delivery addendum inner html ends here */}
      </Grid>

      {/*Iframe Popup starts here*/}
    </div>
  );
};

export default Footer;