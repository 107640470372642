/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { agrGeneralInfoStyles } from '../../../JSstyles/agrGeneralInfoStyles';
import { paymentCost } from '../../../JSstyles/paymentCost';
import moment from 'moment';
import {
  Grid,
  Typography,
  RACSelect,
  RACButton,
  Card,
  CardContent,
  CircularProgress,
  RACModalCard,
  RACCOLOR,
  RACDatePicker,
} from '@rentacenter/racstrap';
import { ContainerContext } from '../../../app/App';
import { CustomPropInterface } from '../../../index';
import { ReactComponent as Alerticon } from '../../../assets/images/no-records-found.svg';
import CurrencyInput from 'react-currency-input-field';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  getCOpriceQuote,
  GetEmployeeID,
  getAgreementInfo,
  GetRole,
  coCreateAgreement,
  GenerateAgreementDoc,
  CreateAgreement,
} from '../../../api/user';
import { LoanerContext } from '../../../context/LoanerAgreementContext';
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import CustomerSignature from '../../Shared/CustomerSignature';
import { AgreementContext } from '../../../context/AgreementContext';
interface Params {
  customerId: string;
  agreementId: string;
  parentinventoryNumber: string;
}
export default function LoanerAgrDetails({
  CoCustomerDetails: { coCustomerId },
  setItemSearchCompleted,
}) {
  const {
    loanerPayload,
    setLoanerpayload,
    getAgrInfoResponse,
    setgetAgrInfoResponse,
    setAgreementId,
    AgreementId,
    CustomerSignComplete,
    setCustomerSignComplete,
    CustomerSignCancel,
    setCustomerSignCancel,
    setAgreementNumber
  } = useContext(LoanerContext);
  const history = useHistory();
  const search = useLocation().search;
  const location = useLocation();
  const classes = agreementGlobalStyles();
  const agrGeneralInfoClassName = agrGeneralInfoStyles();
  const paymentCostClassName = paymentCost();
  const [salesPersonDiable, setsalesPersonDiable] = useState(false);
  const { customerId, agreementId, parentinventoryNumber } =
    useParams<Params>();
  const [inventoryNumber, setinventorynumber] = useState(parentinventoryNumber);
  const [AgreementCustomersPS, setAgreementCustomersPS]: any = useState([]);
  const [DeliveryChargeOptions, setDeliveryChargeOptions] = useState([
    {
      label: '0.00',
      value: '1',
    },
  ]);
  const [DeliveryChargeOptionsValue, setDeliveryChargeOptionsvalue] =
    useState('1');

  const defaultDropdownValue = {
    value: '',
    label: 'Select',
  };

  const { agreementOptions } = useContext(AgreementContext);
  const PopupCompleteObj = {
    AgreementCreated: false,
    AgreementSigned: false,
    // CustomerSignComplete: false,
    InitialPaymentComplete: false,
    PrelimpaymentPaymentComplete: false,
    AgreementStatus: '',
  };
  const [Rangeactive, setRangeactive] = useState<boolean>(false);
  const [PopupCompleteStatus, setPopupCompleteStatus] =
    useState(PopupCompleteObj);
  const [agreementSourceOptions, setagreementSourceOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [OptServiceDropdownValue, setOptServiceDropdownValue] = useState('');
  const [optService, setOptService] = useState([
    {
      label: 'select',
      value: '',
    },
  ]);
  const [agreementSourceValue, setagreementSourceValue] = useState('');
  const [scheduleOptions, setscheduleOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [PromoCodeValue, setPromoCodeValue] = useState('');
  const [PromoCodeDropDownOptions, setPromoCodeDropDownOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);
  const [daysExtensionValue, setdaysExtensionValue] = useState('');
  const [daysExtension, setdaysExtension] = useState([
    {
      value: '0',
      label: '0',
    },
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
    {
      value: '7',
      label: '7',
    },
  ]);
  const [salesPersonOptions, setsalesPersonOptions] = useState([
    {
      value: '',
      label: 'Select',
    },
  ]);

  const [scheduleValue, setscheduleValue] = useState('');
  const [salesPersonValue, setsalesPersonValue] = useState('');

  const [GenerateDocReq, setGenerateDocReq] = useState({});

  const [previousBtnDisabled, setpreviousBtnDisabled] = useState(false);
  const [nextBtnDisabled, setnextBtnDisabled] = useState(true);
  const [somethingWentWrong, setsomethingWentWrong] = useState(false);
  const [apiFailPopupOpen, setapiFailPopupOpen] = useState(false);
  const [apiFailFirstMessage, setapiFailFirstMessage] = useState('Oops');
  const [apiFailSecondMessage, setapiFailSecondMessage] = useState(
    'Unable to create agreement at this moment'
  );
  const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
  const [createAgrProcessingPopup, setcreateAgrProcessingPopup] =
    useState(false);
  const API_ERROR_MESSAGE = 'Unable to fetch the data';

  const [customerSignatureEnabled, setcustomerSignatureEnabled] =
    useState(false);
  const [documentGenerateCompleted, setdocumentGenerateCompleted] =
    useState(false);
  const [AgrCustomerObj, setAgrCustomerObj]: any = useState([]);

  const [agreementSourceRefCode, setagreementSourceRefCode] = useState('');
  const [agreementDescription, setagreementDescription] = useState('');
  const [nextDueDate, setnextDueDate] = useState('');
  const [agreementRate, setagreementRate] = useState('');
  const [agreementTerm, setagreementTerm] = useState('');
  const [SACPrice, setSACPrice] = useState<any>('');
  const [inputFieldLoader, setInputFieldLoader] = useState(true);
  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];
  useEffect(() => {
    if (AgreementId) {
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    if (
      getAgrInfoResponse?.agreementInformation?.agreementType ==
      'Rent to Rent - Loaner'
    ) {
      setAgreementCustomersPS(getAgrInfoResponse?.agreementCustomer);
      setAgreementId(agreementId);
      setInputFieldLoader(false);
      setnextBtnDisabled(false);
      setagreementSourceOptions([
        {
          value:
            getAgrInfoResponse?.agreementInformation?.agreementSourceRefCode,
          label: getAgrInfoResponse?.agreementInformation?.agreementSource,
        },
      ]);

      setscheduleOptions([
        {
          value: getAgrInfoResponse?.agreementInformation?.scheduleRefCode,
          label: getAgrInfoResponse?.agreementInformation?.paymentSchedule,
        },
      ]);
      setdaysExtension([
        {
          value: getAgrInfoResponse?.agreementInformation?.daysExtension,
          label: getAgrInfoResponse?.agreementInformation?.daysExtension,
        },
      ]);
      setPromoCodeDropDownOptions([
        {
          value: getAgrInfoResponse?.agreementInformation?.promoCode,
          label: getAgrInfoResponse?.agreementInformation?.promoName,
        },
      ]);
      setOptService([
        {
          value: getAgrInfoResponse?.agreementInformation?.optServices,
          label: getAgrInfoResponse?.agreementInformation?.optServices,
        },
      ]);
      setsalesPersonOptions([
        {
          value: getAgrInfoResponse?.agreementInformation?.salesPersonId,
          label: getAgrInfoResponse?.agreementInformation?.salesPerson,
        },
      ]);
      setagreementSourceValue(
        getAgrInfoResponse?.agreementInformation?.agreementSourceRefCode
      );
      setscheduleValue(
        getAgrInfoResponse?.agreementInformation?.scheduleRefCode
      );
      setdaysExtensionValue(
        getAgrInfoResponse?.agreementInformation?.daysExtension
      );
      setPromoCodeValue(getAgrInfoResponse?.agreementInformation?.promoCode);
      setOptServiceDropdownValue(
        getAgrInfoResponse?.agreementInformation?.optServices
      );
      setsalesPersonValue(
        getAgrInfoResponse?.agreementInformation?.salesPersonId
      );
      setsalesPersonDiable(true);
      setnextDueDate(
        moment(getAgrInfoResponse?.agreementInformation?.dueDate).format(
          'YYYY-MM-DD'
        )
      );
    } else {
      getAgreementInfoFn();
      if (agreementOptions) {
        const AgrSourceArray = agreementOptions.agreementSource.map(
          (ele: any) => {
            return { value: ele.refCode, label: ele.desc };
          }
        );
        const OptServiceArray = agreementOptions.optServices.map((ele: any) => {
          return { value: ele.desc, label: ele.desc };
        });
        const PromoCodeArray = agreementOptions.storePromotions.map(
          (ele: any) => {
            return { value: ele.promoCode, label: ele.promoName };
          }
        );
        const SalesPersonArray = agreementOptions.salesPerson.map(
          (ele: any) => {
            return { value: ele.id.toString(), label: ele.name };
          }
        );
        const UniqScheduleOptions = agreementOptions.schedule.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.desc === value.desc)
        );
        const ScheduleArray: any = UniqScheduleOptions.map((ele: any) => {
          return { value: ele.refCode, label: ele.desc };
        });
        SalesPersonArray.splice(0, 0, defaultDropdownValue);
        setPromoCodeDropDownOptions(PromoCodeArray);
        setagreementSourceOptions(AgrSourceArray);
        setOptService(OptServiceArray);
        setsalesPersonOptions(SalesPersonArray);
        setscheduleOptions(ScheduleArray);
      }
    }
  }, []);
  console.log('agreementOptionslOANER', agreementOptions);
  const getAgreementInfoFn = async () => {
    const agrmeentId = agreementId;
    const getAgrRes = await getAgreementInfo(agrmeentId);
    setInputFieldLoader(false);
    if (getAgrRes.status == 200) {
      console.log('getAgrResLoaner', getAgrRes);
      setscheduleValue(getAgrRes?.data?.agreementInformation?.scheduleRefCode);
      setagreementSourceValue(
        getAgrRes?.data?.agreementInformation?.agreementSourceRefCode
      );
      setagreementDescription(
        getAgrRes?.data?.agreementInformation?.agreementDescription
      );
      setdaysExtensionValue(
        getAgrRes?.data?.agreementInformation?.daysExtension
      );
      setPromoCodeValue(getAgrRes?.data?.agreementInformation?.promoCode);
      setOptServiceDropdownValue(
        getAgrRes?.data?.agreementInformation?.optServices
      );
      setagreementRate(getAgrRes?.data?.agreementInventories[0]?.agreementRate);
      setagreementTerm(getAgrRes?.data?.agreementInventories[0]?.agreementTerm);
      setSACPrice(getAgrRes?.data?.totalPayment?.cashPrice);
      setnextDueDate(
        moment(getAgrRes?.data?.agreementInformation?.dueDate).format(
          'YYYY-MM-DD'
        )
      );
    } else {
      setsomethingWentWrong(true);
    }
  };

  const SalesPersonOnchange = (value: any) => {
    setsalesPersonValue(value);
    if (value != '') {
      setnextBtnDisabled(false);
    } else {
      setnextBtnDisabled(true);
    }
  };

  const PreviousBtnClick = () => {
    if (
      getAgrInfoResponse?.agreementInformation?.agreementType ==
      'Rent to Rent - Loaner'
    ) {
      history.push({
        pathname: `/agreement/loaner/itemsearch/${customerId}/${AgreementId}`,
        search: `?inventoryNumber=${parentinventoryNumber}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (AgreementId) {
      history.push({
        pathname: `/agreement/loaner/itemsearch/${customerId}/${AgreementId}`,
        search: `?inventoryNumber=${parentinventoryNumber}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else if (!AgreementId) {
      history.push({
        pathname: `/agreement/loaner/itemsearch/${customerId}/${agreementId}`,
        search: `?inventoryNumber=${parentinventoryNumber}`,
      });
      // communicate to Routes that URL has changed//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };

  const NextBtnClick1 = async () => {
    if (
      getAgrInfoResponse?.agreementWorkflow?.isAgreementSigned == '0' &&
      getAgrInfoResponse?.agreementInformation?.agreementType ==
      'Rent to Rent - Loaner' &&
      !CustomerSignComplete
    ) {
      setdocumentGenerateCompleted(true);
      const Customerdata = AgreementCustomersPS?.filter(
        (obj: any) => obj.priority == '1'
      );

      const CustomerName =
        Customerdata[0]?.firstName + ' ' + Customerdata[0].lastName;
      if (AgreementCustomersPS.length === 1) {
        const AGreementDocObj: any = GenerateDocReq;
        const CustomerObj = [
          {
            customerId: Customerdata[0].customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0].phoneNumber,
            emailId: Customerdata[0].emailId,
            priority: 1,
          },
        ];
        console.log('PayloadForm1');
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        setAgrCustomerObj(CustomerObj);
        setcustomerSignatureEnabled(true);
      } else {
        const AGreementDocObj: any = GenerateDocReq;
        const CoCustomer = AgreementCustomersPS.filter(
          (obj) => obj.priority == '2'
        );
        const CoCustomerName =
          CoCustomer[0]?.firstName + ' ' + CoCustomer[0]?.lastName;
        const CustomerObj = [
          {
            customerId: Customerdata[0]?.customerId.toString(),
            customerName: CustomerName,
            phoneNumber: Customerdata[0]?.phoneNumber,
            emailId: Customerdata[0]?.emailId,
            priority: 1,
          },
          {
            customerId: CoCustomer[0].customerId.toString(),
            customerName: CoCustomerName,
            phoneNumber: CoCustomer[0].phoneNumber,
            emailId: CoCustomer[0].emailId,
            priority: 2,
          },
        ];
        AGreementDocObj.signers = CustomerObj;
        setGenerateDocReq(AGreementDocObj);
        setAgrCustomerObj(CustomerObj);
        setcustomerSignatureEnabled(true);
      }
    } else if (!AgreementId) {
      let parentInventoryNum = getAgrInfoResponse?.agreementInventories.filter(
        (obj) => obj.inventoryNumber == parentinventoryNumber
      );
      parentInventoryNum = parentInventoryNum[0].inventoryId;
      const payload = {
        agreementInfo: {
          agreementDesc: loanerPayload[0]?.itemDescription,
          // agreementDesc: 'SAMSUNG 5G 6.6 GALAXY A42 4GB,128GB SMARTPHNE, BLK',
          agreementType: 'LOAN',
          storeNumber: window.sessionStorage.getItem('storeNumber'),
          // storeNumber: '00582',
          agreementSource: 'W',
          // agreementSource: 'W',
          salesPersonId: Number(salesPersonValue),
          // salesPersonId: 1,
          agreementRate: Number(agreementRate),
          // agreementRate: 23.0,
          agreementTerm: Number(agreementTerm),
          // agreementTerm: 78,
          requestAutopay: false,
          sacPrice: Number(SACPrice),
          parentAgreementId: Number(agreementId),
        },
        fees: [],
        customer: [
          {
            customerId: Number(customerId),
            // customerId: 15157,
            priority: 1,
          },
        ],
        coverages: {
          isLdwApplied: false,
          isClubApplied: false,
        },
        inventoryInfo: [
          {
            inventoryId: loanerPayload[0]?.inventoryId,
            inventoryPriceId: loanerPayload[0]?.inventoryPriceId,

            parentInventoryId: Number(parentInventoryNum),
          },
        ],
      };
      setcreateAgrProcessingPopup(true);
      const createAgrRes = await CreateAgreement(payload);
      setcreateAgrProcessingPopup(false);
      if (createAgrRes.status == 200) {

        setAgreementNumber(String(createAgrRes?.data?.agreementNumber));        setPopupCompleteStatus({
          ...PopupCompleteStatus,
          AgreementCreated: true,
        });
        setCustomerSignComplete(false);
        setAgreementId(createAgrRes.data.agreementId.toString());
        setsalesPersonDiable(true);
        history.push({
          pathname: `/agreement/loaner/details/${customerId}/${createAgrRes.data.agreementId.toString()}`,
        });
        setcreateAgrProcessingPopup(false);
        const AgreementCustomers = createAgrRes.data.customers;
        const Customerdata = AgreementCustomers.filter(
          (obj) => obj.priority == 1
        );

        const CustomerName =
          Customerdata[0].firstName + ' ' + Customerdata[0].lastName;
        if (AgreementCustomers.length === 1) {
          const AGreementDocObj: any = GenerateDocReq;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0].phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
          ];
          console.log('PayloadForm1');
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        } else {
          const AGreementDocObj: any = GenerateDocReq;
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber: Customerdata[0].phoneNumber,
              emailId: Customerdata[0].emailAddress,
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber: CoCustomer[0].phoneNumber,
              emailId: CoCustomer[0].emailAddress,
              priority: 2,
            },
          ];
          AGreementDocObj.signers = CustomerObj;
          setGenerateDocReq(AGreementDocObj);
          setAgrCustomerObj(CustomerObj);
          setcustomerSignatureEnabled(true);
        }
        const AgreementDocReq: any = GenerateDocReq;
        AgreementDocReq.identifier = parseInt(createAgrRes.data.agreementId);
        AgreementDocReq.identifierType = 'AGR';
        AgreementDocReq.signatureType = 1;
        AgreementDocReq.isRemote = false;
        AgreementDocReq.manualSignatureReasonId = null;
        AgreementDocReq.signingUrlRequired = false;
        AgreementDocReq.documentType = ['LOAN'];
        AgreementDocReq.returnURL = 'https://qa-menu-racpad.rentacenter.com';

        if (AgreementCustomers.length === 1) {
          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        } else {
          const CoCustomer = AgreementCustomers.filter(
            (obj) => obj.priority == 2
          );
          const CoCustomerName =
            CoCustomer[0].firstName + ' ' + CoCustomer[0].lastName;

          const CustomerObj = [
            {
              customerId: Customerdata[0].customerId.toString(),
              customerName: CustomerName,
              phoneNumber:
                Customerdata[0].phoneNumber !== null
                  ? Customerdata[0].phoneNumber
                  : '1111111111',
              emailId:
                Customerdata[0].emailAddress !== null
                  ? Customerdata[0].emailAddress
                  : 'test@example.com',
              priority: 1,
            },
            {
              customerId: CoCustomer[0].customerId.toString(),
              customerName: CoCustomerName,
              phoneNumber:
                CoCustomer[0].phoneNumber !== null
                  ? CoCustomer[0].phoneNumber
                  : '1111111111',
              emailId:
                CoCustomer[0].emailAddress !== null
                  ? CoCustomer[0].emailAddress
                  : 'test@example.com',
              priority: 2,
            },
          ];
          AgreementDocReq.signers = CustomerObj;
        }
        setpreviousBtnDisabled(true);
        const GenerateAgreementDocRes = await GenerateAgreementDoc(
          AgreementDocReq
        );
        console.log(
          'First Generate Agreement Call response',
          GenerateAgreementDocRes
        );
        setpreviousBtnDisabled(false);
        setdocumentGenerateCompleted(true);
      } else if (createAgrRes.status == 400) {
        setcreateAgrProcessingPopup(false);
        const ErrorMessage = createAgrRes.data.errors[0].error;
        setapiFailFirstMessage(ErrorMessage);
        setapiFailSecondMessage('Please try again');
        setapiFailPopupOpen(true);
        setsalesPersonValue('');
      } else {
        setcreateAgrProcessingPopup(false);
        setsomethingWentWrongOpen(true);
      }
    }
    else if (CustomerSignCancel) {
    setcustomerSignatureEnabled(true)
    }
    else {
      history.push({
        pathname: `/agreement/loaner/delivery/${customerId}/${AgreementId}`,
      });
      // communicate to Routes that URL has changed=//
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const customerSignatureCompletedFn = () => {
    setcustomerSignatureEnabled(false);
    setCustomerSignComplete(true);
    setCustomerSignCancel(false);
  };
  const customerSignatureCanceledFn = () => {
    setcustomerSignatureEnabled(false);
    setCustomerSignCancel(true)
  };
  // eslint-disable-next-line sonarjs/no-identical-functions
  const emailcompletedFn = () => {
    setcustomerSignatureEnabled(false);
    setCustomerSignComplete(true);
  };
  const CreateAgreementProcessingPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>Please wait...</Typography>
          <Typography className={classes.formLabel}>
            We are creating the agreement for you!
          </Typography>
          <CircularProgress style={{ marginTop: '30px' }} size={25} />
        </Grid>
      </div>
    );
  };
  const ApiFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            {apiFailFirstMessage}
          </Typography>
          <Typography className={classes.formLabel}>
            {' '}
            {apiFailSecondMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setapiFailPopupOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const SomethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setsomethingWentWrongOpen(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <Grid>
      {customerSignatureEnabled ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="loaner"
          identifier={parseInt(AgreementId)}
          customersArr={AgrCustomerObj}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={['LOAN']}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          emailcompletedFn={() => emailcompletedFn()}
          documentGenerateCompleted={documentGenerateCompleted}
        />
      ) : null}
      {somethingWentWrong ? (
        <div
          className="Norecords"
          style={{ marginTop: '20%', marginLeft: '40%' }}
        >
          <Alerticon></Alerticon>
          Something went wrong!
        </div>
      ) : (
        <Grid>
          <RACModalCard
            isOpen={apiFailPopupOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {ApiFailPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={somethingWentWrongOpen}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {SomethingWentWrongPopup()}
          </RACModalCard>
          <RACModalCard
            isOpen={createAgrProcessingPopup}
            closeIcon={false}
            maxWidth="xs"
            borderRadius={'20px !important'}
          >
            {' '}
            {CreateAgreementProcessingPopup()}
          </RACModalCard>
          <Grid className={classes.px2}>
            <Grid>
              <Grid container className={classes.mt1}>
                <Typography
                  variant="h6"
                  className={`${classes.title} ${classes.mb1}`}
                >
                  General Information
                </Typography>
              </Grid>

              <Grid container>
                <Grid item md={12} className={classes.mb4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container className={classes.mb2}>
                        <Grid
                          item
                          className={agrGeneralInfoClassName.listitemstyles}
                        >
                          <Grid
                            item
                            xs="auto"
                            className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                          >
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColor
                              }
                            >
                              Agreement Type :{' '}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={
                                agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                              }
                            >
                              {'Loaner'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            options={agreementSourceOptions}
                            defaultValue={agreementSourceValue}
                            // isDisabled={DropDownDisable}
                            isDisabled={true}
                            inputLabel="Agreement Source"
                            loading={inputFieldLoader}
                          // {...(DropdownOptions.AgreementSource.length == 0 &&
                          //   dropDownLoaded && {
                          //     errorMessage: API_ERROR_MESSAGE,
                          //   })}
                          />
                        </Grid>

                        <Grid item sm={4} md={2}>
                          <RACSelect
                            isDisabled={true}
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            options={scheduleOptions}
                            name="Schedule"
                            defaultValue={scheduleValue}
                            inputLabel="Schedule"
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   ScheduleChange(e.target.value);
                            // }}
                            // isDisabled={DropDownDisable}
                            loading={inputFieldLoader}
                          // {...(DropdownOptions.Schedulevalues.length == 0 &&
                          //   dropDownLoaded && {
                          //     errorMessage: API_ERROR_MESSAGE,
                          //   })}
                          />
                          <Typography
                            variant="body1"
                            className={classes.font14}
                          >
                            Term
                            <Typography
                              variant="caption"
                              className={`${classes.bold} ${classes.racTextGreen} ${classes.ms1}`}
                            >
                              0
                            </Typography>
                          </Typography>
                        </Grid>

                        {Rangeactive === true ? (
                          <Grid item sm={4} md={2}>
                            <RACSelect
                              inputLabelClassName={
                                agrGeneralInfoClassName.dropDownLabel
                              }
                              isDisabled={true}
                              // options={DropdownOptions.Range}
                              name="Range"
                              // defaultValue={DropdownValues.Range}
                              inputLabel="Range"
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   RangeOnchange(e.target.value);
                            // }}
                            // isDisabled={DropDownDisable}
                            // loading={DropdownLoading}
                            // {...(DropdownOptions.Range.length == 0 &&
                            //   dropDownLoaded && {
                            //     errorMessage: API_ERROR_MESSAGE,
                            //   })}
                            />
                          </Grid>
                        ) : null}

                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            isDisabled={true}
                            options={DeliveryChargeOptions}
                            defaultValue={DeliveryChargeOptionsValue}
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   DeliveryChargeOnchange(e.target.value);
                            // }}
                            inputLabel="Delivery Charge ($)"
                            // isDisabled={props.DeliveryChargeDisable}
                            loading={inputFieldLoader}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACDatePicker
                            value={nextDueDate}
                            label="Due Date"
                            name="duedate"
                            disabled={true}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            options={daysExtension}
                            defaultValue={daysExtensionValue}
                            // isDisabled={
                            //   props.PromoCodeValue !== ''
                            //     ? true
                            //     : props.EditDisablemode
                            // }
                            isDisabled={true}
                            inputLabel="Days Extension"
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   DaysExtensionOnchange(e.target.value);
                            // }}
                            loading={inputFieldLoader}
                          />
                        </Grid>

                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            name=" PromoCode"
                            defaultValue={PromoCodeValue}
                            options={PromoCodeDropDownOptions}
                            // isDisabled={
                            //   DropDownDisable || props.PromoCodeDisable
                            //     ? true
                            //     : false
                            // }
                            isDisabled={true}
                            inputLabel="Promo Code"
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   PromoCodeOnchange(e.target.value);
                            // }}
                            loading={inputFieldLoader}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            isDisabled={true}
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            options={optService}
                            name="Opt Services"
                            defaultValue={OptServiceDropdownValue}
                            // isDisabled={OptServicesDisable}
                            inputLabel="Opt Services"
                            // onChange={(
                            //   e: React.ChangeEvent<HTMLSelectElement>
                            // ) => {
                            //   OptServicesOnchange(e.target.value);
                            // }}
                            loading={inputFieldLoader}
                          // {...(DropdownOptions.OPtServices.length == 0 &&
                          //   dropDownLoaded && {
                          //   errorMessage: API_ERROR_MESSAGE,
                          // })}
                          />
                        </Grid>
                        <Grid item sm={4} md={2}>
                          <RACSelect
                            inputLabelClassName={
                              agrGeneralInfoClassName.dropDownLabel
                            }
                            isDisabled={salesPersonDiable ? true : false}
                            // isDisabled={AgreementId ? true : false}
                            options={salesPersonOptions}
                            // name=" Sales Person"
                            defaultValue={salesPersonValue}
                            // isDisabled={DropDownDisable}
                            inputLabel="SalesPerson"
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              SalesPersonOnchange(e.target.value);
                            }}
                            loading={inputFieldLoader}
                            required={true}
                          // {...(DropdownOptions.SalesPerson.length == 0 &&
                          //   dropDownLoaded && {
                          //   errorMessage: API_ERROR_MESSAGE,
                          // })}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={classes.mb6}>
              <Grid item className={paymentCostClassName.paymentWidget}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Initial Payment
                    </Typography>

                    <Card className={classes.card}>
                      <CardContent className={classes.p32}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt2}`}
                        >
                          <Grid item md={6} className={classes.floatLeft}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Other
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Grid className={classes.floatRight}>
                              <Typography
                                className={`${paymentCostClassName.inputGroupText} ${classes.floatLeft}`}
                              >
                                $
                              </Typography>
                              <CurrencyInput
                                id="input-example"
                                name=""
                                style={{ zIndex: 0, textAlign: 'right' }}
                                className={`      ${paymentCostClassName.formControl} ${paymentCostClassName.currencyInput} ${paymentCostClassName.disabledColour}`}
                                decimalsLimit={2}
                                data-testid="ProcessingFeeTxtBoxTest"
                                value={0.0}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Rate
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Discount
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Extension Amount
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              LDW
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.cardLabel}
                            >
                              Tax
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.widgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.pt2} ${paymentCostClassName.payscheduleBorder} ${paymentCostClassName.alignCenter} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={`${classes.font18} ${classes.semiBold}`}
                            >
                              Initial Payment{' '}
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={`${classes.font24} ${classes.bold} ${classes.textRight}`}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={6}>
                    <Typography className={classes.title}>
                      Total Payment/Total Cost
                    </Typography>

                    <Card className={paymentCostClassName.bluecard}>
                      <CardContent className={classes.p4}>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              Initial Payment
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueWidgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              Regular Payments
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueWidgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={7}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              Final Payment
                            </Typography>
                          </Grid>
                          <Grid item md={5}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueWidgetAlign}
                            >
                              $ 0.00
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.mb2} ${classes.mt4}`}
                        >
                          <Grid item md={12}>
                            <Typography
                              variant="subtitle1"
                              className={paymentCostClassName.blueCardLabel}
                            >
                              {' '}
                              Total Payments
                            </Typography>
                          </Grid>
                        </Grid>

                        <Card
                          className={`${classes.card} ${classes.p3} ${paymentCostClassName.mtcustom} ${paymentCostClassName.totalPaymentShade}`}
                        >
                          <Grid container className={classes.mb2}>
                            <Grid item md={7} className={classes.floatLeft}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.totalValue}
                              >
                                Same As Cash Total
                              </Typography>
                            </Grid>

                            <Grid item md={5}>
                              <Grid className={classes.floatRight}>
                                <Typography
                                  variant="subtitle1"
                                  className={
                                    paymentCostClassName.agreementTotal
                                  }
                                >
                                  $ 0.00
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            className={paymentCostClassName.alignCenter}
                          >
                            <Grid item md={7}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.totalValue}
                              >
                                Agreement Total
                              </Typography>
                            </Grid>
                            <Grid item md={5}>
                              <Typography
                                variant="subtitle1"
                                className={paymentCostClassName.agreementTotal}
                              >
                                $ 0.00
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.fixedBottom}>
              <RACButton
                className={`${classes.mx1} ${classes.floatLeft}`}
                variant="outlined"
                color="primary"
                onClick={() => PreviousBtnClick()}
                disabled={previousBtnDisabled}
              >
                Previous
              </RACButton>
              <Grid style={{ float: 'right' }}>
                <RACButton
                  className={`${classes.me2} ${classes.floatRight}`}
                  variant="contained"
                  color="primary"
                  onClick={() => NextBtnClick1()}
                  disabled={nextBtnDisabled}
                >
                  Next
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

