/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
import React, { useState, useEffect, createContext, useContext } from 'react';
import { agreementGlobalStyles } from '../../../JSstyles/agreementGlobalStyles';
import { searchResultStyles } from '../../../JSstyles/searchResultStyles';
import { CustomerOrderAgreementContext } from '../../../context/CustomerOrderAgreementContext';
import { AgreementContext } from '../../../context/AgreementContext';
import ExceptionController from './exceptionController';
import FooterContent from './footerInventory';
import SearchFilter from './searchCriteria';
import DynamicGridComponent from './grid';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom'; import {
  getAgreementOptions,
  customerOrderSearch,
  getSalesInfo,
} from '../../../api/user';

import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  RACModalCard,
  RACButton
} from '@rentacenter/racstrap';
import SearchContainer from './Item search TB/itemSearchTB';
//PS - 14 Importing all required files from the corresponding
export const Usercontext = createContext({});
export interface columns {
  columnId: string;
  IsSorted: boolean;
  sortOrder: string;
  columnType?: 'link';
  columnTitle: string;
  IsSortable: boolean;
  columnColor?: string;
  subColumns?: columns[];
  handleColumnClick?: () => void;
}
interface Params {
  customerId: string;
}
const CustomerOrderSearchResult = (props: any) => {
  const classes = agreementGlobalStyles(); //This will hold the global styles
  const agrComponent = searchResultStyles(); //This will hold the component specific styles
  const {
    searchResult,
    setsearchResult,
    agreementCreateStatus,
    setagreementOptions,
    agreementOptions,
    setcrossedItemSearch,
    setPromoCode,
    setToggleTB,
    toggleTB //Nagaarjun
  } = useContext(CustomerOrderAgreementContext); //This is the destructured data of Agreement Context
  const { isAllDone } = useContext(AgreementContext);
  // const { setagreementOptions, agreementOptions } =
  //   useContext(AgreementContext);
  const sortDirection = {
    ASC: 'asc',
    DESC: 'desc',
  }; //This will be handling the sorting order of search result
  const resultControllerObj = {
    controlRecord: false,
    controlEmptyRecord: false,
    internalServerController: false,
  }; //This object will hold all the state of the data of API response
  const loaderObj = {
    Gridloader: true,
    masterLoader: false,
    loaderController: false,
  }; //This object will hold all the loader state
  const { customerId } = useParams<Params>();
  const [loaderBundle, setLoaderBundle] = useState(loaderObj);
  const [resultController, setresultController] = useState(resultControllerObj);
  const [gridData, setGridData] = useState<any>();
  const [controlViewMode, setControlViewMode] = useState(false);
  const [footerControlData, setfooterControlData] = useState(true);
  //PS - 15 Declaration of all required state variable for performing appropriate action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerId, setcocustomerId] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coCustomerName, setcoCustomerName] = useState('');
  const storeNumber = window.sessionStorage.getItem('storeNumber');
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  const [storeSalesData, setstoreSalesData] = useState<any>();
  const dynamicViewClass = controlViewMode
    ? agrComponent.disableme
    : 'enableme'; //This will be controlling the view and edit mode of entire page
  const showItemSearch = isAllDone
    ? agrComponent.showItemSearch
    : agrComponent.hideItemSearch;
  useEffect(() => {
    // console.log("before getSearchCriteriaContext", getSearchCriteriaContext);
    console.log('before getsearchResultContext', searchResult);
    console.log('Status of the agreement is', agreementCreateStatus);
    if (searchResult !== undefined && agreementCreateStatus !== undefined) {
      console.log('entered');
      if (agreementCreateStatus.agreementCreatedStatus === true) {
        console.log('entered view mode');
        setControlViewMode(true);
        setfooterControlData(false);
        setGridData(searchResult);
        setcrossedItemSearch(true);
      } else {
        console.log('entered edit mode');
        if (searchResult.length <= 0) {
          setresultController({
            ...resultControllerObj,
            controlEmptyRecord: true,
          });
        } else {
          setGridData(searchResult);
        }
        setLoaderBundle({
          ...loaderBundle,
          Gridloader: false,
          loaderController: false,
        });
        setfooterControlData(false);
      }
    }
    async function GetDropdownApiCall() {
      const dropdownval: any = await getAgreementOptions(
        customerId,
        storeNumber
      );
      console.log('New res', dropdownval);
      if (dropdownval?.status === 200) {
        setagreementOptions(dropdownval.data);
      }
    }
    if (agreementOptions == undefined) {
      GetDropdownApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //PS - 16 This method will be prepopulating all the values when previous scenario is handled
  useEffect(() => {
    if (salesLeadId !== null) {
      console.log('Inside the SalesLeadInformation condition in search Result' )
      const salesValue = async () => {
        const salesData = await getSalesInfo(salesLeadId);
        console.log('salesDatakgyg', salesData);

        if (salesData.status == 200) {
          setstoreSalesData(salesData.data);
          if (salesData.data.inventories[0].reservedStoreNumber === null) {
            const req: any = {
              model: salesData.data.modelNumber,
              storeNumber: salesData.data.storeNumber,
            };
            setPromoCode(salesData.data.promoCode);
            if (salesData.data.modelNumber) {
              searchClick(req);
            }
          }

          if(salesData.data.orderInfo.fitmentId !== null && salesData.data?.inventories[0].modelNumber !== null){   
            console.log('Inside the tierBuyer condition for enabling the condition')
            setToggleTB(true)
          }
        }
      };
      salesValue();
    }
    console.log('Received Query Param Data', salesLeadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesLeadId]);
  const pull_data = (data) => {
    setcocustomerId(data.coCustomerId);
    setcoCustomerName(data.coCustomerName);
    props.func({
      coCustomerId: data.coCustomerId,
      coCustomerName: data.coCustomerName,
    });
    // eslint-disable-next-line no-console
    console.log('Search Result Props', data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
  };
  const suppiler = () => {
    console.log('response in suppiler');
    console.log('cvgfdcvgfd');
    props.somedeeptrigger();
  };
  const searchClick = async (value: any) => {
    setsearchResult([]);
    setGridData([]);
    setLoaderBundle({
      ...loaderBundle,
      Gridloader: true,
      loaderController: true,
    });
    setfooterControlData(true);
    setcrossedItemSearch(false);
    props.setItemSearchCompleted(false);
    console.log('value in search click', value);
    const currentStore = sessionStorage.getItem('storeNumber');
    const req: any = {
      modelNumber: [value.model.toUpperCase()],
      storeNumber: currentStore,
    };
    const response: any = await customerOrderSearch(req);
    console.log('vfbkfnjknkjkdskj', response);
    if (response.status == 500) {
      setresultController({
        ...resultControllerObj,
        internalServerController: true,
      }); //This will render Some thing went wrong message in search result
      setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
    } else if (
      response.status == 200 &&
      response.data.rmsItemInformation.length > 0
    ) {
      setsearchResult(response.data.rmsItemInformation); //This will be storing the response of the inventory search API
      setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
      console.log('Response from api', response);
      if (response.data.rmsItemInformation[0].availability.maxQuantity <= 0) {
        console.log('APIResponseNoRecords', response);
        setresultController({ ...resultControllerObj, controlRecord: true });
      } else {
        console.log('APIResponse', response);
        setGridData(response.data.rmsItemInformation); //This will assign the API result to get the Search Result grid get load.
      }
    } else if (
      response.status == 400 ||
      response.data.rmsItemInformation.length <= 0
    ) {
      setresultController({ ...resultControllerObj, controlRecord: true });
      setLoaderBundle({ ...loaderBundle, loaderController: false }); //The loader is disabled here
    }
  }; //PS - 20 This method is called every time when Search button is clicked. This method will be triggering the Search Inventory API call with the corresponding request parameter.
  const gridCallMethod = () => {
    console.log('Came inside Grid Method');
    if (gridData !== undefined && gridData.length > 0) {
      console.log('Inside table bind', gridData);
      return (
        <div>
          <Typography className={`${classes.title} ${classes.mt3}`}>
            Search Results
          </Typography>
          <Card className={`${classes.card} ${classes.mb4} ${classes.p3}`}>
            <CardContent className={classes.p0}>
              <DynamicGridComponent
                items={gridData}
                handleActionType={() => setfooterControlData(false)}
                order={sortDirection.ASC}
                throwStyle={classes}
              ></DynamicGridComponent>
            </CardContent>
          </Card>
        </div>
      ); //This is rendered when the API response has a definite data.
    } else if (resultController.internalServerController) {
      return (
        <ExceptionController
          throwExceptionDecider="Went Wrong"
          throwStyle={classes}
        />
      ); //This is rendered when 500 occours
      /*  } else if (resultController.controlRecord) {
         return (
           <ExceptionController
             throwExceptionDecider="No Records"
             throwStyle={classes}
           />
         ); //This is rendered when no search result id found */
    } else if (resultController.controlEmptyRecord) {
      return (
        <ExceptionController
          throwExceptionDecider="No Item"
          throwStyle={classes}
        />
      ); //This is rendered when no item to add further
    }
  }; //PS - 21 This method is called when the API result arrives to a variable. Based on the response the data is displayed in the Search Result area.
  const ApiFailPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Typography className={classes.formLabel}>
            The item you’re trying to order is temporarily out of stock.  For additional assistance trying to order this item, please contact your Purchasing Operations Specialist.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setresultController({ ...resultControllerObj, controlRecord: false })}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  }; 
  return (
    <div className={`${classes.agreementContainerSpacing} ${showItemSearch}`}>
      <div>
        {loaderBundle.masterLoader ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        {/* Nagaarjun started here */}
       {!toggleTB ? <Grid container spacing={2} className={dynamicViewClass}>
          <Grid item className={agrComponent.expandWigid}>
            <SearchFilter searchClick={searchClick} throwStyle={classes} />
            <div>
              {loaderBundle.loaderController === true &&
                loaderBundle.Gridloader === true ? (
                <ExceptionController
                  throwExceptionDecider="Data Delay"
                  throwStyle={classes}
                />
              ) : (
                gridCallMethod()
              )}
            </div>
          </Grid>
        </Grid> :
          <Grid>
            <SearchContainer salesInfo={storeSalesData}/>
          </Grid>}
        {/* Nagaarjun Ended here */}
        <RACModalCard
          isOpen={resultController.controlRecord}
          closeIcon={false}
          maxWidth="xs"
          borderRadius={'20px !important'}
        >
          {ApiFailPopup()}
        </RACModalCard>
        <FooterContent
          setItemsearchCompleted={props.setItemSearchCompleted}
          controlData={footerControlData}
          throwStyle={classes}
          func={pull_data}
          sometrigger={suppiler}
        />
      </div>
    </div>
  );
};
export default CustomerOrderSearchResult;
