/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  RACModalCard,
  RACSelect,
  RACButton,
  CircularProgress,
  Card,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
  List,
  ListItem,
} from '@rentacenter/racstrap';
import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as Successicon } from '../../assets/images/success-icon.svg';
import {
  getAgreementsByCustomerId,
  splitAgreement,
  GenerateAgreementDoc,
} from '../../api/user';
import { useParams } from 'react-router-dom';
import CustomerSignature from '../Shared/CustomerSignature';
import DecisionEngine from '../Shared/DecisionEngine';
interface Params {
  customerId: string;
}
export default function SplitAgreement() {
  const classes = agreementGlobalStyles();
  const history = useHistory();
  const { customerId } = useParams<Params>();
  const [gotSplitAgrResponse, setGotSplitAgrResponse] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    customerName: '',
    customerId: '',
    coCustomerName: '',
    coCustomerId: '',
    creditOnAccount: '',
    customerHasClub: false,
  });
  const [customerNameToDisplay, setCustomerNameToDisplay]: any = useState('-');
  const [agreementRenterDetails, setAgreementRenterDetails]: any = useState([]);
  const [noCoCustomerOpen, setNoCoCustomerOpen] = useState(false);
  const [somethingWentWrongOpen, setSomethingWentWrongOpen] = useState(false);
  const [errorMessagePopupOpen, seterrorMessagePopupOpen] = useState(false);
  const [manageAgrErrMessage, setManageAgrErrMessage] = useState('');
  const [splitConfirmPopupOpen, setsplitConfirmPopupOpen] = useState(false);
  const [ensureBplusPopupOpen, setEnsureBplusPopupOpen] = useState(false);
  const [splitSuccessOpen, setsSplitSuccessOpen] = useState(false);
  const [agreementCannotSplitOpen, setAgreementCannotSplitOpen] =
    useState(false);
  const [hiddenLoader, sethiddenLoader] = useState(false);
  const [splitProcessDone, setSplitProcessDone] = useState(false);
  const [deProcessDone, setDEProcessDone] = useState(false);
  const [coCustomersWoBplus, setCoCustomersWoBplus]: any = useState('');
  const [customerSignatureEnabled, setcustomerSignatureEnabled] =
    useState(false);
  const [agreementIdForCustomerSign, setagreementIdForCustomerSign] =
    useState('');
  const [AgrCustomerObj, setAgrCustomerObj]: any = useState([]);
  const ManualSignOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ];
  const [documentGenerateCompleted, setDocumentGenerateCompleted] =
    useState(false);
  const [splitAgrDocPayloadArray, setSplitAgrDocPayloadArray]: any = useState(
    []
  );
  const [deEnabled, setDEEnabled] = useState(false);
  const [deCustomerIds, setDEcustomerIds]: any = useState([]);

  useEffect(() => {
    SplitAgreementPageload();
  }, []);

  const SplitAgreementPageload = async () => {
    try {
      const splitReponse: any = await getAgreementsByCustomerId(customerId);
      if (splitReponse.status == 200) {
        setGotSplitAgrResponse(true);
        const splitinfoData = splitReponse.data;
        console.log('splitinfoData', splitinfoData);
        if (splitinfoData.coCustomerId) {
          const customerName = findCustomerName(splitinfoData);
          setCustomerNameToDisplay(customerName);
          setCustomerInfo({
            customerName: `${splitinfoData?.customerFirstName} ${splitinfoData?.customerLastName}`,
            customerId: splitinfoData.customerId,
            coCustomerName: `${splitinfoData?.coCustomerFirstName} ${splitinfoData?.coCustomerLastName}`,
            coCustomerId: splitinfoData?.coCustomerId,
            creditOnAccount: splitinfoData?.customerCOAAmount,
            customerHasClub:
              splitinfoData?.clubInfoResponse?.length > 0 ? true : false,
          });
          if (splitinfoData?.agreement?.length > 0) {
            const tempAgrRenterDetails: any = [];
            for (
              let loopLength = 0;
              loopLength < splitinfoData?.agreement?.length;
              loopLength++
            ) {
              const obj: any = {
                agreementId: splitinfoData?.agreement[loopLength]?.agreementId,
                agreementNumber:
                  splitinfoData?.agreement[loopLength]?.agreementNumber,
                description:
                  splitinfoData?.agreement[loopLength]?.agreementDescription,
                suspense: splitinfoData?.agreement[loopLength]?.suspenseAmount,
                remainingRent:
                  splitinfoData?.agreement[loopLength]?.remainingPaymentAmount,
                pastDue: splitinfoData?.agreement[loopLength]?.pastDueRent,
                customerDropdown: splitinfoData?.agreement[
                  loopLength
                ]?.customers?.map((obj: any) => {
                  return {
                    value: obj?.customerId,
                    label: `${obj?.firstName} ${obj?.lastName}`,
                  };
                }),
                customersInfo: splitinfoData?.agreement[loopLength]?.customers,
              };
              const selectedcustomerArr = splitinfoData?.agreement[
                loopLength
              ]?.customers?.filter((element) => element.priority == '1');
              obj.selectedcustomer = selectedcustomerArr[0]?.customerId;
              obj.isLDWActive =
                splitinfoData?.agreement[loopLength]?.isLDWActive;
              tempAgrRenterDetails.push(obj);
            }
            setAgreementRenterDetails(tempAgrRenterDetails);
            console.log('tempAgrRenterDetails', tempAgrRenterDetails);
          }
        } else {
          setNoCoCustomerOpen(true);
        }
      } else if (splitReponse.status == 400) {
        setGotSplitAgrResponse(true);
        seterrorMessagePopupOpen(true);
        setManageAgrErrMessage(splitReponse?.data?.errors[0]?.error);
      } else {
        setGotSplitAgrResponse(true);
        setSomethingWentWrongOpen(true);
      }
    } catch {
      setGotSplitAgrResponse(true);
      setSomethingWentWrongOpen(true);
    }
  };
  const findCustomerName = (data: any) => {
    if (customerId == data.customerId) {
      if (data?.customerFirstName && data?.customerLastName) {
        return `${data?.customerFirstName} ${data?.customerLastName}`;
      } else if (data?.customerFirstName && !data?.customerLastName) {
        return `${data?.customerFirstName}`;
      } else {
        return '';
      }
    } else if (customerId == data.coCustomerId) {
      if (data?.coCustomerFirstName && data?.coCustomerLastName) {
        return `${data?.coCustomerFirstName} ${data?.coCustomerLastName}`;
      } else if (data?.coCustomerFirstName && !data?.coCustomerLastName) {
        return `${data?.coCustomerFirstName}`;
      } else {
        return '';
      }
    }
  };
  const splitAgreementClick = () => {
    debugger;
    console.log(agreementRenterDetails, 'agreementRenterDetails');
    if (!splitProcessDone && !deProcessDone) {
      let tempCustIds: any = [];
      for (let length = 0; length < agreementRenterDetails.length; length++) {
        const temp = agreementRenterDetails[length]?.customersInfo?.map(
          (obj) => obj.customerId
        );
        tempCustIds.push(temp);
      }
      tempCustIds = tempCustIds.flat(Infinity);
      const uniqueCustomerIds = Array.from(new Set(tempCustIds.flat(Infinity)));
      debugger;
      setDEcustomerIds(uniqueCustomerIds);
      setDEEnabled(true);
    } else if (deProcessDone && !splitProcessDone) {
      setsplitConfirmPopupOpen(true);
    } else {
      setcustomerSignatureEnabled(true);
    }
  };
  const splitConfirmOkClick = () => {
    console.log('agreementRenterDetails', agreementRenterDetails);
    setsplitConfirmPopupOpen(false);
    const ensureCoCustomerBplusResult = ensureCoCustomerBplus();
    console.log('ensureCoCustomerBplusResult', ensureCoCustomerBplusResult);
    const eachCustomerClubLdwValidationResult = eachCustomerClubLdwValidation();
    console.log(
      'eachCustomerClubLdwValidationResult',
      eachCustomerClubLdwValidationResult
    );
    if (!ensureCoCustomerBplusResult) {
      setEnsureBplusPopupOpen(true);
    } else if (!eachCustomerClubLdwValidationResult) {
      setAgreementCannotSplitOpen(true);
    } else {
      const splitAgreementPayload = makeSplitAgreementPayload();
      makeSplitAgreement(splitAgreementPayload);
    }
  };
  const eachCustomerClubLdwValidation = () => {
    console.log('agreementRenterDetails', agreementRenterDetails);
    for (
      let agrLength = 0;
      agrLength < agreementRenterDetails.length;
      agrLength++
    ) {
      const customerClubActive = agreementRenterDetails[
        agrLength
      ].customersInfo.filter(
        (element: any) =>
          element.customerId ==
          agreementRenterDetails[agrLength].selectedcustomer
      );
      if (
        agreementRenterDetails[agrLength].isLDWActive == '0' &&
        customerClubActive[0].isClubApplied == '1'
      ) {
        return false;
      }
    }
    return true;
  };
  const ensureCoCustomerBplus = () => {
    if (customerInfo.customerHasClub) {
      let coCustomerWithOutBp: any = [];
      for (
        let agrLength = 0;
        agrLength < agreementRenterDetails.length;
        agrLength++
      ) {
        coCustomerWithOutBp = agreementRenterDetails[
          agrLength
        ].customersInfo.filter((obj: any) => {
          if (
            obj.customerId ==
              agreementRenterDetails[agrLength].selectedcustomer &&
            obj.isClubApplied == '0'
          ) {
            return `${obj.firstName} ${obj.lastName}`;
          }
        });
      }
      if (coCustomerWithOutBp.length > 0) {
        const uniqueCustomerLists = [
          ...new Set(
            coCustomerWithOutBp.map(
              (item: any) => `${item.firstName} ${item.lastName}`
            )
          ),
        ];
        const CoCustomersWosList = uniqueCustomerLists.join(', ');
        console.log('uniqueCustomerLists', uniqueCustomerLists);
        setCoCustomersWoBplus(CoCustomersWosList);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const ensureBpOkClick = () => {
    console.log('agreementRenterDetails');
    setEnsureBplusPopupOpen(false);
    if (!eachCustomerClubLdwValidation()) {
      setAgreementCannotSplitOpen(true);
    } else {
      const splitAgreementPayload = makeSplitAgreementPayload();
      makeSplitAgreement(splitAgreementPayload);
    }
  };
  const renderTableHeadFn = () => (
    <>
      <RACTableCell style={{ maxWidth: '50px' }}>Agreement#</RACTableCell>
      <RACTableCell>Description</RACTableCell>
      <RACTableCell style={{ textAlign: 'right' }}>Suspense</RACTableCell>
      <RACTableCell style={{ textAlign: 'right' }}>Remaining Rent</RACTableCell>
      <RACTableCell style={{ textAlign: 'right', paddingRight: '150px' }}>
        Past Due
      </RACTableCell>
      <RACTableCell style={{ maxWidth: '150px' }}>
        Primary Customer
      </RACTableCell>
    </>
  );
  const makeSplitAgreementPayload = () => {
    const splitAgreementPayload: any = {};
    const uniqueCustomerIds = [
      ...new Set(
        agreementRenterDetails.map((item: any) => Number(item.selectedcustomer))
      ),
    ];
    const splitAgreementsArray: any = [];
    for (
      let customerLength = 0;
      customerLength < uniqueCustomerIds.length;
      customerLength++
    ) {
      let individualArray = agreementRenterDetails.filter(
        (obj) => obj.selectedcustomer == uniqueCustomerIds[customerLength]
      );
      individualArray = individualArray.map((obj) => Number(obj.agreementId));
      const obj: any = {
        customerId: uniqueCustomerIds[customerLength],
        agreementIds: individualArray,
      };
      splitAgreementsArray.push(obj);
    }
    splitAgreementPayload.splitAgreements = splitAgreementsArray;
    return splitAgreementPayload;
  };
  const makeSplitAgreement = async (payload: any) => {
    try {
      sethiddenLoader(true);
      const splitAgreementResponse: any = await splitAgreement(payload);
      console.log('splitagreementResponse', splitAgreementResponse);
      if (splitAgreementResponse?.status == 200) {
        sethiddenLoader(false);
        setSplitProcessDone(true);
        const splitAgreementData: any =
          splitAgreementResponse?.data?.customerAgreementInfo;
        const customerSignDataArray: any = [];
        for (
          let agrLength = 0;
          agrLength < splitAgreementData?.length;
          agrLength++
        ) {
          const obj: any = {};
          const customerArray = splitAgreementData[agrLength]?.customerInfo.map(
            (obj: any) => {
              return {
                customerId: String(obj.customerId),
                customerName: `${obj.firstName} ${obj.lastName}`,
                phoneNumber: obj.phoneNumber,
                emailId: obj.emailAddress,
                priority: 1,
              };
            }
          );
          obj.agreementNumber = splitAgreementData[agrLength]?.agreementNumber;
          obj.customerName = customerArray[0].customerName;
          obj.agreementId = splitAgreementData[agrLength]?.agreementId;
          obj.signCompletion = '0';
          obj.index = agrLength;
          obj.customerArr = customerArray;
          customerSignDataArray.push(obj);
        }
        setSplitAgrDocPayloadArray(customerSignDataArray);
        setcustomerSignatureEnabled(true);
        genrateAgreementDocuments(customerSignDataArray);
      } else if (splitAgreementResponse?.status == 400) {
        sethiddenLoader(false);
        seterrorMessagePopupOpen(true);
        setManageAgrErrMessage(splitAgreementResponse?.data?.errors[0]?.error);
      } else {
        sethiddenLoader(false);
        setSomethingWentWrongOpen(true);
      }
    } catch {
      sethiddenLoader(false);
      setSomethingWentWrongOpen(true);
    }
  };
  const genrateAgreementDocuments = async (splitAgreementDetails: any) => {
    const GenerateDocPayloadArray: any = [];
    for (let length = 0; length < splitAgreementDetails.length; length++) {
      const ReqObj: any = {};
      ReqObj.identifier = Number(splitAgreementDetails[length].agreementId);
      ReqObj.identifierType = 'AGR';
      ReqObj.signingUrlRequired = false;
      ReqObj.signatureType = 1;
      ReqObj.isRemote = false;
      ReqObj.manualSignatureReasonId = null;
      ReqObj.signers = splitAgreementDetails[length].customerArr;
      ReqObj.documentType = ['SPSW'];
      ReqObj.returnURL = window.location.origin;
      console.log('length', length, 'ReqObj in split loop', ReqObj);
      GenerateDocPayloadArray.push(ReqObj);
    }
    await Promise.all(
      GenerateDocPayloadArray.map(async (obj: any) => {
        await GenerateAgreementDoc(obj);
      })
    ).then(() => {
      setDocumentGenerateCompleted(true);
    });
    console.log('check in generate doc');
  };
  const customerSignatureCompletedFn = () => {
    console.log('customerSignatureCompletedFn');
    setcustomerSignatureEnabled(false);
    setsSplitSuccessOpen(true);
  };
  const customerSignatureCanceledFn = () => {
    console.log('customerSignatureCanceledFn');
    setcustomerSignatureEnabled(false);
  };
  const decisionCanceledFn = () => {
    setDEEnabled(false);
  };
  const decisionEngineCompletedFn = () => {
    setsplitConfirmPopupOpen(true);
    setDEEnabled(false);
    setDEProcessDone(true);
  };
  const agreementNumRedirect = (agrNumber: any) => {
    if (!splitProcessDone) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${agrNumber}`,
      });
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };
  const renderTableContentFn = () => (
    <>
      {agreementRenterDetails.map((obj: any, index: any) => {
        return (
          <RACTableRow>
            <RACTableCell
              className={classes.fontstyle}
              onClick={() => {
                agreementNumRedirect(obj.agreementId);
              }}
              style={{ cursor: !splitProcessDone ? 'pointer' : 'not-allowed' }}
            >
              {obj.agreementNumber}
            </RACTableCell>
            <RACTableCell className={classes.grayClr} title={obj?.description}>
              {obj?.description
                ? hideLengthyText(obj?.description, 30, '...')
                : ''}
            </RACTableCell>
            <RACTableCell
              className={`${classes.amountRight} ${classes.grayClr}`}
              style={{ textAlign: 'right' }}
            >
              ${obj.suspense}
            </RACTableCell>
            <RACTableCell
              className={`${classes.amountRight} ${classes.grayClr}`}
              style={{ textAlign: 'right' }}
            >
              ${obj.remainingRent}
            </RACTableCell>
            <RACTableCell
              className={`${classes.amountRight} ${classes.grayClr}`}
              style={{ textAlign: 'right', paddingRight: '150px' }}
            >
              ${obj.pastDue}
            </RACTableCell>
            <RACTableCell
              className={classes.grayClr}
              style={{ maxWidth: '150px' }}
            >
              <RACSelect
                options={obj.customerDropdown}
                defaultValue={obj.selectedcustomer}
                onChange={(e) => {
                  const onChangeValue: any = e.target.value;
                  const tempAgreementRenterDetails: any =
                    agreementRenterDetails;
                  tempAgreementRenterDetails[index].selectedcustomer =
                    onChangeValue;
                  setAgreementRenterDetails([...tempAgreementRenterDetails]);
                }}
                isDisabled={splitProcessDone}
              />
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  const errorMessagePopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            {manageAgrErrMessage}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              seterrorMessagePopupOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const noCoCustomerPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.formLabel}>
            No Co-customer Available to Split
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setNoCoCustomerOpen(false);
              history.push('/dashboard/homepage');
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const somethingWentWrongPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={classes.textCenter}>
          <Alerticon></Alerticon>

          <Typography className={classes.formLabel}>
            Something went wrong!
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
        >
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setSomethingWentWrongOpen(false);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };
  const splitSuccessPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.textCenter} `}>
          <Successicon />
          <Typography
            className={classes.fontBold}
            style={{ marginTop: '10px', fontSize: 'larger' }}
          >
            Agreement split success
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                history.push('/dashboard/homepage');
              }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const splitConfirmPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.textCenter} `}>
          <Typography
            className={classes.fontBold}
            style={{ marginTop: '-19px', fontSize: 'larger' }}
          >
            Agreements will be split.
            <Typography
              className={`${classes.mt3} ${classes.fontBold}`}
              style={{ fontSize: 'medium' }}
            >
              Please confirm with customers that any reversals, suspense, COA,
              carried rent, promotional dollars, etc must be resolved before the
              split.
            </Typography>
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.mx1}
              onClick={() => {
                setsplitConfirmPopupOpen(false);
              }}
            >
              No
            </RACButton>
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                splitConfirmOkClick();
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const BenefitsPlusActiveConfirmPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.textCenter} `}>
          <Typography
            className={classes.fontBold}
            style={{ marginTop: '-19px', fontSize: 'larger' }}
          >
            The customer has Benifits Plus.
            <Typography
              className={`${classes.mt3} ${classes.fontBold}`}
              style={{ fontSize: 'medium' }}
            >
              Please ensure that you activate Benefits Plus when taking the next
              payment for {coCustomersWoBplus}.
            </Typography>
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={`${classes.mx1} ${classes.mt3}`}
              variant="contained"
              color="primary"
              onClick={() => {
                ensureBpOkClick();
              }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const AgreementCannotSplitPopup = () => {
    return (
      <div
        id="initialpayment"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid className={`${classes.textCenter} `}>
          <Typography
            className={`${classes.mt3} ${classes.fontBold}`}
            style={{ fontSize: 'medium' }}
          >
            These agreements can not be split. The renter has Benefits plus/club
            which requires LDW on all Agreements. We have detected that not all
            the agreement(s) have LDW. Please either add LDW to all agreements
            or deactivate benefits plus/club. If you choose to deactivate
            Benefits Plus Club, you will not be able to reactivate the club to
            cover any previous paid-in-full merchandise.
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
          >
            <RACButton
              className={`${classes.mx1} ${classes.mt3}`}
              variant="contained"
              color="primary"
              onClick={() => {
                setAgreementCannotSplitOpen(false);
              }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
    return Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
  };

  return (
    <div style={{ width: '1514px', marginTop: '65px' }}>
      {hiddenLoader ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {deEnabled ? (
        <DecisionEngine
          decisionCanceledFn={decisionCanceledFn}
          decisionEngineCompletedFn={decisionEngineCompletedFn}
          customerId={deCustomerIds}
        />
      ) : null}
      <RACModalCard
        isOpen={noCoCustomerOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {noCoCustomerPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={errorMessagePopupOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {errorMessagePopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={somethingWentWrongOpen}
        closeIcon={false}
        maxWidth="xs"
        borderRadius={'20px !important'}
      >
        {somethingWentWrongPopup()}
      </RACModalCard>
      <RACModalCard
        borderRadius={'25px !important'}
        isOpen={splitConfirmPopupOpen}
        maxWidth="xs"
        closeIcon={true}
        className={classes.splitConfirmStyle}
        onClose={() => {
          setsplitConfirmPopupOpen(false);
        }}
      >
        {splitConfirmPopup()}
      </RACModalCard>
      <RACModalCard
        borderRadius={'25px !important'}
        onClose={() => {
          ensureBpOkClick();
        }}
        isOpen={ensureBplusPopupOpen}
        maxWidth="sm"
        closeIcon={true}
        className={classes.splitConfirmStyle}
      >
        {BenefitsPlusActiveConfirmPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={agreementCannotSplitOpen}
        closeIcon={false}
        maxWidth="sm"
        borderRadius={'25px !important'}
      >
        {AgreementCannotSplitPopup()}
      </RACModalCard>
      <RACModalCard
        borderRadius={'25px !important'}
        isOpen={splitSuccessOpen}
        maxWidth="xs"
        className={classes.splitConfirmStyle}
      >
        {splitSuccessPopup()}
      </RACModalCard>
      {customerSignatureEnabled ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="split"
          identifier={parseInt(agreementIdForCustomerSign)}
          customersArr={AgrCustomerObj}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={['SPSW']}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
          documentGenerateCompleted={documentGenerateCompleted}
          splitAgrDocPayloadArray={splitAgrDocPayloadArray}
          setSplitAgrDocPayloadArray={setSplitAgrDocPayloadArray}
        />
      ) : null}

      {!gotSplitAgrResponse ? (
        <>
          <div style={{ margin: '20% 20% 20% 48%' }}>
            <CircularProgress size={30} />
          </div>
        </>
      ) : (
        <>
          <Grid container style={{ marginTop: '20px', marginLeft: '5px' }}>
            <List className={`${classes.breadcrumb}`}>
              <ListItem
                className={`${classes.breadcrumbItemActive}`}
                style={{ fontSize: '12px', padding: '0px', cursor: 'pointer' }}
                onClick={() => {
                  history.push('/dashboard/homepage');
                }}
              >
                Dashboard
              </ListItem>
              <ListItem
                className={`${classes.breadcrumbItemActive} ${classes.breadcrumbArrow} ${classes.breadCrumbMarg}`}
                style={{
                  fontSize: '12px',
                  padding: '0px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(`/customer/update/${customerId}/customer`)
                }
              >
                {customerNameToDisplay}-{customerId}
              </ListItem>
              <ListItem
                className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                style={{ fontSize: '12px', padding: '0px' }}
              >
                Split agreement
              </ListItem>
            </List>
          </Grid>
          <Grid style={{ backgroundColor: 'white' }}>
            <Grid container style={{ marginTop: '9px' }}>
              <Grid md={2}>
                <Typography
                  variant="h4"
                  style={{ marginTop: '10px', marginLeft: '10px' }}
                >
                  Agreement Split
                </Typography>
              </Grid>
              <Grid
                md={3}
                container
                style={{ marginTop: '10px', maxWidth: '330px' }}
              >
                <Grid md={5}>
                  <Typography>Primary Customer :</Typography>
                </Grid>
                <Grid md={4}>
                  <Typography
                    className={classes.grayClr}
                    title={customerInfo?.customerName}
                    style={{ width: '300px' }}
                  >
                    {customerInfo?.customerName
                      ? customerInfo?.customerName
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                md={3}
                container
                style={{
                  marginTop: '10px',
                  maxWidth: '300px',
                  marginLeft: '100px',
                }}
              >
                <Grid md={4}>
                  <Typography>Co-Customer:</Typography>
                </Grid>
                <Grid md={8}>
                  <Typography
                    className={classes.grayClr}
                    title={customerInfo?.coCustomerName}
                  >
                    {customerInfo?.coCustomerName
                      ? customerInfo?.coCustomerName
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                md={3}
                container
                style={{ marginTop: '10px', maxWidth: '270px' }}
              >
                <Grid md={6}>
                  <Typography>Credit On Account:</Typography>
                </Grid>
                <Grid md={6}>
                  <Typography className={classes.grayClr}>
                    $
                    {customerInfo?.creditOnAccount
                      ? Number(customerInfo?.creditOnAccount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : '0.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              className={classes.AggRenterDetails}
              style={{ marginLeft: '10px' }}
            >
              {' '}
              Agreement Renter Details
            </Typography>
            <Card className={classes.borderRadius}>
              <CardContent>
                <Grid className={classes.tableStyle}>
                  <RACTable
                    renderTableHead={renderTableHeadFn}
                    renderTableContent={renderTableContentFn}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid className={classes.footerSplit}>
            <RACButton
              variant="outlined"
              color="primary"
              style={{ marginTop: '15px' }}
              onClick={() => {
                history.push('/dashboard/homepage');
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              style={{ float: 'right', marginTop: '15px' }}
              variant="contained"
              color="primary"
              onClick={() => {
                splitAgreementClick();
              }}
            >
              Split Agreement
            </RACButton>
          </Grid>
        </>
      )}
    </div>
  );
}
