/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';

// import RACLogo from '../../assets/images/RAC-Logo.svg';
import { ReactComponent as RACLogo } from '../../../assets/images/RACUpdatedLogoNew.svg';
import { ReactComponent as Erroricon } from '../../../assets/images/error-icon.svg';
// import OtpInput from 'react-otp-input';
//import CircularProgress from '@mui/material/CircularProgress';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
import {
  CircularProgress,
  Typography,
  Grid,
  RACButton,
  RACTextbox,
  RACModalCard,
  makeStyles,
  RACCOLOR,
} from '@rentacenter/racstrap';
// import PinField from 'react-pin-field';
import clsx from 'clsx';
import PinField from 'react-pin-input';
import '../../../styles/pin.css';
// import '../../Styles/global.css';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  GetRole,
  GetRemoteLoginStatus,
} from '../../../api/user';

export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  setRoleFromSLAModule?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
  minRole?: string;
  agrRate?: any;
  throwCurrentPage?: string;
  closeSLAClicked?: any;
  SACedit?: any;
  setSACUserId?: any;
};
const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px',
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonMargin: {
    marginRight: '50px',
  },
}));
export default function SecondFactor(props: PropsInterface) {
  const classes = useClasses();
  const {
    setTwoFactorCancelClick,
    setTwoFactorCompleted,
    setRoleFromSLAModule,
    setEmployeeDetails,
    setSACUserId,
    SACedit,
    minRole,
    closeSLAClicked,
    throwCurrentPage,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [thresHoldExceptionMessage, setthresHoldExceptionMessage] =
    useState('');
  const [openRateAlertModal, setopenRateAlertModal] = useState(false);
  const [openRemotePopup, setopenRemotePopup] = useState(false);
  const [remoteId, setremoteId] = useState('');
  const [securityCode, setsecurityCode] = useState('');
  const [errorMessage, seterrorMessage] = useState('');

  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [roleCapture, setroleCapture] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  const [loader, setloader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');

  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);

  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
    setroleCapture(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const handleRemoteID = (event: any) => {
    setremoteId(event.target.value);
  };

  const handleSecurityCode = (event: any) => {
    setsecurityCode(event.target.value);
  };

  const remoteContinue = async (remoteId: any, securityCode: any) => {
    setopenRemotePopup(false);
    setloader(true);
    const payload = {
      userName: remoteId,
      passCode: securityCode,
    };
    seterrorMessage('');
    const result = await GetRemoteLoginStatus(payload);
    SACedit ? null : setloader(false);
    RemoteValidate(result);
  };

  const RemoteValidate = async (result: any) => {
    if (result?.status == 200) {
      if (result?.data?.response?.result == 'allow') {
        setopenRemotePopup(false);
        setremoteId('');
        setsecurityCode('');
        if (SACedit) {
          const result = await GetRole({ coworkerId: userID });
          setloader(false)
        const Role = result?.data?.coworkerProfile?.role;
          setTwoFactorCompleted !== undefined
            ? setTwoFactorCompleted(true)
            : null;
          setSACUserId(Role);
        } else {
          setTwoFactorCompleted !== undefined
            ? setTwoFactorCompleted(true)
            : null;
        }
      } else {
        seterrorMessage(result?.data?.response?.status_msg);
        setremoteId('');
        setsecurityCode('');
        setopenRemotePopup(true);
      }
    } else {
      console.log('Hi from remote validate', result);
      setremoteId('');
      setsecurityCode('');
      seterrorMessage(result?.data?.errors[0]?.error?.split('"')[7]);
      setopenRemotePopup(true);
    }
  };
  const remoteClick = () => {
    setOpenModal(false);
    setopenRemotePopup(true);
  };

  const remoteEnter = (event: any, remoteId: any, securityCode: any) => {
    if (event.charCode === 13 && remoteId !== '' && securityCode !== '') {
      event.preventDefault();
      remoteContinue(remoteId, securityCode);
    }
  };

  const remoteModelCancelClick = () => {
    if (
      throwCurrentPage === 'ItemSearch' ||
      throwCurrentPage === 'SwitchoutScenario'
    ) {
      closeSLAClicked();
    } else {
      seterrorMessage('');
      setopenRemotePopup(false);
      setTwoFactorCancelClick !== undefined
        ? setTwoFactorCancelClick(true)
        : null;
    }
  };

  const loginClick = async (username: any, Password: any) => {
    console.log('username,password', username, Password);
    setRoleFromSLAModule !== undefined ? setRoleFromSLAModule(username) : null;
    //setRoleFromSLAModule(username);
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    console.log('SLApayload', payload);
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    console.log('SLA result', result);
    SACedit ? null : setloader(false);
    UserValidate(result);
  };

  useEffect(() => {
    console.log('roleCapture fbvjhfdbvjhbd', roleCapture);
  }, [roleCapture]);

  const UserValidate = async (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        
        if (SACedit) {
          const result = await GetRole({ coworkerId: userID });
        const Role = result?.data?.coworkerProfile?.role;
        setloader(false)
          setTwoFactorCompleted !== undefined
            ? setTwoFactorCompleted(true)
            : null;
          setSACUserId(Role);
        } else {
          setTwoFactorCompleted !== undefined
            ? setTwoFactorCompleted(true)
            : null;
        }
        setTwoFactorCancelClick(false);
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else if (
      result.status == 400 &&
      props.throwCurrentPage == 'addedItem' &&
      result.data.errors[0].error !== 'Error in LoginAuthenticationservice'
    ) {
      console.log('captured role name is', roleCapture);
      const result: any = roleCapture.substring(0, 4);
      console.log('fsdvsdvbhs result log', result);

      const getRoleCheck: any = window.sessionStorage.getItem('storeNumber');
      const excludeZero = Number(getRoleCheck).toString();
      console.log('bravdsCXZ excludeZero', excludeZero);

      console.log('fgvwkjhiquwaNSCBjbhsa ', getRoleCheck);

      const finalResult = result.includes(excludeZero);
      console.log('645sd41fcScSDGVc', finalResult);

      if (!finalResult) {
        setOpenModal(false);
        setOpenSecondModal(true);
      } else {
        let roleDecider: any = '';
        if (roleCapture !== undefined) {
          roleDecider = roleCapture.substring(roleCapture.length - 3);
        }
        console.log('roleDecider value is ', roleDecider);

        let allowedRate;
        let minAmount;
        // var ResultRole = "AM";
        if (roleDecider == '001') {
          setthresHoldExceptionMessage(
            `Override is invalid. SLA user can override till $${props.agrRate}`
          );
        } else if (
          roleDecider == '002' ||
          roleDecider == '003' ||
          roleDecider == '004'
        ) {
          allowedRate = Number(props.agrRate * 0.1);
          minAmount = props.agrRate - allowedRate;
          setthresHoldExceptionMessage(
            `Override is invalid. SLA user can override from $${Number(
              minAmount.toString()
            ).toFixed(2)} to $${props.agrRate}`
          );
        } else if (
          roleDecider == '006' ||
          roleDecider == '007' ||
          roleDecider == '008'
          // roleDecider == 'FSC-DMT' ||
          // roleDecider == 'FSC-DM' ||
          // roleDecider == 'FSC-RD'
        ) {
          allowedRate = Number(props.agrRate * 0.5);
          minAmount = props.agrRate - allowedRate;
          setthresHoldExceptionMessage(
            `Override is invalid. SLA user can override from $${Number(
              minAmount.toString()
            ).toFixed(2)} to $${props.agrRate}`
          );
        }
        setOpenModal(false);
        setopenRateAlertModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  useEffect(() => {
    setsomethingone('modal fade show expandPopup');
    setsomethingtwo('modal fade');
    const payload = {
      module: props.moduleName || window.localStorage.getItem('moduleName'),
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      currentRole:
        props.currentRole || window.localStorage.getItem('currentRole'),
    };
    const PageLoad = async () => {
      const result = await getprofileaccess(payload);
      setloader(false);
      if (result.status === 200) {
        setRole(result.data.role);
        popupSelect(result);
      } else if (
        result.status === 400 &&
        (throwCurrentPage === 'ItemSearch' ||
          throwCurrentPage === 'SwitchoutScenario')
      ) {
        setTwoFactorCompleted(true);
      } else {
        setTwoFactorCancelClick(true);
      }
    };
    if (minRole !== undefined && minRole !== '') {
      setloader(false);
      setRole(minRole);
      console.log('minRole in two fact', minRole);

      const Roles = [
        'CAR',
        'FSC-CAR',
        'AM',
        'FSC-AM',
        'LAM',
        'FSC-LAM',
        'SM',
        'FSC-SM',
        'DMT',
        'FSC-DMT',
        'DM',
        'FSC-DM',
        'RD',
        'FSC-RD',
      ];

      console.log(
        'Roles.indexOf(String(props.currentRole))',
        Roles.indexOf(String(props.currentRole))
      );
      console.log('Roles.indexOf(minRole)', Roles.indexOf(minRole));

      const result = {
        data: {
          pin: 0,
          secondFactor:
            Roles.indexOf(String(props.currentRole)) <= Roles.indexOf(minRole)
              ? 1
              : 0,
        },
      };
      console.log('result in sec fact', result);

      popupSelect(result);
    } else if (
      props.moduleName == 'Return' ||
      props.moduleName == 'pastDuePin'
    ) {
      setOpenPinModal(true);
      setloader(false);
      setpopupFlag(false);
    } else if (props.moduleName == 'pastDueSLA') {
      setloader(false);
      setRole(String(props?.currentRole));
      setOpenModal(true);
      setpopupFlag(false);
    } else {
      PageLoad();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    if (
      throwCurrentPage === 'ItemSearch' ||
      throwCurrentPage === 'SwitchoutScenario'
    ) {
      closeSLAClicked();
    } else {
      setErrorLine(false);
      setOpenModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
    }
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
      setTwoFactorCancelClick(false);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
      setTwoFactorCancelClick(false);
    }
  };

  const noClick = () => {
    if (
      throwCurrentPage === 'ItemSearch' ||
      throwCurrentPage === 'SwitchoutScenario'
    ) {
      closeSLAClicked();
    } else {
      setOpenPinModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
    }
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const pinValue = (event: any) => {
    otp = event;
  };

  //eslint-disable-next-line sonarjs/no-identical-functions
  const onCloseClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };

  const onCloseSecondfacClick = () => {
    if (
      throwCurrentPage == 'ItemSearch' ||
      throwCurrentPage === 'SwitchoutScenario'
    ) {
      closeSLAClicked();
    } else {
      setOpenModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
    }
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onPinCloseClick = () => {
    if (
      throwCurrentPage == 'ItemSearch' ||
      throwCurrentPage === 'SwitchoutScenario'
    ) {
      closeSLAClicked();
    } else {
      setOpenPinModal(false);
      setTwoFactorCompleted(false);
      setTwoFactorCancelClick(true);
    }
  };

  const openModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.buttonMargin
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => remoteClick()}
          >
            Remote
          </RACButton>

          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openRemoteModal = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorMessage !== '' ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racRemoteLoginAlert}>
              {errorMessage}
            </Typography>
          </Grid>
        ) : null}
        <Grid className={classes.spacerMargin}>
          <Typography className={classes.racRemoteLogin}>
            Please enter the DM in Training or above level security code to
            continue
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Remote ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={remoteId}
              OnChange={(event) => handleRemoteID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Security Code
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => remoteEnter(event, remoteId, securityCode)}
              isCurrency={false}
              type="password"
              value={securityCode}
              OnChange={(event) => handleSecurityCode(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={remoteModelCancelClick}
          >
            Cancel
          </RACButton>
          {remoteId !== '' && securityCode !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => remoteContinue(remoteId, securityCode)}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {/* <img className={classes.racErrorIcon} src={Erroricon} alt="Logo" /> */}
          <Erroricon className={classes.racErrorIcon} />
          <Typography className={classes.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  const acceptRateAlert = () => {
    setopenRateAlertModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };

  const openRateAlert = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          {/* <img className={classes.racErrorIcon} src={Erroricon} alt="Logo" /> */}
          <Erroricon className={classes.racErrorIcon} />
          <Typography className={classes.racErrorFunction}>
            {thresHoldExceptionMessage}
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={acceptRateAlert}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {loader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <RACModalCard
        isOpen={openModal}
        borderRadius="20px"
        onClose={onCloseSecondfacClick}
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openRemotePopup}
        borderRadius="20px"
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openRemoteModal()}
      ></RACModalCard>

      <RACModalCard
        isOpen={openSecondModal}
        borderRadius="20px"
        closeIcon={true}
        onClose={onCloseClick}
        maxWidth={'sm'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openAlertModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openPinModal}
        borderRadius="20px"
        className="racPinPopup"
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>

      <RACModalCard
        isOpen={openRateAlertModal}
        maxWidth={'sm'}
        borderRadius="25px !important"
        //eslint-disable-next-line react/no-children-prop
        children={openRateAlert()}
      ></RACModalCard>
    </div>
  );
}
